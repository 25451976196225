import React from "react";
import { Row, Col, Table, Card, Button, Image } from "react-bootstrap";
import { Boolean } from "../../comp/boolean";
import { date_formatCZ } from "../../lib/date-utils";
import { Downloader } from "../../comp/downloader";
import { LinkContainer } from "react-router-bootstrap";
import { partnerLogo } from "../../lists/warehouses-defs";
import {
  dnote_field_ok,
  null_or_qmark,
  twist_TypAdresy_str,
  HeaderOrItemField,
  compare_prp_sort,
  ExpirationBadge,
} from "./deliveryNoteChecks";
import { make_product_check_tag } from "../../products/product-checks";
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from "../../products/product-view";
import { MandatoryAddressPart } from "../../partners/partner-detail";
import { ErrorWrap } from "../../comp/errorwrap";
import { ADR_icons } from "../../lists/adr_icons";
import { useTranslation } from "react-i18next";
import Fraction from "fraction.js";
import { LiteralUnion } from "react-hook-form";

//seen all - OK

interface HeaderProps {
  reloadIt: () => void;
  kodDokladu: string;
  document: Dto.GetDeliveryNoteDetailResponse;
}

export function Header({ reloadIt, kodDokladu, document }: HeaderProps) {
  const { t } = useTranslation();

  const pdf_language =
    document?.header?.deliveryAddress?.countryCode === "CZ" || document?.header?.deliveryAddress?.countryCode === "SK"
      ? "čeština"
      : "english";
  return (
    <Row className="g-0">
      <Col xs={12} md="9">
        <Button size="sm" className="me-2" onClick={reloadIt}>
          <Image src="/img/reload.svg" height="19" />
        </Button>
        <h3 className="d-inline align-middle">
          {t("deno-delivery_note")} {kodDokladu}{" "}
        </h3>
        <br className="d-md-none" />
        <span className="d-inline-block me-3">
          <Downloader
            img="/application-pdf.svg"
            height="25"
            alt="pdf"
            label={"CZ"}
            path={"/api/delivery-notes/" + kodDokladu + "/pdf/cz"}
            message="CZ"
            filename={kodDokladu + "-cz.pdf"}
          />
        </span>
        <span className="d-inline-block" style={{ width: "100px" }}>
          <Downloader
            img="/application-pdf.svg"
            height="25"
            alt="pdf"
            label={"EN"}
            path={"/api/delivery-notes/" + kodDokladu + "/pdf/en"}
            message="EN"
            filename={kodDokladu + "-en.pdf"}
          />
        </span>
        <br className="d-md-none" />
        {t("deno-PDF_lang")}:&nbsp;{pdf_language}&nbsp;&nbsp;&nbsp;&nbsp;
        <Downloader
          img="/application-pdf.svg"
          height="25"
          alt="pdf"
          label={"Proof of export"}
          path={"/api/delivery-notes/" + kodDokladu + "/poe-pdf"}
          message="Proof of export"
          filename={kodDokladu + "-proof-of-export.pdf"}
        />
      </Col>
      <Col xs="3" className="text-end">
        &nbsp;
        {document.warehousePartner ? partnerLogo[document.warehousePartner] : ""}
      </Col>
    </Row>
  );
}

export function GeneralInfo({
  ErrorBadge,
  datumDokladu,
  vystavil,
  kodPartnera,
  nazevPartnera,
  typAdresyFaktur,
  nazevAdresy,
  adresa,
  adresa2,
  misto,
  kodStatu,
  psc,
  stredisko,
  zakazka,
  kategorie,
  kodAdresy,
  uzavreno,
  schvaleno,
  poznamka,
}) {
  const { t } = useTranslation();
  return (
    <ErrorWrap>
      <Card body className="p-0 mb-3">
        <h5>{t("deno-basic_info")}</h5>
        <Table borderless size="sm" className="p-0 m-0">
          <tbody>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-document_date")} &nbsp;</td>
              <td className="p-0">
                {date_formatCZ(datumDokladu)}
                <ErrorBadge tag={"PRP9Delivery"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-issuer")} &nbsp;</td>
              <td className="p-0">{vystavil}</td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("biz-partner_code")} &nbsp;</td>
              <td className="p-0">
                <LinkContainer to={{ pathname: "/partners/list/" + kodPartnera + "/detail" }}>
                  <a href="!#">{kodPartnera}</a>
                </LinkContainer>
                <ErrorBadge tag={"addresses-"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("biz-partner_name")} &nbsp;</td>
              <td className="p-0">{nazevPartnera}</td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-inv_adrs")} &nbsp;</td>
              <td className="p-0">
                {t(twist_TypAdresy_str(typAdresyFaktur))}
                <ErrorBadge tag={"InvoicingAddressType"} />
              </td>
            </tr>

            <tr className="p-0 bg-light">
              <td colSpan={2} className="text-center p-0">
                <ErrorBadge tag={"InvoicingAddressData"} /> {t("biz-adrs")} 🗺️
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <MandatoryAddressPart
              nazev={nazevAdresy}
              adresa1={adresa}
              adresa2={adresa2}
              ErrorBadge={ErrorBadge}
              misto={misto}
              psc={psc}
              kodStatu={kodStatu}
              bgLight
              kodAdresy={kodAdresy}
            />
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("biz-centre")} &nbsp;</td>
              <td className="p-0">
                {stredisko}
                <ErrorBadge tag={"CentreCode"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-contract")} &nbsp;</td>
              <td className="p-0">
                {zakazka}
                <ErrorBadge tag={"contractCode"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-category")} &nbsp;</td>
              <td className="p-0">
                {kategorie}
                <ErrorBadge tag={"categoryCode"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("biz-closed")} &nbsp;</td>
              <td className="p-0">
                <Boolean variant="onlyTrue" value={uzavreno} />
                <ErrorBadge tag={"closed"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-approved")} &nbsp;</td>
              <td className="p-0">
                <Boolean variant="onlyTrue" value={schvaleno} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("note")} &nbsp;</td>
              <td className="p-0">{poznamka}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </ErrorWrap>
  );
}

export function DeliveryInfo({
  ErrorBadge,
  realDatumDodani,
  KodDopravce,
  kodDoprProstredku,
  kodDodaciPodminky,
  dopravce,
  kodAdresyDodani,
  nazevAdresy,
  adresa,
  adresa2,
  misto,
  kodStatu,
  psc,
  poznamka,
  telefon,
  mobil,
  zastupcePartnera,
}) {
  const { t } = useTranslation();
  return (
    <ErrorWrap>
      <Card body className="p-0">
        <h5>{t("deno-transport_info")}</h5>
        <Table borderless size="sm" className="p-0 m-0">
          <tbody>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">
                {KodDopravce === "HSHLOGISTIKA" || KodDopravce === "SAMOODBĚR"
                  ? t("deno-stockout_date")
                  : t("deno-delivery_date")}{" "}
                &nbsp;
              </td>
              <td className="p-0">
                {realDatumDodani}
                <ErrorBadge tag={"deliveryDate"} />
                <ErrorBadge tag={"deliveryDateSoft"} />
                <ErrorBadge tag={"PRP9Delivery"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-vehicle")} &nbsp;</td>
              <td className="p-0">
                {kodDoprProstredku} <ErrorBadge tag={"missing-vehicle"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-delivery_condition")} &nbsp;</td>
              <td className="p-0">
                {kodDodaciPodminky} <ErrorBadge tag={"deliveryCondition"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-carrier")} &nbsp;</td>
              <td className="p-0">
                {dopravce} <ErrorBadge tag={"warehouseProblems"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-delivery_adrs")} &nbsp;</td>
              <td className="p-0">
                {kodAdresyDodani} <ErrorBadge tag={"wrongAddress"} /> <ErrorBadge tag={"AddressProblems"} />
              </td>
            </tr>
            <tr className="p-0 bg-light">
              <td colSpan={2} className="text-center p-0">
                <ErrorBadge tag={"DodaciAdresaAllData"} /> {t("biz-adrs")} 🗺️ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <MandatoryAddressPart
              nazev={nazevAdresy}
              adresa1={adresa}
              adresa2={adresa2}
              misto={misto}
              psc={psc}
              kodStatu={kodStatu}
              bgLight
              ErrorBadge={ErrorBadge}
              kodAdresy={kodAdresyDodani}
            />
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">
                <span role="img" aria-label="poznámka ikona">
                  {t("note")} 📝
                </span>{" "}
                &nbsp;
              </td>
              <td className="p-0">{poznamka}</td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">
                <span role="img" aria-label="telefon ikona">
                  {t("biz-phon")} 📞
                </span>{" "}
                &nbsp;
              </td>
              <td className="p-0">
                {telefon} <ErrorBadge tag={"din-phone"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">
                <span role="img" aria-label="mobil ikona">
                  {" "}
                  {t("biz-mobile")} 📱
                </span>{" "}
                &nbsp;
              </td>
              <td className="p-0">
                {mobil} <ErrorBadge tag={"din-phone"} />
              </td>
            </tr>
            <tr className="p-0">
              <td className="text-end text-muted p-0 ">{t("deno-partners_agent")} &nbsp;</td>
              <td className="p-0">{zastupcePartnera}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </ErrorWrap>
  );
}

interface AdditionalInfoProps {
  ErrorBadge: React.FC<{ tag: string }>;
  isADR: boolean;
  document: Dto.GetDeliveryNoteDetailResponse;
}

export function AdditionalInfo({ ErrorBadge, isADR, document }: AdditionalInfoProps) {
  const { t } = useTranslation();
  var idx = 0;
  const del_start_num = document.textsDin.length;
  const dsi_start_num = del_start_num + document.textsDel.length;
  const gw_num_nums = document.items.length * 10;
  // console.log("dels="+del_start_num+" :: dsis="+dsi_start_num+" :: gwm = "+gw_num_nums);
  return (
    <ErrorWrap>
      <Card>
        <Row>
          <Col xs="auto">
            <h5 className="m-3">{t("deno-additional_info")}</h5>
          </Col>
          <Col>
            <div className="p-1" style={{ float: "right" }}>
              {isADR ? <DisplayADR adrClass="1" height={60} /> : ""}
            </div>
          </Col>
        </Row>
        <Card.Body className="ps-3">
          <h6 className="mb-1">{t("deno-delivery_info")}</h6>
          {document.textsDin.sort(compare_prp_sort).map(function (din) {
            idx += 1;
            return (
              <Row className="g-0" key={idx}>
                <Col xs="2">
                  {din.textTypeCode}
                  {din.textTypeCode === "PRP36" || din.textTypeCode === "PRP37" ? <ErrorBadge tag={"din-phone"} /> : ""}
                </Col>
                <Col xs="10">
                  {din.originalTextName}
                  <ErrorBadge tag={"hsh-din"} />
                </Col>
              </Row>
            );
          })}
          <hr />
          <h6 className="mb-1">{t("deno-cargo_info")}</h6>
          {document.textsPrp.sort(compare_prp_sort).map(function (prp) {
            idx += 1;
            return (
              <Row className="g-0" key={idx}>
                <Col xs="2">{prp.textTypeCode}</Col>
                <Col xs="10">{prp.originalTextName}</Col>
              </Row>
            );
          })}

          {document.textsDel[0] === "" ? (
            ""
          ) : (
            <div style={{ backgroundColor: "#28a745b5" }} className="mt-2 ps-1">
              {document.textsDel.slice(0, 2).map(function (del, didx) {
                return (
                  <samp className="small" key={didx}>
                    {del}
                    <br />
                  </samp>
                );
              })}
            </div>
          )}
          <hr />
          <h6 className="mb-1">
            {t("deno-manipulation_info")}
            <ErrorBadge tag={"missingText"} />
          </h6>
          {document.textsSkl.sort(compare_prp_sort).map(function (skl, sidx) {
            // console.log('sidx = '+sidx);
            const cls = sidx + dsi_start_num >= gw_num_nums ? " text-muted" : "";
            return (
              <Row key={sidx} className={cls + " g-0 "}>
                <Col xs="2">{skl.textTypeCode}</Col>
                <Col xs="10">{skl.originalTextName}</Col>
              </Row>
            );
          })}
        </Card.Body>
      </Card>
    </ErrorWrap>
  );
}

interface DisplayADRProps {
  adrClass: LiteralUnion<keyof typeof ADR_icons, string>;
  height?: number;
}

export function DisplayADR({ adrClass, height = 35 }: DisplayADRProps) {
  if (!adrClass) {
    return "-";
  }
  const img = ADR_icons[adrClass];
  if (img) {
    return <Image src={"/img/adr/isADR.svg"} height={height} />;
  }
  return adrClass;
}

export interface ProcessedDeliveryNoteItem extends Dto.GetDeliveryNoteDetailItem {
  packageWeightKgHsh: Fraction;
  packageWeightKgWh: Fraction;
  packageQuantity: Fraction;
  alternativeNameStrings: string;
  noCard: boolean;
  redish: string;
}

interface PDItemsWebProps {
  items: ProcessedDeliveryNoteItem[];
  header: Dto.GetDeliveryNoteDetailHeader;
  ErrorBadge: React.FC<{ tag: string }>;
}

export function PDItemsWeb({ items, header, ErrorBadge }: PDItemsWebProps) {
  const { t } = useTranslation();
  return (
    <>
      <h4 className=" ms-3 mt-3 mb-3">{t("biz-items")} </h4> <ErrorBadge tag={"noItems"} />
      <Table bordered size="sm" className="d-none d-xl-table striped-2-rows bg-white mb-0">
        <thead className="beGray">
          <tr>
            <th rowSpan={3} className="ps-3">
              {t("biz-centre")}
            </th>
            <th rowSpan={3}>{t("deno-contract")}</th>
            <th rowSpan={3}>{t("deno-category")}</th>
            <th rowSpan={3}>{t("not-prod_id")}</th>
            <th rowSpan={3}>{t("prod-code")}</th>
            <th colSpan={9} className="text-center">
              {t("prod-prod_card")} <small>({t("prod-hsh_wh_comparison")})</small>
            </th>
            <th rowSpan={3} className="text-center">
              {t("ord-expiry")}
            </th>
            <th rowSpan={3} className="text-center">
              {t("deno-delivered_amount")}
            </th>
            <th rowSpan={3} className="text-center">
              {t("package_count")}
            </th>
          </tr>
          <tr className="text-center">
            <th rowSpan={2}>{t("warehouse")}</th>
            <th rowSpan={2} style={{ width: "10%" }}>
              {t("prod-name")}
            </th>
            <th colSpan={4}>{t("prod-package")}</th>
            <th colSpan={3}>{t("prod-ADR")}</th>
          </tr>
          <tr>
            <th className="text-center beGray">{t("ord-pack")}</th>
            <th className="text-center beGray">{t("ord-content_w_unit")}</th>
            <th className="text-center beGray">{t("ord-density")}</th>
            <th className="text-center beGray">{t("ord-1_pack_weight")}</th>
            <th className="text-center beGray">{t("ord-adr_class")}</th>
            <th className="text-center beGray">{t("ord-pack_group")}</th>
            <th className="text-center beGray">{t("ord-un_num")}</th>
          </tr>
        </thead>
        <tbody>
          {items.map(function (item) {
            return (
              <React.Fragment key={item.series.itemId}>
                <tr className="text-center">
                  <td rowSpan={2} className="align-middle">
                    <HeaderOrItemField header={header.stockOut.departmentCode} item={item.stockOut.departmentCode} />
                    {null_or_qmark(item.stockOut.departmentCode) ? <ErrorBadge tag={"CentreCode"} /> : ""}
                  </td>
                  <td rowSpan={2} className="align-middle">
                    <HeaderOrItemField header={header.stockOut.orderCode} item={item.stockOut.orderCode} />
                    {null_or_qmark(item.stockOut.orderCode) ? <ErrorBadge tag={"contractCode"} /> : ""}
                  </td>
                  <td rowSpan={2} className="align-middle">
                    <HeaderOrItemField header={header.stockOut.categoryCode} item={item.stockOut.categoryCode} />
                    {null_or_qmark(item.stockOut.categoryCode) ? <ErrorBadge tag={"categoryCode"} /> : ""}
                  </td>
                  <td rowSpan={2} className="align-middle">
                    {item.productId}
                  </td>
                  <td rowSpan={2} className="align-middle">
                    <LinkContainer
                      to={{ pathname: "/products/" + encodeURIComponent(item.stockOut.productCode) + "/view" }}
                    >
                      <a href="!#">{item.stockOut.productCode}</a>
                    </LinkContainer>
                  </td>
                  <td className="align-middle">
                    HSH:
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "twistApproval")} />
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "hisApproval")} />
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "configured")} />
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "monthExpiration")} />
                  </td>
                  <td className="align-middle text-start">
                    {item.stockOut.productName}
                    {item.alternativeNameStrings ? (
                      <>
                        <hr />
                        {item.alternativeNameStrings}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <ProductContainerView
                    id={"hsh-" + item.productId}
                    container={item.hshPackage}
                    ErrorBadge={ErrorBadge}
                  />
                  <DensityAmountUnitWeightView
                    id={"hsh-" + item.productId}
                    density={item.series.unitOfMeasurementWeight}
                    densityUnit={item.series.unitOfMeasurementUnit}
                    amount={item.packageUnitOfMeasurementQuantity}
                    unit={item.storageUnitOfMeasurementCode}
                    packageWeight={item.packageWeightKgHsh}
                    ErrorBadge={ErrorBadge}
                  />
                  <ProductADRView
                    id={"hsh-" + item.productId}
                    TridaADR={item.classAdr}
                    k_ADRObalovaSkupina={item.adrPackingGroup}
                    k_ADRUNCislo={item.adrUnNumber}
                    ErrorBadge={ErrorBadge}
                  />
                  <td rowSpan={2} className="align-middle">
                    {date_formatCZ(item.expirationDate)}
                    {dnote_field_ok(item.expirationDate) ? "" : <ErrorBadge tag={"expiryMissing"} />}
                    <ErrorBadge tag={"expiration-" + item.productId} />
                    <ExpirationBadge expiration={item.expirationDate} />
                  </td>
                  <td rowSpan={2} className="align-middle text-center">
                    {item.stockOut.stockQuantity} {item.storageUnitOfMeasurementCode}{" "}
                    <ErrorBadge tag={"amount-fits-packages-" + item.series.itemId} />
                  </td>
                  <td rowSpan={2} className="align-middle text-center">
                    {item.packageQuantity?.toString()} <ErrorBadge tag={"amount-fits-packages-" + item.series.itemId} />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle text-center">
                    {item.stockOut.bookCode}
                    <ErrorBadge tag={"singleWarehouse"} />
                    <ErrorBadge tag={"missing-card-" + item.stockOut.bookCode + "-" + item.productId} />
                  </td>
                  <td className={"align-middle text-start" + item.redish}>{item.warehouseCard.productName}</td>
                  <ProductContainerView
                    id={"wh-" + item.stockOut.bookCode + "-" + item.productId}
                    wid={item.stockOut.bookCode}
                    container={item.warehouseCard.hshPackage}
                    remap
                    ErrorBadge={ErrorBadge}
                    bg={item.noCard}
                  />
                  <DensityAmountUnitWeightView
                    id={"wh-" + item.stockOut.bookCode + "-" + item.productId}
                    density={item.warehouseCard.unitOfMeasurementWeightInKg}
                    densityUnit="kg"
                    amount={item.warehouseCard.packageUnitOfMeasurementQuantity}
                    unit={item.warehouseCard.storageUnitOfMeasurementCode}
                    packageWeight={item.packageWeightKgWh}
                    ErrorBadge={ErrorBadge}
                    bg={item.noCard}
                  />
                  <ProductADRView
                    id={"wh-" + item.stockOut.bookCode + "-" + item.productId}
                    TridaADR={item.warehouseCard.adrClass}
                    k_ADRObalovaSkupina={item.warehouseCard.adrPackingGroup}
                    k_ADRUNCislo={item.warehouseCard.adrUnNumber}
                    romanized
                    ErrorBadge={ErrorBadge}
                    bg={item.noCard}
                  />
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
