/**
* List of stockins
* @module orders/stockins
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React, { useState, useCallback } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { his_fetch } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { date_formatCZ } from '../lib/date-utils';
import { LinkContainer } from 'react-router-bootstrap';
import { filter_rule } from '../lib/utils';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * List of stockins
 * 
 * @param {any} userlogin - info about logged user
 * @returns {component}
 */

export function Stockins({ userlogin }) {
    const { t } = useTranslation();

    const [stockins, setStockins] = useState([]);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [totalCount, setTotalCount] = useState(null);
    const [oldest, setOldest] = useState(null);
    const [offset, setOffset] = useState(0);
    const [filter_s_id, setFilter_s_id] = useState("");

    const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
        return (
            his_fetch(
                userlogin,
                [
                    {
                        // uri: "/api/stockins/range/" + "2022-01-01" + "/" + "2022-12-07",
                        uri: "/api/stockins/range/" + startDate + "/" + endDate,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            if (result.error) {
                                result_received(false);
                            } else {
                                setStockins(stockinsCat => (reload ? [] : stockinsCat).concat(result.result));
                                setTotalCount(result.total_count);
                                setOldest(result.oldest.sn_created_at);
                                result_received(true);
                            }
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            result_received(false);
                        }
                    }
                ]
            ))
    }, [userlogin])

    const cleanFilters = () => {
        setFilter_s_id("");
    }

    const handleChange_s_id = function (event) {
        setFilter_s_id(event.target.value);
        setOffset(0);
    }

    /**
     * stockins for choosen sn_id - stock notifiction id
     * 
     * @param {number} sn_id - stock notification id
     * @returns {any}
     */
    function stockins_for_sn_id(sn_id) {
        return stockins.filter((stockin) => stockin.sn_id === sn_id);
    }

    /**
     * Returns array of unique batches for particular stock notification
     * 
     * @param {number} sn_id - stock notification id
     * @returns {any}
     */
    function get_unique_batches_for_sn(sn_id) {
        return Object.values(stockins_for_sn_id(sn_id)
            .map((s) => s.s_snb_id).filter((v, i, a) => a.indexOf(v) === i) // array of unique s_snb_ids in stockins
            .reduce((acc, s_snb_id) => ({
                ...acc,
                [s_snb_id]: stockins_for_sn_id(sn_id).filter((batch) => batch.s_snb_id === s_snb_id)[0] // s_snb_id: first of all batches with this id
            }), {}));
    }

    /**
     * Returns array of dictionaries, dict keys: sn_id, lowest_s_id, stockins, batches
     */
    const preprocessedData = stockins
        .map((s) => s.sn_id) //all sn_id
        .filter((v, i, a) => a.indexOf(v) === i) // unique sn_id
        .map((sn_id) => ({ //creates array of dictionaries - one dictionary per every sn_id
            sn_id: sn_id,
            lowest_s_id: "HIS-" + stockins_for_sn_id(sn_id).sort((a, b) => a.s_id - b.s_id)[0].s_id, //sort stockins by s_id and give me lowest s_id number
            sn_number: stockins_for_sn_id(sn_id)[0].sn_number,
            stockins: stockins_for_sn_id(sn_id), //all stockins for a sn_id - not used, for debuging purposes
            batches: get_unique_batches_for_sn(sn_id)//batches by s_snb_id, duplicity of s_snb_id ignored
        }));



    // podle sni_id bych to mohla rozdělit na původní položky avizace, ale to nyní nemá význam)

    // console.log(preprocessedData);

    const stockins_filtered = preprocessedData.filter(
        function (s) {
            return (
                (filter_rule(filter_s_id, s.lowest_s_id))
            );
        });

    const show_stockins = stockins_filtered.slice(offset, offset + 20);
    //console.log(show_stockins);

    return (
        <ErrorWrap>
            <DynamicLoad period='1y' loadData={loadData}
                filtered_data={stockins_filtered} data={preprocessedData}
                offset={offset} setOffset={setOffset}
                totalCount={totalCount} oldest={oldest}
                cleanFilters={cleanFilters}
                loadedCount={stockins.length}
            />
            <Table size="sm" striped>
                <thead className='beGray'>
                    <tr>
                        <th className='pb-2'>
                            <p className="mb-1">{t('ord-stockin_id')}</p>
                            <Form.Group controlId="filter_s_id" className='mb-1'>
                                <Form.Control type="text" placeholder="&#128269;" value={filter_s_id} onChange={handleChange_s_id} />
                            </Form.Group>
                        </th>
                        <th className='pb-2'>{t('ord-pur')}</th>
                        <th className='pb-2'>{t('ord-sn_num')}</th>
                        <th className='pb-2'>{t('ord-imported')}</th>
                        <th className='pb-2'>{t('ord-notified')}</th>
                        <th className='pb-2'>{t('warehouse')}</th>
                        <th className='pb-2'>{t('ord-eta')}</th>
                        <th className='pb-2'>
                            <Row>
                                <Col className='text-end'>{t('not-prod_id')}</Col>
                                <Col className='text-end'>{t('amount')}</Col>
                                <Col>{t('ord-batch')}</Col>
                            </Row>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {show_stockins.map(function (rec, idx) {
                        const batch = rec.batches[0];
                        const classa = "align-middle";
                        return (
                            <tr key={idx}>
                                <td className={classa}>{rec.lowest_s_id}</td>
                                <td className={classa}>
                                    <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/orders/purchase/" + batch.nod_KodDokladu }}>
                                        <span className=' blueish'>{batch.nod_KodDokladu}</span>
                                    </LinkContainer>
                                </td>
                                <td className={classa}>{rec.sn_number}</td>
                                <td className={classa}>{batch.s_imported ? date_formatCZ(batch.s_imported) : "-"}</td>
                                <td className={classa}>{date_formatCZ(batch.sn_created_at)}</td>
                                <td className={classa}>{batch.sn_warehouse_id}</td>
                                <td className={classa}>{batch.sn_eta}</td>
                                <td className={classa}>
                                    {rec.batches.map(function (batch, idx) {
                                        //console.log(batch);
                                        return (
                                            <Row key={idx}>
                                                <Col className='text-end'>{batch.sni_k_IDProduktu}</Col>
                                                <Col className='text-end'>{batch.snb_amount}</Col>
                                                <Col>{batch.snb_batch}</Col>
                                            </Row>
                                        );
                                    })
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <LoadingDataInfo loadedStatus={loadedStatus} data={stockins} />
        </ErrorWrap>
    );
}

/*
mp_Jmeno: "Jindřich Ondračka"

mp_KodPracovnika: "ondracka"

nod_KodDokladu: "NO202201000080"

s_amount: 50

s_batch: "280222"

s_id: 1

s_imported: null

s_received_file_id: 1

s_snb_id: 204

sn_created_at: "2022-02-24 14:48:44"

sn_created_by: 1

sn_eta: "4. 3. 2022"

sn_id: 133

sn_nod_id: 44724

sn_number: 3

sn_warehouse_id: 27

snb_amount: 50

snb_batch: "asdf"

snb_bbd: null

snb_sni_id: 175

sni_k_IDProduktu: 5681

sni_nop_id: 64187

u_username: "joe"
*/