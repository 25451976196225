/**
* statistics of stock notifications of users and warehouses
* @module orders/stock-notifications-statistics
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Badge, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { StockNotifi3 } from "./stock-notify-step3";
import { StockNotifi1 } from './stock-notify-step1';
import { StockNotifi2 } from './stock-notify-step2';
import { his_fetch } from '../comp/FetchLoader';
import { LinkContainer } from 'react-router-bootstrap';
import { date_formatISO } from '../lib/date-utils';
import { Loading } from '../comp/loading';
import { ErrorWrap } from '../comp/errorwrap';
import { OtherUsersAlert } from './order-purchase';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * procházím přes items a podle toho updatuju nofityItems, který je můj počáteční acc
 * a modifikuju když to neobrahuje dané id_polozky - dám tam klíč pro batch
 * když jsou tam batches prázdný, tak tam dám patříčné hodnoty batch, amount, expiration, expiryType 
 * 
 */

//používám jen na začátku při otevření avizace

function ensureDefaultBatches(items, notifyItems, t) { // vemu notify items a pak items z NOcka, 
    const newItems = items
        .reduce(function (acc, item) {
            const IDPolozky = item.nop_IDPolozky;
            const polozka = acc[IDPolozky] || { //podívám se do akumulátoru, jestli je tam položka - pokud ne, použiju prázdnou, ((celou dobu mám všechny položky na pozadí, s klíčem picked))
                batches: [],
                expiry: null
            };
            const nova_polozka = polozka.batches.length === 0 ? { ...polozka, batches: make_default_batches(item.pwc_id, t) } : polozka; //podívám se, pokud má položka vyplněné batches, pokud nemá, tak to nahrazuje defaultem
            return {
                ...acc, // ten slovník, ve skutečnosti notifyItems
                [IDPolozky]: nova_polozka // každé IDPolozky přepisuju hodnotou nova_polozka
            };
        },
            notifyItems);
    return newItems;
}

function make_default_batches(pwc_id, t) {
    switch (pwc_id) {
        case 3:
            return [{ batch: t('ord-not_filled'), amount: "", expiration: "", expiryType: "default" }];
        case 1:
            return [{ batch: null, amount: "", expiration: t('ord-filled_by_wh'), expiryType: "default" }];
        default:
            return [{ batch: null, amount: "", expiration: "", expiryType: "default" }];
    }
}


/**
 * Component for notification process, source of data about notification, onChange functions, switching steps
 * 
 * @param {any} order - order details
 * @param {any} items - items in order
 * @param {any} userlogin - information about logged user
 * @param {number} stockinsNumber - how many stockins have been made within order
 * @param {any} products - products info in notification
 * @param {any} reloadIt - reload order page
 * @param {any} texts - additional text to display during notification process
 * @param {array} notifyOtherUsers - users, who are working on this notification
 * @returns {component}
 */

export function StockNotification({ order, items, userlogin, stockinsNumber, products, reloadIt, texts, notifyOtherUsers }) {
    const { t } = useTranslation();

    const { step } = useParams();
    const [etaDate, setEtaDate] = useState("");
    const [pickedItems, setPickedItems] = useState({}); //ids of picked items in dictionary
    const [notifyItems, setNotifyItems] = useState({}); // 3 level dictionary of items with batches and expiry
    const [unlockedItems, setUnlockedItems] = useState({}); // dictionary of ids of batches with unknown batch
    const [finalResult, setFinalResult] = useState(null);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [stateOfUsersInside, setStateOfUsersInside] = useState(0); // 0 - initial, 1- transition phase, 2 - userInside

    const creatorUserIsIn = notifyOtherUsers.map((us) => us.uo_username).includes(userlogin.username);
    //console.log("creatorUserIsIn: " + creatorUserIsIn);
    if (stateOfUsersInside === 1 && creatorUserIsIn) {
        setStateOfUsersInside(2);
    }

    function updateNotifyItemsItem(nop_IDPolozky, item) {
        var itemAdd = {};
        itemAdd[nop_IDPolozky] = item;
        setNotifyItems({ ...ensureDefaultBatches(items, notifyItems, t), ...itemAdd });
    }

    function setNotifyItemExpiry(nop_IDPolozky, expiry) {
        const item = ensureDefaultBatches(items, notifyItems, t)[nop_IDPolozky] || {};
        const newItem = {
            batches: item.batches || [],
            expiry: expiry
        };
        updateNotifyItemsItem(nop_IDPolozky, newItem);
    }

    function getNotifyItemExpiry(nop_IDPolozky) {
        return (ensureDefaultBatches(items, notifyItems, t)[nop_IDPolozky] || { expiry: null })["expiry"];
    }

    function getNotifyItemBatches(nop_IDPolozky) {
        return (ensureDefaultBatches(items, notifyItems, t)[nop_IDPolozky] || { batches: [] })["batches"];
    }

    function updateNotifyItemBatchLine(nop_IDPolozky, rowNumber, func) {
        const item = ensureDefaultBatches(items, notifyItems, t)[nop_IDPolozky] || {};
        const batches = item.batches || [];
        const newBatches = batches.map((batch, index) => index === rowNumber ? func(batch) : batch);
        const newItem = { ...item, batches: newBatches };
        updateNotifyItemsItem(nop_IDPolozky, newItem);
    }

    function setNotifyItemBatch(nop_IDPolozky, rowNumber, batchStr) {
        updateNotifyItemBatchLine(nop_IDPolozky, rowNumber, (batchLine) => ({ ...batchLine, batch: batchStr }));
    }

    function setNotifyItemAmount(nop_IDPolozky, rowNumber, amount) {
        updateNotifyItemBatchLine(nop_IDPolozky, rowNumber, (batchLine) => ({ ...batchLine, amount: amount }));
    }

    function setNotifyItemExpiration(nop_IDPolozky, rowNumber, expiration, expiryType) {
        updateNotifyItemBatchLine(nop_IDPolozky, rowNumber, (batchLine) => ({ ...batchLine, expiration: expiration, expiryType: expiryType }));
    }

    function addNotifyItemBatch(nop_IDPolozky, pwc_id) {
        const item = ensureDefaultBatches(items, notifyItems, t)[nop_IDPolozky] || {};
        const batches = item.batches || [];
        //const batch_string = unlockedItems[nop_IDPolozky] === 2 ? unknown_batch_string : null; už nechci přidávat neznámou
        const expiration = pwc_id === 1 ? t('ord-filled_by_wh') : "";
        const newBatches = batches.concat([{ batch: null, amount: "", expiration: expiration, expiryType: "default" }]);
        const newItem = { ...item, batches: newBatches };
        updateNotifyItemsItem(nop_IDPolozky, newItem);
    }

    function delNotifyItemBatch(nop_IDPolozky, rowNumber) {
        const item = ensureDefaultBatches(items, notifyItems, t)[nop_IDPolozky] || {};
        const batches = item.batches || [];
        const newBatches = batches.filter((batch, index) => index !== rowNumber);
        const newItem = { ...item, batches: newBatches };
        updateNotifyItemsItem(nop_IDPolozky, newItem);
    }



    function setExpirations(expirations) { //reduce - mám element a nějaký mezivýsledek a mohu si tím dělat co chci
        const newItems = items
            .reduce(function (acc, item) { //reducem procházím items, ale aktualizuji notifyItems 
                const polozka = item.nop_IDPolozky; // císlo(id) položky z items
                const expiration = expirations[polozka]; //koukám do parametru expirations, jstli v tom je polozka z items
                const stara_polozka = acc[polozka]; //položka v akumulátoru notifyItems
                const nova_polozka = expiration ? {
                    ...stara_polozka,
                    expiry: expiration, //expiry přepisuju jako klíč uvnitř staré polozky
                    batches: stara_polozka.batches.map((batch) => //přemapování batches: pro každý single batch v poli batches => rozprostřu klíče a nahrazuju klíč expiration podle pravidla
                    ({
                        ...batch,
                        expiration: batch.expiryType === "default" ? expiration : batch.expiration
                    }))
                }
                    : stara_polozka;
                return {
                    ...acc,
                    [polozka]: nova_polozka,
                }
            },
                ensureDefaultBatches(items, notifyItems, t));
        setNotifyItems(newItems);
    }

    function updateMetaBatch(nop_IDPolozky, conversion) {
        const item = ensureDefaultBatches(items, notifyItems, t)[nop_IDPolozky] || {};
        const batches = item.batches || [];
        const newBatches = batches.map(conversion);
        const newItem = { ...item, batches: newBatches };
        updateNotifyItemsItem(nop_IDPolozky, newItem);
    }

    function updateUnknownBatch(nop_IDPolozky) {
        updateMetaBatch(nop_IDPolozky, (batch) => ({ ...batch, batch: (batch.batch === null || batch.batch === "") ? 'neznámá' : batch.batch }));
    }

    function updateKnownBatch(nop_IDPolozky) {
        updateMetaBatch(nop_IDPolozky, (batch) => ({ ...batch, batch: batch.batch === 'neznámá' ? '' : batch.batch }));
    }

    function createNotification() {
        if (!saving) {
            setSaving(true);

            const selectedItems = Object.keys(ensureDefaultBatches(items, notifyItems, t)).filter((id) => pickedItems[id])
                .reduce(function (acc, id) {
                    acc[id] = ensureDefaultBatches(items, notifyItems, t)[id]; //v každým kroku přidává akumulátor 1 záznam
                    return acc;
                }, {});

            var notify = {};
            notify['eta'] = date_formatISO(etaDate);
            notify['items'] = selectedItems; //projet mapem a formátovat jako iso
            notify['unlockedItems'] = unlockedItems;
            const jsonData = JSON.stringify(notify);
            //console.log(jsonData);

            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stock-notifications/" + order.nod_IDDokladu + "/create",
                        json: true,
                        ok: function (resource, result) {
                            //console.log(result);
                            if (result.result === "ok") {
                                setFinalResult(true);
                                setSaving(false);
                                setErrorMessage("");
                            } else if (result.error) {
                                setFinalResult(false);
                                setErrorMessage(result.error);
                                setSaving(false);
                            }
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setFinalResult(false);
                            setErrorMessage(reason);
                            setSaving(false);
                        },
                        args: {
                            method: 'POST',
                            body: jsonData,
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    }
                ]
            );
        }
    }

    useEffect(function () {
        his_fetch(
            userlogin,
            [
                {
                    uri: "/api/stock-notifications/" + order.nod_IDDokladu + "/open",
                    json: true,
                    ok: function (resource, result) {
                        setStateOfUsersInside(1);
                    },
                    error: function (resource, reason) {
                        console.log('err: ' + reason);
                    },
                    args: {
                        method: 'POST',
                    }
                }
            ]
        );
        return function () {
            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stock-notifications/" + order.nod_IDDokladu + "/open",
                        json: true,
                        ok: function (resource, result) {
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                        },
                        args: {
                            method: 'DELETE',
                        }
                    }
                ]
            );
        }
    }, [order, userlogin]);

    if (notifyOtherUsers === null) {
        return (
            <>
                <Row>
                    <Col> <h3 className="mb-4">{t('ord-sn_number')}  {stockinsNumber + 1} {t('of_prep')} {order.nod_KodDokladu}</h3></Col>
                    <Col></Col>
                </Row>
                <Loading />
            </>
        );
    }

    var ntfStep = <StockNotifi1 kodDokladu={order.nod_KodDokladu} items={items} pickedItems={pickedItems} setPickedItems={setPickedItems} />;
    switch (step) {
        case "2":
            ntfStep = <StockNotifi2 kodDokladu={order.nod_KodDokladu} items={items} etaDate={etaDate} setEtaDate={setEtaDate} pickedItems={pickedItems}
                setNotifyItemExpiry={setNotifyItemExpiry} getNotifyItemExpiry={getNotifyItemExpiry}
                addNotifyItemBatch={addNotifyItemBatch} getNotifyItemBatches={getNotifyItemBatches}
                setNotifyItemAmount={setNotifyItemAmount} setNotifyItemBatch={setNotifyItemBatch} delNotifyItemBatch={delNotifyItemBatch}
                unlockedItems={unlockedItems} setUnlockedItems={setUnlockedItems}
                setExpirations={setExpirations}
                products={products} setFinalResult={setFinalResult} texts={texts} setNotifyItemExpiration={setNotifyItemExpiration}
                notifyItems={ensureDefaultBatches(items, notifyItems, t)} updateUnknownBatch={updateUnknownBatch} updateKnownBatch={updateKnownBatch}
            />;
            break;
        case "3":
            ntfStep = <StockNotifi3 kodDokladu={order.nod_KodDokladu} items={items} etaDate={etaDate} pickedItems={pickedItems} notifyItems={ensureDefaultBatches(items, notifyItems, t)}
                createNotification={createNotification} finalResult={finalResult} saving={saving}
                reloadIt={reloadIt} errorMessage={errorMessage} texts={texts} />;
            break;
        default:
            ntfStep = <StockNotifi1 kodDokladu={order.nod_KodDokladu} items={items} pickedItems={pickedItems} setPickedItems={setPickedItems} texts={texts} />
    }
    const userIsntIn = stateOfUsersInside === 2 && !creatorUserIsIn;
    return (
        <ErrorWrap>
            <Row>
                <Col> <h3 className="mb-4">{t('ord-sn_number')} {stockinsNumber + 1} {t('of_prep')} {order.nod_KodDokladu}</h3></Col>

                <Col>
                    <NotifyCancelled userIsntIn={userIsntIn}
                        kodDokladu={order.nod_KodDokladu} />
                    <OtherUsersAlert notifyOtherUsers={notifyOtherUsers} userlogin={userlogin} reloadIt={reloadIt} />
                </Col>
            </Row>
            {userIsntIn ? "" :
                <>
                    <BreadcrumbNav step={step} />
                    {ntfStep}
                </>
            }
        </ErrorWrap>
    );
}

/**
 * 
 * @param {boolean} userIsntIn - true if user is no longer active in notification process
 * @param {string} kodDokladu - id of order
 * @returns {component}
 */
function NotifyCancelled({ userIsntIn, kodDokladu }) {
    const { t } = useTranslation();

    if (userIsntIn) {
        return (
            <Alert className="" variant="danger">
                {t('ord-your_notification_was_terminated')}.
                <br />
                <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu + "/old" }} >
                    <Button size="sm" className="mb-2 mt-3" variant="primary">{t('ord-back_to_NO')}</Button>
                </LinkContainer>
            </Alert>
        );
    } else {
        return "";
    }
}

/**
 * Navigation with stockin notification
 * 
 * @param {string} step - number of step
 * @returns {component}
 */
function BreadcrumbNav({ step }) {
    const { t } = useTranslation();

    const variant1 = step === "1" ? "primary" : "secondary";
    const variant2 = step === "2" ? "primary" : "secondary";
    const variant3 = step === "3" ? "primary" : "secondary";

    const muted1 = step === "1" ? "" : "text-muted";
    const muted2 = step === "2" ? "" : "text-muted";
    const muted3 = step === "3" ? "" : "text-muted";

    return (
        <Row className="text-center border-top border-bottom p-3 bg-light mb-4">
            <Col className={muted1 + " text-end"}><h5 className="mb-0"><Badge pill bg={variant1}>1</Badge> {t('ord-items_choice')}</h5></Col>
            <Col xs="auto"><h4 className="mb-0">&gt;</h4></Col>
            <Col xs="auto" className={muted2 + " text-start"}><h5 className="mb-0"><Badge pill bg={variant2}>2</Badge> {t('ord-items_param')}</h5></Col>
            <Col xs="auto"><h4 className="mb-0">&gt;</h4></Col>
            <Col className={muted3 + " text-start"}><h5 className="mb-0"><Badge pill bg={variant3}>3</Badge> {t('ord-sn_summary')}</h5></Col>
        </Row>
    );
}
