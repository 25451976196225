import Form from "react-bootstrap/Form";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import BooleanDropdownRhf from "../comp/BooleanDropdownRhf";

interface DeliveryNotesWebFiltersProps {
  type: "PD" | "ND";
}

export interface DeliveryNotesWebFiltersValues {
  deliveryNoteCode?: string;
  orderCode?: string;
  partnerCode?: string;
  partnerName?: string;
  note?: string;
  issuedBy?: string;
  massFilter?: string;
  exported?: boolean;
  imported?: boolean;
  closed?: boolean;
  approved?: boolean;
  cancelled?: boolean;
}

export function DeliveryNotesWebFilters({ type }: DeliveryNotesWebFiltersProps) {
  const { t } = useTranslation();
  const form = useFormContext<DeliveryNotesWebFiltersValues>();
  const { register } = form;

  return (
    <tr>
      <th className="text-center">
        <Form.Group className="m-1" controlId="filterCode">
          <Form.Label>{t("doc_code")}</Form.Label>
          <Form.Control type="text" placeholder="&#128269; " {...register("deliveryNoteCode")} />
        </Form.Group>
      </th>
      <th className="text-center">
        <Form.Group className="m-1" controlId="filterOrder">
          <Form.Label>{t("deno-ord")}</Form.Label>
          <Form.Control type="text" placeholder="&#128269; " {...register("orderCode")} />
        </Form.Group>
      </th>
      <th className="text-center" style={{ paddingBottom: "13px" }}>
        <p className="mb-2">{type === "PD" ? t("deno-exported") : t("ord-imported")}</p>
        <BooleanDropdownRhf name={type === "PD" ? "exported" : "imported"} />
      </th>
      <th className="text-center">
        <Form.Group className="m-1" controlId="filterPartner">
          <Form.Label>{t("Partner")}</Form.Label>
          <Form.Control type="text" placeholder="&#128269; " {...register("partnerCode")} />
        </Form.Group>
      </th>
      <th className="text-center">
        <Form.Group className="m-1" controlId="filterPartnerName">
          <Form.Label>{t("biz-partner_name")}</Form.Label>
          <Form.Control type="text" placeholder="&#128269; " {...register("partnerName")} />
        </Form.Group>
      </th>
      <th className="text-center">
        <Form.Group className="m-1" controlId="filterNote">
          <Form.Label>{t("note")}</Form.Label>
          <Form.Control type="text" placeholder="&#128269; " {...register("note")} />
        </Form.Group>
      </th>
      <th>{type === "PD" ? t("deno-delivery_date") : t("deno-stockin_date")}</th>
      <th className="text-end">{t("biz-invoiced")} %</th>
      <th className="text-center" style={{ paddingBottom: "13px" }}>
        <p className="mb-2">{t("biz-closed")}</p>
        <BooleanDropdownRhf name="closed" />
      </th>
      <th className="text-center" style={{ paddingBottom: "13px" }}>
        <p className="mb-2">{t("deno-approved")}</p>
        <BooleanDropdownRhf name="approved" />
      </th>
      <th className="text-center" style={{ paddingBottom: "13px" }}>
        <p className="mb-2">{t("canceled")}</p>
        <BooleanDropdownRhf name="cancelled" />
      </th>
      <th className="text-center">
        <Form.Group className="m-1" controlId="filterWriter">
          <Form.Label>{t("deno-issuer")}</Form.Label>
          <Form.Control type="text" placeholder="&#128269; " {...register("issuedBy")} />
        </Form.Group>
      </th>
    </tr>
  );
}
