/**
 * Three components with loading spinners in three sizes with optional loading message.
 * @module comp/loading
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

//seen all - oK


/*
 * Loading spinner with optional message, compatibile with original Loading
 * former TightLoading is <Loading margin="0" />
 * former SpanLoading is <Loading margin="0" size="small" />
 * 
 * @param {string} message - optional text of label next to loading, default is "Načítám..."
 * @param {string} margin - space around loading 0-5 bootstrap style 
 * @param {string} size - size of loading, default is empry string, small makes small spinner
 * @returns {component}
 */
export function Loading({ message = "loading", size = "", margin = "5" }) {
	const { t } = useTranslation();
	
	const myMargin = margin ? "m-" + margin : "";
	const mySize = size === "small" ? "sm" : "";

	return (
		<span className={myMargin + " d-block"}>
			<Spinner
				as="span"
				animation="border"
				role="status"
				aria-hidden="true"
				variant="info"
				size={mySize}
			/>
			&nbsp;&nbsp;
			{t(message)}
		</span>
	);
}