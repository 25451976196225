import React, { useState } from "react";
import { Table, Row, Col, Image, Button, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { OkOrProblemsCardNO } from "../../comp/problems-card";
import { NO_PIN_generate } from "../../comp/pin";
import { make_product_check_tag } from "../../products/product-checks";
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from "../../products/product-view";
import { Emails } from "../../emails";
import { Texts, OtherUsersAlert, PurchaseOrderProcessedItem } from "./PurchaseOrder";
import { ErrorWrap } from "../../comp/errorwrap";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { final_states_SN } from "../progress-stockins-def";
import { Downloader } from "../../comp/downloader";
import { partnerName } from "../../lists/warehouses-defs";
import {
  NotificationsTableHead,
  NotificationsOfOrderPurchase,
  get_id_boolean,
  stockin_data_transformation,
  StockNotificationProcessed,
  is_stockin_problematic,
} from "../stockNotifications/StockinDisplayComms";
import { useTranslation } from "react-i18next";
import { useUserLogin } from "../../UserLoginProvider";
import { ItemOfNOPurchaseApp } from "./ItemOfNOPurchaseApp";
import { date_formatCZ, date_parseCzech } from "../../lib/date-utils";

//seen all - OK

function expandAll(allHidden, setDetailShown, stockNotifi) {
  const value = allHidden ? true : false;
  setDetailShown(get_id_boolean(stockNotifi, value));
}

interface PurchaseOrderDetailProps {
  order: Dto.GetPurchaseOrderDetailOrder;
  items: PurchaseOrderProcessedItem[];
  reloadIt: () => void;
  ErrorBadge: any;
  TodoList: any;
  worstCheckResult: any;
  emails: any;
  texts: any;
  everythingOK: any;
  showButton: any;
  noWarehouse: any;
  stockNotifi: Dto.GetPurchaseOrderDetailResponse["stockNotifications"];
  notifyOtherUsers: any;
  deliveryNO: any;
  //stockNotifiLogs: Dto.GetPurchaseOrderDetailResponse["stock_notifications_logs"];
}

export function PurchaseOrderDetail({
  order,
  items,
  reloadIt,
  ErrorBadge,
  TodoList,
  worstCheckResult,
  emails,
  texts,
  everythingOK,
  showButton,
  noWarehouse,
  stockNotifi,
  notifyOtherUsers,
  deliveryNO,
}: PurchaseOrderDetailProps) {
  const { t } = useTranslation();
  const userLogin = useUserLogin();

  const authorizedUser =
    userLogin.userInfo.hisSuperuser === true ||
    userLogin.userInfo.roleCompliance === true ||
    userLogin.userInfo.roleCsrHead === true;

  const remaining = items.map((item) => item.item.remainingQuantity);
  const allNotifiedNO = remaining.reduce((partial_sum, a) => partial_sum + a, 0) === 0;

  const currentStockNotifi = stockNotifi.filter((noti) => !final_states_SN.includes(noti.status));
  //const NOs = stockin_data_transformation(stockNotifi, stockNotifiLogs);
  const NOs_final = processStockNotifications(stockNotifi);
  //const current_NOs = stockin_data_transformation(currentStockNotifi, stockNotifiLogs);
  const current_NOs_final = processStockNotifications(currentStockNotifi);

  return (
    <ErrorWrap>
      <div className="mb-3">
        <Button size="sm" className="me-2 d-inline" onClick={reloadIt}>
          <Image src="/img/reload.svg" height="19" />
        </Button>
        <h3 className="d-inline align-middle">
          {t("ord-pur")} {order.orderCode} |<span>&nbsp;{t("partner")}&nbsp;</span>
          <LinkContainer to={{ pathname: "/partners/list/" + order.partnerCode + "/orders/purchase" }}>
            <a href="!#">{order.partnerCode}</a>
          </LinkContainer>
          <ErrorBadge tag={make_product_check_tag("hsh", "addresses")} />
          <ErrorBadge tag={make_product_check_tag("hsh", "address")} />
        </h3>
      </div>
      <Row>
        <Col xs={12} xl={8}>
          {authorizedUser ? (
            <Card className="mt-4 mb-4">
              <Card.Body className="p-3">
                <Row>
                  <Col>
                    <h5 className="d-inline mb-0">{t("ord-cards")}</h5>
                  </Col>
                  {["GW", "LF", "JP"].map((ww, idx) => (
                    <Col key={idx}>
                      <Downloader
                        img="/application-excel.svg"
                        alt="xlsx"
                        height="25"
                        label={partnerName[ww]}
                        path={"/api/orders/purchase/" + order.orderCode + "/card/" + ww}
                        filename={"karty-" + order.orderCode + "-" + ww + ".xlsx"}
                      />
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          ) : (
            <></>
          )}
          <SNOverview
            stockNotifi={currentStockNotifi}
            notifications={current_NOs_final}
            defaultExpanded
            currentSN
            authorizedUser={authorizedUser}
            reloadIt={reloadIt}
            showDaysToEtaCol
          />
        </Col>
        <Col xs={12} xl={4}>
          <OtherUsersAlert notifyOtherUsers={notifyOtherUsers ?? []} reloadIt={reloadIt} NOview />
          <OkOrProblemsCardNO
            NO={order.orderCode}
            //ok={everythingOK}
            headerOk={t("ord-you_can_notify")}
            textOk={t("ord-cards_ok_you_can_notify")}
            todo={<TodoList />}
            variant={worstCheckResult()}
            deliveryNO={deliveryNO}
            showButton={showButton}
            noWarehouse={noWarehouse}
            allNotifiedNO={allNotifiedNO}
          />
        </Col>
      </Row>
      <ItemsofNOPurchase items={items} authorizedUser={authorizedUser} ErrorBadge={ErrorBadge} />
      <Texts texts={texts} />
      <SNOverview
        stockNotifi={stockNotifi}
        notifications={NOs_final}
        authorizedUser={authorizedUser}
        reloadIt={reloadIt}
      />
      {emails.length > 0 ? (
        <>
          <Card className="mt-4 mb-4">
            <Card.Body className="px-0 pb-0">
              <Emails
                pageSize={5}
                existingEmails={emails}
                noFetch
                optionalHeader={t("ord-no_emails") + " " + order.orderCode}
              />
            </Card.Body>
          </Card>
        </>
      ) : (
        ""
      )}
    </ErrorWrap>
  );
}

export function processStockNotifications(
  stockNotifications: Dto.GetPurchaseOrderStockNotification[]
): StockNotificationProcessed["notifications"] {
  return stockNotifications.map((sn) => ({
    sn_id: sn.id,
    mp_Jmeno: sn.userName,
    sn_eta: date_formatCZ(sn.eta),
    sn_eta_parsed: sn.eta ? new Date(sn.eta) : null,
    sn_created_at: sn.created,
    sn_number: sn.number,
    days_to_eta: sn.eta ? etaDaysNumber(sn.eta) : null,
    extra_keys: undefined,
    nop_KodDokladu: sn.orderCode,
    problematic_solve_soon: is_stockin_problematic(sn.status, sn.recorded),
    sn_warehouse_id: sn.warehouseId,
    sn_created_by: sn.createdBy,
    sns_status: sn.status,
    sns_recorded: sn.recorded,
    u_username: sn.login,
    items: sn.items.map((item) => ({
      nop_KodMj: item.itemUnitOfMeasurementCode,
      nop_KodDokladu: sn.orderCode,
      nop_KodMjSkl: item.itemStorageUnitOfMeasurementCode,
      p_KodMjSkl: item.productStorageUnitOfMeasurementCode,
      p_KodProduktu: item.productCode,
      p_NazevProduktu: item.productName,
      pj_HmotnostMj: item.productUnitOfMeasurementWeight,
      pj_MjHmotnostiMj: item.productUnitOfMeasurementUnit,
      pjo_KodObalu: item.packageCode,
      sni_id: item.id,
      pjo_MnozstviSklMjVObalu: item.packageUnitOfMeasurementQuantity,
      sni_k_IDProduktu: item.productId,
      sni_nop_id: item.purchaseOrderItemId,
      batches: item.batches.map((batch) => ({
        snb_amount: batch.amount,
        snb_batch: batch.batch,
        snb_bbd: date_formatCZ(batch.bbd),
        snb_id: batch.id,
        extra_keys: undefined,
      })),
    })),
    history: sn.logs.map((history) => ({
      frf_data_size: history.ftpReceivedDataSize,
      frf_file_name: history.ftpReceivedFileName,
      frf_id: history.ftpReceivedId,
      fwr_data_size: history.ftpRequestDataSize,
      fwr_file_name: history.ftpRequestFileName,
      fwr_id: history.ftpRequestId,
      mp_Jmeno: history.userName,
      mp_KodPracovnika: history.userCode,
      recorded: history.recorded,
      u_username: history.login,
      nod_KodDokladu: history.orderCode,
      sns_changed_by: history.changedBy,
      sns_ftp_request_id: history.ftpRequestId,
      sns_invalidated: history.invalidated,
      sns_status: history.status,
      sns_received_file_id: history.ftpReceivedId,
      sns_sn_id: sn.id,
      status: history.status,
      sns_twist_nd: history.twistPurchaseOrder,
      sns_twist_response: history.twistResponse,
    })),
  }));
}

function etaDaysNumber(eta: string) {
  const days_diff_eta = Math.floor((new Date().getTime() - new Date(eta).getTime()) / 1000 / 60 / 60 / 24);
  //console.log("diff:" + days_diff_eta) ;
  return days_diff_eta;
}

interface ItemsOfNOPurchaseProps {
  items: PurchaseOrderProcessedItem[];
  authorizedUser: boolean;
  ErrorBadge: any;
}

function ItemsofNOPurchase({ items, authorizedUser, ErrorBadge }: ItemsOfNOPurchaseProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-none d-xl-block">
        <ItemsofNOPurchaseWeb items={items} authorizedUser={authorizedUser} ErrorBadge={ErrorBadge} />
      </div>
      <div className="d-xl-none">
        <Card className="m-0 p-0">
          <Card.Body className="m-0 p-1">
            <h3 className="mt-2 ms-2">{t("biz-items")}</h3>
            {items.map(function (item, idx) {
              return (
                <ItemOfNOPurchaseApp key={idx} item={item} authorizedUser={authorizedUser} ErrorBadge={ErrorBadge} />
              );
            })}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

/*
 * Overview of stockins at NO with expand button, its used twice - for currently processed and for history card
 *
 * @param {boolean} showDaysToEtaCol - display column of remaining days to eta
 * @returns {component}
 */
interface SNOverviewProps {
  stockNotifi: Dto.GetPurchaseOrderDetailResponse["stockNotifications"];
  notifications: StockNotificationProcessed["notifications"];
  currentSN?: boolean;
  defaultExpanded?: boolean;
  authorizedUser: boolean;
  reloadIt: () => void;
  showDaysToEtaCol?: boolean;
}

export function SNOverview({
  stockNotifi,
  notifications,
  currentSN = false,
  defaultExpanded = false,
  authorizedUser,
  reloadIt,
  showDaysToEtaCol = false,
}: SNOverviewProps) {
  const { t } = useTranslation();

  const expandedDetailShown = stockNotifi
    .map((sn) => sn.id)
    .filter((v, i, a) => a.indexOf(v) === i)
    .reduce((acc, v) => ({ ...acc, [v]: true }), {});
  const rightDetailShown = defaultExpanded ? expandedDetailShown : {};
  const [detailShown, setDetailShown] = useState(rightDetailShown);

  function toggleDetailShown(sn_id) {
    setDetailShown({ ...detailShown, [sn_id]: !detailShown[sn_id] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
  }

  const header = currentSN ? t("ord-stockin_in_process") : t("ord-stockin_history");
  const emptyMessage = currentSN ? t("ord-no_stockins_currently") : t("ord-no_stockins_ever");
  const allHidden = Object.keys(detailShown).length === Object.values(detailShown).filter((v) => v === false).length;

  return (
    <Card className="mt-4 mb-4">
      <Card.Body className="px-0 pb-0">
        <h5 className="mb-3 ps-3 d-inline">{header}</h5>
        {notifications.length === 0 ? (
          ""
        ) : (
          <Button
            size="sm"
            className="float-end mb-3 me-4"
            onClick={() => expandAll(allHidden, setDetailShown, stockNotifi)}
          >
            {allHidden ? (
              <>
                {t("expand")} <MdExpandMore />
              </>
            ) : (
              <>
                {t("hide")} <MdExpandLess />
              </>
            )}
          </Button>
        )}
        {stockNotifi.length === 0 ? (
          <p className="text-muted ps-3">{emptyMessage}</p>
        ) : (
          <Table className="border mb-0" size="sm">
            <NotificationsTableHead showDaysToEtaCol={showDaysToEtaCol} />
            <tbody>
              <NotificationsOfOrderPurchase
                key={currentSN ? 1 : 2}
                notifications={notifications}
                detailShown={detailShown}
                toggleDetailShown={toggleDetailShown}
                authorizedUser={authorizedUser}
                reloadIt={reloadIt}
                showDaysToEtaCol={showDaysToEtaCol}
                expandAllInNO={expandAll}
                is_NO_hidden={() => false}
                filterDaysToEta=""
              />
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
}

/*
 * Table of items for desktop.
 */
export function ItemsofNOPurchaseWeb({ items, authorizedUser, ErrorBadge }: ItemsOfNOPurchaseProps) {
  const { t } = useTranslation();
  return (
    <ErrorWrap>
      <Card className="mt-4 mb-4">
        <Card.Body className="px-0 pb-0">
          <h3 className="mb-2 ms-3">{t("biz-items")}</h3>
          <Table size="sm" className="striped-2-rows mb-0">
            <ItemsofNOPurchaseHeaderWeb authorizedUser={authorizedUser} />
            <tbody>
              {items.map(function (item) {
                return (
                  <React.Fragment key={item.itemId}>
                    <tr key={item.item.productCode}>
                      <td rowSpan={2} className={item.bg_item_green_red}></td>
                      <td rowSpan={2} className="align-middle">
                        {item.productId}
                        <ErrorBadge tag={make_product_check_tag(item.productId?.toString(), "IDProduktu-missing")} />
                        <ErrorBadge tag={make_product_check_tag(item.itemId?.toString(), "duplicity")} />
                      </td>
                      {authorizedUser ? (
                        <td rowSpan={2} className="align-middle">
                          {item.itemId}
                        </td>
                      ) : (
                        false
                      )}
                      <td rowSpan={2} className="align-middle">
                        <LinkContainer
                          to={{ pathname: "/products/" + encodeURIComponent(item.item.productCode) + "/view" }}
                        >
                          <a href="!#">{item.item.productCode}</a>
                        </LinkContainer>
                      </td>
                      <td rowSpan={2} className="align-middle text-end">
                        {item.item.quantity}&nbsp;{item.item.unitOfMeasurementCode}
                        <ErrorBadge tag={make_product_check_tag(item.itemId.toString(), "NO-unit-uniformity")} />
                      </td>
                      <td rowSpan={2} className="align-middle text-center">
                        {item.item.receivedQuantity}&nbsp;{item.item.unitOfMeasurementCode}
                      </td>
                      <td rowSpan={2} className="align-middle text-center">
                        {item.item.remainingQuantity}&nbsp;{item.item.unitOfMeasurementCode}
                      </td>
                      <td className="border-start">
                        HSH:
                        <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "twistApproval")} />
                        <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "hisApproval")} />
                        <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "configured")} />
                        <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "monthExpiration")} />
                      </td>
                      <td>{item.item.productName ? item.item.productName : <>&mdash;</>}</td>
                      <ProductContainerView
                        id={"hsh-" + item.productId}
                        container={item.hshPackage}
                        ErrorBadge={ErrorBadge}
                        border
                      />
                      <DensityAmountUnitWeightView
                        id={"hsh-" + item.productId}
                        density={item.unitOfMeasurementWeight}
                        densityUnit={item.unitOfMeasurementUnit}
                        amount={item.packageUnitOfMeasurementQuantity}
                        unit={item.storageUnitOfMeasurementCode}
                        packageWeight={item.package_weight_kg_hsh}
                        ErrorBadge={ErrorBadge}
                        border
                      />
                      <ProductADRView
                        id={"hsh-" + item.productId}
                        TridaADR={item.classAdr}
                        k_ADRObalovaSkupina={item.adrPackingGroup}
                        k_ADRUNCislo={item.adrUnNumber}
                        ErrorBadge={ErrorBadge}
                      />
                      {authorizedUser ? (
                        <td rowSpan={2} className="border-start align-middle text-center">
                          {NO_PIN_generate(item.itemId, 1)}
                        </td>
                      ) : (
                        false
                      )}
                    </tr>
                    <tr>
                      <td className="border-start">
                        {item.item.warehouseBookCode ? <>{item.item.warehouseBookCode}:</> : ""}
                        <ErrorBadge
                          tag={make_product_check_tag(
                            item.item.warehouseBookCode + "-" + item.productId,
                            "missing-card"
                          )}
                        />
                        <ErrorBadge
                          tag={make_product_check_tag(
                            item.item.warehouseBookCode + "-" + item.productId,
                            "missing-values"
                          )}
                        />
                        <ErrorBadge
                          tag={make_product_check_tag(
                            item.item.warehouseBookCode + "-" + item.productId,
                            "wh-existance"
                          )}
                        />
                      </td>
                      <td className={item.redish}>
                        {item.warehouseCard.productName ? item.warehouseCard.productName : <>&mdash;</>}
                      </td>
                      <ProductContainerView
                        id={"wh-" + item.item.warehouseBookCode + "-" + item.productId}
                        wid={item.item.warehouseBookCode}
                        container={item.warehouseCard.hshPackage}
                        remap
                        border
                        ErrorBadge={ErrorBadge}
                        bg={!item.is_card}
                      />
                      <DensityAmountUnitWeightView
                        id={"wh-" + item.item.warehouseBookCode + "-" + item.productId}
                        density={item.warehouseCard.unitOfMeasurementWeightInKg}
                        densityUnit="kg"
                        amount={item.warehouseCard.packageUnitOfMeasurementQuantity}
                        unit={item.warehouseCard.storageUnitOfMeasurementCode}
                        packageWeight={item.package_weight_kg_wh}
                        ErrorBadge={ErrorBadge}
                        bg={!item.is_card}
                        border
                      />
                      <ProductADRView
                        id={"wh-" + item.item.warehouseBookCode + "-" + item.productId}
                        TridaADR={item.warehouseCard.adrClass}
                        k_ADRObalovaSkupina={item.warehouseCard.adrUnNumber}
                        k_ADRUNCislo={item.warehouseCard.adrUnNumber}
                        romanized={true}
                        ErrorBadge={ErrorBadge}
                        bg={!item.is_card}
                      />
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </ErrorWrap>
  );
}

/**
 * Desktop header for items in order purchase detail
 *
 * @param {boolean} authorizedUser - if true, PIN is also displayed
 * @returns {component}
 */
function ItemsofNOPurchaseHeaderWeb({ authorizedUser }) {
  const { t } = useTranslation();
  return (
    <thead className="beGray">
      <tr>
        <th rowSpan={3} className="ps-3">
          #
        </th>
        <th rowSpan={3}>{t("prod-id")}</th>
        {authorizedUser ? <th rowSpan={3}>{t("ord-item_id")}</th> : false}
        <th rowSpan={3}>{t("code")}</th>
        <th rowSpan={3} className="text-end">
          {t("ord-amount_measure_unit")}
        </th>
        <th rowSpan={3} className="text-center">
          {t("ord-accepted_by_wh")}
        </th>
        <th rowSpan={3} className="text-center">
          {t("ord-remaining")}
        </th>
        <th colSpan={9} className="text-center border-start">
          {t("prod-prod_card")} <small>({t("prod-hsh_wh_comparison")})</small>
        </th>
        {authorizedUser ? (
          <th rowSpan={3} className="border-start text-center">
            PIN
          </th>
        ) : (
          false
        )}
      </tr>
      <tr>
        <th className="border-start" rowSpan={2}>
          {t("warehouse")}
        </th>
        <th rowSpan={2}>{t("name")}</th>
        <th colSpan={4} className="text-center border-end border-start">
          {t("prod-package")}
        </th>
        <th colSpan={3} className="text-center border-end">
          {t("prod-ADR")}
        </th>
      </tr>
      <tr className="beGray">
        <th className="text-center beGray border-start">{t("ord-pack")}</th>
        <th className="text-end beGray">{t("ord-content_w_unit")}</th>
        <th className="text-end beGray">{t("ord-density")}</th>
        <th className="text-end beGray border-end">{t("ord-1_pack_weight")}</th>
        <th className="text-center beGray">{t("prod-class")}</th>
        <th className="text-center beGray">{t("prod-pack-group")}</th>
        <th className="text-center beGray border-end">{t("ord-un_num")}</th>
      </tr>
    </thead>
  );
}
