/**
 * List of invoices received
 * @module invoices/invoices-received
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useCallback } from 'react';
import { Pager } from '../comp/pager';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { format_amount } from '../lib/format';
import { filter_rule } from '../lib/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { date_formatCZ } from '../lib/date-utils';
import { his_fetch } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { currencyTitles } from '../lists/currency_titles';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function InvoicesReceived({ userlogin }) {
	const { t } = useTranslation();

	const [invoices, setInvoices] = useState([]);
	const [totalCount, setTotalCount] = useState(null);
	const [oldest, setOldest] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterCode, setFilterCode] = useState("");
	const [filterPartner, setFilterPartner] = useState("");
	const [filterCurrency, setFilterCurrency] = useState("");
	const [massFilterApp, setMassFilterApp] = useState("");
	const [loadedStatus, setLoadedStatus] = useState(0);

	const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
		return (
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/invoices/received/range/" + startDate + "/" + endDate,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							if (result.error) {
								result_received(false);
							} else {
								setInvoices(invoicesCat => (reload ? [] : invoicesCat).concat(result.result));
								setTotalCount(result.total_count);
								setOldest(result.oldest.DatDokladu);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin])

	const cleanFilters = () => {
		setFilterCode("");
		setFilterPartner("");
		setFilterCurrency("");
		setMassFilterApp("");
	}

	const listinvoices = invoices.filter(
		function (inv) {
			return (
				(filter_rule(filterCode, inv.KodDokladu)) &&
				(filter_rule(filterPartner, inv.KodPartnera)) &&
				((filterCurrency === "") || (inv.KodMeny === filterCurrency)) &&
				((filter_rule(massFilterApp, inv.KodDokladu)) ||
					(filter_rule(massFilterApp, inv.KodPartnera)))
			);
		});
	const show_invoices = listinvoices.slice(offset, offset + 20);

	function handleChangeCode(event) {
		setFilterCode(event.target.value);
		setOffset(0);
	}

	function handleChangePartner(event) {
		setFilterPartner(event.target.value);
		setOffset(0);
	}

	function handleChangeCurrency(val) {
		setFilterCurrency(val);
		setOffset(0);
	}

	function handleChangeFilterApp(event) {
		setMassFilterApp(event.target.value);
		setOffset(0);
	}

	return (
		<ErrorWrap>
			<DynamicLoad period='1y' loadData={loadData}
				filtered_data={listinvoices} data={invoices}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount} oldest={oldest}
				cleanFilters={cleanFilters}
			/>

			<div className="d-none d-md-block">
				<Table size="sm" striped >
					<thead>
						<tr className="beGray">
							<th className="text-center pb-3 pt-2" scope="col">
								<p className="mb-1">{t('doc_code')}</p>
								<Form.Group controlId="filterCode">
									<Form.Control type="text" placeholder="&#128269;" value={filterCode} onChange={handleChangeCode} />
								</Form.Group>
							</th>
							<th className="text-center align-middle" scope="col">{t('date')}</th>
							<th className="text-center align-middle pb-3 pt-2" scope="col">
								<Form.Group controlId="filterPartner">
									<p className="mb-1">{t('partner')}</p>
									<Form.Control type="text" placeholder="&#128269;" value={filterPartner} onChange={handleChangePartner} />
								</Form.Group></th>
							<th className="align-middle text-end" scope="col">{t('biz-amount_money')}&nbsp;&nbsp;&nbsp;&nbsp;</th>
							<th className="align-middle pb-3 pt-2" scope="col">
								<p className="mb-1">{t('biz-currency')}</p>
								<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light">
									{Object.keys(currencyTitles)
										.map((title, idx) =>
											<Dropdown.Item key={idx} onClick={() => handleChangeCurrency(title)} >{t(currencyTitles[title])}</Dropdown.Item>
										)}
								</DropdownButton></th>
						</tr>
					</thead>
					<tbody>
						{show_invoices.map((inv) => <SingleInvoiceRow key={inv.KodDokladu} invoice={inv} />)}
					</tbody>
				</Table>
			</div>

			<InvoicesReceivedMobile
				show_invoices={show_invoices}
				massFilterApp={massFilterApp}
				handleChangeFilterApp={handleChangeFilterApp}
				currencyTitles={currencyTitles}
				filterCurrency={filterCurrency}
				handleChangeCurrency={handleChangeCurrency}
				offset={offset}
				setOffset={setOffset} />
			<LoadingDataInfo loadedStatus={loadedStatus} data={invoices} />
			<Pager offset={offset} pagesize={20} total={listinvoices.length} callback={setOffset} />
		</ErrorWrap>
	);
}


function SingleInvoiceRow({ invoice }) {
	return (
		<tr>
			<td>{invoice.KodDokladu}</td>
			<td className="text-center">{date_formatCZ(invoice.DatDokladu)}</td>
			<td className="text-center">{invoice.KodPartnera}</td>
			<td className="text-end">{format_amount(invoice.CastkaCelkemMen)}</td>
			<td>{invoice.KodMeny}</td>
		</tr>
	);
}

function SingleInvoiceRowMobile({ invoice }) {
	return (
		<tr>
			<td>
				<Row>
					<Col className="beBigger me-1">{invoice.KodDokladu}</Col>
					<Col className="text-end">{format_amount(invoice.CastkaCelkemMen)}&nbsp;{invoice.KodMeny}</Col>
				</Row>
				<Row>
					<Col >{date_formatCZ(invoice.DatDokladu)}</Col>
					<Col className="text-end">{invoice.KodPartnera}</Col>
				</Row>
			</td>
		</tr>
	);
}

function InvoicesReceivedMobile({ show_invoices, massFilterApp, handleChangeFilterApp, currencyTitles, filterCurrency, handleChangeCurrency }) {
	const { t } = useTranslation();
	return (
		<div className="d-md-none" >
			<br />
			<Row className="g-0">
				<Col>
					<p className="mb-1">{t('mass_filter')}</p>
					<Form.Group controlId="filterCodex" className='me-2'>
						<Form.Control type="text" placeholder={"🔍 " + t(('doc_code_partner'))} value={massFilterApp} onChange={handleChangeFilterApp} />
					</Form.Group>
				</Col>
				<Col xs='auto'>
					<p className="mb-1">{t('biz-currency')}</p>
					<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light" className='sto beGrayButton'>
						{Object.keys(currencyTitles)
							.map((title, idx) =>
								<Dropdown.Item key={idx} onClick={() => handleChangeCurrency(title)} >{t(currencyTitles[title])}</Dropdown.Item>
							)}
					</DropdownButton>
				</Col>
			</Row>
			<br />
			<Table striped >
				<tbody>
					{show_invoices.map((inv) => <SingleInvoiceRowMobile key={inv.KodDokladu} invoice={inv} />)}
				</tbody>
			</Table>
		</div>
	);

}