/**
 * Formatting functions 
 * @module lib/format
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from "react";

export function format_addZero(num) {
    if (num < 10) {
        return '0' + num;
    } else {
        return String(num);
    }
}

/* Is the function below good substitution?
export function format_amount_old(amt, use_hh = true) {
    if (amt === '—') {
        return amt;
    }
    var sign = '';
    if (amt < 0) {
        sign = '-';
    }
    amt = Math.abs(amt); //amount v absolutní hodnotě
    var cr = Math.floor(amt); //amount s floor - tady to asi ani nevadí..?
    var hh = Math.floor(100 * amt) % 100;
    if (hh < 10) {
        hh = '0' + hh;
    }
    var num = '';
    if (cr === 0) {
        num = '0';
    } else {
        while (cr > 0) {
            var o = cr % 1000;
            cr = Math.floor(cr / 1000);
            if (cr > 0) {
                if (o < 10) {
                    o = '00' + o;
                } else if (o < 100) {
                    o = '0' + o;
                }
            }
            if (num.length > 0) {
                num = String.fromCharCode(160) + num;
            }
            num = o + num;
        }
    }
    if (use_hh) {
        return sign + num + ',' + hh;
    }
    return sign + num;
}
*/

export function format_amount(number, decimal = true) {
    if (number === "-") {
        return "-";
    }

    const parsedNum = parseFloat(number);
    if (isNaN(parsedNum)) {
        return "-";
    }

    // Round the number to two decimal places
    const roundedNumber = Math.round(number * 100) / 100;

    // Convert the number to a string with two decimal places
    const formattedNumber = roundedNumber.toFixed(2);

    // Add a space as a thousands separator
    const parts = formattedNumber.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Join the parts back together with a comma for decimal separation, if decimal is requered
    if (!decimal) {
        return parts[0];
    } else {
        return parts.join(",");
    }
}



export function capitalize(str) {
    if (typeof str === 'string') {
        return str.replace(/^\w/, c => c.toUpperCase());
    } else {
        return '';
    }
}

/**
 * Highlights searched characters in string
 * 
 * @param {string} s - source string
 * @param {string} r - string to highlite
 * @returns {component}
 */
export function hilite(s, r) {
    try {
        const R = new RegExp(r, 'gi');
        return [s.split(R), s.match(R) || []]
            .map((a, i) => a.map((e, j) => [j * 2 + i, i === 1 ? (<span key={"aww" + (j * 2 + i)} className="bg-info-light">{e}</span>) : <span key={"aw" + (j * 2 + i)}>{e}</span>]))
            .flat()
            .sort((a, b) => a[0] < b[0] ? -1 : 1)
            .map((e) => e[1])
        // .join("")
    } catch (ex) {
        return s;
    }
}

/**
 * Removes all spaces and new lines inside and outside any string
 * 
 * @param {string} string 
 * @returns {string}
 */
export function remove_spaces_and_enters(string) {
    return string.trim().replaceAll(' ', '').replace(/[\r\n]/gm, '');
}

/**
 * Displays any string with enter behing each word, one letter words doesnt have enter
 * 
 * @param {string} text 
 * @returns {html}
 */
export function one_word_per_line(text) {
    if (!text) {
        return "";
    }
    const split = text.split(" ");
    //console.log(split);
    return split.map((word, idx) => word.length > 1 ? <React.Fragment key={idx}>{word} <br /></React.Fragment> : word + " ");
}