/**
 * List of emails.
 * @module emails
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useCallback } from 'react';
import { his_fetch } from './comp/FetchLoader';
import Table from 'react-bootstrap/Table';
import { date_formatCZ, date_time_format } from './lib/date-utils';
import { Pager } from './comp/pager';
import { LinkContainer } from 'react-router-bootstrap';
import Form from 'react-bootstrap/Form';
import { filter_rule } from './lib/utils';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ErrorWrap } from './comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from './comp/dynamic-load';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function Emails({ userlogin, pagesize = 20, existingEmails = [], noFetch = false, optionalHeader = "" }) {

    const [loadedStatus, setLoadedStatus] = useState(0);
    const [emails, setEmails] = useState(existingEmails);
    const [totalCount, setTotalCount] = useState(null);
    const [oldest, setOldest] = useState(null);
    const [offset, setOffset] = useState(0);
    const [filterTo, setFilterTo] = useState("");
    const [filterSubject, setFilterSubject] = useState("");
    const [filterCC, setFilterCC] = useState("");
    const [filterBCC, setFilterBCC] = useState("");
    const [filterSender, setFilterSender] = useState("");
    const [massFilterApp, setMassFilterApp] = useState("");

    const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
        //console.log(startDate, endDate);
        return (
            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/email/list/range/" + startDate + "/" + endDate,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            if (result.error) {
                                result_received(false);
                            } else {
                                setEmails(emailsCat => (reload ? [] : emailsCat).concat(result.result));
                                setTotalCount(result.total_count);
                                setOldest(result.oldest.sent);
                                result_received(true);
                            }
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            result_received(false);
                        }
                    }
                ]
            ))
    }, [userlogin])

    const cleanFilters = () => {
        setFilterTo("");
        setFilterSubject("");
        setFilterCC("");
        setFilterBCC("");
        setFilterSender("");
        setMassFilterApp("");
    }

    const handleChangeTo = function (event) {
        setFilterTo(event.target.value);
        setOffset(0);
    }

    const handleChangeSubject = function (event) {
        setFilterSubject(event.target.value);
        setOffset(0);
    }

    const handleChangeCC = function (event) {
        setFilterCC(event.target.value);
        setOffset(0);
    }

    const handleChangeBCC = function (event) {
        setFilterBCC(event.target.value);
        setOffset(0);
    }

    const handleChangeSender = function (event) {
        setFilterSender(event.target.value);
        setOffset(0);
    }

    const handleMassFilterApp = function (event) {
        setMassFilterApp(event.target.value);
        setOffset(0);
    }

    const emails_filtered = emails.filter(
        function (ema) {
            return (
                (filter_rule(filterTo, ema.to, true)) &&
                (filter_rule(filterSubject, ema.subject, true)) &&
                (filter_rule(filterCC, ema.cc, true)) &&
                (filter_rule(filterBCC, ema.bcc, true)) &&
                ((filter_rule(filterSender, ema.jmeno, true)) ||
                    (filter_rule(filterSender, ema.username, true))) &&
                ((filter_rule(massFilterApp, ema.to, true)) ||
                    (filter_rule(massFilterApp, ema.subject, true)) ||
                    (filter_rule(massFilterApp, ema.cc, true)) ||
                    (filter_rule(massFilterApp, ema.bcc, true)) ||
                    (filter_rule(massFilterApp, ema.jmeno, true)) ||
                    (filter_rule(massFilterApp, ema.username, true)))
            );
        }
    );

    const emails_list = emails_filtered.slice(offset, offset + pagesize);
    const temporaryMarginClass = noFetch ? " me-3 " : "";

    return (
        <ErrorWrap>
            {noFetch ?
                <>
                    <h5 className="d-inline ms-3 mb-2">{optionalHeader}</h5>
                    <Pager offset={offset} pagesize={pagesize} total={emails_filtered.length} callback={setOffset} className={temporaryMarginClass} />
                </>
                :
                <DynamicLoad period='1m' loadData={loadData}
                    filtered_data={emails_filtered} data={emails}
                    offset={offset} setOffset={setOffset}
                    totalCount={totalCount} oldest={oldest}
                    cleanFilters={cleanFilters} />
            }
            <EmailsWeb filterSender={filterSender} handleChangeSender={handleChangeSender} filterTo={filterTo} handleChangeTo={handleChangeTo}
                filterSubject={filterSubject} handleChangeSubject={handleChangeSubject} filterCC={filterCC} handleChangeCC={handleChangeCC}
                filterBCC={filterBCC} handleChangeBCC={handleChangeBCC} emails_list={emails_list} noFetch={noFetch} />
            <EmailsApp massFilterApp={massFilterApp} handleMassFilterApp={handleMassFilterApp} emails_list={emails_list} />
            <LoadingDataInfo loadedStatus={loadedStatus} data={emails} />
        </ErrorWrap>
    );
}

function EmailsApp({ massFilterApp, handleMassFilterApp, emails_list }) {
    const { t } = useTranslation();

    return (
        <ErrorWrap>
            <Form.Group controlId="filterSubject" className='d-md-none mb-3 mx-1'>
                <Form.Control type="text" placeholder={"🔍 " + t('sys-filter_from_to_subject_cc')} value={massFilterApp} onChange={handleMassFilterApp} />
            </Form.Group>
            <Table striped className="d-md-none">
                <tbody>
                    {emails_list.map(function (em, idx) {
                        const date_time = em.sent.split(" ");
                        return (
                            <LinkContainer key={idx} to={{ pathname: "/system/emails/" + em.id }} >
                                <tr>
                                    <td>
                                        <Row>
                                            <Col>
                                                <span className='text-muted'>{t('from-prep')}: </span>
                                                {em.jmeno || <>&mdash;</>}&nbsp;
                                                ({em.username || <>&mdash;</>})
                                            </Col>
                                            <Col xs="auto">{date_formatCZ(date_time[0])}</Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span className='text-muted'>{t('to_whom')}: </span>
                                                {em.to.map((a, idx) => <React.Fragment key={idx}><span key={idx} className="mb-0">{a}</span><br /></React.Fragment>)}
                                            </Col>
                                            <Col xs="auto">{date_time[1]}</Col>
                                        </Row>
                                        <span className='text-muted'>{t('sys-subject')}:</span>  {em.subject}
                                        <br />
                                        {em.cc.length !== 0 ?
                                            <>
                                                <span className='text-muted'>{t('sys-cc')}: </span>
                                                {em.cc.map((b, idx) => <span key={idx} className="mb-0">{b}<br /></span>)}
                                            </>
                                            : ""}
                                        {em.bcc.length !== 0 ?
                                            <>
                                                <span className='text-muted'>{t('sys-bcc')}: </span>
                                                {em.bcc.map((b, idx) => <span key={idx} className="mb-0">{b}<br /></span>)}
                                            </>
                                            : ""}
                                    </td>
                                </tr>
                            </LinkContainer>
                        );
                    })}
                </tbody>
            </Table>
        </ErrorWrap>
    );
}

function EmailsWeb({ filterSender, handleChangeSender, filterTo, handleChangeTo, filterSubject, handleChangeSubject, filterCC, handleChangeCC,
    filterBCC, handleChangeBCC, emails_list, noFetch }) {
    const { t } = useTranslation();

    const myPadding = noFetch ? " ps-3" : "";
    const myPadding2 = noFetch ? " pe-3" : "";
    return (
        <ErrorWrap>
            <Table hover size="sm" striped borderless className="d-none d-md-table">
                <thead className="beGray">
                    <tr>
                        <th className={myPadding + " py-3"}>
                            <Form.Group controlId="filterSubject">
                                <Form.Control type="text" placeholder={"🔍 " + t('sys-from')} value={filterSender} onChange={handleChangeSender} />
                            </Form.Group>
                        </th>
                        <th className='py-3'>
                            <Form.Group controlId="filterTo">
                                <Form.Control type="text" placeholder={"🔍 " + t('sys-to')} value={filterTo} onChange={handleChangeTo} />
                            </Form.Group>
                        </th>
                        <th className='py-3'>
                            <Form.Group controlId="filterSubject">
                                <Form.Control type="text" placeholder={"🔍 " + t('sys-subject')} value={filterSubject} onChange={handleChangeSubject} />
                            </Form.Group>
                        </th>
                        <th className="align-middle text-end pe-5">{t('date')}</th>
                        <th className='py-3'>
                            <Form.Group controlId="filterCC">
                                <Form.Control type="text" placeholder={"🔍 " + t('sys-cc')} value={filterCC} onChange={handleChangeCC} />
                            </Form.Group>
                        </th>
                        <th className={myPadding2 + " py-3"}>
                            <Form.Group controlId="filterBCC">
                                <Form.Control type="text" placeholder={"🔍 " + t('sys-bcc')} value={filterBCC} onChange={handleChangeBCC} />
                            </Form.Group>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {emails_list.map(function (em, idx) {
                        return (
                            <LinkContainer to={{ pathname: "/system/emails/" + em.id }} key={idx}>
                                <tr>
                                    <td className={myPadding}>
                                        {em.jmeno || <>&mdash;</>}&nbsp;
                                        ({em.username || <>&mdash;</>})
                                    </td>
                                    <td>{em.to.map((a, idx) => <p key={idx} className="mb-0">{a}</p>)} </td>
                                    <td>{em.subject}</td>
                                    <td className="text-end">{date_time_format(em.sent)}</td>
                                    <td>{em.cc.map((b, idx) => <p key={idx} className="mb-0">{b}</p>)}</td>
                                    <td>{em.bcc.map((c, idx) => <p key={idx} className="mb-0">{c}</p>)}</td>
                                </tr>
                            </LinkContainer>
                        );
                    })}
                </tbody>
            </Table>
        </ErrorWrap>
    );
}