/**
 * Common functions for partner insurance section
 * @module insurance/insurance-utils
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { format_amount } from '../lib/format';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
} from 'chart.js';
import { ErrorWrap } from '../comp/errorwrap';
import { rating_letters } from '../lists/rating_letters';
import { useTranslation } from 'react-i18next';
import { insurance_badges } from '../lists/insurance-badges';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

export function PartnerInsuranceStatus({ status, partner }) {
	const { t } = useTranslation();

	var message = t('unknown');
	const curr = status.CurrentLimitCurrency;
	var style1 = {};

	if (!status.CurrentLimit) {
		message = t('biz-non_insured_partner');
		style1 = { backgroundColor: "lightgrey" };
		return (
			<>
				<h5>{t('biz-insurance_state')}</h5>
				<div style={style1} className="p-m">
					<Row className="g-0">
						<Col xs="auto"><Badge pill bg="secondary">i</Badge>&nbsp;&nbsp;</Col>
						<Col>{message}</Col>
					</Row>
				</div>
			</>
		)
	}

	else if ((!(status.E1Days)) || (status.E1OK)) {
		if (status.RemainingLimit > 0) {
			message = t('ins-new_issued_invoices_will_be_insured_up_to') + " " + format_amount(status.RemainingLimit) + " " + curr + ".";
			style1 = { backgroundColor: "#a0c75c" };
		} else {
			message = t('ins-new_issued_inv_wont_be_insured_no_limit_left');
			style1 = { backgroundColor: "#ffbbbb" };

		}
	} else {
		message = t('ins-new_invoices_wont_be_insured_E1_invalid_unpaid_invoice') + " "
			+ status.E1Max + " " + t('ins-days_after_due_date_but_allowed_max_is') + " " + status.E1Days
			+ " " + t('ins-invoices_must_be_paid') + " " + status.E1Days + " " + t('ins-days_after_due_date');
		style1 = { backgroundColor: "#ffbbbb" };
	}
	return (
		<>
			<h5>{t('biz-insurance_state')}</h5>
			<div style={style1} className="p-m">
				<Row className="g-0">
					<Col xs="auto"><Badge pill bg="secondary">i</Badge>&nbsp;&nbsp;</Col>
					<Col>{message}</Col>
				</Row>
			</div>
			<div className="text-center">
				<div style={{ display: "inline-block" }}>
					<Table className="propTable" responsive style={{ width: "auto" }} size="sm" borderless>
						<tbody>
							<tr>
								<th className="text-end">{t('ins-FIC')}:</th>
								<td className="text-start">{partner.CisloSmlouvy}</td>
							</tr>
							<tr>
								<th className="text-end">{t('ins-approved_valid_limit')}:</th>
								<td className="text-end">
									{status.CurrentLimit ? format_amount(status.CurrentLimit) : "–"}
								</td>
								<td className="text-start">
									{status.CurrentLimit ? curr : ""}
								</td>
							</tr>
							<tr>
								<th className="text-end">{t('ins-gained_limit')}:</th>
								<td className="text-end">
									{status.UsedLimit ? format_amount(status.UsedLimit) : "–"}
								</td>
								<td className="text-start">
									{status.UsedLimit ? curr : ""}
								</td>
							</tr>
							<tr>
								<th className="text-end">{t('ins-remaining_limit')}:</th>
								<td className="text-end">
									{status.RemainingLimit ? format_amount(status.RemainingLimit) : "–"}
								</td>
								<td className="text-start">
									{status.RemainingLimit ? curr : ""}
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
}

export function PartnerRatingHistory({ history }) {

	var today = new Date();
	var ihistory = [];
	if (history) {
		ihistory = history.map(
			function (val) {
				return val;
			});
	}
	var months = [];
	for (var i in ihistory) {
		var j = ihistory.length - i - 1;
		var itoday = new Date(today.getFullYear(), today.getMonth() - j, 1);
		var month = itoday.getMonth() + 1;
		if (month < 10) {
			month = '0' + month;
		}
		var year = itoday.getFullYear();
		months[i] = '' + year + '-' + month;
	}


	var data = {
		labels: months,
		datasets: [
			{
				label: "Rating",
				data: ihistory,
				fill: true,
				borderColor: 'rgba(128,0,0,0.5)'
			}
		]
	};

	const options = {
		animation: {
			duration: 0
		},
		scales: {
			y: {
				beginAtZero: true,
				min: 0,
				max: 7,
				scaleLabel: {
					display: true,
					labelString: "Rating",
					fontSize: 16
				},
				ticks: {
					stepSize: 1,
					callback: function (value, index, values) {
						if ((value <= 6) &&
							(value >= 1)) {
							return rating_letters[value];
						} else {
							return "";
						}
					}
				}
			}
		}
	};
	return <ErrorWrap>
		<Line data={data} options={options} />
	</ErrorWrap>;
}


const detailSpecials = {
	"ins-valid_from": false,
	"ins-valid_to": false,
	"state": false,
	"ins-request_date": false,
	"ins-required_amount": false,
	"ins-approved_amount": false,
	"ins_credendo_agent": function (value) {
		const values = value.split("\n");
		var idx = 0;
		return (
			<>
				{
					values.map(function (val) {
						idx += 1;
						return (
							<React.Fragment key={idx}>
								{val}
								<br />
							</React.Fragment>
						);
					})
				}
			</>
		);
	}
};

export function filterDetail(detail) {
	const key = detail[0];
	if (detailSpecials[key] === false) {
		return false;
	}
	return true;
}

export function PartnerDetailLimitDetail({ idx, detail }) {
	const { t } = useTranslation();

	const key = detail[0];
	var value = detail[1];
	if ((key in detailSpecials) &&
		(typeof detailSpecials[key] === "function")) {
		value = detailSpecials[key](value);
	}
	return (
		<tr key={idx} className="propTable2">
			<th>{t(key)}:</th>
			<td>{value}</td>
		</tr>
	);
}

export function get_insurance_status(FIC, named_insured, auto_insured, auto_country_ok) {
	if (FIC === null) {
		// Autolimit
		if (auto_insured === true) {
			return 3;
		}
		if (auto_insured === false) {
			return 4;
		}
		if (!auto_country_ok) {
			return 4;
		}
	} else {
		// Named limit
		if (named_insured === true) {
			return 1;
		}
		if (named_insured === false) {
			return 2;
		}
	}

	// No information yet
	return 0;
}

export function Insured3({ status }) {
	const { t } = useTranslation();

	const item = insurance_badges.filter((itm) => itm.status === status);
	if (item.length > 0) {
		return <Badge bg={item[0].bg} text={item[0].color} className='beGray' style={{ width: "90px" }}>{t(item[0].label)}</Badge>;
	} else {
		return "Error";
	}
}

