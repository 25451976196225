/**
 * Normal configuration view editor.
 * @file
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { Button, Table, Form, Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { Formula, LeftFormulaElement } from './report-config-formula';
import { IdentFilterForm, MappingEditForm } from './report-config-forms';
import { filter_rule, filter_rules } from '../lib/utils';
import { computeFormulaValue } from './report-utils';
import { useTranslation } from 'react-i18next';
import { Pager } from '../comp/pager';
import { ItemsAndFiltered } from '../comp/dynamic-load';
import { existing_titles } from '../lists/reporting_titles';

/**
 * Component allowing the user to edit the configuration in normal
 * (forward) manner. Rows are destination ids and formulae can be
 * edited in each row. If not all destination ids are shown a form
 * for adding new ones is available as the last row.
 *
 * @param {any} config - the whole configuration dictionary of formulae
 * @param {any} setup - meta-configuration with source and destination identifiers
 * @param {function} updateConfig - function to update configuration
 * @param {boolean} reaonly - if true, no editing is allowed
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {array} allSrcIds - list of all possible srcIds based on fetched config and balances
 * @return {component}
 */
export function NormalConfig({
	config, setup, updateConfig, multi, readonly, srcToDst, allSrcIds
}) {
	const { t } = useTranslation();

	// Last active row - the only one where active formula elements do something
	const [activeDst, setActiveDst] = useState(null);
	const [filterSrc, setFilterSrc] = useState("");
	const [filterDst, setFilterDst] = useState("");
	const [filterExisting, setFilterExisting] = useState("1"); // 1 is all, 
	const [offset, setOffset] = useState(0);
	// Do not show all and allow adding new rows by default
	const [showAllDst, setShowAllDst] = useState(false);

	// Make sure all are present
	const configKeys = Object.keys(config).sort();
	const allDstIds = [...(setup.dstIds || []), ...configKeys]
		.filter((v, i, a) => a.indexOf(v) === i)
		.sort();

	// Used and remaining source ids
	const usedSrcIds = Object.keys(config)
		.reduce((acc, dstId) => [...acc, ...Object.keys(config[dstId])], [])
		.filter((v, i, a) => a.indexOf(v) === i);
	const availableSrcIds = multi ?
		(setup.srcIds || [])
		:
		(setup.srcIds || []).filter((v) => usedSrcIds.indexOf(v) < 0);

	// Either show all or only those which are present
	const showDstIds = showAllDst ? allDstIds : configKeys;

	//console.log(allDstIds);
	//console.log(configKeys);

	// Now filter that basic list
	const filteredDstIds = showDstIds
		.filter((dstId) =>
			filter_rule(filterDst, dstId, true) &&
			filter_rules(filterSrc, Object.keys(config[dstId] || {})) &&
			(
				(filterExisting === "1") ||
				((filterExisting === "2") && (Object.keys(config[dstId]).reduce((acc, srcId) => acc || (setup.srcIds.indexOf(srcId) < 0), false))) || //libovolný ze srcId není v setupSrcId a reduce vrátí true
				((filterExisting === "3") && (Object.keys(config[dstId]).reduce((acc, srcId) => acc || (setup.srcIds.indexOf(srcId) >= 0), false))) || // alespoň jeden účet ve vzorci existuje
				((filterExisting === "4") && (Object.keys(config[dstId]).reduce((acc, srcId) => acc && (setup.srcIds.indexOf(srcId) < 0), true))) || //only not existing
				((filterExisting === "5") && (Object.keys(config[dstId]).reduce((acc, srcId) => acc && (setup.srcIds.indexOf(srcId) >= 0), true))) //only existing
			)
			//filter rule for isExisting
			//odpovídá položka filtru isExisting a isNotExistting? - mezi id je apson jedno, co to splnuje
		);

	const slicedDstIds = filteredDstIds.slice(offset, offset + 10);

	// If showing only some, which are there remaining to be added?
	const availableDstIds = (setup.dstIds || []).filter((v) => configKeys.indexOf(v) < 0);

	// Makes sure the activated row truly is active
	function onRowActivated(newDstId) {
		setActiveDst(newDstId);
	}

	// Removes the whole formula from config
	function onRowDeleted(dstId) {
		updateConfig(dstId, false, false);
	}

	const cleanFilters = () => {
		setActiveDst(null);
		setFilterSrc("");
		setFilterDst("");
		setFilterExisting("1");
		setShowAllDst(false);
	}

	// The empty onChange handler is required but the flipping of
	// showAllDst is easier using onClick
	return (
		<>
			<Row>
				<Col xs="12" md="4" ></Col>
				<Col xs="12" md="4" className='text-center'>
					<ItemsAndFiltered filtered_data={filteredDstIds} data={showDstIds} cleanFilters={cleanFilters} itemsName="rep-destination_idents" />
				</Col>
				<Col xs="12" md="4" >
					<Pager offset={offset} pagesize={10} total={filteredDstIds.length} callback={setOffset} />
				</Col>
			</Row>
			<Table
				bordered>
				<thead className='beGray'>
					<tr>
						<th className="w-25">
							{t('rep-destination_ident')}
							<Form.Check label={t('rep-show_all_available')}
								checked={showAllDst}
								id="NormalConfigShowAllCheckId"
								onClick={(ev) => setShowAllDst(!showAllDst)}
								onChange={() => null}
								style={{ fontWeight: "normal" }} />
							<Form.Control type="text"
								placeholder="&#128269;"
								value={filterDst}
								onChange={(ev) => setFilterDst(ev.target.value)} />
						</th>
						<th>
							<Row>
								<Col xs="8">
									Vzorec
									<Form.Control type="text"
										placeholder="&#128269;"
										value={filterSrc}
										onChange={(ev) => setFilterSrc(ev.target.value)} />
								</Col>
								<Col>
									<p className="mb-0">{t('rep-existing_accounts')}</p>
									<DropdownButton className='sto' id="isExisting" title={t(existing_titles[filterExisting])} variant="light">
										<Dropdown.Item onClick={() => setFilterExisting("1")}>{t('all')}</Dropdown.Item>
										<Dropdown.Item onClick={() => setFilterExisting("2")}>{t('rep-non_existing')}</Dropdown.Item>
										<Dropdown.Item onClick={() => setFilterExisting("3")}>{t('rep-existing')}</Dropdown.Item>
										<Dropdown.Item onClick={() => setFilterExisting("4")}>{t('rep-not_existing_only')}</Dropdown.Item>
										<Dropdown.Item onClick={() => setFilterExisting("5")}>{t('rep-existing_only')}</Dropdown.Item>
									</DropdownButton>
								</Col>
							</Row>

						</th>
						{readonly ?
							<></> :
							<th className='text-center'>{t('rep-formula_action')}</th>
						}
					</tr>
				</thead>
				<tbody>
					{slicedDstIds.map((dstId, idx) =>
						<NormalConfigRow key={idx}
							ident={dstId}
							active={activeDst === dstId}
							onActivate={onRowActivated}
							srcIds={availableSrcIds}
							updateConfig={updateConfig}
							onDelete={onRowDeleted}
							unknown={(setup.dstIds || []).indexOf(dstId) < 0}
							setup={setup}
							readonly={readonly}
							srcToDst={srcToDst}
							multi={multi}
							formula={config[dstId] || {}}
							config={config}
							allSrcIds={allSrcIds} />)}
					{showAllDst || readonly ?
						<></> : <AddDstRow onActivate={onRowActivated}
							availableIds={availableDstIds} // this has to be checked
							updateConfig={updateConfig}
							activeDst={activeDst}
							config={config}
							allSrcIds={allSrcIds} />}
				</tbody>
			</Table>
		</>
	);
}

/**
 * This row is shown only if there are any remaining dstIds to be added.
 * @param {function} onActivate - activates this "virtual" config row so that others are collapsed
 * @param {string} activeDst - checks whether this is the active row (must be done within this component)
 * @param {array} availableIds - list of dstIds to select from
 * @param {function} updateConfig - with only random src and coef 0, it actually adds empty formula
 * @param {array} allSrcIds - list of all possible srcIds based on fetched config and balances
 * @return {component}
 */
function AddDstRow({ onActivate, activeDst, availableIds, updateConfig, config, allSrcIds }) {
	const { t } = useTranslation();
	// Something that will never appear as dstId
	const myId = 'AddDstRow-active-id';

	/*
	console.log("+++++++++AddDstRow+++++++");
	console.log(allSrcIds);
	*/

	// Used to track whether we are adding right now
	const [adding, setAdding] = useState(false);

	// Fast-path bail out if there is nothing to add (must be after useState anyway!)
	if (availableIds.length === 0) {
		return <></>;
	}

	// Are we active? This is a special case that cannot be handled in
	// parent component.
	const active = activeDst === myId;

	// Should we show the add form?
	const showAdding = active && adding;

	// Enforces row-collapsing behavior like regular config rows
	function onClickAdding() {
		if (!active) {
			onActivate(myId);
			setAdding(true);
		} else {
			setAdding(!adding);
		}
	}

	// When the user picks some dstId to be added
	function onIdSelected(newId) {
		setAdding(false);
		onActivate(null);

		// Just adds empty formula
		updateConfig(newId, 0, 0);
	}

	return (
		<tr>
			<td>
				<div>
					<Button variant={(showAdding ? "" : "") + 'info'}
						onClick={onClickAdding}
						title={t('rep-adds_dest_ident_to_config')}>
						{t('rep-add"')}
					</Button>
				</div>
				{showAdding ? <IdentFilterForm onChangeId={onIdSelected}
					availableIds={availableIds} config={config} activeDst={activeDst}
					allSrcIds={allSrcIds} /> : <></>}
			</td>
			<td></td>
			<td></td>
		</tr>
	);
}

/**
 * Single formula editor.
 * @param {string} ident - this formula left-hand side (dstId)
 * @param {any} formula - dictionary of srcId to coef mappings (the formula itself)
 * @param {boolean} active - true if this formula is actively edited right now
 * @param {function} onActivate - is called whenever this formula
 * wants to activate itself and typically sets this formula ident as
 * current one in the parent component
 * @param {function} updateConfig - function(dst, src, coef[, src2, coef2 ...])
 * @param {array} srcIds - list of all (or available, depending on parent component decision) source ids
 * @param {function} onDelete - function that gets called if user requests deleting this row (gets ident as its argument)
 * @param {boolean} unknown - if given dstId is only in config and not in list of allowed dstIds
 * @param {any} setup - meta-configuration
 * @param {boolean} readonly - if true, no editing available
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {boolean} multi - if true, multiple usage of srcIds is allowed
 * @param {dictionary} config - for SignOptions component, who has to offer option based on usage of srcIds in config
 * @param {array} allSrcIdd - list of all possible srcIds based on fetched config and balances
 * @return {component}
 */
function NormalConfigRow({ ident, formula, active, onActivate, updateConfig, srcIds, onDelete, unknown, setup, readonly, srcToDst, multi, config, allSrcIds }) {
	const { t } = useTranslation();
	// Current formula element being edited - valid only if active is true
	const [activeSrcId, setActiveSrcId] = useState(null);
	const [newEdit, setNewEdit] = useState(false);

	// Used only for new forms
	const [addingSign, setAddingSign] = useState(null);
	const [addingSrcId, setAddingSrcId] = useState(null);

	// Filter local formula's srcIds from given srcIds
	const formulaSrcIds = Object.keys(formula);
	const availableSrcIds = srcIds.filter((v) => formulaSrcIds.indexOf(v) < 0);

	// Sets this config row as active and sets given element as active
	function onClickElement(newSrcId) {
		setNewEdit(false);
		setActiveSrcId((newSrcId === activeSrcId) && active ? null : newSrcId);
		onActivate(ident);
		setAddingSign(null);
		setAddingSrcId(null);
	}

	// Sets this row as active and activates new element form
	function onAddButtonClick() {
		onActivate(ident);
		setActiveSrcId(null);
		setAddingSign(null);
		setAddingSrcId(null);
		setNewEdit(active ? !newEdit : true);
	}

	// Changes the sign of currently selected element
	function onClickSign(sign) {
		updateConfig(ident, activeSrcId, sign);
		setAddingSign(null);
		setAddingSrcId(null);
	}

	// Replaces current srcId with a new one in the formula, keeping coefficient
	function onChangeId(newSrcId) {
		updateConfig(ident, activeSrcId, 0, ident, newSrcId, formula[activeSrcId]);
		setActiveSrcId(newSrcId);
	}

	// Removes current srcId from this formula
	function onClickDelete(delSrcId) {
		updateConfig(ident, delSrcId, 0);
		setActiveSrcId(null);
	}

	function finishAddNew(newSrcIds, newSign) {
		const args = newSrcIds.reduce((acc, srcId) => [ident, srcId, newSign, ...acc], []);
		updateConfig(...args);
		setNewEdit(false);
		if (newSrcIds.length === 1) {
			setActiveSrcId(newSrcIds[0]);
		}
	}

	// Clicking a sign when adding new srcId
	function onClickNewSign(sign) {
		if ((addingSrcId) && (addingSrcId.length > 0)) {
			// Already selected srcId means the process is finished
			//updateConfig(ident, addingSrcId, sign);
			finishAddNew(addingSrcId, sign);
			// setNewEdit(false);
			// setActiveSrcId(addingSrcId);
		} else {
			// We setup the sign and wait for srcId
			setAddingSign(sign);
		}
	}

	// Picking srcId when adding a new one
	function onChangeNewId(newSrcId) {
		if ((addingSign) && (newSrcId.length > 0)) {
			// If we already knew the sign, we are done
			//updateConfig(ident, newSrcId, addingSign);
			finishAddNew(newSrcId, addingSign);
			// setNewEdit(false);
			// setActiveSrcId(newSrcId);
		} else {
			// We select the new id and wait for a sign
			setAddingSrcId(newSrcId);
		}
	}

	// May trigger re-render after undo action, but probably it is the safest option
	if ((activeSrcId !== null) && (formulaSrcIds.indexOf(activeSrcId) < 0)) {
		// showSrcEdit (below) cannot be true if given activeSrcId is
		// no longer in formula (such as after undo action).
		setActiveSrcId(null);
		onActivate(null);
	}

	// The following two mutually exclusive - that is enforced using
	// onClickElement and onAddButtonClick functions
	const showNewEdit = active && newEdit;
	const showSrcEdit = active && (activeSrcId !== null);

	// Removes all elements
	function flushFormula() {
		updateConfig(...Object
			.keys(formula)
			.reduce((acc, srcId) => [...acc, ident, srcId, 0], []));
	}

	return (
		<tr>
			<td>
				<Row>
					<Col>
						{readonly ?
							<></>
							:
							<Button variant="light" size="sm"
								onClick={() => onDelete ? onDelete(ident) : null}
								className="me-3 border"
								title={t('rep-deletes_ident') + " " + ident + " " + t('rep-from_config')}
							>
								&#x1f5d1; {t('rep-remove')}
							</Button>
						}
					</Col>
					<Col className="text-end">
						<LeftFormulaElement ident={ident} balancesOn={setup.balances}
							value={setup.balances ? computeFormulaValue(formula, setup.balances) : null}
							unknown={unknown} />
						=
					</Col>
				</Row>
			</td>
			<td>
				<div>
					<Formula formula={formula}
						onClick={onClickElement}
						current={active ? activeSrcId : null}
						setup={setup}
						srcToDst={srcToDst}
						multi={multi}
						editable={!readonly} />

				</div>
				{showSrcEdit ? <MappingEditForm onChangeSign={onClickSign}
					activeId={activeSrcId}
					availableIds={availableSrcIds}
					onChangeId={onChangeId}
					onDelete={onClickDelete}
					coef={formula[activeSrcId]}
					normalView={true}
					relatedId={ident}
					config={config}
					allSrcIds={allSrcIds}
				/> : <></>}
				{showNewEdit ? <MappingEditForm onChangeSign={onClickNewSign}
					onChangeId={onChangeNewId}
					coef={addingSign}
					activeId={addingSrcId}
					multi
					availableIds={availableSrcIds}
					normalView={true}
					relatedId={ident}
					config={config}
					allSrcIds={allSrcIds}
					conditionsDisabled
				/> : <></>}
			</td>
			{readonly ?
				<></> :
				<td className='text-end'>
					<Button variant={(showNewEdit ? "" : "") + "info"} size="sm"
						className="ms-3 mb-2"
						onClick={onAddButtonClick}
						title={t('rep-adds_new_source_ident_for_destination_ident') + " " + ident + "."}
					>
						{t('rep-add')}
					</Button>
					<Button variant="light" size="sm"
						className="ms-3 border"
						onClick={flushFormula}
						title={t('rep-deletes_whole_formula_for_ident') + " " + ident + "."}
					>
						&#x1f5d1;&nbsp;{t('rep-delete')}
					</Button>
				</td>
			}
		</tr>
	)
}
