/**
 * Dropdown allowing to set month and year.
 * @module comp/month-year-dropdown
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { mesice } from '../lists/months';
import Button from 'react-bootstrap/Button';
import { format_addZero } from '../lib/format';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Dropdown, which alloes to pick month and year
 * 
 * @param {date} today - value of picked date
 * @param {function} setToday - function for changing picked date
 * @param {array} years - array of years available
 * @param {array} months - array of months available
 * @param {date} minDate - minDate allowed - for disabling
 * @param {date} maxDate  - maxDate allowed - for didabling
 * @param {boolean} yearOnly - true, if only year can be picked
 * @param {boolean} withoutArrows - true, if user cant see arrows for moving there and back
 * @param {string} variant - color, default is info
 * @param {boolean} whiteStyle - whether white variant color scheme should be used
 * @returns {component}
 */
export function MonthYearDropdown({ today, setToday, years, months, minDate, maxDate,
    yearOnly = false, withoutArrows = false, variant = "info", whiteStyle = false }) {
    const { t } = useTranslation();

    const jmenoMesice = mesice[parseInt(today.getMonth())][1]; //jméno aktuálního měsíce 
    const platneMesice = mesice.filter((m) => months.indexOf(m[0]) >= 0); //měsíce, které jsou v months obsaženy
    // console.log(today);

    const handleYearChange = function (year, platneMesice) {
        const todayCopy = new Date(today);
        todayCopy.setDate(1);
        todayCopy.setMonth(parseInt(platneMesice[0][0]) - 1);
        todayCopy.setFullYear(year);
        setToday(todayCopy);
    }

    const handleMonthChange = function (month, year) {
        const todayCopy = new Date(today);
        todayCopy.setDate(1);
        todayCopy.setMonth(month);
        todayCopy.setFullYear(year);
        setToday(todayCopy);
    }

    function goToNextMonth() {
        const todayCopy = new Date(today);
        todayCopy.setDate(1);
        todayCopy.setMonth(todayCopy.getMonth() + 1);
        setToday(todayCopy);
    }
    function goToPrevMonth() {
        const todayCopy = new Date(today);
        todayCopy.setDate(1);
        todayCopy.setMonth(todayCopy.getMonth() - 1);
        setToday(todayCopy);
    }

    function goToNextYear() {
        const todayCopy = new Date(today);
        todayCopy.setDate(1);
        todayCopy.setFullYear(todayCopy.getFullYear() + 1);
        setToday(todayCopy);
    }
    function goToPrevYear() {
        const todayCopy = new Date(today);
        todayCopy.setDate(1);
        todayCopy.setFullYear(todayCopy.getFullYear() - 1);
        setToday(todayCopy);
    }

    var disabled = (today.getMonth() === new Date().getMonth()) && (today.getYear() === new Date().getYear());
    var disabled2 = (today.getMonth() <= new Date(minDate).getMonth()) && (today.getYear() === new Date(minDate).getYear());
    var disabled3 = (today.getYear() <= new Date(minDate).getYear());
    var disabled4 = (today.getYear() === new Date(maxDate).getYear());
    const whiteStyling =
        whiteStyle ?
            { backgroundColor: "white", borderColor: "#d8dde1", color: "#565d63" } : {};
    return (
        <>
            <ButtonGroup aria-label="Basic example">
                {yearOnly ?
                    withoutArrows ? "" : <Button size='sm' variant={variant} onClick={goToPrevYear} disabled={disabled3}>&#60;</Button>
                    :
                    <>
                        {withoutArrows ? "" : <Button size='sm' variant={variant} onClick={goToPrevMonth} disabled={disabled2}>&#60;</Button>}
                        <Dropdown as={ButtonGroup} align="end" className="d-inline-flex">
                            <Dropdown.Toggle variant={variant} style={whiteStyling} className='width7em' size='sm' id="dropdown-basic" >{t(jmenoMesice)}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    platneMesice.map(function (mesic) {
                                        const changeMesic = function () {
                                            handleMonthChange(mesic[0] - 1, today.getFullYear());
                                        }
                                        return (
                                            <Dropdown.Item key={mesic[0]} onClick={changeMesic}>{t(mesic[1])}</Dropdown.Item>
                                        );
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                }
                <Dropdown as={ButtonGroup} className="d-inline-flex">
                    <Dropdown.Toggle variant={variant} style={whiteStyling} id="dropdown-basic" size='sm' >{today.getFullYear()}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            years.map(function (rok) {
                                const changeYear = function () {
                                    handleYearChange(rok, platneMesice);
                                    if (rok === new Date(minDate).getFullYear()) {
                                        handleMonthChange(format_addZero(new Date(minDate).getMonth()), rok);
                                    }
                                    if (rok === new Date(maxDate).getFullYear()) {
                                        handleMonthChange(format_addZero(new Date(maxDate).getMonth()), rok);
                                    }
                                }
                                return (
                                    <Dropdown.Item key={rok} onClick={changeYear}>{rok}</Dropdown.Item>
                                );
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                {whiteStyle ?
                    <Button as={ButtonGroup} style={{ color: "#495057", backgroundColor: "#e9ecef", paddingLeft: "12px", paddingRight: "12px", paddingTop: "6px", cursor: "default" }} 
                    variant="light" disabled={true} size="sm" className=" d-inline-flex border">
                        <FaCalendarAlt style={{ fontSize: "118%", }} />
                    </Button>
                    : ""}
                {yearOnly ?
                    withoutArrows ? "" : <Button size='sm' variant={variant} onClick={goToNextYear} disabled={disabled4}>&#62;</Button>
                    :
                    withoutArrows ? "" : <Button size='sm' variant={variant} onClick={goToNextMonth} disabled={disabled}>&#62;</Button>
                }
            </ButtonGroup>
        </>
    );
}
