export const insurance_badges = [
    {
        status: 1,
        bg: "success",
        label: "insured",
        explanation: "ins-from_insurance",
    },
    {
        status: 2,
        bg: "danger",
        label: "not_insured",
        explanation: "ins-but_insurance_paid",
    },
    {
        status: 3,
        bg: "info",
        label: "insured",
        explanation: "ins-autolimit",
    },
    {
        status: 4,
        bg: "secondary",
        label: "no_insurance",
        explanation: "ins-uninsured_partner",
    },
    {
        status: 0,
        bg: "null",
        label: "unknown",
        explanation: "ins-sync_waiting",
        color: "dark"
    }

]