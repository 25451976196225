/**
 * Various components for use by debt and balance history (mobile app).
 * @module partners/partner-history-utils
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { delay_color } from '../lists/colors';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import notes_comms from '../notes/notes-comms.json';

//seen all - OK

export function DelayOrHold({ label, number }) {
	const style = {
		backgroundColor: delay_color(number)
	};
	if (number) {
		return (
			<Col className="barvy" style={style}>
				{label}: {number}
			</Col>
		);
	} else {
		return <Col />;
	}
}

export function TextIcon({ note, icon, noteClass }) {
	if (note) {
		const cls = "note-icon " + noteClass;
		return (
			<span className={cls}>
				<img src={icon} alt="" width="15" />
			</span>
		);
	} else {
		return <></>;
	}
}

export function PlainTextWithIcon({ label, note, icon, noteClass }) {
	if (note) {
		const cls = "note-icon " + noteClass;
		return (
			<div>
				<span className={cls}>
					<img src={icon} alt="" width="15" />
				</span>
				<span>
					{label} {note}
				</span>
			</div>
		);
	} else {
		return <></>;
	}
}

export function TextWithIcon({ label, note, icon, noteClass }) {
	if (note) {
		const cls = "note-icon " + noteClass;
		return (
			<ListGroup.Item>
				<span className={cls}>
					<img src={icon} alt="" width="15" />
				</span>
				<span>
					{label} {note}
				</span>
			</ListGroup.Item>
		);
	} else {
		return <></>;
	}
}


function get_note_type_icon(note_type) {
	const notedef = notes_comms[note_type];
	if (notedef) {
		return "/img/" + notes_comms[note_type]["icon"];
	}
	return "/img/note.svg";
}

function get_note_type_class(note_type) {
	const notedef = notes_comms[note_type];
	if (notedef) {
		return "rounded bg-" + notes_comms[note_type]["variant"];
	}
	return "rounded bg-danger";
}

export function NoteIcon({ note, note_type }) {
	if (note) {
		return <TextIcon note={note} icon={get_note_type_icon(note_type)} noteClass={get_note_type_class(note_type)} />;
	} else {
		return <></>;
	}
}

export function NoteWithIcon({ note, note_type }) {
	if (note) {
		return <TextWithIcon note={note} icon={get_note_type_icon(note_type)} noteClass={get_note_type_class(note_type)} />;
	} else {
		return <></>;
	}
}

export function PlainNoteWithIcon({ note, note_type }) {
	if (note) {
		return <PlainTextWithIcon note={note} icon={get_note_type_icon(note_type)} noteClass={get_note_type_class(note_type)} />;
	} else {
		return <></>;
	}
}
