import React, { useCallback, useEffect, useState } from "react";
import { DynamicLoadPaged, DynamicLoadPagedProps } from "../comp/DynamicLoadPaged";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Pager } from "../comp/pager";
import { ErrorWrap } from "../comp/errorwrap";
import { getReceivedFtpFiles } from "../api/ftpFiles";
import { queryStatusToLoadedStatus } from "../api/common";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { Downloader } from "../comp/downloader";
import { date_time_format } from "../lib/date-utils";
import { useQuery } from "@tanstack/react-query";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { MultipleSelectRhf } from "../comp/MultipleSelectRhf";

function getResultTitle(value: Dto.FtpActionResult) {
  if (value === "Success") return "yes";
  if (value === "Error") return "no";
  if (value === "Unknown") return "-";
  return "all";
}

function ftpResults(value?: boolean | null) {
  if (value === true) return "✔️";
  if (value === false) return "❌";
  return "-";
}

const defaultFilters: Dto.GetReceivedFtpRangePagedFilterRequest = {
  fileName: "",
  fileSize: "",
  actions: [],
  result: undefined,
};

export default function FtpReceivedPaged() {
  const [offset, setOffset] = useState<number>(0);
  const [from, setFrom] = useState<string>(undefined);
  const form = useForm<Dto.GetReceivedFtpRangePagedFilterRequest>({ mode: "onChange", defaultValues: defaultFilters });
  const { watch } = form;
  const [filter] = useDebounceFilterValues(watch, defaultFilters);

  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["deliveryNotes", from, offset, filter],
    queryFn: ({ signal }) => {
      if (!from)
        return Promise.resolve<Dto.GetReceivedFtpRangeResponseItemRangePagedResponse>({
          items: [],
          totalCount: 0,
          filteredCount: 0,
          rangeCount: 0,
          count: 0,
        });

      return getReceivedFtpFiles(from, 20, offset, filter, { signal });
    },
    initialData: {
      items: [],
      totalCount: 0,
      filteredCount: 0,
      rangeCount: 0,
      count: 0,
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);
  const isLoaded = loadedStatus === 2;

  const loadData = useCallback<DynamicLoadPagedProps["loadData"]>((rangeFrom, rangeTo) => {
    setFrom(rangeFrom);
  }, []);

  return (
    <ErrorWrap>
      <DynamicLoadPaged
        period="1m"
        loadData={loadData}
        reloadIt={() => refetch()}
        offset={offset}
        setOffset={setOffset}
        loadingState={loadedStatus}
        totalCount={data?.totalCount}
        filteredCount={data?.filteredCount}
        rangeCount={data?.rangeCount}
        cleanFilters={() => form.reset(defaultFilters)}
      />
      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          <Table size="sm">
            <thead className="beGray">
              <FtpReceivedPagedFilters />
            </thead>
            <tbody>
              {data?.items.map(function (f, idx) {
                const bg = idx % 2 === 0 ? "" : "beGray3";
                const events = f.events?.length > 0 ? f.events : [{}];
                const myRowSpan = events.length;
                return events.map(function (event, idxEvent) {
                  return (
                    <tr key={idxEvent} className={bg}>
                      {idxEvent === 0 ? (
                        <>
                          <td rowSpan={myRowSpan} className="text-center align-middle">
                            {f.fileName}&nbsp;
                            <Downloader
                              img="/download.svg"
                              height="20"
                              alt="pdf"
                              label={""}
                              path={"/api/ftp/get/received/" + f.id}
                              filename={f.fileName}
                            />
                          </td>
                          <td rowSpan={myRowSpan} className="text-end align-middle">
                            {date_time_format(f.receivedAt)}
                          </td>
                          <td rowSpan={myRowSpan} className="text-center align-middle">
                            {f.dataSize} B
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                      <td className="text-center">{event.trigger || "-"}</td>
                      <td className="text-center">{ftpResults(event.success)}</td>
                      <td className="text-end">{event.created ? date_time_format(event.created) : "-"}</td>
                      <td className="text-end">{event.started ? date_time_format(event.started) : "-"}</td>
                      <td className="text-end">{event.finished ? date_time_format(event.finished) : "-"}</td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </FormProvider>
      </TableFetchStatusOverlay>
      <Pager
        offset={offset}
        pagesize={20}
        disabled={isLoaded === false}
        total={data?.filteredCount}
        callback={setOffset}
      />
    </ErrorWrap>
  );
}

const actionTriggerNames = {
  trigger: "trigger",
  "jp-pickup": "jp-pickup",
  "jp-pickup-notification-reply": "jp-pickup-notification-reply",
  "gw-pickup-stockin": "gw-pickup-stockin",
  "lf-pickup-stocks": "lf-pickup-stocks",
  "gw-pickup": "gw-pickup",
  "gw-cards": "gw-cards",
  "lf-pickup-stockin": "lf-pickup-stockin",
  "lf-cards": "lf-cards",
  "jp-pickup-card-reply": "jp-pickup-card-reply",
  "gw-stocks": "gw-stocks",
  "jp-cards": "jp-cards",
  "gw-pickup-obd": "gw-pickup-obd",
  "jp-pickup-stockin": "jp-pickup-stockin",
  "lf-pickup-b": "lf-pickup-b",
  "jp-pickup-stocks": "jp-pickup-stocks",
  "jp-pickup-konf": "jp-pickup-konf",
};

function FtpReceivedPagedFilters() {
  const { t } = useTranslation();
  const { register, unregister, setValue, watch } = useFormContext<Dto.GetReceivedFtpRangePagedFilterRequest>();

  useEffect(() => {
    register("result");

    return () => {
      unregister("result");
    };
  }, [register, unregister]);

  const filterResult = watch("result");

  return (
    <>
      <tr>
        <th rowSpan={2} className="text-center align-middle">
          <Form.Label className="mb-1">{t("sys-filename")}</Form.Label>
          <Form.Group controlId="filterName">
            <Form.Control type="text" placeholder={"🔍 "} {...register("fileName")} />
          </Form.Group>
        </th>
        <th rowSpan={2} className="text-end align-middle pe-5">
          {t("sys-accepted")}
        </th>
        <th className="text-center align-middle" rowSpan={2}>
          <Form.Group controlId="filterSize">
            <Form.Label className="mb-1">{t("sys-size")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("fileSize", { pattern: /[!=<>]\d+/ })}
            />
          </Form.Group>
        </th>
        <th colSpan={6} className="text-center">
          {t("action")}
        </th>
      </tr>
      <tr>
        <th className="text-center ps-3">
          <p className="mb-1">{t("name")}</p>

          <MultipleSelectRhf values={actionTriggerNames} id="actions" withoutNumber />
        </th>
        <th className="text-center pb-2">
          <p className="mb-1">{t("rep-result")}</p>

          <DropdownButton id="filterResult" title={t(getResultTitle(filterResult))} variant="light">
            <Dropdown.Item onClick={() => setValue("result", null)}>{t("all")}</Dropdown.Item>
            <Dropdown.Item onClick={() => setValue("result", "Success")}>{t("yes")} ✔️</Dropdown.Item>
            <Dropdown.Item onClick={() => setValue("result", "Error")}>{t("no")} ❌</Dropdown.Item>
            <Dropdown.Item onClick={() => setValue("result", "Unknown")}>&ndash;</Dropdown.Item>
          </DropdownButton>
        </th>
        <th className="text-end ps-1 pb-2">{t("sys-created")}</th>
        <th className="text-end pb-2">{t("sys-triggered")}</th>
        <th className="text-end pb-2">{t("sys-finished")}</th>
      </tr>
    </>
  );
}
