/**
 * Button and handling for downloading text files.
 * @module comp/file-downloader
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import { Button, Image } from 'react-bootstrap';

/**
 * 
 * @param {strinng} text - content of the file 
 * @param {string} name - filename 
 * @returns {component}
 */
export function TextFileDownloader({ text, name }) {

    function downloadTextFile(text, name) {
        const a = document.createElement('a');
        const type = name.split(".").pop();
        a.href = URL.createObjectURL(new Blob([text], { type: `text/${type === "txt" ? "plain" : type}` }));
        a.download = name;
        a.click();
    }

    return (
        <Button onClick={() => downloadTextFile(JSON.stringify(text), name)}
            variant="light" className='float-end m-1'><Image height={40} src={"/generic_file.svg"} />&nbsp;{name}</Button>
    );
}