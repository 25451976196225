/**
 * Simple message wrapper
 * @module comp/resultmessage
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { Alert, Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

/*
 * Returns colored Alert with message and potentially reloadButton
 * 
 * @param {string} message - mesage to display, mandatory prop
 * @param {string} variant - color of alert, mandatory prop
 * @param {function} reloadButton - onClick function for reloadButton, optional prop
 * @returns {component}
 */
export function ResultMessage({ message, variant, reloadButton = () => undefined }) {
	const { t } = useTranslation();

	if ((message) && (message.length > 0)) {
		return (
			<Alert variant={variant} className="m-3">
				{message}
				{reloadButton ?
					<>&nbsp;&nbsp;<Button className="my-1" variant="light" size="sm" onClick={reloadButton}><Image src="/img/reload_black.svg" height="20" /> {t('load_again')}</Button></>
					: ""}
			</Alert>
		);
	} else {
		return <></>;
	}
}
