import { authorizedFetch } from "./common";

export function getEmails(
  from: string,
  take: number,
  skip: number,
  filter: Dto.GetEmailListPagedHandlerFilterRequest,
  options: RequestInit
) {
  return authorizedFetch<Dto.EmailArchiveItemRangePagedResponse>(
    `/api/email/list/range/${from}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    options
  );
}

export function getEmail(id: number, options: RequestInit) {
  return authorizedFetch<Dto.GetEmailDetailResponse>(`/api/email/view/${id}/new`, options);
}
