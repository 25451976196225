/**
 * Single partner views.
 * @module partners/partner-view
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PartnerDetail } from './partner-detail';
import { PartnerPaymentsHistory } from './partner-payments-history';
import { PartnerDebts } from './partner-debts';
import { PartnerBalanceHistory } from './partner-balance-history';
import { PartnerHeader } from './partner-header';
import { partner_get_acc_saldos, partner_get_insured_limit } from './partner-stats-utils';
import { PartnerInsurance } from './partner-insurance';
import { PartnerTabs } from './partner-tabs';
import { PartnerLiabilities } from './partner-liabilities';
import { PartnerOrdersSale } from './partner-orders-sale';
import { PartnerOrdersPurchase } from './partner-orders-purchase';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Image } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import {
	check_address,
	check_address_name, check_address_street, check_address_city, check_address_zip,
	check_address_country_code
} from '../products/product-checks';
import { make_checker } from '../comp/checker';
import { Rating } from '../comp/rating';
import { OkOrProblemsCard } from '../comp/problems-card';
import { ErrorWrap } from '../comp/errorwrap';
import { ResultMessage } from '../comp/resultmessage';
import { useTranslation } from 'react-i18next';

export function Partner({ userlogin, user, userinfo }) {
	const { t } = useTranslation();

	const { partnerCode } = useParams();

	const [partner, setPartner] = useState(null);
	const [addresses, setAddresses] = useState(null);
	const [persons, setPersons] = useState(null);
	const [payments, setPayments] = useState([]);
	const [debts, setDebts] = useState([]);
	const [currency, setCurrency] = useState(null);
	const [history, setHistory] = useState(null);
	const [liabilities, setLiabilities] = useState(null);
	const [saleOrders, setSaleOrders] = useState(null);
	const [purchaseOrders, setPurchaseOrders] = useState(null);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [loadedPaymentsStatus, setLoadedPaymentsStatus] = useState(0);
	const [loadedHistoryStatus, setLoadedHistoryStatus] = useState(0);
	const [loadedLiabilitiesStatus, setLoadedLiabilitiesStatus] = useState(0);
	const [loadedDebtsStatus, setLoadedDebtsStatus] = useState(0);
	const [loadedSaleOrdersStatus, setLoadedSaleOrdersStatus] = useState(0);
	const [loadedPurchaseOrdersStatus, setLoadedPurchaseOrdersStatus] = useState(0);


	const reloadIt = () => {
		setPartner(null);
		setAddresses(null);
		setPersons(null);
		setPayments([]);
		setDebts([]);
		setCurrency(null);
		setHistory(null);
		setLiabilities(null);
		setSaleOrders(null);
		setLoadedStatus(0);
		setLoadedPaymentsStatus(0);
		setLoadedHistoryStatus(0);
		setLoadedLiabilitiesStatus(0);
		setLoadedDebtsStatus(0);
		setLoadedSaleOrdersStatus(0);
	}

	useEffect(() => {
		if (partner === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/partners/" + partnerCode,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							//console.log(partnerCode);
							if (result.partner) {
								setPartner(result.partner);
							} else {
								setPartner("error");
							}
							setAddresses(result.addresses);
							setPersons(result.persons);
							// setDebts(result.debts);
							setCurrency(result.currency);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setPartner("error");
						}
					},
					{
						uri: "/api/partners/" + partnerCode + "/debt",
						json: true,
						status: setLoadedDebtsStatus,
						ok: function (resource, result) {
							setDebts(result.debts);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					},
					{
						uri: "/api/partners/" + partnerCode + "/liabilities",
						json: true,
						status: setLoadedLiabilitiesStatus,
						ok: function (resource, result) {
							setLiabilities(result.liabilities);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					},
					{
						uri: "/api/partners/" + partnerCode + "/payments",
						json: true,
						status: setLoadedPaymentsStatus,
						ok: function (resource, result) {
							setPayments(result.payments);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					},
					{
						uri: "/api/partners/" + partnerCode + "/history",
						json: true,
						status: setLoadedHistoryStatus,
						ok: function (resource, result) {
							setHistory(result.history);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					},
					{
						uri: "/api/partners/" + partnerCode + "/orders/sale",
						json: true,
						status: setLoadedSaleOrdersStatus,
						ok: function (resource, result) {
							//console.log(result.orders);
							setSaleOrders(result.orders);
							//console.log(saleOrders);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					},
					{
						uri: "/api/partners/" + partnerCode + "/orders/purchase",
						json: true,
						status: setLoadedPurchaseOrdersStatus,
						ok: function (resource, result) {
							//console.log(result);
							setPurchaseOrders(result.orders);
							//console.log(saleOrders);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					}
				]
			);
			return () => {
				// console.log("finito");
				running_fetch();
			}
		}
	}, [partnerCode, userlogin]); //partner tu záměrně není, protože jinak bych ten fetcher musela pracně přepsat, časem řešit jinak

	// console.log(loadedStatus);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	if (partner === "error") {
		var message = t('partner_loading_error') + ": '" + partnerCode + "'";
		return <ResultMessage message={message} variant="danger" reloadButton={reloadIt} />
	}

	const il = partner_get_insured_limit(partner);
	const insured_limit = il[0];
	const insured_currency = il[1];
	// console.log(debts);
	const acc_saldos = partner_get_acc_saldos(debts);
	const acc_saldos_all = payments !== null ? partner_get_acc_saldos(payments) : { avg1: -10000 };
	const tolerance = partner['k_ZpozdeniPlatebTolerance'];

	var usedLimit = 0;
	for (var di in debts) {
		const d = debts[di];
		if ((d[15]) || (d[17])) {
			usedLimit += d[1];
		}
	}

	const { performCheck, ErrorBadge, TodoList, checksOK, worstCheckResult } = make_checker();

	const checked_addresses = addresses.map(function (adr) {
		if (adr.TypAdresy === 0) {
			check_address(performCheck, "hsh" + adr.TypAdresy, addresses);
		}
		check_address_name(performCheck, "hsh" + adr.KodAdresy, adr.NazevAdresy);
		check_address_street(performCheck, "hsh" + adr.KodAdresy, adr.Adresa);
		check_address_city(performCheck, "hsh" + adr.KodAdresy, adr.Misto);
		check_address_zip(performCheck, "hsh" + adr.KodAdresy, adr.PSC);
		check_address_country_code(performCheck, "hsh" + adr.KodAdresy, adr.KodStatu);
		return {
			...adr,
		};
	})

	return (
		<ErrorWrap>

			<Row>
				<Col>
					<Button size="sm" className="me-2 mb-2" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
					<h3 className='d-inline mb-0 me-4'>{partner.NazevPartnera}</h3>
					<Rating rating={partner['Rating']} history={partner.RatingHistory} show_label={true} variant="horizontal" />
				</Col>
				<Col xs="auto" className='float-end'>
					<OkOrProblemsCard ok={checksOK()} headerOk={t('biz-warn_partner_ok')} textOk="" todo={<TodoList />} variant={worstCheckResult()} />
				</Col>
			</Row>


			<PartnerHeader name={partner["NazevPartnera"]}
				code={partnerCode}
				ic={partner.ICO}
				dic={partner.DIC}
				country={partner.KodStatu}
				rating={partner['Rating']}
				credit_limit={partner["KreditniLimit"]}
				internal_limit={partner["k_KreditniLimit2"]}
				insured_limit={insured_limit}
				insured_currency={insured_currency}
				acc_saldo={acc_saldos["saldo"]}
				acc_saldo_late={acc_saldos["lsaldo"]}
				acc_currency={currency}
				tolerance={tolerance}
				delay_avg={acc_saldos_all["avg1"]}
				hold_avg={acc_saldos["avg3"]}
				hold_max={acc_saldos["maxover2"]}
				rating_history={partner.RatingHistory}
				insured_saldo={usedLimit}
				reloadIt={reloadIt}
				todo={<TodoList />}
				variant={worstCheckResult()}
				ok={checksOK()}
			/>

			<PartnerTabs loadedDebtsStatus={loadedDebtsStatus}
				loadedLiabilitiesStatus={loadedLiabilitiesStatus}
				loadedPaymentsStatus={loadedPaymentsStatus}
				loadedHistoryStatus={loadedHistoryStatus}
				loadedSaleOrdersStatus={loadedSaleOrdersStatus}
				loadedPurchaseOrdersStatus={loadedPurchaseOrdersStatus}
			/>

			<Routes>
				<Route path='detail' element={
					<PartnerDetail user={user} userlogin={userlogin} userinfo={userinfo} partner={partner} addresses={checked_addresses} persons={persons} ErrorBadge={ErrorBadge} TodoList={TodoList} />
				} />
				<Route path='debt' element={
					<PartnerDebts partner={partner} debts={debts} currency={currency} loadedDebtsStatus={loadedDebtsStatus} reloadButton={reloadIt} />
				} />
				<Route path='insurance' element={
					<PartnerInsurance partner={partner} />
				} />
				<Route path='payments' element={
					<PartnerPaymentsHistory payments={payments} currency={currency} partner={partner} loadedPaymentsStatus={loadedPaymentsStatus} reloadButton={reloadIt} />
				} />
				<Route path='history' element={
					<PartnerBalanceHistory partner={partner} currency={currency} history={history} loadedHistoryStatus={loadedHistoryStatus} reloadButton={reloadIt} />
				} />
				<Route path='liabilities' element={
					<PartnerLiabilities partner={partner} currency={currency} liabilities={liabilities} loadedLiabilitiesStatus={loadedLiabilitiesStatus} reloadButton={reloadIt} />
				} />
				<Route path='orders/sale' element={
					<PartnerOrdersSale partner={partner} saleOrders={saleOrders} loadedSaleOrdersStatus={loadedSaleOrdersStatus} reloadButton={reloadIt} />
				} />
				<Route path='orders/purchase' element={
					<PartnerOrdersPurchase partner={partner} purchaseOrders={purchaseOrders} loadedPurchaseOrdersStatus={loadedPurchaseOrdersStatus} reloadButton={reloadIt} />
				} />
			</Routes>
		</ErrorWrap>
	);
}
