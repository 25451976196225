/**
 * Report computation utilities.
 * @file
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */

/**
 * For distinguishing booked and all transactions in reporting url are used short strings "any" and "acc", this function gives longer string for the report filename
 * 
 * @param {string} urlPart - dictionary representing the formula
 */
export function get_booked_string_for_filename(urlPart) {
	if (urlPart === "any") {
		return "all";
	} else if (urlPart === "acc") {
		return "rep-booked";
	} else {
		return "";
	}
}



/**
 * Computes given formula value from the balances provided.
 *
 * @param {any} formula - dictionary representing the formula
 * @param {any} balances - balances of source identifiers
 * @return {number} - the computed formula value
 */
export function computeFormulaValue(formula, balances) {
	return Object.keys(formula)
		.reduce((acc, id) => {
			const element = formula[id];
			const sign = getFormulaElementSign(element);
			const condition = getFormulaElementCondition(element);
			const balance = balances[id] || 0;
			const value = applyFormulaElementCondition(balance, condition);
			return acc + sign * value;
		}, 0);
}

/**
 * Returns -1 for negative and +1 for positive sign. Discards the
 * absolute value of its argument (that has to interpreted elsewhere).
 *
 * @param {number} element - element representing condition and sign
 * @return {number} - just the sign
 */
export function getFormulaElementSign(el) {
	if (el < 0) {
		return -1;
	} else if (el > 0) {
		return 1;
	} else {
		return null;
	}
}

/**
 * Returns the condition number.
 *
 * @param {number} element - number representing condition and sign
 * @return {number} - just the condition.
 */
export function getFormulaElementCondition(el) {
	return Math.abs(el);
}

/**
 * Applies the condition and returns conditional result.
 *
 * @param {number} balance - the balance from sources
 * @param {number} condition:
 *   - 1 - always
 *   - 2 - only when >= 0
 *   - 3 - only when negative
 * @return {number} - conditional result
 */
function applyFormulaElementCondition(balance, condition) {
	switch (condition) {
		case 1: // Include unconditionally
			return balance;
		case 2: // Only when >= 0
			if (balance >= 0) {
				return balance;
			}
			return 0;
		case 3: // Only when < 0
			if (balance < 0) {
				return balance;
			}
			return 0;
		default:
			return null;
	}
}

/**
 * Returns available conditions for given srcId within dstId in given config.
 *
 * @param { any } config - dictionary of dstId to formula
 * @param { string } dstId - currently edited formula identified by dstId
 * @param { string } srcId - srcId for which we want to know available conditions
 * @return { array } - available conditions
 */
export function getAvailableFormulaElementConditions(config, dstId, srcId) {
	const usedConditions = Object.keys(config) // All formulae
		.filter((cfgDstId) => cfgDstId !== dstId) // All other than dstId
		.reduce((acc, dstId) => config[dstId][srcId] ? // If present, mark
			{
				...acc, // Keep other marks
				[getFormulaElementCondition(config[dstId][srcId])]: true
			} : acc, {}); // Not present
	//console.log(usedConditions);
	if ((usedConditions[1]) || // Used unconditionally
		((usedConditions[2] && usedConditions[3]))) { // Used with both conditions
		// No other usage possible
		return [];
	}
	if (usedConditions[2]) {
		// "When positive" used, available "when negative"
		return [3];
	}
	if (usedConditions[3]) {
		// "When negative" used, available "when positive"
		return [2];
	}
	// All available
	return [1, 2, 3];
}

/**
 * Returns list of available ids for editing given formula element.
 *
 * @param { array } allSrcIds - array of all source ids (strings)
 * @param { any } config - mapping configuration - a dictionary of dstId to formula
 * @param { string } dstId - currently edited formula destination
 * @param { string } srcId - currently edited formula element source
 * @return { array } - array of srcIds to select from for this element
 */
export function getAvailableFormulaElementSrcIds(allSrcIds, config, dstId, srcId) {
	//console.log(Object.keys(config));
	const usedConditions = Object.keys(config) // All formulae
		// .filter((cfgDstId) => cfgDstId !== dstId) // All other than currently edited dstId
		.reduce((dstAcc, cfgDstId) => Object.keys(config[cfgDstId]) // Single formula
			// .filter((cfgSrcId) => (cfgSrcId !== srcId) && (cfgDstId !== dstId))
			.reduce((srcAcc, srcId) => ({
				...srcAcc,
				[srcId]: {
					...(srcAcc[srcId] || {}),
					[getFormulaElementCondition(config[cfgDstId][srcId])]: true
				}
			}), dstAcc), {});
	return allSrcIds
		.filter((srcId) => usedConditions[srcId] ?
			!(usedConditions[srcId][1] || (usedConditions[srcId][2] && usedConditions[srcId][3]))
			: true);
}

/**
 * Returns a list of dstIds to offer when editing this srcId in
 * transposed configuration. As there is no other canonical list of
 * dstIds than configuration (dstIds without any formula should
 * contain empty formula), this should be enough. But who knows...
 *
 * We take all dstIds and remove those that contain given srcId. The
 * current dstId must always be added by the component that uses this
 * function.
 *
 * @param { any } config - mapping configuration
 * @param { string } srcId - that is currently edited in transposed mode
 * @return { array } - array of dstIds to select from for this element
 */
export function getAvailableFormulaElementDstIds(config, srcId) {
	return Object.keys(config)
		.filter((dstId) => !config[dstId][srcId]);
}

/**
 * Returns true if given value satisfies given condition.
 *
 * @param {number} condition - 1,2,3 condition representation
 * @param {number} value - the value to check
 * @return {boolean} - true if the condition holds
 */
export function isFormulaElementConditionFulfilled(condition, value) {
	switch (condition) {
		case 1:
			return true;
		case 2:
			return value >= 0;
		case 3:
			return value < 0;
		default:
			// Should not happen
			return null;
	}
}

/**
 * Returns true if this srcId is not used or used only partially.
 *
 * @param { any } srcToDst - mapping of srcId to partial config
 * @param { string } srcId - the source id in question
 * @return { boolean } - true if this is not enough
 */
export function isSrcIdUsedInsufficiently(srcToDst, srcId) {
	// Only formulae that contain this srcId
	const thisConfig = srcToDst[srcId] || {};

	// If none, that is insufficient
	if (Object.keys(thisConfig).length === 0) {
		// No mapping at all
		return true;
	}

	const cc = getSrcIdConditionCounts(srcToDst, srcId);
	const ok = (((cc[1] === 1) && (cc[2] === 0) && (cc[3] === 0)) ||
		((cc[1] === 0) && (cc[2] === 1) && (cc[3] === 1)));
	return !ok;
}

/**
 * Returns a dictionary with three keys: 1, 2, 3 where the values
 * represent the number of repetitions of particular srcId with given
 * condition in the configuration.
 *
 * @param { any } srcToDst - mapping of srcId to partial config
 * @param { string } srcId - the source id in question
 * @return { any } - the dictionary with condition counts
 */
export function getSrcIdConditionCounts(srcToDst, srcId) {
	const thisDsts = srcToDst ? srcToDst[srcId] : {};
	const cc = Object.keys(thisDsts || {})     // conditionsCounts
		.reduce((acc, dstId) => {
			const condition = getFormulaElementCondition(thisDsts[dstId][srcId]);
			return {
				...acc,
				[condition]: (acc[condition] || 0) + 1
			}
		},
			{
				1: 0,
				2: 0,
				3: 0
			});
	return cc;
}
