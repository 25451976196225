import React, { useState, useEffect } from "react";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import { Pager } from '../comp/pager';
import { filter_rule, icompare, get_dict_for_multiselect, date_compare, is_date_comparable } from '../lib/utils';
import { ItemsAndFiltered } from "../comp/dynamic-load";
import { date_formatCZ } from "../lib/date-utils";
import { SortIcons } from '../comp/sort';
import { format_amount } from "../lib/format";
import { useTranslation } from 'react-i18next';
import { hilite } from "../lib/format";
import { MultipleSelect } from '../comp/multiple-select';
import { MyInfo } from '../comp/info-badge';
import {
    unique_sorted_product_ids, get_batch_weight_kg_twist, get_package_count_twist
} from "./product-utils";
import { EmbeddedNotes } from "../notes/embedded-notes";
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

/**
 * Return array of notes for specific batch within specific product
 * 
 * @param {*} batchString - specific batch string
 * @param {*} IDProduktu - Product id for specific product
 * @param {*} notes - all notes for batches
 * @returns {array}
 */
function get_notes_per_batch(batchString, IDProduktu, notes) {
    const usable_notes = typeof (notes) !== "object" ? [] : notes;
    // console.log(usable_notes);
    // console.log(typeof(notes));
    const notes_per_product = usable_notes.filter((note) => String(note.object_code) === String(IDProduktu));
    const notes_per_batch = notes_per_product.filter((note) => note.object_code2 === batchString);
    const sorted_notes = notes_per_batch.sort((a, b) => new Date(b.created) - new Date(a.created));
    return sorted_notes;
}

/**
 * Main structure of data for "Time in-stock" table, grouped by Product ID.
 * 
 * @param {any} records - all single record of an items in warehouses
 * @returns {array}
 */
function preprocess_timeInStock_data(records, notes) {
    const unique_product_ids_sorted = unique_sorted_product_ids(records, "sth_k_IDProduktu");

    const product_batches_tree = unique_product_ids_sorted.reduce(function (acc, productID) {
        const oneProductStocksData = records.filter((rec) => rec.sth_k_IDProduktu === productID);
        //console.log(productData);
        return ({
            ...acc,
            [productID]: oneProductStocksData,
        })
    }, {});

    const TreeArrayPerBatch =
        unique_product_ids_sorted.map(function (IDProduktu) {
            const this_product = product_batches_tree[IDProduktu]; //všechny batches per jedno IDProduktu
            const one_batch = this_product[0]; //první batch z produktové série

            return ({
                IDProduktu: IDProduktu,
                product_name: one_batch.p_NazevProduktu,
                product_code: one_batch.p_KodProduktu,
                package: one_batch.co_k_HSHObal5,
                centre: one_batch.p_KodSkupiny6,
                batches: process_stocks_batches(this_product, notes), // ??.sort((a, b) => compare_values(a, b, "expiry", direction === "up" ? 1 : -1)), //local sorting of expirations within batches on product
                product_td_rowspan: this_product.length,
            })
        })
    // ??  .sort((a, b) => compare_values(a.batches[0], b.batches[0], sort_key, direction === "up" ? 1 : -1)) //sorting according to expiry globaly
    return TreeArrayPerBatch;
}

/**
 * 2nd level structure of data for "Time in-stock" - all batches per product grouped by batch string.
 * 
 * @param {any} batches - all batches on one product
 * @returns {array}
 */
function process_stocks_batches(batches, notes /*, direction, sort_key */) {
    const unique_batches = unique_sorted_product_ids(batches, "ps_KodSerie");
    const batches_tree = unique_batches.reduce(function (acc, batchString) {
        const oneBatchData = batches.filter((rec) => rec.ps_KodSerie === batchString);
        return ({
            ...acc,
            [batchString]: oneBatchData,
        })
    }, {});
    const TreeArrayOfBatches = unique_batches.map(function (batchString) {
        const this_batch = batches_tree[batchString]; //všechny záznamy per jedden batchstring
        const one_batch_item = this_batch[0]; //první záznam z série jedné šarže
        /*
         const sort_it = direction && sort_key;
         const batches_in_whs = sort_it ? this_batch.sort((a, b) => compare_values(a, b, sort_key, direction === "up" ? 1 : -1)) : this_batch; //third level of sorting based on last update, only if we call the function with params direction and sort_key
         */
        return ({
            alf_batch: batchString,
            expiry: one_batch_item.ps_DatExpirace,
            batches_in_whs: process_stocks_batches_stockin_dates(this_batch), // batches_in_whs, //one batch can be in multiple warehouses
            batch_td_rowspan: this_batch.length,
            notes: get_notes_per_batch(batchString, one_batch_item.sth_k_IDProduktu, notes)
        })
    })
    return TreeArrayOfBatches;
}

/**
 * 3rd level structure of data for "Time in-stock" - all stockin_date items groupped by warehouse ID.
 * 
 * @param {any} one_batch - all items on one batch on one product, 
 * @returns {array}
 */
function process_stocks_batches_stockin_dates(one_batch) {
    const unique_warehouses_per_batch = unique_sorted_product_ids(one_batch, "wid");
    const wids_tree = unique_warehouses_per_batch.reduce(function (acc, wid) {
        const oneWarehouseBatchData = one_batch.filter((rec) => rec.wid === wid);
        return ({
            ...acc,
            [wid]: oneWarehouseBatchData,
        })
    }, {});
    const TreeArrayOfBatchDatesInWarehouses = unique_warehouses_per_batch.map(function (wid) {
        const this_warehouse = wids_tree[wid]; //všechny záznamy per jedden batchstring
        //const one_warehouse_item = this_warehouse[0]; //první záznam
        return ({
            wid: wid,
            stocks_in_one_wh: add_calculations_to_date_in_wh(this_warehouse), // one wh can have multiple stocking dates of one batch
            warehouse_td_rowspan: this_warehouse.length
        })
    })
    //console.log(TreeArrayOfBatchDatesInWarehouses);
    return TreeArrayOfBatchDatesInWarehouses;
}

/**
 * Adds extra keys to given dictionary. In this case, quantity_in_kg and pieces are calculated and added per record
 * 
 * @param {dictionary} records_in_one_warehouse 
 * @returns {dictionary}
 */
function add_calculations_to_date_in_wh(records_in_one_warehouse) {
    return records_in_one_warehouse.reduce((acc, record) => //this is how we add keys to dicts in array of dicts!!!!!!
        [...acc,
        {
            ...record,
            quantity_in_kg: get_batch_weight_kg_twist(record),
            pieces: get_package_count_twist(record)
        }],
        []);
}

/**
 * Main component with fetch for the section Time in-stock.
 * 
 * @param {any} userlogin - info about logged user
 * @param {string} fetchDate - date for url
 * @returns {component}
 */
export function TimeInStock({ userlogin, fetchDate }) {

    const [stocksData, setStocksData] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [fetchedNotes, setFetchedNotes] = useState([]);
    const [loadedStatus2, setLoadedStatus2] = useState(0);

    const reloadIt = () => {
        setStocksData(null);
        setLoadedStatus(0);
    }

    useEffect(() => {
        if (stocksData === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stocks/packs/all/" + fetchDate,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setStocksData(result.records);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setStocksData("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        } else {
            const second_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stocks/packs/notes/" + fetchDate,
                        json: true,
                        status: setLoadedStatus2,
                        ok: function (resource, result) {
                            //console.log(result);
                            setFetchedNotes(result.notes);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setFetchedNotes("error");
                        }
                    }
                ]
            )
            return () => {
                second_fetch();
            }

        }
    }, [userlogin, stocksData, fetchDate]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    return (
        <TimeInStockTable records={stocksData} userlogin={userlogin} notes={fetchedNotes} loadedStatusNotes={loadedStatus2} />
    )
}

/**
 * Table of Time in-stock with filters
 * 
 * @param {any} records 
 * @param {any} userlogin - needed for notes component - posting notes
 * @returns {component} 
 */
function TimeInStockTable({ records, userlogin, notes, loadedStatusNotes }) {
    const { t } = useTranslation();

    const dictionaryOfWHValues = get_dict_for_multiselect(records, "wid", true);
    const dictionaryOfWHValuesFalse = get_dict_for_multiselect(records, "wid", false);
    const itemsNames = Object.keys(dictionaryOfWHValues).reduce((acc, v) => ({ ...acc, [v]: v }), {});
    const dictionaryPackageValues = get_dict_for_multiselect(records, "co_k_HSHObal5", true);
    const dictionaryPackageValuesFalse = get_dict_for_multiselect(records, "co_k_HSHObal5", false);
    const itemsNamesPackage = Object.keys(dictionaryPackageValues).reduce((acc, v) => ({ ...acc, [v]: v }), {});

    const [offset, setOffset] = useState(0);
    const [filterID, setFilterID] = useState("");
    const [filterCentre, setFilterCentre] = useState("");
    const [filterBatch, setFilterBatch] = useState("");
    const [filterCount, setFilterCount] = useState("");
    const [filterQuantity, setFilterQuantity] = useState("");
    const [filterQuantityKG, setFilterQuantityKG] = useState("");
    const [filterNoteText, setFilterNoteText] = useState("");
    const [sortValue, setSortValue] = useState("");
    const [direction, setDirection] = useState("down");
    const [type, setType] = useState("");
    const [checkedWH, setCheckedWH] = useState(dictionaryOfWHValues);
    const [checkedPackages, setCheckedPackage] = useState(dictionaryPackageValues);
    const [filterExpiry, setFilterExpiry] = useState("");
    const [filterStockinDate, setFilterStockinDate] = useState("");
    const [filterUpdate, setFilterUpdate] = useState("");
    const [openedNotes, setOpenedNotes] = useState({});

    const cleanFilters = () => {
        setFilterID("");
        setFilterCentre("")
        setFilterBatch("");
        setFilterCount("");
        setFilterQuantity("");
        setFilterQuantityKG("");
        setCheckedWH(dictionaryOfWHValues);
        setCheckedPackage(dictionaryPackageValues);
        setFilterExpiry("");
        setFilterStockinDate("");
        setFilterUpdate("");
        setFilterNoteText("");
    }

    useEffect(() => {
        const dictionaryOfWHValues = get_dict_for_multiselect(records, "wid", true);
        setCheckedWH(checkedWHCat => ({ ...dictionaryOfWHValues, ...checkedWHCat })); //functional updates 
        const dictionaryPackageValues = get_dict_for_multiselect(records, "co_k_HSHObal5", true);
        setCheckedPackage(checkedPackagesCat => ({ ...dictionaryPackageValues, ...checkedPackagesCat }));
    }, [records]);

    const preprocessedData = preprocess_timeInStock_data(records, notes);

    //function for expading notes
    function toggleOpenNotes(identification) {
        setOpenedNotes({ ...openedNotes, [identification]: !openedNotes[identification] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
    }

    const allHidden = Object.keys(openedNotes).length === Object.values(openedNotes).filter((v) => v === false).length;

    function expandAll() {
        const value = allHidden ? true : false;
        const newDictionary = openAllNotes(value);
        setOpenedNotes(newDictionary);
    }

    // console.log(openedNotes);

    function openAllNotes(value) {
        const all_productID_batch_combinations = records.map((rec) => String(rec.sth_k_IDProduktu) + String(rec.ps_KodSerie));
        const unique_identifications = all_productID_batch_combinations.filter((v, i, a) => a.indexOf(v) === i);
        const dictionary = unique_identifications.reduce((acc, id) => ({ ...acc, [id]: value }), {});
        //console.log(dictionary);
        return dictionary;
    }


    //console.log(preprocessedData);


    const stocks_filtered = preprocessedData.filter(
        function (stock) {
            return (
                (filter_rule(filterID, stock.IDProduktu, true) || filter_rule(filterID, stock.product_code, true) || filter_rule(filterID, stock.product_name, true)) &&
                (filter_rule(filterCentre, stock.centre, true)) &&
                checkedPackages[stock.package] &&
                (stock.batches.reduce((res, batch) => res || filter_rule(filterBatch, batch.alf_batch, true), false)) &&
                (stock.batches.reduce((res, batch) => res || date_compare(batch.expiry, filterExpiry), false)) &&
                (stock.batches.reduce((res, batch) => res || (batch.batches_in_whs || []).reduce((res, batchInWh) => res || checkedWH[batchInWh.wid], false), false)) &&
                //tripple reduce to filter number of packages/piecess
                (stock.batches.reduce((res, batch) => res ||
                    (batch.batches_in_whs || []).reduce((res, batchInWh) => res ||
                        (batchInWh.stocks_in_one_wh || []).reduce((res, dateInWh) => res ||
                            icompare(dateInWh.pieces, filterCount),
                            false),
                        false),
                    false)) &&
                //tripple reduce to filter quantity
                (stock.batches.reduce((res, batch) => res ||
                    (batch.batches_in_whs || []).reduce((res, batchInWh) => res ||
                        (batchInWh.stocks_in_one_wh || []).reduce((res, dateInWh) => res ||
                            icompare(dateInWh.sth_MnozstviSkl, filterQuantity),
                            false),
                        false),
                    false)) &&
                //tripple reduce to filter quantity in kg
                (stock.batches.reduce((res, batch) => res ||
                    (batch.batches_in_whs || []).reduce((res, batchInWh) => res ||
                        (batchInWh.stocks_in_one_wh || []).reduce((res, dateInWh) => res ||
                            icompare(dateInWh.quantity_in_kg, filterQuantityKG),
                            false),
                        false),
                    false)) &&
                //tripple reduce to filter stockinDate
                (stock.batches.reduce((res, batch) => res ||
                    (batch.batches_in_whs || []).reduce((res, batchInWh) => res ||
                        (batchInWh.stocks_in_one_wh || []).reduce((res, dateInWh) => res ||
                            date_compare(dateInWh.sth_DatNaskladneni, filterStockinDate),
                            false),
                        false),
                    false)) &&
                //tripple reduce to filter lastUpdate
                (stock.batches.reduce((res, batch) => res ||
                    (batch.batches_in_whs || []).reduce((res, batchInWh) => res ||
                        (batchInWh.stocks_in_one_wh || []).reduce((res, dateInWh) => res ||
                            date_compare(dateInWh.sth_stamp, filterUpdate),
                            false),
                        false),
                    false))
                &&
                //reduce to filter notes text 
                (
                    (stock.batches.reduce((res, batch) => res || (batch.notes || []).reduce((res, note) => res || filter_rule(filterNoteText, note.note), false), false))
                    ||
                    (filterNoteText === "" && (stock.batches.reduce((res, batch) => res || batch.notes.length === 0, false))) //or notes is empty array and filter in unfullfilled
                )
            )
        }
    )

    const stocks_sorted = stocks_filtered.sort(function (a, b) {
        if (type === "num") {
            if (direction === "up") {
                return a[sortValue] - b[sortValue];
            }
            if (direction === "down")
                return b[sortValue] - a[sortValue];
        }
        if (type === "letters") {
            if (direction === "up") {
                var nameA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
                var nameB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }
            if (direction === "down") {
                var nameAA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
                var nameBB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
                if (nameAA > nameBB) {
                    return -1;
                }
                if (nameAA < nameBB) {
                    return 1;
                }
                return 0;
            }
        }
        return 0;
    });

    const show_stocks = stocks_sorted.slice(offset, offset + 20);

    return (
        <>
            <Row>
                <Col>
                    <h4 className="mb-3">{t('menu3-time-in-stock')}</h4>
                </Col>
                <Col className="text-center">
                    <ItemsAndFiltered filtered_data={stocks_filtered} data={preprocessedData} cleanFilters={cleanFilters} />
                </Col>
                <Col>
                    <Pager offset={offset} pagesize={20} total={stocks_filtered.length} callback={setOffset} />
                </Col>
            </Row>
            <Table size="sm" bordered style={{ backgroundColor: "white" }}>
                <thead className="beGray">
                    <tr>
                        <th rowSpan={3} className="align-middle text-center pb-2">
                            <div className="mb-1">{t('not-prod_id')}&nbsp;
                                <SortIcons name={"IDProduktu"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                {t('prod-code')}
                                <br />
                                {t('prod-name')}
                            </div>
                            <Form.Group controlId="filterCode" className='mb-0'>
                                <Form.Control type="text" placeholder="&#128269;" value={filterID} onChange={(ev) => { setFilterID(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th rowSpan={3} className="text-center pb-2">{t('biz-centre')}
                            <SortIcons name={"centre"} sortValue={sortValue} setSortValue={setSortValue}
                                direction={direction} setDirection={setDirection} setType={setType} />
                            <br />
                            <Form.Group controlId="filterCentre">
                                <Form.Control type="text" placeholder="&#128269;" value={filterCentre} onChange={(ev) => { setFilterCentre(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th rowSpan={3} className="text-center pb-2">{t('ord-pack')}
                            <MultipleSelect checked={checkedPackages} setChecked={setCheckedPackage}
                                dictionaryTrue={dictionaryPackageValues} dictionaryFalse={dictionaryPackageValuesFalse}
                                itemsNames={itemsNamesPackage} setOffset={setOffset} id="filterPackage" withoutNumber />
                        </th>
                        <th colSpan={9} className="text-center">{t('prod-found_batches')}</th>
                    </tr>
                    <tr>
                        <th rowSpan={2} className="">  <p className="mb-1">{t('ord-batch')}</p>
                            <Form.Group controlId="filterCode" className='mb-1'>
                                <Form.Control type="text" placeholder="&#128269;" value={filterBatch} onChange={(ev) => { setFilterBatch(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th width="20%" rowSpan={2}>
                            <p className="mb-1">{t('prod-batch_notes')}</p>
                            <HisFetchStatus status={loadedStatusNotes} />
                            <Row className="g-0">
                                <Col>
                                    <Form.Group controlId="filterNote" className='mb-1'>
                                        <Form.Control type="text" placeholder="&#128269;" value={filterNoteText}
                                            onChange={(ev) => {
                                                setFilterNoteText(ev.target.value); setOffset(0);
                                                setOpenedNotes(openAllNotes(true))
                                            }} />
                                    </Form.Group>
                                </Col>
                                <Col xs="auto" className="ms-2">
                                    <Button variant="outline-danger" onClick={() => expandAll()}>
                                        {allHidden ? <>{t('expand')} <MdExpandMore /></> : <>{t('hide')} <MdExpandLess /></>}
                                    </Button>
                                </Col>
                            </Row>


                        </th>
                        <th rowSpan={2} className="text-end pb-2"><br />
                            <Form.Group controlId="filterExpiry" className="mb-0">
                                <Form.Label className="mb-1"> {t('ord-expiry')}</Form.Label>
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterExpiry}
                                    onChange={(ev) => { setFilterExpiry(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                            {/*   <SortIcons name={"expiry"} sortValue={sortValue} setSortValue={setSortValue}
                                direction={direction} setDirection={setDirection} setType={setType} numeric />
                                */}
                        </th>
                        <th rowSpan={2} className="text-center">{t('warehouse')}
                            <MultipleSelect checked={checkedWH} setChecked={setCheckedWH}
                                dictionaryTrue={dictionaryOfWHValues} dictionaryFalse={dictionaryOfWHValuesFalse}
                                itemsNames={itemsNames} setOffset={setOffset} id="filterWH" withoutNumber />
                        </th>
                        <th rowSpan={2} className="text-end">
                            <Form.Group controlId="filterStockinD" className="mb-1">
                                <Form.Label className="mb-1">  {t('deno-stockin_date')}</Form.Label>
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterStockinDate}
                                    onChange={(ev) => { setFilterStockinDate(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                            {/*  <SortIcons name={"sth_DatNaskladneni"} sortValue={sortValue} setSortValue={setSortValue}
                                direction={direction} setDirection={setDirection} setType={setType} numeric />
                                */}
                        </th>
                        <th colSpan={2} className="text-center">{t('prod-amount_per_batch')}</th>
                        <th rowSpan={2} className="text-center ">
                            <Form.Group controlId="filtercOUNT" className="mb-0">
                                <Form.Label className="mb-1"> {t('prod-pack_count')}</Form.Label>
                                <Form.Control className="mb-1" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterCount}
                                    onChange={(ev) => { setFilterCount(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th rowSpan={2} className="text-end">
                            <Form.Group controlId="filterUpdate" className="mb-1">
                                <Form.Label className="mb-1">   {t('prod-date_of_last')} <br /> {t('prod-status_update')}</Form.Label>
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterUpdate}
                                    onChange={(ev) => { setFilterUpdate(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                            {/*  <SortIcons name={"sth_stamp"} sortValue={sortValue} setSortValue={setSortValue}
                                direction={direction} setDirection={setDirection} setType={setType} numeric />
                                */}
                        </th>
                    </tr>
                    <tr>
                        <th className="text-center">
                            <Form.Group controlId="filterTotal" className="mb-0">
                                <Form.Label className="mb-1"> {t('measure_unit')} <MyInfo text='mj_means_warehouse_unit' /></Form.Label>
                                <Form.Control className="mb-1" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterQuantity}
                                    onChange={(ev) => { setFilterQuantity(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className="text-center">
                            <Form.Group controlId="filterTotalKG" className="mb-0">
                                <Form.Label className="mb-1"> {t('prod-kg')}</Form.Label>
                                <Form.Control className="mb-1" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterQuantityKG}
                                    onChange={(ev) => { setFilterQuantityKG(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {show_stocks.map(function (prod, idx) { // 1st level of the product
                        const bg = (idx % 2) === 0 ? "" : "beGray3";
                        return prod.batches.map(function (batch, idxBatch) { //2nd level of the batch
                            const class_hi_expiry = date_compare(batch.expiry, filterExpiry) && is_date_comparable(filterExpiry) ? "bg-info-light" : "";
                            return batch.batches_in_whs.map(function (batchInWh, idxBatchInWh) { //3rd level of warehouse
                                const class_hi_wh = checkedWH[batchInWh.wid] && !(Object.values(checkedWH).filter((val) => val === false).length === 0) ? "bg-info-light" : ""; //pokud jsou všechny true, tak nebarvit, pole těch false je 0
                                //console.log(batch.notes);
                                const notes_on_batch = batch.notes;
                                return batchInWh.stocks_in_one_wh.map(function (stockinDateInWh, idxDateInWh) { //4th level of one date in one warehouse of one batch of an product
                                    const class_hi_stockinDate = date_compare(stockinDateInWh.sth_DatNaskladneni, filterStockinDate) && is_date_comparable(filterStockinDate) ? "bg-info-light" : "";
                                    const class_hilite_MJ = icompare(stockinDateInWh.sth_MnozstviSkl, filterQuantity) && filterQuantity !== "" ? "bg-info-light" : "";
                                    const class_hi_kg = icompare(stockinDateInWh.quantity_in_kg, filterQuantityKG) && filterQuantityKG !== "" ? "bg-info-light" : "";
                                    const class_hi_count = icompare(stockinDateInWh.pieces, filterCount) && filterCount !== "" ? "bg-info-light" : "";
                                    const class_hi_update = date_compare(stockinDateInWh.sth_stamp, filterUpdate) && is_date_comparable(filterUpdate) ? "bg-info-light" : "";
                                    return (
                                        <tr key={idxDateInWh} className={bg}>
                                            {
                                                idxBatch === 0 && idxBatchInWh === 0 && idxDateInWh === 0 ?
                                                    <>
                                                        <td rowSpan={prod.product_td_rowspan} className={" align-middle text-center"}>
                                                            <h5>{hilite(String(prod.IDProduktu) || "", filterID)}</h5>
                                                            {hilite(prod.product_code || "", filterID)} <br />
                                                            {hilite(prod.product_name || "", filterID)}
                                                        </td>
                                                        <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">{hilite(prod.centre || "", filterCentre)}</td>
                                                        <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">{prod.package}</td>
                                                    </>
                                                    : <></>
                                            }

                                            {idxBatchInWh === 0 && idxDateInWh === 0 ?
                                                <>
                                                    <td rowSpan={batch.batch_td_rowspan} className="align-middle"> {filterBatch.length > 0 ? hilite(batch.alf_batch || "", filterBatch) : batch.alf_batch}</td>
                                                    <td rowSpan={batch.batch_td_rowspan}>
                                                        <Row className="g-0">
                                                            <Col className="me-2">
                                                                {batch.notes.length > 0 && !openedNotes[prod.IDProduktu + batch.alf_batch] ?
                                                                    <>
                                                                        <span className="text-muted">{batch.notes[0].username}:</span>&nbsp;{batch.notes[0].note}
                                                                    </>
                                                                    : <></>}
                                                                {batch.notes.length > 1 && !openedNotes[prod.IDProduktu + batch.alf_batch] ?
                                                                    <>
                                                                        <br />
                                                                        (+ {batch.notes.length - 1} další)
                                                                    </>
                                                                    :
                                                                    <></>}
                                                            </Col>
                                                            <Col xs="auto">
                                                                <Button disabled={loadedStatusNotes !== 2}
                                                                    onClick={() => toggleOpenNotes(prod.IDProduktu + batch.alf_batch)}
                                                                    className="float-end px-1 py-1" size="sm" variant="outline-danger">
                                                                    <img alt="note" height={20} src="/img/note2.svg"></img>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        {openedNotes[prod.IDProduktu + batch.alf_batch] ? //zkontrolovat, jestli se nacházím na správném batchi
                                                            <>
                                                                {/** 
                                                                 * testing what the component is getting
                                                                 * 
                                                                {prod.IDProduktu + batch.alf_batch}
                                                                <br />
                                                                {notes_on_batch.length}
                                                                <br />
                                                                {notes_on_batch.map((n) => n.username + "+")}
                                                                */}

                                                                <EmbeddedNotes object_type={4} object_code={prod.IDProduktu}
                                                                    object_code2={batch.alf_batch} userlogin={userlogin} enabled_notes={[1]}
                                                                    existingNotes={notes_on_batch} />
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </td>
                                                    <td rowSpan={batch.batch_td_rowspan} className={"text-end align-middle "}><span className={class_hi_expiry}>{batch.expiry ? date_formatCZ(batch.expiry) : <span className="text-danger">{t('expiry-missing')}!</span>}</span></td>
                                                </> : <></>
                                            }

                                            {idxDateInWh === 0 ?
                                                <>
                                                    <td rowSpan={batchInWh.warehouse_td_rowspan} className="text-center align-middle"><span className={class_hi_wh}>{batchInWh.wid}</span></td>
                                                </> : <></>
                                            }
                                            <td className="text-end align-middle"><span className={class_hi_stockinDate}>{date_formatCZ(stockinDateInWh.sth_DatNaskladneni)}</span></td>
                                            <td className="text-end align-middle"><span className={class_hilite_MJ}>{format_amount(stockinDateInWh.sth_MnozstviSkl)} {stockinDateInWh.p_KodMjSkl}</span></td>
                                            <td className="text-end align-middle"><span className={class_hi_kg}>{format_amount(stockinDateInWh.quantity_in_kg)}&nbsp;{t('prod-kg')}</span></td>
                                            <td className="text-end align-middle"><span className={class_hi_count}>{stockinDateInWh.pieces} {t('piece_short')}</span></td>
                                            <td className="text-end align-middle"><span className={class_hi_update}>{stockinDateInWh.sth_stamp ? date_formatCZ(stockinDateInWh.sth_stamp) : "-"}</span></td>
                                        </tr>
                                    )
                                })
                            })
                        })
                    })}
                </tbody>

            </Table>
        </>
    );
}
