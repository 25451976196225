/**
* Definition of possible states from progress path in case of stokins
* @module orders/progress-stockins-def
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import { greenish, redish, yellowish } from "../lists/colors";

export const final_states_SN = [null, 0, 6, 9, 10, 11, 12, 13];


// klíč needs solution - null když to tam nepadá, 0 hned a jinak čas v sekundách asi

export const stock_progress_specification = [
    // 1: odesílání souboru s avizací
    {
        name: "sys-created", //Vytvořeno
        number: 1,
        position: 1,
        color: greenish,
        standard: true,
        timer: null,
        needs_solution: null,
    },
    {
        name: "sending", //Odesílání
        number: 2,
        position: 1,
        color: greenish,
        standard: false,
        timer: 5 * 60, //5 min
        needs_solution: null,
    },
    {
        name: "uploaded", //Nahráno
        number: 3,
        position: 1,
        color: greenish,
        standard: false,
        timer: 5 * 60, //5 min
        needs_solution: null,
    },
    {
        name: "prod-picked", //Vyzvednuto
        number: 4,
        position: 1,
        color: greenish,
        standard: false,
        timer: 15 * 60, //15 min
        needs_solution:  15 * 60, //15 min
    },
    //2: potvrzeno či zamítnuto
    {
        name: "prod-confirmed", //Potvrzeno
        number: 5,
        position: 2,
        color: greenish,
        standard: true,
        timer: null, //stojí za řešení - vstupuje do toho ETA na NO
        needs_solution: null,
    },
    {
        name: "manually_canceled", //Ručně zrušeno
        number: 10,
        position: 2,
        color: redish,
        standard: false,
        timer: null,
        needs_solution: null,
    },
    { 
        name: "denied", //Zamítnuto
        number: 6,
        position: 2,
        color: redish,
        standard: false,
        timer: null,
        needs_solution: null,
    },
    {
        name: "manually_confirmed", //Ručně potvrzeno
        number: 14,
        position: 2,
        color: yellowish,
        standard: false,
        timer: null, //stejný jako potvrzeno
        needs_solution: null,
    },
    //3: oznámení o příjmu obdrženo ze skladu
    {
        name: "arrival_notification_delivered", // Příjem obdržen
        number: 7,
        position: 3,
        color: greenish,
        standard: true,
        timer: 15, // 15 sekund 
        needs_solution: 15 * 60, // 15 minut 
    },
    // 4: uložení dat do databáze Twistu
    {
        name: "arrival_saved", // Příjem uložen
        number: 8,
        position: 4,
        color: greenish,
        standard: true,
        timer: 1 * 60, //1 min
        needs_solution: null,
    },
    {
        name: "notification_error", // Chyba v oznámení
        number: 11,
        position: 4,
        color: redish,
        standard: false,
        timer: null,
        needs_solution: 0,
    },
    // 5: naskladnění
    { 
        name: "stocked", //Naskladněno
        number: 9,
        position: 5,
        color: greenish,
        standard: true,
        timer: null, // koncový
        needs_solution: null,
    },
    {
        name: "stock_error", // Chyba v naskladnění
        number: 12,
        position: 5,
        color: redish,
        standard: false,
        timer: null, // koncový
        needs_solution: 0,
    },
    {
        name: "manually_stocked", //Ručně naskladněno
        number: 13,
        position: 5,
        color: yellowish,
        standard: false,
        timer: null, // koncový
        needs_solution: null,
    },
];