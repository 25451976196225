import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ReportTabs, ListSources, AmountsTables, ReportResultTable } from './reporting-components';
import { ReportConfig } from './report-config';
import { useTranslation } from 'react-i18next';
import { get_booked_string_for_filename } from './report-utils';

/**
 * @param { string } reportName - mapping name to use
 */
export function ReportFGProfitLoss({ userlogin, reportName }) {
	const { t } = useTranslation();

	// Both must be specified, both are strings
	const { year } = useParams();
	const { month } = useParams();
	const { booked } = useParams();

	// Global state for this report
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [rawBalances, setRawBalances] = useState(null);
	const [config, setConfig] = useState(null);
	const [currentFG, setCurrentFG] = useState("1");
	const [updating, setUpdating] = useState(false); // for func saveConfig
	const [mess, setMess] = useState(''); // error message for func saveConfig
	const [saved, setSaved] = useState(true);
	const [history, setHistory] = useState([]);
	const [rehistory, setRehistory] = useState([]);
	const [accountNames, setAccountNames] = useState(null);

	useEffect(() => {
		return his_fetch(
			userlogin,
			[
				// Load the mapping first, if anything goes wrong, we
				// bail out early.  It does not update status.
				{
					uri: "/api/reporting/mappings/" + reportName,
					json: true,
					ok: (resource, result) => {
						setConfig(result.config);
					},
					error: (resource, reason) => {
						console.log("error: " + reason);
						setLoadedStatus(3);
					}
				},
				// Load the data from backend first - contains
				// unstructured tuplets groupping accounts, focus
				// groups and cost centers. Both year and month must
				// be specified.
				{
					uri: "/api/reporting/fg-profit-loss/" + year + "/" + month + "/" + booked,
					json: true,
					status: setLoadedStatus,
					ok: (resource, result) => {
						setRawBalances(result.months[Object.keys(result.months)[0]]);
						setAccountNames(result.chartOfAccounts);
					},
					error: (resource, reason) => {
						console.log("error2: " + reason);
					}
				}
			]
		);
	}, [userlogin, year, month, reportName]);

	//console.log(rawBalances);

	// Continue only if we have something
	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus}
			loadingType="big"
			errorType="fetcherError" />;
	}

	// Copy-paste from ReportProfitLoss ...
	function saveConfig() {
		if (!updating) {
			setUpdating(true);
			setMess("");
			const jsonData = JSON.stringify(config);
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/reporting/mappings/" + reportName,
						json: true,
						ok: function (resource, result) {
							if (result.error) {
								setMess([t('saving_failed'), "text-danger"]);
								setUpdating(false);
							} else {
								setMess([t('successfully_saved'), "text-success"]);
								setUpdating(false);
							}
						},
						error: function (resource, reason) {
							setUpdating(false);
							setMess([t('saving_failed'), "text-danger"]);
						},
						args: {
							method: 'PUT',
							body: jsonData,
							headers: {
                                "Content-Type": "application/json",
                            },
						}
					}
				]
			)
		}
	}

	// Get the data per FG
	const balances = convertBalancesToFGSums(rawBalances);

	// Get the destination keys from configuration
	const dstIds = Object.keys(config || {});

	// Get source keys from balances 2nd level
	const srcIds = Object.keys(balances)
		.reduce((acc, fg) => [...acc, ...Object.keys(balances[fg])], [])
		.filter((v, i, a) => a.indexOf(v) === i)
		.sort();
	const language = userlogin.userinfo.language;
	const accountNameTranslation = language === "en" ? "NazevUctuPreklad" : "NazevUctu";

	//console.log(balances);
	//console.log(srcIds);

	// Rendering, most of it somewhere else
	return (
		<>
			<ReportTabs />
			<Routes>
				<Route path='sources' element={
					Object.keys(balances).map((fg, idx) =>
						<Card key={idx} className="mb-3">
							<Card.Body>
								<Card.Title>Focus Group {fg}</Card.Title>
								<ListSources accountNames={accountNames} keyName={accountNameTranslation} sources={Object.keys(balances[fg])} />
							</Card.Body>
						</Card>
					)
				} />
				<Route path='balance' element={
					<AmountsTables balances={balances} accountNames={accountNames} keyName={accountNameTranslation}
						excelFileName={"fg-" + reportName + "_" + year + "-" + month + "-" + t(get_booked_string_for_filename(booked)).toLocaleLowerCase() + "-balances.xlsx"} />
				} />
				<Route path='config' element={
					<ReportConfig name="Profit/Loss"
						setup={{
							srcIds: srcIds,
							dstIds: dstIds,
							balances: balances[currentFG]
						}}
						setConfig={setConfig}
						subMenu={["1", "2", "3", "4", "5"]}
						subMenuLabel="Focus Group:"
						currentSubMenu={currentFG}
						onSubMenu={(fg) => setCurrentFG(fg)}
						config={config}
						onSave={saveConfig}
						savingMess={mess} setSavingMess={setMess}
						saved={saved} setSaved={setSaved}
						history={history} setHistory={setHistory}
						rehistory={rehistory} setRehistory={setRehistory}
						accountNames={accountNames} keyName={accountNameTranslation}
						reportName={"fg-" + reportName}
					/>
				} />
				<Route path='result' element={
					<ReportResultTable identifiers={dstIds}
						config={config}
						balances={balances}
						columns={Object.keys(balances).sort()}
						excelFileName={"fg-" + reportName + "_" + year + "-" + month + "-" + t(get_booked_string_for_filename(booked)).toLocaleLowerCase() + "-result.xlsx"} />
				} />
			</Routes >
		</>
	);
}

/**
 * Aggregates accounts present in the ledger and produces their balances.
 *
 * The ledger is an array of accounting ledger quintuplets (arrays).
 * Each quintuplet contains:
 *
 * * [0] account (string)
 * * [1] accounting side (boolean)
 * * [2] cost center (string)
 * * [3] focus group (string) - single character string containing only digits 0-5
 * * [4] amount in local currency (number)
 *
 * @param { array } - ledger containing the raw quintuplets
 * @return { array } - dictionary of accounts and their balances
 */
function ledgerSumAccounts(ledger) {
	return ledger.reduce(
		(acc, rec) => ({
			...acc,
			[rec[0]]: (acc[rec[0]] || 0) + (rec[1] ? rec[4] : -rec[4])
		}), {});
}

/**
 * Splits the accounting ledger into multiple sub-ledgers, one
 * for each unique value at the index position within the record quintuplets.
 *
 * @param { array } - ledger
 * @param { number } - index
 * @return { array } - dictionary of ledgers based on unique keys
 */
function ledgerSplitByIndex(ledger, index) {
	return ledger.reduce(
		(acc, rec) => ({
			...acc,
			[rec[index]]: [...(acc[rec[index]] || []), rec]
		}), {});
}

/**
 * Filters only accounts matching predicate.
 *
 * @param { array } - ledger
 * @param { function } - predicate(string) -> boolean
 * @return { array } - filtered ledger
 */
function ledgerFilterAccounts(ledger, predicate) {
	return ledger.filter((rec) => predicate(rec[0]));
}

/**
 * Returns true if given account should be used for weight computation.
 *
 * @param { string } - account
 * @return { boolean }
 */
function ledgerWeightRevenueAccount(account) {
	// Right now only 604xxx accounts are considered
	return account.match(/^604/i);
}

/**
 * From ledger, compute FG weights (should be used with ledger for
 * particular CC).
 *
 * @param { array } - ledger
 * @return { array } - dictionary of weights for FGs
 */
function ledgerComputeFGWeights(ledger) {
	// Can't smeg, won't smeg
	const defaultWeights = {
		"1": 0.2,
		"2": 0.2,
		"3": 0.2,
		"4": 0.2,
		"5": 0.2
	};
	if (!ledger) {
		return defaultWeights;
	}

	// Split by FG
	const FGLedger = ledgerSplitByIndex(ledger, 3);

	// Get totals by FG - make sure all are there
	const FGTotals = Object.keys(defaultWeights).reduce(
		(acc, fg) => ({
			...acc,
			[fg]: ledgerSumsSum(ledgerSumAccounts(FGLedger[fg] || []))
		}), {});

	// Get total
	const total = Object.keys(FGTotals).reduce((acc, fg) => acc + FGTotals[fg], 0);

	// Safety check
	if (total === 0) {
		return defaultWeights;
	}

	// Normalize
	return Object.keys(defaultWeights).reduce(
		(acc, fg) => ({
			...acc,
			[fg]: (FGTotals[fg] || 0) / total
		}), {});
}

/**
 * Expects summed ledger by ledgerSumAccounts. Returns total sum.
 *
 * @param { array } sums - dictionary of account: amount
 * @return { number } - total sum
 */
function ledgerSumsSum(sums) {
	return Object.keys(sums).reduce((sum, account) => sum + sums[account], 0);
}

/**
 * Takes two ledger sums dictionaries and merges them summing any
 * accounts present in both sums.
 *
 * @param { array } sums1 - dictionary account:amount
 * @param { array } sums2 - dictionary account:amount
 * @return { array } - dictionary of summed account:amount
 */
function ledgerSumsMerge(sums1, sums2) {
	return [...Object.keys(sums1), ...Object.keys(sums2)]
		.filter((v, i, a) => a.indexOf(v) === i)
		.reduce((acc, key) => ({
			...acc,
			[key]: (sums1[key] || 0) + (sums2[key] || 0)
		}), {});
}

/**
 * Multiplies all values by given factor.
 *
 * @param { array } sums - dictionary of account:amount
 * @param { number } factor - multiplier
 * @return { array } - dictionary of account:amount (multiplied)
 */
function ledgerSumsMultiply(sums, factor) {
	return Object.keys(sums)
		.reduce((acc, key) => ({
			...acc,
			[key]: sums[key] * factor
		}), {});
}

/**
 * Converts the raw data from accounting ledger to reporting balances split by FG.
 *
 * @param { array } rawBalances - array of quintuplets (see ledgerSumAccounts)
 * @return { array } - dictionary FG -> account -> amount
 */
function convertBalancesToFGSums(rawBalances) {
	////////////////////////////////
	// Focus Group splitting

	// rawBalances contain the aggregated ledger, we split this into FG 0-5
	const rawFGBalances = ledgerSplitByIndex(rawBalances, 3);

	////////////////////////////////
	// Focus Group 0 split into business cost centers (normal) and support cost centers (special)

	// FG 0 is the one we need to split between the other 5
	const rawFG0Balances = rawFGBalances[0]; // array

	// We split FG 0 by CC and extract "special" CCs to be split evenly (1/5 each)
	const FG0CCBalances = ledgerSplitByIndex(rawFG0Balances, 2); // dictionary
	const specialCCs = ["SAL", "XX", "N", "?"];
	const FG0SpecialBalances = specialCCs.reduce((acc, cc) => [...acc, ...(FG0CCBalances[cc] || [])], []); // array
	const FG0SpecialSums = ledgerSumAccounts(FG0SpecialBalances); // dictionary

	// What remains on normal CCs should be split according to weights that differ for each CC
	const normalCCs = Object.keys(FG0CCBalances).filter((cc) => specialCCs.indexOf(cc) < 0);
	const FG0NormalBalances = normalCCs.reduce((acc, cc) => ({ ...acc, [cc]: FG0CCBalances[cc] }), {}); // dictionary

	////////////////////////////////
	// Focus group weights for each CC

	// Only relevant revenue accounts
	const weightBalances = ledgerFilterAccounts(rawBalances, ledgerWeightRevenueAccount);

	// Separate ledgers for each CC
	const weightCCBalances = ledgerSplitByIndex(weightBalances, 2); // dictionary by CC

	// Dictionary CC -> dictionary FG -> weight
	const CCFGWeights = normalCCs.reduce((acc, cc) => ({ ...acc, [cc]: ledgerComputeFGWeights(weightCCBalances[cc]) }), {});
	// console.log(CCFGWeights);

	////////////////////////////////
	// Step 1: Get sums from the sub-ledgers 1-5

	const S1FGSums = ["1", "2", "3", "4", "5"].reduce(
		(acc, fg) => ({
			...acc,
			[fg]: ledgerSumAccounts(rawFGBalances[fg])
		}), {});
	// console.log(S1FGSums);

	////////////////////////////////
	// Step 2: Add 1/5 of FG0SpecialSums to Step 1

	// Divide all values by 5
	const FG0SpecialSums1Fifth = ledgerSumsMultiply(FG0SpecialSums, 0.2);

	// For all FGs, add the 1/5 of the special CCs FG 0 ledger
	const S2FGSums = Object.keys(S1FGSums)
		.reduce((acc, fg) => ({
			...acc,
			[fg]: ledgerSumsMerge(S1FGSums[fg], FG0SpecialSums1Fifth)
		}), {});
	// console.log(S2FGSums);

	////////////////////////////////
	// Step 3: For each normal CC, add FG 0 to FGs 1-5 multiplied by appropriate weight

	// Convert ledger quintuplets into sums
	const FG0NormalSums = Object.keys(FG0NormalBalances)
		.reduce((acc, cc) => ({
			...acc,
			[cc]: ledgerSumAccounts(FG0NormalBalances[cc])
		}), {});

	// Apply the weights
	const S3FGSums = Object.keys(S2FGSums)
		.reduce((acc, fg) => ({
			...acc,
			[fg]: normalCCs.reduce(
				(fgacc, cc) => ledgerSumsMerge(fgacc,
					ledgerSumsMultiply(FG0NormalSums[cc] || {},
						CCFGWeights[cc][fg])),
				S2FGSums[fg])
		}), {});
	// console.log(S3FGSums);

	// Finished
	return S3FGSums;
}
