/**
 * function for month-year-dropdown
 * @module lib/month-year-utils
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import { format_addZero } from "./format";
import {mesice} from '../lists/months';

export function platneRoky(minDate, maxDate) {
    const minYear = new Date(minDate);
    const maxYear = new Date(maxDate);
    var poleRoku = [];
    var y_min = minYear.getFullYear();
    var y_max = maxYear.getFullYear();
    while (y_max >= y_min) {
        poleRoku.push(y_max);
        y_max -= 1;
    }
    return poleRoku;
}

export function monthsOfYear(minDate, maxDate, year) {
    const oldestYear = new Date(minDate).getFullYear();
    const youngestYear = new Date(maxDate).getFullYear();
    var monthsArr = [];
    if (year === oldestYear) {
        var m_min = new Date(minDate).getMonth();
        while (m_min <= 11) {
            monthsArr.push(format_addZero(m_min + 1));
            m_min += 1;
        }
        return monthsArr;
    } else if (year === youngestYear) {
        var m_max = new Date(maxDate).getMonth();
        //console.log(m_max);
        while (0 <= m_max) {
            monthsArr.push(format_addZero(m_max + 1));
            m_max -= 1;
        }
        return monthsArr.reverse();
    } else {
        return mesice.map((m) => m[0]);
    }
}

