import React from 'react';
import { useResolvedPath } from "react-router-dom";
import { Nav, Table, Card, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { format_amount } from '../lib/format';
import { ExcelDownloader } from '../comp/excel';
//import { FormulaElement } from './report-config-formula';
import { computeFormulaValue } from './report-utils';
import { useTranslation } from 'react-i18next';

/**
 * Common tabs for all reports, should keep URI consistent.
 */
export function ReportTabs() {
	const { t } = useTranslation();
	const { pathname } = useResolvedPath(""); //useRouteMatch().url; // previously in v5
	//console.log(pathname);

	const menu = [
		["sources", "rep-sources"],
		["balance", "rep-remaings"],
		["config", "rep-config"],
		["result", "rep-result"],
	];
	return (
		<Nav variant="tabs" className="mb-3">
			{menu.map((item, idx) =>
				<LinkContainer key={idx} to={`${pathname}/${item[0]}`}>
					<Nav.Link eventKey={pathname + "/" + item[0]}>{t(item[1])}</Nav.Link>
				</LinkContainer>
			)}
		</Nav>
	);
}

/**
 * Lists grouped sources.
 *
 * @param { array } sources - array of string identifiers
 * @param { array } grouping - dictionary of name:regexp
 */
export function ListSources({
	sources,
	grouping = {
		"5xx": /^5/,
		"6xx": /^6/
	},
	accountNames = {},
	keyName = ""
}) {
	return (
		<>
			{Object.keys(grouping)
				.map((name, idx) =>
					<React.Fragment key={idx}>
						<h5>{name}</h5>
						<ListIdentifiers identifiers={sources.filter((s) => s.match(grouping[name]))}
							accountNames={accountNames} keyName={keyName} />
					</React.Fragment>
				)}
		</>
	);
}

function ListIdentifiers({ identifiers, accountNames, keyName }) {
	const accountNamesAvailable = Object.keys(accountNames).length > 0;
	const buttonWidth = accountNamesAvailable ? "420px" : "auto";
	return (
		<p>
			{identifiers.map((ident, idx) =>
				<Button style={{ width: buttonWidth }}
					className='m-2 p-1 text-start'
					disabled variant='outline-primary'
					key={idx}>
					<b>{ident}</b>
					{accountNamesAvailable ?
						<>
							<hr className='my-1' />
							{(accountNames[ident] || {})[keyName] ? accountNames[ident][keyName] : "-"}
						</>
						: ""}
				</Button>)}
		</p>
	);
}

/**
 * 
 *
 * @param { array } balances - dictonary key -> account -> amount
 */
export function AmountsTables({
	balances,
	grouping = {
		"5xx": /^5/,
		"6xx": /^6/
	},
	accountNames = {},
	keyName = "",
	excelFileName = ""
}) {
	const { t } = useTranslation();
	// Get all 2nd level sources
	const all_sources = Object.keys(balances)
		.reduce((acc, k) => [...acc, ...Object.keys(balances[k])], [])
		.filter((v, i, a) => a.indexOf(v) === i)
		.sort();
	const months = Object.keys(balances).sort();


	//creating data for excel from all identifiers
	const first_line = ["", ""].concat(months.map((m) => m));
	const table_data = all_sources.map((ident) =>
		[
			ident,
			(accountNames[ident] || {})[keyName] ? accountNames[ident][keyName] : "-",
			months.map((month) => balances[month][ident] || 0)
		].flat()
	)
	const excel_data = [first_line].concat(table_data);
	//console.log(excel_data);

	return (
		<>
			<Card body className='float-end mb-1' style={{ width: "auto" }}>
				<h6 className='bolder'>{t('file_download')}</h6>
				<ExcelDownloader tableData={excel_data} fileName={excelFileName} />
			</Card>

			{Object.keys(grouping)
				.map((name, idx) =>
					<React.Fragment key={idx}>
						<h2 className='mt-5 pt-4'>{name}</h2>
						<AmountsTable identifiers={all_sources.filter((s) => s.match(grouping[name]))}
							columns={months}
							balances={balances} accountNames={accountNames}
							keyName={keyName} />
					</React.Fragment>
				)}
		</>
	);
}

function AmountsTable({ columns, identifiers, balances, accountNames, keyName }) {
	return (
		<Table size="sm" bordered>
			<thead>
				<tr>
					<th></th>
					<th></th>
					{columns.map((column, idx) => <th key={idx}>{column}</th>)}
				</tr>
			</thead>
			<tbody>
				{identifiers.map((identifier, idx) =>
					<tr key={idx}>
						<th>{identifier}</th>
						<td>{(accountNames[identifier] || {})[keyName] ? accountNames[identifier][keyName] : "-"}</td>
						{columns.map((column, idx) =>
							<td className="text-end" key={idx}>
								{format_amount(balances[column][identifier] || 0)}
							</td>)}
					</tr>
				)}
			</tbody>
		</Table>
	);
}

/**
 * 
 * @param {array} data - table data as two dimensional array 
 * @param {boolean} colHeaders - whether there are column headers that should be bold
 * @param {boolean} rowHeaders - whether there are row headers that should be bold
 * @param {string} excelFileName - if defined, show excel download button
 * @returns {component}
 */
export function SuperTable({ data, colHeaders = false, rowHeaders = false, excelFileName = "" }) {
	const { t } = useTranslation();
	const htmlData = data;

	return (
		<>{excelFileName !== "" ?
			<Card body className='float-end mb-1' style={{ width: "auto" }}>
				<h6 className='bolder'>{t('file_download')}</h6>
				<ExcelDownloader tableData={data} fileName={excelFileName} />
			</Card>
			: <></>}
			<Table size="sm" bordered>
				<tbody>
					{htmlData.map((row, idxRow) =>
						<tr key={idxRow}>{row.map((cell, idxCell) =>
							<td className={(colHeaders && idxRow === 0) ||
								(rowHeaders && idxCell === 0) ?
								"fw-bold" :
								typeof cell === 'number' ? "text-end" : ''}
								key={idxCell}>
								{typeof cell === 'number' ? format_amount(cell) : cell}
							</td>)}
						</tr>)}
				</tbody>
			</Table>
		</>
	);
}

export function ReportResultTable({ columns, identifiers, config, balances, excelFileName = "" }) {

	const two_D_array = [
		[" "].concat(columns),
		...identifiers.map((ident) =>
			[ident].concat(columns.map((column) =>
				computeFormulaValue(config[ident], balances[column])
			))
		)];

	return (
		<>
			<SuperTable data={two_D_array} colHeaders rowHeaders excelFileName={excelFileName} />
		</>
	);
}
