/**
* Orders sale list app and web
* @module orders/orders-sale
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React, { useState, useCallback } from 'react';
import { Table, Row, Col, Form } from 'react-bootstrap';
import { Boolean } from '../comp/boolean';
import { LinkContainer } from 'react-router-bootstrap';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { filter_rule } from '../lib/utils';
import { his_fetch } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Returns list of sale orders
 * 
 * @param {any} userlogin - info about logged user 
 * @returns {component}
 */
export function OrdersSale({ userlogin }) {

    const [orders, setOrders] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [oldest, setOldest] = useState(null);
    const [offset, setOffset] = useState(0);
    const [filterCode, setFilterCode] = useState("");
    const [filterPartner, setFilterPartner] = useState("");
    const [filterNote, setFilterNote] = useState("");
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [massFilterApp, setMassFilterApp] = useState("");

    const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
        return (
            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/orders/sale/range/" + startDate + "/" + endDate,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            if (result.error) {
                                result_received(false);
                            } else {
                                setOrders(ordersCat => (reload ? [] : ordersCat).concat(result.result));
                                setTotalCount(result.total_count);
                                setOldest(result.oldest.pod_DatDokladu);
                                result_received(true);
                            }
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            result_received(false);
                        }
                    }
                ]
            ))
    }, [userlogin])

    const cleanFilters = () => {
        setFilterCode("");
        setFilterPartner("");
        setFilterNote("");
        setMassFilterApp("");
    }

    const handleChangeCode = function (event) {
        setFilterCode(event.target.value);
        setOffset(0);
    }
    const handleChangePartner = function (event) {
        setFilterPartner(event.target.value);
        setOffset(0);
    }
    const handleChangeNote = function (event) {
        setFilterNote(event.target.value);
        setOffset(0);
    }
    const handleChangeFilterApp = function (event) {
        setMassFilterApp(event.target.value);
        setOffset(0);
    }

    const list_orders = orders.filter(
        function (ord) {
            return (filter_rule(filterCode, ord.pod_KodDokladu, true) &&
                filter_rule(filterPartner, ord.pod_KodPartnera, true) &&
                filter_rule(filterNote, ord.pod_Poznamka, true) &&
                (filter_rule(massFilterApp, ord.pod_KodDokladu, true) ||
                    filter_rule(massFilterApp, ord.pod_KodPartnera, true) ||
                    filter_rule(massFilterApp, ord.pod_Poznamka, true))
            );
        }
    );
    const show_orders = list_orders.slice(offset, offset + 20);

    return (
        <ErrorWrap>
            <DynamicLoad period='1y' loadData={loadData}
                filtered_data={list_orders} data={orders}
                offset={offset} setOffset={setOffset}
                totalCount={totalCount} oldest={oldest}
                cleanFilters={cleanFilters}
            />
            <OSWeb
                show_orders={show_orders}
                list_orders={list_orders}
                offset={offset}
                setOffset={setOffset}
                filterCode={filterCode}
                handleChangeCode={handleChangeCode}
                filterPartner={filterPartner}
                handleChangePartner={handleChangePartner}
                filterNote={filterNote}
                handleChangeNote={handleChangeNote}
            />
            <OSApp
                show_orders={show_orders}
                offset={offset}
                setOffset={setOffset}
                massFilterApp={massFilterApp}
                handleChangeFilterApp={handleChangeFilterApp}
            />
            <LoadingDataInfo loadedStatus={loadedStatus} data={orders} />
        </ErrorWrap>
    );
}

/**
 * Table with list of sale orders for web, header with filters included
 * 
 * @param {any} show_orders - list of orders to show on one page
 * @param {string} filterCode - state for code filter
 * @param {function} handleChangeCode - function for code filter
 * @param {string} filterPartner - state for partner filter
 * @param {function} handleChangePartner - function for partner filter
 * @param {string} filterNote - state for note filter
 * @param {function} handleChangeNote - function for note filter
 * @returns {component}
 */
function OSWeb({ show_orders, filterCode, handleChangeCode, filterPartner, handleChangePartner, filterNote, handleChangeNote }) {
    const { t } = useTranslation();

    return (
        <div className="d-none d-lg-block">
            <Table hover size="sm" striped className="d-none d-lg-table">
                <thead className="beGray">
                    <tr>
                        <th>
                            <Form.Group className="m-1" controlId="filterCode">
                                <Form.Control type="text"
                                    placeholder={"🔍 " + t('doc_code')}
                                    value={filterCode}
                                    onChange={handleChangeCode} />
                            </Form.Group>
                        </th>
                        <th>
                            <Form.Group className="m-1" controlId="filterPartner">
                                <Form.Control type="text"
                                    placeholder={"🔍 " + t('biz-partner_code')}
                                    value={filterPartner}
                                    onChange={handleChangePartner} />
                            </Form.Group>
                        </th>
                        <th className="pb-2"> {t('date')}</th>
                        <th>
                            <Form.Group className="m-1" controlId="filterNote">
                                <Form.Control type="text"
                                    placeholder={"🔍 " + t('note')}
                                    value={filterNote}
                                    onChange={handleChangeNote} />
                            </Form.Group>
                        </th>
                        <th className="pb-2 text-end">{t('biz-amount_money')}</th>
                        <th className="pb-2">{t('biz-currency')}</th>
                        <th className="pb-2 text-center">{t('biz-closed')}</th>
                        <th className="pb-2 text-center">{t('biz-invoiced')}&nbsp;%</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        show_orders.map(
                            function (ord) {
                                return (
                                    <LinkContainer to={{ pathname: "/orders/sale/" + ord.pod_KodDokladu }}
                                        key={ord.pod_KodDokladu}>
                                        <tr key={ord.pod_KodDokladu}>
                                            <td>{ord.pod_KodDokladu}</td>
                                            <td>{ord.pod_KodPartnera}</td>
                                            <td>{date_formatCZ(ord.pod_DatDokladu)}</td>
                                            <td>{ord.pod_Poznamka}</td>
                                            <td className="text-end">{format_amount(ord.pod_CastkaCelkemMen)}</td>
                                            <td>{ord.pod_KodMeny}</td>
                                            <td className="text-center"><Boolean value={ord.pod_Uzavreno} variant="onlyTrue" /></td>
                                            <td className="text-center">{ord.pod_FakturovanoProc}&nbsp;%</td>
                                        </tr>
                                    </LinkContainer>
                                );
                            }
                        )
                    }
                </tbody>
            </Table>
        </div>
    );
}

/**
 * Returns list of sale orders for mobile with merged filter
 * 
 * @param {any} show_orders - list of orders to show on one page
 * @param {string} massFilterApp - state for filter
 * @param {function} handleChangeFilterApp - function for filter
 * @returns {component}
 */
function OSApp({ show_orders, massFilterApp, handleChangeFilterApp }) {
    const { t } = useTranslation();

    return (
        <div className="d-lg-none">
            <br />
            <p className="mb-1">{t('mass_filter')}</p>
            <Form.Group controlId="filterCodex" className='mb-3'>
                <Form.Control type="text" placeholder={"🔍 " + t('filter_doc_code_note_partner')} value={massFilterApp} onChange={handleChangeFilterApp} />
            </Form.Group>
            <Table striped>
                <tbody>
                    {show_orders.map(function (ord) {
                        return (
                            <LinkContainer to={{ pathname: "/orders/sale/" + ord.pod_KodDokladu }}
                                key={ord.pod_KodDokladu}>
                                <tr>
                                    <td>
                                        <Row className="g-0">
                                            <Col className="beBigger me-1">{ord.pod_KodDokladu}</Col>
                                            <Col className="text-end align-bottom">{format_amount(ord.pod_CastkaCelkemMen)} {ord.pod_KodMeny}</Col>
                                        </Row>
                                        <Row>
                                            <Col>{ord.pod_KodPartnera}</Col>
                                            <Col className="text-end">{t('biz-closed')}: <Boolean value={ord.pod_Uzavreno} variant="onlyTrue" /></Col>
                                        </Row>
                                        <Row>
                                            <Col>{date_formatCZ(ord.pod_DatDokladu)}</Col>
                                            <Col xs="auto" className="text-end">{t('biz-invoiced')}: {ord.pod_FakturovanoProc}&nbsp;%</Col>
                                        </Row>
                                        <Row>
                                            <Col>{t('note_short')}: {ord.pod_Poznamka}</Col>
                                        </Row>
                                    </td>
                                </tr>
                            </LinkContainer>
                        );
                    })
                    }
                </tbody>
            </Table>
        </div>
    );
}