import React, { useState } from "react";
import { Table, Form, Row, Col, Alert } from "react-bootstrap";
import { his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import { mergeWarehouseWithTwistStocks } from "./productInventoryMagic";
import { warehouses_names_ids_separe } from "../lists/warehouses-defs";
import { Pager } from "../comp/pager";
import { filter_rule, icompare } from "../lib/utils";
import { ItemsAndFiltered } from "../comp/dynamic-load";
import { date_time_format } from "../lib/date-utils";
import { format_amount } from "../lib/format";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import { date_getYesterday, date_formatISO } from "../lib/date-utils";
import { useTranslation } from "react-i18next";
import { hilite } from "../lib/format";
import { preprocessStocksData } from "./ProductStocks";
import { useQuery } from "@tanstack/react-query";
import { getStocksRecords, getTwistStocksRecords } from "../api/stocks";
import { queryStatusToLoadedStatus } from "../api/common";
import { MultipleSelectRhf } from "../comp/MultipleSelectRhf";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { SortIconsRhf } from "../comp/SortIconsRhf";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";

const defaultFilterValues: ProductInventoryTwistWarehouseFilterValues = {
  status: ["", "warning", "danger"],
  orderBy: "IDProduktu",
  product: "",
  batch: "",
  batchKgWh: "",
  batchKgTwist: "",
  sumWh: "",
  sumTwist: "",
};

interface ProductInventoryTwistWarehouseProps {
  warehouseId: number;
  fetchDate: string;
}

/**
 * Table of inventory to compare Twist and warehouse data
 */
export function ProductInventoryTwistWarehouse({ warehouseId, fetchDate }: ProductInventoryTwistWarehouseProps) {
  const { t } = useTranslation();

  const TwistDate = useParams().twistDate;

  const [offset, setOffset] = useState(0);
  const fetchDateTwist = TwistDate === "todaysTwist" ? fetchDate : date_formatISO(date_getYesterday(fetchDate));

  const {
    data: { items: warehouseData, snapshotCreated: updateTime },
    refetch: refetchWarehouseData,
    status: warehousesStatus,
    isFetching: warehousesIsFetching,
  } = useQuery({
    queryKey: ["stocks/inventory", warehouseId, fetchDate],
    queryFn: ({ signal, queryKey }) => {
      return getStocksRecords(queryKey[1] as number, queryKey[2] as string, { signal });
    },
    initialData: { items: [] },
  });
  const loadedStatus1 = queryStatusToLoadedStatus(warehousesStatus, warehousesIsFetching);

  const {
    data: { items: twistData },
    refetch: refetchTwistData,
    status: twistStatus,
    isFetching: twistIsFetching,
  } = useQuery({
    queryKey: ["stocks/inventoryTwist", warehouseId, fetchDateTwist],
    queryFn: ({ signal, queryKey }) => {
      return getTwistStocksRecords(queryKey[1] as number, queryKey[2] as string, { signal });
    },
    initialData: { items: [] },
  });
  const loadedStatus2 = queryStatusToLoadedStatus(twistStatus, twistIsFetching);

  const form = useForm<ProductInventoryTwistWarehouseFilterValues>({
    mode: "onChange",
    defaultValues: defaultFilterValues,
  });
  const [filters] = useDebounceFilterValues(form.watch, defaultFilterValues);

  if (!his_fetch_success(loadedStatus1)) {
    return (
      <HisFetchStatus
        status={loadedStatus1}
        loadingType="big"
        errorType="fetcherError"
        reloadButton={refetchWarehouseData}
      />
    );
  }
  if (!his_fetch_success(loadedStatus2)) {
    return (
      <HisFetchStatus
        status={loadedStatus2}
        loadingType="big"
        errorType="fetcherError"
        reloadButton={refetchTwistData}
      />
    );
  }

  const cleanFilters = () => {
    form.reset(defaultFilterValues);
  };

  if (warehouseData.length === 0) {
    return (
      <>
        <p className="text-secondary mt-3">
          {t("prod-last_update_of_wh_data")}: {updateTime ? date_time_format(updateTime) : ""}
        </p>
        <Alert variant="danger">{t("prod-cannot_make_inventory_no_wh_data")}.</Alert>
      </>
    );
  }
  if (twistData.length === 0) {
    return <Alert variant="danger">{t("prod-cannot_make_inventory_no_twist_data")}.</Alert>;
  }

  const wd2 = preprocessStocksData(warehouseData, "warehouses");
  const td2 = preprocessStocksData(twistData, "twist");
  const mashup = mergeWarehouseWithTwistStocks(wd2, td2);

  const data_filtered = mashup.filter(function (rec) {
    return (
      (filter_rule(filters.product, rec.IDProduktu, true) ||
        filter_rule(filters.product, rec.product_name, true) ||
        filter_rule(filters.product, rec.product_code, true)) &&
      (rec.batches.reduce(
        (acc, batchLine) => acc || filter_rule(filters.batch, batchLine.twist["alf_batch"] || null, true),
        false
      ) ||
        rec.batches.reduce(
          (acc, batchLine) => acc || filter_rule(filters.batch, batchLine.warehouse["alf_batch"] || null, true),
          false
        )) &&
      rec.batches.reduce(
        (acc, batchLine) => acc || icompare(batchLine.twist.alf_batch_weight_kg, filters.batchKgTwist),
        false
      ) &&
      rec.batches.reduce(
        (acc, batchLine) => acc || icompare(batchLine.warehouse.alf_batch_weight_kg, filters.batchKgTwist),
        false
      ) &&
      // zreducovaný or a začínám na false - batchline.twist.něco či batch-while.něco porovnávám uvnitř toho reducu, reduce přes batches
      icompare(rec.super_sum_kg_twist, filters.sumTwist) &&
      icompare(rec.super_sum_kg_warehouse, filters.sumWh) &&
      filters.status.includes(rec.stocks_check_status)
    );
  });

  const sortDirection = filters.orderBy?.startsWith("-") ? "down" : "up";
  const sortValue = filters.orderBy?.replace(/^-/, "");
  const data_sorted = data_filtered.sort(function (a, b) {
    if (sortValue === "IDProduktu") {
      if (sortDirection === "up") {
        return a[sortValue] - b[sortValue];
      }
      if (sortDirection === "down") return b[sortValue] - a[sortValue];
    }
    return 0;
  });

  const show_mashup = data_sorted.slice(offset, offset + 10);
  //console.log(show_mashup);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">
            {t("prod-wh_inventory")} {warehouses_names_ids_separe[warehouseId]}
          </h4>
          <p className="text-secondary">
            {t("prod-last_update_of_wh_data")}: {date_time_format(updateTime)}
          </p>
          <Form.Group controlId="formBasicCheckbox">
            <LinkContainer
              to={
                "/products/stocks/" +
                fetchDate +
                "/inventory/" +
                warehouseId +
                (TwistDate === "todaysTwist" ? "/yesterdaysTwist" : "/todaysTwist")
              }
            >
              <Form.Check
                readOnly
                type="checkbox"
                checked={TwistDate === "yesterdaysTwist"}
                label={t("prod-yesterdays_twist")}
              />
            </LinkContainer>
          </Form.Group>
        </Col>
        <Col className="text-center">
          <ItemsAndFiltered filtered_data={data_filtered} data={mashup} cleanFilters={cleanFilters} />
        </Col>
        <Col>
          <Pager offset={offset} pagesize={10} total={data_filtered.length} callback={setOffset} />
        </Col>
      </Row>

      <Table size="sm">
        <thead className="beGray">
          <FormProvider {...form}>
            <ProductInventoryTwistWarehouseFilter />
          </FormProvider>
        </thead>
        <tbody>
          {show_mashup.map(function (rec, idx) {
            const classSumKG = rec.super_sum_kg_ok !== true ? "alert-danger" : "";
            const wholeProductClass = "alert-" + rec.stocks_check_status;
            const bg = idx % 2 === 0 ? "" : "beGray3";
            const problem_classa = !rec.stocks_check_status ? "bg-success" : "bg-" + rec.stocks_check_status;
            return (
              <React.Fragment key={idx}>
                {rec.batches.map(function (batchLine, idm) {
                  const classBatchKG = batchLine.sum_kg_ok !== true ? "alert-danger" : "";
                  const backlightTwistKg =
                    filters.batchKgTwist?.length > 0
                      ? icompare((batchLine.twist || {}).alf_batch_weight_kg, filters.batchKgTwist)
                        ? "bg-info-light"
                        : ""
                      : "";
                  const backlightWHKg =
                    filters.batchKgWh?.length > 0
                      ? icompare((batchLine.warehouse || {}).alf_batch_weight_kg, filters.batchKgWh)
                        ? "bg-info-light"
                        : ""
                      : "";
                  const myRowSpan = rec.batches.length;
                  return (
                    <React.Fragment key={idm}>
                      <tr className={bg} key={idm}>
                        {idm === 0 ? (
                          <>
                            <td rowSpan={myRowSpan} className={problem_classa + " align-middle text-center"}>
                              {rec.problem ? <span className="bg-light p-1">❌</span> : "✅"}
                            </td>
                            <td rowSpan={myRowSpan} className={wholeProductClass + " align-middle text-center "}>
                              {rec.IDProduktu}
                              <br />
                              {rec.product_name}
                            </td>
                          </>
                        ) : (
                          <></>
                        )}
                        <td className="align-middle ">
                          {!batchLine.twist["alf_batch"] // if there is no twist batch, show warehouse batch
                            ? filters.batch.length > 0
                              ? hilite(batchLine.warehouse["alf_batch"] || "", filters.batch)
                              : batchLine.warehouse["alf_batch"]
                            : filters.batch.length > 0
                              ? hilite(batchLine.twist["alf_batch"] || "", filters.batch)
                              : batchLine.twist["alf_batch"]}
                        </td>
                        <td className="align-middle text-end">{format_amount(batchLine.warehouse.alf_batch_sum)}</td>
                        <td className="align-middle">{batchLine.warehouse["alf_package_unit"]}</td>
                        <td className={"align-middle text-end"}>{format_amount(batchLine.twist.alf_batch_sum)}</td>
                        <td className="align-middle">{batchLine.twist["alf_package_unit"]}</td>
                        <td className={classBatchKG + " align-middle text-end "}>
                          <span className={backlightWHKg}>
                            {/* @ts-ignore */}
                            {batchLine.warehouse.alf_batch_weight_kg === "Chyba"
                              ? "Chyba"
                              : format_amount(batchLine.warehouse.alf_batch_weight_kg)}{" "}
                            {t("prod-kg")}
                          </span>
                        </td>
                        <td className={classBatchKG + " align-middle text-end "}>
                          <span className={backlightTwistKg}>
                            {/* @ts-ignore */}
                            {batchLine.twist.alf_batch_weight_kg === "Chyba"
                              ? "Chyba"
                              : format_amount(batchLine.twist.alf_batch_weight_kg)}{" "}
                            {t("prod-kg")}
                          </span>
                        </td>
                        {idm === 0 ? (
                          <>
                            <td rowSpan={myRowSpan} className={" align-middle text-end "}>
                              {/* @ts-ignore */}
                              {isNaN(rec.super_sum_kg_warehouse)
                                ? "Chyba"
                                : format_amount(parseFloat(rec.super_sum_mj_warehouse.toString()))}
                            </td>
                            <td rowSpan={myRowSpan} className={" align-middle "}>
                              {rec.package_unit}
                            </td>
                            <td rowSpan={myRowSpan} className={" align-middle text-end "}>
                              {/* @ts-ignore */}
                              {isNaN(rec.super_sum_kg_warehouse)
                                ? "Chyba"
                                : format_amount(parseFloat(rec.super_sum_mj_twist.toString()))}
                            </td>
                            <td rowSpan={myRowSpan} className={" align-middle "}>
                              {rec.package_unit}
                            </td>
                            <td rowSpan={myRowSpan} className={classSumKG + " align-middle text-end "}>
                              {/* @ts-ignore */}
                              {isNaN(rec.super_sum_kg_warehouse)
                                ? "Chyba"
                                : format_amount(parseFloat(rec.super_sum_kg_warehouse.toString()))}{" "}
                              kg
                            </td>
                            <td rowSpan={myRowSpan} className={classSumKG + " align-middle text-end "}>
                              {/* @ts-ignore */}
                              {isNaN(rec.super_sum_kg_twist)
                                ? "Chyba"
                                : format_amount(parseFloat(rec.super_sum_kg_twist.toString()))}{" "}
                              kg
                            </td>
                          </>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

interface ProductInventoryTwistWarehouseFilterValues {
  status: ("danger" | "warning" | "")[];
  orderBy?: string;
  product?: string;
  batch?: string;
  batchKgWh?: string;
  batchKgTwist?: string;
  sumWh?: string;
  sumTwist?: string;
}

function ProductInventoryTwistWarehouseFilter() {
  const { t } = useTranslation();
  const { register } = useFormContext<ProductInventoryTwistWarehouseFilterValues>();

  const statusesTitles = {
    danger: " ❌ " + t("prod-incorrect_amount"),
    warning: "❌ " + t("prod-incorrect_batch"),
    "": "✅ OK",
  };

  return (
    <>
      <tr>
        <th rowSpan={3}>
          <p className="mb-2">{t("state")}</p>
          <MultipleSelectRhf id="status" values={statusesTitles} withoutNumber width="300px" />
        </th>
        <th rowSpan={3}>
          <Form.Group controlId="filterIDCodeName" className="mb-0">
            <Form.Label>
              {t("product")}&nbsp;
              <SortIconsRhf columnName="IDProduktu" filterFieldName={"orderBy"} numeric />
            </Form.Label>
            <Form.Control type="text" placeholder="&#128269;" {...register("product")} />
          </Form.Group>
        </th>
        <th colSpan={7} className="text-center">
          {t("prod-found_batches")}
        </th>
        <th rowSpan={2} colSpan={4} className="text-center">
          {t("prod-product_mj")}
        </th>
        <th rowSpan={2} colSpan={2} className="text-center">
          {t("prod-product_KG")}
        </th>
      </tr>
      <tr>
        <th rowSpan={2}>
          <Form.Group controlId="filterBatch" className="mb-0">
            <Form.Label>{t("ord-batch")}</Form.Label>
            <Form.Control type="text" placeholder="&#128269;" {...register("batch")} />
          </Form.Group>
        </th>
        <th colSpan={4} className="text-center">
          {t("prod-amount_MJ")}
        </th>
        <th colSpan={2} className="text-center">
          {t("prod-weight_kg")}
        </th>
      </tr>
      <tr>
        <th colSpan={2} className="text-center">
          {t("warehouse")}
        </th>
        <th colSpan={2} className="text-center">
          {t("prod-twist")}
        </th>
        <th className="text-center">
          {t("warehouse")}
          <Form.Group controlId="filterBatchKgTwist" className="mb-0">
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("batchKgWh", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
        <th className="text-center">
          {t("prod-twist")}
          <Form.Group controlId="filterBatchKgWH" className="mb-0">
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("batchKgTwist", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
        <th colSpan={2} className="text-center">
          {t("warehouse")}
        </th>
        <th colSpan={2} className="text-center">
          {t("prod-twist")}
        </th>
        <th className="text-center">
          {t("warehouse")}
          <Form.Group controlId="filterSumWH" className="mb-0">
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("sumWh", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
        <th className="text-center">
          {t("prod-twist")}
          <Form.Group controlId="filterSumTwist" className="mb-0">
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("sumTwist", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
      </tr>
    </>
  );
}
