import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { his_fetch_loading, his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Loading } from '../comp/loading';
import { Boolean } from '../comp/boolean';
import { monitoring_options } from '../lists/monitoring_batch_bbd';
import { make_product_check_tag } from '../products/product-checks';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export const defined_pH_classes = ["8", 8];

/**
 * Filters values from array of configs for product
 * 
 * @param {string} key - name of key to filter
 * @param {array} array - array to filter
 * @returns {any}
 */
function filter_config_value(key, array) {
    return ((array || []).filter((line) => line.invalidated === null)[0] || {})[key];
}

/**
 * Card with product configuration setting. Namely approval in HIS, monitoring config, expiry in months and pH.
 * 
 * @param {any} userlogin - info about logged user
 * @param {any} product - info about product
 * @param {function} reloadProduct - reloads whole product - its necessary for perfoming checks again
 * @param {component} ErrorBadge - error badge component to display error location
 * @returns {component}
 */
export function ProductConfiguration({ userlogin, product, reloadProduct, ErrorBadge }) {
    const { t } = useTranslation();

    const [productInfo, setProductInfo] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [savingStatus, setSavingStatus] = useState(false);
    const [currentConfig, setCurrentConfig] = useState("");
    const [currentApproval, setCurrentApproval] = useState(false);
    const [currentMonthExp, setCurrentMonthExp] = useState("");
    const [currentPh, setCurrentPh] = useState("");
    const [errorMess, setErrorMess] = useState("");

    const originalApproval = productInfo && productInfo.length > 0 ? (filter_config_value("approved", productInfo) || false) : false;
    const originalConfig = productInfo && productInfo.length > 0 ? (filter_config_value("type", productInfo) || "0") : "0";
    const originalMonths = productInfo && productInfo.length > 0 ? (filter_config_value("months", productInfo) || "") : "";
    const originalPH = productInfo && productInfo.length > 0 ? (filter_config_value("ph", productInfo) || "") : "";

    useEffect(() => {
        if (productInfo === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/products/" + product.productId + "/config",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            setProductInfo(result);
                            // console.log(result.configs.filter((line) => line.pc_invalidated === null));
                            // console.log(result.configs);
                            setCurrentApproval(result.length > 0 ? result.filter((line) => line.invalidated === null)[0].approved || false : false);
                            setCurrentConfig(result.length > 0 ? result.filter((line) => line.invalidated === null)[0].type || "0" : "0");
                            setCurrentMonthExp(result.length > 0 ? result.filter((line) => line.invalidated === null)[0].months || "" : "");
                            setCurrentPh(result.length > 0 ? result.filter((line) => line.invalidated === null)[0].ph || "" : "");
                            //console.log(result);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setProductInfo("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, productInfo, product.productId]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={() => setProductInfo(null)} />;
    }

    //const prodConfig = productInfo ? productInfo.filter((line) => line.pc_invalidated === null)[0] : {};
    //console.log(prodConfig);

    //user cannot configure product
    const noRight = !(userlogin.userinfo.roleCompliance || userlogin.userinfo.hisSuperuser);
    //if ph input should display
    const right_ph_class = defined_pH_classes.includes(product.adrClass);

    //when expiry in months is mandatory
    const monthExpNeeded = parseInt(currentConfig) === 2 || parseInt(currentConfig) === 3;
    //empty mandatory expiry
    const monthExpNeededButEmpty = monthExpNeeded && (currentMonthExp.length === 0 || currentMonthExp === "0");
    const invalidMonths = isNaN(currentMonthExp) || monthExpNeededButEmpty;
    // its input values weer changed
    const changedPwc_id = parseInt(originalConfig) !== parseInt(currentConfig);
    const changedMonths = (originalMonths !== currentMonthExp) && !(currentConfig === "1");
    const changedApproval = String(originalApproval) !== String(currentApproval);
    const changedPh = (originalPH !== currentPh); 
    const nothingChanged = (!changedPwc_id && !changedMonths && !changedApproval && !changedPh);
    const invalidPh = isNaN(currentPh.replaceAll(' ', '').replace(',', '.'));
    const disabledSave = nothingChanged || invalidMonths || (invalidPh && changedPh);

    /*
    console.log("disabledSave------particles");
    console.log("nothingChanged: " + nothingChanged);
    console.log("isNaN(currentMonthExp): " + isNaN(currentMonthExp));
    console.log("monthExpNeededButEmpty: " + monthExpNeededButEmpty);
    console.log("invalidPh && changedPh: " + invalidPh && changedPh);

    console.log("-----------------------------------changes overview-------------------------");
    console.log("changedPwc_id: " + changedPwc_id + "     originalConfig: " + originalConfig + "    currentConfig: " + parseInt(currentConfig));
    console.log("changedMonths: " + changedMonths + "     originalMonths " + originalMonths + "    currentMonthsExp: " + currentMonthExp);
    console.log("changedApproval: " + changedApproval + "     originalApproval " + String(originalApproval) + "    currentApproval: " + String(currentApproval));
    console.log("changedPH: " + changedPh + "     originalPh " + originalPH + "    currentPH: " + currentPh);

    console.log("phčka-----orig, current, invalid");
    console.log(originalPH);
    console.log(currentPh);
    console.log(invalidPh);

    console.log("-------------approvals---------------------------")
    console.log(originalApproval);
    console.log(currentApproval);

    console.log("-------------config-monitoring---------------------------")
    console.log(originalConfig);
    console.log(currentConfig);

    console.log("-------------config-months---------------------------")
    console.log(originalMonths);
    console.log(currentMonthExp);

    console.log("************************************creating_JSON****************************-");
    console.log({
        ...(changedPwc_id ? { pwc_id: parseInt(currentConfig) } : {}),
        ...(changedMonths && currentConfig !== "1" ? { months: parseInt(currentMonthExp) } : {}),
        ...(changedApproval ? { approved: currentApproval === "true" } : {}),
        ...(changedPh ? { ph: String(currentPh.replace('.', ',')) } : {}),
    });
    */


    function updateIt() {
        //conditional adding keys to dictionary
        const myJSON = {
            ...(changedPwc_id ? { type: parseInt(currentConfig) } : {}),
            ...(changedMonths && currentConfig !== "1" ? { months: parseInt(currentMonthExp) } : {}),
            ...(changedApproval ? { approved: currentApproval === "true" } : {}),
            ...(changedPh ? { ph: String(currentPh.replace('.', ',')) } : {}),
        };
        his_fetch(userlogin,
            [
                {
                    uri: "/api/products/" + product.productId + "/config",
                    json: true,
                    status: setSavingStatus,
                    args: {
                        method: 'PUT',
                        body: JSON.stringify(myJSON),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    },
                    ok: function (resource, result) {
                        // console.log(result);
                        // console.log(myJSON);
                        setProductInfo(null); //mělo by vynutit fetch
                        reloadProduct(); //reload is necessary in most cases due to checks, which are implemented in mother component
                    },
                    error: function (resource, reason) {
                        console.log('err: ' + reason);
                        setErrorMess("error");
                    },
                }
            ]);
    }

    function changeCurrentConfig(ev) {
        setCurrentConfig(ev.target.value);
        if (ev.target.value === "1") {
            setCurrentMonthExp("");
        }
    }

    function changeCurrentApproval(ev) {
        setCurrentApproval(ev.target.value);
    }

    const changeCurrentMonthExp = (ev) => {
        setCurrentMonthExp(ev.target.value);
    }
    const changeCurrentPh = (ev) => {
        setCurrentPh(ev.target.value);
    }

    return (
        <ErrorWrap>
            <Card body className='myShadow'>
                <Row>
                    <Col><h5>{t('prod-product_card_setting')}</h5></Col>
                    <Col xs="auto" className="text-end">
                        {his_fetch_loading(savingStatus) ?
                            <Loading message={t('saving') + " ..."} margin="0" size="small" />
                            :
                            <Button size="sm" disabled={disabledSave} onClick={updateIt}>{t('save')}</Button>
                        }
                        <br />
                        {errorMess ? <small className="text-danger">{errorMess}</small> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end">{t('prod-approved_card')}:</Col>
                    <Col><Boolean value={product.warehouseConfiguration?.productApproved ?? false} /></Col>
                </Row>
                <Row>
                    <Col className="text-end">{t('prod-approved_buy')}:
                        <ErrorBadge tag={make_product_check_tag("hsh", "twistApproval")} /></Col>
                    <Col><Boolean value={product.approvedInTwist} /></Col>
                </Row>
                <Row className="my-2">
                    <Col className="text-end">{t('prod-approved_his')}:
                        <ErrorBadge tag={make_product_check_tag("hsh", "hisApproval")} />
                    </Col>
                    <Col>{product.needsApproval ?
                        <Form.Control size="sm" as="select" value={currentApproval} onChange={changeCurrentApproval} disabled={noRight}>
                            <option value={false}>{t('no')}</option>
                            <option value={true}>{t('yes')}</option>
                        </Form.Control>
                        :
                        t('not_necessary')}</Col>
                </Row>
                <Row>
                    <Col className="text-end">{t('prod-bbd_batch_monitoring')}:
                        <ErrorBadge tag={make_product_check_tag("hsh", "configured")} />
                    </Col>
                    <Col><Form.Control size="sm" as="select" value={currentConfig} onChange={changeCurrentConfig} disabled={noRight}>
                        {Object.keys(monitoring_options).map(function (m) {
                            return <option disabled={m === "0"} value={m} key={m}>{t(monitoring_options[m])}</option>
                        })}
                    </Form.Control>
                    </Col>
                </Row>
                {monthExpNeeded ?
                    <Row>
                        <Col className="text-end mt-1">{t('prod-expiry_months')}:
                            <ErrorBadge tag={make_product_check_tag("hsh", "monthExpiration")} /></Col>
                        <Col>
                            <Form.Group className="mb-2 mt-1" controlId="expirace_mesice">
                                <Form.Control size="sm" type="text" placeholder={currentMonthExp === "" ? "-" : ""} value={currentMonthExp} onChange={changeCurrentMonthExp} disabled={noRight} />
                                {isNaN(currentMonthExp) ? <small className="text-danger">{t('numbers_only')}</small> : ""}
                                {monthExpNeededButEmpty ? <small className="text-danger">{t('expiry_required')}</small> : ""}
                            </Form.Group>
                        </Col>
                    </Row>
                    : ""}
                {right_ph_class ?
                    <Row>
                        <Col className="text-end mt-1">pH:
                            <ErrorBadge tag={make_product_check_tag("hsh", "pH-missing")} /></Col>
                        <Col>
                            <Form.Group className="mb-3 mt-2" controlId="ph">
                                <Form.Control size="sm" type="text" placeholder={currentPh === "" ? "-" : ""} value={currentPh} onChange={changeCurrentPh} disabled={noRight} />
                                {currentPh === "" ?
                                    <small className="text-danger">{t('ph_mandatory')}</small> :
                                    invalidPh ? <small className="text-danger">{t('numbers_only')}</small>
                                        : ""
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    : ""}
            </Card>
        </ErrorWrap>
    );
}
