import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Row, Col } from "react-bootstrap";
import { NO_PIN_generate } from "../../comp/pin";
import { make_product_check_tag } from "../../products/product-checks";
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from "../../products/product-view";
import { useTranslation } from "react-i18next";
import { PurchaseOrderProcessedItem } from "./PurchaseOrder";

interface ItemOfNOPurchaseProps {
  item: PurchaseOrderProcessedItem;
  authorizedUser: boolean;
  ErrorBadge: any;
}

/*
 * Table of items for mobile.
 *
 * @param {any} items - items in order purchase
 * @param {boolean} authorizedUser - if true, PIN is also displayed
 * @param {any} ErrorBadge - component showing error numbers at right places
 * @returns {component}
 */
export function ItemOfNOPurchaseApp({ item, authorizedUser, ErrorBadge }: ItemOfNOPurchaseProps) {
  const { t } = useTranslation();

  return (
    <Table borderless className="m-0">
      <tbody>
        <tr>
          <td>
            <Row className="mb-1">
              <Col className={item.bg_item_green_red}>&nbsp;</Col>
            </Row>
            <Row>
              <Col>
                <span className="text-muted">{t("not-prod_id")} </span> {item.productId}
                <ErrorBadge tag={make_product_check_tag(item.itemId.toString(), "duplicity")} />
              </Col>
              {authorizedUser ? (
                <>
                  <Col className="text-center">
                    <span className="text-muted">{t("ord-item_id")} </span>
                    {item.itemId}
                  </Col>
                  <Col className="text-end">
                    <span className="text-muted">PIN </span>
                    {NO_PIN_generate(item.itemId, 1)}
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
            <span className="text-muted">{t("code")} </span>
            <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(item.item.productCode) + "/view" }}>
              <a href="!#">{item.item.productCode}</a>
            </LinkContainer>
            <br />
            <Row className="g-0">
              <Col xs="auto" className="text-end text-muted">
                {t("ord-amount_measure_unit")} <br />
                {t("ord-accepted_by_wh")} <br />
                {t("ord-remaining")} <br />
              </Col>
              <Col className="text-end ms-2 me-1" xs="auto">
                {item.item.quantity} <br />
                {item.item.receivedQuantity} <br />
                {item.item.remainingQuantity}
              </Col>
              <Col xs="auto">
                {item.item.unitOfMeasurementCode}
                <ErrorBadge tag={make_product_check_tag(item.itemId.toString(), "NO-unit-uniformity")} />
                <br />
                {item.item.unitOfMeasurementCode}
                <br />
                {item.item.unitOfMeasurementCode}
              </Col>
            </Row>
            <Table className="mt-2">
              <tbody>
                <tr>
                  <td className="align-middle border text-center">
                    H<br />S<br />H<br />
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "twistApproval")} />
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "hisApproval")} />
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "configured")} />
                    <ErrorBadge tag={make_product_check_tag(item.productId.toString(), "monthExpiration")} />
                  </td>
                  <td className="border">
                    {t("name")}: {item.item.productName ? item.item.productName : <>&mdash;</>}
                    <Table size="sm" className="p-0 mb-1">
                      <tbody>
                        <ProductContainerView
                          id={"hsh-" + item.productId}
                          container={item.hshPackage}
                          ErrorBadge={ErrorBadge}
                          mobile
                        />
                        <DensityAmountUnitWeightView
                          id={"hsh-" + item.productId}
                          density={item.unitOfMeasurementWeight}
                          densityUnit={item.unitOfMeasurementUnit}
                          amount={item.packageUnitOfMeasurementQuantity}
                          unit={item.storageUnitOfMeasurementCode}
                          packageWeight={item.package_weight_kg_hsh}
                          ErrorBadge={ErrorBadge}
                          mobile
                        />
                      </tbody>
                    </Table>
                    <ProductADRView
                      id={"hsh-" + item.productId}
                      TridaADR={item.classAdr}
                      k_ADRObalovaSkupina={item.adrPackingGroup}
                      k_ADRUNCislo={item.adrUnNumber}
                      ErrorBadge={ErrorBadge}
                      mobile
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle border">
                    {item.item.warehouseBookCode ? <>{item.item.warehouseBookCode}</> : ""}
                    <br />
                    <ErrorBadge
                      tag={make_product_check_tag(item.item.warehouseBookCode + "-" + item.productId, "missing-card")}
                    />
                    <ErrorBadge
                      tag={make_product_check_tag(item.item.warehouseBookCode + "-" + item.productId, "missing-values")}
                    />
                    <ErrorBadge
                      tag={make_product_check_tag(item.item.warehouseBookCode + "-" + item.productId, "wh-existance")}
                    />
                  </td>
                  <td className="border">
                    {t("name")}:{" "}
                    <span className={item.redish}>{item.item.productName ? item.item.productName : <>&mdash;</>}</span>
                    <Table borderless size="sm" className="p-0 mb-0">
                      <tbody>
                        <ProductContainerView
                          id={"wh-" + item.item.warehouseBookCode + "-" + item.productId}
                          wid={item.item.warehouseBookCode}
                          container={item.warehouseCard.hshPackage}
                          remap
                          border
                          ErrorBadge={ErrorBadge}
                          bg={!item.is_card}
                          mobile
                        />
                        <DensityAmountUnitWeightView
                          id={"wh-" + item.item.warehouseBookCode + "-" + item.productId}
                          density={item.warehouseCard.unitOfMeasurementWeightInKg}
                          densityUnit="kg"
                          amount={item.warehouseCard.packageUnitOfMeasurementQuantity}
                          unit={item.warehouseCard.storageUnitOfMeasurementCode}
                          packageWeight={item.package_weight_kg_wh}
                          ErrorBadge={ErrorBadge}
                          bg={!item.is_card}
                          mobile
                        />
                      </tbody>
                    </Table>
                    <hr className="m-1" />
                    <ProductADRView
                      id={"wh-" + item.item.warehouseBookCode + "-" + item.productId}
                      TridaADR={item.warehouseCard.adrClass}
                      k_ADRObalovaSkupina={item.warehouseCard.adrPackingGroup}
                      k_ADRUNCislo={item.warehouseCard.adrUnNumber}
                      romanized={true}
                      ErrorBadge={ErrorBadge}
                      bg={!item.is_card}
                      mobile
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
