/**
 * Notifications for owing partners mock-up.
 * @module partners/debts2
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import { format_amount } from '../lib/format';
import { filter_rule,  } from '../lib/utils';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import Card from 'react-bootstrap/Card';

/*
import { delay_color, } from '../lib/colors';
import { LinkContainer } from 'react-router-bootstrap';
import Form from 'react-bootstrap/Form';
import notes_comms from '../notes/notes-comms.json';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import { BooleanDropdown } from '../comp/boolean';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DelayOrHold, NoteIcon, NoteWithIcon, PlainNoteWithIcon } from './partner-history-utils';
*/
/*
	   0   1      2        3          4         5           6           7       8       9           10         11         12             13             14     15      16        17    18       19       20
select ps,saldo,uhrada,datdokladu,poznamka,datsplatnosti,koddokladu,zpozdeni,zpozdeni2,kodmeny,kodstrediska,kodpartnera,nazevpartnera,castkacelkemmen,saldo2,comm_type,note,comm_type2,note2,strediska,pojisteno
*/


export function Debts2({ userinfo, userlogin }) {

	/* var defaultcode = '';
	if (userinfo) {
		defaultcode = (userinfo.KodStrediska || "");
	} */

	const [debts, setDebts] = useState([]);
	const [loadedStatus, setLoadedStatus] = useState(0);

	const reloadIt = () => {
		setLoadedStatus(0);
		setDebts([]);
		loadIt();
	}

	const loadIt = useCallback(function () {
		his_fetch(
			userlogin,
			[
				{
					uri: "/api/debts",
					json: true,
					status: setLoadedStatus,
					ok: function (resource, result) {
						setDebts(result.debts);
						//console.log(debts);
						//console.log(currency);
					}
				}
			]
		)
	}, [userlogin]);

	useEffect(() => {
		if (debts.length === 0) {
			loadIt();
		}
	}, [userlogin, loadIt, debts.length]);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	/*
	const listDebts = debts.filter(
		function (debt) {
			// console.log(''+[filterPartly,debt[2]]);
			return (((saldoType === 0) ||
				(debt[14] > 0)) &&
				((filter_rule(filterCode, debt[10])) && // středisko
					(filter_rule(filterCode, debt[19])) && // možná seznam středisek
					(filter_rule(filterName, debt[12])) &&
					icompare(debt[1], filterSaldo) &&
					((filterInsurance === "") ||
						(get_insurance_status(debt[21], debt[20], debt[22], debt[23]) === filterInsurance)) &&
					(filter_rule(filterNote1, debt[4])) &&
					(filter_rule(filterInvoice, debt[6])) &&
					(filter_rule(filterNote2, debt[18], true)) &&
					(filter_rule(filterNote3, debt[16], true)) &&
					((filterPartly === null) ||
						((filterPartly === true) && (debt[2] !== null)) ||
						((filterPartly === false) && (debt[2] === null))) &&

					((massFilterApp.length === 0) || ((debt[6].toLowerCase().search(massFilterApp) >= 0) ||
						((debt[12] || "").toLowerCase().search(massFilterApp) >= 0) ||
						((debt[4] || "").toLowerCase().search(massFilterApp) >= 0) ||
						((debt[18] || "").toLowerCase().search(massFilterApp) >= 0) ||
						((debt[16] || "").toLowerCase().search(massFilterApp) >= 0)))
				));
		});
	*/

	const all = false;
	const late_invoices = debts.filter((d) => d[14] > 0 && d[8] >= 3 && (d[10] === 'ONJI' || filter_rule('ONJI', d[19])));
	const partners = late_invoices.map((d) => d[11]).filter((v, i, a) => a.indexOf(v) === i).map((c) => {
		return {
			code: c,
			invoices: debts.filter((d) => d[11] === c && d[14] > 0 && (all || d[8] >= 3))
		};
	});

	return (
		<ErrorWrap>
			<h3 className='d-inline pt-3'>Upomínky</h3>
			<Card body className='float-end text-white mb-3' bg="primary" >
				<h5 className='text-end mb-3'>Faktur: {late_invoices.length}</h5>
				<h5 className='text-end m-0'>Partnerů: &nbsp;&nbsp;{partners.length}</h5>
			</Card>
			<Table size="sm" >
				<thead className='beGray'>
					<tr>
						<th className='pb-2 pt-3 text-center'>Středisko</th>
						<th className='py-2'>Kód dokladu</th>
						<th className='py-2'>Poznámka</th>
						<th className="text-end py-2">Dokladové saldo</th>
						<th className='py-2'>Měna</th>
						<th className='text-center py-2'>Prodlení úhrady</th>
					</tr>
				</thead>
				<tbody>
					{partners.map((p) =>
						<>
							<tr className="bg-primary text-white">
								<th className='py-2 ps-2' colSpan={10}>
									<h5 className='mb-0'>{p.code}: {p.invoices[0][12]}</h5>
								</th>
							</tr>
							{p.invoices.map((inv) =>
								<tr>
									<td className='text-center'>{inv[10]}</td>
									<td>{inv[6]}</td>
									<td>{inv[4]}</td>
									<td className="text-end">{format_amount(inv[14])}</td>
									<td>{inv[9]}</td>
									<td className='text-center'>{inv[8]}</td>
								</tr>)}
						</>)}
				</tbody>
			</Table>
		</ErrorWrap>
	);

}

/*
function DebtsListWeb({ filterCode, handleChangeCode, handleChangeName, handleChangeNote2, handleChangeInvoice, handleChangeNote3,
	handleChangePartly, filterPartly, currency, handleChangeSaldo, handleChangeNote1, filterInsurance, handleChangeInsurance, listDebts,
	insuranceTitles, rstyle, saldo, currary, cidx, totalcurr, lstyle, lsaldo, latecurr, filterSaldo }) {

	return (
		<Table hover size="sm" striped className="d-none d-lg-table">
			<thead className="beGray">
				<tr>
					<th rowSpan="2" scope="col" className="text-center align-bottom">
						<Form.Group controlId="filterCode">
							<Form.Label>Středisko</Form.Label>
							<Form.Control type="text" placeholder="&#128269;" value={filterCode} onChange={handleChangeCode} />
						</Form.Group>
					</th>
					<th rowSpan="2" scope="col" className="text-center" style={{ width: "16%", paddingTop: "20px" }}>
						<Form.Group controlId="filterName" >
							<Form.Control type="text" placeholder="&#128269; Partner" onChange={handleChangeName} />
						</Form.Group>
						<Form.Group controlId="filterNote2">
							<Form.Control type="text" placeholder="&#128269; Poznámka" onChange={handleChangeNote2} />
						</Form.Group>
					</th>
					<th rowSpan="2" scope="col" className="text-center align-top" style={{ width: "16%", paddingTop: "20px" }}>
						<Form.Group controlId="filterInvoice">
							<Form.Control type="text" placeholder="&#128269; Kód dokladu " onChange={handleChangeInvoice} />
						</Form.Group>
						<Form.Group controlId="filterNote3">
							<Form.Control type="text" placeholder="&#128269; Poznámka" onChange={handleChangeNote3} />
						</Form.Group>
					</th>

					<th rowSpan="2" scope="col" className="text-center align-bottom" style={{ paddingBottom: "20px" }}>
						<p className="mb-2">Částečná<br />úhrada</p>
						<BooleanDropdown onChange={handleChangePartly} value={filterPartly}/>
					</th>

					<th rowSpan="2" scope="colgroup" className="text-center align-bottom">
						<Form.Group controlId="filterSaldo" className="mb-0">
							<Form.Label className="mb-1">Účetní Saldo</Form.Label>
							<Form.Control className="mb-3" type="text"
								placeholder="&#128269; > < ="
								value={filterSaldo} onChange={handleChangeSaldo} />
						</Form.Group>
					</th>
					<th rowSpan="2" scope="col" className="text-center align-bottom pb-4">Prodlení<br />úhrady</th>
					<th rowSpan="2" scope="col" className="text-center align-bottom">
						<Form.Group controlId="filterNote">
							<Form.Label>Poznámka</Form.Label>
							<Form.Control type="text" placeholder="&#128269;  " onChange={handleChangeNote1} />
						</Form.Group>
					</th>
					<th className="text-center align-bottom" colSpan="3" scope="col">Doklad celkem</th>
					<th rowSpan="2" scope="col" className="text-center align-bottom" style={{ paddingBottom: "20px" }}>
						<p className="mb-2">Pojištění</p>
						<DropdownButton id="dropdown-basic-button" title={insuranceTitles[filterInsurance]} variant="light">
							<Dropdown.Item onClick={() => handleChangeInsurance("")} >Všechny stavy</Dropdown.Item>
							<Dropdown.Item onClick={() => handleChangeInsurance(1)} ><Badge style={{ padding: "0.2em 1.1em", marginLeft: "2px" }} variant="success">Pojištěno</Badge> z pojistky</Dropdown.Item>
							<Dropdown.Item onClick={() => handleChangeInsurance(2)}><Badge style={{ marginLeft: "2px" }} variant="danger">Nepojištěno</Badge> ale pojistka zaplacená</Dropdown.Item>
							<Dropdown.Item onClick={() => handleChangeInsurance(3)}><Badge style={{ padding: "0.2em 1.1em", marginLeft: "2px" }} variant="info">Pojištěno</Badge> z autolimitu</Dropdown.Item>
							<Dropdown.Item onClick={() => handleChangeInsurance(4)} ><Badge style={{ padding: "0.2em 0.5em", marginLeft: "2px" }} variant="secondary">Nezajištěno</Badge> partner nepojištěn</Dropdown.Item>
							<Dropdown.Item onClick={() => handleChangeInsurance(0)} ><Badge style={{ padding: "0.2em 1em", marginLeft: "2px", backgroundColor: "#8080803d" }} >Neznámé</Badge> čeká na synchronizaci</Dropdown.Item>
						</DropdownButton></th>
				</tr>
				<tr>
					<th className="text-center align-middle" scope="col">Saldo</th>
					<th className="text-center align-middle" scope="col">Částka</th>
					<th className="text-center align-middle" scope="col">Měna</th>
				</tr>
			</thead>
			<tbody>
				{
					listDebts.map(function (pm) {
						var ostyle = {
							...rstyle,
							fontWeight: "bold",
							backgroundColor: delay_color(pm[8])
						};
						var partial = "";
						if (pm[2]) {
							partial = "Ano";
						} else {
							partial = "Ne";
						}
						var formatted_note = "";
						if (pm[16]) {
							const commdef = notes_comms[pm[15]];
							const emoji = commdef.emoji;
							const variant = commdef.variant;
							const text = commdef.text;
							const cls = "rounded p-1 me-1 bg-" + variant + " " + text;
							formatted_note = <>
								<br />
								<span className={cls}><img src={emoji} alt="" width="20" /></span>
								{pm[16]}
							</>;
						}
						var formatted_note2 = "";
						if (pm[18]) {
							const commdef = notes_comms[pm[17]];
							const emoji = commdef.emoji;
							const variant = commdef.variant;
							const text = commdef.text;
							const cls = "rounded p-1 me-1 bg-" + variant + " " + text;
							formatted_note2 = <>
								<br />
								<span className={cls}><img src={emoji} alt="" width="20" /></span>
								{pm[18]}
							</>;
						}
						const stredisko = (pm[10] === null) ? "(" + pm[19] + ")" : pm[10];
						return (
							<LinkContainer to={{ pathname: "/partners/list/" + pm[11] + "/debt" }} key={pm[0]}>
								<tr key={pm[0]}>
									<td className="align-middle" style={rstyle}>{stredisko}</td>
									<td className="align-middle" style={rstyle}>{pm[12]}{formatted_note2}</td>
									<td className="align-middle" style={rstyle}>{pm[6]}{formatted_note}</td>
									<td style={rstyle} className=" align-middle">{partial}</td>
									<td style={rstyle} className="text-end align-middle">{format_amount(pm[1])}</td>
									<td className="text-center align-middle" style={ostyle}>{pm[8]}</td>
									<td className="align-middle" style={rstyle}>{pm[4]}</td>
									<td className="text-end align-middle">{format_amount(pm[14])}</td>
									<td className="text-end align-middle">{format_amount(pm[13])}</td>
									<td className="align-middle" style={rstyle}>{pm[9]}</td>
									<td className="align-middle"><Insured3 status={get_insurance_status(pm[21], pm[20], pm[22], pm[23])} /></td>
								</tr>
							</LinkContainer>
						);
					})
				}
				<tr>
					<td colSpan="4"><strong>Saldo celkem</strong></td>
					<td className="text-end">{format_amount(saldo)}</td>
					<td colSpan="4" className="text-end">
						{
							currary.map(function (el) {
								cidx += 1;
								return <React.Fragment key={cidx}>{format_amount(totalcurr[el])}<br /></React.Fragment>;
							})
						}
					</td>
					<td>
						{
							currary.map(function (el) {
								cidx += 1;
								return <React.Fragment key={cidx}>{el}<br /></React.Fragment>;
							})
						}
					</td>
					<td></td>
				</tr>
				<tr style={lstyle}>
					<td colSpan="4"><strong>Saldo po splatnosti</strong></td>
					<td className="text-end">{format_amount(lsaldo)}</td>
					<td colSpan="4" className="text-end">
						{
							currary.map(function (el) {
								cidx += 1;
								return <React.Fragment key={cidx}>{format_amount(latecurr[el])}<br /></React.Fragment>;
							})
						}
					</td>
					<td>
						{
							currary.map(function (el) {
								cidx += 1;
								return <React.Fragment key={cidx}>{el}<br /></React.Fragment>;
							})
						}
					</td>
					<td></td>
				</tr>
			</tbody>
		</Table>
	);
}

function single_debt_func(pm, acc_currency) {
	const stredisko = (pm[10] === null) ? "(" + pm[19] + ")" : pm[10];
	const keyx = pm[6];
	return (
		<SingleDebtFull
			keyx={keyx}
			key={keyx}
			id={keyx}
			issue_date={pm[3]}
			due_date={pm[5]}
			partlyPaid={pm[2]}
			delay={pm[7]}
			hold={pm[8]}
			saldo_acc={pm[1]}
			saldo_curr={pm[14]}
			currency={pm[9]}
			amount={pm[13]}
			note={pm[16]}
			note_type={pm[15]}
			item={pm[4]}
			acc_currency={acc_currency}
			center={stredisko}
			partner={pm[12]}
			note2={pm[18]}
			note2_type={pm[17]}
			FIC={pm[21]}
			named_insured={pm[20]}
			auto_insured={pm[22]}
			auto_country_ok={pm[23]}
			partnerCode={pm[11]}
		/>
	);
}


function SingleDebtFull({ keyx, id, delay, hold, issue_date, due_date, currency, amount, saldo_curr, saldo_acc, item, note, note_type, partlyPaid, acc_currency, center, partner, note2, note2_type, FIC, named_insured, auto_insured, auto_country_ok, partnerCode }) {
	return (
		<Card key={keyx}>
			<Accordion.Toggle as={Card.Header} className="smaller" eventKey={keyx}> //old accordion style from previous bootsrap
				<Row>
					<Col xs={6}>
						{center}
					</Col>
					<Col xs={6} className="text-end">
						{partner}
					</Col>
				</Row>
				<PlainNoteWithIcon note={note2} note_type={note2_type} />
				<Row>
					<Col className="beBigger">{id}</Col>
					<DelayOrHold label="Zpoždění" number={delay} />
					<DelayOrHold label="Prodlení" number={hold} />
				</Row>
				<Row className="g-0">
					<Col xs="3" className="text-end">{format_amount(amount)}</Col>
					<Col xs="auto">&nbsp;{currency} celkem</Col>
				</Row>
				<Row className="g-0">
					<Col xs="3" className="text-end">{format_amount(saldo_curr)}</Col>
					<Col xs="5">&nbsp;{currency} dokladové saldo</Col>
					<Col xs="4" className="text-end">
						<NoteIcon note={note} note_type={note_type} />
						<Insured3 status={get_insurance_status(FIC, named_insured, auto_insured, auto_country_ok)} />
					</Col>
				</Row>
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={keyx}>
				<LinkContainer to={{ pathname: "/partners/list/" + partnerCode + "/debt" }}>
					<Card.Body className="beSmaller">
						<ListGroup variant="flush" className="smaller">
							<ListGroup.Item>
								<Row className="g-0">
									<Col xs="3" className="text-end">{format_amount(saldo_acc)}</Col>
									<Col xs="4">&nbsp;{acc_currency} účetní saldo</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								{partlyPaid === null ? <span>Částečně uhrazeno: Ne</span> : <span>Částečně uhrazeno: Ano</span>}
							</ListGroup.Item>
							<NoteWithIcon note={note} note_type={note_type} />
							<ListGroup.Item>{item}</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</LinkContainer>
			</Accordion.Collapse>
		</Card>
	);
}

*/