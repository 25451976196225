export const mesice = [
    ["01", "jan"], // 0
    ["02", "feb"], // 1 ...
    ["03", "mar"],
    ["04", "apr"],
    ["05", "may"],
    ["06", "jun"],
    ["07", "jul"],
    ["08", "aug"],
    ["09", "sep"],
    ["10", "oct"],
    ["11", "nov"],
    ["12", "dec"]
];

export const weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
