/**
 * Multiple selecting filtering dropdown.
 * @module comp/multiple-select
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from 'react';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useTranslation } from 'react-i18next';

//seen all - OK


/**
 * Function, which returns title for multiselect.
 * 
 * @param {any} checked - dictionary of items with true or false value
 * @param {boolean} allValuesChecked - true, if all options are selected
 * @param {any} itemsNames - dictionary with names of items in multiselect
 * @param {function} t - translate function
 * @returns {string}
 */
function make_title(checked, allValuesChecked, itemsNames, t) {
	const indexesOfChosen = Object.keys(checked).filter((v) => checked[v]);
	const numberOfItems = indexesOfChosen.length;
	if (allValuesChecked) {
		return t('all');
	} else if (numberOfItems === 0) {
		return t('not_choosen');
	} else if (numberOfItems === 1) {
		return t(itemsNames[indexesOfChosen]);
	} else if (numberOfItems < 6) {
		return indexesOfChosen.join(", ");
	} else {
		return indexesOfChosen.slice(0, 5).join(", ") + "...";
	}
}
/**
 * Multiselect component
 * 
 * @param {any} checked - state for keeping multiselect choice - dictionary
 * @param {function} setChecked - function for changing multiselect choice
 * @param {any} dictionaryTrue - disctionary of all true values
 * @param {any} dictionaryFalse - dictionary of all false values
 * @param {any} itemsNames - dictionary with names of multiselect options
 * @param {function} setOffset - for setting offset of Pager component, when items on the page are reduced
 * @param {string} id - specific string, its useful, when more multiselects are used on one page - they would interfere with just one id
 * @param {boolean} withoutNumber - if indexes of values should be used for option names in dropdown
 * @param {string} width - width of dropdown 
 * @returns {component}
 */
export function MultipleSelect({ checked, setChecked, dictionaryTrue, dictionaryFalse, itemsNames, setOffset, id, withoutNumber = false, width="inherit" }) {
	const { t } = useTranslation();

	const allValuesChecked = Object.keys(checked).filter((v) => checked[v]).length === Object.keys(checked).length;
	return (
		<DropdownButton id={id} title={make_title(checked, allValuesChecked, itemsNames, t)} variant="light" className='mb-1' menualign="right">
			<div className='mx-3 mt-1' style={{width: width}}>
				<Form.Check className='fw-normal mb-1' id={id + "0"} type="checkbox" label={t('all')}
					checked={allValuesChecked}
					onChange={() => { setChecked(allValuesChecked ? dictionaryFalse : dictionaryTrue); setOffset(0) }}
				/>
				{
					Object.keys(itemsNames).map(function (key) {
						const newValue = checked[key] ? false : true;
						const label = withoutNumber ? t(itemsNames[key]) : key + " – " + t(itemsNames[key]);
 						return <Form.Check key={key}
							className='fw-normal mb-1'
							id={id + key + 1}
							type="checkbox"
							label={label}
							checked={checked[key]}
							onChange={() => { setChecked({ ...checked, [key]: newValue }); setOffset(0) }}
						/>;
					})
				}
			</div>
		</DropdownButton>
	);
}