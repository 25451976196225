import { DeepPartial, UseFormWatch } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";

export function useDebounceFilterValuesCallback<T>(
  watch: UseFormWatch<T>,
  filtersChangedCallback?: (values: DeepPartial<T>) => void,
  delay: number = 500
) {
  const debouncedCallback = useDebouncedCallback((v: DeepPartial<T>) => filtersChangedCallback?.(v), delay);

  useEffect(() => {
    const subscription = watch((value) => debouncedCallback(value));

    return () => subscription.unsubscribe();
  }, [watch, debouncedCallback]);
}

export function useDebounceFilterValues<T>(watch: UseFormWatch<T>, defaultValue?: T, delay: number = 500) {
  const [filter, setFilter] = useState(defaultValue);

  useDebounceFilterValuesCallback(watch, (v) => setFilter((p) => ({ ...p, ...v })), delay);

  return [filter];
}
