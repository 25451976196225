/**
 * Insurance section navigation and fetcher for global insurance data
 * @module insurance/insurance
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { Routes, Route } from "react-router-dom";
import { InsuranceList } from './insurance-list';
import { InsuranceInfo } from './insurance-info';
import { InsuranceReport } from './insurance-report';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { HISInfo, LastSynctimes } from '../his-info';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - ok

export function Insurance({ userlogin }) {
    const { t } = useTranslation();

    const [limitscurr, setLimitscurr] = useState(null);
    const [freefics, setFreeFics] = useState([]);
    const [totalinsured, setTotalInsured] = useState(0);
    const [currency, setCurrency] = useState("XXX");
    const [loadedStatus, setLoadedStatus] = useState(0);

    const reloadIt = () => {
        setLimitscurr(null);
        setFreeFics([]);
        setTotalInsured(0);
        setCurrency("XXX");
        setLoadedStatus(0);
    }


    useEffect(() => {
        if (limitscurr === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/credendo/info",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            setLimitscurr(result.limitscurr);
                            //console.log("limitscurr: " +result.limitscurr);
                            setFreeFics(result.freefics);
                            //console.log("freefics: " +result.freefics);
                            setTotalInsured(result.totalinsured);
                            //console.log("totalinsured: " +result.totalinsured);
                            setCurrency(result.currency);
                            //console.log("result: " + result);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setLimitscurr("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, limitscurr]);

    //console.log(loadedStatus);


    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt}
        />;
    }

    return (
        <ErrorWrap>
            <Nav variant="tabs" defaultActiveKey="/insurance/info">
                <LinkContainer to="/insurance/info">
                    <Nav.Link eventKey="/insurance/info">{t('menu2-ins-info')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/insurance/limits">
                    <Nav.Link eventKey="/insurance/limits">{t('menu2-ins-limit')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/insurance/report">
                    <Nav.Link eventKey="/insurance/report">{t('menu2-ins-turnover_rep')}</Nav.Link>
                </LinkContainer>
            </Nav>
            <HISInfo userlogin={userlogin} TableComponent={LastSynctimes} />
            <br />
            <Routes>
                <Route path="info" element={<InsuranceInfo limitscurr={limitscurr} freefics={freefics} totalinsured={totalinsured} currency={currency} />} />
                <Route path="limits" element={<InsuranceList userlogin={userlogin} />} />
                <Route path="report" element={<InsuranceReport />} />
            </Routes>
        </ErrorWrap>
    );
}