import { authorizedFetch } from "./common";

export function getPurchaseOrders(
  from: string,
  to: string,
  take: number = 10,
  skip: number = 0,
  filter: unknown = {},
  orderBy: string = undefined,
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetPurchaseOrdersItemRangePagedResponse>(
    `/api/orders/purchase/range/${from}/${to}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}` +
      (orderBy ? `&orderBy=${orderBy}` : ""),
    { ...options, method: "GET" }
  );
}

export function getSaleOrders(
  from: string,
  to: string,
  take: number = 10,
  skip: number = 0,
  filter: unknown = {},
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetSaleOrdersPagedItemRangePagedResponse>(
    `/api/orders/sale/range/${from}/${to}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    { ...options, method: "GET" }
  );
}

export function getPurchaseOrder(orderCode: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetPurchaseOrderDetailResponse>(`/api/orders/purchase/${orderCode}/new`, {
    ...options,
    method: "GET",
  });
}

export function getPurchaseOrderOpenNotifications(orderId: number, options?: RequestInit) {
  return authorizedFetch<Dto.GetOrderOpenStockNotificationsResponseItem[]>(
    `/api/stock-notifications/${orderId}/open/new`,
    {
      ...options,
      method: "GET",
    }
  );
}

export function purchaseOrderOpenNotification(orderId: number, options?: RequestInit) {
  return authorizedFetch<Dto.SimpleResult>(`/api/stock-notifications/${orderId}/open`, {
    ...options,
    method: "POST",
  });
}

export function purchaseOrderCloseNotification(orderId: number, options?: RequestInit) {
  return authorizedFetch<Dto.SimpleResult>(`/api/stock-notifications/${orderId}/open`, {
    ...options,
    method: "DELETE",
  });
}

export function purchaseOrderCreateNotification(
  orderId: number,
  data: Dto.PostCreateOrderStockNotificationData,
  options?: RequestInit
) {
  return authorizedFetch<Dto.SimpleResult>(`/api/stock-notifications/${orderId}/create`, {
    ...options,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
}

export function closeOpenStockNotification(orderId: number, userId: number, options?: RequestInit) {
  return authorizedFetch<Dto.SimpleResult>(`/api/stock-notifications/${orderId}/open/${userId}`, {
    ...options,
    method: "DELETE",
  });
}
