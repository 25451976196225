/**
 * List of delivery notes, app and web version
 * @module delivery-notes/delivery-notes-sales
 * @author Miroslav Lhoťan <miroslav.lhotan@netglade.cz>
 */

import React, { useState, useCallback } from "react";
import { PDListMobile } from "./delivery-notes-sales-app";
import { DeliveryNotesSalesWeb } from "./delivery-notes-sales-web";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { Pager } from "../comp/pager";
import { DynamicLoadPaged, DynamicLoadPagedProps } from "../comp/DynamicLoadPaged";
import { FormProvider, useForm } from "react-hook-form";
import { DeliveryNotesWebFiltersValues } from "./DeliveryNotesWebFilters";
import { useQuery } from "@tanstack/react-query";
import { getDeliveryNotesFrom } from "../api/deliveryNotes";
import { queryStatusToLoadedStatus } from "../api/common";
import { useCachedOffsetAndFilter } from "../comp/FilterCacheProvider";

const defaultFilters: DeliveryNotesWebFiltersValues = {
  deliveryNoteCode: "",
  orderCode: "",
  partnerCode: "",
  partnerName: "",
  note: "",
  issuedBy: "",
  exported: null,
  imported: null,
  closed: null,
  approved: null,
  cancelled: null,
  massFilter: "",
};

interface DeliveryNotesListPagedProps {
  type?: "PD" | "ND";
}

export function DeliveryNotesListPaged({ type = "PD" }: DeliveryNotesListPagedProps) {
  const [from, setFrom] = useState<string>(undefined);

  const form = useForm({ mode: "onChange", defaultValues: defaultFilters });
  const { watch, reset } = form;
  const [offset, setOffset, filter] = useCachedOffsetAndFilter(
    type === "PD" ? "deliveryNotesPD" : "deliveryNotesND",
    watch,
    reset,
    defaultFilters
  );

  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["deliveryNotes", type, from, offset, filter],
    queryFn: ({ signal }) => {
      if (!from)
        return Promise.resolve<Dto.GetDeliveryNotesRangePagedItemRangePagedResponse>({
          items: [],
          totalCount: 0,
          filteredCount: 0,
          rangeCount: 0,
          count: 0,
        });

      return getDeliveryNotesFrom(type, from, 20, offset, filter, { signal });
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  function cleanFilters() {
    form.reset(defaultFilters, {});
    setOffset(0);
  }

  const loadData = useCallback<DynamicLoadPagedProps["loadData"]>((rangeFrom, rangeTo) => {
    setFrom(rangeFrom);
  }, []);

  const isLoaded = loadedStatus === 2;

  return (
    <ErrorWrap>
      <DynamicLoadPaged
        period="1y"
        loadData={loadData}
        reloadIt={() => refetch()}
        offset={offset}
        setOffset={setOffset}
        loadingState={loadedStatus}
        totalCount={data?.totalCount}
        filteredCount={data?.filteredCount}
        rangeCount={data?.rangeCount}
        cleanFilters={cleanFilters}
      />
      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          <DeliveryNotesSalesWeb dnotes_shown={data?.items} type={type} isLoaded={data?.items?.length > 0} />

          <PDListMobile
            dnotes_shown={data?.items}
            offset={offset}
            setOffset={setOffset}
            type={type}
            isLoaded={data?.items?.length > 0}
          />
        </FormProvider>
      </TableFetchStatusOverlay>
      <Pager
        offset={offset}
        pagesize={20}
        disabled={isLoaded === false}
        total={data?.filteredCount}
        callback={setOffset}
      />
    </ErrorWrap>
  );
}
