/**
 * Partner payments history app view.
 * @module partners/partner-payments-history-app
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { LinkContainer } from 'react-router-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { DelayOrHold, NoteIcon, NoteWithIcon } from './partner-history-utils';
import { Pager } from '../comp/pager';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import { Loading } from '../comp/loading';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerPaymentsHistoryApp({ debts, acc_currency, partner, offset, setOffset }) {

    var idx = 0;
    const debts_acc = debts == null ? null : debts.slice(offset, offset + 20);
    const debts_length = debts == null ? 0 : debts.length;

    return (
        <ErrorWrap>
            <div className="d-block d-lg-none">
                <Pager offset={offset} pagesize={20} total={debts_length} callback={setOffset} />
                <br />
                <br />
                {debts == null ? <Loading />
                    :
                    <Accordion>
                        {debts_acc.map(function (debt) {
                            idx += 1;
                            return (
                                <React.Fragment key={idx}>
                                    {single_debt_history_func(debt, acc_currency, partner)}
                                </React.Fragment>
                            );
                        })}
                    </Accordion>
                }
            </div>
        </ErrorWrap>
    );
}

function single_debt_history_func(debt, acc_currency, partner) {
    const kodDokladu = debt[6];
    return (
        <SingleDebtHistory
            keyx={kodDokladu}
            id={kodDokladu}
            delay={debt[7]}
            hold={debt[8]}
            issue_date={date_formatCZ(debt[3])}
            due_date={date_formatCZ(debt[5])}
            currency={debt[9]}
            saldo_acc={format_amount(debt[1])}
            saldo_acc_num={debt[1]}
            item={debt[4]}
            note={debt[11]}
            note_type={debt[10]}
            partlyPaid={date_formatCZ(debt[2])}
            acc_currency={acc_currency}
            FIC={debt[14]}
            named_insured={debt[13]}
            auto_insured={debt[15]}
            auto_country_ok={partner.credendo_country_ok}

        />
    );
}

function SingleDebtHistory({ keyx, id, delay, hold, issue_date, due_date, currency, saldo_acc, saldo_acc_num, item, partlyPaid, acc_currency, note, note_type, FIC, named_insured, auto_insured, auto_country_ok }) {
    const { t } = useTranslation();

    return (
        <Accordion.Item eventKey={keyx}>
            <Accordion.Header as="div">
                <div className='container-fluid p-0 pe-3'>
                    <Row>
                        <Col className="beBigger">{id}</Col>
                        <DelayOrHold label={t('biz-delay')} number={delay} />
                        {/** <DelayOrHold label={t('biz-delay2')} number={hold} /> */}
                    </Row>
                    <Row>
                        <Col xs="6">{saldo_acc} {acc_currency} {t('biz-acc_balance')}</Col>
                        <Col xs="6" className="text-end">{t('issued')}: {issue_date}</Col>
                    </Row>
                    <Row className="g-0">
                        <Col xs="12" className="text-end">
                            <NoteIcon note={note} note_type={note_type} />
                            <Insured3 status={get_insurance_status(FIC, named_insured, auto_insured, auto_country_ok)} />
                        </Col>
                    </Row>
                </div>
            </Accordion.Header>
            <Accordion.Body className='p-2' >
                <LinkContainer to={{ pathname: "/partners/invoices-issued/" + id }}>
                    <Card.Body className="beSmaller">
                        <ListGroup variant="flush" className="smaller">
                            <ListGroup.Item>
                                <Row className="g-0">
                                    <Col xs="6"><span>{t('biz-paid')}: {partlyPaid}</span></Col>
                                    <Col xs="6" className="text-end">&nbsp;{t('biz-due')}: {due_date}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row className="g-0">
                                    <Col xs="7">{item}</Col>
                                    <Col xs="5" className="text-end">{t('biz-doc_currency')}: {currency}</Col>
                                </Row>
                            </ListGroup.Item>
                            <NoteWithIcon note={note} note_type={note_type} />
                        </ListGroup>
                    </Card.Body>
                </LinkContainer>
            </Accordion.Body>
        </Accordion.Item>
    );
}
