import React from "react";
import { Downloader } from "../../comp/downloader";
import Card from "react-bootstrap/Card";
import { Badge } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Boolean } from "../../comp/boolean";
import { ShowADR } from "../../comp/adr";
import { partnerLogoShort, wid2partner, whColor, whID2whNumber } from "../../lists/warehouses-defs";
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from "../product-view";
import { make_product_check_tag } from "../product-checks";
import { ErrorWrap } from "../../comp/errorwrap";
import { MandatoryAddressPart } from "../../partners/partner-detail";
import { LinkContainer } from "react-router-bootstrap";
import { mandatory_format } from "../product-utils";
import { date_formatCZ } from "../../lib/date-utils";
import { useTranslation } from "react-i18next";
import Fraction from "fraction.js";
import { useSendAndCancelCard, useWarehouseSendingCardStatus } from "./productDetailHooks";
import { PreprocessedHistory, PreprocessedWarehouse } from "./ProductDetail";

interface HistoryTableWebProps {
  preprocessedHistory: PreprocessedHistory[];
}

export function HistoryTableWeb({ preprocessedHistory }: HistoryTableWebProps) {
  const { t } = useTranslation();
  return (
    <Table bordered size="sm" className="mb-0 d-none d-lg-table">
      <thead className="beGray">
        <tr>
          <th className="ps-4">{t("warehouse")}</th>
          <th className="text-end">{t("prod-created")}</th>
          <th className="text-end">{t("prod-invalidated")}</th>
          <th className="text-center">{t("name")}</th>
          <th className="text-center">{t("ord-pack")}</th>
          <th className="text-center">{t("prod-amount_MJ")}</th>
          <th className="text-center">{t("ord-density")}</th>
          <th className="text-center">{t("ord-1_pack_weight")}</th>
          <th className="text-center">{t("prod-class")}</th>
          <th className="text-center">{t("prod-pack-group")}</th>
          <th className="text-center">{t("ord-un_num")}</th>
          <th>{t("prod-safe_list")}</th>
          <th>{t("ord-batch")}</th>
          <th>{t("prod-bbd")}</th>
        </tr>
      </thead>
      <tbody>
        {preprocessedHistory.map(function (wh) {
          return wh.history.map(function (h, idx) {
            const package_weight_kg_wh_count = new Fraction(h.packageUnitOfMeasurementQuantity).mul(
              new Fraction(h.unitOfMeasurementWeightInKg)
            );
            return (
              <tr key={idx}>
                {idx === 0 ? (
                  <td rowSpan={wh.history.length} className="align-middle text-center">
                    {wh.warehouseId}
                  </td>
                ) : (
                  <></>
                )}
                <td className="text-end">{date_formatCZ(h.created)}</td>
                <td className="text-end">{date_formatCZ(h.invalidated)}</td>
                <td className="text-center">{h.productName}</td>
                <td className="text-center">{h.hshPackage}</td>
                <td className="text-center">
                  {h.packageUnitOfMeasurementQuantity} {h.storageUnitOfMeasurementCode}
                </td>
                <td className="text-center">
                  {h.unitOfMeasurementWeightInKg} {t("prod-kg")}
                </td>
                <td className="text-center">
                  {String(package_weight_kg_wh_count)} {t("prod-kg")}
                </td>

                <td className="text-center">{h.adrClass}</td>
                <td className="text-center">{h.adrPackingGroup}</td>
                <td className="text-center">{h.adrUnNumber}</td>
                <td className="text-center">
                  {h.safetySheet === null ? "?" : <Boolean value={h.safetySheet} variant="true_false" />}
                </td>
                <td>{mandatory_format(h.gwCheckBatch)}</td>
                <td>{mandatory_format(h.gwCheckBbd)}</td>
              </tr>
            );
          });
        })}
      </tbody>
    </Table>
  );
}

export interface ProductCompareProps {
  product: Dto.ProductDetailProduct;
  ErrorBadge: React.FunctionComponent<{ tag: string }>;
  package_weight_kg_hsh: number;
  preprocessedWarehouses: PreprocessedWarehouse[];
  approved: boolean;
  configured: boolean;
  no_errors: boolean;
}

export function ProductCompareWeb({
  product,
  ErrorBadge,
  package_weight_kg_hsh,
  preprocessedWarehouses,
  approved,
  configured,
  no_errors,
}: ProductCompareProps) {
  const { isWarehouseSendingCard } = useWarehouseSendingCardStatus(product.productId);

  return (
    <Table striped size="sm" bordered className="bg-white m-0 d-none d-lg-table">
      <ProductTableHeaderWeb product={product} />
      <tbody>
        <ProductLineHSHWeb product={product} ErrorBadge={ErrorBadge} package_weight_kg_hsh={package_weight_kg_hsh} />
        {preprocessedWarehouses.map(function (wh, idx) {
          const disableSend =
            (wh.states_1_2_3 && !wh.readback_confirmed) ||
            (wh.ADRBelongsToADRWarehouse && !wh.compliance_can_send_ADR_anywhere) ||
            isWarehouseSendingCard(wh.warehouseId) ||
            !approved ||
            !configured ||
            !no_errors;
          return (
            <ProductLineWarehouseWeb
              key={idx}
              wh={wh}
              ErrorBadge={ErrorBadge}
              product={product}
              disableSend={disableSend}
            />
          );
        })}
      </tbody>
    </Table>
  );
}

export interface ProductLineWarehouseProps {
  wh: PreprocessedWarehouse;
  ErrorBadge: React.FunctionComponent<{ tag: string }>;
  product: Dto.ProductDetailProduct;
  disableSend: boolean;
}

function ProductLineWarehouseWeb({ wh, ErrorBadge, product, disableSend }: ProductLineWarehouseProps) {
  const { t } = useTranslation();
  const { isWarehouseSendingCard, warehouseSendingCardError } = useWarehouseSendingCardStatus(product.productId);
  const { sendCard, cancelSending } = useSendAndCancelCard(product.productId);

  return (
    <tr key={wh.warehouseId}>
      {wh.lineRowSpan > 0 ? (
        <td rowSpan={wh.lineRowSpan} className="align-middle ps-4">
          {partnerLogoShort[wid2partner[wh.warehouseId]]}
        </td>
      ) : (
        <></>
      )}
      <td className="text-center align-middle">{whID2whNumber[wh.warehouseId]}</td>
      {wh.isProductInWarehouse ? (
        <>
          <td className="align-middle">
            <span className={wh.superClass5}>{wh.nazevPrefix}</span>
            {wh.nazevSuffix}
          </td>
          <ProductContainerView
            id={"wh-" + wh.warehouseId}
            wid={wh.warehouseId}
            container={wh.piw.hshPackage}
            remap
            ErrorBadge={ErrorBadge}
          />
          <DensityAmountUnitWeightView
            id={"wh-" + wh.warehouseId}
            density={wh.piw.unitOfMeasurementWeightInKg}
            densityUnit="kg"
            amount={wh.piw.packageUnitOfMeasurementQuantity}
            unit={wh.piw.storageUnitOfMeasurementCode}
            packageWeight={wh.package_weight_kg_wh}
            ErrorBadge={ErrorBadge}
          />
          <ProductADRView
            id={"wh-" + wh.warehouseId}
            TridaADR={wh.piw.adrClass}
            k_ADRObalovaSkupina={wh.piw.adrPackingGroup}
            k_ADRUNCislo={wh.piw.adrUnNumber}
            romanized
            ErrorBadge={ErrorBadge}
          />
        </>
      ) : (
        <td colSpan={8} className="text-center align-middle">
          &mdash;
        </td>
      )}
      {wh.lineRowSpan > 0 ? (
        <td rowSpan={wh.lineRowSpan} className="text-center align-middle">
          <Downloader
            img="/application-excel.svg"
            alt="xlsx"
            height="25"
            label={wid2partner[wh.warehouseId].toLowerCase()}
            path={"/api/products/" + product.productCode + "/card/" + wid2partner[wh.warehouseId]}
            filename={"karta-" + product.productId + "-" + wid2partner[wh.warehouseId].toLowerCase() + ".xlsx"}
          />
        </td>
      ) : (
        <></>
      )}
      <td className="text-center align-middle">{wh.warehouse_status_str}</td>
      <td className="text-center align-middle">{wh.sent_status_str}</td>
      <td className={"text-center align-middle" + wh.received_class}>{wh.received_status_str}</td>
      <td className={wh.readback_class}>{wh.readback_datetime_str}</td>
      <td className="text-center align-middle">{wh.stavKontroly}</td>
      <td className="text-start align-middle">
        {wh.states_1_2_3 && !wh.readback_confirmed ? (
          ""
        ) : (
          <>
            {isWarehouseSendingCard(wh.warehouseId) ? (
              <Button style={{ backgroundColor: whColor[wid2partner[wh.warehouseId]], border: "none" }} disabled>
                {wh.isProductInWarehouse ? t("updating") : t("sending")}
              </Button>
            ) : (
              <>
                <Button
                  title={wh.title}
                  style={{ backgroundColor: whColor[wid2partner[wh.warehouseId]], border: "none" }}
                  onClick={() => sendCard(wh.warehouseId)}
                  disabled={disableSend}
                >
                  {wh.isProductInWarehouse
                    ? t("update") + " " + whID2whNumber[wh.warehouseId]
                    : t("prod-sent_to") + " " + whID2whNumber[wh.warehouseId]}
                  {wh.warehouseId === 22 || wh.warehouseId === 30 ? (
                    ""
                  ) : (
                    <ErrorBadge tag={make_product_check_tag("hsh", "isADR")} />
                  )}
                </Button>
              </>
            )}
          </>
        )}
        {wh.show_timeDiff}
        {wh.showCancel ? (
          <Button onClick={() => cancelSending(wh.warehouseId)} className="mt-1" size="sm" variant="danger">
            {t("storno")}
          </Button>
        ) : (
          ""
        )}
        <br />
        <small className="text-danger">{warehouseSendingCardError(wh.warehouseId)}</small>
      </td>
    </tr>
  );
}

function ProductTableHeaderWeb({ product }) {
  const { t } = useTranslation();
  return (
    <thead className="beGray">
      <tr>
        <th colSpan={10} className="text-center">
          {t("prod-prod_card")} <small>({t("prod-hsh_wh_comparison")})</small>
        </th>
        <th rowSpan={3}>{t("prod-xlsx_card")}</th>
        <th rowSpan={3}>{t("prod-communication_status")}</th>
        <th rowSpan={3}>{t("prod-sent(f)")}</th>
        <th rowSpan={3}>{t("prod-accepted")}</th>
        <th rowSpan={3}>{t("prod-readback")}</th>
        <th rowSpan={3}>{t("prod-check_state")}</th>
        <th rowSpan={3}>{t("action")}</th>
      </tr>
      <tr>
        <th rowSpan={2} className="ps-4">
          {t("partner")}
        </th>
        <th rowSpan={2}>{t("warehouse")}</th>
        <th rowSpan={2}>{t("name")}</th>
        <th colSpan={4} className="text-center">
          {t("prod-package")}
        </th>
        <th colSpan={3} className="text-center">
          {t("prod-ADR")} – <Boolean value={product.adr} />
          <ShowADR isADR={product.adr} height={25} />
        </th>
      </tr>
      <tr>
        <th className="text-center">{t("ord-pack")}</th>
        <th className="text-center">{t("ord-content_w_unit")}</th>
        <th className="text-center">{t("ord-density")}</th>
        <th className="text-center">{t("ord-1_pack_weight")}</th>
        <th className="text-center">{t("prod-class")}</th>
        <th className="text-center">{t("prod-pack-group")}</th>
        <th className="text-center">{t("ord-un_num")}</th>
      </tr>
    </thead>
  );
}

function ProductLineHSHWeb({ product, ErrorBadge, package_weight_kg_hsh }) {
  return (
    <tr>
      <td className="text-center align-middle" colSpan={2}>
        <Image src="/hsh.png" style={{ width: "50px", padding: "2px" }} />
      </td>
      <td className="align-middle">{product.productName}</td>
      <ProductContainerView id="hsh" container={product.hshPackage} ErrorBadge={ErrorBadge} />
      <DensityAmountUnitWeightView
        id={"hsh"}
        density={product.unitOfMeasurementWeight}
        densityUnit={product.unitOfMeasurementUnit}
        amount={product.packageUnitOfMeasurementQuantity}
        unit={product.storageUnitOfMeasurementCode}
        packageWeight={package_weight_kg_hsh}
        ErrorBadge={ErrorBadge}
      />
      <ProductADRView
        id="hsh"
        TridaADR={product.adrClass}
        k_ADRObalovaSkupina={product.adrPackingGroup}
        k_ADRUNCislo={product.adrUnNumber}
        ErrorBadge={ErrorBadge}
      />
      <td colSpan={7} className="text-center align-middle">
        &mdash;
      </td>
    </tr>
  );
}

export function IsActive({ active }) {
  const { t } = useTranslation();
  const variant = active ? "success" : "danger";
  const text = active ? t("active") : t("inactive");
  return (
    <ErrorWrap>
      <Badge className="mx-2" pill bg={variant}>
        {text}
      </Badge>
    </ErrorWrap>
  );
}

interface PackagingsProps {
  packagings: Dto.ProductDetailPackaging[];
  ErrorBadge: React.FunctionComponent<{ tag: string }>;
}

export function Packagings({ packagings, ErrorBadge }: PackagingsProps) {
  const { t } = useTranslation();
  return (
    <ErrorWrap>
      <Card className="myShadow">
        <Card.Body className="p-0">
          <h5 className="mb-3 mt-3 ms-4">{t("prod-packages")}</h5>
          <Table size="sm" className="mb-4">
            <thead className="beGray">
              <tr>
                <th className="ps-4">
                  {t("prod-basic_pack")}
                  <ErrorBadge tag={make_product_check_tag("hsh", "packagings")} />
                </th>
                <th>{t("prod-pack_code")}</th>
                <th>{t("prod-pack_type")}</th>
                <th className="text-end pe-3">{t("ord-amount_measure_unit")}</th>
              </tr>
            </thead>
            <tbody>
              {packagings.map(function (pa, idx) {
                return (
                  <tr key={idx}>
                    <td className="ps-4">
                      <Boolean value={pa.basic} variant="onlyTrue" />
                    </td>
                    <td>{pa.packagingCode}</td>
                    <td>{pa.packagingType}</td>
                    <td className="text-end pe-3">
                      {pa.packageUnitOfMeasurementQuantity}&nbsp;{pa.unitOfMeasurementCode}{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </ErrorWrap>
  );
}

export function AltNames({ names }) {
  const { t } = useTranslation();
  return (
    <ErrorWrap>
      <Card className="myShadow">
        <Card.Body className="p-0">
          <h5 className="mb-3 mt-3 ms-4">{t("prod-alt_names")}</h5>
          {names.length === 0 ? (
            <p className="text-muted m-4">{t("prod-not_registered")}</p>
          ) : (
            <Table size="sm" className="mb-4">
              <thead className="beGray">
                <tr>
                  <th className="ps-4">{t("biz-partner_code")}</th>
                  <th>{t("biz-partner_name")}</th>
                  <th>{t("prod-is_supplier")}?</th>
                  <th>{t("prod-code")}</th>
                  <th>{t("prod-name")}</th>
                </tr>
              </thead>
              <tbody>
                {names.map(function (an, idx) {
                  return (
                    <tr key={idx}>
                      <td className="ps-4">{an.partnerCode}</td>
                      <td>{an.partnerName}</td>
                      <td>
                        <Boolean value={an.supplier} variant="onlyTrue" />
                      </td>
                      <td>{an.externalProductCode}</td>
                      <td>{an.externalProductName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </ErrorWrap>
  );
}

export function BasicInfo({ product }) {
  const { t } = useTranslation();

  return (
    <ErrorWrap>
      <Card body className="myShadow">
        <Table borderless size="sm" className="p-0 m-0">
          <tbody>
            <tr>
              <td className="text-end text-muted p-0">{t("code")} &nbsp;</td>
              <td className="p-0">{product.productCode}</td>
            </tr>
            <tr>
              <td className="text-end text-muted p-0">
                {product.productParameterCount === 1 ? t("prod-parameter") : t("prod-parameters")} &nbsp;
              </td>
              <td className="p-0">
                ({product.productParameterCount}) {product.productParameter}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </ErrorWrap>
  );
}

interface SupplierProps {
  suppliers: Dto.ProductDetailSupplier[];
  product: Dto.ProductDetailProduct;
  ErrorBadge: React.FunctionComponent<{ tag: string }>;
}

export function Supplier({ suppliers, product, ErrorBadge }: SupplierProps) {
  const { t } = useTranslation();
  return (
    <ErrorWrap>
      <Card body className="myShadow">
        <h5>{t("prod-supplier")}</h5>
        <Table size="sm" borderless className="p-0 m-0">
          <tbody>
            <tr>
              <td className="text-end text-muted p-0">{t("prod-supplier_code")} &nbsp;</td>
              <td className="p-0">
                {product.supplierPartnerCode}
                <ErrorBadge tag={make_product_check_tag("hsh", "addresses")} />
              </td>
            </tr>
            <tr>
              <td className="text-end text-muted p-0">{t("biz-partner_code")} &nbsp;</td>
              <td className="p-0">
                {suppliers.length > 0 ? (
                  <LinkContainer to={{ pathname: "/partners/list/" + suppliers[0].partnerCode + "/detail" }}>
                    <a href="!#">{suppliers[0].partnerCode}</a>
                  </LinkContainer>
                ) : (
                  ""
                )}
              </td>
            </tr>
            <tr>
              <td className="text-end text-muted p-0 pb-2 border-bottom">{t("biz-partner_name")} &nbsp;</td>
              <td className="p-0 pb-2 border-bottom">{suppliers.length > 0 ? suppliers[0].partnerName : ""}</td>
            </tr>

            {suppliers.length < 1 ? (
              <tr>
                <td className="text-end text-muted p-0 pt-2 border-top">{t("biz-adrs_name")} &nbsp;</td>
                <td className="p-0 pt-2 border-top">
                  <ErrorBadge tag={make_product_check_tag("hsh", "addresses")} />
                  <ErrorBadge tag={make_product_check_tag("hsh", "address")} />
                </td>
              </tr>
            ) : (
              suppliers.map(function (su, idx) {
                return (
                  <React.Fragment key={idx}>
                    <tr>
                      <td colSpan={2} className="text-center">
                        {t("biz-adrs")} 🗺️ <small className="text-muted">(*{t("biz-mandatory")})</small>
                      </td>
                    </tr>
                    <MandatoryAddressPart
                      nazev={su.addressName}
                      adresa1={su.address}
                      adresa2={su.address2}
                      misto={su.city}
                      psc={su.zipCode}
                      kodStatu={su.countryCode}
                      withAsterisks
                      ErrorBadge={ErrorBadge}
                      kodAdresy={""}
                    />
                    <tr>
                      <td className="text-end text-muted p-0">{t("biz-adrs_type")} &nbsp;</td>
                      <td className="p-0">{su.addressType}</td>
                    </tr>
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </Table>
      </Card>
    </ErrorWrap>
  );
}
