import React, { useState } from "react";
import { his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import Table from "react-bootstrap/Table";
import { Button, Image, Row, Col, Form } from "react-bootstrap";
import { Pager } from "../comp/pager";
import { filter_rule, icompare } from "../lib/utils";
import { Boolean } from "../comp/boolean";
import { check_warehouse_container_name, romanize_packaging_group } from "./product-checks";
import { ShowADR } from "../comp/adr";
import { ItemsAndFiltered } from "../comp/dynamic-load";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { LoadingDataInfo } from "../comp/dynamic-load";
import { mandatory_format } from "./product-utils";
import { LinkContainer } from "react-router-bootstrap";
import { whs, valid_packaging_groups } from "../lists/warehouses-defs";
import { monitoring_names, monitoring_titles } from "../lists/monitoring_batch_bbd";
import { boolean_titles } from "../lists/boolean_titles";
import { useTranslation } from "react-i18next";
import Fraction from "fraction.js";
import { useQuery } from "@tanstack/react-query";
import { getProductsCheck } from "../api/products";
import { queryStatusToLoadedStatus } from "../api/common";
import BooleanDropdownRhf from "../comp/BooleanDropdownRhf";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { MultipleSelectRhf } from "../comp/MultipleSelectRhf";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";

// https://stackoverflow.com/questions/7656719/c-sharp-or-javascript-determining-common-prefix-in-strings
export function findLongestPrefixLen(list) {
  // console.log(list);
  var prefix = "" + list[0];
  var prefixLen = prefix.length;
  for (var i = 1; i < list.length && prefixLen > 0; i++) {
    var word = list[i];
    // The next line assumes 1st char of word and prefix always match.
    // Initialize matchLen to -1 to test entire word.
    var matchLen = 0;
    var maxMatchLen = Math.min(word.length, prefixLen);
    while (++matchLen < maxMatchLen) {
      if (word.charAt(matchLen) !== prefix.charAt(matchLen)) {
        break;
      }
    }
    prefixLen = matchLen;
  }
  return prefixLen;
}

function getUniqueSomething(arr: any[], getValue: (r: any) => any) {
  //arr je vstupní pole slovníků, vytáhne to z nich jeden klíč a následně to pro ten klíč získá unikátní hodnoty
  const values = arr.map(getValue);
  return values.filter((v, i, a) => a.indexOf(v) === i);
}

function getUniqueSomethingWarehouseCard(arr, getValue) {
  const values = getUniqueSomething(
    arr.flatMap((r) => r.warehouseCards),
    getValue
  );
  return values.filter((v, i, a) => a.indexOf(v) === i);
}

export function ProductsCheckOverviewNew() {
  const [offset, setOffset] = useState(0);
  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["products", "check"],
    queryFn: ({ signal }) => getProductsCheck({ signal }),
    initialData: { items: [] },
    gcTime: 0,
  });
  const loadedStatusCheck = queryStatusToLoadedStatus(status, isFetching);

  function reloadIt() {
    return refetch();
  }
  const usable_data = data.items;

  return (
    <ChecksTable
      records={usable_data}
      offset={offset}
      setOffset={setOffset}
      reloadIt={reloadIt}
      loadedStatusCheck={loadedStatusCheck}
    />
  );
}

/**
 * pokud má  gw - check batch - kontroluje sarži, pokud check_bbd - kontrolují expiraci a my chceme vědět, jestli ty kontroly ve
 * skladu odpovídají kontrole v HSH,
 * 1 nebude nikdy správně, bbd - nikdy není ano
 * srovnávám, jestli sklad splnuje HSH nastavení!!! pouze v případě,  že sklad má opačné nastavení, tak je to problém, pokud má sklad  nastavení navíc, tak mírný warning
 * null je okej stav - u jiných než gw skladů to jen tak kontrolovat nebudem
 */

function checksEtc(piw: Dto.ProductWarehouseCard, hsh_product) {
  //console.log(hsh_product);
  if (!piw) {
    return {
      piw: false,
    };
  } else {
    // váha 1 balení výpočet + kontrola
    const worth_display1_wh = piw.packageUnitOfMeasurementQuantity && piw.unitOfMeasurementWeightInKg;
    const package_weight_count_wh = new Fraction(piw.packageUnitOfMeasurementQuantity).mul(
      new Fraction(piw.unitOfMeasurementWeightInKg)
    );
    const package_weight_wh = worth_display1_wh ? String(package_weight_count_wh) : <>&mdash;</>;
    const package_weight_problem = !hsh_product.worth_display_hsh
      ? false
      : hsh_product.package_weight !== package_weight_wh;
    //název produktu kontroly
    const wNazevProduktu = piw.productName;
    const NazevProduktu = hsh_product.NazevProduktu; //v tom piw jsou hsh hodnoty taky!
    const prefixLen = findLongestPrefixLen([NazevProduktu, wNazevProduktu]);
    const nazevPrefix = wNazevProduktu.substring(0, prefixLen);
    const nazevSuffix = wNazevProduktu.substring(prefixLen);
    // amount compare checks
    const amount_problem = hsh_product.amount_hsh_problem
      ? false
      : hsh_product.amount !== piw.packageUnitOfMeasurementQuantity;
    // mj compare check - problémy, když není jednotka v HSH/Twistu
    const unit_problem = hsh_product.unit_hsh_problem
      ? false
      : (hsh_product.unit || "").toLowerCase().trim() !== (piw.storageUnitOfMeasurementCode || "").toLowerCase().trim();
    // density compare check
    const density_problem = hsh_product.density_hsh_problem
      ? false
      : hsh_product.density !== piw.unitOfMeasurementWeightInKg;
    //batch & bbd checkings
    const batch_problem =
      piw.gwCheckBatch === null
        ? false
        : (hsh_product.pwc_id === 1 || hsh_product.pwc_id === 2) && piw.gwCheckBatch !== "MANDATORY"; // pwc je 1 || 2 a batch není "mandatory"
    const bbd_problem = piw.gwCheckBbd === null ? false : hsh_product.pwc_id === 1 && piw.gwCheckBbd !== "MANDATORY"; //pwc je 1 a bbd není "mandatory"
    const batch_warning = hsh_product.pwc_id === 3 && piw.gwCheckBatch === "MANDATORY"; // pwc je 3 a batch je mandatory
    const bbd_warning = (hsh_product.pwc_id === 3 || hsh_product.pwc_id === 2) && piw.gwCheckBbd === "MANDATORY"; //pwc je 3 || 2 a bbd je mandatory
    //safety_sheet
    const safety_sheet_problem = piw.safetySheet === false;
    //obal check
    const container_problem = hsh_product.container_hsh_problem
      ? false
      : !check_warehouse_container_name(piw.warehouseId, piw.hshPackage, hsh_product.obal);
    //adr check
    const isADR = hsh_product.adr;
    const package_group_problem = hsh_product.package_group_hsh_problem
      ? false
      : isADR &&
        (!piw.adrPackingGroup || romanize_packaging_group(hsh_product.obal_sk) !== (piw.adrPackingGroup || "").trim());
    const class_problem = hsh_product.class_hsh_problem
      ? false
      : isADR && (!piw.adrClass || hsh_product.trida !== piw.adrClass);
    const un_num_problem = hsh_product.un_num_hsh_problem
      ? false
      : isADR && (!piw.adrUnNumber || hsh_product.un_cislo !== piw.adrUnNumber);

    // console.log(package_weight_problem);

    return {
      piw: true,
      package_weight_wh: package_weight_wh,
      nazevPrefix: nazevPrefix,
      nazevSuffix: nazevSuffix,
      amount_problem: amount_problem,
      unit_problem: unit_problem,
      density_problem: density_problem,
      package_weight_problem: package_weight_problem,
      batch_problem: batch_problem,
      bbd_problem: bbd_problem,
      batch_warning: batch_warning,
      bbd_warning: bbd_warning,
      safety_sheet_problem: safety_sheet_problem,
      packaging_problem: container_problem,
      package_group_problem: package_group_problem,
      class_problem: class_problem,
      un_num_problem: un_num_problem,
      problem:
        package_weight_problem ||
        safety_sheet_problem ||
        batch_problem ||
        bbd_problem ||
        container_problem ||
        package_group_problem ||
        class_problem ||
        un_num_problem,
    };
  }
}

function intelligentRomanizePackagingGroup(v) {
  return v === null ? null : romanize_packaging_group(v);
}

function getPackagingGroupKeys(pcr_values, hsh_values) {
  //  //logika val === null || val === "" je také v match_pg
  return pcr_values
    .concat(hsh_values)
    .map((v) => (v === null || v === "" ? null : String(v).trim().toLowerCase()))
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((a, b) => (a === null ? -1 : b === null ? 1 : String(a) < String(b) ? -1 : 1));
}

function getPackagingGroupName(key, uppercase) {
  return key === null ? "? Neznámé" : uppercase ? key.toUpperCase() : key.toLowerCase();
}

function getPackagingGroupNames(keys, uppercase = false) {
  return keys.reduce((acc, v) => ({ ...acc, [v]: getPackagingGroupName(v, uppercase) }), {});
}

function matchBb(piw, val, fil) {
  return piw ? (val === null ? null : val === "MANDATORY") === fil : false; //(val === "MANDATORY")) to přemapovává na true-false
}

function matchPg(val, fil: string[]) {
  if(fil.length === 0) return true;
  return fil.includes(String(val ?? "null").trim().toLowerCase());
}

function matchUnitOfMeasurement(val, fil: string[]) {
  if(fil.length === 0) return true;
  return fil.includes(String(val ?? "null").trim().toLowerCase());
}

function getClassDict(records) {
  const unique_class_hsh = getUniqueSomething(records, (r) => r.adrClass);
  const unique_class_wh = getUniqueSomethingWarehouseCard(records, (r) => r.adrClass);
  const classes_keys = getPackagingGroupKeys(unique_class_wh, unique_class_hsh);
  const dictClass = getPackagingGroupNames(classes_keys);
  return dictClass;
}

function getPackageGroupDict(records) {
  const unique_PG_hsh = getUniqueSomething(records, (r) => r.adrPackingGroup).map(
    intelligentRomanizePackagingGroup
  );
  const unique_PG_wh = getUniqueSomethingWarehouseCard(records, (r) => r.adrPackingGroup);
  const PG_keys = getPackagingGroupKeys(unique_PG_wh, unique_PG_hsh);
  return PG_keys;
}

function getUnitOfMeasurementDict(records) {
  const unique_MJ_hsh = getUniqueSomething(records, (r) => r.storageUnitOfMeasurementCode);
  const unique_MJ_wh = getUniqueSomethingWarehouseCard(records, (r) => r.storageUnitOfMeasurementCode);
  const MJ_keys = getPackagingGroupKeys(unique_MJ_wh, unique_MJ_hsh);
  const dictMJ = getPackagingGroupNames(MJ_keys);
  return dictMJ;
}

interface ChecksTableProps {
  records: Dto.GetProductCheckNewItem[];
  offset: number;
  setOffset: (offset: number) => void;
  reloadIt: () => void;
  loadedStatusCheck: number;
}

const defaultFilterValues: ProductCheckFilterValues = {
  units: [],
  classes: [],
  packages: [],
  adr: null,
  bbd: "",
  batch: "",
  container: "",
  product: "",
  amount: "",
  density: "",
  monitoring: "",
  name: "",
  packageWeight: "",
  warehouses: [],
  unNumber: "",
  status: null,
  safetySheet: "",
};

function ChecksTable({ records, offset, setOffset, reloadIt, loadedStatusCheck }: ChecksTableProps) {
  const form = useForm<ProductCheckFilterValues>({ mode: "onChange", defaultValues: defaultFilterValues });
  const { watch } = form;
  const [filterValues] = useDebounceFilterValues(watch, defaultFilterValues);

  const dictMJ = getUnitOfMeasurementDict(records);

  const dictClass = getClassDict(records);

  const PG_keys = getPackageGroupDict(records);
  const PG_names = getPackagingGroupNames(PG_keys, true);

  //console.log(filterPackageGroup);

  //console.log(checkedWH);

  function cleanFilters() {
    form.reset(defaultFilterValues);
  }

  const warehouses = getUniqueSomethingWarehouseCard(records, (r) => r.warehouseId);
  warehouses.sort((a, b) => a - b);

  const productsDetails = records.map((rec) => {
    //const rec = records.find((r) => r.pcr_k_IDProduktu === id); //najdeme první záznam od daného produktu a pak v 207 tam přidáme záznamy ke všem wh
    const isInTwist = rec.productCode !== null;

    const worth_display_hsh = rec.packageUnitOfMeasurementQuantity && rec.unitOfMeasurementWeight;
    const package_weight_count = new Fraction(rec.packageUnitOfMeasurementQuantity).mul(
      new Fraction(rec.unitOfMeasurementWeight)
    );
    const package_weight = worth_display_hsh ? String(package_weight_count) : "—";

    const adr_hsh_problem = !rec.adr === null;
    const monitoring_hsh_problem = !rec.warehouseConfiguration.type;
    const name_hsh_problem = !rec.productName;
    const container_hsh_problem = !rec.hshPackage;
    const amount_hsh_problem = !rec.packageUnitOfMeasurementQuantity;
    const unit_hsh_problem = !rec.storageUnitOfMeasurementCode;
    const density_hsh_problem = !rec.unitOfMeasurementWeight;
    const class_hsh_problem = !rec.adrClass && rec.adr;
    const package_group_hsh_problem = !valid_packaging_groups[rec.adrPackingGroup] && rec.adr;
    const un_num_hsh_problem = !rec.adrUnNumber && rec.adr;

    const hsh_product = {
      id: rec.productId,
      KodProduktu: rec.productCode,
      pwc_id: rec.warehouseConfiguration.type,
      NazevProduktu: rec.productName,
      obal: rec.hshPackage,
      amount: rec.packageUnitOfMeasurementQuantity,
      density: rec.unitOfMeasurementWeight,
      unit: rec.storageUnitOfMeasurementCode,
      package_weight_count: package_weight_count,
      package_weight: package_weight,
      worth_display_hsh: worth_display_hsh,
      adr: rec.adr,
      trida: rec.adrClass,
      obal_sk: rec.adrPackingGroup,
      un_cislo: rec.adrUnNumber,
      isInTwist: isInTwist,
      adr_hsh_problem: adr_hsh_problem,
      monitoring_hsh_problem: monitoring_hsh_problem,
      name_hsh_problem: name_hsh_problem,
      container_hsh_problem: container_hsh_problem,
      amount_hsh_problem: amount_hsh_problem,
      unit_hsh_problem: unit_hsh_problem,
      density_hsh_problem: density_hsh_problem,
      class_hsh_problem: class_hsh_problem,
      package_group_hsh_problem: package_group_hsh_problem,
      un_num_hsh_problem: un_num_hsh_problem,
    };

    rec.warehouseCards = rec.warehouseCards.map((wh) => {
      const checks_result = checksEtc(wh, hsh_product);
      return {
        productId: rec.productId,
        ...wh,
        ...checks_result,
        isInTwist: isInTwist,
      };
    });

    //console.log(piws);
    const problemAtLeastOneVisibleWH = rec.warehouseCards.reduce(
      (acc, v) =>
        acc ||
        // @ts-ignore
        (v.problem &&
          (filterValues.warehouses.length === 0 ||
            filterValues.warehouses.includes(v.warehouseId?.toString()))) /*checkedWH[v.warehouseId]*/,
      false
    ); //začne na false, projde všechny v.problem v piws a oruje je mezi sebou //toto mi řekne, jestli je problem aspon v jednom wh na produktu, přidána podmínka, že to musí být ve filtru vybrané

    return {
      ...hsh_product,
      warehouseCards: rec.warehouseCards,
      //   warehouseProblemTest: piws.reduce((acc, v) => acc || v.problem && checkedWH[v.pci_warehouse_id], false), //v.problem && checkedWH[v.pci_warehouse_id] je ve filtru
      problem:
        !isInTwist ||
        adr_hsh_problem ||
        monitoring_hsh_problem ||
        name_hsh_problem ||
        container_hsh_problem ||
        amount_hsh_problem ||
        unit_hsh_problem ||
        density_hsh_problem ||
        class_hsh_problem ||
        package_group_hsh_problem ||
        un_num_hsh_problem ||
        problemAtLeastOneVisibleWH,
    };
  });

  //console.log(productsDetails);
  //console.log(filterPackageGroup);
  //console.log(filterMJ);

  const products_with_filtered_piws = productsDetails.map(
    //prochází všechny produkty a mění: v product v klíči warehouses a ty, které nesplňují filter pro warehouse,  nahradí záznamem, co říká, že není karta
    function (pr) {
      const piws = pr.warehouseCards;
      const piws_filtered = piws.map(function (piw) {
        if (
          // @ts-ignore
          piw.piw &&
          !(filterValues.warehouses.length === 0 || filterValues.warehouses.includes(piw.warehouseId?.toString()))
        ) {
          return {
            piw: false,
          };
        } else {
          return piw;
        }
      });
      return {
        ...pr,
        warehouses: piws_filtered, //přepisuje ten klíč, pokud je product in warehouse, předávám ho jak je, jinak tam dělám dojem, že karta není ve skladu
      };
    }
  );

  const products_filtered_by_warehouse = products_with_filtered_piws.filter(function (pr) {
    return (
      filterValues.warehouses.length === 0 ||
      pr.warehouseCards.reduce((acc, v) => acc || filterValues.warehouses.includes(v.warehouseId?.toString()), false)
    );
  });
  //console.log(products_filtered_by_warehouse.length);
  const products_filtered = products_filtered_by_warehouse.filter(function (pr) {
    return (
      (filter_rule(filterValues.product, pr.id, true) || filter_rule(filterValues.product, pr.KodProduktu, true)) &&
      (filterValues.status === null || filterValues.status === pr.problem) &&
      (filter_rule(filterValues.container, pr.obal, true) ||
        pr.warehouseCards.reduce((acc, v) => acc || filter_rule(filterValues.container, v.hshPackage, true), false)) &&
      (filter_rule(filterValues.name, pr.NazevProduktu, true) ||
        pr.warehouseCards.reduce((acc, v) => acc || filter_rule(filterValues.name, v.productName, true), false)) && //začínám na false, procházím strukturu warehouseCards a koukám na hodnotu filter_rule v kontextu pcr_nazevProduktu a oruju, protože chci, aby mi tam true spadlo //pro všechny warehousy se zeptáme na filter_rule získáme boolean hodnotu toho
      // @ts-ignore
      (pr.warehouseCards.reduce((acc, v) => acc || matchBb(v.piw, v.gwCheckBatch, filterValues.batch), false) ||
        filterValues.batch === "") &&
      // @ts-ignore
      (pr.warehouseCards.reduce((acc, v) => acc || matchBb(v.piw, v.gwCheckBbd, filterValues.bbd), false) ||
        filterValues.bbd === "") &&
      (pr.warehouseCards.reduce((acc, v) => acc || pr.pwc_id?.toString() === filterValues.monitoring, false) ||
        filterValues.monitoring === "") &&
      (pr.warehouseCards.reduce((acc, v) => acc || v.safetySheet === filterValues.safetySheet, false) ||
        filterValues.safetySheet === "") &&
      (filter_rule(filterValues.unNumber, pr.un_cislo, true) ||
        pr.warehouseCards.reduce((acc, v) => acc || filter_rule(filterValues.unNumber, v.adrUnNumber, true), false)) &&
      (icompare(pr.amount, filterValues.amount) ||
        pr.warehouseCards.reduce(
          (acc, v) => acc || icompare(v.packageUnitOfMeasurementQuantity, filterValues.amount),
          false
        )) &&
      (icompare(pr.density, filterValues.density) ||
        pr.warehouseCards.reduce(
          (acc, v) => acc || icompare(v.unitOfMeasurementWeightInKg, filterValues.density),
          false
        )) &&
      (icompare(parseInt(pr.package_weight), filterValues.packageWeight) ||
        pr.warehouseCards.reduce(
          // @ts-ignore
          (acc, v) => acc || icompare(parseInt(v.package_weight_wh), filterValues.packageWeight),
          false
        )) &&
      (filterValues.adr === null || filterValues.adr === pr.adr) &&
      (matchPg(intelligentRomanizePackagingGroup(pr.obal_sk), filterValues.packages) || // TODO
        pr.warehouseCards.reduce((acc, v) => acc || matchPg(v.adrPackingGroup, filterValues.packages), false)) &&
      (matchPg(pr.trida, filterValues.classes) ||
        pr.warehouseCards.reduce((acc, v) => acc || matchPg(v.adrClass, filterValues.classes), false)) &&
      (matchUnitOfMeasurement(pr.unit, filterValues.units) ||
        pr.warehouseCards.reduce(
          (acc, v) => acc || matchUnitOfMeasurement(v.storageUnitOfMeasurementCode, filterValues.units),
          false
        ))
    );
  });
  
  const products_to_show_sliced = products_filtered.slice(offset, offset + 20);

  return (
    <div>
      <Row>
        <Col>
          <Button disabled={loadedStatusCheck !== 2} size="sm" className="me-2 d-inline" onClick={reloadIt}>
            <Image src="/img/reload.svg" height="19" />
          </Button>
        </Col>
        <Col className="text-center">
          <ItemsAndFiltered filtered_data={products_filtered} data={records} cleanFilters={cleanFilters} />
        </Col>
        <Col>
          <Pager offset={offset} pagesize={20} total={products_filtered.length} callback={setOffset} />
        </Col>
      </Row>

      <Table size="sm" bordered>
        <thead className="beGray">
          <FormProvider {...form}>
            <ProductCheckFilters unitValues={dictMJ} classValues={dictClass} packagesValues={PG_names} />
          </FormProvider>
        </thead>
        <tbody>
          {products_to_show_sliced.map((product, idx) => (
            <ProductRow
              key={idx}
              product={product}
              warehouses={warehouses}
              row_num={idx}
              selectedWarehouses={filterValues.warehouses}
            />
          ))}
        </tbody>
      </Table>
      <LoadingDataInfo loadedStatus={loadedStatusCheck} data={records} withoutLoading />
      {!his_fetch_success(loadedStatusCheck) ? (
        <HisFetchStatus status={loadedStatusCheck} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
      ) : (
        <></>
      )}
    </div>
  );
}

interface ProductCheckFilterValues {
  status: boolean | null;
  product?: string;
  adr: boolean | null;
  warehouses: string[];
  monitoring: "" | null | 1 | 2 | 3;
  batch: "" | null | boolean;
  bbd: "" | null | boolean;
  safetySheet: "" | null | boolean;
  name?: string;
  container?: string;
  amount?: string;
  density?: string;
  packageWeight?: string;
  unNumber?: string;
  units: string[];
  classes: string[];
  packages: string[];
}

interface ProductCheckFiltersProps {
  unitValues: { [key: string]: string };
  classValues: { [key: string]: string };
  packagesValues: { [key: string]: string };
}

function ProductCheckFilters({ unitValues, classValues, packagesValues }: ProductCheckFiltersProps) {
  const { t } = useTranslation();
  const { register, control } = useFormContext<ProductCheckFilterValues>();

  return (
    <>
      <tr>
        <th rowSpan={2}>
          <p className="mb-2">{t("state")}</p>
          <BooleanDropdownRhf variant="product-checks" name="status" />
        </th>
        <th rowSpan={2} className="text-center">
          <Form.Group controlId="filterID" className="mb-0">
            <Form.Label>ID / {t("prod-code")}</Form.Label>
            <Form.Control type="text" placeholder="&#128269;" {...register("product")} />
          </Form.Group>
          {t("prod-ADR")}
          <BooleanDropdownRhf name="adr" />
        </th>
        <th rowSpan={2}>
          <p className="mb-1">
            {t("menu-prod")} <br /> {t("prod-in_wh")}
          </p>
          <MultipleSelectRhf values={whs} id="warehouses" withoutNumber />
        </th>
        <th rowSpan={2}>
          {t("prod-monitoring")}
          <Controller
            control={control}
            name="monitoring"
            render={({ field: { value, onChange } }) => (
              <DropdownButton id="filterMonitoring" title={t(monitoring_titles[value])} variant="light">
                {Object.keys(monitoring_titles).map(function (m, idx) {
                  return (
                    <Dropdown.Item key={idx} onClick={() => onChange(m)}>
                      {t(monitoring_titles[m])}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            )}
          />
          <Row className="g-0">
            <Col>
              {t("ord-batch")}:
              <BooleanWithUnknown name="batch" />
            </Col>
            <Col>
              {t("prod-bbd")}:
              <BooleanWithUnknown name="bbd" />
            </Col>
          </Row>
        </th>
        <th rowSpan={2}>
          {t("prod-safe_list")}
          <BooleanWithUnknown name="safetySheet" />
        </th>
        <th rowSpan={2}>
          <Form.Group controlId="filterName" className="mb-0">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control type="text" placeholder="&#128269;" {...register("name")} />
          </Form.Group>
        </th>
        <th colSpan={4} className="text-center">
          {t("prod-package")}
        </th>
        <th colSpan={3} className="text-center">
          {t("prod-ADR")}
        </th>
      </tr>
      <tr>
        <th className="text-center">
          <Form.Group controlId="filterContainer" className="mb-0">
            <Form.Label>{t("ord-pack")}</Form.Label>
            <Form.Control type="text" placeholder="&#128269;" {...register("container")} />
          </Form.Group>
        </th>
        <th className="text-center">
          <Row className="g-0">
            <Col>
              {t("prod-content")}
              <Form.Group controlId="filterAmount" className="mb-0">
                <Form.Control
                  type="text"
                  placeholder="&#128269; > < ="
                  {...register("amount", { pattern: /^[=<>]?[0-9]+$/ })}
                />
              </Form.Group>
            </Col>
            <Col>
              {t("measure_unit")}
              <MultipleSelectRhf values={unitValues} id="units" withoutNumber />
            </Col>
          </Row>
        </th>
        <th className="text-center">
          {t("ord-density")}
          <Form.Group controlId="filterDensity" className="mb-0">
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("density", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
        <th className="text-center">
          {t("ord-1_pack_weight")}
          <Form.Group controlId="filterPackageWeight" className="mb-0">
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("packageWeight", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
        <th className="text-center">
          {t("prod-class")}
          <MultipleSelectRhf values={classValues} id="classes" withoutNumber />
        </th>
        <th className="text-center">
          {t("prod-pack-group")}
          <MultipleSelectRhf values={packagesValues} id="packages" withoutNumber />
        </th>
        <th className="text-center">
          <Form.Group controlId="filterUnNum" className="mb-0">
            <Form.Label>{t("ord-un_num")}</Form.Label>
            <Form.Control type="text" placeholder="&#128269;" {...register("unNumber")} />
          </Form.Group>
        </th>
      </tr>
    </>
  );
}

function ProductRow({ product, warehouses, row_num, selectedWarehouses }) {
  const { t } = useTranslation();

  const bg_class = product.problem ? "bg-danger" : "bg-success";
  const td_bg = row_num % 2 === 0 ? "" : "beGray3";
  const isInTwist = product.isInTwist;

  const adr_missing_message =
    isInTwist && product.adr_hsh_problem ? <div className="text-danger">{t("ADR_info_missing")}!</div> : "";
  const monitoring_bg = isInTwist && product.monitoring_hsh_problem ? " alert-danger " : "";
  const name_bg = isInTwist && product.name_hsh_problem ? " alert-danger " : "";
  const package_bg = isInTwist && product.container_hsh_problem ? " alert-danger " : "";
  const amount_bg = isInTwist && product.amount_hsh_problem ? " alert-danger " : "";
  const unit_bg = isInTwist && product.unit_hsh_problem ? " alert-danger " : "";
  const density_bg = isInTwist && product.density_hsh_problem ? " alert-danger " : "";
  const class_bg = isInTwist && product.class_hsh_problem ? " alert-danger " : "";
  const package_group_bg = isInTwist && product.package_group_hsh_problem ? " alert-danger " : "";
  const un_num_bg = isInTwist && product.un_num_hsh_problem ? " alert-danger " : "";
  const rowSpan =
    warehouses.reduce(
      (acc, v) => acc + (selectedWarehouses.length === 0 || selectedWarehouses.includes(v?.toString()) ? 1 : 0),
      0
    ) + 1; //začínám na 0, projdu všechny warehousy, a když je cheked, tak přičtu 1
  const checkedWarehouses = warehouses.filter(
    (w) => selectedWarehouses.length === 0 || selectedWarehouses.includes(w?.toString())
  );
  return (
    <>
      <tr className={td_bg}>
        <td rowSpan={rowSpan} className={"align-middle text-center " + bg_class}>
          {product.problem ? <span className="bg-light p-1">❌</span> : "✅"}
        </td>
        <td rowSpan={rowSpan} className={"align-middle text-center "}>
          <h5>
            {product.id} <br /> <br />
            <LinkContainer
              style={{ cursor: "pointer" }}
              to={{ pathname: "/products/" + encodeURIComponent(product.KodProduktu) + "/view" }}
            >
              <a href="!#">{product.KodProduktu}</a>
            </LinkContainer>
            {isInTwist ? "" : <span className="text-danger">{t("product_not_registered_in_twist")}!</span>}
            {adr_missing_message}
            <br /> <br />
            <ShowADR isADR={product.adr} />
          </h5>
        </td>
        <td className="align-middle text-center bolder ">HSH:</td>
        {isInTwist ? (
          <>
            <td className={monitoring_bg + "align-middle text-center bolder"}>{t(monitoring_names[product.pwc_id])}</td>
            <td></td>
            <td className={name_bg + "bolder"}>{product.NazevProduktu}</td>
            <td className={package_bg + "text-center bolder"}>{product.obal}</td>
            <td className={amount_bg + unit_bg + "text-center bolder"}>
              {product.amount} {product.unit}
            </td>
            <td className={density_bg + "text-center bolder"}>
              {product.density} {t("prod-kg")}{" "}
            </td>
            <td className={"text-center bolder"}>
              {product.package_weight} {t("prod-kg")}
            </td>
            {product.adr ? (
              <>
                <td className={class_bg + "text-center bolder"}>{product.trida}</td>
                <td className={package_group_bg + "text-center bolder"}>{romanize_packaging_group(product.obal_sk)}</td>
                <td className={un_num_bg + "text-center bolder"}>{product.un_cislo}</td>
              </>
            ) : (
              <td colSpan={3} className="text-center">
                &mdash;
              </td>
            )}
          </>
        ) : (
          <td colSpan={10} className="text-danger font-weighht-bold">
            {" "}
            {t("product_not_registered_in_twist")}.
          </td>
        )}
      </tr>
      {checkedWarehouses.map(function (piw, idx) {
        const warehouseCard = product.warehouseCards.find((w) => w.warehouseId === piw);
        return (
          <tr className={td_bg} key={idx}>
            <td className="text-center" colSpan={1}>
              <>
                <Boolean value={warehouseCard?.problem} variant="piw-problem" /> {piw}:{" "}
              </>
            </td>
            <ProductInWarehouse piw={warehouseCard} classa={td_bg} />
          </tr>
        );
      })}
    </>
  );
}

function ProductInWarehouse({ piw, classa }) {
  const { t } = useTranslation();

  if (!piw) {
    return (
      <td className={"text-center text-muted " + classa} colSpan={10}>
        &mdash;
      </td>
    );
  }
  const green_bg = " alert-success ";
  const yellow_bg = " alert-warning ";
  const red_bg = " alert-danger ";
  const isInTwist = piw.isInTwist;
  const name_bg = isInTwist ? green_bg : "";
  const amount_unit_bg = isInTwist && (piw.amount_problem || piw.unit_problem) ? yellow_bg : "";
  const density_bg = piw.density_problem && isInTwist ? yellow_bg : "";
  const package_weight_bg = piw.package_weight_problem && isInTwist ? red_bg : "";
  const safety_sheet_bg = piw.safety_sheet_problem && isInTwist ? red_bg : "";
  const batch_bg = isInTwist && piw.batch_problem ? red_bg : isInTwist && piw.batch_warning ? yellow_bg : "";
  const bbd_bg = isInTwist && piw.bbd_problem ? red_bg : piw.bbd_warning ? yellow_bg : "";
  const package_bg = isInTwist && piw.packaging_problem ? red_bg : "";
  const adr_class_bg = isInTwist && piw.class_problem ? red_bg : "";
  const adr_package_group_bg = isInTwist && piw.package_group_problem ? red_bg : "";
  const adr_un_num_bg = isInTwist && piw.un_num_problem ? red_bg : "";
  return (
    <>
      <td className="align-middle">
        <Table borderless className="m-0">
          <tbody>
            <tr>
              <td className={batch_bg + "border-0 py-0 my-0 align-middle"}>
                {t("ord-batch")}:&nbsp;<span>{mandatory_format(piw.gwCheckBatch)}</span>
              </td>
              <td className={bbd_bg + "border-0 py-0 my-0 align-middle"}>
                {t("prod-bbd")}:&nbsp;<span>{mandatory_format(piw.gwCheckBbd)}</span>
              </td>
            </tr>
          </tbody>
        </Table>
      </td>
      <td className={safety_sheet_bg + " align-middle text-center"}>
        {piw.safetySheet === null ? "?" : <Boolean value={piw.safetySheet} variant="true_false" />}
      </td>
      <td className="align-middle">
        <span className={name_bg}>{piw.nazevPrefix}</span>
        {piw.nazevSuffix}
      </td>
      <td className={package_bg + "text-center align-middle"}>{piw.hshPackage}</td>
      <td className={amount_unit_bg + "text-center align-middle"}>
        {piw.packageUnitOfMeasurementQuantity} {piw.storageUnitOfMeasurementCode}
      </td>
      <td className={density_bg + "text-center align-middle"}>{piw.unitOfMeasurementWeightInKg} kg </td>
      <td className={package_weight_bg + "text-center align-middle"}>{piw.package_weight_wh} kg</td>
      <td className={adr_class_bg + "text-center align-middle"}>{piw.adrClass}</td>
      <td className={adr_package_group_bg + "text-center align-middle"}>{piw.adrPackingGroup}</td>
      <td className={adr_un_num_bg + "text-center align-middle"}>{piw.adrUnNUmber}</td>
    </>
  );
}

function BooleanWithUnknown({ name }: { name: string }) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      render={({ field: { value, onChange } }) => (
        <DropdownButton id={name} title={t(boolean_titles[value])} variant="light">
          <Dropdown.Item onClick={() => onChange("")}>{t("all")}</Dropdown.Item>
          <Dropdown.Item onClick={() => onChange(null)}>? - {t("unknown")}</Dropdown.Item>
          <Dropdown.Item onClick={() => onChange(true)}>{t("yes")}</Dropdown.Item>
          <Dropdown.Item onClick={() => onChange(false)}>{t("no")}</Dropdown.Item>
        </DropdownButton>
      )}
      name={name}
    />
  );
}
