/**
 * Basic react neccessary call of <App/> function.
 * @module index
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
//import ReactDOM from 'react-dom'; //old React 16 method
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ErrorWrap } from './comp/errorwrap';
import './i18n/i18n';
import './index.css';
import { msalConfig } from './msal';
import * as serviceWorker from './serviceWorker';
import {setMsalInstance} from "./api/common";


const msalInstance = new PublicClientApplication(msalConfig);

setMsalInstance(msalInstance);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

//Old React 16 method
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
	onUpdate: function (registration) {
		const waitingServiceWorker = registration.waiting

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener("statechange", event => {
				if (event.target.state === "activated") {
					caches.keys().then(function (cacheNames) {
						return Promise.all(
							cacheNames.filter(function (cacheName) {
								// Return true if you want to remove this cache,
								// but remember that caches are shared across
								// the whole origin
								return true;
							}).map(function (cacheName) {
								return caches.delete(cacheName);
							})
						);
					});
					window.location.reload(true);
				}
			});
			waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
		}
	}
});



let msalSetupSuccessful = false;
while (!msalSetupSuccessful) {
	try {
		await msalInstance.initialize()
		await msalInstance.handleRedirectPromise();
		const root = createRoot(document.getElementById('root'));
		root.render(
			<ErrorWrap>
				<MsalProvider instance={msalInstance}>
					<App />
				</MsalProvider>
			</ErrorWrap>
		)
		msalSetupSuccessful = true;
	} catch (e) {
	}
}
