import { authorizedFetch } from "./common";

export function getNotesRange(from: string, to: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetNotesRangeResponse>(`/api/notes/range/${from}/${to}/new`, {
    ...options,
    method: "GET",
  });
}

export function getObjectNotes(
  type: Exclude<Dto.NoteObjectType, "Unknown">,
  objectCode: string,
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetObjectNotesResponseItem[]>(`/api/notes/${type}/${objectCode}/new`, {
    ...options,
    method: "GET",
  });
}

export function getObjectNotes2(
  type: Exclude<Dto.NoteObjectType, "Unknown">,
  objectCode: string,
  objectCode2: string,
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetObjectNotesResponseItem[]>(`/api/notes/${type}/${objectCode}/${objectCode2}/new`, {
    ...options,
    method: "GET",
  });
}

export function showNote(noteId: number, options?: RequestInit) {
  return authorizedFetch<Dto.SimpleResult>(`/api/notes/${noteId}`, {
    ...options,
    method: "PUT",
  });
}

export function hideNote(noteId: number, options?: RequestInit) {
  return authorizedFetch<Dto.SimpleResult>(`/api/notes/${noteId}`, {
    ...options,
    method: "DELETE",
  });
}

export function createNote(note: Dto.PostAddNoteRequest, options?: RequestInit) {
  return authorizedFetch<Dto.PostAddNoteResponse>(`/api/notes/new`, {
    method: "POST",
    ...options,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(note),
  });
}
