import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert, Badge, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Loading } from "../../comp/loading";
import { ErrorWrap } from "../../comp/errorwrap";
import { OtherUsersAlert, PurchaseOrderProcessedItem } from "./PurchaseOrder";
import { useTranslation } from "react-i18next";
import { useUserLogin } from "../../UserLoginProvider";
import { StockNotificationStep1 } from "./StockNotificationStep1";
import { StockNotificationStep2 } from "./StockNotificationStep2";
import { StockNotificationStep3 } from "./StockNotificationStep3";
import { useMutation } from "@tanstack/react-query";
import { purchaseOrderCloseNotification, purchaseOrderOpenNotification } from "../../api/orders";
import { FormProvider, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

export interface StockNotificationItemBatchFormValues {
  batch: string;
  amount: number;
  expiration: string;
  expiryType: Dto.BatchExpiryType;
}

export interface StockNotificationFormValues {
  eta: string;
  pickedItems: {
    [nop_IDPolozky: string]: boolean;
  };
  items: {
    [nop_IDPolozky: string]: {
      batches: StockNotificationItemBatchFormValues[];
    };
  };
  unlockedItems: {
    [nop_IDPolozky: string]: number;
  };
}

const defaultValues: StockNotificationFormValues = {
  eta: "",
  pickedItems: {},
  items: {},
  unlockedItems: {},
};

interface StockNotificationProps {
  order: Dto.GetPurchaseOrderDetailResponse["order"];
  items: PurchaseOrderProcessedItem[];
  stockinsNumber: number;
  reloadIt: () => void;
  texts: Dto.GetPurchaseOrderDetailResponse["texts"];
  notifyOtherUsers?: Dto.GetOrderOpenStockNotificationsResponseItem[];
}

/**
 * Component for notification process, source of data about notification, onChange functions, switching steps
 */
export function StockNotification({
  order,
  items,
  stockinsNumber,
  reloadIt,
  texts,
  notifyOtherUsers,
}: StockNotificationProps) {
  const { t } = useTranslation();

  const { step } = useParams<{ step: "1" | "2" | "3" }>();
  const [stateOfUsersInside, setStateOfUsersInside] = useState(0); // 0 - initial, 1- transition phase, 2 - userInside
  const form = useForm<StockNotificationFormValues>({ mode: "onChange", defaultValues });

  const userLogin = useUserLogin();

  const creatorUserIsIn = notifyOtherUsers.map((us) => us.username).includes(userLogin.userName);
  if (stateOfUsersInside === 1 && creatorUserIsIn) {
    setStateOfUsersInside(2);
  }

  const { mutate: openNotification } = useMutation({
    mutationFn: purchaseOrderOpenNotification,
    onSuccess: (data, variables, context) => {
      setStateOfUsersInside(1);
    },
    onError: (error, variables, context) => {
      console.log("onError");
    },
  });

  const { mutate: closeNotification } = useMutation({
    mutationFn: purchaseOrderCloseNotification,
  });

  useEffect(() => {
    openNotification(order.orderId);

    return () => {
      closeNotification(order.orderId);
    };
  }, [openNotification, order, closeNotification]);

  if (!notifyOtherUsers) {
    return (
      <>
        <Row>
          <Col>
            {" "}
            <h3 className="mb-4">
              {t("ord-sn_number")} {stockinsNumber + 1} {t("of_prep")} {order.orderCode}
            </h3>
          </Col>
          <Col></Col>
        </Row>
        <Loading />
      </>
    );
  }

  const userIsntIn = stateOfUsersInside === 2 && !creatorUserIsIn;
  return (
    <ErrorWrap>
      <Row>
        <Col>
          {" "}
          <h3 className="mb-4">
            {t("ord-sn_number")} {stockinsNumber + 1} {t("of_prep")} {order.orderCode}
          </h3>
        </Col>

        <Col>
          <NotifyCancelled userIsntIn={userIsntIn} purchaseOrderCode={order.orderCode} />
          <OtherUsersAlert notifyOtherUsers={notifyOtherUsers} reloadIt={reloadIt} />
        </Col>
      </Row>
      {userIsntIn ? (
        ""
      ) : (
        <>
          <BreadcrumbNav step={step} />
          <DevTool control={form.control} placement="top-left" />
          <FormProvider {...form}>
            <StockNotificationStep1 kodDokladu={order.orderCode} items={items} texts={texts} hidden={step !== "1"} />
            <StockNotificationStep2 orderCode={order.orderCode} items={items} texts={texts} hidden={step !== "2"} />
            <StockNotificationStep3
              orderId={order.orderId}
              orderCode={order.orderCode}
              items={items}
              texts={texts}
              hidden={step !== "3"}
            />
          </FormProvider>
        </>
      )}
    </ErrorWrap>
  );
}

interface NotifyCancelledProps {
  userIsntIn: boolean;
  purchaseOrderCode: string;
}

function NotifyCancelled({ userIsntIn, purchaseOrderCode }: NotifyCancelledProps) {
  const { t } = useTranslation();

  if (userIsntIn) {
    return (
      <Alert className="" variant="danger">
        {t("ord-your_notification_was_terminated")}.
        <br />
        <LinkContainer to={{ pathname: "/orders/purchase/" + purchaseOrderCode }}>
          <Button size="sm" className="mb-2 mt-3" variant="primary">
            {t("ord-back_to_NO")}
          </Button>
        </LinkContainer>
      </Alert>
    );
  } else {
    return "";
  }
}

interface BreadcrumbNavProps {
  step: "1" | "2" | "3";
}

function BreadcrumbNav({ step }: BreadcrumbNavProps) {
  const { t } = useTranslation();

  const variant1 = step === "1" ? "primary" : "secondary";
  const variant2 = step === "2" ? "primary" : "secondary";
  const variant3 = step === "3" ? "primary" : "secondary";

  const muted1 = step === "1" ? "" : "text-muted";
  const muted2 = step === "2" ? "" : "text-muted";
  const muted3 = step === "3" ? "" : "text-muted";

  return (
    <Row className="text-center border-top border-bottom p-3 bg-light mb-4">
      <Col className={muted1 + " text-end"}>
        <h5 className="mb-0">
          <Badge pill bg={variant1}>
            1
          </Badge>{" "}
          {t("ord-items_choice")}
        </h5>
      </Col>
      <Col xs="auto">
        <h4 className="mb-0">&gt;</h4>
      </Col>
      <Col xs="auto" className={muted2 + " text-start"}>
        <h5 className="mb-0">
          <Badge pill bg={variant2}>
            2
          </Badge>{" "}
          {t("ord-items_param")}
        </h5>
      </Col>
      <Col xs="auto">
        <h4 className="mb-0">&gt;</h4>
      </Col>
      <Col className={muted3 + " text-start"}>
        <h5 className="mb-0">
          <Badge pill bg={variant3}>
            3
          </Badge>{" "}
          {t("ord-sn_summary")}
        </h5>
      </Col>
    </Row>
  );
}
