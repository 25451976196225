
/**
 * Components for display delivery note on destop.
 * @module delivery-notes/delivery-note-sales-detail-web
 * @author Lucie Zdeňková <ĺucie.zdenek@trustica.cz>
 */

import React from 'react';
import { Row, Col, Table, Card, Button, Image } from 'react-bootstrap';
import { Boolean } from '../comp/boolean';
import { date_formatCZ } from '../lib/date-utils';
import { Downloader } from '../comp/downloader';
import { LinkContainer } from 'react-router-bootstrap';
import { partnerLogo } from '../lists/warehouses-defs';
import {
    dnote_field_ok, null_or_qmark, twist_TypAdresy_str, HeaderOrItemField,
    compare_prp_sort, ExpirationBadge
} from './delivery-note-sales-checks';
import { make_product_check_tag } from '../products/product-checks';
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from '../products/product-view';
import { MandatoryAddressPart } from '../partners/partner-detail';
import { ErrorWrap } from '../comp/errorwrap';
import { ADR_icons } from '../lists/adr_icons';
import { useTranslation } from 'react-i18next';

//seen all - OK


export function Header({ reloadIt, kodDokladu, document, partner }) {
    const { t } = useTranslation();

    const pdf_language = document && document.header && document.header.pa_KodStatu &&
        ((document.header.pa_KodStatu === 'CZ') || (document.header.pa_KodStatu === 'SK')) ?
        "čeština" : "english";
    return (
        <Row className="g-0">
            <Col xs={12} md='9'>
                <Button size="sm" className="me-2" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
                <h3 className="d-inline align-middle">{t('deno-delivery_note')} {kodDokladu} </h3>
                <br className="d-md-none" />
                <span className="d-inline-block me-3">
                    <Downloader img="/application-pdf.svg" height="25" alt="pdf" label={"CZ"}
                        path={"/api/delivery-notes/" + kodDokladu + "/pdf/cz"}
                        message="CZ"
                        filename={kodDokladu + "-cz.pdf"}
                    />
                </span>
                <span className="d-inline-block" style={{ width: "100px" }}>
                    <Downloader img="/application-pdf.svg" height="25" alt="pdf" label={"EN"}
                        path={"/api/delivery-notes/" + kodDokladu + "/pdf/en"}
                        message="EN"
                        filename={kodDokladu + "-en.pdf"}
                    />
                </span>
                <br className="d-md-none" />
                {t('deno-PDF_lang')}:&nbsp;{pdf_language}&nbsp;&nbsp;&nbsp;&nbsp;
                <Downloader img="/application-pdf.svg" height="25" alt="pdf" label={"Proof of export"}
                    path={"/api/delivery-notes/" + kodDokladu + "/poe-pdf"}
                    message="Proof of export"
                    filename={kodDokladu + "-proof-of-export.pdf"} />
            </Col>
            <Col xs='3' className="text-end">
                &nbsp;
                {partner ? partnerLogo[partner] : ""}
            </Col>
        </Row>
    );
}

export function GeneralInfo({ ErrorBadge, datumDokladu, vystavil, kodPartnera, nazevPartnera,
    typAdresyFaktur, nazevAdresy, adresa, adresa2, misto, kodStatu, psc, stredisko, zakazka, kategorie, kodAdresy,
    uzavreno, schvaleno, poznamka }) {
    const { t } = useTranslation();
    return (
        <ErrorWrap>
            <Card body className="p-0 mb-3">
                <h5>{t('deno-basic_info')}</h5>
                <Table borderless size="sm" className="p-0 m-0">
                    <tbody>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-document_date')} &nbsp;</td>
                            <td className="p-0">
                                {date_formatCZ(datumDokladu)}
                                <ErrorBadge tag={"PRP9Delivery"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-issuer')} &nbsp;</td>
                            <td className="p-0">{vystavil}</td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('biz-partner_code')} &nbsp;</td>
                            <td className="p-0">
                                <LinkContainer to={{ pathname: "/partners/list/" + kodPartnera + "/detail" }}>
                                    <a href="!#">{kodPartnera}</a>
                                </LinkContainer>
                                <ErrorBadge tag={"addresses-"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('biz-partner_name')} &nbsp;</td>
                            <td className="p-0">{nazevPartnera}</td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-inv_adrs')} &nbsp;</td>
                            <td className="p-0">
                                {t(twist_TypAdresy_str(typAdresyFaktur))}
                                <ErrorBadge tag={"InvoicingAddressType"} />
                            </td>
                        </tr>

                        <tr className="p-0 bg-light">
                            <td colSpan="2" className="text-center p-0"><ErrorBadge tag={"InvoicingAddressData"} /> {t('biz-adrs')} 🗺️ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        </tr>
                        <MandatoryAddressPart nazev={nazevAdresy} adresa1={adresa} adresa2={adresa2}
                            ErrorBadge={ErrorBadge}
                            misto={misto} psc={psc} kodStatu={kodStatu} bgLight kodAdresy={kodAdresy} />
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('biz-centre')} &nbsp;</td>
                            <td className="p-0">
                                {stredisko}
                                <ErrorBadge tag={"CentreCode"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-contract')} &nbsp;</td>
                            <td className="p-0">
                                {zakazka}
                                <ErrorBadge tag={"contractCode"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-category')} &nbsp;</td>
                            <td className="p-0">
                                {kategorie}
                                <ErrorBadge tag={"categoryCode"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('biz-closed')} &nbsp;</td>
                            <td className="p-0">
                                <Boolean variant="onlyTrue" value={uzavreno} />
                                <ErrorBadge tag={"closed"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-approved')} &nbsp;</td>
                            <td className="p-0">
                                <Boolean variant="onlyTrue" value={schvaleno} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('note')} &nbsp;</td>
                            <td className="p-0">{poznamka}</td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
        </ErrorWrap>
    );
}

export function DeliveryInfo({ ErrorBadge, realDatumDodani, KodDopravce, kodDoprProstredku, kodDodaciPodminky, dopravce,
    kodAdresyDodani, nazevAdresy, adresa, adresa2, misto, kodStatu, psc, poznamka, telefon, mobil, zastupcePartnera }) {
    const { t } = useTranslation();
    return (
        <ErrorWrap>
            <Card body className="p-0">
                <h5>{t('deno-transport_info')}</h5>
                <Table borderless size="sm" className="p-0 m-0">
                    <tbody>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{(KodDopravce === "HSHLOGISTIKA" || KodDopravce === "SAMOODBĚR") ? t('deno-stockout_date') : t('deno-delivery_date')} &nbsp;</td>
                            <td className="p-0">
                                {realDatumDodani}
                                <ErrorBadge tag={"deliveryDate"} />
                                <ErrorBadge tag={"deliveryDateSoft"} />
                                <ErrorBadge tag={"PRP9Delivery"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-vehicle')} &nbsp;</td>
                            <td className="p-0">
                                {kodDoprProstredku}	<ErrorBadge tag={"missing-vehicle"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-delivery_condition')} &nbsp;</td>
                            <td className="p-0">{kodDodaciPodminky}	<ErrorBadge tag={"deliveryCondition"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-carrier')} &nbsp;</td>
                            <td className="p-0">
                                {dopravce}	<ErrorBadge tag={"warehouseProblems"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-delivery_adrs')} &nbsp;</td>
                            <td className="p-0">
                                {kodAdresyDodani} <ErrorBadge tag={"wrongAddress"} />	<ErrorBadge tag={"AddressProblems"} />
                            </td>
                        </tr>
                        <tr className="p-0 bg-light">
                            <td colSpan="2" className="text-center p-0"><ErrorBadge tag={"DodaciAdresaAllData"} /> {t('biz-adrs')} 🗺️  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        </tr>
                        <MandatoryAddressPart nazev={nazevAdresy} adresa1={adresa} adresa2={adresa2}
                            misto={misto} psc={psc} kodStatu={kodStatu} bgLight ErrorBadge={ErrorBadge} kodAdresy={kodAdresyDodani} />
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 "><span role="img" aria-label="poznámka ikona">{t('note')} 📝</span> &nbsp;</td>
                            <td className="p-0">{poznamka}</td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 "><span role="img" aria-label="telefon ikona">{t('biz-phon')} 📞</span> &nbsp;</td>
                            <td className="p-0">
                                {telefon} <ErrorBadge tag={"din-phone"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 "><span role="img" aria-label="mobil ikona"> {t('biz-mobile')} 📱</span> &nbsp;</td>
                            <td className="p-0">
                                {mobil}	<ErrorBadge tag={"din-phone"} />
                            </td>
                        </tr>
                        <tr className="p-0">
                            <td className="text-end text-muted p-0 ">{t('deno-partners_agent')} &nbsp;</td>
                            <td className="p-0">{zastupcePartnera}</td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
        </ErrorWrap>
    );
}

export function AdditionalInfo({ ErrorBadge, isADR, document }) {
    const { t } = useTranslation();
    var idx = 0;
    const del_start_num = document.texts_din.length;
    const dsi_start_num = del_start_num + document.texts_del.length;
    const gw_num_nums = document.items.length * 10;
    // console.log("dels="+del_start_num+" :: dsis="+dsi_start_num+" :: gwm = "+gw_num_nums);
    return (
        <ErrorWrap>
            <Card>
                <Row>
                    <Col xs="auto">
                        <h5 className='m-3'>{t('deno-additional_info')}</h5>
                    </Col>
                    <Col>
                        <div className="p-1" style={{ float: "right" }}>
                            {isADR ? <DisplayADR tridaADR="1" height={60} /> : ""}
                        </div>
                    </Col>
                </Row>
                <Card.Body className="ps-3">
                    <h6 className="mb-1">{t('deno-delivery_info')}</h6>
                    {
                        document.texts_din.sort(compare_prp_sort).map(function (din) {
                            idx += 1;
                            return <Row className="g-0" key={idx}>
                                <Col xs="2">{din.KodTypuTextu}
                                    {(din.KodTypuTextu === "PRP36") || (din.KodTypuTextu === "PRP37") ?
                                        <ErrorBadge tag={"din-phone"} />
                                        : ""}
                                </Col>
                                <Col xs="10">
                                    {din.NazevTypuTextuOrig}
                                    <ErrorBadge tag={"hsh-din"} />
                                </Col>
                            </Row>
                        })
                    }
                    <hr />
                    <h6 className="mb-1">{t('deno-cargo_info')}</h6>
                    {
                        document.texts_prp.sort(compare_prp_sort).map(function (prp) {
                            idx += 1;
                            return <Row className="g-0" key={idx}>
                                <Col xs="2" >
                                    {prp.KodTypuTextu}
                                </Col>
                                <Col xs="10">
                                    {prp.NazevTypuTextuOrig}
                                </Col>
                            </Row>
                        })
                    }

                    {document.texts_del[0] === "" ? "" :
                        <div style={{ backgroundColor: "#28a745b5" }} className="mt-2 ps-1" >
                            {
                                document.texts_del.slice(0, 2).map(function (del, didx) {
                                    return <samp className="small" key={didx}>{del}<br /></samp>
                                })
                            }
                        </div>
                    }
                    <hr />
                    <h6 className="mb-1">
                        {t('deno-manipulation_info')}
                        <ErrorBadge tag={"missingText"} />
                    </h6>
                    {
                        document.texts_skl.sort(compare_prp_sort).map(function (skl, sidx) {
                            // console.log('sidx = '+sidx);
                            const cls = (sidx + dsi_start_num >= gw_num_nums) ? " text-muted" : "";
                            return <Row key={sidx} className={cls +  " g-0 "}>
                                <Col xs="2" >{skl.KodTypuTextu}</Col>
                                <Col xs="10" >{skl.NazevTypuTextuOrig}</Col>
                            </Row>
                        })
                    }
                </Card.Body>
            </Card>
        </ErrorWrap>
    );
}

export function DisplayADR({ tridaADR, height = 35 }) {
    if (tridaADR === null) {
        return "-";
    }
    const img = ADR_icons[tridaADR];
    if (img) {
        return <Image src={"/img/adr/isADR.svg"} height={height} />;
    }
    return tridaADR;
}


export function PDItemsWeb({ items, header, ErrorBadge }) {
    const { t } = useTranslation();
    return (
        <>
            <h4 className=" ms-3 mt-3 mb-3">{t('biz-items')} </h4> <ErrorBadge tag={'noItems'} />
            <Table bordered size="sm" className="d-none d-xl-table striped-2-rows bg-white mb-0">
                <thead className="beGray">
                    <tr>
                        <th rowSpan="3" className='ps-3'>{t('biz-centre')}</th>
                        <th rowSpan="3">{t('deno-contract')}</th>
                        <th rowSpan="3">{t('deno-category')}</th>
                        <th rowSpan="3">{t('not-prod_id')}</th>
                        <th rowSpan="3">{t('prod-code')}</th>
                        <th colSpan={9} className='text-center'>{t('prod-prod_card')} <small>({t('prod-hsh_wh_comparison')})</small></th>
                        <th rowSpan="3" className='text-center'>{t('ord-expiry')}</th>
                        <th rowSpan="3" className='text-center'>{t('deno-delivered_amount')}</th>
                        <th rowSpan="3" className='text-center'>{t('package_count')}</th>
                    </tr>
                    <tr className="text-center">
                        <th rowSpan="2">{t('warehouse')}</th>
                        <th rowSpan="2" width="10%">{t('prod-name')}</th>
                        <th colSpan="4">{t('prod-package')}</th>
                        <th colSpan="3">{t('prod-ADR')}</th>
                    </tr>
                    <tr>
                        <th className="text-center beGray">{t('ord-pack')}</th>
                        <th className='text-center beGray'>{t('ord-content_w_unit')}</th>
                        <th className='text-center beGray'>{t('ord-density')}</th>
                        <th className='text-center beGray'>{t('ord-1_pack_weight')}</th>
                        <th className='text-center beGray'>{t('ord-adr_class')}</th>
                        <th className='text-center beGray'>{t('ord-pack_group')}</th>
                        <th className='text-center beGray'>{t('ord-un_num')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map(function (item) {
                            return (
                                <React.Fragment key={item.svs_IDPolozkySerie}>
                                    <tr className="text-center">
                                        <td rowSpan="2" className="align-middle">{HeaderOrItemField(header.svd_KodStrediska, item.svp_KodStrediska)}
                                            {null_or_qmark(item.svp_KodStrediska) ? <ErrorBadge tag={"CentreCode"} /> : ""}
                                        </td>
                                        <td rowSpan="2" className="align-middle">{HeaderOrItemField(header.svd_KodZakazky, item.svp_KodZakazky)}
                                            {null_or_qmark(item.svp_KodZakazky) ? <ErrorBadge tag={"contractCode"} /> : ""}
                                        </td>
                                        <td rowSpan="2" className="align-middle">{HeaderOrItemField(header.svd_KodKategorie, item.svp_KodKategorie)}
                                            {null_or_qmark(item.svp_KodKategorie) ? <ErrorBadge tag={"categoryCode"} /> : ""}
                                        </td>
                                        <td rowSpan="2" className="align-middle">{item.p_k_IDProduktu}</td>
                                        <td rowSpan="2" className="align-middle">
                                            <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(item.svp_KodProduktu) + "/view" }}>
                                                <a href="!#">{item.svp_KodProduktu}</a>
                                            </LinkContainer>
                                        </td>
                                        <td className="align-middle">HSH:
                                            <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "twistApproval")} />
                                            <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "hisApproval")} />
                                            <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "configured")} />
                                            <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "monthExpiration")} />
                                        </td>
                                        <td className="align-middle text-start">
                                            {item.svp_NazevProduktu}
                                            {item.altNamesStr ? <><hr />{item.altNamesStr}</> : ""}
                                        </td>
                                        <ProductContainerView id={"hsh-" + item.p_k_IDProduktu}
                                            container={item.co_k_HSHObal5}
                                            ErrorBadge={ErrorBadge} />
                                        <DensityAmountUnitWeightView
                                            id={"hsh-" + item.p_k_IDProduktu} density={item.spj_HmotnostMj}
                                            densityUnit={item.spj_MjHmotnostiMj} amount={item.pjo_MnozstviSklMjVObalu}
                                            unit={item.p_KodMjSkl} packageWeight={item.package_weight_kg_hsh}
                                            ErrorBadge={ErrorBadge} />
                                        <ProductADRView id={"hsh-" + item.p_k_IDProduktu}
                                            TridaADR={item.kcunc_TridaADR}
                                            k_ADRObalovaSkupina={item.p_k_ADRObalovaSkupina}
                                            k_ADRUNCislo={item.p_k_ADRUNCislo}
                                            ErrorBadge={ErrorBadge} />
                                        <td rowSpan="2" className="align-middle">
                                            {date_formatCZ(item.ps_DatExpirace)}
                                            {dnote_field_ok(item.ps_DatExpirace) ? "" : <ErrorBadge tag={"expiryMissing"} />}
                                            <ErrorBadge tag={'expiration-' + item.p_k_IDProduktu} />
                                            <ExpirationBadge expiration={item.ps_DatExpirace} />
                                        </td>
                                        <td rowSpan="2" className="align-middle text-center">{item.svs_MnozstviSkl} {item.p_KodMjSkl} <ErrorBadge tag={'amount-fits-packages-' + item.svs_IDPolozkySerie} /></td>
                                        <td rowSpan="2" className="align-middle text-center">{item.package_quantity} <ErrorBadge tag={'amount-fits-packages-' + item.svs_IDPolozkySerie} /></td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle text-center">
                                            {item.svp_KodKnihy}
                                            <ErrorBadge tag={"singleWarehouse"} />
                                            <ErrorBadge tag={'missing-card-' + item.svp_KodKnihy + "-" + item.p_k_IDProduktu} />
                                        </td>
                                        <td className={"align-middle text-start" + item.redish}>{item.pcr_NazevProduktu}</td>
                                        <ProductContainerView id={"wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu}
                                            wid={item.svp_KodKnihy}
                                            container={item.pcr_k_HSHObal5}
                                            remap
                                            ErrorBadge={ErrorBadge}
                                            bg={item.no_card} />
                                        <DensityAmountUnitWeightView
                                            id={"wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu} density={item.pcr_HmotnostMjVKg}
                                            densityUnit="kg" amount={item.pcr_MnozstviSklMjVObalu}
                                            unit={item.pcr_KodMjSkl} packageWeight={item.package_weight_kg_wh}
                                            ErrorBadge={ErrorBadge} bg={item.no_card}
                                        />
                                        <ProductADRView id={"wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu}
                                            TridaADR={item.pcr_TridaADR}
                                            k_ADRObalovaSkupina={item.pcr_k_ADRObalovaSkupina}
                                            k_ADRUNCislo={item.pcr_k_ADRUNCislo}
                                            romanized
                                            ErrorBadge={ErrorBadge}
                                            bg={item.no_card}
                                        />
                                    </tr>
                                </React.Fragment>
                            );
                        })
                    }
                </tbody>
            </Table>
        </>
    );
}