import React, { useEffect, useState } from "react";
import { his_fetch_success, HisFetchStatus } from "../../comp/FetchLoader";
import { Button, Col, Form, Image, Row, Table } from "react-bootstrap";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { ErrorWrap } from "../../comp/errorwrap";
import {
  get_id_boolean,
  NotificationsOfOrderPurchase,
  StockNotificationProcessed,
} from "../stockNotifications/StockinDisplayComms";
import { Pager } from "../../comp/pager";
import { filter_rule, icompare } from "../../lib/utils";
import { warehouses_for_select } from "../../lists/warehouses-defs";
import { stock_progress_specification } from "../progress-stockins-def";
import { ItemsAndFiltered } from "../../comp/dynamic-load";
import { Loading } from "../../comp/loading";
import { useTranslation } from "react-i18next";
import { MyInfo } from "../../comp/info-badge";
import { useQuery } from "@tanstack/react-query";
import { useUserLogin } from "../../UserLoginProvider";
import { getStockNotificationsTracking } from "../../api/stockNotifications";
import { queryStatusToLoadedStatus } from "../../api/common";
import { processStockNotifications } from "../purchaseOrderDetail/PurchaseOrderDetail";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { OrderBy } from "../../lib/utilTypes";
import BooleanDropdownRhf from "../../comp/BooleanDropdownRhf";
import { SortIconsRhf } from "../../comp/SortIconsRhf";
import { MultipleSelectRhf } from "../../comp/MultipleSelectRhf";
import { DevTool } from "@hookform/devtools";
import { useIsLgUpQuery } from "../../hooks/breakpoints";
import { StockinTrackingAppFilters } from "./StockinTrackingAppFilters";
import { useCachedOffsetAndFilter } from "../../comp/FilterCacheProvider";

const filterDefaultValues: StockNotificationsTrackingFilterValues = {
  etaDays: "",
  orderCode: "",
  creator: "",
  warehouse: [],
  status: [],
  problematic: null,
  orderBy: "-recorded",
};

export function StockNotifyTracking() {
  const { t } = useTranslation();

  const statuses_for_select = stock_progress_specification.reduce((acc, v) => ({ ...acc, [v.number]: v.name }), {});

  const [reloadingPeriod, setReloadingPeriod] = useState(0);
  const [detailShown, setDetailShown] = useState({});
  const { userInfo } = useUserLogin();
  const isLgUp = useIsLgUpQuery();
  const form = useForm<StockNotificationsTrackingFilterValues>({
    mode: "onChange",
    defaultValues: filterDefaultValues,
  });
  const { watch, reset } = form;
  const { etaDays, orderBy } = watch();
  const [offset, setOffset, debouncedFilter] = useCachedOffsetAndFilter(
    "stockNotificationTracking",
    watch,
    reset,
    filterDefaultValues
  );

  useEffect(() => {
    form.register("orderBy");
    form.register("warehouse");
    form.register("status");

    return () => {
      form.unregister("orderBy");
      form.unregister("warehouse");
      form.unregister("status");
    };
  }, [form]);

  const cleanFilters = () => {
    form.reset(filterDefaultValues);
  };

  const authorizedUser =
    userInfo.hisSuperuser === true || userInfo.roleCompliance === true || userInfo.roleCsrHead === true;

  const { refetch, data, status, isFetching } = useQuery({
    queryKey: ["stock-notifications/tracking", orderBy],
    queryFn: ({ signal }) => getStockNotificationsTracking(orderBy, { signal }),
    initialData: [],
    refetchInterval: reloadingPeriod,
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  function reloadIt() {
    return refetch();
  }

  const trackedNOs = data.reduce((acc, sn) => {
    const found = acc.find((no) => no.KodDokladu === sn.orderCode);
    if (!found) {
      acc.push({ KodDokladu: sn.orderCode, notifications: processStockNotifications([sn]) });
    } else {
      found.notifications.push(...processStockNotifications([sn]));
    }
    return acc;
  }, [] as StockNotificationProcessed[]);

  function toggleDetailShown(sn_id) {
    setDetailShown({ ...detailShown, [sn_id]: !detailShown[sn_id] });
  }

  const allHidden = Object.keys(detailShown).length === Object.values(detailShown).filter((v) => v === false).length;

  function expandAll() {
    setDetailShown(get_id_boolean(data, allHidden));
  }

  function get_unique_SN_ids_from_kodDokladu(kodDokladu) {
    return data
      .filter((sn) => sn.orderCode === kodDokladu)
      .map((sn) => sn.id)
      .filter((v, i, a) => a.indexOf(v) === i);
  }

  function isNoHidden(orderCode: string) {
    const ids = get_unique_SN_ids_from_kodDokladu(orderCode);
    const isHidden = ids.reduce((acc, sn_id) => acc || detailShown[sn_id], false);
    return !isHidden;
  }

  function expandAllInNO(no: string) {
    const sn_ids = get_unique_SN_ids_from_kodDokladu(no);
    const value = isNoHidden(no);
    setDetailShown(sn_ids.reduce((acc, sn_id) => ({ ...acc, [sn_id]: value }), detailShown));
  }

  const stockinsFiltered = trackedNOs.filter(function (no) {
    return (
      filter_rule(debouncedFilter?.orderCode ?? "", no.KodDokladu) &&
      ((debouncedFilter?.creator ?? "") === "" ||
        no.notifications.reduce(
          (res, notify) => res || filter_rule(debouncedFilter.creator, notify.mp_Jmeno, true),
          false
        ) ||
        no.notifications.reduce(
          (res, notify) => res || filter_rule(debouncedFilter.creator, notify.u_username, true),
          false
        )) &&
      no.notifications.reduce(
        (res, notify) =>
          (res ||
            (debouncedFilter?.warehouse ?? []).length === 0 ||
            debouncedFilter?.warehouse.includes(notify.sn_warehouse_id.toString())) ??
          true,
        false
      ) &&
      no.notifications.reduce(
        (res, notify) =>
          (res ||
            (debouncedFilter?.status ?? []).length === 0 ||
            debouncedFilter?.status.includes(notify.sns_status.toString())) ??
          true,
        false
      ) &&
      no.notifications.reduce(
        (res, notify) => res || icompare(notify.days_to_eta, debouncedFilter?.etaDays ?? ""),
        false
      ) &&
      no.notifications.reduce(
        (res, notify) =>
          res ||
          debouncedFilter === undefined ||
          debouncedFilter.problematic === null ||
          debouncedFilter.problematic === notify.problematic_solve_soon,
        false
      )
    );
  });

  const showStockIns = stockinsFiltered.slice(offset, offset + 5);

  return (
    <ErrorWrap>
      <Row>
        <Col>
          <Button disabled={!his_fetch_success(loadedStatus)} size="sm" className="me-2 d-inline" onClick={reloadIt}>
            <Image src="/img/reload.svg" height="19" />
          </Button>
        </Col>
        <Col className="text-center">
          <ItemsAndFiltered
            filtered_data={stockinsFiltered}
            data={trackedNOs}
            cleanFilters={cleanFilters}
            itemsName={t("ord-of_ords_purch")}
          />
          {!his_fetch_success(loadedStatus) ? (
            <span>
              {" "}
              <Loading size="small" message={t("loading")} margin="0" />
            </span>
          ) : (
            ""
          )}
        </Col>
        <Col className="text-end">
          <h6 className="d-inline">{t("background_loading")}:&nbsp;</h6>
          <Form.Group controlId="display" className="mb-0 ms-3 d-inline">
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={0}
              id="1"
              label={t("never")}
              onClick={() => setReloadingPeriod(0)}
              defaultChecked={reloadingPeriod === 0}
            />
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={15 * 1000}
              id="2"
              label="15s"
              onClick={() => setReloadingPeriod(15 * 1000)}
              defaultChecked={reloadingPeriod === 15}
            />
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={1 * 60 * 1000}
              id="3"
              label="1min"
              onClick={() => setReloadingPeriod(60 * 1000)}
              defaultChecked={reloadingPeriod === 60}
            />
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={5 * 60 * 1000}
              id="4"
              label="5min"
              onClick={() => setReloadingPeriod(5 * 60 * 1000)}
              defaultChecked={reloadingPeriod === 5 * 60}
            />
          </Form.Group>
          <br />
          <br />
          <Pager offset={offset} pagesize={5} total={stockinsFiltered.length} callback={setOffset} />
          <Button size="sm" className="float-end me-3 mb-2" onClick={() => expandAll()}>
            {allHidden ? (
              <>
                {t("expand")} <MdExpandMore />
              </>
            ) : (
              <>
                {t("hide")} <MdExpandLess />
              </>
            )}
          </Button>
        </Col>
      </Row>

      <FormProvider {...form}>
        <DevTool control={form.control} />
        {!isLgUp && <StockinTrackingAppFilters statusesForSelect={statuses_for_select} />}

        <Table className="border" size="sm">
          {isLgUp && <StockinsTrackingWebFilters statusesForSelect={statuses_for_select} />}
          <tbody>
            {showStockIns.map(function (no, idx) {
              return (
                <React.Fragment key={idx}>
                  <NotificationsOfOrderPurchase
                    key={idx}
                    global
                    notifications={no.notifications}
                    detailShown={detailShown}
                    toggleDetailShown={toggleDetailShown}
                    authorizedUser={authorizedUser}
                    reloadIt={reloadIt}
                    expandAllInNO={expandAllInNO}
                    is_NO_hidden={isNoHidden}
                    filterDaysToEta={etaDays}
                    showProblematicCol
                    showDaysToEtaCol
                  />
                  <tr>
                    <td colSpan={11}></td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </FormProvider>

      <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
    </ErrorWrap>
  );
}

export interface StockNotificationsTrackingFilterValues {
  orderCode?: string;
  problematic?: boolean;
  creator?: string;
  etaDays?: string;
  warehouse?: string[];
  status?: string[];
  orderBy?: OrderBy<Pick<Dto.GetStockNotificationTrackingResponseItem, "recorded" | "eta">>;
}

export interface StockinsTrackingWebFiltersProps {
  statusesForSelect: Record<string, string>;
}

function StockinsTrackingWebFilters({ statusesForSelect }: StockinsTrackingWebFiltersProps) {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext<StockNotificationsTrackingFilterValues>();
  const [myNotesChecked, setMyNotesChecked] = useState(false);
  const { userName } = useUserLogin();

  return (
    <thead className="beGray ">
      <tr className="d-none d-lg-table-row">
        <th className="py-2" style={{ width: "10%" }}>
          <p className="mb-1">{t("ord-pur")}</p>
          <Form.Group controlId="filterCode" className="mb-1">
            <Form.Control type="text" placeholder="&#128269;" {...register("orderCode")} />
          </Form.Group>
        </th>
        <th className="text-center">
          <p className="mb-1">{t("problematic")}</p>
          <div className="mb-2">
            <BooleanDropdownRhf variant="order-tracking" name="problematic" />
          </div>
        </th>
        <th className="text-center pb-2">{t("ord-sn_num")}</th>
        <th className="text-center">
          <Row className="g-0">
            <Col className="text-start">
              <p className="mb-1">{t("ord-created_by")}</p>
            </Col>
            <Col className="text-end">
              <div className="float-end mb-0">
                <Form.Check
                  type="checkbox"
                  id="filter_my_stockins"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue("creator", userName);
                      setMyNotesChecked(true);
                    } else {
                      setValue("creator", "");
                      setMyNotesChecked(false);
                    }
                  }}
                  checked={myNotesChecked}
                  label={t("my_stockins")}
                />
              </div>
            </Col>
          </Row>
          <Form.Group controlId="filterCreator" className="mb-1">
            <Form.Control type="text" placeholder="&#128269;" {...register("creator")} />
          </Form.Group>
        </th>
        <th className="text-center pb-2">
          {t("ord-last_change_time")}&nbsp;
          <SortIconsRhf columnName="recorded" filterFieldName="orderBy" numeric />
        </th>
        <th className="text-center pb-2">
          {t("ord-eta")}&nbsp;
          <SortIconsRhf columnName="eta" filterFieldName="orderBy" numeric />
        </th>
        <th className="text-center">
          &nbsp;
          <Form.Group controlId="filterDaysToEta" className="mb-0">
            <Form.Label className="mb-1">
              {t("ord-days_to_or_after_eta")} <MyInfo text="filter_days_to_ETA_with_minus_sign" />
            </Form.Label>
            <Form.Control className="mb-1" type="text" placeholder="&#128269; > < =" {...register("etaDays")} />
          </Form.Group>
        </th>
        <th className="text-center">
          <p className="mb-1">{t("warehouse")}</p>
          <MultipleSelectRhf values={warehouses_for_select} id="warehouse" />
        </th>
        <th className="text-center">
          <p className="mb-1">{t("state")}</p>
          <MultipleSelectRhf values={statusesForSelect} id="status" />
        </th>
        <th className="text-center pb-2">{t("biz-items")}</th>
        <th></th>
      </tr>
    </thead>
  );
}
