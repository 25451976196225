/**
 * Old fetching function, which isnt used any more, but fetcher2 is still used for downloaders
 * @module lib/fetcher
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import { acquireTokenOrRedirect } from '../msal';

export class RequestErrorWithCode extends Error {
	constructor(message, code) {
		super(message);
		this.code = code;
	}
}

export function fetcher2(uri, okfun, errfun, msalInstance, args = {}) {
	// console.log("fetcher uri='" + uri + "'");
	var encodedURI;
	// var uriprefix = process.env.REACT_APP_API_URL
	try {
		encodedURI = encodeURI(uri);
	} catch (err) {
		errfun({ error: "Malformed URI." });
	}
	acquireTokenOrRedirect(msalInstance).then(token =>
		fetch(encodedURI, {
			...args, cache: 'no-cache', headers: {
				Authorization: 'Bearer ' + token,
			}
		})
			.then(function (res) {
				okfun(res);
			},
				function (error) {
					errfun(error);
				}
			));
}

export async function fetchWrapper(uri, msalInstance, args = {}) {
	const token = await acquireTokenOrRedirect(msalInstance);
	const result = await fetch(uri, {
		...args, cache: 'no-cache', headers: {
			Authorization: 'Bearer ' + token,
		}
	});
	if (result.ok) {
		return result.json();
	} else {
		const json = await result.json();
		throw new RequestErrorWithCode(json.error, json.code === undefined ? null : json.code);
	}
}