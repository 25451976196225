import React from "react";
import { Downloader } from "../../comp/downloader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Boolean } from "../../comp/boolean";
import { ShowADR } from "../../comp/adr";
import { partnerLogoShort, wid2partner, whColor, whID2whNumber } from "../../lists/warehouses-defs";
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from "../product-view";
import { make_product_check_tag } from "../product-checks";
import Accordion from "react-bootstrap/Accordion";
import { mandatory_format } from "../product-utils";
import { date_formatCZ } from "../../lib/date-utils";
import { useTranslation } from "react-i18next";
import Fraction from "fraction.js";
import { useSendAndCancelCard, useWarehouseSendingCardStatus } from "./productDetailHooks";
import { ProductCompareProps, ProductLineWarehouseProps } from "./ProductDetailWeb";
import { PreprocessedHistory } from "./ProductDetail";

interface HistoryAppProps {
  preprocessedHistory: PreprocessedHistory[];
  ErrorBadge: React.FunctionComponent<{ tag: string }>;
}

export function HistoryApp({ preprocessedHistory, ErrorBadge }: HistoryAppProps) {
  const { t } = useTranslation();

  return (
    <div className="d-lg-none">
      {preprocessedHistory.map(function (wh) {
        return wh.history.map(function (h, idx) {
          const package_weight_kg_wh_count = new Fraction(h.packageUnitOfMeasurementQuantity).mul(
            new Fraction(h.unitOfMeasurementWeightInKg)
          );
          return (
            <div key={idx}>
              {idx === 0 ? (
                <div className="bg-secondary mt-3 mb-1 p-2 text-white text-center">{wh.warehouseId}</div>
              ) : (
                <></>
              )}
              <div className="px-3">
                {t("prod-created")}: &nbsp; &nbsp; {h.created ? date_formatCZ(h.created) : <>&nbsp; &ndash;</>} <br />
                {t("prod-invalidated")}: {h.invalidated ? date_formatCZ(h.invalidated) : <>&nbsp; &ndash;</>} <br />
                <hr className="m-1" />
                {t("name")}: {h.productName}
                <Table borderless size="sm" className="p-0 mb-0">
                  <tbody>
                    <ProductContainerView id="hsh" container={h.hshPackage} ErrorBadge={ErrorBadge} mobile />
                    <DensityAmountUnitWeightView
                      id={"hsh"}
                      density={h.unitOfMeasurementWeightInKg}
                      densityUnit={"kg"}
                      amount={h.packageUnitOfMeasurementQuantity}
                      unit={h.storageUnitOfMeasurementCode}
                      packageWeight={String(package_weight_kg_wh_count)}
                      ErrorBadge={ErrorBadge}
                      mobile
                    />
                  </tbody>
                </Table>
                <hr className="m-1" />
                <ProductADRView
                  id="hsh"
                  TridaADR={h.adrClass}
                  k_ADRObalovaSkupina={h.adrPackingGroup}
                  k_ADRUNCislo={h.adrUnNumber}
                  ErrorBadge={ErrorBadge}
                  mobile
                  romanized
                />
                <hr className="m-1" />
                <Row>
                  <Col>
                    BL: &nbsp;
                    {h.safetySheet === null ? "?" : <Boolean value={h.safetySheet} variant="true_false" />}
                  </Col>
                  <Col className="text-center">
                    {t("ord-batch")}: &nbsp;
                    {mandatory_format(h.gwCheckBatch)}
                  </Col>
                  <Col className="text-end">
                    BBD: &nbsp;
                    {mandatory_format(h.gwCheckBbd)}
                  </Col>
                </Row>
              </div>
              <hr className="mt-2 mb-0" />
              <hr className="m-0" />
              <hr className="m-0" />
              <hr className="m-0" />
              <hr className="m-0" />
              <hr className="mb-2 mt-0" />
            </div>
          );
        });
      })}
    </div>
  );
}

export function ProductCompareApp({
  product,
  ErrorBadge,
  package_weight_kg_hsh,
  preprocessedWarehouses,
  approved,
  configured,
  no_errors,
}: ProductCompareProps) {
  const { t } = useTranslation();
  const { isWarehouseSendingCard } = useWarehouseSendingCardStatus(product.productId);
  return (
    <div className="d-lg-none">
      <div className="p-3">
        <Row className="g-0">
          <Col xs="auto">
            <Image src="/hsh.png" style={{ width: "50px", padding: "2px" }} />
          </Col>
          <Col className="text-end pt-2">
            {t("prod-ADR")} – <Boolean value={product.adr} />
            <ShowADR isADR={product.adr} height={25} />
          </Col>
        </Row>
        {product.productName}
        <Table borderless size="sm" className="p-0">
          <tbody>
            <ProductContainerView id="hsh" container={product.hshPackage} ErrorBadge={ErrorBadge} mobile />
            <DensityAmountUnitWeightView
              id={"hsh"}
              density={product.unitOfMeasurementWeight}
              densityUnit={product.unitOfMeasurementUnit}
              amount={product.packageUnitOfMeasurementQuantity}
              unit={product.storageUnitOfMeasurementCode}
              packageWeight={package_weight_kg_hsh}
              ErrorBadge={ErrorBadge}
              mobile
            />
          </tbody>
        </Table>
        <hr className="m-1" />
        <ProductADRView
          id="hsh"
          TridaADR={product.adrClass}
          k_ADRObalovaSkupina={product.adrPackingGroup}
          k_ADRUNCislo={product.adrUnNumber}
          ErrorBadge={ErrorBadge}
          mobile
        />
      </div>
      {preprocessedWarehouses.map(function (wh, idx) {
        const disableSend =
          (wh.states_1_2_3 && !wh.readback_confirmed) ||
          (wh.ADRBelongsToADRWarehouse && !wh.compliance_can_send_ADR_anywhere) ||
          isWarehouseSendingCard(wh.warehouseId) ||
          !approved ||
          !configured ||
          !no_errors;
        return (
          <Accordion key={idx}>
            <ProductLineWarehouseApp wh={wh} ErrorBadge={ErrorBadge} product={product} disableSend={disableSend} />
          </Accordion>
        );
      })}
    </div>
  );
}

function ProductLineWarehouseApp({ wh, ErrorBadge, product, disableSend }: ProductLineWarehouseProps) {
  const { t } = useTranslation();
  const { isWarehouseSendingCard, warehouseSendingCardError } = useWarehouseSendingCardStatus(product.productId);
  const { sendCard, cancelSending } = useSendAndCancelCard(product.productId);
  return (
    <>
      <Accordion.Item eventKey={wh.warehouseId.toString()}>
        <Accordion.Header>
          <Row className="container-fluid g-0">
            <Col>
              {partnerLogoShort[wid2partner[wh.warehouseId]]} &nbsp;{whID2whNumber[wh.warehouseId]}
            </Col>
            <Col className="text-end pt-2 pe-3">
              {t("prod-card_in_warehouse")}: {wh.isProductInWarehouse ? t("yes") : t("no")}
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          {wh.isProductInWarehouse ? (
            <>
              {t("name")} : <span className={wh.superClass5}>{wh.nazevPrefix}</span>
              {wh.nazevSuffix}
              <Table borderless size="sm" className="m-0 p-0">
                <tbody>
                  <ProductContainerView
                    id={"wh-" + wh.warehouseId}
                    wid={wh.warehouseId}
                    container={wh.piw.hshPackage}
                    remap
                    ErrorBadge={ErrorBadge}
                    mobile
                  />
                  <DensityAmountUnitWeightView
                    id={"wh-" + wh.warehouseId}
                    density={wh.piw.unitOfMeasurementWeightInKg}
                    densityUnit="kg"
                    amount={wh.piw.packageUnitOfMeasurementQuantity}
                    unit={wh.piw.storageUnitOfMeasurementCode}
                    packageWeight={wh.package_weight_kg_wh}
                    ErrorBadge={ErrorBadge}
                    mobile
                  />
                </tbody>
              </Table>
              <hr className="m-1" />
              <ProductADRView
                id={"wh-" + wh.warehouseId}
                TridaADR={wh.piw.adrClass}
                k_ADRObalovaSkupina={wh.piw.adrPackingGroup}
                k_ADRUNCislo={wh.piw.adrUnNumber}
                romanized
                ErrorBadge={ErrorBadge}
                mobile
              />
            </>
          ) : (
            t("prod-product_card_isnt_in_warehouse")
          )}
          <hr className="mt-1 mb-2" />
          {t("prod-xlsx_card")}:
          <Downloader
            img="/application-excel.svg"
            alt="xlsx"
            height="25"
            label={wid2partner[wh.warehouseId].toLowerCase()}
            path={"/api/products/" + product.productCode + "/card/" + wid2partner[wh.warehouseId]}
            filename={"karta-" + product.productId + "-" + wid2partner[wh.warehouseId].toLowerCase() + ".xlsx"}
          />
          <br />
          {t("prod-communication_status")}: {wh.warehouse_status_str} <br />
          {t("prod-sent(f)")}: {wh.sent_status_str} <br />
          <div className={wh.received_class}>
            {t("prod-accepted")}: {wh.received_status_str}
          </div>
          {t("prod-readback")}: <div className={wh.readback_class + " d-inline "}> {wh.readback_datetime_str}</div>
          <br />
          {t("prod-check_state")}: {wh.stavKontroly} <br />
          <hr className="m-1" />
          <Row>
            <Col>{t("prod-send_action")}:</Col>
            <Col className="text-end">
              {wh.states_1_2_3 && !wh.readback_confirmed ? (
                ""
              ) : (
                <>
                  {isWarehouseSendingCard(wh.warehouseId) ? (
                    <Button style={{ backgroundColor: whColor[wid2partner[wh.warehouseId]], border: "none" }} disabled>
                      {wh.isProductInWarehouse ? t("updating") : t("sending")}
                    </Button>
                  ) : (
                    <>
                      <Button
                        title={wh.title}
                        style={{ backgroundColor: whColor[wid2partner[wh.warehouseId]], border: "none" }}
                        onClick={() => sendCard(wh.warehouseId)}
                        disabled={disableSend}
                      >
                        {wh.isProductInWarehouse
                          ? t("update") + " " + whID2whNumber[wh.warehouseId]
                          : t("prod-sent_to") + " " + whID2whNumber[wh.warehouseId]}
                        {wh.warehouseId === 22 ? "" : <ErrorBadge tag={make_product_check_tag("hsh", "isADR")} />}
                      </Button>
                    </>
                  )}
                </>
              )}
              {wh.show_timeDiff}
              {wh.showCancel ? (
                <Button onClick={() => cancelSending(wh.warehouseId)} className="mt-1" size="sm" variant="danger">
                  {t("storno")}
                </Button>
              ) : (
                ""
              )}
              <br />
              <small className="text-danger">{warehouseSendingCardError(wh.warehouseId)}</small>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
