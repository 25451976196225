import { InteractionRequiredAuthError, LogLevel } from '@azure/msal-browser';


export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_CLIENT_ID,
		authority: process.env.REACT_APP_AUTHORITY,
		redirectUri: process.env.REACT_APP_REDIRECT_URI,
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const loginRequest = { scopes: [process.env.REACT_APP_SCOPE] }

export async function acquireTokenOrRedirect(msalInstance) {
	if (msalInstance.getActiveAccount() !== null) {
		try {
			const result = await msalInstance.acquireTokenSilent(loginRequest)
			return result.accessToken
		} catch (e) {
			if (e instanceof InteractionRequiredAuthError) {
				msalInstance.acquireTokenRedirect(loginRequest)
			} else {
				throw e
			}
		}
	} else {
		msalInstance.acquireTokenRedirect(loginRequest)
	}
}

