/**
 * Partner liabilities view.
 * @module partners/partner-liabilities
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { format_amount } from '../lib/format';
import { date_sub, date_formatCZ, date_formatISO } from '../lib/date-utils';
import { delay_color, worst_color } from '../lists/colors';
import { DelayOrHold } from './partner-history-utils';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Downloader } from '../comp/downloader';
import { PartnerSaldo2 } from './partner-panels';
import Button from 'react-bootstrap/Button';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerLiabilities({ partner, currency, liabilities, loadedLiabilitiesStatus, reloadButton }) {
	const { t } = useTranslation();

	const [saldoType, setSaldoType] = useState(1);

	if (!his_fetch_success(loadedLiabilitiesStatus)) {
		return <HisFetchStatus status={loadedLiabilitiesStatus} loadingType="big" errorType="fetcherError" waitingType="explained" reloadButton={reloadButton} />;
	}

	const lias_filtered = liabilities.filter((lia) => saldoType === 0 || lia.DokladoveSaldo > 0);
	const acc_saldo = lias_filtered.reduce((acc, lia) => acc + lia.UcetniSaldo, 0);
	const totals = get_liabilities_totals(lias_filtered);
	const sortedLiabilities = [].concat(lias_filtered).sort((a, b) => b.DatSplatnosti < a.DatSplatnosti);

	const KodPartnera = partner.KodPartnera;
	const NazevPartnera = partner.NazevPartnera;
	const todayStr = date_formatISO(new Date());

	const classSaldo0 = saldoType === 0 ? "primary" : "light";
	const classSaldo1 = saldoType === 1 ? "primary" : "light";
	const classGray1 = saldoType === 0 ? "" : "beGray";
	const classGray2 = saldoType === 1 ? "" : "beGray";

	function handleSaldoTypeChange0() {
		setSaldoType(0);
	}
	function handleSaldoTypeChange1() {
		setSaldoType(1);
	}

	const lstyle = {
		backgroundColor: worst_color(),
		fontWeight: "bold"
	};

	//var liab = 0;
	var lliab = 0;
	var i = 0;
	var currmap = {};
	var totalcurr = {};
	var latecurr = {};
	for (i = 0; i < lias_filtered.length; i++) {
		//liab += lias_filtered[i]["UcetniSaldo"];
		const delay = date_sub(new Date(), lias_filtered[i].DatSplatnosti);
		if (delay > 0) {
			lliab += lias_filtered[i]["UcetniSaldo"];
		}
		currmap[lias_filtered[i]["KodMeny"]] = true;
	}
	var currary = [];
	var ckey;
	for (ckey in currmap) {
		currary.push(ckey);
		totalcurr[ckey] = 0;
		latecurr[ckey] = 0;
	}
	for (i = 0; i < lias_filtered.length; i++) {
		totalcurr[lias_filtered[i]["KodMeny"]] += lias_filtered[i]["DokladoveSaldo"];
		const delay = date_sub(new Date(), lias_filtered[i].DatSplatnosti);
		if (delay > 0) {
			latecurr[lias_filtered[i]["KodMeny"]] += lias_filtered[i]["DokladoveSaldo"];
		}
	}

	return (
		<ErrorWrap>
			<Row className="p-3">
				<Col lg={4}>
					<PartnerSaldo2 lstyle={lstyle} lsaldos={latecurr} currs={currary} tsaldo={lliab} currency={currency} />
				</Col>
				<Col lg={5}>
					<h6 className="mb-0 mt-2 text-center">{t('biz-pdf_dwnld')}</h6>
					<Row className="pt-3">
						<Col xs={4} className="text-center">
							<Downloader img="/application-pdf.svg" alt="pdf" label="CZ"
								path={"/api/partners/" + KodPartnera + "/liability/pdf/cz"}
								filename={NazevPartnera + "-liabitities-" + todayStr + "-cz.pdf"} />
						</Col>
						<Col xs={4} className="text-center">
							<Downloader img="/application-pdf.svg" alt="pdf" label="EN"
								path={"/api/partners/" + KodPartnera + "/liability/pdf/en"}
								filename={NazevPartnera + "-liabitities-" + todayStr + "-en.pdf"} />
						</Col>
						<Col xs={4} className="text-center">
							<Downloader img="/application-pdf.svg" alt="pdf" label="SK"
								path={"/api/partners/" + KodPartnera + "/liability/pdf/sk"}
								filename={NazevPartnera + "-liabitities-" + todayStr + "-sk.pdf"} />
						</Col>
					</Row>
				</Col>
				<Col />
				<Col xs={12} lg={2} className="text-end mt-3 pe-0 ps-0">
					<div className=" text-center" >
						<Button className={"m-1 " + classGray1} variant={classSaldo0} onClick={handleSaldoTypeChange0} style={{ width: "150px" }}>{t('biz-acc_balance')}</Button>
						<br className="d-none d-lg-block" />
						<Button className={"m-1 " + classGray2} variant={classSaldo1} onClick={handleSaldoTypeChange1} style={{ width: "150px" }}>{t('biz-inv_balance')}</Button>
					</div>
				</Col>
			</Row>
			<Table striped size="sm" hover className="d-none d-lg-table">
				<thead className="beGray">
					<tr>
						<th rowSpan="2">{t('biz-inv_balance')}</th>
						<th colSpan="3" className="text-center">{t('date')}</th>
						<th rowSpan="2" className="text-end">{t('biz-acc_balance')} {currency}</th>
						<th colSpan="2" className="text-center">{t('biz-payment')}</th>
						<th rowSpan="2" className="text-center">{t('note')}</th>
						<th colSpan="4" className="text-center">{t('biz-inv_total')}</th>
					</tr>
					<tr>
						<th className="text-end">{t('biz-issued')}</th>
						<th className="text-end">{t('biz-due(2p)')}</th>
						<th className="text-end">{t('biz-payments')}</th>
						<th className="text-center">{t('biz-delay')}</th>
						<th className="text-center">{t('biz-delay2')}</th>
						<th className="text-end">{t('biz-balance')}</th>
						<th>{t('biz-currency')}</th>
						<th className="text-end">{t('biz-amount_money')}</th>
						<th>{t('biz-currency')}</th>
					</tr>
				</thead>
				<tbody>
					{sortedLiabilities.map((lia) => <Liability key={lia.KodDokladu} liability={lia} />)}
					<tr>
						<td colSpan="4"><strong>{t('biz-total_balance')}:</strong></td>
						<td className="text-end">{format_amount(acc_saldo)}</td>
						<td className="text-end" colSpan="6">
							{totals.map((tot) => <div key={tot[0]}>{format_amount(tot[1])}</div>)}
						</td>
						<td>
							{totals.map((tot) => <div key={tot[0]}>{tot[0]}</div>)}
						</td>
					</tr>
					<tr style={lstyle}>
						<td colSpan="4"><strong>{t('biz-after_due_balance')}:</strong></td>
						<td className="text-end">{format_amount(lliab)}</td>
						<td colSpan="6" className="text-end">
							{
								currary.map(function (el) {
									return (
										<React.Fragment key={el}>
											{format_amount(latecurr[el])}<br />
										</React.Fragment>
									);
								})
							}
						</td>
						<td>
							{
								currary.map(function (el) {
									return (
										<React.Fragment key={el}>
											{el}<br />
										</React.Fragment>
									);
								})
							}
						</td>
					</tr>
				</tbody>
			</Table>
			<LiaMobile sortedLiabilities={sortedLiabilities} totals={totals} acc_saldo={acc_saldo} currency={currency} />
		</ErrorWrap>
	);
}

function Liability({ liability }) {

	const delay = date_sub(new Date(), liability.DatSplatnosti);
	const dstyle = {
		backgroundColor: delay_color(delay)
	};
	const late = liability.Uhrada != null ? date_sub(liability.Uhrada, liability.DatSplatnosti) : null;
	const lstyle = liability.Uhrada != null ? { backgroundColor: delay_color(late) } : {};
	return (
		<tr>
			<td>{liability.KodDokladu}</td>
			<td className="text-end">{date_formatCZ(liability.DatDokladu)}</td>
			<td className="text-end">{date_formatCZ(liability.DatSplatnosti)}</td>
			<td className="text-end">{date_formatCZ(liability.Uhrada)}</td>
			<td className="text-end">{format_amount(liability.UcetniSaldo)}</td>
			<td className="text-center" style={lstyle}>{late}</td>
			<td className="text-center" style={dstyle}>{delay}</td>
			<td className="text-center">{liability.Poznamka}</td>
			<td className="text-end">{format_amount(liability.DokladoveSaldo)}</td>
			<td>{liability.KodMeny}</td>
			<td className="text-end">{format_amount(liability.CastkaCelkemMen)}</td>
			<td>{liability.KodMeny}</td>
		</tr>
	);
}

function get_liabilities_totals(lias_filtered) {
	const currencies = lias_filtered.map((lia) => lia.KodMeny).filter((v, i, a) => a.indexOf(v) === i);
	// console.log(currencies);
	const result = currencies
		.map((curr) => [curr,
			lias_filtered
				.filter((lia) => lia.KodMeny === curr)
				.reduce((acc, lia) => acc + lia.CastkaCelkemMen, 0)]);
	//console.log(result);
	return result;
}

// theoretically there is a component PartnerSaldo2, but doesnt fit well
function LiaMobile({ sortedLiabilities, totals, acc_saldo, currency }) {
	const { t } = useTranslation();
	//console.log(totals);
	return (
		<div className="d-block d-lg-none">
			<Card className="style-big mb-3">
				<Card.Header as="h6">
					<Row>
						<Col><b>{t('biz-total_balance')}: </b></Col>
						<Col className="text-end"> {format_amount(acc_saldo)} {currency}</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Row className="g-0">
						<Col className="text-end me-2">{totals.map((tot) => <div key={tot[0]}>{format_amount(tot[1])}</div>)}</Col>
						<Col>{totals.map((tot) => <div key={tot[0]}>{tot[0]}</div>)}</Col>
					</Row>
				</Card.Body>
			</Card>

			<Accordion className="d-block d-lg-none">
				{sortedLiabilities.map((lia) => <LiabilityMobile key={lia.KodDokladu} liability={lia} currency={currency} />)}
			</Accordion>

		</ div>
	);
}

function LiabilityMobile({ liability, currency }) {
	const { t } = useTranslation();

	const delay = date_sub(new Date(), liability.DatSplatnosti);
	const late = liability.Uhrada != null ? date_sub(liability.Uhrada, liability.DatSplatnosti) : null;

	return (
		<Accordion.Item eventKey={liability.KodDokladu}>
			<Accordion.Header as="div">
				<div className='container-fluid p-0 pe-3'>
					<Row className='g-0'>
						<Col className="beBigger pe-0">{liability.KodDokladu}</Col>
						<DelayOrHold label={t('biz-delay')} number={late} />
						<DelayOrHold label={t('biz-delay2')} number={delay} />
					</Row>
					<Row className="g-0">
						<Col>{t('biz-created_date')}: {date_formatCZ(liability.DatDokladu)}</Col>
						<Col className="text-end">{t('biz-acc_balance')} {currency}: {format_amount(liability.UcetniSaldo)}</Col>
					</Row>
				</div>
			</Accordion.Header>
			<Accordion.Body className='p-3' >
				<ListGroup variant="flush" className="smaller">
					<ListGroup.Item className="text-end">
						<Row className="g-0">
							<Col className="text-start pe-1">{t('biz-due_date')}: {date_formatCZ(liability.DatSplatnosti)}</Col>
							<Col xs="auto">
								{t('biz-balance_in_currency')}: {format_amount(liability.DokladoveSaldo)}&nbsp;{liability.KodMeny}
							</Col >
						</Row>
					</ListGroup.Item>
					<ListGroup.Item className="text-end">
						<Row className="g-0">
							<Col className="text-start">{t('biz-payment_date')}: {date_formatCZ(liability.Uhrada)}</Col>
							<Col xs="auto">
								{t('biz-amount_curr')}: {format_amount(liability.CastkaCelkemMen)}&nbsp;{liability.KodMeny}
							</Col>

						</Row>
					</ListGroup.Item>
					<ListGroup.Item>
						{liability.Poznamka}
					</ListGroup.Item>
				</ListGroup>
			</Accordion.Body>
		</Accordion.Item>
	);
}
