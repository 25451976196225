/**
 * Report configuration manipulation utilities.
 * @file
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */
import { Button, Row, Col } from 'react-bootstrap';
import { format_amount } from '../lib/format';
import { computeFormulaValue, getFormulaElementSign, getFormulaElementCondition, 
	isFormulaElementConditionFulfilled, getSrcIdConditionCounts } from './report-utils';
import { useTranslation } from 'react-i18next';

//komponenty pro zobrazení vzorce

/**
 * Displays the whole formula including the left-hand side. Allows
 * user interaction with the left-hand term.
 *
 * @param {string} ident - the left-hand term (dstId)
 * @param {string} formula - dictionary representing the whole formula
 * @param {string} current - currently selected srcId inside this formula (visual-only)
 * @param {boolean} editable - whether the left-hand term should be clickable
 * @param {boolean} active - if editable and this is true, highlight the left-hand term
 * @param {function} onClick - if editable and user clicks the left-hand term, this 
 * gets called and ident (dstId) is passed as argument
 * @param {any} setup - source and destination identifiers
 * @param {boolean} multi - if true, multiple mappings are fine in transposed view row
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @return {component}
 */
export function WholeFormula({ ident, formula, current, editable = false, active, onClick, setup, multi, srcToDst }) {
	// Uses multi={true} to disable multi check on left-hand side
	return (
		<div className="mb-3">
			<LeftFormulaElement editable={editable}
				active={active}
				onClick={() => onClick ? onClick(ident) : null}
				unknown={(setup.dstIds || []).indexOf(ident) < 0}
				value={setup.balances ? computeFormulaValue(formula, setup.balances) : null}
				srcToDst={srcToDst}
				ident={ident}
				balancesOn={setup.balances} />
			<span className='me-2'>
				=
			</span>
			<Formula formula={formula}
				current={current}
				multi={multi}
				srcToDst={srcToDst}
				setup={setup} />
		</div>
	);
}

/**
 * Represents given formula visually, possibly allowing user interaction.
 *
 * @param {any} formula - dictionary with the formula (ident to coefficient mapping)
 * @param {string} current - identifier of currently selected element (may be null)
 * @param {boolean} editable - if true, interactivity enabled
 * @param {function} onClick - if interactive and user clicks on given element, this function gets called
 * @param {any} setup - meta-configuration
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {boolean} multi - if true, multiple usage of srcIds is allowed
 * @return {component}
 */
export function Formula({ formula = {}, current, editable = false, onClick, setup, srcToDst, multi }) {
	const srcIdsArray = Object.keys(formula).sort();
	return (
		srcIdsArray.map(function (ident, idx) {
			return (
				<FormulaElement key={idx}
					ident={ident}
					coef={formula[ident]}
					active={current === ident}
					editable={editable}
					unknown={(setup.srcIds || []).indexOf(ident) < 0}
					srcToDst={srcToDst}
					multi={multi}
					value={setup.balances && setup.balances[ident] !== null ? setup.balances[ident] : null}
					onClick={onClick}
					balancesOn={setup.balances}
				/>
			);
		})
	);
}

/**
 * Left element of a formula. Can be interactive.
 *
 * @param {string} ident - the source identifier
 * @param {boolean} editable - if true then interactive
 * @param {boolean} active - if editable, signalizes this element is active (highlighted)
 * @param {function} onClick - if editable and user clicks this
 * element, this function gets called with ident and coef arguments
 * @param {boolean} unknown - show warning sign that this is unknown element
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {number} value - the value of given identifier
 * @return {component}
 */
export function LeftFormulaElement({ ident, value = null, unknown, editable, active, onClick = () => null, srcToDst, balancesOn }) {
	return <FormulaElement ident={ident}
		multi
		variant="primary"
		value={value}
		editable={editable}
		active={active}
		onClick={onClick}
		srcToDst={srcToDst}
		unknown={unknown}
		balancesOn={balancesOn} />
}

/**
 * Single element of a formula. Can be interactive.
 *
 * @param {string} ident - the source identifier
 * @param {number} coef - current coefficient (1 or -1) - if 0, no sign is shown
 * @param {boolean} editable - if true then interactive
 * @param {boolean} active - if editable, signalizes this element is active (highlighted)
 * @param {function} onClick - if editable and user clicks this
 * element, this function gets called with ident and coef arguments
 * @param {string} variant - override variant computed by coef
 * @param {boolean} unknown - show warning sign that this is unknown element
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {boolean} multi - if true, multiple usage of srcIds is allowed
 * @param {number} value - the value of given identifier
 * @param {boolean} balancesOn - show balances
 * @return {component}
 */
export function FormulaElement({
    ident, coef, editable, active, onClick = () => null, variant, unknown,
    srcToDst, multi, value = null, balancesOn
}) {
    const { t } = useTranslation();

    // Extract data from coefficient
    const coefSign = getFormulaElementSign(coef);
    const coefCondition = getFormulaElementCondition(coef);

    // Sign character and coloring
    const sign = coefSign > 0 ? "+" : coef < 0 ? "-" : "";
    const bvariant = (active ? '' : 'outline-') +
	  (variant ? variant : (coef > 0 ? "success" : "danger"));
    const class_padding = coefSign > 0 ? "pl-2" : "";

    // Condition fulfillment and representation
    const valueForCondition = value === null ? 0 : value;
    const fulfilledCondition = isFormulaElementConditionFulfilled(coefCondition, valueForCondition);
    const unfulfilledCondition = !fulfilledCondition;
    const class_strike = unfulfilledCondition ? "text-decoration-line-through" : "";
    const conditionString = coefCondition === 2 ? ">= 0" : "< 0";
    const coefConditionElem = coefCondition === 1 ?
	  <></>
	  :
	<div className='text-end border-top px-2'>
	    <small><span className={class_strike}>{conditionString}</span></small>
	</div>;

    // Multi-check
    //const thisDsts = srcToDst ? srcToDst[ident] : {};
    const cc = getSrcIdConditionCounts(srcToDst, ident);
    const multiOK = (((cc[1] === 1) && (cc[2] === 0) && (cc[3] === 0)) ||
		     ((cc[1] === 0) && (cc[2] === 1) && (cc[3] === 1)) ||
		     ((cc[1] === 0) && (cc[2] === 0) && (cc[3] === 0)));
    const badMulti = !multiOK;

    // Compose it together
    return (
	<Button className='me-2 my-1 p-0'
		disabled={!editable} variant={bvariant}
		onClick={() => onClick(ident, coef)}>

	    {/** sign left */}
	    <Row className="g-0" >
		{sign ?
		 <Col size="auto" className={'m-auto border-end ' + class_padding}>
		     <div className='px-2'>
			 {sign}
		     </div>
		     {coefConditionElem}
		 </Col>
		 :
		 <></>
		}
		<Col className='text-end'>
		    <div className='px-2'>
			{badMulti ?
			 <span title={t('rep-ident_cant_be_used_several_times')}>&#x1f6d1;</span>
			 : <></>}
			{unknown ?
			 <span title={t('rep-ident_isnt_allowed')}>&#x26a0;</span>
			 : <></>}
			{ident}
		    </div>
		    {balancesOn ?
		     value !== null ?
		     <div className='text-end border-top px-2'>
			 <small>{format_amount(value)}</small>
		     </div>
		     :
		     <div className='text-end border-top px-2'>
			 -,--
		     </div>
		     :
		     <></>
		    }
		</Col>
	    </Row>
	</Button>
    )
}
