import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { his_fetch, his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { MdEmail } from "react-icons/md";
import { IoIosCopy } from "react-icons/io";
import { useParams } from "react-router-dom";
import { Loading } from "./loading";
import { date_formatCZ } from "../lib/date-utils";
import { warehouses_names_ids_separe } from "../lists/warehouses-defs";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getProduct } from "../api/products";
import { queryStatusToLoadedStatus } from "../api/common";
import { getRecipients, sendMessage } from "../api/messaging";
import { Controller, FormProvider, useForm } from "react-hook-form";

interface MessengerProps {
  type: "prod" | "nod" | "pdd" | "inventory";
}

export function Messenger({ type }: MessengerProps) {
  const { t } = useTranslation();

  const { id } = useParams();
  const { code } = useParams();
  const { fetchDate } = useParams();
  const { wh } = useParams();

  const [statusMessage, setStatusMessage] = useState("");
  const [statusVariant, setStatusVariant] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [show, setShow] = useState(false);
  //const [productID, setProductID] = useState(null);
  //const [loadedStatusProduct, setLoadedStatusProduct] = useState(0);

  const {
    data: productID,
    status,
    isFetching,
  } = useQuery({
    queryKey: ["messenger", "product", id],
    enabled: type === "prod",
    queryFn: async ({ queryKey, signal }) => {
      const product = await getProduct(queryKey[2], { signal });
      return product.product.productId;
    },
    initialData: -1,
  });
  const loadedStatusProduct = queryStatusToLoadedStatus(status, isFetching);

  //console.log(productID);
  //console.log(code);

  function get_id(type) {
    switch (type) {
      case "prod":
        return productID; //id produktu
      case "nod":
        return code; // nod_KodDokladu
      case "pdd":
        return id; // taky kod dokladu
      case "inventory":
        return fetchDate + "/" + wh; // taky kod dokladu
      default:
        return "";
    }
  }

  function get_subject(type) {
    switch (type) {
      case "prod":
        return t("product_link") + " " + productID;
      case "nod":
        return t("order_purch_link") + " " + code;
      case "pdd":
        return t("delivery_note_link") + " " + id;
      case "inventory":
        return (
          t("inventory_link") +
          " " +
          warehouses_names_ids_separe[wh] +
          t("due_day") +
          (fetchDate ? date_formatCZ(fetchDate) : "-")
        );
      default:
        return "";
    }
  }

  const objectID = get_id(type);
  // console.log(objectID);
  const subject = get_subject(type);
  //tady podle typu rozhoduju: , dostanu typ a userlogin , na začátku si dělám kontrolu, jeslti typ je validní
  // u produktu dostavam kod produktu, ale my pracacujem s idckem - takze si zavolam fetcher, fetchnu si produkt a tam to id mám,
  //	<Messenger userlogin={userlogin} id={product.k_IDProduktu} type={'prod'} subject={"Odkaz na produkt ID " + product.k_IDProduktu} />

  function copyLink() {
    const realURL = window.location.href;
    const notificationURLposition = realURL.search("notification");
    const finalURL = notificationURLposition > 0 ? realURL.slice(0, -15) : realURL;
    navigator.clipboard.writeText(finalURL);
    setStatusMessage(t("link_copied"));
    setStatusVariant("info");
    setShowStatus(true);
    setShow(false);
  }

  return (
    <Row className="mb-1">
      <Col className="text-end">
        <Alert
          show={showStatus}
          variant={statusVariant}
          className="mb-0 clearfix closerStyle"
          style={{ paddingTop: "6px", paddingBottom: "0px", paddingLeft: "4px", paddingRight: "4px" }}
        >
          <h5 className="float-end myHover me-2" style={{ cursor: "pointer" }} onClick={() => setShowStatus(false)}>
            ×
          </h5>
          <span className="float-start me-5 ms-2">{statusMessage}</span>
        </Alert>
      </Col>
      <Col xs="auto">
        <OverlayTrigger
          onToggle={setShow}
          show={show}
          trigger="click"
          placement="left-start"
          overlay={
            <Popover style={{ maxWidth: "500px", width: "500px" }}>
              <div className="m-3">
                <h5 className="float-end myHover" style={{ cursor: "pointer" }} onClick={() => setShow(false)}>
                  ×
                </h5>
                <br />
                {!his_fetch_success(loadedStatusProduct) && type === "prod" ? (
                  <Alert className="m-3 me-5" variant="danger">
                    {t("mess-cannot_send_mess")}.
                  </Alert>
                ) : (
                  <MessageForm
                    statusMessage={statusMessage}
                    setStatusMessage={setStatusMessage}
                    setStatusVariant={setStatusVariant}
                    setShowStatus={setShowStatus}
                    subject={subject}
                    id={objectID}
                    type={type}
                    setShow={setShow}
                  />
                )}
              </div>
            </Popover>
          }
        >
          <Button size="sm" className="pb-2" title={t("send_link_by_email")} variant="success">
            <span className="fs-5">
              <MdEmail />
            </span>{" "}
            {t("message")}
          </Button>
        </OverlayTrigger>
        <Button size="sm" onClick={copyLink} className="ms-2 pb-2" title={t("copy_link")} variant="info">
          <span className="fs-5 text-white">
            <IoIosCopy />
          </span>
        </Button>
      </Col>
    </Row>
  );
}

function getIdPropertyName(type: MessengerProps["type"]) {
  switch (type) {
    case "prod":
      return "productId";
    case "nod":
      return "purchaseOrderId";
    case "pdd":
      return "salesDeliveryNoteId";
    case "inventory":
      return "inventoryCode";
    default:
      return "";
  }
}

interface MessageFormValues {
  recipient: string;
  message: string;
}

function MessageForm({ statusMessage, setStatusMessage, setStatusVariant, setShowStatus, subject, id, type, setShow }) {
  const { t } = useTranslation();
  const [sendingEmailError, setSendingEmailError] = useState(false);
  const form = useForm<MessageFormValues>();

  const {
    data: recipients,
    status,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["recipients"],
    queryFn: async ({ signal }) => {
      try {
        return await getRecipients({ signal });
      } catch {
        setStatusMessage(t("cant_load_users_list"));
        setStatusVariant("danger");
        setShowStatus(true);
        setShow(false);
      }
    },
    initialData: [],
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const { mutateAsync, isPending: sending } = useMutation({
    mutationFn: async ({ recipient, message }: MessageFormValues) => {
      const messageStruct: Dto.SendMessageRequest = {
        message: message,
        linkUrl: window.location.pathname,
        subject: "@fullname (@username) " + t("sends_you") + ": " + subject,
        [getIdPropertyName(type)]: id,
      };
      return sendMessage(recipient, messageStruct);
    },
    onSuccess: () => {
      setStatusMessage(t("email_sent"));
      setStatusVariant("success");
      setShowStatus(true);
      setShow(false);
    },
    onError: (e) => {
      setStatusMessage(t("sending_fail") + ": " + e.message);
      setStatusVariant("danger");
      setSendingEmailError(true);
    },
  });

  const submit = form.handleSubmit((recipientMessage) => {
    return mutateAsync(recipientMessage);
  });

  const reloadIt = () => {
    return refetch();
  };

  if (!his_fetch_success(loadedStatus)) {
    //troublesome
    return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
  }

  const recEmail = form.watch("recipient");

  return (
    <Card.Body>
      <FormProvider {...form}>
        <Form.Group controlId="recipientSelect">
          <Form.Label>{t("to_whom")}:</Form.Label>
          <Controller
            name="recipient"
            control={form.control}
            rules={{ required: true, minLength: 1 }}
            render={({ field }) => (
              <Form.Control as="select" {...field}>
                <option value="">&mdash; {t("choose_receiver")} &mdash;</option>
                {recipients.map(function (rec) {
                  return (
                    <option key={rec.username} value={rec.username}>
                      {rec.name} ({rec.username})
                    </option>
                  );
                })}
              </Form.Control>
            )}
          />
          <Form.Control.Feedback type="invalid">{t("you_have_to_choose_receiver")}.</Form.Control.Feedback>
        </Form.Group>
        <br />
        <Form.Group controlId="messageText">
          <Form.Label>{t("message_text_optional")}:</Form.Label>
          <Form.Control as="textarea" rows={3} {...form.register("message")} />
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <span className="text-muted">{recEmail}</span>
          </Col>
          <Col xs="auto">
            <Button size="sm" className="mt-2" onClick={submit} disabled={sending || !form.formState.isValid}>
              {sending ? <Loading message={t("sending")} margin="0" size="small" /> : t("send")}
            </Button>
          </Col>
        </Row>
        <Alert
          dismissible
          onClose={() => setSendingEmailError(false)}
          className="mb-0"
          show={sendingEmailError}
          variant="danger"
        >
          {statusMessage}
        </Alert>
      </FormProvider>
    </Card.Body>
  );
}
