//used in dynamic-load
export const quarters = {
	0: "1st_quarter",
	1: "1st_quarter",
	2: "1st_quarter",
	3: "2nd_quarter",
	4: "2nd_quarter",
	5: "2nd_quarter",
	6: "3rd_quarter",
	7: "3rd_quarter",
	8: "3rd_quarter",
	9: "4th_quarter",
	10: "4th_quarter",
	11: "4th_quarter",
}