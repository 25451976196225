/**
 * Icons for sorting filters.
 * @module comp/SortIconsRhf
 * @author Miroslav Lhoťan <miroslav.lhotan@netglade.cz>
 */

import React, { useEffect } from "react";
import { FaSortAlphaDownAlt, FaSortAlphaDown, FaSortAmountDownAlt, FaSortAmountDown } from "react-icons/fa";
import { useFormContext } from "react-hook-form";

//seen all - OK

interface SortIconsRhfProps {
  columnName: string;
  filterFieldName: string;
  numeric?: boolean;
}

export function SortIconsRhf({ columnName, filterFieldName, numeric }: SortIconsRhfProps) {
  const form = useFormContext();

  const ascendingIcon = numeric ? <FaSortAmountDownAlt /> : <FaSortAlphaDown />;
  const descendingIcon = numeric ? <FaSortAmountDown /> : <FaSortAlphaDownAlt />;
  const sortAscending = form.watch(filterFieldName)?.startsWith("-") === false;
  const active = sortAscending
    ? form.watch(filterFieldName) === columnName
    : form.watch(filterFieldName) === "-" + columnName;
  const color1 = active && sortAscending ? "text-white bg-dark" : "";
  const color2 = active && sortAscending === false ? "text-white bg-dark" : "";

  return (
    <p className="mb-0">
      <span
        style={{ cursor: "pointer" }}
        className={color1 + " p-1 my-3 d-inline"}
        onClick={() => {
          form.setValue(filterFieldName, columnName);
        }}
      >
        {ascendingIcon}
      </span>
      &nbsp;&nbsp;
      <span
        style={{ cursor: "pointer" }}
        className={color2 + " p-1 my-3 d-inline"}
        onClick={() => {
          form.setValue(filterFieldName, "-" + columnName);
        }}
      >
        {descendingIcon}
      </span>
    </p>
  );
}
