/**
 * Functions used for products.
 * @module products/product-utils
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */

import { get_package_weight_kg } from '../lib/generic-checks'
import Fraction from "fraction.js";

/**
 * Goes throught array of dictionaries, sorts it according to the id, and return array of unique ids
 * 
 * @param {any} data - array of dictionaries
 * @param {string} productID_db_name - the name of productID key in particular database
 * @returns {array} - array of unique ids
 */
export function unique_sorted_product_ids(data, productID_db_name) {
    const unique_ids_sorted = data
        .sort((a, b) => a[productID_db_name] - b[productID_db_name])
        .map((m) => m[productID_db_name])
        .filter((v, i, a) => a.indexOf(v) === i)
    return unique_ids_sorted;
}

/**
 * Counts package weight in kg
 * 
 * @param {any} batchLine - dictionary with detail of single batch
 * @returns {number}
 */

export function kg_package_weight(batchLine, fraction = false) {
    try {
        const result = get_package_weight_kg(batchLine.pj_HmotnostMj, batchLine.pj_MjHmotnostiMj, batchLine.pjo_MnozstviSklMjVObalu);
        return fraction ? result : parseFloat(result);
    }
    catch (ex) {
        return "error";
    }
}

/**
 * Counts kg weight of one batch for twist data
 * 
 * @param {any} batchLine - data for one batch
 * @param {boolean} fraction - true if Fraction should be returned
 * @returns {number}
 */
export function get_batch_weight_kg_twist(batchLine, fraction = false) {
    try {
        const kg_package_weight_fraction = kg_package_weight(batchLine, true);
        //console.log(kg_package_weight_fraction);
        const items_count_fraction = Fraction(batchLine.sth_MnozstviSkl).div(Fraction(batchLine.pjo_MnozstviSklMjVObalu));
        //console.log(items_count_fraction);
        const result = kg_package_weight_fraction.mul(items_count_fraction);
        return fraction ? result : parseFloat(parseFloat(result).toFixed(2)); //zaokrouhlí na 2 desetiná místa a opět vrátí jako float, aby na to fungoval icompare
    }
    catch (ex) {
        return "error";
    }
}

/**
 * Counts kg weight of one batch for warehouse data
 * 
 * @param {any} batchLine - data for one batch
 * @param {boolean} fraction - true if Fraction should be returned
 * @returns {number}
 */
export function get_batch_weight_kg_warehouse(batchLine, fraction = false) {
    try {
        const kg_package_weight_fraction = kg_package_weight(batchLine, true);
        //console.log(kg_package_weight_fraction);
        const items_count_fraction = Fraction(batchLine.sr_items_count);
        //console.log(items_count_fraction);
        const result = kg_package_weight_fraction.mul(items_count_fraction);
        return fraction ? result : parseFloat(parseFloat(result).toFixed(2));
    }
    catch (ex) {
        return "error";
    }
}

/**
 * Return number of packages based on Twist data
 * 
 * @param {any} batchLine - data for one batch
 * @returns {number}
 */
export function get_package_count_twist(batchLine) {
    try {
        const result = Fraction(batchLine.sth_MnozstviSkl).div(Fraction(batchLine.pjo_MnozstviSklMjVObalu));
        return parseFloat(parseFloat(result).toFixed(2));
    }
    catch (ex) {
        return "error";
    }
}

/**
 * 
 * @param {*} m - value of monitoring
 * @returns Ano, Ne, ?, ERROR for values monitoring batch or bbd
 */
export function mandatory_format(m) {
    switch (m) {
        case null:
            return "?";
        case "MANDATORY":
            return "Ano";
        case "NOT_APPLICABLE":
            return "Ne";
        default:
            return "ERROR";
    }
}

/**
 * Creates the dictionary with true/false value from all unique warehouses founds in records
 * 
 * @param {any} stocksData - all data to choose from
 * @param {boolean} boolean - which boolean is required
 * @returns {dictionary}
 */

export function create_wh_dict(stocksData, boolean) {
    const unique_whs = stocksData.map((rec) => rec.wid).filter((v, i, a) => a.indexOf(v) === i).sort();
    return unique_whs.reduce((acc, v) => ({ ...acc, [v]: boolean }), {});
}