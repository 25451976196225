/**
* Order Sale Detail
* @module orders/order-sale-detail
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Image, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Returns list of orders sale
 * 
 * 
 * @param {any} userlogin - info about logged user
 * @returns {component}
 */
export function OrderSaleDetail({ userlogin }) {
    const { t } = useTranslation();

    const { code } = useParams();

    const [order, setOrder] = useState(null);
    const [items, setItems] = useState([]);
    //const [products, setProducts] = useState([]);
    const [loadedStatus, setLoadedStatus] = useState(0);

    const reloadIt = () => {
        setLoadedStatus(0);
        setOrder(null);
        setItems([]);
    }


    useEffect(() => {
        if(order === null){
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/orders/sale/" + code + "/new",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            setOrder(result.order);
                            setItems(result.items);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setOrder("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, order, code]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    return (
        <ErrorWrap>
            <Button size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
            <h3 className="d-inline align-middle">{t('ord-sal')} {order.orderCode}</h3>
            <h4 className="mt-1">{t('partner')}&nbsp;
                <LinkContainer to={{ pathname: "/partners/list/" + order.partnerCode + "/orders/sale" }}>
                    <a href="!#">{order.partnerCode}</a>
                </LinkContainer>
            </h4>

            <br />
            <h5>{t('biz-items')}</h5>
            <OSDWeb items={items} />
            <OSDApp items={items} />
        </ErrorWrap>
    );
}

/**
 * List of orders sale for web
 * 
 * @param {any} items - items to display
 * @returns {component}
 */
function OSDWeb({ items }) {
    const { t } = useTranslation();

    return (
        <Table size="sm" striped className="d-none d-md-table">
            <thead className="beGray">
                <tr>
                    <th>{t('prod-id')}</th>
                    <th>{t('prod-code')}</th>
                    <th className="text-end">{t('amount')}</th>
                    <th>{t('unit')}</th>
                    <th>{t('prod-name')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map(function (item, idx) {
                        //console.log(item);
                        return (
                            <tr key={idx}>
                                <td>{item.productId}</td>
                                <td>
                                    <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(item.productCode) + "/view" }}>
                                        <a href="!#">{item.productCode}</a>
                                    </LinkContainer>
                                </td>
                                <td className="text-end">{item.quantity}</td>
                                <td>{item.unitOfMeasurementCode}</td>
                                <td>{item.productName}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    );
}

/**
 * List of orders sale for web
 * 
 * @param {any} items - items to display
 * @returns {component}
 */
function OSDApp({ items }) {
    const { t } = useTranslation();

    return (
        <Table size="sm" striped className="d-md-none">
            <tbody>
                {items.map(function (item, idx) {
                    return (
                        <tr key={idx}>
                            <td>
                                <Row>
                                    <Col>{t('prod-id')}: {item.productId}</Col>
                                    <Col className="text-end"> {t('ord-amount_and_unit')}: {item.quantity}&nbsp;{item.unitOfMeasurementCode}</Col>
                                </Row>
                                <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(item.productCode) + "/view" }}>
                                    <a href="!#">
                                        {t('prod-code')}: {item.productCode} </a>
                                </LinkContainer> <br />
                                {t('prod-name')}: {item.productName}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>

    );
}