/**
 * Dynamic loading management for faster loading of long lists.
 * @module comp/DynamicLoadPaged
 * @author Miroslav Lhoťan <miroslav.lhotan@netglade.cz>
 */

import React, { useState, useEffect } from "react";
import { date_getTomorrow, date_formatISO } from "../lib/date-utils";
import { Button, Image, Row, Col } from "react-bootstrap";
import { Loading } from "./loading";
import { Pager } from "./pager";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  displayPeriod,
  ItemsAndFilteredPaged,
  nameOfCurrentPeriod,
  nameOfPreviousPeriod,
  parse_period,
  subtract_period,
} from "./dynamic-load";
import { LoadedStatus } from "../api/common";

export interface DynamicLoadPagedProps {
  period: string;
  loadData: (rangeFrom: string, rangeTo: string) => void;
  reloadIt: () => void;
  loadingState: LoadedStatus;
  filteredCount: number;
  offset: number;
  setOffset: (offset: number) => void;
  totalCount: number;
  rangeCount: number;
  cleanFilters: () => void;
}

export function DynamicLoadPaged({
  period,
  loadData,
  reloadIt,
  loadingState,
  filteredCount,
  offset,
  setOffset,
  totalCount,
  rangeCount,
  cleanFilters,
}: DynamicLoadPagedProps) {
  const { t } = useTranslation();
  const pageSize = 20;

  const first_end_date = date_formatISO(date_getTomorrow());
  const loadPeriod = parse_period(period, first_end_date);
  const firstStartDate = loadPeriod.current;

  const [rangeStart, setRangeStart] = useState(firstStartDate);
  const dataEnd = rangeCount === totalCount;

  useEffect(() => {
    loadData(rangeStart, first_end_date);
  }, []);

  function goBack() {
    const new_start_date = subtract_period(rangeStart, loadPeriod);
    setRangeStart(new_start_date);
    loadData(new_start_date, first_end_date);
  }

  const loading = loadingState === 1;

  const variant = loadingState === 3 ? "danger" : "primary";
  const loadedStuff = rangeCount + "/" + (totalCount ? totalCount : "-");

  return (
    <>
      <Row className="g-0">
        <Col xs="12" md="4">
          <Button size="sm" className="me-2 d-inline" onClick={reloadIt}>
            <Image src="/img/reload.svg" height="19" />
          </Button>

          <ButtonGroup style={{ width: "200px" }}>
            <Button
              size="sm"
              variant={variant}
              disabled={loading || dataEnd}
              onClick={goBack}
              title={dataEnd ? t("all_loaded") : ""}
            >
              {loading ? (
                <Loading message={t("loading")} margin="0" size="small" />
              ) : (
                <>
                  {t("show_also")}
                  {loadingState === 3
                    ? nameOfCurrentPeriod(period, rangeStart, t)
                    : nameOfPreviousPeriod(period, rangeStart, t)}
                </>
              )}
            </Button>

            <DropdownButton size="sm" as={ButtonGroup} title="" id="bg-nested-dropdown">
              <div className="px-3 py-2" style={{ maxWidth: "300px", width: "300px" }}>
                <small className="text-muted">
                  {t("loaded")} {loadedStuff} {t("records_from_db")}.
                </small>
              </div>
            </DropdownButton>
          </ButtonGroup>
          <span className="" style={{ minWidth: "300px", maxWidth: "300px" }}>
            &nbsp;&nbsp;
            {t("showing")}&nbsp;
            {displayPeriod(rangeStart, firstStartDate, period, loadingState, t)} &nbsp;
          </span>
          {loadingState === 3 ? (
            <>
              <br />
              <small className="ms-5  mt-1">⚠️ {t("loading_fail")}</small>
            </>
          ) : (
            ""
          )}
        </Col>
        <Col xs="12" md="4" className="text-center">
          <ItemsAndFilteredPaged totalCount={rangeCount} filteredCount={filteredCount} cleanFilters={cleanFilters} />
        </Col>
        <Col xs="12" md="4">
          <Pager offset={offset} pagesize={pageSize} total={filteredCount} callback={setOffset} disabled={loading} />
        </Col>
      </Row>
    </>
  );
}
