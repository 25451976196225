/**
 * Partner orders purchase view.
 * @module partners/partner-orders-puchase
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import Table from 'react-bootstrap/Table';
import { Boolean } from '../comp/boolean';
import { LinkContainer } from 'react-router-bootstrap';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { Pager } from '../comp/pager';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerOrdersPurchase({ purchaseOrders, loadedPurchaseOrdersStatus, reloadButton }) {
    const { t } = useTranslation();

    const [offset, setOffset] = useState(0);

    if (!his_fetch_success(loadedPurchaseOrdersStatus)) {
        return <HisFetchStatus status={loadedPurchaseOrdersStatus} loadingType="big" errorType="fetcherError" waitingType="explained" reloadButton={reloadButton} />;
    }

    //console.log(purchaseOrders);

    /*
    const saleOrdersTransformed = saleOrders.map(
        function (ord) {
            const remainingInCurrency = ord.pod_CastkaCelkemMen * (100 - ord.pod_FakturovanoProc) / 100;
            ord["remainingInCurrency"] = remainingInCurrency;
            return ord;
        });

    const saleOrdersRemaining = saleOrdersTransformed.filter(
        function (ord) {
            return ord.remainingInCurrency > 0;
        });

    const remainingCurrencies = saleOrdersRemaining.reduce(
        function (acc, ord) {
            if (!acc[ord.pod_KodMeny]) {
                acc[ord.pod_KodMeny] = 0;
            }
            acc[ord.pod_KodMeny] += ord.remainingInCurrency;
            return acc;
        }, {});

    const saleOrdersTable = saleOrdersTransformed.slice(offset, offset + 20);
    */

    const ordersTable = purchaseOrders.slice(offset, offset + 20);

    return (
        <ErrorWrap>
            {purchaseOrders.length === 0 ?
                <p>{t('biz-no_ord_purch_to_show')}.</p>
                :
                <>
                    <Pager offset={offset} pagesize={20} total={purchaseOrders.length} callback={setOffset} />
                    <POWeb ordersTable={ordersTable} />
                    <POApp ordersTable={ordersTable} />
                </>
            }
            {/** 
            <Row>
                <Col md={4}>
                    <Card>
                        <Card.Header as="h5">Nevyfakturované objednávky</Card.Header>
                        <Card.Body>
                            <table>
                                <tbody>
                                    {Object.keys(remainingCurrencies).map(function (curr) {
                                        return (
                                            <tr key={curr}>
                                                <td className="text-end">{format_amount(remainingCurrencies[curr])}</td>
                                                <td>{curr}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Pager offset={offset} pagesize={20} total={saleOrders.length} callback={setOffset} />
            <SOWeb saleOrdersTable={saleOrdersTable} />
            <SOApp saleOrdersTable={saleOrdersTable} />
              */}
        </ErrorWrap >

    )
}

function POWeb({ ordersTable }) {
    const { t } = useTranslation();
    return (
        <Table hover size="sm" striped className="d-none d-lg-table">
            <thead className="beGray">
                <tr>
                    <th className='pt-3'>{t('biz-ord_code')}</th>
                    <th className="text-end">{t('date')}</th>
                    <th className="text-center">{t('note')}</th>
                    <th className="text-end">{t('biz-amount_money')}</th>
                    <th>{t('biz-currency')}</th>
                    <th>{t('biz-closed')}</th>
                    <th className="text-center">{t('biz-invoiced')} %</th>
                </tr>
            </thead>
            <tbody>
                {ordersTable.map(function (po) {
                    return (
                        <LinkContainer to={{ pathname: "/orders/purchase/" + po.nod_KodDokladu }} key={po.nod_KodDokladu} >
                            <tr>
                                <td>{po.nod_KodDokladu}</td>
                                <td className="text-end">{date_formatCZ(po.nod_DatDokladu)}</td>
                                <td className="text-center">{po.nod_Poznamka}</td>
                                <td className="text-end">{po.nod_CastkaCelkemMen}</td>
                                <td>{po.nod_KodMeny}</td>
                                <td><Boolean value={po.nod_Uzavreno} variant="onlyTrue" /></td>
                                <td className="text-center">{po.nod_FakturovanoProc} %</td>
                            </tr>
                        </LinkContainer>
                    );
                })}
            </tbody>
        </Table>
    );
}

function POApp({ ordersTable }) {
    const { t } = useTranslation();
    return (
        <Table striped className="d-lg-none">
            <tbody>
                {ordersTable.map(function (po) {
                    return (
                        <LinkContainer to={{ pathname: "/orders/purchase/" + po.nod_KodDokladu }} key={po.nod_KodDokladu}>
                            <tr>
                                <td>
                                    <Row className="g-0">
                                        <Col className="beBigger me-1">{po.nod_KodDokladu}</Col>
                                        <Col className="text-end align-bottom">{format_amount(po.nod_CastkaCelkemMen)} {po.nod_KodMeny}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{date_formatCZ(po.nod_DatDokladu)}</Col>
                                        <Col className="text-end">{t('biz-closed')}: <Boolean value={po.nod_Uzavreno} variant="onlyTrue" /></Col>
                                    </Row>
                                    <Row>
                                        <Col>{t('note_short')}: {po.nod_Poznamka}</Col>
                                        <Col xs="auto" className="text-end">{t('biz-invoiced')}: {po.nod_FakturovanoProc}&nbsp;%</Col>
                                    </Row>
                                    <Row>
                                        <Col></Col>
                                    </Row>
                                </td>
                            </tr>
                        </LinkContainer>
                    );
                })
                }
            </tbody>
        </Table>
    );
}
