import React, { useState } from 'react';
import { Button, Nav, Card, Row, Col } from 'react-bootstrap';
import { updateReportConfig, filterReportConfigSrc } from './report-config-utils';
import { TransposedConfig } from './report-config-transposed';
import { NormalConfig } from './report-config-normal';
import { useTranslation } from 'react-i18next';
import { ExcelDownloader } from '../comp/excel';
import { TextFileDownloader } from '../comp/file-downloader';

/**
 * Allows the user to edit given configuration. All configuration
 * changes are propagated liv to state variable config/setConfig passed
 * as props. If multi is set, allows using the same srcId in
 * multiple formulae.
 * @param {any} config - dictionary of dictionaries representing the configuration
 * @param {function} setConfig - config state variable setter
 * @param {boolean} multi - if true, given srcId may be used in multiple formulae
 * @param {string} name - name of this configuration
 * @param {function} onSave - callback provided for saving the configuration
 * @param {any} setup - meta-configuration of the configuration editor
 * @param {boolean} readonly - if true disable all editing features
 * @param {any} balances - dictionary of sources to balances mapping
 * @param {array} subMenu - additional buttons to click on
 * @param {function} onSubMenu - when user clicks
 * @param {string} currentSubMenu - currently selected submenu item
 * @param {string} subMenuLabel - label shown left of submenu
 * @param {boolean} saved - state - represents whether config was saved/changed - mandatory param, it has to be on the config level, so as clicking to results and back do not reset it
 * @param {function} setSaved - changes saved value - mandatory param
 * @param {string} savingMess - says the result of saving
 * @param {function} setSavingMess - resets the result of saving after editing config again
 * @param {array} history - Used for storing configuration history
 * @param {function} setHistory - useState changing function
 * @param {array} rehistory - Used for storing forward history (to implement redo)
 * @param {function} setRehistory - useState changing function
 * @param {any} accountNames - names of accounts to display in transposed config
 * @return {component}
 */
export function ReportConfig({
	config = {}, setConfig = () => null,
	multi = false,
	name, onSave,
	setup = {},
	readonly = false,
	balances = null,
	subMenu = null,
	onSubMenu = () => null,
	currentSubMenu = null,
	subMenuLabel = null,
	savingMess = "",
	setSavingMess,
	saved,
	setSaved, //new props, that causes the problem: "Cannot update a component (`NormalConfig`) while rendering a different component (`NormalConfigRow`). To locate the bad setState() call inside `NormalConfigRow`," 
	//how to make error: change config, save it, press undo
	history, setHistory,
	rehistory, setRehistory,
	accountNames = {}, keyName,
	reportName = ""
}) {
	const { t } = useTranslation();

	// Default is forward display, transposed display if true
	const [transposed, setTransposed] = useState(false);

	function changeConfig(newConfig) {
		// Prepends to history
		setHistory([config, ...history]);

		// Discard redo history
		setRehistory([]);

		// Set as new configuration
		setConfig(newConfig);

		// Mark dirty
		setSaved(false);

		// Previous message about saving result should disappear after new edit of config
		setSavingMess("");

	}

	function undoConfigStep() {
		// Very primitive linear undo mechanism
		setHistory(history.slice(1));

		// Record for possible redo
		setRehistory([config, ...rehistory]);

		// Set the configuration
		setConfig(history[0]);

		// If run after save, definitely should mark the current status as dirty
		setSaved(false);

		setSavingMess("");
	}

	function redoConfigStep() {
		// Together history+rehistory form a zipper structure (until
		// rehistory gets discarded upon manual edit)
		setHistory([config, ...history]);
		setRehistory(rehistory.slice(1));
		setConfig(rehistory[0]);
		setSaved(false);
		setSavingMess("");
	}

	function updateConfig(...triplets) {
		// Uses the report-config-utils implementation
		changeConfig(updateReportConfig(config, ...triplets));
	}

	function performSave() {
		// Just wrapper around whatever we get
		if (onSave) {
			onSave(config);
		}
		setSaved(true);
	}

	// All srcIds seen either in balances or in any formulae of current config.

	//these are those allSrcIds, needed by function getAvailableFormulaElementSrcIds in IdentFilterForm in report-config-forms.js
	const allSrcIds = Object.keys(config)
		.reduce((acc, dstId) => [...acc, ...Object.keys(config[dstId])], setup.srcIds || [])
		.filter((v, i, a) => a.indexOf(v) === i)
		.sort();

	// Check for multiple usages of srcIds - store complete formulae
	// in the mapping for detailed inspection when handling
	// conditions.
	const srcToDst = allSrcIds
		.reduce((acc, srcId) => ({
			...acc,
			[srcId]: filterReportConfigSrc(config, srcId)
		}), {});

	// Compose the toolbar and actual editing view
	const config_data_xlsx = Object.keys(config).map((dstId) => [dstId, String(Object.keys(config[dstId])).split(",").join(", ")]);
	// console.log(config_data_xlsx);
	// console.log(config);

	const fileNameConfig = reportName + "-config.json";

	return (
		<>
			<ConfigToolbar historyLength={history.length}
				rehistoryLength={rehistory.length}
				onUndo={undoConfigStep}
				onRedo={redoConfigStep}
				name={name}
				transposed={transposed}
				onSave={onSave ? performSave : null}
				saved={saved}
				subMenu={subMenu}
				onSubMenu={onSubMenu}
				currentSubMenu={currentSubMenu}
				subMenuLabel={subMenuLabel}
				setTransposed={setTransposed}
				savingMess={savingMess} />
			<Row>
				<Col className="text-end">
					<Card body className='float-end mb-2' style={{ width: "auto" }}>
						<Row>
							<Col>
								<h6 className='bolder float-start mt-4 me-4'>{t('file_download')}</h6>
							</Col>
							<Col xs="auto">
								<ExcelDownloader tableData={config_data_xlsx} fileName={reportName + "-config.xlsx"} />
							</Col>
							<Col xs="auto">
								<TextFileDownloader text={config} name={fileNameConfig} />
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>


			{transposed ?
				<TransposedConfig config={config}
					updateConfig={updateConfig}
					multi={multi}
					readonly={readonly}
					allSrcIds={allSrcIds}
					srcToDst={srcToDst}
					setup={setup}
					accountNames={accountNames}
					keyName={keyName} />
				:
				<NormalConfig config={config}
					updateConfig={updateConfig}
					setup={setup}
					readonly={readonly}
					srcToDst={srcToDst}
					multi={multi}
					allSrcIds={allSrcIds}
				/>}

		</>
	);
}

/**
 * The configuration editor toolbar that allows global user
 * interaction and switching of views.
 * @param {boolean} transposed - whether transposed view is currently shown
 * @param {function} setTransposed - state variable changer to request transposition
 * @param {number} historyLength - length of recorded editing history (for undo)
 * @param {function} onUndo - what to do when user clicks undo
 * @param {string} name - name of the currently edited configuration
 * @param {number} rehistoryLength - possible redo history length
 * @param {array} subMenu - additional buttons to click on
 * @param {function} onSubMenu - when user clicks
 * @param {string} currentSubMenu - currently selected submenu item
 * @param {string} subMenuLabel - label shown left of submenu
 * @return {component}
 */
function ConfigToolbar({
	transposed, setTransposed, historyLength, onUndo, name, rehistoryLength, onRedo, onSave, saved,
	subMenu, onSubMenu, currentSubMenu, subMenuLabel, savingMess
}) {
	const { t } = useTranslation();
	// This will become ConfigToolbar later on
	return (
		<Nav variant="tabs" className="mb-3">
			<Nav.Link disabled>
				{t('rep-config')}:
				<strong className="ms-2">{name}</strong>
			</Nav.Link>
			<Nav.Item>
				{onSave ?
					<>
						<Button disabled={saved}
							onClick={() => onSave ? onSave() : null}
							variant="primary">
							{t('save')}
						</Button> <br />
						{savingMess !== "" ? <small className={savingMess[1]}>{savingMess[0]}</small> : ""}
					</>
					: <></>}
			</Nav.Item>
			<Nav.Link disabled>{t('rep-view')}:</Nav.Link>
			<Nav.Link active={!transposed}
				onClick={() => setTransposed(false)}>{t('rep-destionation')}</Nav.Link>
			<Nav.Link active={transposed}
				onClick={() => setTransposed(true)}>{t('rep-source')}</Nav.Link>
			<Nav.Item>
				<Nav.Link disabled>
					{t('rep-edit')}:
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Button disabled={(historyLength === 0) || (!onUndo)}
					onClick={onUndo}
					variant="outline-primary">
					↩{t('undo')}
					{historyLength > 0 ? "(" + historyLength + ")" : ""}
				</Button>
				<Button disabled={(rehistoryLength === 0) || (!onRedo)}
					className="ms-3"
					onClick={onRedo}
					variant="outline-primary">
					↪{t('rep-redo')}
					{rehistoryLength > 0 ? "(" + rehistoryLength + ")" : ""}
				</Button>
			</Nav.Item>
			{subMenu ?
				<SubMenu menu={subMenu}
					onClick={onSubMenu}
					label={subMenuLabel}
					current={currentSubMenu} />
				: <></>}
		</Nav>
	);
}

function SubMenu({ menu, onClick, current, label }) {
	return (
		<>
			{label ?
				<Nav.Item>
					<Nav.Link disabled>
						{label}
					</Nav.Link>
				</Nav.Item>
				: <></>}
			{menu.map((item, idx) =>
				<Nav.Link key={idx}
					active={current === item}
					onClick={() => onClick ? onClick(item) : null}>
					{item}
				</Nav.Link>)}
		</>
	);
}
