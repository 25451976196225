import { authorizedFetch } from "./common";

export function getRecipients(options?: RequestInit) {
  return authorizedFetch<Dto.GetRecipientsItemResponse[]>("/api/messaging/recipients", {
    ...options,
    method: "GET",
  });
}

export function sendMessage(recipient: string, messageRequest: Dto.SendMessageRequest, options?: RequestInit) {
  return authorizedFetch<Dto.SimpleResult>(`/api/messaging/to/${recipient}`, {
    ...options,
    method: "POST",
    body: JSON.stringify(messageRequest),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
