/**
 * Definitions for warehouses names, images, codes
 * @module lib/warehouses-defs
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React from 'react';
import { Image } from 'react-bootstrap';
// warehouses-packagings.json

//používá se to ke generování navigace na stocks
export const physicalWarehouses = ["03", "16", "22", "27", "29", "31", "30"]; // pro stavy skladů

//používá se na orders-purchase a v kroku 2 na avizacích (ale tam je to možná useless)
export function is_warehouse_defined(wh) {
    const warehouses = ["03", 3, "16", 16, "22", 22, "27", 27, "29", 29, "31", 31, "30", 30,];
    return warehouses.includes(wh);
}

// používá se na detailu produktu, druhé číslo udává RowSpan pro tabulku skladů na detailu produktu
export const warehouses = [[16, 2], [22, 0], [27, 1], [3, 1], [30, 1]]; // tohle je jen pro karty, ty se posílají jen do 27, takže Logflex 29 a 31 sem nepatří

//toto se používá na velké kontrole karet v multiselectu - vlastně jde o "karetní" sklady - šlo by předělat
export const whs = {
    3: 3,
    16: 16,
    22: 22,
    27: 27,
    30: 30
}

// používá se v product-checks a na velké kontrole karet
export const valid_packaging_groups = {
    0: "I",
    1: "II",
    2: "III",
}

//na product checks pro rwid pro kontrolu obalu
export const warehouses_remap = {
    "03": "03",
    3: "03",
    "16": "16",
    // 16: "16",
    "22": "22",
    // 22: "22",
    "27": "27",
    // 27: "27",
    "29": "27",
    // 29: "27",
    "31": "27",
    "30": "30"
}

// Nutne pro zobrazeni cisla skladu na tlacitku pro odeslani karty a
// ve sloupecku vedle loga v tabulce skladu na detailu produktu
export const whID2whNumber = {
    3: "3",
    16: "16",
    22: "22",
    27: "27, 29, 31",
    29: "27, 29, 31",
    31: "27, 29, 31",
    30: "30",
}

//sklady pro statistiky
export const whID2whNumberStats = {
    3: "3",
    16: "16",
    22: "22",
    27: "27",
    29: "29",
    31: "31",
    30: "30"

}

//product-detail
export const partnerLogoShort = {
    "GW": <Image src="/gw-logo-short.png" style={{ width: "50px", backgroundColor: "#ff6600", padding: "2px" }} />,
    "LF": <Image src="/LogFlex-Logo_short.png" fluid style={{ height: "40px" }} />,
    "JP": <Image src="/joppa-logo-short.png" fluid style={{ width: "40px" }} />,
    "LL": <Image src="/lorenc-logo-short.png" fluid style={{ width: "40px" }} />,
};

//delivery-notes-sales-detail and warehouses packagings
export const partnerLogo = {
    "GW": <Image src="/GW-Logo-EN.svg" height={40} style={{ backgroundColor: "#ff6600" }} />,
    "LF": <Image src="/LogFlex-Logo.svg" height={40} />,
    "JP": <Image src="/joppa-logo.png" height={40} />,
    "LL": <Image src="/lorenc-logo.png" height={40} />,
};

//order-purchase-detail
export const partnerName = {
    "GW": "Gebrüder Weiss",
    "LF": "Logflex",
    "JP": "Joppa",
    "LL": "Lorenc",
};

// product-detail and warehouses-packagings
export const wid2partner = {
    16: "GW",
    22: "GW",
    27: "LF",
    29: "LF",
    31: "LF",
    3: "JP",
    "03": "JP",
    30: "LL"
};

//product-detail
export const whColor = {
    "GW": "#ff6600",
    "LF": "#2b7b3d",
    "JP": "#294973",
    "LL": "#843179"
};

//seznamy
export const warehouses_names_ids = {
    3: "3 – Joppa Brno",
    "03": "3 – Joppa Brno",
    16: "16 – GebrüderWeiss Syrovice",
    22: "22 – GebrüderWeiss Jeneč",
    27: "27, 29, 31 – LogFlex Jažlovice, Syrovice, Ostrava",
    29: "27, 29, 31 – LogFlex Jažlovice, Syrovice, Ostrava",
    31: "27, 29, 31 – LogFlex Jažlovice, Syrovice, Ostrava",
    30: "30 – Lorenc Logistic"
};

//seznamy
export const warehouses_names_ids_separe = {
    3: "3 – Joppa Brno",
    "03": "3 – Joppa Brno",
    16: "16 – GebrüderWeiss Syrovice",
    22: "22 – GebrüderWeiss Jeneč",
    27: "27 – LogFlex Jažlovice",
    29: "29 – LogFlex Syrovice",
    31: "31 – LogFlex Ostrava",
    30: "30 – Lorenc Logistic",
};

//multiselecty
export const warehouses_for_select = {
    3: "Joppa Brno",
    16: "GebrüderWeiss Syrovice",
    22: "GebrüderWeiss Jeneč",
    27: "LogFlex Jažlovice",
    29: "LogFlex Syrovice",
    31: "LogFlex Ostrava",
    30: "Lorenc Logistic"
}

//v ftp seznamech
export const warehouseTitlesDropdown = {
    3: "3 – Joppa",
    16: "16 – GW Syrovice",
    22: "22 – GW Jeneč",
    27: "27 – LogFlex",
    30: "30 – Lorenc Logistic"
};
