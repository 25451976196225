import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import { date_formatCZ } from "../../lib/date-utils";
import { DisplayADR, ProcessedDeliveryNoteItem } from "./DeliveryNoteDetailWeb";
import { LinkContainer } from "react-router-bootstrap";
import { dnote_field_ok, null_or_qmark, HeaderOrItemField } from "./deliveryNoteChecks";
import { ErrorWrap } from "../../comp/errorwrap";
import { useTranslation } from "react-i18next";

interface PDItemsMobileProps {
  items: ProcessedDeliveryNoteItem[];
  header: Dto.GetDeliveryNoteDetailHeader;
  ErrorBadge: React.FC<{ tag: string }>;
}

export function PDItemsMobile({ items, header, ErrorBadge }: PDItemsMobileProps) {
  return (
    <Accordion className="d-xl-none">
      {items.map(function (item, idx) {
        return <SingleItemMobilePD key={idx} item={item} header={header} ErrorBadge={ErrorBadge} />;
      })}
    </Accordion>
  );
}

interface SingleItemMobilePDProps {
  item: ProcessedDeliveryNoteItem;
  header: Dto.GetDeliveryNoteDetailHeader;
  ErrorBadge: React.FC<{ tag: string }>;
}

export function SingleItemMobilePD({ item, header, ErrorBadge }: SingleItemMobilePDProps) {
  const { t } = useTranslation();
  return (
    <ErrorWrap>
      <Accordion.Item eventKey={item.productId.toString()}>
        <Accordion.Header as="div">
          <div>
            <b>{t("prod-name")}:</b> {item.stockOut.productName}
            <br />
            <b>{t("not-prod_id")}: </b>
            {item.productId}
          </div>
        </Accordion.Header>
        <Accordion.Body className="p-2">
          <Card.Body className="beSmaller">
            <ListGroup variant="flush" className="smaller">
              <ListGroup.Item>
                <Row className="g-0">
                  <Col>
                    {t("deno-contract")}:{" "}
                    <HeaderOrItemField header={header.stockOut.orderCode} item={item.stockOut.orderCode} />
                    {null_or_qmark(item.stockOut.orderCode) ? <ErrorBadge tag={"contractCode"} /> : ""}
                  </Col>
                  <Col className="text-end">
                    {t("warehouse")}: {item.stockOut.bookCode}
                    <ErrorBadge tag={"singleWarehouse"} />
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col xs="auto">
                    {t("biz-centre")}:{" "}
                    <HeaderOrItemField header={header.stockOut.departmentCode} item={item.stockOut.departmentCode} />
                    {null_or_qmark(item.stockOut.departmentCode) ? <ErrorBadge tag={"CentreCode"} /> : ""}
                  </Col>
                  <Col className="text-end">
                    {t("selling_amount_unit")}: {item.stockOut.quantity}&nbsp;{item.stockOut.unitOfMeasurementCode}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col xs="auto">
                    {t("deno-category")}:{" "}
                    <HeaderOrItemField header={header.stockOut.categoryCode} item={item.stockOut.categoryCode} />
                    {null_or_qmark(item.stockOut.categoryCode) ? <ErrorBadge tag={"categoryCode"} /> : ""}
                  </Col>
                  <Col className="text-end">
                    {t("warehouse_amount_unit")}: {item.stockOut.stockQuantity}&nbsp;
                    {item.storageUnitOfMeasurementCode}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    {t("ord-expiry")}: {date_formatCZ(item.expirationDate)}
                    {dnote_field_ok(item.expirationDate) ? (
                      ""
                    ) : (
                      <>
                        <ErrorBadge tag={"expiryMissing"} />
                      </>
                    )}
                  </Col>
                  <Col className="text-end">
                    {t("units_in_package")}: {item.packageUnitOfMeasurementQuantity}&nbsp;
                    {item.storageUnitOfMeasurementCode}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    {t("prod-ADR")}: <DisplayADR adrClass={item.classAdr} />
                  </Col>
                  <Col className="text-end">
                    {t("package_count")}: {item.packageQuantity?.toString()}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {t("prod-code")}:&nbsp;&nbsp;
                <LinkContainer
                  to={{ pathname: "/products/" + encodeURIComponent(item.stockOut.productCode) + "/view" }}
                >
                  <a href="!#">{item.stockOut.productCode}</a>
                </LinkContainer>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </ErrorWrap>
  );
}
