/**
* App components for displaying stockins in progress
* @module orders/stockin-display-mobile
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React from 'react';
import { Row, Col, Badge, Table, Card, Form } from 'react-bootstrap';
import { warehouses_names_ids_separe } from '../lists/warehouses-defs';
import { date_time_format } from '../lib/date-utils';
import { SortIcons } from '../comp/sort';
import { warehouses_for_select } from '../lists/warehouses-defs';
import { MultipleSelect } from '../comp/multiple-select';
import { StockinProgressPath } from './stockin-progress-path';
import { etaDaysWithString } from './stockin-display-comms';
import { BooleanDropdown } from '../comp/boolean';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';

//seen all - OK

/**
 * One line of stocking for app.
 * 
 * @param {any} notify - information about stockin
 * @param {function} toggleDetailShown - collapses/expands detail of stockin
 * @param {any} detailShown - state - which stockins are expanded
 * @param {string} className - says do not display on large screen
 * @param {any} userlogin - information about logged user
 * @param {boolean} authorizedUser - whether user is authorized to use magic buttons in progressPath
 * @param {function} reloadIt - reloads after usage of magic buttons in ProgressPath
 * @param {boolean} showProblematicCol - display column with sign, if stockin is problematic
 * @param {boolean} showDaysToEtaCol - display column of remaining days to eta
 * @param {boolean} global - global tracking view or false in case of order view
 * @param {boolean} confirmedAndAfterETA - true, if stockin was confirmed and is after ETA
 * @returns {component}
 */
export function NotificationLineApp({ notify, toggleDetailShown, detailShown,
    className, userlogin, authorizedUser, reloadIt, showProblematicCol, showDaysToEtaCol, global,
    confirmedAndAfterETA }) {
    const { t } = useTranslation();
    //console.log(notify);
    return (
        <React.Fragment>
            <tr className={className + " beGray4 "} onClick={() => toggleDetailShown(notify.sn_id)}>
                <td colSpan={8} className="p-2 pb-3 px-3">
                    <div className='text-center mb-2'>
                        <StockinProgressPath one_history={notify.history} compact userlogin={userlogin} authorizedUser={authorizedUser} reloadIt={reloadIt} />
                    </div>
                    <Row>
                        <Col>{global ?
                            <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/orders/purchase/" + notify.nop_KodDokladu }}>
                                <a href="!#" >{notify.nop_KodDokladu}</a>
                            </LinkContainer >
                            : ""}</Col>
                        {showProblematicCol ?
                            <Col className='text-end'>{notify.problematic_solve_soon ? t('problematic') + " 🔥" : <><span className='text-muted'>{t('problematic')}</span> OK</>}</Col>
                            : ""}
                    </Row>
                    <Row>
                        <Col xs="auto"><span className='text-muted'>{t('ord-sn_number_short')} </span>{notify.sn_number}</Col>
                        <Col className='text-end'><span className='text-muted'>{t('warehouse')} </span>{warehouses_names_ids_separe[notify.sn_warehouse_id]}</Col>
                    </Row>
                    <Row>
                        <Col>{notify.mp_Jmeno} ({notify.u_username})</Col>
                        <Col className='text-end'><span className='text-muted'>{t('ord-eta')} </span>{notify.sn_eta}
                            {showDaysToEtaCol ?
                                <>
                                    {confirmedAndAfterETA ?
                                        <Badge className='py-2 px-3 ms-2' bg="danger" pill>{etaDaysWithString(notify.days_to_eta, t)}</Badge>
                                        :
                                        <>{etaDaysWithString(notify.days_to_eta, t)}</>
                                    }
                                </>
                                : ""}
                        </Col>
                    </Row>
                    <Row>
                        <Col>{date_time_format(notify.sn_created_at)}</Col>
                        <Col className='text-end'><span className='text-muted'>{t('biz-items')} </span>{notify.items.length}</Col>
                    </Row>
                </td>
            </tr>
            <tr className={" p-0 m-0 " + className}>
                <td colSpan={9} className="p-0 m-0">
                    <div className={'mb-0 px-4 py-3 bg-light' + (detailShown[notify.sn_id] ? "" : " d-none")}>
                        <StockinProgressPath one_history={notify.history} userlogin={userlogin} authorizedUser={authorizedUser} reloadIt={reloadIt} />
                        <h5 className='mt-4'>{t('ord-notified_products')}</h5>
                        {notify.items.map(function (item, idx) {
                            return <NotificationItemsTableApp item={item} key={idx} />
                        })
                        }
                    </div>
                </td>
                <td className="p-0 m-0" colSpan={4}></td>
            </tr>
        </React.Fragment>
    );
}

/**
 * Displays one item of an stocking for mobile.
 * 
 * @param {any} item - details about single item
 * @returns {component}
 */
export function NotificationItemsTableApp({ item }) {
    const { t } = useTranslation();
    return (
        <>
            <Table bordered>
                <tbody>
                    <tr>
                        <td>
                            <Row>
                                <Col><span className='text-muted'>{t('not-prod_id')} </span>{item.sni_k_IDProduktu}</Col>
                                <Col className='text-end'><span className='text-muted'>{t('ord-item_id')} </span>{item.sni_nop_id}</Col>
                            </Row>
                            <span className='text-muted'>{t('code')}  </span>{item.p_KodProduktu} <br />
                            <span className='text-muted'>{t('name')} </span>{item.p_NazevProduktu} <br />
                            <hr className='my-1' />
                            <Row>
                                <Col xs="auto">
                                    <span className='text-muted'>{t('prod-amount_MJ')}</span>
                                    {item.batches.map(function (b, idx) {
                                        return <p className='mb-0' key={idx}>
                                            {b.snb_amount} {item.p_KodMjSkl}
                                        </p>
                                    })}
                                </Col>
                                <Col>
                                    <span className='text-muted'>{t('ord-batch')}</span>
                                    {item.batches.map(function (b, idx) {
                                        return <p className='mb-0' key={idx}>
                                            {b.snb_batch}
                                        </p>
                                    })}
                                </Col>
                                <Col xs="auto" className='text-end'>
                                    <span className='text-muted'>{t('ord-expiry')}</span>
                                    {item.batches.map(function (b, idx) {
                                        return <p className='mb-0' key={idx}>
                                            {b.snb_bbd ? b.snb_bbd : "---"}
                                        </p>
                                    })}
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </tbody>
            </Table >

        </>
    );
}

/*
 * Filters for stockin overview in mobile version
 * 
 * @param {object} props - properties
 * @param {string} props.filterNOCode - filter value
 * @param {function} props.handleChangeNOCode - filter handle function
 * @param {string} props.filterCreator - filter value
 * @param {function} props.handleChangeCreator - filter handle function
 * @param {string} props.sortValue - state - value for user sorting 
 * @param {function} props.setSortValue - updates value for user sorting
 * @param {string} props.direction - state - sirection of sorting
 * @param {function} props.setDirection - updates the direction of sorting
 * @param {function} props.setType - type of sorting
 * @param {dictionary} props.checkedWH - multiselect state
 * @param {function} props.setCheckedWH - multiselect update function
 * @param {dictionary} props.dictionaryOfWHValues - dictionary for multiselect with true values
 * @param {dictionary} props.dictionaryOfWHValuesFalse - dictionary for multiselect with false values
 * @param {function} props.setOffset - function that updates start of pager
 * @param {dictionary} props.checkedStatus - multiselect state
 * @param {function} props.setCheckedStatus - multiselect update function
 * @param {dictionary} props.dictionaryOfStatusValues - dictionary for multiselect with true values
 * @param {dictionary} props.dictionaryOfStatusValuesFalse - dictionary for multiselect with false values
 * @param {dictionary} props.statuses_for_select
 * @param {string} props.username - logged user
 * @returns {component}
 */
export function StockinsAppFilters({ filterNOCode, handleChangeNOCode, filterCreator, handleChangeCreator, sortValue, setSortValue, direction,
    setDirection, setType, checkedWH, setCheckedWH, dictionaryOfWHValues, dictionaryOfWHValuesFalse, setOffset, checkedStatus,
    setCheckedStatus, dictionaryOfStatusValues, dictionaryOfStatusValuesFalse, statuses_for_select, username, setFilterCreator,
    filterDaysToEta, handleChangeDaysToEta, filterProblematic, setFilterProblematic }) {
    const { t } = useTranslation();
    return (
        <div className="d-lg-none mb-2">
            <Card className='m-0 p-2'>
                <Card.Body className='m-0 p-0'>
                    <Row className='mb-2'>
                        <Col>
                            <p className="mb-1">{t('ord-pur')}</p>
                            <Form.Group controlId="filterCode" className='mb-1'>
                                <Form.Control type="text" placeholder="&#128269;" value={filterNOCode} onChange={handleChangeNOCode} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <p className="mb-1">{t('ord-created_by')}</p>
                            <Form.Group controlId="filterCreator" className='mb-1'>
                                <Form.Control type="text" placeholder="&#128269;" value={filterCreator} onChange={handleChangeCreator} />
                            </Form.Group>
                            <Form.Check type="checkbox" id="filter_my_stockins"
                                onChange={filterCreator !== username ? () => setFilterCreator(username) : () => setFilterCreator("")}
                                checked={filterCreator === username}
                                label={t('my_stockins')} />
                        </Col>
                    </Row>

                    <Row className='mb-3 mt-3'>
                        <Col>
                            <p className="mb-1">{t('warehouse')}</p>
                            <MultipleSelect checked={checkedWH} setChecked={setCheckedWH}
                                dictionaryTrue={dictionaryOfWHValues} dictionaryFalse={dictionaryOfWHValuesFalse}
                                itemsNames={warehouses_for_select} setOffset={setOffset} id="filterWH" />
                        </Col>
                        <Col>
                            <p className="mb-1">{t('state')}</p>
                            <MultipleSelect checked={checkedStatus} setChecked={setCheckedStatus}
                                dictionaryTrue={dictionaryOfStatusValues} dictionaryFalse={dictionaryOfStatusValuesFalse}
                                itemsNames={statuses_for_select} setOffset={setOffset} id="filterStatus" />
                        </Col>
                        <Col>
                            <p className="mb-1">{t('problematic')}</p>
                            <BooleanDropdown variant="order-tracking" onChange={setFilterProblematic} value={filterProblematic} />
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col>
                            <Form.Group controlId="filterDaysToEta" className="mb-0 mt-2">
                                <Form.Label className="mb-1 bolder">{t('ord-days_to_or_after_eta')}</Form.Label>
                                <Form.Control className="mb-1" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterDaysToEta} onChange={handleChangeDaysToEta}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" className='pb-2'>
                            <span className='bolder'>{t('ord-last_change_time')} &nbsp;</span>
                            <SortIcons name={"sn_created_at"} sortValue={sortValue} setSortValue={setSortValue}
                                direction={direction} setDirection={setDirection} setType={setType} numeric />
                        </Col>
                        <Col>
                            <span className='bolder'>{t('ord-eta')} &nbsp;</span>
                            <SortIcons name={"sn_eta"} sortValue={sortValue} setSortValue={setSortValue}
                                direction={direction} setDirection={setDirection} setType={setType} numeric />
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
        </div>
    );
}