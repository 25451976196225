import { useMutation, useMutationState, useQueryClient } from "@tanstack/react-query";
import { createNote, hideNote, showNote } from "../api/notes";
import { useUserLogin } from "../UserLoginProvider";

export function useHideShowNote(objectType: Dto.NoteObjectType, objectCode: string, objectCode2?: string) {
  const { userInfo } = useUserLogin();
  const queryClient = useQueryClient();

  const { mutate: mutateShowNote } = useMutation({
    mutationFn: ({ noteId }: { noteId: number }) => showNote(noteId),
    onSuccess: (_, { noteId }) => {
      queryClient.setQueryData(
        !!objectCode2 ? ["notes", objectType, objectCode, objectCode2] : ["notes", objectType, objectCode],
        (data: Dto.GetObjectNotesResponseItem[]) => {
          return data?.map((note) => {
            if (note.id === noteId) {
              return { ...note, hiddenBy: null, hiddenAt: null };
            }
            return note;
          });
        }
      );
    },
  });
  const { mutate: mutateHideNote } = useMutation({
    mutationFn: ({ noteId }: { noteId: number }) => hideNote(noteId),
    onSuccess: (_, { noteId }) => {
      queryClient.setQueryData(
        !!objectCode2 ? ["notes", objectType, objectCode, objectCode2] : ["notes", objectType, objectCode],
        (data: Dto.GetObjectNotesResponseItem[]): Dto.GetObjectNotesResponseItem[] => {
          return data?.map((note) => {
            if (note.id === noteId) {
              return {
                ...note,
                hiddenBy: {
                  login: userInfo.loginName,
                  name: userInfo.username,
                  userCode: userInfo.twKodPracovnika,
                  departmentCode: userInfo.kodStrediska,
                  id: userInfo.id,
                },
                hiddenAt: new Date().toISOString(),
              };
            }
            return note;
          });
        }
      );
    },
  });

  return { mutateHideNote, mutateShowNote };
}

export function useCreateNote(
  objectType: Dto.NoteObjectType,
  communicationId: number,
  objectCode: string,
  objectCode2?: string,
  cancelForm?: () => void
) {
  const queryClient = useQueryClient();
  const { mutateAsync: createNoteMutation } = useMutation({
    mutationKey: !!objectCode2
      ? ["createNote", objectType, objectCode, objectCode2]
      : ["createNote", objectType, objectCode],
    mutationFn: ({ note }: { note: string }) =>
      createNote({
        note,
        objectCode,
        objectCode2,
        objectType,
        communicationId,
      }),
    onSuccess: (newNote) => {
      queryClient.setQueryData(
        !!objectCode2 ? ["notes", objectType, objectCode, objectCode2] : ["notes", objectType, objectCode],
        (data: Dto.GetObjectNotesResponseItem[]) => {
          return [newNote, ...data];
        }
      );
      cancelForm?.();
    },
  });

  return { createNoteMutation };
}

export function useCreateNoteRunning() {
  return useMutationState({ filters: { status: "pending", mutationKey: ["createNote"] } }).length > 0;
}
