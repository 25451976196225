/**
 * Partner debt history with graph.
 * @module partners/partner-balance-history
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import Table from 'react-bootstrap/Table';
import { Loading } from '../comp/loading';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import { his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import zoomPlugin from 'chartjs-plugin-zoom';
import { date_formatISO, date_getTomorrow, date_CzechToISO } from '../lib/date-utils';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
);


export function PartnerBalanceHistory({ partner, currency, history, loadedHistoryStatus, reloadButton }) {
	const { t } = useTranslation();

	const [isFirst, setIsFirst] = useState(true);

	useEffect(() => {
		if (isFirst) {
			setIsFirst(false);
			ChartJS.register(zoomPlugin, {
				id: "bleble",
				afterDraw: function (chart, easing) {
					if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
						const activePoint = chart.tooltip._active[0];
						const ctx = chart.ctx;
						const x = activePoint.element.x;
						const topY = chart.scales.y.top;
						const bottomY = chart.scales.y.bottom;
						ctx.save();
						ctx.beginPath();
						ctx.moveTo(x, topY);
						ctx.lineTo(x, bottomY);
						ctx.lineWidth = 1;
						ctx.strokeStyle = '#666666';
						ctx.setLineDash([10, 10]);
						ctx.stroke();
						ctx.restore();
					}
				}
			});
		}
	}, [partner, isFirst]);
	

	if (!his_fetch_success(loadedHistoryStatus)) {
		return <HisFetchStatus status={loadedHistoryStatus} loadingType="big" errorType="fetcherError" waitingType="explained" reloadButton={reloadButton} />;
	}

	if (history != null) {
		var amt1 = -1;
		var amt2 = -1;
		var amt3 = -1;
		var lhistory = history.map(function (row) {
			// Add next column to history with insurance limit (will
			// work only when limits are defined)
			return row.concat([0]);
		});
		for (var li in partner.limits) {
			const pl = partner.limits[li];
			//console.log('pl: ' +pl);
			if (pl[3]) {
				const limit = pl[6];
				const fday = date_CzechToISO(pl[3]);
				const tday = pl[10] === null ?
					date_formatISO(new Date()) :
					date_CzechToISO(pl[10]);
				// console.log('limit: ' + limit);
				// console.log('fday: ' + fday);
				// console.log('tday: ' + tday);
				// Start at fday, till tday or today whichever comes first
				var cday = fday;
				// find index of fday, increment until tday
				var cindex = 0;
				const lhistory_length = lhistory.length;
				while ((cindex < lhistory_length) && (lhistory[cindex][0] < cday)) {
					cindex += 1;
				}
				// console.log('cindex = ' + cindex + ' :: total = ' + lhistory.length + ' :: cday = ' + cday + ' :: tday = ' + tday);
				// console.log('cday <= tday = ' + (cday <= tday));
				while ((cday <= tday) && (cindex < lhistory_length)) {
					//console.log('day');
					lhistory[cindex][4] = limit;
					cday = date_formatISO(date_getTomorrow(cday));
					cindex += 1;
				}
			}
		}
		const fhistory = lhistory.filter(function (row) {
			//console.log(row);
			if ((amt1 === row[1]) && (amt2 === row[2]) && (amt3 === row[4])) {
				return false;
			}
			amt1 = row[1];
			amt2 = row[2];
			amt3 = row[4];
			return true;
		});

		const labels = lhistory.map(
			function (row) {
				return date_formatCZ(row[0]);
			});
		const beforedata = lhistory.map(
			function (row) {
				return row[1];
			});
		const afterdata = lhistory.map(
			function (row) {
				return row[2];
			});
		const insureddata = lhistory.map(
			function (row) {
				return row[3];
			});
		const totaldata = lhistory.map(
			function (row) {
				return row[1] + row[2];
			});
		const limitdata = lhistory.map(
			function (row) {
				return row[4];
			});
		var tooltipDatasetsState = {};
		const options = {
			hover: {
				axis: 'x',
				mode: 'index',
				intersect: false
			},
			plugins: {

				zoom: {
					pan: {
						enabled: true,
						mode: 'x',
					},
					zoom: {
						wheel: {
							enabled: true,
						},
						pinch: {
							enabled: true
						},
						mode: 'x',
					}
				},
				
				tooltip: {
					mode: 'x',
					intersect: false,
					filter: function (el) {
						const dsi = el.datasetIndex;
						if (tooltipDatasetsState[dsi]) {
							return false;
						} else {
							tooltipDatasetsState[dsi] = true;
							return true;
						}
					},
					callbacks: {
						label: function (context) {
							tooltipDatasetsState = {};
							var label = context.dataset.label.substr(2) || '';
							// console.log(tooltipItem.datasetIndex);	
							//var label = data.datasets[tooltipItem.datasetIndex].label.substr(2) || '';     //funkce co přesně vrací znak po znaku v tom hoveru 
							if (label) {
								label += ': ';
							}
							label += format_amount(context.parsed.y);
							return label;
						}
					},
					bodyAlign: 'right'
				}
			},
			scales: {
				y: {
					scaleLabel: {
						display: true,
						labelString: "Saldo [" + currency + "]",
						fontSize: 16
					},
					ticks: {
						beginAtZero: true
					}
				},
				x: {
					scaleLabel: {
						display: true,
						labelString: "Datum",
						fontSize: 16
					}
				}
			}
		};

		const data = {
			labels: labels,
			datasets: [
				{
					label: "☑ " + (t('biz-ins_balance')),
					data: insureddata,
					borderJoinStyle: 'miter',
					borderColor: '#28a745',
					backgroundColor: 'rgba(40, 167, 69, 0.1)',
					pointRadius: 0,
					fill: true
				},
				{
					label: "☑ " + (t('biz-before_due_balance')),
					data: beforedata,
					borderJoinStyle: 'miter',
					borderColor: 'rgb(23, 162, 184)',
					backgroundColor: 'rgba(23, 162, 184, 0.01)',
					pointRadius: 0,
					fill: true
				}, {
					label: "☑ " + (t('biz-after_due_balance')),
					data: afterdata,
					borderJoinStyle: 'miter',
					borderColor: '#f1433f',
					backgroundColor: 'rgba(236,120,118, 0.6)',
					pointRadius: 0,
					fill: true
				},
				{
					label: "☑ " + (t('biz-total_balance2')),
					data: totaldata,
					borderJoinStyle: 'miter',
					borderColor: 'rgba(128,128,128,0.5)',
					backgroundColor: 'rgba(225, 225, 225, 0.25)',
					pointRadius: 0,
					fill: true
				}, {
					label: "☑ " + (t('biz-valid_limit')),
					data: limitdata,
					borderJoinStyle: 'miter',
					borderColor: 'rgb(255, 191, 0)',
					backgroundColor: 'rgba(204, 217, 46, 0.01)',
					pointRadius: 0,
					fill: true
				}
			]
		};
		return (
			<ErrorWrap>
				<Row className="g-0">
					<Col xs="12" xl="10"><Line data={data} options={options} /></Col>
					<Col xs="12" xl="2">
						<Card style={{ marginTop: "32px" }}>
							<Card.Body style={{ padding: "25px" }}>
								<h5>{t('biz-did_y_know')}</h5>
								<ul style={{ paddingLeft: "1px", lineHeight: "170%" }}>
									<li>{t('if')} <span className="graphGrey">{t('biz-total_balance2')}</span> {t('biz-is_up')} <span className="graphYellow">{t('biz-valid_limit')}</span> – {t('biz-uninsured_balance')} </li>
									<li>{t('if')} <span className="graphGrey">{t('biz-total_balance2')}</span> {t('biz-is_up')} <span className="graphGreen">{t('biz-ins_balance')}</span>, {t('biz-but_inst_above')} <span className="graphYellow">{t('biz-valid_limit')}</span> – {t('biz-E1_unfullfiled')} </li>
									<li><span className="graphRed">{t('biz-after_due_balance')}</span> {t('biz-means_we_lend_money_free')} </li>
								</ul>
							</Card.Body>
							<Card.Footer className="text-muted">
								<Badge pill bg="secondary">i</Badge>&nbsp;&nbsp;{t('biz-tip1')}<br />
								<Badge pill bg="secondary">i</Badge>&nbsp;&nbsp;{t('biz-tip2')}
							</Card.Footer>
						</Card></Col>
				</Row>
				<Table size="sm" className='mt-3'>
					<thead className="beGray">
						<tr>
							<th scope="col" rowSpan="2" className="text-center">{t('date')}</th>
							<th colSpan="4" className="text-center">{t('biz-balance')}</th>
						</tr>
						<tr>
							<th scope="col" className="text-center">
								{t('before')}&nbsp;
								<span className="d-lg-none">
									{t('biz-due_short')}
								</span>
								<span className="d-none d-lg-inline">
									{t('biz-due(6p_sm)')}
								</span>
							</th>
							<th scope="col" className="text-center">
								{t('after')}&nbsp;
								<span className="d-lg-none">
									{t('biz-due_short')}
								</span>
								<span className="d-none d-lg-inline">
									{t('biz-due(2p_sm)')}
								</span>
							</th>
							<th scope="col" className="text-center">{t('biz-insured')}</th>
							<th scope="col" className="text-center">{t('total')}</th>
						</tr>
					</thead>
					<tbody>
						{
							fhistory.map(
								function (row) {
									return (
										<tr key={row[0]}>
											<td className="text-center">{date_formatCZ(row[0])}</td>
											<td className="text-center">{format_amount(row[1])}</td>
											<td className="text-center">{format_amount(row[2])}</td>
											<td className="text-center">{format_amount(row[3])}</td>
											<td className="text-center">{format_amount(row[1] + row[2])}</td>
										</tr>
									);
								}
							)
						}
					</tbody>
				</Table>
			</ErrorWrap>
		);
	} else {
		return <Loading />;
	}
}