/**
 * list of ftp files received and sent.
 * @module ftp
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useCallback } from 'react';
import { his_fetch } from './comp/FetchLoader';
import Table from 'react-bootstrap/Table';
import { Downloader } from './comp/downloader';
import { date_time_format } from './lib/date-utils';
import { filter_rule, icompare } from './lib/utils';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { ErrorWrap } from './comp/errorwrap';
import { MultipleSelect } from './comp/multiple-select';
import { DynamicLoad, LoadingDataInfo } from './comp/dynamic-load';
import { warehouseTitlesDropdown, warehouses_names_ids_separe } from './lists/warehouses-defs';
import { useTranslation } from 'react-i18next';

//seen all - OK

export const titles = {
	"": "all",
	true: "yes",
	false: "no",
	null: "–"
}

const ftp_results = {
	null: "-",
	true: "✔️",
	false: "❌",
}

export function FTPReceived2({ userlogin }) {
	const { t } = useTranslation();

	const [loadedStatus, setLoadedStatus] = useState(0);
	const [ftpRec, setFtpRec] = useState([]);

	const action_names = ftpRec.map((f) => f.event_trigger)
		.filter((v) => v !== null)
		.filter((v, i, a) => a.indexOf(v) === i)
		.concat([t('no_name')]);  //u imperativních kroků je lepší concat než push

	//console.log(action_names); 

	const dict = action_names.sort().reduce((acc, v) => ({ ...acc, [v]: v }), {});

	const dictionaryOfActionValues = Object.keys(dict).reduce((acc, v) => ({ ...acc, [v]: true }), {});
	const dictionaryOfActionValuesFalse = Object.keys(dict).reduce((acc, v) => ({ ...acc, [v]: false }), {});

	const [totalCount, setTotalCount] = useState(null);
	const [oldest, setOldest] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterName, setFilterName] = useState("");
	const [filterSize, setFilterSize] = useState("");
	const [filterResult, setFilterResult] = useState("");
	const [checkedAction, setCheckedAction] = useState(null);
	//console.log(filterResult);

	const realCheckedAction = checkedAction === null ? dictionaryOfActionValues : checkedAction;


	//useCallback, aby se neměnila hodnota té funkce při každém přerenderování, pokud se nezmění něco v dependeciech toho useCallbacku
	const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
		//console.log(startDate, endDate);
		return (
			his_fetch( //his fetch spustí asynchroní operaci, tak musím mít funkci, která to zruší
				userlogin,
				[
					{
						uri: "/api/ftp/get/received2/range/" + startDate + "/" + endDate,
						json: true,
						status: setLoadedStatus, //nedostane se stejně do 2, když mi Adam vrací result s errorem, jedině ho potřebuji v LoadedDataInfo
						ok: function (resource, result) {
							//console.log(result);
							if (result.error) {
								result_received(false); //toto je neoptimální a čeká nás rethinking errorhandlingu
							} else {
								setFtpRec(ftpRecKocicka => (reload ? [] : ftpRecKocicka).concat(result.result)); //react functional update - aby se nemusely předávat hodnoty 
								/*na této řádce používám aktuální hodnot ftpRec, ...setFtpRec dostane jako argument ES6 funkci, 
								dynamicLoad očekává, že zavolá něco a to mu načte data, result_received - dynamicLoad se chce dovědět, že to klaplo a je to načtený
								potom, co skončí asynchní funkce, co his_fetch udělalt, tak 
								jj
								*/
								setTotalCount(result.total_count);
								setOldest(result.oldest.received_at);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin]) //tu asi bude setFrpRec

	const cleanFilters = () => {
		setFilterName("");
		setFilterSize("");
		setFilterResult("");
		setCheckedAction(null);
	}

	const handleChangeName = function (event) {
		setFilterName(event.target.value);
		setOffset(0);
	}

	const handleChangeSize = function (event) {
		setFilterSize(event.target.value);
		setOffset(0);
	}

	const handleChangeResult = function (val) {
		setFilterResult(val);
		setOffset(0);
	}

	const unique_ids = ftpRec.map((m) => m.id)
		.filter((v, i, a) => a.indexOf(v) === i);

	const ftpReceivedTree = unique_ids.reduce(function (acc, file_id) {
		return ({
			...acc,
			[file_id]: ftpRec.filter((rec) => rec.id === file_id),
		})
	}, {});

	//console.log(ftpSentTree);

	const preprocessed_data = unique_ids.map(function (file_id) {
		const one_event = ftpReceivedTree[file_id][0];
		return ({
			id: file_id,
			file_name: one_event.file_name,
			received_at: one_event.received_at,
			data_size: one_event.data_size,
			events: ftpReceivedTree[file_id]
		})
	});

	//console.log(preprocessed_data);

	const ftpRec_filtered = preprocessed_data.filter(
		function (ftp) {
			return (

				filter_rule(filterName, ftp.file_name, true) &&
				icompare(ftp.data_size, filterSize) &&
				(
					(realCheckedAction[t('no_name')] && ftp.events.reduce((res, event) => res || event.event_trigger === null, false)) ||
					ftp.events.reduce((res, event) => res || realCheckedAction[event.event_trigger], false)
				) &&

				((filterResult === "") ||
					(ftp.events.reduce((res, event) => res || (filterResult === event.event_success), false))
				)

			);
		}
	)

	const list_ftpRec = ftpRec_filtered.slice(offset, offset + 20);

	return (
		<ErrorWrap>
			<DynamicLoad period='1m' loadData={loadData}
				filtered_data={ftpRec_filtered} data={preprocessed_data}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount} oldest={oldest}
				cleanFilters={cleanFilters}
				loadedCount={ftpRec.length}
			//  lastNormal
			/>

			<Table size="sm">
				<thead className="beGray">
					<tr>
						<th rowSpan="2" className="text-center align-middle" >
							<Form.Label className="mb-1">{t('sys-filename')}</Form.Label>
							<Form.Group controlId="filterName">
								<Form.Control type="text" placeholder={"🔍 "} value={filterName} onChange={handleChangeName} />
							</Form.Group>
						</th>
						<th rowSpan="2" className="text-end align-middle pe-5">{t('sys-accepted')}</th>
						<th className='text-center align-middle' rowSpan="2">
							<Form.Group controlId="filterSize">
								<Form.Label className="mb-1">{t('sys-size')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterSize} onChange={handleChangeSize}
								/>
							</Form.Group>
						</th>
						<th colSpan={6} className="text-center">{t('action')}</th>

					</tr>
					<tr>
						<th className="text-center ps-3">
							<p className="mb-1">{t('name')}</p>

							<MultipleSelect checked={realCheckedAction} setChecked={setCheckedAction}
								dictionaryTrue={dictionaryOfActionValues} dictionaryFalse={dictionaryOfActionValuesFalse}
								itemsNames={dict} setOffset={setOffset} id="filterAction" withoutNumber />

						</th>
						<th className="text-center pb-2">
							<p className="mb-1">{t('rep-result')}</p>

							<DropdownButton id="filterResult" title={t(titles[filterResult])} variant="light">
								<Dropdown.Item onClick={() => handleChangeResult("")}>{t('all')}</Dropdown.Item>
								<Dropdown.Item onClick={() => handleChangeResult(true)}>{t('yes')} ✔️</Dropdown.Item>
								<Dropdown.Item onClick={() => handleChangeResult(false)}>{t('no')} ❌</Dropdown.Item>
								<Dropdown.Item onClick={() => handleChangeResult(null)}>&ndash;</Dropdown.Item>
							</DropdownButton>

						</th>
						<th className="text-end ps-1 pb-2">{t('sys-created')}</th>
						<th className="text-end pb-2">{t('sys-triggered')}</th>
						<th className="text-end pb-2">{t('sys-finished')}</th>
					</tr>
				</thead>
				<tbody>
					{
						list_ftpRec.map(function (f, idx) {
							const bg = (idx % 2) === 0 ? "" : "beGray3";
							return f.events.map(function (event, idxEvent) {
								const myRowSpan = f.events.length;
								return (
									<tr key={idxEvent} className={bg}>
										{idxEvent === 0 ?
											<>
												<td rowSpan={myRowSpan} className="text-center align-middle">{event.file_name}&nbsp;  <Downloader img="/download.svg" height="20" alt="pdf" label={""}
													path={"/api/ftp/get/received/" + event.id}
													filename={f.file_name}
												/>
												</td>
												<td rowSpan={myRowSpan} className="text-end align-middle">{date_time_format(event.received_at)}</td>
												<td rowSpan={myRowSpan} className='text-center align-middle'>{event.data_size} B</td>
											</>
											: <></>
										}
										<td className="text-center">{event.event_trigger || "-"}</td>
										<td className="text-center">{ftp_results[event.event_success]}</td>
										<td className="text-end">{event.event_created ? date_time_format(event.event_created) : "-"}</td>
										<td className="text-end">{event.event_started ? date_time_format(event.event_started) : "-"}</td>
										<td className="text-end">{event.event_finished ? date_time_format(event.event_finished) : "-"}</td>
									</tr>
								);
							})
						})}
				</tbody>
			</Table>
			<LoadingDataInfo loadedStatus={loadedStatus} data={ftpRec} />
		</ErrorWrap>
	);
}


export function FTPSent({ userlogin }) {
	const { t } = useTranslation();

	const [loadedStatus, setLoadedStatus] = useState(0);
	const [ftpSent, setFtpSent] = useState([]);
	const [totalCount, setTotalCount] = useState(null);
	const [oldest, setOldest] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterName, setFilterName] = useState("");
	const [filterID, setFilterID] = useState("");
	const [filterSize, setFilterSize] = useState("");
	const [filterSender, setFilterSender] = useState("");

	const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
		return (
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/ftp/get/sent/range/" + startDate + "/" + endDate,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							if (result.error) {
								result_received(false);
							} else {
								setFtpSent(ftpSentCat => (reload ? [] : ftpSentCat).concat(result.result));
								setTotalCount(result.total_count);
								setOldest(result.oldest.created);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin])

	const cleanFilters = () => {
		setFilterName("");
		setFilterID("");
		setFilterSize("");
		setFilterSender("");
	}


	const handleChangeSender = function (event) {
		setFilterSender(event.target.value);
		setOffset(0);
	}

	const handleChangeName = function (event) {
		setFilterName(event.target.value);
		setOffset(0);
	}

	const handleChangeWarehouse = function (val) {
		setFilterID(val);
		setOffset(0);
	}

	const handleChangeSize = function (event) {
		setFilterSize(event.target.value);
		setOffset(0);
	}

	const unique_ids = ftpSent.map((m) => m.id)
		.filter((v, i, a) => a.indexOf(v) === i);

	const ftpSentTree = unique_ids.reduce(function (acc, file_id) {
		return ({
			...acc,
			[file_id]: ftpSent.filter((rec) => rec.id === file_id),
		})
	}, {});

	//console.log(ftpSentTree);

	const preprocessed_data = unique_ids.map(function (file_id) {
		const one_event = ftpSentTree[file_id][0];
		return ({
			id: file_id,
			file_name: one_event.file_name,
			created: one_event.created,
			collected_at: one_event.collected_at,
			data_size: one_event.data_size,
			jmeno: one_event.jmeno,
			username: one_event.username,
			user_id: one_event.user_id,
			warehouse_id: one_event.warehouse_id,
			events: ftpSentTree[file_id]
		})
	});

	//console.log(preprocessed_data);

	const ftpSent_filtered = preprocessed_data.filter(
		function (ftp) {
			//console.log(ftp.warehouse_id);
			return (
				filter_rule(filterName, ftp.file_name, true) &&
				(filter_rule(filterSender, ftp.username, true) || filter_rule(filterSender, ftp.jmeno, true)) &&
				((filterID === "") || (String(ftp.warehouse_id) === filterID)) &&
				icompare(ftp.data_size, filterSize)
			)
		}
	)

	const list_ftpSent = ftpSent_filtered.slice(offset, offset + 20);

	return (
		<ErrorWrap>
			<DynamicLoad period='1m' loadData={loadData}
				filtered_data={ftpSent_filtered} data={preprocessed_data}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount} oldest={oldest}
				cleanFilters={cleanFilters}
				loadedCount={ftpSent.length}
			// lastNormal 
			/>

			<Table size="sm">
				<thead className="beGray">
					<tr>
						{/* <th rowSpan={2}>id</th> */}
						<th rowSpan={2} className="text-center pb-2">
							<Form.Label className="mb-1">{t('sys-from')}</Form.Label>
							<Form.Group controlId="filterSender">
								<Form.Control type="text" placeholder={"🔍 "} value={filterSender} onChange={handleChangeSender} />
							</Form.Group>
						</th>
						<th rowSpan={2} className="text-center pb-2">
							<Form.Label className="mb-1">{t('sys-filename')}</Form.Label>
							<Form.Group controlId="filterName">
								<Form.Control type="text" placeholder={"🔍 "} value={filterName} onChange={handleChangeName} />
							</Form.Group>
						</th>
						<th rowSpan={2} className="text-center pb-2 pe-4">{t('sys-created')}</th>
						<th rowSpan={2} className="text-center pb-2 pe-4">{t('picked')}</th>
						<th rowSpan={2} className='pb-2'>
							<Form.Group controlId="filterSize" className="mb-0">
								<Form.Label className="mb-1">{t('sys-size')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterSize} onChange={handleChangeSize}
								/>
							</Form.Group>
						</th>

						<th rowSpan={2} className="text-center pb-2">
							<p className="mb-1">{t('sys-warehouse_ID')}</p>
							<DropdownButton id="filterID" title={warehouseTitlesDropdown[filterID] || t('all')} variant="light">
								<Dropdown.Item onClick={() => handleChangeWarehouse("")} >{t('all')}</Dropdown.Item>
								{Object.keys(warehouseTitlesDropdown)
									.map((wh, idx) =>
										<Dropdown.Item key={idx} onClick={() => handleChangeWarehouse(wh)} >{warehouseTitlesDropdown[wh]}</Dropdown.Item>
									)}
							</DropdownButton>
						</th>
						<th colSpan={6} className="text-center">{t('sys-file_action')}</th>
					</tr>
					<tr>
						<th className='pb-2'>{t('name')}</th>
						<th className='pb-2'>{t('rep-result')}</th>
						<th className='pb-2'>{t('sys-created')}</th>
						<th className='pb-2'>{t('sys-triggered')}</th>
						<th className='pb-2'>{t('sys-finished')}</th>
						<th className='pb-2'>{t('sys-triggerer')}</th>
					</tr>
				</thead>
				<tbody>
					{list_ftpSent.map(function (fs, idx) {
						const bg = (idx % 2) === 0 ? "" : "beGray3";
						return fs.events.map(function (event, idxEvent) {
							const myRowSpan = fs.events.length;
							return (
								<tr key={idxEvent} className={bg}>
									{idxEvent === 0 ?
										<>
											{/* <td rowSpan={myRowSpan} className=" align-middle">{fs.id}</td> */}
											<td rowSpan={myRowSpan} className=" align-middle">
												&nbsp;&nbsp;{fs.jmeno || <>&mdash;</>}&nbsp;
												({fs.username || <>&mdash;</>})
											</td>
											<td rowSpan={myRowSpan} className="text-center align-middle">{fs.file_name} &nbsp;
												<Downloader img="/download.svg" height="20" alt="pdf" label={""}
													path={"/api/ftp/get/sent/" + fs.id}
													filename={fs.file_name}
												/></td>
											<td rowSpan={myRowSpan} className="text-end align-middle">{date_time_format(fs.created)}&nbsp;&nbsp;&nbsp;</td>
											<td rowSpan={myRowSpan} className="text-end align-middle">{date_time_format(fs.collected_at)}&nbsp;&nbsp;&nbsp;</td>
											<td rowSpan={myRowSpan} className="text-center align-middle">{fs.data_size} B</td>
											<td rowSpan={myRowSpan} className="text-center align-middle">{warehouses_names_ids_separe[fs.warehouse_id]}</td>

										</>
										: <></>}
									<td>{event.event_name ? event.event_name : "-"}</td>
									<td className="text-center" >{event.event_success === true ? "✔️" : event.event_success === false ? "❌" : "-"}</td>
									<td>{event.event_created ? date_time_format(event.event_created) : "-"}</td>
									<td>{event.event_started ? date_time_format(event.event_started) : "-"}</td>
									<td>{event.event_finished ? date_time_format(event.event_finished) : "-"}</td>
									<td>{event.event_trigger ? event.event_trigger : "-"}</td>
								</tr>
							);
						});
					})}
				</tbody>
			</Table>
			<LoadingDataInfo loadedStatus={loadedStatus} data={ftpSent} />
		</ErrorWrap>
	);
}