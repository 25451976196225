/**
 * Global overview of outstanding debts.
 * @module partners/debts
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Badge, Card, Row, Col, Accordion, ListGroup, Dropdown, DropdownButton } from 'react-bootstrap';
// eslint-disable-next-line
import { format_amount } from '../lib/format';
import { delay_color, worst_color } from '../lists/colors';
import { LinkContainer } from 'react-router-bootstrap';
import notes_comms from '../notes/notes-comms.json';
import credendo_autolimit from '../lists/credendo-autolimit.json';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import { filter_rule, icompare } from '../lib/utils';
import { BooleanDropdown } from '../comp/boolean';
import { his_fetch, HisFetchStatus, his_fetch_success } from '../comp/FetchLoader';
import { PartnerSaldo2 } from './partner-panels';
import { DelayOrHold, NoteIcon, NoteWithIcon, PlainNoteWithIcon } from './partner-history-utils';
import { ErrorWrap } from '../comp/errorwrap';
import { Pager } from '../comp/pager';
import { ItemsAndFiltered, LoadingDataInfo } from '../comp/dynamic-load';
import { Downloader } from '../comp/downloader';
import { date_formatISO, date_formatCZ } from '../lib/date-utils';
import { insuranceTitles } from '../lists/insurance_titles';
import { useTranslation } from 'react-i18next';
import { insurance_badges } from '../lists/insurance-badges';

//seen all - OK

export function Debts({ userinfo, userlogin }) {
	const { t } = useTranslation();

	var defaultcode = '';
	if (userinfo) {
		defaultcode = (userinfo.KodStrediska || "");
	}

	const [debts, setDebts] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterCode, setFilterCode] = useState(defaultcode);
	const [filterPartner, setFilterPartner] = useState("");
	const [filterSaldo, setFilterSaldo] = useState("");
	const [currency, setCurrency] = useState("---");
	const [saldoType, setSaldoType] = useState(0); // 0: accounting, 1: invoices
	const [filterInsurance, setFilterInsurance] = useState("");
	const [filterInvoice, setFilterInvoice] = useState("");
	const [filterNote1, setFilterNote1] = useState("");
	const [filterNote2, setFilterNote2] = useState("");
	const [filterNote3, setFilterNote3] = useState("");
	const [filterPartly, setFilterPartly] = useState(null);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [massFilterApp, setMassFilterApp] = useState("");

	const reloadIt = () => {
		setDebts(null);
		setLoadedStatus(0);
	}

	const cleanFilters = () => {
		setFilterCode("");
		setFilterPartner("");
		setFilterSaldo("");
		setCurrency("---");
		setSaldoType(0);
		setFilterInvoice("");
		setFilterInsurance("");
		setFilterNote1("");
		setFilterNote2("");
		setFilterNote3("");
		setFilterPartly(null);
		setMassFilterApp("");
	}


	useEffect(() => {
		if (debts === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/debts-new",
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							setDebts(result.debts);
							setCurrency(result.currency);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setDebts("error");
						}
					}
				]
			);
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, debts]);

	const handleChangeCode = (e) => {
		setFilterCode(e.target.value.toLowerCase());
	}
	const handleChangePartner = (e) => {
		setFilterPartner(e.target.value.toLowerCase());
	}
	const handleChangeSaldo = (e) => {
		setFilterSaldo(e.target.value);
	}
	const handleSaldoTypeChange = (newtype) => {
		setSaldoType(newtype);
	}
	const handleSaldoTypeChange0 = () => {
		handleSaldoTypeChange(0);
	}
	const handleSaldoTypeChange1 = () => {
		handleSaldoTypeChange(1);
	}
	const handleChangeNote1 = (e) => {
		setFilterNote1(e.target.value);
	}
	const handleChangeNote2 = (e) => {
		setFilterNote2(e.target.value);
	}
	const handleChangeNote3 = (e) => {
		setFilterNote3(e.target.value);
	}
	const handleChangeInvoice = (e) => {
		setFilterInvoice(e.target.value);
	}
	const handleChangeInsurance = (val) => {
		setFilterInsurance(val);
	}
	const handleChangePartly = (val) => {
		setFilterPartly(val);
	}
	const handleChangeAppFilter = (e) => {
		setMassFilterApp(e.target.value.toLowerCase());
	}

	const usable_debts = (debts !== null && debts !== "error") ? debts : [];
	//console.log(usable_debts);

	const debts_preprocessing = usable_debts.map(function (debt) {
		const auto_country_ok = credendo_autolimit.includes(debt.KodStatu ? debt.KodStatu.trim().toUpperCase() : debt.KodStatu);
		const datDokladu = new Date(debt.DatDokladu);
		const datSplatnosti = new Date(debt.DatSplatnosti);
		const dueDateDays = (datSplatnosti - datDokladu) / 1000 / 60 / 60 / 24;
		const stredisko = (debt.KodStrediska === null) ? "(" + debt.strediska + ")" : debt.KodStrediska;
		return {
			...debt,
			auto_country_ok: auto_country_ok,
			dueDateDays: dueDateDays,
			stredisko: stredisko,
		};
	});

	//console.log(debts_preprocessing);


	const debts_filtered = debts_preprocessing.filter(
		function (debt) {
			// console.log(''+[filterPartly,debt[2]]);
			return (((saldoType === 0) ||
				(debt.saldo2 > 0)) &&
				((filter_rule(filterCode, debt.KodStrediska)) && // středisko
					(filter_rule(filterCode, debt.strediska)) && // možná seznam středisek
					(filter_rule(filterPartner, debt.NazevPartnera)) &&
					icompare(debt.saldo, filterSaldo) &&
					((filterInsurance === "") ||
						(get_insurance_status(debt.fic, debt.pojisteno, debt.insured, debt.auto_country_ok) === filterInsurance)) &&
					(filter_rule(filterNote1, debt.poznamka)) &&
					(filter_rule(filterInvoice, debt.KodDokladu)) &&
					(filter_rule(filterNote2, debt.note2, true)) &&
					(filter_rule(filterNote3, debt.note, true)) &&
					((filterPartly === null) ||
						((filterPartly === true) && (debt.uhrada !== null)) ||
						((filterPartly === false) && (debt.uhrada === null))) &&

					((massFilterApp.length === 0) || ((debt.KodDokladu.toLowerCase().search(massFilterApp) >= 0) ||
						((debt.NazevPartnera || "").toLowerCase().search(massFilterApp) >= 0) ||
						((debt.poznamka || "").toLowerCase().search(massFilterApp) >= 0) ||
						((debt.note2 || "").toLowerCase().search(massFilterApp) >= 0) ||
						((debt.note || "").toLowerCase().search(massFilterApp) >= 0)))
				));
		});

	const show_debts = debts_filtered.slice(offset, offset + 20);

	var saldo = 0;
	var lsaldo = 0;
	var i;
	var currmap = {};
	var totalcurr = {};
	var latecurr = {};
	for (i = 0; i < debts_filtered.length; i++) {
		saldo += debts_filtered[i].saldo;
		if (debts_filtered[i].zpozdeni2 > 0) {
			lsaldo += debts_filtered[i].saldo;
		}
		currmap[debts_filtered[i].KodMeny] = true;
	}
	var currary = [];
	var ckey;
	for (ckey in currmap) {
		currary.push(ckey);
		totalcurr[ckey] = 0;
		latecurr[ckey] = 0;
	}
	for (i = 0; i < debts_filtered.length; i++) {
		totalcurr[debts_filtered[i].KodMeny] += debts_filtered[i].saldo2;
		if (debts_filtered[i].zpozdeni2 > 0) {
			latecurr[debts_filtered[i].KodMeny] += debts_filtered[i].saldo2;
		}
	}
	currary = currary.sort();
	var lstyle = {};
	if (lsaldo > 0) {
		lstyle = {
			backgroundColor: worst_color(),
			fontWeight: "bold"
		};
	}
	const rstyle = {
		cursor: "pointer"
	};
	const classSaldo0 = saldoType === 0 ? "primary" : "light";
	const classSaldo1 = saldoType === 1 ? "primary" : "light";
	const classGray1 = saldoType === 0 ? "" : "beGray";
	const classGray2 = saldoType === 1 ? "" : "beGray";
	var cidx = 0;
	return (
		<ErrorWrap>
			<Row>
				<Col>
					<h5>{t('biz-debts')}</h5>
					<div className='mt-4 mb-3'>
						<Downloader img="/application-excel.svg" alt="xlsx" height="25"
							label={t('biz-debts_to_date') + " " + date_formatCZ(new Date())}
							path={"/api/debts-new/xlsx"}
							filename={"pohledavky-k-" + date_formatISO(new Date()) + ".xlsx"}
						/>
					</div>
				</Col>
				<Col className='text-center'>
					<ItemsAndFiltered filtered_data={debts_filtered} data={usable_debts} cleanFilters={cleanFilters} />
				</Col>
				<Col className='text-end'>
					<Pager offset={offset} pagesize={20} total={debts_filtered.length} callback={setOffset} />
					<br />
					<br />
					<Button className={"m-1 " + classGray1} variant={classSaldo0} onClick={handleSaldoTypeChange0} style={{ width: "150px" }}>{t('biz-acc_balance')}</Button>
					<Button className={"m-1 " + classGray2} variant={classSaldo1} onClick={handleSaldoTypeChange1} style={{ width: "150px" }}>{t('biz-inv_balance')}</Button>
				</Col>
			</Row>
			<DebtsListWeb
				debts={show_debts}
				fullDebts={usable_debts}
				currency={currency}
				filterCode={filterCode}
				handleChangeCode={handleChangeCode}
				handleChangePartner={handleChangePartner}
				filterPartner={filterPartner}
				handleChangeNote2={handleChangeNote2}
				filterNote2={filterNote2}
				handleChangeInvoice={handleChangeInvoice}
				filterInvoice={filterInvoice}
				handleChangeNote3={handleChangeNote3}
				filterNote3={filterNote3}
				handleChangePartly={handleChangePartly}
				filterPartly={filterPartly}
				handleChangeSaldo={handleChangeSaldo}
				filterSaldo={filterSaldo}
				handleChangeNote1={handleChangeNote1}
				filterNote1={filterNote1}
				filterInsurance={filterInsurance}
				handleChangeInsurance={handleChangeInsurance}
				insuranceTitles={insuranceTitles}
				rstyle={rstyle}
				saldo={saldo}
				currary={currary}
				cidx={cidx}
				totalcurr={totalcurr}
				lstyle={lstyle}
				lsaldo={lsaldo}
				latecurr={latecurr}
				loadedStatus={loadedStatus}
			/>

			<DebtsListApp
				debts={show_debts}
				currency={currency}
				handleChangeAppFilter={handleChangeAppFilter}
				filterCode={filterCode}
				handleChangeCode={handleChangeCode}
				handleChangePartly={handleChangePartly}
				filterPartly={filterPartly}
				handleChangeSaldo={handleChangeSaldo}
				filterSaldo={filterSaldo}
				filterInsurance={filterInsurance}
				handleChangeInsurance={handleChangeInsurance}
				insuranceTitles={insuranceTitles}
				saldo={saldo}
				currary={currary}
				totalcurr={totalcurr}
				lstyle={lstyle}
				lsaldo={lsaldo}
				latecurr={latecurr}
				massFilterApp={massFilterApp}
			/>

			{!his_fetch_success(loadedStatus) ?
				<HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
				: <></>
			}

		</ErrorWrap>
	);

}

export function DebtsListWeb({ filterCode, handleChangeCode, handleChangePartner, handleChangeNote2, handleChangeInvoice, handleChangeNote3,
	handleChangePartly, filterPartly, handleChangeSaldo, handleChangeNote1, filterInsurance, handleChangeInsurance, debts,
	insuranceTitles, rstyle, saldo, currary, cidx, totalcurr, lstyle, lsaldo, latecurr, filterSaldo, filterPartner, filterNote1,
	filterNote2, filterNote3, filterInvoice, loadedStatus, fullDebts, currency }) {
	const { t } = useTranslation();
	return (
		<>
			<Table hover size="sm" striped className="d-none d-lg-table">
				<thead className="beGray">
					<tr>
						<th rowSpan="2" scope="col" className="text-center align-bottom pb-3">
							<Form.Group controlId="filterCode">
								<Form.Label>{t('biz-centre')}</Form.Label>
								<Form.Control type="text" placeholder="&#128269;" value={filterCode} onChange={handleChangeCode} />
							</Form.Group>
						</th>
						<th rowSpan="2" scope="col" className="text-center pb-3" style={{ width: "16%", paddingTop: "20px" }}>
							<Form.Group controlId="filterName" >
								<Form.Control type="text" placeholder={"🔍 " + t('partner')} value={filterPartner} onChange={handleChangePartner} />
							</Form.Group>
							<Form.Group controlId="filterNote2">
								<Form.Control type="text" placeholder={"🔍 " + t('note')} value={filterNote2} onChange={handleChangeNote2} />
							</Form.Group>
						</th>
						<th rowSpan="2" scope="col" className="text-center pb-3" style={{ width: "16%", paddingTop: "20px" }}>
							<Form.Group controlId="filterInvoice">
								<Form.Control type="text" placeholder={"🔍 " + t('doc_code')} value={filterInvoice} onChange={handleChangeInvoice} />
							</Form.Group>
							<Form.Group controlId="filterNote3">
								<Form.Control type="text" placeholder={"🔍 " + t('note')} value={filterNote3} onChange={handleChangeNote3} />
							</Form.Group>
						</th>

						<th rowSpan="2" scope="col" className="text-center pb-3" style={{ paddingBottom: "20px" }}>
							<p className="mb-1">{t('biz-partial_pay')}</p>
							<BooleanDropdown onChange={handleChangePartly} value={filterPartly} />
						</th>

						<th rowSpan="2" scope="colgroup" className="text-center pb-3">
							<Form.Group controlId="filterSaldo" className="mb-0">
								<Form.Label className="mb-1">{t('biz-acc_balance')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterSaldo} onChange={handleChangeSaldo} />
							</Form.Group>
						</th>
						<th rowSpan="2" scope="col" className="text-center align-bottom pb-4">{t('biz-pay_late')}</th>
						<th rowSpan="2" scope="col" className="text-center align-bottom pb-3">
							<Form.Group controlId="filterNote">
								<Form.Label>{t('note')}</Form.Label>
								<Form.Control type="text" placeholder="&#128269;  " value={filterNote1} onChange={handleChangeNote1} />
							</Form.Group>
						</th>
						<th className="text-center align-bottom" colSpan="3" scope="col">{t('biz-inv_total')}</th>
						<th rowSpan="2" scope="col" className="text-center pb-3" style={{ paddingBottom: "20px" }}>
							<p className="mb-2">{t('menu-ins')}</p>
							<DropdownButton id="dropdown-basic-button" title={t(insuranceTitles[filterInsurance])} variant="light">
								<Dropdown.Item onClick={() => handleChangeInsurance("")} >{t('all')}</Dropdown.Item>
								{insurance_badges.map((itm) => <Dropdown.Item key={itm.status} onClick={() => handleChangeInsurance(itm.status)}><Badge bg={itm.bg} text={itm.color} className='beGray' style={{ width: "90px" }}>{t(itm.label)}</Badge> {t(itm.explanation)}</Dropdown.Item>)}
							</DropdownButton>
						</th>
						<th rowSpan="2" className='pb-4'>{t('biz-due')}</th>
						<th rowSpan="2" className='pb-4'>{t('biz-tolerance')}</th>
					</tr>
					<tr>
						<th className="text-center align-middle" scope="col">{t('biz-balance')}</th>
						<th className="text-center align-middle" scope="col">{t('biz-amount_money')}</th>
						<th className="text-center align-middle" scope="col">{t('biz-currency')}</th>
					</tr>
				</thead>
				<tbody>
					{
						debts.map(function (pm) {
							var ostyle = {
								...rstyle,
								fontWeight: "bold",
								backgroundColor: delay_color(pm.zpozdeni2)
							};
							var partial = "";
							if (pm.uhrada) {
								partial = "yes";
							} else {
								partial = "no";
							}
							var formatted_note = "";
							if (pm.note) {
								const commdef = notes_comms[pm.comm_type];
								const emoji = commdef.emoji;
								const variant = commdef.variant;
								const text = commdef.text;
								const cls = "rounded p-1 me-1 bg-" + variant + " " + text;
								formatted_note = <>
									<br />
									<span className={cls}><img src={emoji} alt="" width="20" /></span>
									{pm.note}
								</>;
							}
							var formatted_note2 = "";
							if (pm.note2) {
								const commdef = notes_comms[pm.comm_type2];
								const emoji = commdef.emoji;
								const variant = commdef.variant;
								const text = commdef.text;
								const cls = "rounded p-1 me-1 bg-" + variant + " " + text;
								formatted_note2 = <>
									<br />
									<span className={cls}><img src={emoji} alt="" width="20" /></span>
									{pm.note2}
								</>;
							}

							return (
								<LinkContainer to={{ pathname: "/partners/list/" + pm.KodPartnera + "/debt" }} key={pm.ps}>
									<tr>
										<td className="align-middle" style={rstyle}>{pm.stredisko}</td>
										<td className="align-middle" style={rstyle}>{pm.NazevPartnera}{formatted_note2}</td>
										<td className="align-middle" style={rstyle}>{pm.KodDokladu}{formatted_note}</td>
										<td style={rstyle} className=" align-middle">{t(partial)}</td>
										<td style={rstyle} className="text-end align-middle">{format_amount(pm.saldo)} {currency}</td>
										<td className="text-center align-middle" style={ostyle}>{pm.zpozdeni2} {t('days')}</td>
										<td className="align-middle" style={rstyle}>{pm.poznamka}</td>
										<td className="text-end align-middle">{format_amount(pm.saldo2)}</td>
										<td className="text-end align-middle">{format_amount(pm.CastkaCelkemMen)}</td>
										<td className="align-middle" style={rstyle}>{pm.KodMeny}</td>
										<td className="align-middle"><Insured3 status={get_insurance_status(pm.fic, pm.pojisteno, pm.insured, pm.auto_country_ok)} /></td>
										<td className="align-middle">{pm.dueDateDays} {t('days')}</td>
										<td className='text-center align-middle'>{pm.k_ZpozdeniPlatebTolerance} {t('days')}</td>
									</tr>
								</LinkContainer>
							);
						})
					}
					<tr>
						<td colSpan="4"><strong>{t('biz-total_balance')}</strong></td>
						<td className="text-end">{format_amount(saldo)}</td>
						<td colSpan="4" className="text-end">
							{
								currary.map(function (el) {
									cidx += 1;
									return <React.Fragment key={cidx}>{format_amount(totalcurr[el])}<br /></React.Fragment>;
								})
							}
						</td>
						<td>
							{
								currary.map(function (el) {
									cidx += 1;
									return <React.Fragment key={cidx}>{el}<br /></React.Fragment>;
								})
							}
						</td>
						<td colSpan="4"></td>
					</tr>
					<tr style={lstyle}>
						<td colSpan="4"><strong>{t('biz-after_due_balance')}</strong></td>
						<td className="text-end">{format_amount(lsaldo)}</td>
						<td colSpan="4" className="text-end">
							{
								currary.map(function (el) {
									cidx += 1;
									return <React.Fragment key={cidx}>{format_amount(latecurr[el])}<br /></React.Fragment>;
								})
							}
						</td>
						<td>
							{
								currary.map(function (el) {
									cidx += 1;
									return <React.Fragment key={cidx}>{el}<br /></React.Fragment>;
								})
							}
						</td>
						<td colSpan="4"></td>
					</tr>
				</tbody>
			</Table>
			<LoadingDataInfo loadedStatus={loadedStatus} data={fullDebts} withoutLoading />
		</>
	);
}

export function DebtsListApp({ filterCode, handleChangeCode, massFilterApp,
	handleChangePartly, filterPartly, currency, handleChangeSaldo, filterInsurance, handleChangeInsurance, debts,
	insuranceTitles, saldo, currary, totalcurr, lstyle, lsaldo, latecurr, handleChangeAppFilter, filterSaldo }) {
	var sstyle = {};
	const { t } = useTranslation();

	return (
		<div className="d-lg-none">
			<Row>
				<Col>
					<Form.Group controlId="filterNameM" className='mb-2'>
						<Form.Label>{t('mass_filter')}</Form.Label>
						<Form.Control type="text" placeholder={"🔍 " + t('filter_doc_code_note_partner')} value={massFilterApp} onChange={handleChangeAppFilter} />
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col xs={6}>
					<Form.Group controlId="filterCodeM">
						<Form.Label>{t('biz-centre')}</Form.Label>
						<Form.Control type="text" placeholder="" value={filterCode} onChange={handleChangeCode} />
					</Form.Group>
				</Col>
				<Col xs={6}>
					<Form.Group controlId="filterSaldo" className="mb-0">
						<Form.Label className="mb-1">{t('biz-acc_balance')}</Form.Label>
						<Form.Control className="mb-3" type="text"
							placeholder="&#128269; > < ="
							value={filterSaldo} onChange={handleChangeSaldo} />
					</Form.Group>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col xs={6}>
					<p className="mb-2">{t('biz-partial_pay')}</p>
					<BooleanDropdown onChange={handleChangePartly} value={filterPartly} className="beGrayButton" />
				</Col>
				<Col xs={6}>
					<p className="mb-2">{t('menu-ins')}</p>
					<DropdownButton id="dropdown-basic-button" title={t(insuranceTitles[filterInsurance])} variant="light" className="sto beGrayButton">
						<Dropdown.Item onClick={() => handleChangeInsurance("")} >{t('all')}</Dropdown.Item>
						{insurance_badges.map((itm) => <Dropdown.Item key={itm.status} onClick={() => handleChangeInsurance(itm.status)}><Badge bg={itm.bg} text={itm.color} className='beGray' style={{ width: "90px" }}>{t(itm.label)}</Badge> {t(itm.explanation)}</Dropdown.Item>)}
					</DropdownButton>
				</Col>
			</Row>

			<div className="d-block d-lg-none">
				<PartnerSaldo2 lsaldos={totalcurr} lstyle={sstyle} currs={currary} title="Saldo celkem:" tsaldo={saldo} currency={currency} />
				<PartnerSaldo2 lsaldos={latecurr} lstyle={lstyle} currs={currary} title="Saldo po splatnosti:" tsaldo={lsaldo} currency={currency} />
			</div>
			<Accordion>
				{debts.map(function (pm, idx) {
					return <DebtLineApp key={idx} pm={pm} currency={currency} />;
				})}
			</Accordion>
		</div>
	);
}

function DebtLineApp({ pm, currency }) {
	const { t } = useTranslation();

	const keyx = pm.KodDokladu;
	return (
		<Accordion.Item eventKey={keyx}>
			<Accordion.Header as="div">
				<div className='container-fluid py-0 ps-0 me-2'>
					<Row>
						<Col xs={6}>
							{pm.stredisko}
						</Col>
						<Col xs={6} className="text-end">
							{pm.NazevPartnera}
						</Col>
					</Row>
					<PlainNoteWithIcon note={pm.note2} note_type={pm.note2_type} />
					<Row>
						<Col className="beBigger">{keyx}</Col>
						<DelayOrHold label={t('biz-delay')} number={pm.zpozdeni} />
						<DelayOrHold label={t('biz-delay2')} number={pm.zpozdeni2} />
					</Row>
					<Row className="g-0">
						<Col xs="3" className="text-end">{format_amount(pm.CastkaCelkemMen)}</Col>
						<Col xs="auto">&nbsp;{pm.KodMeny} {t('total')}</Col>
					</Row>
					<Row className="g-0">
						<Col xs="3" className="text-end">{format_amount(pm.saldo2)}</Col>
						<Col xs="5">&nbsp;{pm.KodMeny} {t('biz-inv_balance')}</Col>
						<Col xs="4" className="text-end">
							<NoteIcon note={pm.note} note_type={pm.note_type} />
							<Insured3 status={get_insurance_status(pm.fic, pm.pojisteno, pm.insured, pm.auto_country_ok)} />
						</Col>
					</Row>
				</div>
			</Accordion.Header>
			<Accordion.Body className='p-1'>
				<LinkContainer to={{ pathname: "/partners/list/" + pm.KodPartnera + "/debt" }}>
					<Card.Body className="beSmaller">
						<ListGroup variant="flush" className="smaller">
							<ListGroup.Item>
								<Row className="g-0">
									<Col xs="3" className="text-end">{format_amount(pm.saldo)}</Col>
									<Col xs="4">&nbsp;{currency} {t('biz-acc_balance')}</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								{pm.uhrada === null ? <span>{t('biz-partial_pay')}: {t('no')}</span> : <span>{t('biz-partial_pay')}: {t('yes')}</span>}
							</ListGroup.Item>
							<ListGroup.Item>
								<Row className="g-0">
									<Col >{t('biz-due')}: {pm.dueDateDays} {t('days')}</Col>
									<Col className='text-end'>{t('biz-tolerance')}: {pm.k_ZpozdeniPlatebTolerance} {t('days')}</Col>
								</Row>
							</ListGroup.Item>
							<NoteWithIcon note={pm.note} note_type={pm.note_type} />
							<ListGroup.Item>{pm.poznamka}</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</LinkContainer>
			</Accordion.Body>
		</Accordion.Item>
	);
}
