
/**
 * Generic progress component
 * @module comp/progress
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import {
    Row, Col, OverlayTrigger, Popover,
    //Button, Badge 
} from "react-bootstrap";
import { date_time_format, time_diff_formatted } from '../lib/date-utils';
import { Downloader } from "./downloader";
import { greenish, greyish } from '../lists/colors';
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { compare_values } from "../lib/utils";
//globální konstanty - aby šla barva změnit na jednom místě, aka matematické konstanty
// dynamické věci nemají být globální
// při dynamické definici si udělat funkci, co vrátí jednu z konstant

/**
 * Creates dictionary of steps for one progress
 * 
 * @param {*} specification - dictionary with definion of all possible progress steps
 * @param {*} history - history of event in progress
 * @param {*} stockin_extra_content - which additional elements should be displayed with progress
 * @returns {dictionary}
 */
export function make_progress_steps(specification, history, stockin_extra_content) {
    const specification_dict = specification.reduce(
        (acc, spec) => ({ ...acc, [spec.number]: spec }),
        {});
    //console.log(specification_dict);
    // console.log(history);
    const reversed_history = [...history].reverse(); //vyrobíme kopii historie a tu zreversujem
    //console.log(reversed_history);
    return specification
        .filter((spec) => spec.standard) //filtruji standardní - ukazuji je šedivě, když nejsou data
        .map(function (spec) { //přemapovávám pole slovníků
            const relevant_records = reversed_history //dovnitř si nacpu zreversovanou historii
                .filter((rec) => (specification_dict[rec.status] || {}).position === spec.position); // má stejnou pozici, jako standard - napcou se tam všechny, které mají pozici
            // .reverse(); //tady se vyrábí kopie filtrem, takže reverse je v pořádku
            if (relevant_records.length === 0) {
                return {
                    name: spec.name, //if final step is undefined, lets use just name
                    position: spec.position,
                    color: spec.color,
                    passed: null,
                    timestamp: null,
                };
            } else {
                //console.log(relevant_records);
                // pro každou pozici si vemu nejnovější 
                const sorted_relevant_records = relevant_records.sort((a, b) => compare_values(a, b, "recorded", 1)); //sort in case data arent correctly sorted from backend
                const record = sorted_relevant_records[0]; //nejnovější je nejmladší, protože jsme to reversovali //relevant records jsou previous_steps
                const record_spec = specification_dict[record.status]; //specifikace pro můj nejvovější record
                const active = record.status === reversed_history[0].status; // pokud status nejnovějšího je stejný jako status recordu
                //console.log(record);
                //console.log(reversed_history);
                // const custom_keys = stockin_extra_content ? stockin_extra_content(record, record_spec, active) : {};
                return {
                    ...record,
                    name: record_spec.name,
                    name2: record_spec.name2,
                    name3: record_spec.name3,
                    carrier: record.pdd_KodDopravce,
                    position: record_spec.position,
                    color: record_spec.color,
                    background: record_spec.background,
                    passed: true,
                    timestamp: record.recorded,
                    created_by: record.mp_Jmeno,
                    number: record_spec.number,
                    active: active,
                    timer: record_spec.timer,
                    //sns_sn_id: record.sns_sn_id,
                    //sns_twist_nd: record.sns_twist_nd,
                    //sns_twist_response: record.sns_twist_response,
                    // ...custom_keys,
                    extra_function: stockin_extra_content,
                    previous_steps: sorted_relevant_records.slice(1) //slice(1) takes away current record 
                        .map((rec) => ({
                            ...rec,
                            name: specification_dict[rec.status].name,
                            color: specification_dict[rec.status].color,
                        }))      //ještě budu přidávat nějak ten extra content
                };
            }
        });
}

/**
 * Return progress path based on steps definition
 * 
 * @param {any} steps - definition of steps
 * @param {boolean} compact - whether step shoud be in compact version
 * @returns {component}
 */
export function ProgressPath({ steps, compact = false, authorizedUser }) {

    //console.log(carrier);

    const num_steps = steps.length;
    const first_active = ((steps.find((step) => step.passed) || {}).position || 1) - 1;
    const last_active = ((steps.filter((step) => step.passed).reverse()[0] || {}).position || 1) - 1;
    const grey_line_indentation = 100 / (2 * num_steps) + '%';
    const grey_line_width = (100 - (100 / num_steps)) + '%';
    const grey_line_style = {
        left: grey_line_indentation,
        width: grey_line_width,
    }
    const colored_line_indentation = first_active * 100 / num_steps + 100 / (2 * num_steps) + '%';
    const colored_line_width = (last_active - first_active) * 100 / num_steps + '%';
    const grey_style2 = {
        left: colored_line_indentation,
        width: colored_line_width,
        backgroundColor: greenish,
    };
    const dynamic_line_size = {
        top: compact ? "14px" : "18px",
        height: compact ? "2px" : "4px",
    }

    const mobile_class_row = compact ? "" : " d-block d-md-flex ";
    const mobile_class_line = compact ? "" : " d-none d-md-block ";
    return (
        <>
            <Row className={" my-1 progress-container g-0 " + mobile_class_row}>
                <div className={"myBefore " + mobile_class_line} style={{ ...grey_line_style, ...dynamic_line_size }}></div>
                <div className={"myBefore " + mobile_class_line} style={{ ...grey_style2, ...dynamic_line_size }}></div>
                {steps.map(function (step, idx) {
                    //console.log(step.previous_steps)
                    return <ProgressStep step={step} key={idx} compact={compact} authorizedUser={authorizedUser} />;
                })}
            </Row>
        </>
    );
}

/**
 * Shows timer after defined period of time in step.timer
 * 
 * @param {any} step - one step
 * @returns {component}
 */

function Timer({ step }) {
    const timeDiffMinutes = (new Date() - new Date(step.timestamp)) / 1000; //in seconds
    const overDefinedTime = timeDiffMinutes > step.timer; // in seconds
    //u aktivního kroku ukazuje timer, pokud je definovaný v dokumentaci na true
    const showTimer = step.timer && step.active && overDefinedTime
    return showTimer ? <><small>{time_diff_formatted(step.timestamp)}</small><br /></> : <></>;
}

function split_filename(filename) {
    const length = filename.length;
    if (length > 25) {
        return (
            <>
                {filename.slice(0, 20)}
                <br />
                {filename.slice(20, 100)}
            </>);
    } else {
        return filename;
    }
}

/**
 * Returns one single step in progress path.
 * 
 * @param {any} step - definition of one progress step
 * @param {boolean} compact - whether step shoud be in compact version
 * @returns {component}
 */
function ProgressStep({ step, compact, authorizedUser }) {
    const { t } = useTranslation();

    //console.log("HSH_IMPORT_LOG_20233105100400.txt".length);

    const dynamic_cicle_size = {
        height: compact ? "30px" : "40px",
        width: compact ? "30px" : "40px",
        border: compact ? "2px solid" : "3px solid",
        lineHeight: compact ? "1" : "1.5",
    }

    const time_title = step.timestamp ? date_time_format(step.timestamp) : "";
    const title = compact ? step.name + " " + time_title : "";
    const step_color = step.passed ? "white" : greyish;
    const backgroundColor = step.passed ? step.color : "";

    const sent_file = <ProgressFileDownloader step={step} id_key_name="fwr_id" filename_key_name="fwr_file_name" path="/api/ftp/get/sent/" />;
    const received_file = <ProgressFileDownloader step={step} id_key_name="frf_id" filename_key_name="frf_file_name" path="/api/ftp/get/received/" />;

    /**  for testing purposes
        <div className="bg-white p-2 m-1 rounded" >
            <span className="text-dark">{split_filename("HSH_IMPORT_LOG_20233105100400.txt")}</span>&nbsp;&nbsp;
            <Downloader img="/download.svg" height="20" alt="pdf" label={""}
                path={"/api/ftp/get/sent/" + step.fwr_id}
                filename={"step.fwr_file_name"} />
        </div>
        */

    //console.log(step);
    //with delivery notes, final step name is based on carrier (samoodběr means handed over to customer, else is handed over to shipping), therefore specification defines name and name2
    const final_step_delivery_note_name = step.carrier === "SAMOODBĚR" ? t(step.name3) : t(step.name2);
    const name_of_step = step.name2 && step.name3 ? final_step_delivery_note_name : t(step.name);

    return (
        <Col className={" mb-4 progressz"} style={{ color: backgroundColor }}>
            <div
                //onClick={() => console.log("click")}
                title={title}
                style={{ ...dynamic_cicle_size, color: backgroundColor, backgroundColor: backgroundColor, cursor: "pointer", position: "relative" }}
                className={' text-center circle d-inline-block d-md-block'}>
                <span style={{ color: step_color }}>{step.position}</span>
                {step.previous_steps && step.previous_steps.length && !compact > 0 && authorizedUser ?
                    <PreviousSteps step={step} />
                    : <></>
                }
            </div>
            {compact ? <></> :
                <>
                    <div className={' text-center mt-2 d-inline d-md-block mx-2 '}>{name_of_step}</div>
                    <div className={' text-center d-inline d-md-block '}><small>{date_time_format(step.timestamp)}</small></div>
                    <div className={' text-center d-inline d-md-block ms-2'}><small>{step.created_by}</small></div>
                    <div className={' text-center d-inline d-md-block '}><Timer step={step} /></div>
                    <div className={' text-center d-inline d-md-block my-1'}>{sent_file}{received_file}</div>
                    {step.extra_function ?
                        <div className={' text-center d-inline d-md-block '}>{step.extra_function(step)}</div>
                        :
                        <></>
                    }
                </>
            }
        </Col>
    );
}

/**
 * Returns downloader, if file id and filename are defined, specific design for progress-path
 * 
 * @param {any} step - dictionary with step definition
 * @param {string} id_key_name - name of right key for file id - differs for sent and received
 * @param {string} filename_key_name - name of right key for filename - differs for sent and received
 * @param {string} path - path for download - differs for sent and received
 * @returns {component}
 */
function ProgressFileDownloader({ step, id_key_name, filename_key_name, path }) {
    //console.log(step);
    if (step[id_key_name] && step[filename_key_name]) {
        return <div className="beGray4 p-2 m-1 rounded">
            <span className="text-dark">{split_filename(step[filename_key_name])}</span>&nbsp;&nbsp;
            <Downloader img="/download.svg" height="20" alt="pdf" label={""}
                path={path + step[id_key_name]}
                filename={step[filename_key_name]} />
        </div>;
    } else {
        return "";
    }
}

/**
 * Plus button above the position number of the step, which allows to toggle previous states happened within particular position
 * 
 * @param {any} step - dictionary with step definition
 * @returns {component}
 */
function PreviousSteps({ step }) {
    const { t } = useTranslation();

    const [showStepDetail, setShowStepDetail] = useState(false);

    return (
        <OverlayTrigger onToggle={setShowStepDetail} show={showStepDetail} trigger="click" placement="right-start" overlay={
            <Popover style={{ maxWidth: "400px", width: "400px" }}>
                <div className="p-3">
                    <h5 className="float-end myHover mb-0" style={{ cursor: "pointer" }} onClick={() => setShowStepDetail(false)}>×</h5>
                    {step.previous_steps ? [...step.previous_steps].reverse().map((step, idx) =>
                        <div className="mb-1" key={idx} style={{ color: step.color }}>
                            {idx === 0 ? "" : <hr />}
                            <h6 className="beBigger">{t(step.name)}</h6>
                            {date_time_format(step.recorded)} <br />
                            {step.mp_Jmeno ? <>{step.mp_Jmeno}<br /></> : ""}
                            <div className="d-inline text-center">
                                <ProgressFileDownloader step={step} id_key_name="fwr_id" filename_key_name="fwr_file_name" path="/api/ftp/get/sent/" />
                                <ProgressFileDownloader step={step} id_key_name="frf_id" filename_key_name="frf_file_name" path="/api/ftp/get/received/" />
                            </div>
                            {step.sns_twist_nd ? step.sns_twist_nd : ""}
                            {step.sns_twist_response ? step.sns_twist_response : ""}
                        </div>)
                        : <></>}
                </div>
            </Popover>
        }>
            {/*<Button className="p-0 m-0" style={{ position: "absolute", top: "-28px", left: "28px", color: "white", backgroundColor: step.color, border: step.color, borderRadius: "50%" }}><h3 className="mb-0 mt-0 mx-1 p-0">+</h3></Button>*/}
            <div style={{ position: "absolute", top: "-28px", left: "28px", cursor: "pointer" }}><h3>+</h3></div>
        </OverlayTrigger>
    )
}
