import React, { useState } from "react";
import { his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import { Col, Form, Row, Table } from "react-bootstrap";
import { warehouses_names_ids_separe } from "../lists/warehouses-defs";
import { Pager } from "../comp/pager";
import { compare_values, filter_rule, icompare } from "../lib/utils";
import { ItemsAndFiltered } from "../comp/dynamic-load";
import { date_formatCZ, date_time_format } from "../lib/date-utils";
import { convert_warehouse_container } from "./product-checks";
import { format_amount, hilite } from "../lib/format";
import { useTranslation } from "react-i18next";
import { MyInfo } from "../comp/info-badge";
import { DefaultError, useQuery } from "@tanstack/react-query";
import { getStocksExpirations, getStocksRecords, getTwistStocksRecords } from "../api/stocks";
import { queryStatusToLoadedStatus } from "../api/common";
import { get_package_weight_kg } from "../lib/generic-checks";
import Fraction from "fraction.js";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import BooleanDropdownRhf from "../comp/BooleanDropdownRhf";
import { SortIconsRhf } from "../comp/SortIconsRhf";
import { MultipleSelectRhf } from "../comp/MultipleSelectRhf";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";

interface RemappedRow {
  alf_batch: string;
  alf_batch_sum?: number;
  alf_batch_weight_kg?: number;
  alf_package?: string;
  alf_package_count?: number;
  alf_package_amount?: number;
  alf_package_unit?: string;
  alf_locations?: string[];
  alf_last_update?: string;
  expiry?: string;
  batches_in_whs?: (RemappedRow & Dto.GetStocksExpirationsResponseItem)[];
  batch_td_rowspan?: number;
  seriesCode?: string;
}

export function getBatchWeightKgTwist(batchLine: Dto.GetTwistStocksResponseItem, fraction = false) {
  try {
    const kg_package_weight_fraction = kgPackageWeight(batchLine, true) as unknown as Fraction;
    const items_count_fraction = new Fraction(batchLine.storageAmount).div(
      new Fraction(batchLine.product.packageUnitOfMeasurementQuantity)
    );
    const result = kg_package_weight_fraction.mul(items_count_fraction);
    return fraction ? result : parseFloat(parseFloat(result.toString()).toFixed(2)); //zaokrouhlí na 2 desetiná místa a opět vrátí jako float, aby na to fungoval icompare
  } catch (ex) {
    return "error";
  }
}

export function getPackageCountTwist(batchLine: Dto.GetTwistStocksResponseItem) {
  try {
    const result = new Fraction(batchLine.storageAmount).div(
      new Fraction(batchLine.product.packageUnitOfMeasurementQuantity)
    );
    return parseFloat(parseFloat(result.toString()).toFixed(2));
  } catch (ex) {
    return "error";
  }
}

/**
 * Keys remaping HSH TWIST: adds some keys to every dictionary (batch) in an array - goal is unify(rename) keys
 */
function remapTwistKeys(batches: Dto.GetTwistStocksResponseItem[]): RemappedRow[] {
  return batches.reduce(
    (
      acc,
      batchLine //this is how we add keys to dicts in array of dicts!!!!!!
    ) => [
      ...acc,
      {
        ...batchLine,
        // keys added on frontend have prefix "alf": a - first letter of alphabetically shown keys, l - lucka, f - frontend
        /*šarže*/
        alf_batch: (batchLine.seriesCode || "").trim(),
        /*Množství MJ */
        alf_batch_sum: batchLine.storageAmount, //tohle pak zobrazuju, to je ok
        /*Hmotnost KG*/
        alf_batch_weight_kg: getBatchWeightKgTwist(batchLine),
        alf_package: convert_warehouse_container(parseInt(batchLine.warehouseId), batchLine.product.hshPackage),
        alf_package_count: getPackageCountTwist(batchLine),
        alf_package_amount: batchLine.product.packageUnitOfMeasurementQuantity, // kolik MJ je v jednom balení, to je ok
        alf_package_unit: batchLine.product.storageUnitOfMeasurementCode, // MJ
        // kolik má celá šarže v kg
        alf_locations: [],
        alf_last_update: batchLine.stamp,
      },
    ],
    []
  );
}

function get_batch_weight_kg_warehouse(batchLine: Dto.GetStocksRecordsResponseItem, fraction = false) {
  try {
    const kg_package_weight_fraction = kgPackageWeight(batchLine, true);
    const items_count_fraction = new Fraction(batchLine.itemsCount);
    const result = kg_package_weight_fraction.mul(items_count_fraction);
    return fraction ? result : parseFloat(parseFloat(result).toFixed(2));
  } catch (ex) {
    return "error";
  }
}

/**
 * Keys remaping warehouses: adds some keys to every dictionary (batch) in an array - goal is unify(rename) keys
 */
function remapWarehousesKeys(batches: Dto.GetStocksRecordsResponseItem[]): RemappedRow[] {
  return batches.reduce(
    (
      acc,
      batchLine //this is how we add keys to dicts in array of dicts!!!!!!
    ) => [
      ...acc,
      {
        ...batchLine,
        // keys added on frontend have prefix "alf": a - first letter of alphabetically shown keys, l - lucka, f - frontend
        alf_batch: (batchLine.seriesCode || "").trim(),
        alf_batch_sum: batchLine.amount,
        //alf_batch_weight_kg_old: kg_package_weight(batchLine) * batchLine.sr_items_count,
        alf_batch_weight_kg: get_batch_weight_kg_warehouse(batchLine),
        alf_package: batchLine.product.hshPackage,
        alf_package_count: batchLine.itemsCount,
        alf_package_amount: batchLine.amountPerItem,
        alf_package_unit: batchLine.amountUnit,
        alf_locations: batchLine.locations,
        alf_last_update: null,
      },
    ],
    []
  );
}

export interface PreprocessedStocksData {
  IDProduktu: number;
  product_name: string;
  product_code: string;
  package: string;
  package_amount?: number;
  package_unit?: string;
  package_weight_kg?: number;
  batches: RemappedRow[];
  sum_of_batches_amounts?: number;
  product_sum_kg?: number;
  product_td_rowspan?: number;
  problematic?: boolean;
  centre?: string;
}

export function uniqueSortedProductIds<T, V extends string | number>(data: T[], getName: (item: T) => V) {
  return (
    data
      // @ts-ignore
      .sort((a, b) => getName(a) - getName(b))
      .map((m) => getName(m))
      .filter((v, i, a) => a.indexOf(v) === i)
  );
}

function kgPackageWeight(batchLine: Dto.GetStocksRecordsResponseItem, fraction = false) {
  try {
    const result = get_package_weight_kg(
      batchLine.product.unitOfMeasurementWeight,
      batchLine.product.unitOfMeasurementUnit,
      batchLine.product.packageUnitOfMeasurementQuantity
    );
    return fraction ? result : parseFloat(result.toString());
  } catch (ex) {
    return "error";
  }
}

/**
 * Preprocessing of stocks data for both - Twist and warehouse data
 */
export function preprocessStocksData(
  stocksData: Dto.GetStocksRecordsResponseItem[],
  type: WarehouseStocksDisplayProps["dataType"],
  sort_key: string = "alf_last_update",
  direction: "up" | "down" = "up"
): PreprocessedStocksData[] {
  const productID_db_name = (s: Dto.GetStocksRecordsResponseItem) => s.product.id;
  const unique_product_ids_sorted = uniqueSortedProductIds(stocksData, productID_db_name);

  //creates dictionary, that matches productID as key with product data as value
  const product_batches_tree: { [key: number]: (RemappedRow & Dto.GetStocksRecordsResponseItem)[] } =
    unique_product_ids_sorted.reduce(function (acc, productID) {
      const oneProductStocksData = stocksData.filter((rec) => productID_db_name(rec) === productID);
      const productData =
        type === "warehouses" ? remapWarehousesKeys(oneProductStocksData) : remapTwistKeys(oneProductStocksData);
      //console.log(productData);
      return {
        ...acc,
        [productID]: productData,
      };
    }, {});

  // creates array of dictionaries with data for one product, including its batches
  //console.log(product_batches_tree[5400]);
  const stocksTreeArray = unique_product_ids_sorted
    .map(function (IDProduktu) {
      const this_product = product_batches_tree[IDProduktu];
      const one_batch = this_product[0];
      //adding sum keys to array of batches dictionaries and sorting them later
      const unique_batches = this_product
        .map((b) => b.alf_batch)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((b) => this_product.find((br) => br.alf_batch === b))
        .map((br) => ({
          ...br,
          alf_batch_sum: this_product
            .filter((br2) => br.alf_batch === br2.alf_batch)
            .reduce((acc, br) => acc + (br.alf_batch_sum || 0), 0),
          alf_batch_weight_kg: this_product
            .filter((br2) => br.alf_batch === br2.alf_batch)
            .reduce((acc, br) => acc + (br.alf_batch_weight_kg || 0), 0),
        }));
      const sorted_batches = unique_batches.sort((a, b) =>
        sort_last_update(a, b, sort_key, direction === "up" ? 1 : -1)
      );

      return {
        IDProduktu: IDProduktu,
        product_name: one_batch.product.name,
        product_code: one_batch.product.code,
        package: one_batch.alf_package,
        package_amount: one_batch.alf_package_amount,
        package_unit: one_batch.alf_package_unit,
        package_weight_kg: kgPackageWeight(one_batch),
        batches: sorted_batches,
        sum_of_batches_amounts: unique_batches
          .map((batch) => batch.alf_batch_sum)
          .reduce((partial_sum, a) => partial_sum + a, 0), //FRACTION needed?
        product_sum_kg:
          kgPackageWeight(one_batch) *
          unique_batches.map((batch) => batch.alf_package_count).reduce((partial_sum, a) => partial_sum + a, 0), //FRACTION needed? //váha jednoho * suma všech kusů
      };
    })
    .sort((a, b) => sort_last_update(a.batches[0], b.batches[0], sort_key, direction === "up" ? 1 : -1));

  //console.log(stocksTreeArray);
  return stocksTreeArray;
}

/**
 * Compares two values according to sorting key, two dictionaries are expected
 *
 * @param {dictionary} a - first entry
 * @param {dictionary} b - second entry
 * @param {string} sort_key - name of sorting key
 * @param {string} direction - sorting direction
 * @returns {number} - represents which entry is below the other
 */
export function sort_last_update(a, b, sort_key = "alf_last_update", direction = 1) {
  const a_value = a[sort_key];
  const b_value = b[sort_key];
  //console.log('sorting: a_date='+a_date+' b_date='+b_date);
  if (a_value < b_value) {
    return 1 * direction;
  }
  if (b_value < a_value) {
    return -1 * direction;
  }
  return 0;
}

/**
 * special preprocessing for expirations, where 3 level structure is needed
 */
function preprocess_expirations_data(
  expirations: Dto.GetStocksExpirationsResponseItem[],
  direction: "up" | "down",
  sort_key: string
): PreprocessedStocksData[] {
  const unique_product_ids_sorted = uniqueSortedProductIds(
    expirations,
    (rec: Dto.GetStocksExpirationsResponseItem) => rec.product.id
  );
  // console.log(unique_product_ids_sorted);

  //creates dictionary, that matches productID as key with product data as value
  const product_batches_tree: { [key: number]: (RemappedRow & Dto.GetStocksExpirationsResponseItem)[] } =
    unique_product_ids_sorted.reduce(function (acc, productID) {
      const oneProductStocksData = expirations.filter((rec) => rec.product.id === productID);
      const productData = remapTwistKeys(oneProductStocksData as Dto.GetTwistStocksResponseItem[]);
      //console.log(productData);
      return {
        ...acc,
        [productID]: productData,
      };
    }, {});
  // console.log(product_batches_tree);

  // creates array of dictionaries with data for one product, including its batches
  const expirationsTreeArray =
    sort_key === "expiry" //ternary operator was used, because sorting of expiry is on second level of structure and sorting of last_update is on third level, so we need 2 or 3 times sorting for mentioned cases
      ? unique_product_ids_sorted
          .map(function (IDProduktu) {
            const this_product = product_batches_tree[IDProduktu]; //všechny batches per jedno IDProduktu
            const one_batch = this_product[0]; //první batch z produktové  série

            return {
              IDProduktu: IDProduktu,
              product_name: one_batch.product.name,
              product_code: one_batch.product.code,
              package: one_batch.alf_package,
              centre: one_batch.product.groupCode,
              batches: process_expirations_batches(this_product).sort((a, b) =>
                compare_values(a, b, "expiry", direction === "up" ? 1 : -1)
              ), //local sorting of expirations within batches on product
              product_td_rowspan: this_product.length,
              problematic: this_product.filter((rec) => rec.product.expiration === null).length > 0, //zatím je problematic === bez expirace
            };
          })
          .sort((a, b) => compare_values(a.batches[0], b.batches[0], sort_key, direction === "up" ? 1 : -1)) //sorting according to expiry globaly
      : unique_product_ids_sorted
          .map(function (IDProduktu) {
            const this_product = product_batches_tree[IDProduktu]; //všechny batches per jedno IDProduktu
            const one_batch = this_product[0]; //první batch z produktové  série
            //console.log(one_batch);
            return {
              IDProduktu: IDProduktu,
              product_name: one_batch.product.name,
              product_code: one_batch.product.code,
              package: one_batch.alf_package,
              centre: one_batch.product.groupCode,
              batches: process_expirations_batches(this_product, direction, sort_key).sort((a, b) =>
                compare_values(a.batches_in_whs[0], b.batches_in_whs[0], "alf_last_update", direction === "up" ? 1 : -1)
              ), //second level sorting based on last update within one product
              product_td_rowspan: this_product.length,
              problematic: this_product.filter((rec) => rec.product.expiration === null).length > 0, //zatím je problematic === bez expirace
            };
          })
          .sort((a, b) =>
            compare_values(
              a.batches[0].batches_in_whs[0],
              b.batches[0].batches_in_whs[0],
              sort_key,
              direction === "up" ? 1 : -1
            )
          ); //global sorting based on last_update

  return expirationsTreeArray;
}

/**
 * Returns batches in product grouped by batchString, batches_in_whs represent the batch in warehouse
 */
function process_expirations_batches(
  batches: (RemappedRow & Dto.GetStocksExpirationsResponseItem)[],
  direction: "up" | "down" = "up",
  sort_key = undefined
): (RemappedRow & Dto.GetStocksExpirationsResponseItem)[] {
  const unique_batches = uniqueSortedProductIds(batches, (s: Dto.GetStocksExpirationsResponseItem) => s.seriesCode);
  const batches_tree: { [key: string]: (RemappedRow & Dto.GetStocksExpirationsResponseItem)[] } = unique_batches.reduce(
    function (acc, batchString) {
      const oneBatchData = batches.filter((rec) => rec.seriesCode === batchString);
      return {
        ...acc,
        [batchString]: oneBatchData,
      };
    },
    {}
  );
  const batchesTreeArray = unique_batches.map(function (batchString: string) {
    const this_batch = batches_tree[batchString]; //všechny záznamy per jedden batchstring
    const one_batch_item = this_batch[0]; //první záznam z série jedné šarže
    const sort_it = direction && sort_key;
    const batches_in_whs = sort_it
      ? this_batch.sort((a, b) => compare_values(a, b, sort_key, direction === "up" ? 1 : -1))
      : this_batch; //third level of sorting based on last update, only if we call the function with params direction and sort_key
    return {
      alf_batch: batchString,
      expiry: one_batch_item.product.expiration,
      batches_in_whs: batches_in_whs,
      batch_td_rowspan: this_batch.length,
    };
  });

  return batchesTreeArray;
}

interface WarehouseStocksDisplayProps {
  dataType: "warehouses" | "twist" | "expirations";
  warehouseId: number;
  fetchDate: string;
}

type QueryType = Dto.GetStocksRecordsResponse | Dto.GetTwistStocksResponse | Dto.GetStocksExpirationsResponse;

/**
 * Fetches and displays stocks in particular warehouse (warehouse and twist view is switched by type prop)
 */
export function WarehouseStocksDisplay({ dataType, warehouseId, fetchDate }: WarehouseStocksDisplayProps) {
  const [offset, setOffset] = useState(0);

  const { data, refetch, status, isFetching } = useQuery<
    QueryType,
    DefaultError,
    QueryType,
    [string, WarehouseStocksDisplayProps["dataType"], number, string]
  >({
    queryKey: ["stocks", dataType, warehouseId, fetchDate],
    queryFn: ({ queryKey, signal }) => {
      const [, dataType, warehouseId, date] = queryKey;
      if (dataType === "warehouses") return getStocksRecords(warehouseId, date, { signal });
      if (dataType === "twist") return getTwistStocksRecords(warehouseId, date, { signal });
      if (dataType === "expirations") return getStocksExpirations(date, { signal });
    },
    initialData: {
      items: [],
      snapshotCreated: null,
      warehouseId: null,
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  if (!his_fetch_success(loadedStatus)) {
    return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={refetch} />;
  }

  return (
    <WarehousesStocksTable
      stocksData={data.items}
      offset={offset}
      setOffset={setOffset}
      updateTime={data["snapshotCreated"]}
      dataType={dataType}
      warehouseID={warehouseId}
    />
  );
}

function createWhDict(
  stocksData: Dto.GetStocksExpirationsResponseItem[] | Dto.GetStocksRecordsResponseItem[],
  boolean: boolean
) {
  const unique_whs = stocksData
    .map((rec: Dto.GetStocksExpirationsResponseItem | Dto.GetStocksRecordsResponseItem) => rec["warehouseId"])
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();
  return unique_whs.reduce((acc, v) => ({ ...acc, [v]: boolean }), {});
}

const defaultFilterValues: WarehouseStocksFilterValues = {
  batch: "",
  product: "",
  count: "",
  amount: "",
  amountKg: "",
  total: "",
  totalKg: "",
  superTotal: "",
  superTotalKg: "",
  centre: "",
  warehouses: [],
  orderBy: "expiry",
  problematic: null,
};

interface WarehousesStocksTableProps {
  stocksData: Dto.GetStocksExpirationsResponseItem[] | Dto.GetStocksRecordsResponseItem[];
  offset: number;
  setOffset: (offset: number) => void;
  updateTime: string;
  dataType: "warehouses" | "twist" | "expirations";
  warehouseID: number;
}

function WarehousesStocksTable({
  stocksData,
  offset,
  setOffset,
  updateTime,
  dataType,
  warehouseID,
}: WarehousesStocksTableProps) {
  const { t } = useTranslation();

  const dictionaryOfWHValues = createWhDict(stocksData, true);
  const itemsNames = Object.keys(dictionaryOfWHValues).reduce((acc, v) => ({ ...acc, [v]: v }), {});
  const form = useForm<WarehouseStocksFilterValues>({ mode: "onChange", defaultValues: defaultFilterValues });

  const [filters] = useDebounceFilterValues(form.watch, defaultFilterValues);
  const sortDirection = filters.orderBy?.startsWith("-") ? "down" : "up";
  const sortValue = filters.orderBy?.replace(/^-/, "");

  const cleanFilters = () => {
    form.reset(defaultFilterValues);
  };

  const preprocessedData =
    dataType === "expirations"
      ? preprocess_expirations_data(stocksData as Dto.GetStocksExpirationsResponseItem[], sortDirection, sortValue)
      : preprocessStocksData(stocksData as Dto.GetStocksRecordsResponseItem[], dataType, sortValue, sortDirection);

  const stocks_filtered = preprocessedData.filter(function (stock) {
    return (
      (filter_rule(filters.product, stock.IDProduktu, true) ||
        filter_rule(filters.product, stock.product_code, true) ||
        filter_rule(filters.product, stock.product_name, true)) &&
      icompare(stock.package_amount, filters.amount) &&
      icompare(stock.package_weight_kg, filters.amountKg) &&
      icompare(stock.sum_of_batches_amounts, filters.superTotal) &&
      icompare(stock.product_sum_kg, filters.superTotalKg) &&
      stock.batches.reduce((res, batch) => res || filter_rule(filters.batch, batch.alf_batch, true), false) &&
      stock.batches.reduce((res, batch) => res || icompare(batch.alf_package_count, filters.count), false) &&
      stock.batches.reduce((res, batch) => res || icompare(batch.alf_batch_sum, filters.total), false) &&
      stock.batches.reduce((res, batch) => res || icompare(batch.alf_batch_weight_kg, filters.totalKg), false)
    );
  });

  const expirations_filtered = preprocessedData.filter(function (stock) {
    return (
      (filters.problematic === null || filters.problematic === stock.problematic) &&
      (filter_rule(filters.product, stock.IDProduktu, true) ||
        filter_rule(filters.product, stock.product_code, true) ||
        filter_rule(filters.product, stock.product_name, true)) &&
      filter_rule(filters.centre, stock.centre, true) &&
      stock.batches.reduce((res, batch) => res || filter_rule(filters.batch, batch.alf_batch, true), false) &&
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) => res || icompare(batchInWh.alf_package_count, filters.count),
            false
          ),
        false
      ) &&
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) => res || icompare(batchInWh.alf_batch_sum, filters.total),
            false
          ),
        false
      ) &&
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) => res || icompare(batchInWh.alf_batch_weight_kg, filters.totalKg),
            false
          ),
        false
      ) &&
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) =>
              res ||
              (filters.warehouses?.length ?? 0) === 0 ||
              filters.warehouses?.includes(batchInWh.warehouseId.toString()),
            false
          ),
        false
      )
    );
  });

  const filtered = dataType === "expirations" ? expirations_filtered : stocks_filtered;

  const stocks_sorted = filtered.sort(function (a, b) {
    if (
      filters.orderBy?.includes("IDProduktu") ||
      filters.orderBy?.includes("expiry") ||
      filters.orderBy?.includes("alf_last_update")
    ) {
      if (sortDirection === "up") {
        return a[sortValue] - b[sortValue];
      }
      if (sortDirection === "down") return b[sortValue] - a[sortValue];
    }
    return 0;
  });

  const show_stocks = stocks_sorted.slice(offset, offset + 20);

  //dataType can be following: warehouses, twist, expirations
  const showOnePackContent = dataType === "warehouses" || dataType === "twist";
  const showSum = dataType === "warehouses" || dataType === "twist";
  const showWarehouseAndExpiry = dataType === "expirations";

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">
            {showWarehouseAndExpiry ? "" : t("prod-stock_wh") + warehouses_names_ids_separe[warehouseID]}
          </h4>
          {updateTime ? (
            <p className="text-secondary">
              {t("prod-last_update")}: {date_time_format(updateTime)}
            </p>
          ) : (
            ""
          )}
        </Col>
        <Col className="text-center">
          <ItemsAndFiltered filtered_data={filtered} data={preprocessedData} cleanFilters={cleanFilters} />
        </Col>
        <Col>
          <Pager offset={offset} pagesize={20} total={filtered.length} callback={setOffset} />
        </Col>
      </Row>
      <Table size="sm" bordered style={{ backgroundColor: "white" }}>
        <thead className="beGray">
          <FormProvider {...form}>
            <WarehouseStocksFilters
              showWarehouseAndExpiry={showWarehouseAndExpiry}
              showOnePackContent={showOnePackContent}
              showSum={showSum}
              dataType={dataType}
              whDictionary={itemsNames}
            />
          </FormProvider>
        </thead>
        {showWarehouseAndExpiry ? (
          <tbody>
            {show_stocks.map(function (prod, idx) {
              const bg = idx % 2 === 0 ? "" : "beGray3";
              return prod.batches.map(function (batch, idxBatch) {
                return batch.batches_in_whs.map(function (batchInWh, idxBatchInWh) {
                  const class_hilite_MJ =
                    icompare(batchInWh.alf_batch_sum, filters.total) && filters.total !== "" ? "bg-info-light" : "";
                  const class_hi_kg =
                    icompare(batchInWh.alf_batch_weight_kg, filters.totalKg) && filters.totalKg !== ""
                      ? "bg-info-light"
                      : "";
                  const class_hi_count =
                    icompare(batchInWh.alf_package_count, filters.count) && filters.count !== "" ? "bg-info-light" : "";
                  const class_hi_wh =
                    filters.warehouses?.includes(batchInWh.warehouseId.toString()) &&
                    !(filters.warehouses?.length === Object.keys(itemsNames).length)
                      ? "bg-info-light"
                      : ""; //pokud jsou všechny true, tak nebarvit, pole těch false je 0
                  const problematic_bg = prod.problematic ? "bg-danger" : "bg-success";
                  return (
                    <tr key={idxBatchInWh} className={bg}>
                      {idxBatch === 0 && idxBatchInWh === 0 ? (
                        <>
                          <td
                            rowSpan={prod.product_td_rowspan}
                            className={problematic_bg + " text-center align-middle"}
                          >
                            {prod.problematic ? <span className="bg-light p-1">❌</span> : "✅"}
                          </td>
                          <td rowSpan={prod.product_td_rowspan} className={" align-middle text-center"}>
                            <h5>{hilite(String(prod.IDProduktu) || "", filters.product)}</h5>
                            {hilite(prod.product_code || "", filters.product)} <br />
                            {hilite(prod.product_name || "", filters.product)}
                          </td>
                          <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">
                            {hilite(prod.centre || "", filters.centre)}
                          </td>
                          <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">
                            {prod.package}
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      {idxBatchInWh === 0 ? (
                        <>
                          <td rowSpan={batch.batch_td_rowspan} className="align-middle">
                            {" "}
                            {filters.batch.length > 0 ? hilite(batch.alf_batch || "", filters.batch) : batch.alf_batch}
                          </td>
                          <td rowSpan={batch.batch_td_rowspan} className="text-end align-middle">
                            {batch.expiry ? (
                              date_formatCZ(batch.expiry)
                            ) : (
                              <span className="text-danger">{t("expiry-missing")}!</span>
                            )}
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      <td className="text-center align-middle">
                        <span className={class_hi_wh}>{batchInWh.warehouseId}</span>
                      </td>
                      <td className="text-center align-middle">
                        <span className={class_hilite_MJ}>
                          {format_amount(batchInWh.alf_batch_sum)} {batchInWh.alf_package_unit}
                        </span>
                      </td>
                      <td className="text-center align-middle">
                        <span className={class_hi_kg}>
                          {format_amount(batchInWh.alf_batch_weight_kg)}&nbsp;{t("prod-kg")}
                        </span>
                      </td>
                      <td className="text-end align-middle">
                        <span className={class_hi_count}>
                          {batchInWh.alf_package_count} {t("piece_short")}
                        </span>
                      </td>
                      <td className="text-end align-middle">
                        {batchInWh.alf_last_update ? date_formatCZ(batchInWh.alf_last_update) : "-"}
                      </td>
                    </tr>
                  );
                });
              });
            })}
          </tbody>
        ) : (
          <tbody>
            {show_stocks.map(function (prod, idx) {
              const bg = idx % 2 === 0 ? "" : "beGray3";
              return prod.batches.map(function (batch, idxBatch) {
                const batchesLength = prod.batches.length;
                return (
                  <tr key={idxBatch} className={bg}>
                    {idxBatch === 0 ? (
                      <>
                        <td rowSpan={batchesLength} className="align-middle text-center">
                          <h5>{prod.IDProduktu}</h5>
                          {prod.product_code} <br />
                          {prod.product_name}
                        </td>
                        <td rowSpan={batchesLength} className="align-middle text-center">
                          {prod.package}
                        </td>
                        {showOnePackContent ? (
                          <>
                            <td rowSpan={batchesLength} className="align-middle text-center">
                              {format_amount(prod.package_amount)} {prod.package_unit}
                            </td>
                            <td rowSpan={batchesLength} className="align-middle text-center">
                              {format_amount(prod.package_weight_kg)}&nbsp;{t("prod-kg")}
                            </td>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <td className="align-middle">
                      {filters.batch.length > 0 ? hilite(batch.alf_batch || "", filters.batch) : batch.alf_batch}
                    </td>
                    <td className="text-center align-middle">
                      {format_amount(batch.alf_batch_sum)} {batch.alf_package_unit}
                    </td>
                    <td className="text-center align-middle">
                      {format_amount(batch.alf_batch_weight_kg)}&nbsp;{t("prod-kg")}
                    </td>
                    <td className="text-end align-middle">
                      {batch.alf_package_count} {t("piece_short")}
                    </td>
                    {dataType === "twist" ? (
                      <td className="text-end align-middle">
                        {batch.alf_last_update ? date_formatCZ(batch.alf_last_update) : "-"}
                      </td>
                    ) : (
                      <td className="align-middle text-center">
                        {String((batch.alf_locations || []).map((m) => " " + m))}
                      </td>
                    )}
                    {idxBatch === 0 && showSum ? (
                      <>
                        <td rowSpan={batchesLength} className="align-middle text-center">
                          {format_amount(prod.sum_of_batches_amounts)} {prod.package_unit}
                        </td>
                        <td rowSpan={batchesLength} className="align-middle text-center">
                          {format_amount(prod.product_sum_kg)}&nbsp;{t("prod-kg")}
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              });
            })}
          </tbody>
        )}
      </Table>
    </>
  );
}

interface WarehouseStocksFiltersProps {
  showWarehouseAndExpiry: boolean;
  showOnePackContent: boolean;
  showSum: boolean;
  dataType: WarehouseStocksDisplayProps["dataType"];
  whDictionary: { [key: string]: string };
}

interface WarehouseStocksFilterValues {
  problematic: boolean | null;
  orderBy?: string;
  product?: string;
  centre?: string;
  batch?: string;
  count?: string;
  amount?: string;
  amountKg?: string;
  total?: string;
  totalKg?: string;
  superTotal?: string;
  superTotalKg?: string;
  warehouses?: string[];
}

function WarehouseStocksFilters({
  showWarehouseAndExpiry,
  showOnePackContent,
  showSum,
  dataType,
  whDictionary,
}: WarehouseStocksFiltersProps) {
  const { t } = useTranslation();
  const { register } = useFormContext<WarehouseStocksFilterValues>();

  return (
    <>
      <tr>
        {showWarehouseAndExpiry ? (
          <th rowSpan={3} className="text-center pb-2">
            <p className="mb-2">{t("problematic")}</p>
            <BooleanDropdownRhf variant="product-checks" name="problematic" />
          </th>
        ) : (
          <></>
        )}
        <th rowSpan={3} className="align-middle text-center pb-2">
          <div className="mb-1">
            {t("not-prod_id")}&nbsp;
            <SortIconsRhf columnName="IDProduktu" filterFieldName="orderBy" numeric />
            {t("prod-code")}
            <br />
            {t("prod-name")}
          </div>
          <Form.Group controlId="filterCode" className="mb-0">
            <Form.Control type="text" placeholder="&#128269;" {...register("product")} />
          </Form.Group>
        </th>
        {showWarehouseAndExpiry ? (
          <th rowSpan={3} className="text-center pb-1">
            {t("biz-centre")}
            <Form.Group controlId="filterCentre">
              <Form.Control type="text" placeholder="&#128269;" {...register("centre")} />
            </Form.Group>
          </th>
        ) : (
          <></>
        )}
        <th rowSpan={3} className="text-center pb-2">
          {t("ord-pack")}
        </th>
        {showOnePackContent ? (
          <th colSpan={2} rowSpan={2} className="text-center ">
            {t("prod-1_pack_content")} 🧴
          </th>
        ) : (
          <></>
        )}
        <th colSpan={showWarehouseAndExpiry ? 7 : 5} className="text-center">
          {t("prod-found_batches")}
        </th>
        {showSum ? (
          <th colSpan={2} rowSpan={2} className="text-center ">
            {t("product_total")}
          </th>
        ) : (
          <></>
        )}
      </tr>
      <tr>
        <th rowSpan={2} className="">
          {" "}
          <p className="mb-1">{t("ord-batch")}</p>
          <Form.Group controlId="filterCode" className="mb-1">
            <Form.Control type="text" placeholder="&#128269;" {...register("batch")} />
          </Form.Group>
        </th>
        {showWarehouseAndExpiry ? (
          <>
            <th rowSpan={2} className="text-end pb-2">
              {t("ord-expiry")}
              <br />
              <SortIconsRhf columnName="expiry" filterFieldName="orderBy" numeric />
            </th>
            <th rowSpan={2} className="text-center">
              {t("warehouse")}
              <MultipleSelectRhf values={whDictionary} id="warehouses" withoutNumber />
            </th>
          </>
        ) : (
          <></>
        )}
        <th colSpan={2} className="text-center">
          {t("prod-amount_per_batch")}
        </th>
        <th rowSpan={2} className="text-end ">
          <Form.Group controlId="filtercOUNT" className="mb-0">
            <Form.Label className="mb-1"> {t("prod-pack_count")}</Form.Label>
            <Form.Control
              className="mb-1"
              type="text"
              placeholder="&#128269; > < ="
              {...register("count", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
        {dataType === "twist" || dataType === "expirations" ? (
          <th rowSpan={2} className="text-end pb-2">
            {t("prod-date_of_last")} <br /> {t("prod-status_update")} <br />
            <SortIconsRhf columnName="alf_last_update" filterFieldName="orderBy" numeric />
          </th>
        ) : (
          <th rowSpan={2} className="pb-2 text-center">
            {t("prod-wh_location")}
          </th>
        )}
      </tr>
      <tr>
        {showOnePackContent ? (
          <>
            <th className="text-center">
              <Form.Group controlId="filterAmountMJ" className="mb-0">
                <Form.Label className="mb-1">
                  {t("measure_unit")} <MyInfo text="mj_means_warehouse_unit" />
                </Form.Label>
                <Form.Control
                  className="mb-1"
                  type="text"
                  placeholder="&#128269; > < ="
                  {...register("amount", { pattern: /^[=<>]?[0-9]+$/ })}
                />
              </Form.Group>
            </th>
            <th className="text-center">
              <Form.Group controlId="filterAmountKG" className="mb-0">
                <Form.Label className="mb-1">{t("prod-kg")}</Form.Label>
                <Form.Control
                  className="mb-1"
                  type="text"
                  placeholder="&#128269; > < ="
                  {...register("amountKg", { pattern: /^[=<>]?[0-9]+$/ })}
                />
              </Form.Group>
            </th>
          </>
        ) : (
          <></>
        )}
        <th className="text-center">
          <Form.Group controlId="filterTotal" className="mb-0">
            <Form.Label className="mb-1">
              {" "}
              {t("measure_unit")} <MyInfo text="mj_means_warehouse_unit" />
            </Form.Label>
            <Form.Control
              className="mb-1"
              type="text"
              placeholder="&#128269; > < ="
              {...register("total", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>
        <th className="text-center">
          <Form.Group controlId="filterTotalKG" className="mb-0">
            <Form.Label className="mb-1"> {t("prod-kg")}</Form.Label>
            <Form.Control
              className="mb-1"
              type="text"
              placeholder="&#128269; > < ="
              {...register("totalKg", { pattern: /^[=<>]?[0-9]+$/ })}
            />
          </Form.Group>
        </th>

        {showSum ? (
          <>
            <th className="text-center">
              <Form.Group controlId="filterSuperTotal" className="mb-0">
                <Form.Label className="mb-1">
                  {" "}
                  {t("measure_unit")} <MyInfo text="mj_means_warehouse_unit" />
                </Form.Label>
                <Form.Control
                  className="mb-1"
                  type="text"
                  placeholder="&#128269; > < ="
                  {...register("superTotal", { pattern: /^[=<>]?[0-9]+$/ })}
                />
              </Form.Group>
            </th>

            <th className="text-center">
              <Form.Group controlId="filterSuperTotalKG" className="mb-0">
                <Form.Label className="mb-1"> {t("prod-kg")}</Form.Label>
                <Form.Control
                  className="mb-1"
                  type="text"
                  placeholder="&#128269; > < ="
                  {...register("superTotalKg", { pattern: /^[=<>]?[0-9]+$/ })}
                />
              </Form.Group>
            </th>
          </>
        ) : (
          <></>
        )}
      </tr>
    </>
  );
}
