/**
* Second step of stock notification process
* @module orders/stock-notify-step2
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React from 'react';
import { Table, Image, Form, Row, Col, Button, Badge, Alert, InputGroup, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { registerLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';
import { PIN } from "../comp/pin";
import {
    date_isHoliday, date_isToday, date_isTomorrow, date_getNextWorkday,
    date_formatCZ, date_formatISO, date_isValidObject, getDateInNMonths,
    date_plusNyears, date_isAtMostNextWorkday
} from '../lib/date-utils';
import { DatePickerCustom } from '../comp/DatePicker';
import { Texts } from './order-purchase';
import { MonthYearDropdown } from '../comp/month-year-dropdown';
import { platneRoky, monthsOfYear } from '../lib/month-year-utils'
import { ErrorWrap } from '../comp/errorwrap';
import { is_warehouse_defined, warehouses_names_ids_separe } from '../lists/warehouses-defs';
import {
    check_sn_amount, check_sn_batch,
    // is_sn_batch_check_special, reinterpret_check_sn_batch 
} from './stock-notification-checks';
import { useTranslation } from 'react-i18next';
import Fraction from 'fraction.js';

registerLocale('cs', cs);

//seen all - OK


/**
 * Makes sum of all batches in kg within one stockin item
 * 
 * @param {number} item_id - id of current item
 * @param {any} item - one item from notifyItems - item with user inputs with amounts and batches
 * @param {any} NO_items - all items shown at NOdetail
 * @returns {fraction} - sum of all batches within one item in kg
 */
function get_sn_item_weight_in_kg(item_id, item, NO_items) {
    // matches current item_id with item dictionary from NO (the dictionary has all detail, such as npj_HmotnostMj) 
    const NO_item = NO_items.find((itm) => String(itm.nop_IDPolozky) === String(item_id)); //this has to be ==
    const batches = item.batches;
    const sales_unit_kg = new Fraction(NO_item.npj_HmotnostMj); //famous "how much sales unit weights in kg"
    const item_in_kg = batches //its array of dictionaries - reduce: for every dict in this array makes fraction from batch.amount, if possible, returns add amount*sales_unit_kg otherwise fraction(0)
        .reduce(function (acc, batch) {
            try {
                const amount_fraction = new Fraction(batch.amount);
                return acc.add(amount_fraction.mul(sales_unit_kg));
            } catch (ex) {
                return new Fraction(0);
            }
        }, new Fraction(0));
    return item_in_kg;
}

/**
 * Returns weight in kg of whole stockin
 * 
 * @param {any} items - all items shown at NOdetail - sort of lookup
 * @param {dictionary} pickedItems - ids of picked items
 * @param {dictionary} notifyItems - ids of all items as key with batches and expiry as value -> 63400: {batches: [{}], expiry: ""}
 * @returns {fraction}
 */
function get_stock_notification_amount_in_kg(items, pickedItems, notifyItems) {
    const pickedNotifyItems = Object
        .keys(notifyItems) //gives me all batches in stockin 
        .filter((id) => pickedItems[id]) // filter picked ones from array of all
        .reduce((acc, id) => ({ ...acc, [id]: notifyItems[id] }), {});  // reduce creates dictionary of picked notifyItems
    const weight_in_kg = Object
        .keys(pickedNotifyItems) // gives array of ids of picked items
        .reduce((acc_weight_in_kg, item_id) => //accumulated weight + weight of one item 
            acc_weight_in_kg.add(get_sn_item_weight_in_kg(item_id, notifyItems[item_id], items))
            ,
            new Fraction(0)); //starts with fraction 0
    return weight_in_kg;
}

/**
 * Return sum of per one item. It gets batches -> parseFloat -> remove isNan -> sum.
 * 
 * @param {any} batches - batches per one item
 * @returns {number}
 */
function sum_amounts_in_item(batches) {
    const result = batches.map(x => parseFloat(x.amount)).filter((x) => !isNaN(x)).reduce((partial_sum, a) => partial_sum + a, 0);
    return result;
}

/**
 * Checks whether storage and purchase unit differs, if yes, we multiply remaining with npj_HmotnostMj
 * 
 * @param {string} remaining - how much remains on item
 * @param {string} npj_HmotnostMj - weight of storage unit in kg
 * @param {string} nop_KodMj - purchase unit
 * @param {string} p_KodMjSkl - storage unit
 * @returns {fraction}
 */
function remaining_in_right_unit(remaining, npj_HmotnostMj, nop_KodMj, p_KodMjSkl) {
    const different_units = nop_KodMj.trim().toLowerCase() !== p_KodMjSkl.trim().toLowerCase();
    return different_units ? Fraction(remaining).mul(Fraction(npj_HmotnostMj)) : Fraction(remaining);
}

/**
 * Compares sum on one item with remaining material on item
 * 
 * @param {string} remaining - how much remains on item
 * @param {any} batches - batches for particular item
 * @param {string} npj_HmotnostMj - weight of storage unit in kg
 * @param {string} nop_KodMj - purchase unit
 * @param {string} p_KodMjSkl - storage unit
 * @returns {boolean}
 */
function sum_in_item_is_over_remaining(remaining, batches, npj_HmotnostMj, nop_KodMj, p_KodMjSkl) {
    const sum_amounts = sum_amounts_in_item(batches);
    const right_remaining = remaining_in_right_unit(remaining, npj_HmotnostMj, nop_KodMj, p_KodMjSkl);
    return Fraction(sum_amounts) > Fraction(right_remaining);
}

/**
 * Counts weight of one package in purchase unit = package_weight_kg_wh / storage unit weight in kg;
 * 
 * @param {*} package_weight_kg_wh - how much kg package has
 * @param {*} pj_HmotnostMj - how much kg storage unit has
 * @returns {string}
 */
function get_package_weight_purchase_unit(package_weight_kg_wh, pj_HmotnostMj) {
    const result = Fraction(package_weight_kg_wh).div(Fraction(pj_HmotnostMj));
    return result.toString();
}

//vycházím z pole batchů (je jedno, jestli jsou to slovníky nebo něco jiné) --- batches na úrovni item je moje pole a budu ho mapovat pomocí reducu
// a díky tomu si mohu v rámci reducu držet libovolný počet dalších akumulátorů
// redukuju batches v items a podle výsledku přidávám klíč overflow do toho

/**
 * Goes thtough batches and enriches it with key called sum_overflow: true/false in case remaining amount is over
 * 
 * @param {any} batches - array of dictionaries like {amount: "", batch: null, expiry:""}
 * @param {number} remaining_in_right_unit - remaining amount in purchase unit (because amount is in purchase unit)
 * @returns {dictionary}
 */
function get_batches_with_overflow_tracking(batches, remaining_in_right_unit) {
    const batches_with_sum = batches
        .reduce((acc, myBatch) =>
            [acc[0] + parseFloat(myBatch.amount) || 0, //k 0 přičti hodnotu myBatch.amount
            [...acc[1], { ...myBatch, sum_overflow: (acc[0] + parseFloat(myBatch.amount) || 0) > parseFloat(remaining_in_right_unit) }]], //vyspreaduj co máš v poli (nahradit slovníkem) //vezme současné pole a přidá nakonec jeden element
            [0, []]); //začínám s [0, []] //budu začínat na prázdném poli a tam budu dávat všechny projitý batche
    return batches_with_sum[1];
}

/**
 * Explanation and warning logic regarding too early eta dates.
 * 
 * @param {object} date - eta date as object
 * @returns {string} - text explaining possible issues with ETA date
 */
function early_ETA_date(date) {
    const today = new Date();
    const eta = new Date(date);
    const todayIsFriday = today.getDay() === 5;
    const etaToday = date_isToday(eta);
    const etaTomorrow = date_isTomorrow(eta);
    const after14 = today.getHours() >= 14;
    const nextMonday = today;
    nextMonday.setDate(nextMonday.getDate() + 3); // only valid if todayIsFriday
    const weekDay = eta.getDay();
    const isWeekend = weekDay === 0 || weekDay === 6;
    const isHoliday = date_isHoliday(eta);

    if (etaToday) {
        return 'ord-eta_today';
    } else if ((etaTomorrow && after14) || (eta === nextMonday && todayIsFriday && after14)) {
        return 'ord-eta_tomorrow_and_after_14h_today';
    } else if (isWeekend) {
        return "ord-eta_weekend";
    } else if (isHoliday) {
        return "ord-eta_holiday";
    } else {
        return "";
    }
}

/**
 * Formating ISO expiration to czech date, condition for special non date cases
 * 
 * @param {any} entry - string or date object, there are special cases: "doplní sklad", "neznámé"
 * @returns {component}
 */
export function expiryISOtoCZdate(entry, t) {
    if (entry === t('ord-filled_by_wh') || entry === t('unknown4')) {
        return entry;
    } else {
        return date_formatCZ(entry);
    }
}

//get unlocked items - used for number unlocked - gives states 0 - locked, 1 - entering pin, 2 - unlocked, 3 - unlocked by exceptional case
function getUnlocking(nop_IDPolozky, unlockedItems) {
    return unlockedItems[nop_IDPolozky] || 0;
}

/**
 * function updates expiry based on pwc_id and choosen eta
 * 
 * @param {object} newEtaDate - value that user choosen in DatePicker
 * @param {function} setEtaDate - update eta
 * @param {any} items - items in NO
 * @param {function} setExpirations - updates expirations based on pwc_id
 */
function changeEtaDate(newEtaDate, setEtaDate, items, setExpirations, t) {
    setEtaDate(newEtaDate);
    var expirations = {};
    items.map(function (item) {
        if ((item.pwc_id === 3) || (item.pwc_id === 2)) {
            const expiryMonths = item.pwc_months || 0;
            const eta = new Date(newEtaDate);
            const expiration = eta.setMonth(eta.getMonth() + expiryMonths);
            return expirations[item.nop_IDPolozky] = date_formatISO(new Date(expiration));
        } else if (item.pwc_id === 1) {
            return expirations[item.nop_IDPolozky] = t('ord-filled_by_wh');
        } else {
            return expirations[item.nop_IDPolozky] = t('unknown4');
        }
    });
    setExpirations(expirations);
}

/**
 * 
 *  Finální disabled na 3. krok - mapuju items a vadí mi tam error, vadí mi když item není unlocked a přitom batch je neznámá
 * 
 * @param {any} preprocessedItems - items enriched with batches and checks results
 * @param {boolean} isItemUnlocked - whether unknown-batch is allowed on this item
 * @returns {boolean}
 */
function at_least_one_error_somewhere(preprocessedItems, isItemUnlocked) {
    //console.log(preprocessedItems);
    return preprocessedItems.filter(function (item) {
        if ((!isItemUnlocked(item.nop_IDPolozky)) && (item.batches.filter((batchLine) => batchLine.batch === 'neznámá').length > 0)) {
            return true;
        }
        return item.hasError;
    }).length !== 0;
}

/**
 * True if there is exactly one emptry batch per item
 * 
 * @param {any} item - one item in stockin
 * @returns {boolean}
 */
function exactly_one_empty_batch(item) {
    return item.batches.filter((batch) => (batch.batch === '' || batch.batch === null)).length === 1;
}


/**
 * Preprocessing of items, returns preprocess items with errors results on batch and item level and overall error results for continue button
 * 
 * @param {any} items - all items shown at NOdetail - sort of lookup
 * @param {dictionary} pickedItems - ids of picked items
 * @param {dictionary} notifyItems - ids of all items as key with batches and expiry as value -> 63400: {batches: [{}], expiry: ""}
 * @param {object} etaDate - ETA as date object
 * @param {dictiona} unlockedItems - state - which items are unlocked
 * @param {function} getNotifyItemBatches - returns batches of one item
 * @returns {array}
 */
function stockin_items_preprocessing_checking(items, pickedItems, etaDate, notifyItems, unlockedItems, getNotifyItemBatches, t) {
    const disabledForms = etaDate === "";
    const selectedItems = items.filter((item) => !!pickedItems[item.nop_IDPolozky]);
    const etaTomorrowOrNextWorkday = date_isAtMostNextWorkday(etaDate);

    const preprocessedItemsStage1 = selectedItems
        .map(function (item) {
            const batches = getNotifyItemBatches(item.nop_IDPolozky);
            const item_sum = sum_amounts_in_item(batches);
            const sum_amount_over_remaining = sum_in_item_is_over_remaining(item.nop_ZbyvaPrijmout, batches, item.npj_HmotnostMj, item.nop_KodMj, item.p_KodMjSkl);
            // Item unit weight  - zpětný přepočet na nákupní jednotku: NO202197700002 - tohle je v litrech - reference
            const pack_weight_item_unit = get_package_weight_purchase_unit(item.package_weight_kg_wh, item.pj_HmotnostMj);
            const remaining_in_purchase_unit = remaining_in_right_unit(item.nop_ZbyvaPrijmout, item.npj_HmotnostMj, item.nop_KodMj, item.p_KodMjSkl);
            const batches_with_overflow_tracking = get_batches_with_overflow_tracking(batches, remaining_in_purchase_unit);

            // Batches preprocessing
            const preprocessedBatches = batches_with_overflow_tracking
                .map(function (batch) {
                    const [rightInputAmount, errorMessageAmount, cleanedInputAmount] = check_sn_amount(batch.amount, item, t);
                    const [rightInputBatch, errorMessBatch] = check_sn_batch(batch.batch, item, etaDate, cleanedInputAmount);
                    return {
                        ...batch,
                        errorMessageAmount: errorMessageAmount, //per batchLine
                        rightInputAmount: rightInputAmount, //per batchLine
                        rightInputBatch: rightInputBatch, //per batchLine
                        errorMessBatch: errorMessBatch, //per batchLine
                    };
                });

            const error_occured = preprocessedBatches.filter((x) => (!x.rightInputAmount || !x.rightInputBatch)).length > 0;
            // Empty errors
            const empty_batches = batches.filter((b) => (b.batch === null || b.batch === "")).length > 0; //slouží k nepuštění dál, když je to prázdný
            // console.log("empty batches" + empty_batches);
            //empty amounts
            const empty_amounts = batches.filter((am) => am.amount === "").length > 0; //slouží k nepuštění dál, když je to prázdný
            // Error status
            const hasError = empty_batches || empty_amounts || error_occured || sum_amount_over_remaining; //all possible errors
            // console.log("hasError:"  + hasError);   
            // console.log("error_occured" + error_occured);

            // Modified item
            return {
                ...item,
                batches: batches,
                sum_number: item_sum, // sum of all batches
                sum_amount_over: sum_amount_over_remaining, // on one item
                hasError: hasError, //item has error
                preprocessedBatches: preprocessedBatches, // batches with checks results
                pack_weight_item_unit_number: pack_weight_item_unit // weight of one item in storage unit - function external?
            };
        });

    // const stocking_weight_over_5t = get_stock_notification_amount_in_kg(items, pickedItems, notifyItems) >= Fraction(5000);
    // const exceptionCase = etaTomorrowOrNextWorkday || stocking_weight_over_5t;
    const exceptionCase = false; // excptions to starting stockin are turned off

    //je item odemčená? pokud je ve stavu 2 nebo je položka ve stavu 3 a je na ní exceptionalCase
    function isItemUnlocked(nop_IDPolozky) {
        return (unlockedItems[nop_IDPolozky] === 2) || ((unlockedItems[nop_IDPolozky] === 3) && exceptionCase);
    }

    /* přidává klíče k items: 
    batches_disabled když je odemčeno nebo pwc_id 3 
    disabled_adding (šarže) když je odemčeno nebo pwc_id 3 
    disabled_pin_change když je pwc_id 3 - stav, že šarže i expirace je volitelná, takže není co odemykat - šarže je disabled nevyplňujeme a expirace je disabled natvrdo
    */
    const preprocessedItems = preprocessedItemsStage1.map(function (item) {
        const batches_disabled = disabledForms || item.pwc_id === 3;
        const only_one_empty_batch = exactly_one_empty_batch(item);
        return {
            ...item,
            batches_disabled: batches_disabled,
            only_one_empty_batch: only_one_empty_batch,
        };
    });

    const cannotGoOn = at_least_one_error_somewhere(preprocessedItems, isItemUnlocked);
    //console.log(cannotGoOn);

    return [preprocessedItems, disabledForms, cannotGoOn, exceptionCase];
}


/**
 * Second step of stockin with inputs for ETA, amounts, batches, optionaly expiry.
 * 
 * @param {string} kodDokladu - code of the order
 * @param {any} items - all items from order
 * @param {object} etaDate - etaDate state, stored in mother component in purpose
 * @param {function} setEtaDate - function to update etaDate
 * @param {dictionary} pickedItems - dictionary with ids of picked items
 * @param {function} getNotifyItemExpiry 
 * @param {function} addNotifyItemBatch
 * @param {function} getNotifyItemBatches
 * @param {function} setNotifyItemAmount
 * @param {function} setNotifyItemBatch
 * @param {function} delNotifyItemBatch
 * @param {function} unlockedItems - state - which items are unlocked
 * @param {function} setUnlockedItems - updating unlockedItems
 * @param {function} setExpirations
 * @param {function} setFinalResult
 * @param {array} texts - text information to display during the stockin process ("neutralizace" and such)
 * @param {function} setNotifyItemExpiration
 * @returns {component}
 */
export function StockNotifi2({
    kodDokladu, items, etaDate, setEtaDate, pickedItems,
    getNotifyItemExpiry,
    addNotifyItemBatch, getNotifyItemBatches,
    setNotifyItemAmount, setNotifyItemBatch,
    delNotifyItemBatch, unlockedItems, setUnlockedItems,
    setExpirations, notifyItems,
    setFinalResult, texts, setNotifyItemExpiration,
    updateUnknownBatch, updateKnownBatch
}) {
    const { t } = useTranslation();

    const [preprocessedItems, disabledForms, cannotGoOn, exceptionCase] =
        stockin_items_preprocessing_checking(items, pickedItems, etaDate, notifyItems, unlockedItems, getNotifyItemBatches, t);

    return (
        <ErrorWrap>
            <Row>
                <Col xs="auto">
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail" style={{ maxWidth: "600px" }}>
                        <Form.Label column sm="auto">
                            <h4 className="mb-0">🕒 ETA</h4>
                            ({t('ord-estimated_delivery_time')})
                        </Form.Label>
                        <Col>
                            <DatePickerCustom onChange={(etaDate) => changeEtaDate(etaDate, setEtaDate, items, setExpirations, t)} minDate={new Date()} maxDate={getDateInNMonths()}
                                value={etaDate}
                                holidays={[]} />
                            <Form.Text className="text-muted">
                                {etaDate === "" ? t('ord-fill_first') : ""}
                            </Form.Text>
                        </Col>
                    </Form.Group>
                    <h4 className="mb-0 mt-2"><Image src="/img/warehouse.svg" height="30" /> {t('warehouse')} {preprocessedItems[0] ? warehouses_names_ids_separe[preprocessedItems[0].nop_KodKnihySkladu] : ""}</h4>
                </Col>
                <Col>
                    {early_ETA_date(etaDate) !== "" ?
                        <Alert variant="warning" style={{ maxWidth: "650px" }}>
                            {t(early_ETA_date(etaDate))}
                            <br />
                            <Button className="mt-2" variant="light" size="sm" onClick={() => changeEtaDate(date_getNextWorkday(etaDate), setEtaDate, items, setExpirations, t)}>{t('ord-set_eta_next_workday')}</Button>
                        </Alert>
                        : ""}
                </Col>
                <Col xs="4">
                    <Texts texts={texts} />
                </Col>
            </Row>
            <h5 className="mt-5">{t('ord-notifyied_items')}</h5>
            <Table borderless size="sm" className="mb-0 bg-white" >
                <thead className="beGray">
                    <tr>
                        <th width="3%">{t('prod-id')}</th>
                        <th width="10%" className='text-center'>{t('name')}</th>
                        <th width="5%" className="text-center">{t('ord-remaining')}</th>
                        <th width="5%" className="text-center">{t('ord-1_pack_size')}</th>
                        <th width="20%">{t('ord-notified_amount')}</th>
                        <th className='text-end'>{t('measure_unit')}&nbsp;</th>
                        <th width="15%">{t('ord-batch')}</th>
                        <th width="11%" className='text-center' >{t('ord-expiry')}</th>
                        <th width="9%"></th>
                        <th width="7%" ></th>
                        <th width="10%" >{t('ord-items_action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {preprocessedItems.map(function (item, idx) {
                        return <SN2Item key={idx} item={item} addNotifyItemBatch={addNotifyItemBatch} setNotifyItemAmount={setNotifyItemAmount} disabledForms={disabledForms}
                            setNotifyItemBatch={setNotifyItemBatch} delNotifyItemBatch={delNotifyItemBatch}
                            unlocked={getUnlocking(item.nop_IDPolozky, unlockedItems)}
                            getNotifyItemExpiry={getNotifyItemExpiry} setNotifyItemExpiration={setNotifyItemExpiration}
                            exceptionCase={exceptionCase}
                            unlockedItems={unlockedItems} setUnlockedItems={setUnlockedItems}
                            updateUnknownBatch={updateUnknownBatch} updateKnownBatch={updateKnownBatch}
                        />
                    })}
                </tbody>
            </Table>
            <span className="text-muted mb-0"><Badge pill bg="secondary">i</Badge>&nbsp;{t('ord-amounts_and_batches_are_mandatory')}.</span>

            <Row className="mt-5">
                <Col>
                    <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu + "/old/notification/1" }}>
                        <Button>{t('ord-back_to_1st_step')}</Button>
                    </LinkContainer>
                </Col>
                <Col className="text-end">
                    <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu + "/old/notification/3" }}>
                        <Button onClick={() => setFinalResult(null)} disabled={disabledForms || cannotGoOn}>{t('ord-sn_summary')}</Button>
                    </LinkContainer>
                </Col>
            </Row>
        </ErrorWrap>
    );
}

/**
 * One items of stockin
 * 
 * @param {any} item 
 * @param {function} addNotifyItemBatch
 * @param {function} setNotifyItemAmount
 * @param {function} setNotifyItemBatch
 * @param {function} delNotifyItemBatch
 * @param {function} getNotifyItemExpiry
 * @param {function} setNotifyItemExpiration
 * @param {boolean} disabledForms
 * @param {number} unlocked - unlocking state of PIN for whole item
 * @returns {component}
 */
function SN2Item({
    item, addNotifyItemBatch, setNotifyItemAmount, setNotifyItemBatch, delNotifyItemBatch,
    getNotifyItemExpiry, setNotifyItemExpiration,
    disabledForms, unlocked,
    unlockedItems, setUnlockedItems, exceptionCase,
    updateUnknownBatch, updateKnownBatch
}) {
    const batches = item.preprocessedBatches;
    return (
        <>
            {batches.map((batch, index) =>
                <SN2Batch key={index} item={item} batch={batch} index={index}
                    addNotifyItemBatch={addNotifyItemBatch} setNotifyItemAmount={setNotifyItemAmount}
                    unlocked={unlocked}
                    setNotifyItemBatch={setNotifyItemBatch} delNotifyItemBatch={delNotifyItemBatch}
                    disabledForms={disabledForms}
                    getNotifyItemExpiry={getNotifyItemExpiry}
                    setNotifyItemExpiration={setNotifyItemExpiration}
                    exceptionCase={exceptionCase}
                    unlockedItems={unlockedItems} setUnlockedItems={setUnlockedItems}
                    updateUnknownBatch={updateUnknownBatch} updateKnownBatch={updateKnownBatch}
                />)}
        </>
    );
}


/**
 * Single batch within item in stockin
 * 
 * @param {any} item 
 * @param {any} batch
 * @param {any} index
 * @param {function} addNotifyItemBatch
 * @param {function} setNotifyItemAmount
 * @param {function} setNotifyItemBatch
 * @param {function} delNotifyItemBatch
 * @param {function} getNotifyItemExpiry
 * @param {function} setNotifyItemExpiration
 * @param {boolean} disabledForms
 * @param {number} unlocked - unlocking state of PIN for whole item
 * @param {boolean} exceptionCase - true if PIN isnt required
 * @returns {component}
 */
function SN2Batch({
    item, batch, index, addNotifyItemBatch, setNotifyItemAmount, disabledForms, setNotifyItemBatch,
    delNotifyItemBatch,
    getNotifyItemExpiry, setNotifyItemExpiration, unlocked,
    exceptionCase, unlockedItems, setUnlockedItems,
    updateUnknownBatch, updateKnownBatch
}) {
    const { t } = useTranslation();

    const is_item = index === 0;
    const batches = item.preprocessedBatches;
    const is_last = index === batches.length - 1;
    const batches_disabled = item.batches_disabled; // nevyplněná eta nebo pwc_id=3
    const sum_number = item.sum_number;
    const sum_amount_over = item.sum_amount_over;
    const multipleBatches = batches.length > 1;
    const borderBottomLast = is_last ? ' border-bottom ' : "";
    const formsCentering = !multipleBatches ? " align-middle " : "";

    return (
        <tr className=''>
            {is_item ? <td className=" border-bottom border-start align-middle" rowSpan={batches.length}>{item.p_k_IDProduktu}</td> : <></>}
            {is_item ? <td className=" border-bottom align-middle" rowSpan={batches.length}>{item.nop_NazevProduktu}</td> : <></>}
            {is_item ? <td className=" border-bottom align-middle text-center" rowSpan={batches.length}>{item.nop_ZbyvaPrijmout}&nbsp;{item.nop_KodMj}</td> : <></>}
            {is_item ? <td className=" border-bottom align-middle text-center" rowSpan={batches.length}>{item.pack_weight_item_unit_number}&nbsp;{item.p_KodMjSkl}</td> : <></>}
            <td colSpan={2} className={borderBottomLast + formsCentering}>
                <InputGroup hasValidation size="sm">
                    <Form.Control type="text" value={batch.amount}
                        onChange={(ev) => setNotifyItemAmount(item.nop_IDPolozky, index, ev.target.value, exceptionCase)}
                        placeholder="" disabled={disabledForms} isValid={batch.rightInputAmount} isInvalid={batch.rightInputAmount === false || batch.sum_overflow === true} />
                        <InputGroup.Text>{item.nop_KodMj}</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">{batch.errorMessageAmount}</Form.Control.Feedback>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                </InputGroup>
                {is_last ?
                    <>
                        {batches.length > 1 ? <small className={sum_amount_over ? "text-danger fw-bold" : ""}>{t('ord-amount_total')}: {sum_number}. </small> : ""}
                        {sum_amount_over ? <small className="text-danger fw-bold">{t('ord-remaining_is')} {item.nop_ZbyvaPrijmout} {item.nop_KodMj.trim()}{t('ord-lower_sn_amount')}</small> : ""}
                    </>
                    : ""}
            </td>
            <td className={borderBottomLast + formsCentering}>
                <Form.Group controlId="sarze" key={index} className='mb-0'>
                    <Form.Control type="batch" size="sm" value={batch.batch || ""}
                        onChange={(ev) => setNotifyItemBatch(item.nop_IDPolozky, index, ev.target.value)}
                        placeholder="" disabled={batches_disabled || batch.batch === 'neznámá'} isValid={batch.rightInputBatch} isInvalid={batch.rightInputBatch === false} />
                    <Form.Control.Feedback type="invalid">{t(batch.errorMessBatch)}</Form.Control.Feedback>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                </Form.Group>
            </td>
            <td className={borderBottomLast + formsCentering + "text-end"}>
                <ExpirySetting item={item} batch={batch} index={index} getNotifyItemExpiry={getNotifyItemExpiry}
                    setNotifyItemExpiration={setNotifyItemExpiration} disabled_adding={batches_disabled} />
            </td>
            <td className={borderBottomLast + formsCentering}>
                <ExpiryDropdown item={item} batch={batch} index={index} setNotifyItemExpiration={setNotifyItemExpiration}
                    disabled_adding={batches_disabled} />
            </td>
            <td className={borderBottomLast + formsCentering}>{batches.length > 1 ?
                <Button variant="light" size="sm" className="d-block" onClick={() => delNotifyItemBatch(item.nop_IDPolozky, index)}>{t('ord-delete_batch')}</Button>
                : ""}
            </td>
            {is_item ?
                <td rowSpan={batches.length} className="border-bottom">
                    <PIN nop_IDPolozky={item.nop_IDPolozky} disabledForms={batches_disabled || !item.only_one_empty_batch}
                        unlocked={unlocked}
                        exceptionCase={exceptionCase}
                        unlockedItems={unlockedItems} setUnlockedItems={setUnlockedItems}
                        updateUnknownBatch={updateUnknownBatch} updateKnownBatch={updateKnownBatch} />
                    <Button variant="info" size="sm" className=" w-100 my-2 mb-1" disabled={batches_disabled}
                        onClick={() => addNotifyItemBatch(item.nop_IDPolozky, item.pwc_id)}>
                        {t('ord-add_batch')}
                    </Button>
                </td>
                :
                <></>}
        </tr>
    );
}

/**
 * Specify expiration dropdown
 * 
 * @param {boolean} disabled_adding - when there is unknown batch, expiry is blocked 
 * @param {any} item 
 * @param {number} index
 * @param {function} setNotifyItemExpiration
 * @returns {component}
 */
function ExpiryDropdown({ disabled_adding, batch, item, index, setNotifyItemExpiration }) {
    const { t } = useTranslation();

    const allowExpiryEditing = is_warehouse_defined(item.nop_KodKnihySkladu);
    return (
        allowExpiryEditing ?
            item.pwc_id === 1 ?
                <Dropdown className='d-flex'>
                    <Dropdown.Toggle className='' size="sm" variant="light" id="dropdown-basic" disabled={disabled_adding || batch.batch === 'neznámá'}>
                        {t('ord-fill_expiry')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { setNotifyItemExpiration(item.nop_IDPolozky, index, "doplní sklad", "default") }}>{t('ord-default_settings')}</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setNotifyItemExpiration(item.nop_IDPolozky, index, new Date(), "exact-date") }}>{t('ord-exact_date')}</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setNotifyItemExpiration(item.nop_IDPolozky, index, new Date(), "month-year") }}>{t('ord-month_year')}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                : <></>
            : ""
    );
}

/**
 * Form for expiration. Allows to enter month-year, exact date or default
 * 
 * @param {any} item 
 * @param {any} batch 
 * @param {number} index 
 * @param {function} getNotifyItemExpiry 
 * @param {function} setNotifyItemExpiration 
 * @returns {component} 
 */
function ExpirySetting({ item, batch, index, getNotifyItemExpiry, setNotifyItemExpiration }) {
    const { t } = useTranslation();

    const minDate = date_plusNyears(new Date(), -2);
    const maxDate = date_plusNyears(new Date(), 11);
    const years = platneRoky(minDate, maxDate).reverse();
    const date_value = date_isValidObject(batch.expiration) ? new Date(batch.expiration) : new Date();
    const months = monthsOfYear(minDate, maxDate, date_value.getFullYear());
    return (
        <div className='float-end'>
            {batch.expiryType === "default" ?
                <Form.Group controlId="expirace" key={index} className='mb-0'>
                    <Form.Control size="sm" type="text" style={{ width: "197px" }}
                        value={expiryISOtoCZdate(getNotifyItemExpiry(item.nop_IDPolozky), t)}
                        disabled />
                </Form.Group>
                : ""}
            {batch.expiryType === "exact-date" ?
                <DatePickerCustom
                    onChange={(ev) => setNotifyItemExpiration(item.nop_IDPolozky, index, ev, "exact-date")}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={date_value}
                    size="sm"
                    holidays={[]} /> : ""}
            {batch.expiryType === "month-year" ?
                <MonthYearDropdown
                    today={date_value}
                    setToday={(ev) => setNotifyItemExpiration(item.nop_IDPolozky, index, ev, "month-year")}
                    years={years}
                    months={months}
                    minDate={minDate}
                    maxDate={maxDate}
                    withoutArrows
                    variant='light'
                    whiteStyle
                /> : ""}
        </div>
    );
}
