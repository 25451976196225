/**
 * Partner debt analysis / mobile app list.
 * @module partners/partner-debts-app
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { LinkContainer } from 'react-router-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { DelayOrHold, NoteIcon, NoteWithIcon } from './partner-history-utils';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK


// 0    1     2         3         4          5           6        7          8       9         10           11       12      13    14      15
// ps,saldo,uhrada,datdokladu,poznamka,datsplatnosti,koddokladu,zpozdeni,zpozdeni2,kodmeny,CastkaCelkemMen,saldo2,comm_type,note,note_ts,pojisteno

export function PartnerDebtsApp({ debts, acc_currency, partner }) {
	var idx = 0;
	return (
		<ErrorWrap>
			<Accordion className="d-block d-lg-none">
				{debts.map(function (debt) {
					idx += 1;
					return (
						<React.Fragment key={idx}>
							{single_debt_func(debt, acc_currency, partner)}
						</React.Fragment>
					);
				})}
			</Accordion>
		</ErrorWrap>
	);
}

function single_debt_func(debt, acc_currency, partner) {
	const kodDokladu = debt[6];
	return (
		<SingleDebt
			keyx={kodDokladu}
			id={kodDokladu}
			delay={debt[7]}
			hold={debt[8]}
			issue_date={date_formatCZ(debt[3])}
			due_date={date_formatCZ(debt[5])}
			currency={debt[9]}
			amount={format_amount(debt[10])}
			saldo_curr={format_amount(debt[11])}
			saldo_acc={format_amount(debt[1])}
			item={debt[4]}
			note={debt[13]}
			note_type={debt[12]}
			partlyPaid={date_formatCZ(debt[2])}
			acc_currency={acc_currency}
			FIC={debt[16]}
			named_insured={debt[15]}
			auto_insured={debt[17]}
			auto_country_ok={partner.credendo_country_ok}
		/>
	);
}


function SingleDebt({ keyx, id, delay, hold, issue_date, due_date, currency, amount, saldo_curr, saldo_acc, item, note, note_type, partlyPaid, acc_currency, FIC, named_insured, auto_insured, auto_country_ok }) {
	const { t } = useTranslation();

	return (
		<Accordion.Item eventKey={keyx}>
			<Accordion.Header as="div">
				<div className='container-fluid py-0 ps-0' >
					<Row className='g-0'>
						<Col className="beBigger">{id}</Col>
						<DelayOrHold label={t('biz-delay')} number={delay} />
						<DelayOrHold label={t('biz-delay2')} number={hold} />
					</Row>
					<Row className="g-0">
						<Col xs="3" className="text-end">{amount}</Col>
						<Col xs="auto">&nbsp;{currency} {t('total')}</Col>
						<Col className="text-end">{t('issued')}: {issue_date}</Col>
					</Row>
					<Row className="g-0">
						<Col xs="3" className="text-end">{saldo_curr}</Col>
						<Col xs="5">&nbsp;{currency} {t('biz-inv_balance')}</Col>
						<Col xs="4" className="text-end">
							<NoteIcon note={note} note_type={note_type} />
							<Insured3 status={get_insurance_status(FIC, named_insured, auto_insured, auto_country_ok)} />
						</Col>
					</Row>
				</div>
			</Accordion.Header>
			<Accordion.Body className='p-2'>
				<LinkContainer to={{ pathname: "/partners/invoices-issued/" + id }}>
					<Card.Body className="beSmaller">
						<ListGroup variant="flush" className="smaller">
							<ListGroup.Item>
								<Row className="g-0">
									<Col xs="3" className="text-end">{saldo_acc}</Col>
									<Col xs="4">&nbsp;{acc_currency} {t('biz-acc_balance')}</Col>
									<Col xs="5" className="text-end">{t('biz-due')}: {due_date}</Col>
								</Row>
							</ListGroup.Item>
							{partlyPaid === null ? "" : <ListGroup.Item className="text-end">{t('biz-partial_pay')}: {partlyPaid}</ListGroup.Item>}
							<NoteWithIcon note={note} note_type={note_type} />
							<ListGroup.Item>{item}</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</LinkContainer>
			</Accordion.Body>
		</Accordion.Item>
	);
}