/**
 * Partner payments history web view.
 * @module partners/partner-payments-history-web
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { delay_color } from '../lists/colors';
import Table from 'react-bootstrap/Table';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { LinkContainer } from 'react-router-bootstrap';
import notes_comms from '../notes/notes-comms.json';
import { Pager } from '../comp/pager';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import { Loading } from '../comp/loading';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerPaymentsHistoryWeb({ payments, currency, partner, offset, setOffset }) {
    const { t } = useTranslation();

    const payments_table = payments == null ? null : payments.slice(offset, offset + 20);
    const payments_length = payments == null ? 0 : payments.length;

    return (
        <ErrorWrap>
            < div className="d-none d-lg-block">
                <Pager offset={offset} pagesize={20} total={payments_length} callback={setOffset} />
                <Table striped size="sm" hover className="mt-3">
                    <thead>
                        <tr className="beGray">
                            <th scope="col" rowSpan="2" className="text-center align-middle">{t('doc_code')} / {t('biz-code_debt')}</th>
                            <th scope="col" colSpan="3" className="text-center">{t('date')}</th>
                            <th scope="col" rowSpan="2" className="text-center align-middle">{t('biz-balance')} {currency}</th>
                            <th scope="col" rowSpan="2" className="text-center align-middle">{t('biz-pay_delay')}</th>
                            <th scope="col" rowSpan="2" className="text-center align-middle">{t('note')}</th>
                            <th scope="col" rowSpan="2" className="text-center align-middle">{t('biz-doc_currency')}</th>
                            <th scope="col" rowSpan="2" className="text-center align-middle">{t('menu-ins')}</th>
                        </tr>
                        <tr className="beGray">
                            <th scope="col" className="text-center">{t('biz-issued')}</th>
                            <th scope="col" className="text-center">{t('biz-due(2p)')}</th>
                            <th scope="col" className="text-center">{t('biz-payments')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments == null ?
                            <tr>
                                <td colSpan="9"><Loading /></td>
                            </tr>
                            :
                            payments_table.map(function (pm) {
                                var lstyle = {};
                                // var ostyle = {};
                                if (pm[7] !== null) {
                                    lstyle = {
                                        fontWeight: "bold",
                                        backgroundColor: delay_color(pm[7])
                                    }
                                }
                                /* if (pm[8] !== null) {
                                    ostyle = {
                                        fontWeight: "bold",
                                        backgroundColor: delay_color(pm[8])
                                    }
                                }  */
                                var formatted_note = "";
                                if (pm[11]) {
                                    const commdef = notes_comms[pm[10]];
                                    const emoji = commdef.emoji;
                                    const variant = commdef.variant;
                                    const text = commdef.text;
                                    const cls = "rounded p-1 me-1 bg-" + variant + " " + text;
                                    formatted_note = <>
                                        <br />
                                        <span className={cls}><img src={emoji} alt="" width="20" /></span>
                                        {date_formatCZ(pm[12])}&nbsp;•&nbsp;
                                        {pm[11]}
                                    </>;
                                }
                                return (
                                    <LinkContainer to={{ pathname: "/partners/invoices-issued/" + pm[6] }} key={pm[0]}>
                                        <tr key={pm[0]}>
                                            <td>{pm[6]}{formatted_note}</td>
                                            <td className="text-end">{date_formatCZ(pm[3])}</td>
                                            <td className="text-end">{date_formatCZ(pm[5])}</td>
                                            <td className="text-end">{date_formatCZ(pm[2])}</td>
                                            <td className="text-end">{format_amount(pm[1])}</td>
                                            <td className="text-center" style={lstyle}>{pm[7]}</td>
                                            <td>{pm[4]}</td>
                                            <td className="text-center">{pm[9]}</td>
                                            <td className="text-center"><Insured3 status={get_insurance_status(pm[14], pm[13], pm[15], partner.credendo_country_ok)} /></td>
                                        </tr>
                                    </LinkContainer>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </ErrorWrap>
    );
}
