import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Routes, Route, useParams } from "react-router-dom";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { format_amount } from '../lib/format';
import { useTranslation } from 'react-i18next';
import { one_word_per_line } from '../lib/format';
import { get_booked_string_for_filename } from './report-utils';

export function ReportManagementRoute({ userlogin, reportName, costCenters }) {
    return (
        <Routes>
            <Route path="/:centre" element={
                <ReportManagement reportName={reportName} userlogin={userlogin} costCenters={costCenters} />
            } />
        </Routes>
    )
}

export function ReportManagement({ userlogin, reportName, costCenters }) {
    const { t } = useTranslation();

    const { year } = useParams();
    const { month } = useParams();
    const { booked } = useParams();
    const { centre } = useParams();

    //console.log(centre);
    //console.log(costCenters);

    // Store the data
    const [balances, setBalances] = useState(null);
    const [categories, setCategories] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [accountNames, setAccountNames] = useState(null);

    //console.log(balances);

    useEffect(() => {
        // console.log("effect");
        const storno_fetch = his_fetch(
            userlogin,
            [
                {
                    uri: "/api/reporting/management/" + year + "/" + month + "/" + booked + "/" + centre,
                    json: true,
                    status: setLoadedStatus,
                    ok: (resource, result) => {
                        //console.log("got balances");
                        // console.log(result);
                        setBalances(result.months);
                        setCategories(result.kategorie);
                        setAccountNames(result.chartOfAccounts);
                    },
                    error: function (resource, reason) {
                        console.log('err: ' + reason);
                    }
                }
            ]
        );
        return () => {
            // console.log("finito");
            storno_fetch();
        }
    }, [userlogin, month, year, centre, reportName]);

    //console.log(config);
    // Continue only if we have the source balances
    if ((!his_fetch_success(loadedStatus)) || (categories === null) || (balances === null)) {
        return <HisFetchStatus status={loadedStatus}
            loadingType="big"
            errorType="fetcherError" />;
    }

    const categoriesLookup = categories.reduce((acc, cat) => ({
        ...acc,
        [cat.KodKategorie]: cat.NazevKategorie
    }), {});
    //console.log(categoriesLookup);
    const groupsLookup = categories.reduce((acc, cat) => ({ // groupsLookup[skupina + '.0']
        ...acc,
        [cat.KodKategorie]: cat.NazevKategoriePreklad
    }), {});
    //console.log(groupsLookup);
    const currentMonth = Object.keys(balances).sort().reverse()[0];
    const currentBalances = filterCenterBalances(balances[currentMonth], centre);
    const balancesCategories = getBalancesCategories(currentBalances);
    const totalExpenses = balancesCategories.reduce((acc, bal) => acc + bal.expenses, 0);
    const totalRevenues = balancesCategories.reduce((acc, bal) => acc + bal.revenues, 0);
    const totalTotal = totalExpenses + totalRevenues;
    const totalExpensesPeriod = balancesCategories.reduce((acc, bal) => acc + bal.expenses_period, 0);
    const totalRevenuesPeriod = balancesCategories.reduce((acc, bal) => acc + bal.revenues_period, 0);
    const totalTotalPeriod = totalExpensesPeriod + totalRevenuesPeriod;
    // console.log(balancesCategories);
    // console.log(categories);
    const costCentersLookup = Object.assign({}, ...costCenters.map((x) => ({ [x.KodStrediska]: x.NazevStrediska })));
    //console.log(costCentersLookup);
    const language = userlogin.userinfo.language;
    const accountNameTranslation = language === "en" ? "NazevUctuPreklad" : "NazevUctu";

    return (
        <>
            <h4 className='my-3'>Management report {centre} –  {costCentersLookup[centre]} {month}/{year}</h4>
            <Table size="sm">
                <thead className='beGray'>
                    <tr>
                        <th rowSpan={2} className='text-center'>{t('biz-group')}</th>
                        <th rowSpan={2} colSpan={1} className='text-center'>{t('deno-category')}</th>
                        <th rowSpan={2}>{t('rep-accounts_in_category')}</th>
                        <th colSpan={3} className='text-center'>{t('total')}</th>
                        <th colSpan={3} className='text-center'>{t('rep-period')}</th>
                        <th rowSpan={2} className='text-center'></th>
                    </tr>
                    <tr>
                        <th className='text-end'>{t('rep-expenses')}</th>
                        <th className='text-end'>{t('rep-revenues')}</th>
                        <th className='text-end'>{t('rep-profit')}+/{t('rep-loss')}-</th>
                        <th className='text-end'>{t('rep-expenses')}</th>
                        <th className='text-end'>{t('rep-revenues')}</th>
                        <th className='text-end'>{t('rep-profit')}+/{t('rep-loss')}-</th>
                    </tr>
                </thead>
                <tbody>
                    {balancesCategories.map((bal, cidx) =>
                        <React.Fragment key={cidx}>
                            <tr className='beGray2'>
                                <td colSpan={10}></td>
                            </tr>
                            {bal.category.map((pod, bidx) => { //pokud bude bidx sudé, tak nabarvit const bg = (idx % 2) === 0 ? "" : "beGray3";
                                const zebra_bg = (bidx % 2) === 0 ? "" : "beGray6";
                                return (
                                    <React.Fragment key={bidx}>
                                        {pod.accounts.map((acc, idx) => {
                                            //console.log(pod.accounts);
                                            return (
                                                <tr key={idx}>
                                                    {(bidx === 0) && (idx === 0) ?
                                                        <td width="10%"
                                                            rowSpan={bal.category.reduce((acc, pod) => acc + pod.accounts.length + 1, 0)}
                                                            className=' beGray2 fw-bold'>
                                                            {bal.group} <br />
                                                            {one_word_per_line(groupsLookup[bal.group + '.0'])}
                                                        </td>
                                                        : <></>}
                                                    {idx === 0 ?
                                                        <>
                                                            <td rowSpan={pod.accounts.length}
                                                                className={'align-middle ' + zebra_bg}>
                                                                {pod.group} {categoriesLookup[pod.group]}</td>
                                                        </> : <></>}

                                                    <td className={zebra_bg}>{acc[0]} &nbsp;&nbsp;{(accountNames[acc[0]] || {})[accountNameTranslation] ? accountNames[acc[0]][accountNameTranslation] : ""}</td>
                                                    <td className={"text-end " + zebra_bg} >{acc[0] < '6' ? formatAccountAmount(acc) : "0,00"}</td>
                                                    <td className={"text-end " + zebra_bg} >{acc[0] >= '6' ? formatAccountAmount(acc) : "0,00"}</td>
                                                    <td className={"text-end " + zebra_bg} >{formatAccountAmount(acc)}</td>
                                                    <td className={"text-end " + zebra_bg} >{acc[0] < '6' ? formatAccountAmount(acc, true) : "0,00"}</td>
                                                    <td className={"text-end " + zebra_bg} >{acc[0] >= '6' ? formatAccountAmount(acc, true) : "0,00"}</td>
                                                    <td className={"text-end " + zebra_bg} >{formatAccountAmount(acc, true)}</td>
                                                    <td className='beGray2'></td>
                                                </tr>
                                            );
                                        }
                                        )}
                                        <tr className={zebra_bg}>
                                            <td colSpan={2} className='bolder'> {pod.group} {categoriesLookup[pod.group]} {t('total')}</td>
                                            <td className="text-end bolder">{format_amount(pod.expenses)}</td>
                                            <td className="text-end bolder">{format_amount(pod.revenues)}</td>
                                            <td className="text-end bolder">{format_amount(pod.total)}</td>
                                            <td className="text-end bolder">{format_amount(pod.expenses_period)}</td>
                                            <td className="text-end bolder">{format_amount(pod.revenues_period)}</td>
                                            <td className="text-end bolder">{format_amount(pod.total_period)}</td>
                                            <td className='beGray2'></td>
                                        </tr>
                                    </React.Fragment>
                                );
                            }
                            )}
                            <tr className='beGray2'>
                                <td colSpan={3} className='fw-bold'> {bal.group} {groupsLookup[bal.group + '.0']} {t('total')}</td>
                                <td className="text-end fw-bold">{format_amount(bal.expenses)}</td>
                                <td className="text-end fw-bold">{format_amount(bal.revenues)}</td>
                                <td className="text-end fw-bold">{format_amount(bal.total)}</td>
                                <td className="text-end fw-bold">{format_amount(bal.expenses_period)}</td>
                                <td className="text-end fw-bold">{format_amount(bal.revenues_period)}</td>
                                <td className="text-end fw-bold">{format_amount(bal.total_period)}</td>
                                <td className='beGray2'></td>
                            </tr>
                            <tr className=''>
                                <td colSpan={6} className='py-2'></td>
                            </tr>
                        </React.Fragment>
                    )}
                    <tr className='bg-secondary text-white fw-bold'>
                        <td colSpan={3} className='py-3 ps-2'>{centre} – {costCentersLookup[centre]}</td>
                        <td className="text-end py-3">{format_amount(totalExpenses)}</td>
                        <td className="text-end py-3">{format_amount(totalRevenues)}</td>
                        <td className="text-end py-3">{format_amount(totalTotal)}</td>
                        <td className="text-end py-3">{format_amount(totalExpensesPeriod)}</td>
                        <td className="text-end py-3">{format_amount(totalRevenuesPeriod)}</td>
                        <td className="text-end py-3">{format_amount(totalTotalPeriod)}</td>
                        <td></td>
                    </tr>

                </tbody>
            </Table>

            {/****************Alternative layout - unfinished****************- 
            <br />
            <br />
            <br />
            <br />
            <br />
            <h4>Alternativní design</h4>

            <Table size="sm">
                <thead className='beGray'>
                    <tr>
                        <th rowSpan={2} className="align-middle" style={{ paddingLeft: "80px" }}>{t('rep-accounts_in_category')}</th>
                        <th colSpan={3} className='text-center'>{t('total')}</th>
                    </tr>
                    <tr>
                        <th className='text-end'>{t('rep-expenses')}</th>
                        <th className='text-end'>{t('rep-revenues')}</th>
                        <th className='text-end'>{t('rep-profit')}+/{t('rep-loss')}-</th>
                    </tr>
                </thead>
                <tbody>
                    {balancesCategories.map((bal, cidx) =>
                        <React.Fragment key={cidx}>
                            {bal.category.map((pod, bidx) => {
                                const zebra_bg = (bidx % 2) === 0 ? "" : "beGray6";
                                return (
                                    <React.Fragment key={bidx}>
                                        {pod.accounts.map((acc, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    {(bidx === 0) && (idx === 0) ?
                                                        <tr>
                                                            <td
                                                                colSpan={4}
                                                                className='align-middle beGray2 fw-bold'>
                                                                {bal.group} {groupsLookup[bal.group + '.0']}
                                                            </td>
                                                        </tr>
                                                        : <></>}

                                                    {idx === 0 ?
                                                        <>
                                                            <td colSpan={4}
                                                                className={'align-middle bolder ' + zebra_bg}
                                                                style={{ paddingLeft: "40px" }}>
                                                                {pod.group} {categoriesLookup[pod.group]}</td>
                                                        </> : <></>}

                                                    <tr key={idx}>
                                                        <td style={{ paddingLeft: "80px" }} className={zebra_bg}>{acc[0]}</td>
                                                        <td className={"text-end " + zebra_bg} >{acc[0] < '6' ? formatAccountAmount(acc) : "0,00"}</td>
                                                        <td className={"text-end " + zebra_bg} >{acc[0] >= '6' ? formatAccountAmount(acc) : "0,00"}</td>
                                                        <td className={"text-end " + zebra_bg} >{formatAccountAmount(acc)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                        <tr className={zebra_bg} >
                                            <td className='bolder' style={{ paddingLeft: "40px" }}>{t('total')} {pod.group}</td>
                                            <td className="text-end bolder">{format_amount(pod.expenses)}</td>
                                            <td className="text-end bolder">{format_amount(pod.revenues)}</td>
                                            <td className="text-end bolder">{format_amount(pod.total)}</td>
                                        </tr>
                                    </React.Fragment>
                                );
                            }
                            )}
                            <tr className='beGray2'>
                                <td colSpan={1} className='fw-bold'>{t('total')} {bal.group}</td>
                                <td className="text-end fw-bold">{format_amount(bal.expenses)}</td>
                                <td className="text-end fw-bold">{format_amount(bal.revenues)}</td>
                                <td className="text-end fw-bold">{format_amount(bal.total)}</td>
                            </tr>
                            {cidx !== 4 ?
                                <tr className=''>
                                    <td colSpan={4}>&nbsp;</td>
                                </tr>
                                :
                                <tr className=''>
                                    <td colSpan={4}></td>
                                </tr>}
                        </React.Fragment>
                    )}
                    <tr className='bg-secondary text-white fw-bold'>
                        <td colSpan={1} className='py-2 ps-2'>{centre} – {costCentersLookup[centre]}</td>
                        <td className="text-end py-2">{format_amount(totalExpenses)}</td>
                        <td className="text-end py-2">{format_amount(totalRevenues)}</td>
                        <td className="text-end py-2">{format_amount(totalTotal)}</td>
                    </tr>

                </tbody>
            </Table>
            */}
        </>
    );
}

function filterCenterBalances(balances, center) {
    return balances.filter((row) => row[2] === center);
}

// Can process multiple balances arrays and returns unique category
// identifiers accross all of them.

// * [0] account (string)
// * [1] accounting side (boolean)
// * [2] cost center (string)
// * [3] focus group (string) - single character string containing only digits 0-5
// * [4] amount in local currency (number)
// * [5] category number 

function getBalancesCategories(...multiBalances) {
    return multiBalances
        .map((balances) => balances
            .map((row) => row[3]) //row[3] is focusgroup
            .filter((v, i, a) => a.indexOf(v) === i))
        .flat()
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort()
        .map((group) => {
            //console.log(multiBalances);
            const category = multiBalances
                .map((balances) => balances
                    .filter((row) => row[3] === group)
                    .map((row) => row[5])
                    .filter((v, i, a) => a.indexOf(v) === i))
                .flat()
                .filter((v, i, a) => a.indexOf(v) === i)
                .map((category) => {
                    const accounts = multiBalances
                        .map((balances) => balances
                            .filter((row) => (row[3] === group) && (row[5] === category)))
                        .flat()
                        .sort((a, b) => a < b ? -1 : b < a ? 1 : 0);
                    //console.log(accounts);
                    const amounts = accounts.map((acc) => (acc[1] ? 1 : -1) * acc[4]);
                    const expenses = amounts.filter((amt) => amt < 0).reduce((acc, amt) => acc + amt, 0);
                    const revenues = amounts.filter((amt) => amt > 0).reduce((acc, amt) => acc + amt, 0);
                    const amounts_period = accounts.map((acc) => (acc[1] ? 1 : -1) * acc[6]);
                    const expenses_period = amounts_period.filter((amt) => amt < 0).reduce((acc, amt) => acc + amt, 0);
                    const revenues_period = amounts_period.filter((amt) => amt > 0).reduce((acc, amt) => acc + amt, 0);
                    return ({
                        // kodPodKategorie: podKategorie, //not used
                        group: group + "." + category,
                        accounts: accounts,
                        expenses: expenses,
                        revenues: revenues,
                        total: revenues + expenses,
                        expenses_period: expenses_period,
                        revenues_period: revenues_period,
                        total_period: revenues_period + expenses_period,
                    })
                });
            const expenses = category.reduce((acc, pod) => acc + pod.expenses, 0);
            const revenues = category.reduce((acc, pod) => acc + pod.revenues, 0);
            const total = category.reduce((acc, pod) => acc + pod.total, 0);
            const expenses_period = category.reduce((acc, pod) => acc + pod.expenses_period, 0);
            const revenues_period = category.reduce((acc, pod) => acc + pod.revenues_period, 0);
            const total_period = category.reduce((acc, pod) => acc + pod.total_period, 0);
            return ({
                group: group,
                category: category,
                expenses: expenses,
                revenues: revenues,
                total: total,
                expenses_period: expenses_period,
                revenues_period: revenues_period,
                total_period: total_period,
            })
        });
}

/**
 * Counts balance on an account and formats it as amount
 * 
 * @param {array} acc - account info
 * @param {boolean} period - whether to count with total ([4]) or period ([6]) balance number
 * @returns {string}
 */
function formatAccountAmount(acc, period = false) {
    const correctAmount = period ? acc[6] : acc[4];
    const mult = acc[1] ? 1 : -1;
    const amt = correctAmount * mult;
    return format_amount(amt);
}
