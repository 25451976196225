import React, { useState, useCallback } from "react";
import { Table, Form, Row, Col } from "react-bootstrap";
import { DynamicLoad, LoadingDataInfo } from "../comp/dynamic-load";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { date_formatCZ } from "../lib/date-utils";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryStatusToLoadedStatus } from "../api/common";
import { getStockins } from "../api/stockins";
import { filter_rule } from "../lib/utils";
import { FormProvider, useForm } from "react-hook-form";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";
import { useQueryLoadData } from "../hooks/queryHooks";
import { DynamicLoadPaged } from "../comp/DynamicLoadPaged";

export function StockInsNew() {
  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const form = useForm();
  const [debouncedFilter] = useDebounceFilterValues(form.watch, { stockInId: "" });

  const [loadData, data, status, isFetching, refetch] = useQueryLoadData("stockIns", getStockins, {
    items: [],
    totalCount: 0,
    oldest: null,
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  function stockInsForStockNotificationId(stockNotificationId: number) {
    return data.items.filter((s) => s.stockNotification.id === stockNotificationId);
  }

  function getUniqueBatchesForSn(stockNotificationId: number): Dto.GetStockinsRangeResponseItem[] {
    return Object.values(
      stockInsForStockNotificationId(stockNotificationId)
        .map((s) => s.stockNotification.batch.id)
        .filter((v, i, a) => a.indexOf(v) === i)
        .reduce(
          (acc, batchId) => ({
            ...acc,
            [batchId]: stockInsForStockNotificationId(stockNotificationId).filter(
              (b) => b.stockNotification.batch.id === batchId
            )[0],
          }),
          {}
        )
    );
  }

  const preprocessedData = data.items
    .map((s) => s.stockNotification.id)
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((sn_id) => ({
      sn_id: sn_id,
      lowest_s_id:
        "HIS-" + stockInsForStockNotificationId(sn_id).sort((a, b) => a.stockIn.id - b.stockIn.id)[0].stockIn.id,
      sn_number: stockInsForStockNotificationId(sn_id)[0].stockNotification.number,
      stockins: stockInsForStockNotificationId(sn_id),
      batches: getUniqueBatchesForSn(sn_id),
    }));

  const filterByStockInId = useCallback(
    (s) => {
      return filter_rule(debouncedFilter.stockInId, s.lowest_s_id);
    },
    [debouncedFilter.stockInId]
  );

  const stockInsFiltered = preprocessedData.filter(filterByStockInId);

  const showStockIns = stockInsFiltered.slice(offset, offset + 20);

  const cleanFilters = () => {
    form.reset({ stockInId: "" });
  };

  return (
    <ErrorWrap>
      <DynamicLoadPaged
        period="1y"
        loadData={loadData}
        filteredCount={stockInsFiltered.length}
        loadingState={loadedStatus}
        reloadIt={refetch}
        rangeCount={preprocessedData.length}
        offset={offset}
        setOffset={setOffset}
        totalCount={data?.totalCount ?? 0}
        cleanFilters={cleanFilters}
      />
      <TableFetchStatusOverlay status={loadedStatus}>
        <Table size="sm" striped>
          <thead className="beGray">
            <tr>
              <th className="pb-2">
                <p className="mb-1">{t("ord-stockin_id")}</p>
                <FormProvider {...form}>
                  <Form.Group controlId="stockInId" className="mb-1">
                    <Form.Control type="text" placeholder="&#128269;" {...form.register("stockInId")} />
                  </Form.Group>
                </FormProvider>
              </th>
              <th className="pb-2">{t("ord-pur")}</th>
              <th className="pb-2">{t("ord-sn_num")}</th>
              <th className="pb-2">{t("ord-imported")}</th>
              <th className="pb-2">{t("ord-notified")}</th>
              <th className="pb-2">{t("warehouse")}</th>
              <th className="pb-2">{t("ord-eta")}</th>
              <th className="pb-2">
                <Row>
                  <Col className="text-end">{t("not-prod_id")}</Col>
                  <Col className="text-end">{t("amount")}</Col>
                  <Col>{t("ord-batch")}</Col>
                </Row>
              </th>
            </tr>
          </thead>
          <tbody>
            {showStockIns.map(function (rec, idx) {
              const batch = rec.batches[0];
              const classa = "align-middle";
              return (
                <tr key={idx}>
                  <td className={classa}>{rec.lowest_s_id}</td>
                  <td className={classa}>
                    <LinkContainer
                      style={{ cursor: "pointer" }}
                      to={{ pathname: "/orders/purchase/" + batch.orderCode }}
                    >
                      <span className=" blueish">{batch.orderCode}</span>
                    </LinkContainer>
                  </td>
                  <td className={classa}>{rec.sn_number}</td>
                  <td className={classa}>{batch.stockIn?.imported ? date_formatCZ(batch.stockIn.imported) : "-"}</td>
                  <td className={classa}>{date_formatCZ(batch.stockNotification.createdAt)}</td>
                  <td className={classa}>{batch.stockNotification?.warehouseId}</td>
                  <td className={classa}>{date_formatCZ(batch.stockNotification.eta)}</td>
                  <td className={classa}>
                    {rec.batches.map(function (batch, idx) {
                      return (
                        <Row key={idx}>
                          <Col className="text-end">{batch.stockNotification.batch.productId}</Col>
                          <Col className="text-end">{batch.stockNotification.batch.amount}</Col>
                          <Col>{batch.stockNotification.batch.batch}</Col>
                        </Row>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableFetchStatusOverlay>
      <LoadingDataInfo loadedStatus={loadedStatus} data={data?.items ?? []} />
    </ErrorWrap>
  );
}
