import React from "react";
import { useParams } from "react-router-dom";
import { his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, Card, Table, Button, Image, Accordion, ListGroup } from "react-bootstrap";
import { Boolean } from "../comp/boolean";
import { date_formatCZ } from "../lib/date-utils";
import { MandatoryAddressPart } from "../partners/partner-detail";
import { romanize_packaging_group } from "../products/product-checks";
import { useQuery } from "@tanstack/react-query";
import { getPurchaseDeliveryNoteDetail } from "../api/deliveryNotes";
import { queryStatusToLoadedStatus } from "../api/common";

export function DeliveryNotePurchaseDetail() {
  const { orderCode } = useParams();

  const {
    data: document,
    status,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["delivery-note", orderCode],
    queryFn: ({ queryKey, signal }) => getPurchaseDeliveryNoteDetail(queryKey[1], { signal }),
  });

  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const reloadIt = () => {
    return refetch();
  };

  if (!his_fetch_success(loadedStatus)) {
    return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
  }

  const uniqueItemIds = document.items.map((itm) => itm.stockIn.itemId).filter((v, i, a) => a.indexOf(v) === i);
  const itemsByItemIds: { [id: string]: Dto.GetPurchaseDeliveryNoteDetailItem[] } = uniqueItemIds.reduce(
    (acc, item_id) => ({
      ...acc,
      [item_id]: document.items.filter((rec) => rec.stockIn.itemId === item_id),
    }),
    {}
  );

  const itemsTransformed = uniqueItemIds.map(function (itemId): TransformedItem {
    return {
      itemId,
      items: itemsByItemIds[itemId],
    };
  });

  const header = document.header || {};
  const partner = document.partner || {};
  const items = itemsTransformed;

  return (
    <ErrorWrap>
      <Button size="sm" className="me-2 mb-2" onClick={reloadIt}>
        <Image src="/img/reload.svg" height="19" />
      </Button>
      <h4 className="d-inline">Nákupní dodací list {document.purchaseDeliveryNoteCode}</h4>
      <Row>
        <Col xs="12" lg="6">
          <BasicInfo header={header} />
        </Col>
        <Col xs="12" lg="6">
          <PartnerInfo partner={partner} />
        </Col>
      </Row>
      <Items items={items} />
      <NDItemsMobile items={items} />
    </ErrorWrap>
  );
}

interface ItemsProps {
  items: TransformedItem[];
}

function Items({ items }: ItemsProps) {
  const { t } = useTranslation();
  return (
    <Card body className="d-none d-lg-block">
      <h5>{t("biz-items")}</h5>
      <Table bordered size="sm">
        <thead className="beGray">
          <tr>
            <th>{t("biz-centre")}</th>
            <th>{t("deno-contract")}</th>
            <th>{t("deno-category")}</th>
            <th>{t("prod-id")}</th>
            <th>{t("prod-code")}</th>
            <th>{t("warehouse")}</th>
            <th>{t("prod-name")}</th>
            <th>{t("ord-adr_class")}</th>
            <th>{t("ord-pack_group")}.</th>
            <th>{t("ord-un_num")}</th>
            <th>{t("ord-batch")}</th>
            <th className="text-end">{t("prod-amount_MJ")}</th>
            <th className="text-center">{t("ord-expiry")}</th>
            <th>{t("biz-invoiced")}</th>
            <th>{t("biz-closed")}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((itms) =>
            itms.items.map((itm, idxx) => (
              <tr key={idxx}>
                {idxx === 0 ? (
                  <>
                    <td rowSpan={itms.items.length} className="align-middle text-muted">
                      {itm.stockIn.departmentCode ? itm.stockIn.departmentCode : "-"}
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle text-muted">
                      {itm.stockIn.stockInOrderCode ? itm.stockIn.stockInOrderCode : "-"}
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle text-muted">
                      {itm.stockIn.categoryCode ? itm.stockIn.categoryCode : "-"}
                    </td>

                    <td rowSpan={itms.items.length} className="align-middle">
                      {itm.productId}
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle">
                      <LinkContainer
                        to={{ pathname: "/products/" + encodeURIComponent(itm.stockIn.productCode) + "/view" }}
                      >
                        <a href="#!">{itm.stockIn.productCode}</a>
                      </LinkContainer>
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle">
                      {itm.stockIn.bookCode}
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle">
                      {itm.stockIn.productName}
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle text-center">
                      {itm.classAdr ? itm.classAdr : "-"}
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle text-center">
                      {itm.adrPackingGroup ? romanize_packaging_group(itm.adrPackingGroup) : "-"}
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle text-center">
                      {itm.adrUnNumber ? itm.adrUnNumber : "-"}
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>{itm.stockIn.seriesCode}</td>
                <td className="text-end">
                  {itm.stockIn.stockQuantity}&nbsp;{itm.storageUnitOfMeasurementCode}
                </td>
                <td className="text-end">
                  {itm.stockIn.expirationDate ? date_formatCZ(itm.stockIn.expirationDate) : "-"}
                </td>

                {idxx === 0 ? (
                  <>
                    <td rowSpan={itms.items.length} className="align-middle text-center">
                      <Boolean variant="onlyTrue" value={itm.stockIn.invoiced} />
                    </td>
                    <td rowSpan={itms.items.length} className="align-middle text-center">
                      <Boolean variant="onlyTrue" value={itm.stockIn.closed} />
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Card>
  );
}

interface TransformedItem {
  itemId: number;
  items: Dto.GetPurchaseDeliveryNoteDetailItem[];
}

function NDItemsMobile({ items }: ItemsProps) {
  return (
    <Accordion className="d-lg-none">
      {items.map(function (item, idx) {
        return <SingleNDItemMobile key={idx} item={item} />;
      })}
    </Accordion>
  );
}

interface SingleNDItemMobileProps {
  item: TransformedItem;
}

function SingleNDItemMobile({ item }: SingleNDItemMobileProps) {
  const { t } = useTranslation();
  const itemComms = item.items[0];

  return (
    <ErrorWrap>
      <Accordion.Item eventKey={itemComms.productId.toString()}>
        <Accordion.Header as="div">
          <div>
            <b>{t("prod-name")}:</b> {itemComms.stockIn.productName}
            <br />
            <b>{t("prod-code")}: </b>
            <LinkContainer
              to={{ pathname: "/products/" + encodeURIComponent(itemComms.stockIn.productCode) + "/view" }}
            >
              <a href="#!">{itemComms.stockIn.productCode}</a>
            </LinkContainer>
            <br />
            <b>{t("not-prod_id")}: </b>
            {itemComms.productId}
          </div>
        </Accordion.Header>
        <Accordion.Body className="p-2">
          <Card.Body className="beSmaller">
            <ListGroup variant="flush" className="smaller">
              <ListGroup.Item>
                <Row className="g-0">
                  <Col>
                    {t("deno-contract")}: <span className="text-muted">{itemComms.stockIn.stockInOrderCode}</span>
                  </Col>
                  <Col className="text-end">
                    {t("warehouse")}: {itemComms.stockIn.bookCode}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col xs="auto">
                    {t("biz-centre")}: <span className="text-muted">{itemComms.stockIn.departmentCode}</span>
                  </Col>
                  <Col className="text-end">
                    {t("ord-adr_class")}: {itemComms.classAdr ? itemComms.classAdr : "-"}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col xs="auto">
                    {t("deno-category")}: <span className="text-muted">{itemComms.stockIn.categoryCode}</span>
                  </Col>
                  <Col className="text-end">
                    {t("ord-pack_group")}:
                    {itemComms.adrPackingGroup ? romanize_packaging_group(itemComms.adrPackingGroup) : "-"}{" "}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    {t("biz-invoiced")}: <Boolean variant="onlyTrue" value={itemComms.stockIn.invoiced} />
                  </Col>
                  <Col className="text-end">
                    {t("ord-un_num")}: {itemComms.adrUnNumber ? itemComms.adrUnNumber : "-"}{" "}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    {t("biz-closed")}: <Boolean variant="onlyTrue" value={itemComms.stockIn.closed} />
                  </Col>
                  <Col className="text-end"></Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            <Table size="sm" borderless className="mt-2">
              <thead className="bg-w-2">
                <tr>
                  <th>{t("ord-batch")}</th>
                  <th className="text-end">{t("prod-amount_MJ")}</th>
                  <th className="text-end">{t("ord-expiry")}</th>
                </tr>
              </thead>
              <tbody>
                {item.items.map((itm, idx) => (
                  <tr key={idx}>
                    <td>{itm.stockIn.seriesCode}</td>
                    <td className="text-end">
                      {itm.stockIn.stockQuantity}&nbsp;{itm.storageUnitOfMeasurementCode}
                    </td>
                    <td className="text-end">
                      {itm.stockIn.expirationDate ? date_formatCZ(itm.stockIn.expirationDate) : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </ErrorWrap>
  );
}

interface BasicInfoProps {
  header: Dto.GetPurchaseDeliveryNoteDetailHeader;
}

function BasicInfo({ header }: BasicInfoProps) {
  const { t } = useTranslation();
  return (
    <Card body className="p-0 mb-4">
      <h5>{t("deno-basic_info")}</h5>
      <Table borderless size="sm" className="p-0 m-0">
        <tbody>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("deno-stockin_date")}&nbsp;</td>
            <td className="p-0">{header.documentDate ? date_formatCZ(header.documentDate) : "-"}</td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("deno-issuer")} &nbsp;</td>
            <td className="p-0">{header.issuedByCode}</td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("biz-ord_code")} &nbsp;</td>
            <td className="p-0">
              {" "}
              <LinkContainer to={{ pathname: "/orders/purchase/" + header.orderCode }}>
                <a href="#!">{header.orderCode}</a>
              </LinkContainer>
            </td>
          </tr>

          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("biz-centre")} &nbsp;</td>
            <td className="p-0">{header.departmentCode}</td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("deno-contract")} &nbsp;</td>
            <td className="p-0">{header.stockInOrderCode}</td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("deno-category")} &nbsp;</td>
            <td className="p-0">{header.categoryCode}</td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("biz-invoiced")} &nbsp;</td>
            <td className="p-0">{header.invoivedPercent} %</td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("ord-imported")} &nbsp;</td>
            <td className="p-0">
              <Boolean variant="onlyTrue" value={header.imported} />
            </td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("biz-closed")} &nbsp;</td>
            <td className="p-0">
              <Boolean variant="onlyTrue" value={header.closed} />
            </td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("deno-approved")} &nbsp;</td>
            <td className="p-0">
              <Boolean variant="onlyTrue" value={header.approved} />
            </td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("canceled")} &nbsp;</td>
            <td className="p-0">
              <Boolean variant="negative" value={header.cancelled} />
            </td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("note")} &nbsp;</td>
            <td className="p-0">{header.note}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

interface PartnerInfoProps {
  partner: Dto.GetPurchaseDeliveryNoteDetailPartner;
}

function PartnerInfo({ partner }: PartnerInfoProps) {
  const { t } = useTranslation();
  return (
    <Card body className="p-0 mb-4">
      <h5>{t("partner")}</h5>
      <Table borderless size="sm" className="p-0 m-0">
        <tbody>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("biz-partner_name")} &nbsp;</td>
            <td className="p-0">{partner.partnerName}</td>
          </tr>
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("biz-partner_code")} &nbsp;</td>
            <td className="p-0">
              <LinkContainer to={{ pathname: "/partners/list/" + partner.partnerCode + "/detail" }}>
                <a href="#!">{partner.partnerCode}</a>
              </LinkContainer>
            </td>
          </tr>
          <tr className="p-0 bg-light">
            <td colSpan={2} className="text-center p-0">
              {t("biz-adrs")} 🗺️ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
          </tr>
          <MandatoryAddressPart
            nazev={partner.addressName}
            adresa1={partner.address}
            adresa2={partner.address2}
            misto={partner.city}
            psc={partner.zipCode}
            kodStatu={partner.countryCode}
            bgLight
            kodAdresy={null}
            ErrorBadge={() => true}
          />
          <tr className="p-0">
            <td className="text-end text-muted p-0 ">{t("biz-adrs_type")} &nbsp;</td>
            <td className="p-0">{partner.addressType}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
