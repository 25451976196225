
/**
* Switch for main navigation.
* @module appcomponent
* @author Dominik Pantucek <dominik.pantucek@trustica.cz>
*/
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { DeliveryNotes } from './delivery-notes/delivery-notes';
import { Help } from './help';
import { Home } from './home';
import { Insurance } from './insurance/insurance';
import { AzureLogin } from './login-users/azure-login';
import { Settings } from './login-users/settings';
import { MainMenu } from './mainmenu';
import { OrdersComponent } from './orders/orders';
import { Debts2 } from './partners/debts2';
import { Partners } from './partners/partners';
import { Products } from './products/products';
import { ReportingRoutes } from './reporting/reporting';
import { SystemComponent } from './system';
import { FilterCacheProvider } from "./comp/FilterCacheProvider";


export function AppComponent({ userlogin, setPingLang }) {
	
	return (
		<Container fluid className='mt-5 pt-3'>

			<BrowserRouter>
				<FilterCacheProvider>
				<MainMenu userlogin={userlogin} setPingLang={setPingLang} />
				{userlogin.logged ?
					<Routes>
						<Route path="/partners/*" element={<Partners userlogin={userlogin} />} />
						<Route path="/orders/*" element={<OrdersComponent userlogin={userlogin} />} />
						<Route path="/products/*" element={<Products user={userlogin.username} userinfo={userlogin.userinfo} userlogin={userlogin} />} />
						<Route path="/delivery-notes/*" element={<DeliveryNotes userlogin={userlogin} />} />
						<Route path="/debts2" element={<Debts2 userinfo={userlogin.userinfo} userlogin={userlogin} />} />
						<Route path="/settings" element={<Settings userlogin={userlogin} />} />
						<Route path="/system/*" element={<SystemComponent userlogin={userlogin} />} />
						<Route path="/insurance/*" element={<Insurance userlogin={userlogin} />} />
						<Route path="/help/*" element={<Help userlogin={userlogin} />} />
						{/** 
							<Route path="/reporting/:reportId/:year/:month/:centre/*" element={<ReportingSwitch userlogin={userlogin} />} />
							<Route path="/reporting/:reportId/:year/:month/*" element={<ReportingSwitch userlogin={userlogin} />} />
							<Route path="/reporting/:reportId/*" element={<ReportingSwitch userlogin={userlogin} />} />
							<Route path="/reporting" element={<ReportingStart userlogin={userlogin} />} />
							*/}
						<Route path="/reporting/*" element={<ReportingRoutes userlogin={userlogin} />} />
						<Route path="/" element={<Home userlogin={userlogin} />} />
					</Routes>

					: (userlogin.justLoggedOut ? '' : <AzureLogin userlogin={userlogin} />)
				}
				</FilterCacheProvider>
			</BrowserRouter>
		</Container>
	);
}
