import { useMsal } from "@azure/msal-react";
import { useQuery } from "@tanstack/react-query";
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Loading } from "../comp/loading";
import { RequestErrorWithCode, fetchWrapper } from "../lib/fetcher";
import { queryOptions } from "../tanstack-query";
import { ResultMessage } from "../comp/resultmessage";

export function AzureLogin({ userlogin }) {
    const { instance: msalInstance } = useMsal();

    async function acquireUserInfo() {
        const userInfo = await fetchWrapper("/api/auth/user-info", msalInstance)

        userlogin.handleLogin(userInfo.username, userInfo)
        
        return userInfo
    }

    const userInfoQuery = useQuery({ queryKey: ["userInfo"], queryFn: acquireUserInfo, cacheTime: 0, ...queryOptions })

    const { t } = useTranslation();

    function errorWithCodeToText(error) {
        if (error.code === 'UserNotInUserGroup') {
            return t('no_group_in_azure')
        } else if (error.code === 'NoHisAccountForUser') {
            return t('no_corresponding_his_account')
        }
    }

    function handleLogoutClick() {
        userlogin.handleLogout()
        msalInstance.logoutRedirect();
    }

    var element = null
    if (userInfoQuery.isLoading) {
        element = <Loading />
    } else if (userInfoQuery.isError && userInfoQuery.error instanceof RequestErrorWithCode) {
        element = <Alert variant="danger" className="m-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3>{t('problem_with_sign_in')} 😔</h3>
            <div style={{ marginBottom: '1rem' }}>{errorWithCodeToText(userInfoQuery.error)}</div>
            <Button className="my-1" variant="light" size="sm" onClick={handleLogoutClick}>{t('sys-log_out')}</Button>
        </Alert>
    } else {
        element =  <ResultMessage message={t('loading_fail')} variant="danger" reloadButton={() => userInfoQuery.refetch()} />
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
            {element}
        </div>
    )
}