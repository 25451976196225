
//used on product card detail - will be transformed to progressPath one day
export const statuses_sending_card_strs = {
	0: "unknown", // "Neznámý",
	1: "sending", // "Odesílání",
	2: "sent", //Odesláno",
	3: "picked", // "Vyzvednuto",
	4: "confirmed", // "Potvrzeno",
	5: "denied", // "Zamítnuto",
	6: "error" // "Chyba"
};