import React, { useCallback, useEffect, useState } from "react";
import { DynamicLoadPaged, DynamicLoadPagedProps } from "../comp/DynamicLoadPaged";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Pager } from "../comp/pager";
import { ErrorWrap } from "../comp/errorwrap";
import { getSentFtpFiles } from "../api/ftpFiles";
import { queryStatusToLoadedStatus } from "../api/common";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { Downloader } from "../comp/downloader";
import { date_time_format } from "../lib/date-utils";
import { useQuery } from "@tanstack/react-query";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { warehouses_names_ids_separe, warehouseTitlesDropdown } from "../lists/warehouses-defs";

const defaultFilters: Dto.GetSentFtpRangePagedFilterRequest = {
  fileName: "",
  fileSize: "",
  sender: "",
  warehouseId: undefined,
};

export default function FtpSentPaged() {
  const [offset, setOffset] = useState<number>(0);
  const [from, setFrom] = useState<string>(undefined);
  const form = useForm<Dto.GetSentFtpRangePagedFilterRequest>({ mode: "onChange", defaultValues: defaultFilters });
  const { watch } = form;
  const [filter] = useDebounceFilterValues(watch, defaultFilters);

  const { data, status, isFetching, refetch } = useQuery<Dto.GetSentFtpRangePagedResponseItemRangePagedResponse>({
    queryKey: ["deliveryNotes", from, offset, filter],
    queryFn: ({ signal }) => {
      if (!from)
        return Promise.resolve<Dto.GetSentFtpRangePagedResponseItemRangePagedResponse>({
          items: [],
          totalCount: 0,
          filteredCount: 0,
          rangeCount: 0,
          count: 0,
        });

      return getSentFtpFiles(from, 20, offset, filter, { signal });
    },
    initialData: {
      items: [],
      totalCount: 0,
      filteredCount: 0,
      rangeCount: 0,
      count: 0,
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);
  const isLoaded = loadedStatus === 2;

  const loadData = useCallback<DynamicLoadPagedProps["loadData"]>((rangeFrom, rangeTo) => {
    setFrom(rangeFrom);
  }, []);

  return (
    <ErrorWrap>
      <DynamicLoadPaged
        period="1m"
        loadData={loadData}
        reloadIt={() => refetch()}
        offset={offset}
        setOffset={setOffset}
        loadingState={loadedStatus}
        totalCount={data?.totalCount}
        filteredCount={data?.filteredCount}
        rangeCount={data?.rangeCount}
        cleanFilters={() => form.reset(defaultFilters)}
      />
      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          <Table size="sm">
            <thead className="beGray">
              <FtpReceivedPagedFilters />
            </thead>
            <tbody>
              {data.items.map(function (fs, idx) {
                const bg = idx % 2 === 0 ? "" : "beGray3";
                const events = fs.events?.length > 0 ? fs.events : [{}];
                const myRowSpan = events.length;
                return events.map(function (event, idxEvent) {
                  return (
                    <tr key={idxEvent} className={bg}>
                      {idxEvent === 0 ? (
                        <>
                          <td rowSpan={myRowSpan} className=" align-middle">
                            &nbsp;&nbsp;{fs.user?.name || <>&mdash;</>}&nbsp; ({fs.user?.login || <>&mdash;</>})
                          </td>
                          <td rowSpan={myRowSpan} className="text-center align-middle">
                            {fs.fileName} &nbsp;
                            <Downloader
                              img="/download.svg"
                              height="20"
                              alt="pdf"
                              label={""}
                              path={"/api/ftp/get/sent/" + fs.id}
                              filename={fs.fileName}
                            />
                          </td>
                          <td rowSpan={myRowSpan} className="text-end align-middle">
                            {date_time_format(fs.created)}&nbsp;&nbsp;&nbsp;
                          </td>
                          <td rowSpan={myRowSpan} className="text-end align-middle">
                            {date_time_format(fs.collectedAt)}&nbsp;&nbsp;&nbsp;
                          </td>
                          <td rowSpan={myRowSpan} className="text-center align-middle">
                            {fs.dataSize} B
                          </td>
                          <td rowSpan={myRowSpan} className="text-center align-middle">
                            {warehouses_names_ids_separe[fs.warehouseId]}
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                      <td>{event.name ?? "-"}</td>
                      <td className="text-center">
                        {event.success === true ? "✔️" : event.success === false ? "❌" : "-"}
                      </td>
                      <td>{event.created ? date_time_format(event.created) : "-"}</td>
                      <td>{event.started ? date_time_format(event.started) : "-"}</td>
                      <td>{event.finished ? date_time_format(event.finished) : "-"}</td>
                      <td>{event.trigger ?? "-"}</td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </FormProvider>
      </TableFetchStatusOverlay>
      <Pager
        offset={offset}
        pagesize={20}
        disabled={isLoaded === false}
        total={data?.filteredCount}
        callback={setOffset}
      />
    </ErrorWrap>
  );
}

function FtpReceivedPagedFilters() {
  const { t } = useTranslation();
  const { register, unregister, setValue, watch } = useFormContext<Dto.GetSentFtpRangePagedFilterRequest>();

  useEffect(() => {
    register("warehouseId");

    return () => {
      unregister("warehouseId");
    };
  }, [register, unregister]);

  const warehouseId = watch("warehouseId");

  return (
    <>
      <tr>
        <th rowSpan={2} className="text-center pb-2">
          <Form.Label className="mb-1">{t("sys-from")}</Form.Label>
          <Form.Group controlId="filterSender">
            <Form.Control type="text" placeholder={"🔍 "} {...register("sender")} />
          </Form.Group>
        </th>
        <th rowSpan={2} className="text-center pb-2">
          <Form.Label className="mb-1">{t("sys-filename")}</Form.Label>
          <Form.Group controlId="filterName">
            <Form.Control type="text" placeholder={"🔍 "} {...register("fileName")} />
          </Form.Group>
        </th>
        <th rowSpan={2} className="text-center pb-2 pe-4">
          {t("sys-created")}
        </th>
        <th rowSpan={2} className="text-center pb-2 pe-4">
          {t("picked")}
        </th>
        <th rowSpan={2} className="pb-2">
          <Form.Group controlId="filterSize" className="mb-0">
            <Form.Label className="mb-1">{t("sys-size")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("fileSize", { pattern: /[!=<>]\d+/ })}
            />
          </Form.Group>
        </th>

        <th rowSpan={2} className="text-center pb-2">
          <p className="mb-1">{t("sys-warehouse_ID")}</p>
          <DropdownButton id="filterID" title={warehouseTitlesDropdown[warehouseId] || t("all")} variant="light">
            <Dropdown.Item onClick={() => setValue("warehouseId", undefined)}>{t("all")}</Dropdown.Item>
            {Object.keys(warehouseTitlesDropdown).map((wh, idx) => (
              <Dropdown.Item key={idx} onClick={() => setValue("warehouseId", parseInt(wh))}>
                {warehouseTitlesDropdown[wh]}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </th>
        <th colSpan={6} className="text-center">
          {t("sys-file_action")}
        </th>
      </tr>
      <tr>
        <th className="pb-2">{t("name")}</th>
        <th className="pb-2">{t("rep-result")}</th>
        <th className="pb-2">{t("sys-created")}</th>
        <th className="pb-2">{t("sys-triggered")}</th>
        <th className="pb-2">{t("sys-finished")}</th>
        <th className="pb-2">{t("sys-triggerer")}</th>
      </tr>
    </>
  );
}
