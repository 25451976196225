import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Card, Row, Col, Table, Form } from 'react-bootstrap';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { useTranslation } from 'react-i18next';
import { format_amount, hilite } from '../lib/format';
import { ExcelDownloader } from '../comp/excel';
import { Pager } from '../comp/pager';
import { filter_rule, date_compare, icompare } from '../lib/utils';
import { SortIcons } from '../comp/sort';
import { date_parse_CZ_or_ISO } from '../lib/date-utils';

export function ReportStockPosition({ reportName, userlogin }) {

    const date = useParams()["*"];

    const [data, setData] = useState([]);
    const [loadedStatus, setLoadedStatus] = useState(0);

    useEffect(() => {
        const storno_fetch = his_fetch(
            userlogin,
            [
                {
                    uri: "/api/reporting/" + reportName + "/" + date,
                    json: true,
                    status: setLoadedStatus,
                    ok: (resource, result) => {
                        setData(result.stockpositions);
                    },
                    error: function (resource, reason) {
                        console.log('err: ' + reason);
                    }
                }
            ]
        );
        return () => {
            storno_fetch();
        }
    }, [userlogin, reportName, date]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus}
            loadingType="big"
            errorType="fetcherError" />;
    }


    return (
        <ReportTable data={data} reportName={reportName} date={date} />
    )
}

function ReportTable({ data, reportName, date }) {
    const { t } = useTranslation();

    const [offset, setOffset] = useState(0);
    const [filterFG, setFilterFG] = useState("");
    const [filterIntRefNum, setFilterIntRefNum] = useState("");
    const [filterDateReceipt, setFilterDateReceipt] = useState("");
    const [filterProdDate, setFilterProdDate] = useState("");
    const [filterValidityDate, setFilterValidityDate] = useState("");
    const [filterWh, setFilterWh] = useState("");
    const [filterWhId, setFilterWhId] = useState("");
    const [filterSupplier, setFilterSupplier] = useState("");
    const [filterSupplierId, setFilterSupplierId] = useState("");
    const [filterBatch, setFilterBatch] = useState("");
    const [filterProduct, setFilterProduct] = useState("");
    const [filterProductId, setFilterProductId] = useState("");
    const [filterUnit, setFilterUnit] = useState("");
    const [filterStockQty, setFilterStockQty] = useState("");
    const [filterPurchPriceUnit, setFilterPurchPriceUnit] = useState("");
    const [filterCurrCode, setFilterCurrCode] = useState("");
    const [filterEurPurchPriceUnit, setFilterEurPurchPriceUnit] = useState("");
    const [filterStockValue, setFilterStockValue] = useState("");
    const [filterEurStockValue, setFilterEurStockValue] = useState("");
    const [sortValue, setSortValue] = useState("");
    const [direction, setDirection] = useState("");
    const [type, setType] = useState("");

    const excel_headers = ["IndustryFocusGroup", "InternalReferenceNumber", "DateOfReceipt", "ProductionDate", "ValidityDate", "Warehouse", "WarehouseId", "Supplier", "SupplierId",
        "BatchNumber", "Product", "ProductId", "Unit", "StockQuantity", "PurchasePricePerUnit", "CurrencyCode", "PurchasePriceEurPerUnit", "StockValue", "StockValueEur"];
    const excel_content = data.map((row) =>
        [row.pp_IndustryFocusGroup, row.pp_InternalReferenceNumber, row.DateOfReceipt, row.ProductionDate, row.ValidityDate,
        row.Warehouse, row.WarehouseId, row.Supplier, row.SupplierID, row.BatchNumber, row.Product, row.ProductId,
        row.Unit, row.StockQuantity, row.PurchasePricePerUnit, row.CurrencyCode, row.PurchasePriceEurPerUnit, row.StockValue, row.StockValueEur]);
    const excel_2D_array = [excel_headers, ...excel_content];

    const data_filtered = data.filter(function (row) {
        return (
            filter_rule(filterFG, row.pp_IndustryFocusGroup, true) &&
            filter_rule(filterIntRefNum, row.pp_InternalReferenceNumber, true) &&
            date_compare(row.DateOfReceipt, filterDateReceipt) &&
            date_compare(row.ProductionDate, filterProdDate) &&
            date_compare(row.ValidityDate, filterValidityDate) &&
            filter_rule(filterWh, row.Warehouse, true) &&
            filter_rule(filterWhId, row.WarehouseId, true) &&
            filter_rule(filterSupplier, row.Supplier, true) &&
            filter_rule(filterSupplierId, row.SupplierID, true) &&
            filter_rule(filterBatch, row.BatchNumber, true) &&
            filter_rule(filterProduct, row.Product, true) &&
            filter_rule(filterProductId, row.ProductId, true) &&
            filter_rule(filterUnit, row.Unit, true) &&
            icompare(row.StockQuantity, filterStockQty) &&
            icompare(row.PurchasePricePerUnit, filterPurchPriceUnit) &&
            filter_rule(filterCurrCode, row.CurrencyCode, true) &&
            icompare(row.PurchasePriceEurPerUnit, filterEurPurchPriceUnit) &&
            icompare(row.StockValue, filterStockValue) &&
            icompare(row.StockValueEur, filterEurStockValue)
        );
    });

    const data_sorted = data_filtered.sort(function (a, b) {
        if (type === "date") {
            if (direction === "up") {
				return date_parse_CZ_or_ISO(a[sortValue]) - date_parse_CZ_or_ISO(b[sortValue]);
			}
			if (direction === "down")
				return date_parse_CZ_or_ISO(b[sortValue]) - date_parse_CZ_or_ISO(a[sortValue]);
        }
        if (type === "num") {
            if (direction === "up") {
                return a[sortValue] - b[sortValue];
            }
            if (direction === "down")
                return b[sortValue] - a[sortValue];
        }
        if (type === "letters") {
            if (direction === "up") {
                var nameA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
                var nameB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }
            if (direction === "down") {
                var nameAA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
                var nameBB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
                if (nameAA > nameBB) {
                    return -1;
                }
                if (nameAA < nameBB) {
                    return 1;
                }
                return 0;
            }
        }
        return 0;
    });

    const sliced_data = data_sorted.slice(offset, offset + 20);

    return (
        <>
            <Row>
                <Col xs="auto">
                    <Card body className='mb-1' style={{ width: "auto" }}>
                        <h6 className='bolder'>{t('file_download')}</h6>
                        <ExcelDownloader tableData={excel_2D_array} fileName={reportName + "_" + date + ".xlsx"} />
                    </Card>
                </Col>
                <Col>

                </Col>
                <Col className="text-end pt-5">
                    <br />
                    <br />
                    <Pager offset={offset} pagesize={20} total={data_filtered.length} callback={setOffset} />
                </Col>
            </Row>
            <Table size="sm" striped>
                <thead className='beGray'>
                    <tr>
                        <th className='py-2'>
                            <Form.Group controlId="IndustryFocusGroup" className="mb-0">
                                <Form.Label className="mb-1">IndustryFocusGroup</Form.Label>
                                <SortIcons name={"pp_IndustryFocusGroup"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterFG}
                                    onChange={(ev) => { setFilterFG(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='py-2'>
                            <Form.Group controlId="InternalReferenceNumber" className="mb-0">
                                <Form.Label className="mb-1">InternalReferenceNumber</Form.Label>
                                <SortIcons name={"pp_InternalReferenceNumber"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterIntRefNum}
                                    onChange={(ev) => { setFilterIntRefNum(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="DateOfReceipt" className="mb-0">
                                <Form.Label className="mb-1"> DateOfReceipt</Form.Label>
                                <SortIcons name={"DateOfReceipt"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} dateType />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterDateReceipt}
                                    onChange={(ev) => { setFilterDateReceipt(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="ProductionDate" className="mb-0">
                                <Form.Label className="mb-1"> ProductionDate</Form.Label>
                                <SortIcons name={"ProductionDate"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} dateType />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterProdDate}
                                    onChange={(ev) => { setFilterProdDate(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="ValidityDate" className="mb-0">
                                <Form.Label className="mb-1">ValidityDate</Form.Label>
                                <SortIcons name={"ValidityDate"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} dateType />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterValidityDate}
                                    onChange={(ev) => { setFilterValidityDate(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-center py-2'>
                            <Form.Group controlId="Warehouse" className="mb-0">
                                <Form.Label className="mb-1">Warehouse</Form.Label>
                                <SortIcons name={"Warehouse"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterWh}
                                    onChange={(ev) => { setFilterWh(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-center py-2'>
                            <Form.Group controlId="WarehouseId" className="mb-0">
                                <Form.Label className="mb-1">WarehouseId</Form.Label>
                                <SortIcons name={"WarehouseId"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterWhId}
                                    onChange={(ev) => { setFilterWhId(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='py-2'>
                            <Form.Group controlId="Supplier" className="mb-0">
                                <Form.Label className="mb-1">Supplier</Form.Label>
                                <SortIcons name={"Supplier"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterSupplier}
                                    onChange={(ev) => { setFilterSupplier(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='py-2'>
                            <Form.Group controlId="SupplierId" className="mb-0">
                                <Form.Label className="mb-1">SupplierId</Form.Label>
                                <SortIcons name={"SupplierID"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterSupplierId}
                                    onChange={(ev) => { setFilterSupplierId(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='py-2'>
                            <Form.Group controlId="BatchNumber" className="mb-0">
                                <Form.Label className="mb-1">BatchNumber</Form.Label>
                                <SortIcons name={"BatchNumber"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterBatch}
                                    onChange={(ev) => { setFilterBatch(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='py-2'>
                            <Form.Group controlId="Product" className="mb-0">
                                <Form.Label className="mb-1">Product</Form.Label>
                                <SortIcons name={"Product"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterProduct}
                                    onChange={(ev) => { setFilterProduct(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-center py-2'>
                            <Form.Group controlId="ProductId" className="mb-0">
                                <Form.Label className="mb-1">ProductId</Form.Label>
                                <SortIcons name={"ProductId"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterProductId}
                                    onChange={(ev) => { setFilterProductId(ev.target.value); setOffset(0); }} />
                            </Form.Group></th>
                        <th width="4%" className='py-2'>
                            <Form.Group controlId="Unit" className="mb-0">
                                <Form.Label className="mb-1">Unit</Form.Label>
                                <SortIcons name={"Unit"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterUnit}
                                    onChange={(ev) => { setFilterUnit(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="StockQuantity" className="mb-0">
                                <Form.Label className="mb-1">StockQuantity</Form.Label>
                                <SortIcons name={"StockQuantity"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterStockQty}
                                    onChange={(ev) => { setFilterStockQty(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="PurchasePricePerUnit" className="mb-0">
                                <Form.Label className="mb-1">PurchasePricePerUnit</Form.Label>
                                <SortIcons name={"PurchasePricePerUnit"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterPurchPriceUnit}
                                    onChange={(ev) => { setFilterPurchPriceUnit(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='py-2'>
                            <Form.Group controlId="CurrencyCode" className="mb-0">
                                <Form.Label className="mb-1">CurrencyCode</Form.Label>
                                <SortIcons name={"CurrencyCode"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; "
                                    value={filterCurrCode}
                                    onChange={(ev) => { setFilterCurrCode(ev.target.value); setOffset(0); }} />
                            </Form.Group>

                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="PurchasePriceEurPerUnit" className="mb-0">
                                <Form.Label className="mb-1">PurchasePriceEurPerUnit</Form.Label>
                                <SortIcons name={"PurchasePriceEurPerUnit"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterEurPurchPriceUnit}
                                    onChange={(ev) => { setFilterEurPurchPriceUnit(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="StockValue" className="mb-0">
                                <Form.Label className="mb-1">StockValue</Form.Label>
                                <SortIcons name={"StockValue"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterStockValue}
                                    onChange={(ev) => { setFilterStockValue(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                        <th className='text-end py-2'>
                            <Form.Group controlId="StockValueEur" className="mb-0">
                                <Form.Label className="mb-1">StockValueEur</Form.Label>
                                <SortIcons name={"StockValueEur"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                <Form.Control type="text" className='mt-2'
                                    placeholder="&#128269; > < ="
                                    value={filterEurStockValue}
                                    onChange={(ev) => { setFilterEurStockValue(ev.target.value); setOffset(0); }} />
                            </Form.Group>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sliced_data.map(function (row, idx) {
                        return (
                            <tr key={idx}>
                                <td className='align-middle'>{hilite(row.pp_IndustryFocusGroup || "", filterFG)}</td>
                                <td className='align-middle'>{hilite(row.pp_InternalReferenceNumber || "", filterIntRefNum)}</td>
                                <td className='align-middle text-end'>{row.DateOfReceipt}</td>
                                <td className='align-middle text-end'>{row.ProductionDate}</td>
                                <td className='align-middle text-end'>{row.ValidityDate}</td>
                                <td className='align-middle text-center'>{hilite(row.Warehouse || "", filterWh)}</td>
                                <td className='align-middle text-center'>{hilite(row.WarehouseId || "", filterWhId)}</td>
                                <td className='align-middle'>{hilite(row.Supplier || "", filterSupplier)}</td>
                                <td className='align-middle'>{hilite(row.SupplierID || "", filterSupplierId)}</td>
                                <td className='align-middle'>{hilite(row.BatchNumber || "", filterBatch)}</td>
                                <td className='align-middle'>{hilite(row.Product || "", filterProduct)}</td>
                                <td className='align-middle text-center'>{hilite(String(row.ProductId) || "", filterProductId)}</td>
                                <td className='align-middle'>{hilite(row.Unit || "", filterUnit)}</td>
                                <td className='align-middle text-end'>{row.StockQuantity}</td>
                                <td className='align-middle text-end'>{row.PurchasePricePerUnit ? format_amount(row.PurchasePricePerUnit) : ""}</td>
                                <td className='align-middle'>{hilite(row.CurrencyCode || "", filterCurrCode)}</td>
                                <td className='align-middle text-end'>{row.PurchasePriceEurPerUnit ? format_amount(row.PurchasePriceEurPerUnit) : ""}</td>
                                <td className='align-middle text-end'>{row.StockValue ? format_amount(row.StockValue) : ""}</td>
                                <td className='align-middle text-end'>{row.StockValueEur ? format_amount(row.StockValueEur) : ""}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    )
}