/**
 * Button and handling for downloading excel files.
 * @module comp/excel
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import { Button, Image } from 'react-bootstrap';
const XLSX = require('xlsx');
//const JS_XLSX = require('js-xlsx');

//seen all - OK

/**
 * Excel file downloader button.
 * 
 * @param {any} tableData - table data as array of arrays
 * @param {string} fileName - name of excel file that downloads
 * @returns {component}
 */
export function ExcelDownloader({tableData, fileName}){

	function excel_format(source_array) {
		return source_array.map((row) => row.map((cell) => typeof cell === 'number' ? parseFloat(cell.toFixed(2)) : cell));
	}

    function s2ab(s) {
		var buf = new ArrayBuffer(s.length);
		var view = new Uint8Array(buf);
		for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
		return buf;
	}

	var wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };

	// Save the XLSX File
	function kocicka() {
		let formatedData = excel_format(tableData);

		let workbook = XLSX.utils.book_new();

		// For testing purposes
		/*
		let worksheetData = {
			'!ref': 'A1:B1',
			'A1': {
				t: 's',
				v: 'Kočka',
				s: {
					font: {
						name: 'Verdana',
						sz: 20,
						bold: true,
						underline: true,
						italic: true,
						color: { rgb: 'FF12A304' },	// Hex Value: ARGB
					}
				}
			},
			'B1': {
				t: 's',
				v: 'Pes',
				s: {
					font: {
						name: 'Times New Roman',
						sz: 10,
						strike: true,
					}
				}
			},
		};
		*/


		let sheetData = XLSX.utils.aoa_to_sheet(formatedData);

		XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet 1');

		// Add the sheet to the workbook
		var wbout = XLSX.write(workbook, wopts);

		let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: "" }));
		let a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		a.click();

		/*
		XLSX.writeFile(workbook, 'FILE_NAME.xlsx')

		new Blob([s2ab(wbout)],{type:""})
		var wbout = XLSX.write(workbook,wopts);
		*/
	}

    return (
        <Button onClick={kocicka} variant="light" className='float-end m-1'><Image height={40} src={"/application-excel.svg"} />&nbsp;{fileName}</Button>
    );
}