/**
 * List of issued invoices
 * @module invoices/invoices-issued
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { format_amount } from '../lib/format';
import { LinkContainer } from 'react-router-bootstrap';
import { Pager } from '../comp/pager';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import { filter_rule } from '../lib/utils';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { date_formatCZ } from '../lib/date-utils';
import { his_fetch } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { currencyTitles } from '../lists/currency_titles';
import { insuranceTitles } from '../lists/insurance_titles';
import { useTranslation } from 'react-i18next';
import { insurance_badges } from '../lists/insurance-badges';

//seen all - OK

/****************************************************************
 * 0 KodDokladu
 * 1 DatDokladu
 * 2 CastkaCelkemMen
 * 3 KodMeny
 * 4 KodPartnera
 * 5 Pojisteno (jmenovity limit)
 * 6 FIC (jmenovity limit)
 * 7 ca.balance (autolimit pojistene saldo ke dni vystaveni)
 * 8 ca.autolimit (vyse autolimitu ke dni vystaveni)
 * 9 insured (pojisteno autolimitem)
 * 10 auto_country_ok (autolimit status 4 if false - cannot be automatically insured)
 */

export function InvoicesIssued({ userlogin }) {
	const { t } = useTranslation();

	const [invoices, setInvoices] = useState([]);
	const [totalCount, setTotalCount] = useState(null);
	const [oldest, setOldest] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterCode, setFilterCode] = useState("");
	const [filterPartner, setFilterPartner] = useState("");
	const [filterCurrency, setFilterCurrency] = useState("");
	const [filterInsurance, setFilterInsurance] = useState("");
	const [massFilterApp, setMassFilterApp] = useState("");
	const [loadedStatus, setLoadedStatus] = useState(0);

	const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
		return (
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/invoices/issued/range/" + startDate + "/" + endDate,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							if (result.error) {
								result_received(false);
							} else {
								setInvoices(invoicesCat => (reload ? [] : invoicesCat).concat(result.result));
								setTotalCount(result.total_count);
								setOldest(result.oldest[1]);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin])

	const cleanFilters = () => {
		setFilterCode("");
		setFilterPartner("");
		setFilterCurrency("");
		setFilterInsurance("");
		setMassFilterApp("");
	}

	const handleChangeCode = function (event) {
		setFilterCode(event.target.value);
		setOffset(0);
	}

	const handleChangePartner = function (event) {
		setFilterPartner(event.target.value);
		setOffset(0);
	}

	const handleChangeCurrency = function (val) {
		setFilterCurrency(val);
		setOffset(0);
	}

	const handleChangeInsurance = function (val) {
		setFilterInsurance(val);
		setOffset(0);
	}

	const handleChangeAppFilter = function (event) {
		setMassFilterApp(event.target.value);
		setOffset(0);
	}

	const listinvoices = invoices.filter(
		function (inv) {
			return (
				(filter_rule(filterCode, inv[0])) &&
				(filter_rule(filterPartner, inv[4])) &&
				((filterInsurance === "") || (get_insurance_status(inv[6], inv[5], inv[9], inv[10]) === filterInsurance)) &&
				((filterCurrency === "") || (inv[3] === filterCurrency)) &&
				((filter_rule(massFilterApp, inv[0])) ||
					(filter_rule(massFilterApp, inv[4])))
			);
		});
	const show_invoices = listinvoices.slice(offset, offset + 20);


	return (
		<ErrorWrap>
			<DynamicLoad period='1y' loadData={loadData}
				filtered_data={listinvoices} data={invoices}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount} oldest={oldest}
				cleanFilters={cleanFilters}
			/>
			<div className="d-none d-md-block">
				<Table hover size="sm" striped >
					<thead>
						<tr className="beGray">
							<th className="text-center pb-3 pt-2" scope="col">
								<p className="mb-1">{t('doc_code')}</p>
								<Form.Group controlId="filterCode">
									<Form.Control type="text" placeholder="&#128269;" value={filterCode} onChange={handleChangeCode} />
								</Form.Group>
							</th>
							<th className="text-center align-middle" scope="col">{t('date')}</th>
							<th className="text-center align-middle pb-3 pt-2" scope="col">
								<Form.Group controlId="filterPartner">
									<p className="mb-1">{t('partner')}</p>
									<Form.Control type="text" placeholder="&#128269;" value={filterPartner} onChange={handleChangePartner} />
								</Form.Group></th>
							<th className="align-middle text-end" scope="col">{t('biz-amount_money')}&nbsp;&nbsp;&nbsp;&nbsp;</th>
							<th className="align-middle pb-3 pt-2" scope="col">
								<p className="mb-1">{t('biz-currency')}</p>
								<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light">
									{Object.keys(currencyTitles)
										.map((title, idx) =>
											<Dropdown.Item key={idx} onClick={() => handleChangeCurrency(title)} >{t(currencyTitles[title])}</Dropdown.Item>
										)}
								</DropdownButton></th>
							<th className="text-center align-middle pb-3 pt-2">
								<p className="mb-1">{t('menu-ins')}</p>
								<DropdownButton id="filterInsurance" title={t(insuranceTitles[filterInsurance])} variant="light">
									<Dropdown.Item onClick={() => handleChangeInsurance("")}>{t('all')}</Dropdown.Item>
									{insurance_badges.map((itm) => <Dropdown.Item key={itm.status} onClick={() => handleChangeInsurance(itm.status)}><Badge bg={itm.bg} text={itm.color} className='beGray' style={{ width: "90px" }}>{t(itm.label)}</Badge> {t(itm.explanation)}</Dropdown.Item>)}
								</DropdownButton>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							show_invoices.map(
								function (inv) {
									return (
										<LinkContainer to={{ pathname: "/partners/invoices-issued/" + inv[0] }} key={inv[0]}>
											<tr>
												<td>{inv[0]}</td>
												<td className="text-center">{date_formatCZ(inv[1])}</td>
												<td className="text-center">{inv[4]}</td>
												<td className="text-end">{format_amount(inv[2])}</td>
												<td>{inv[3]}</td>
												<td className="text-center"><Insured3 status={get_insurance_status(inv[6], inv[5], inv[9], inv[10])} /></td>
											</tr>
										</LinkContainer>
									);
								})
						}
					</tbody>
				</Table>
			</div>
			< InvoicesIssuedMobile
				show_invoices={show_invoices}
				handleChangeCurrency={handleChangeCurrency}
				handleChangeInsurance={handleChangeInsurance}
				currencyTitles={currencyTitles}
				filterCurrency={filterCurrency}
				insuranceTitles={insuranceTitles}
				filterInsurance={filterInsurance}
				massFilterApp={massFilterApp}
				handleChangeAppFilter={handleChangeAppFilter}
				offset={offset}
				setOffset={setOffset} />
			<LoadingDataInfo loadedStatus={loadedStatus} data={invoices} />
			<Pager offset={offset} pagesize={20} total={listinvoices.length} callback={setOffset} />
		</ErrorWrap>
	);
}


function InvoicesIssuedMobile({ show_invoices, handleChangeCurrency, handleChangeInsurance, currencyTitles,
	filterCurrency, insuranceTitles, filterInsurance, massFilterApp, handleChangeAppFilter }) {
	const { t } = useTranslation();

	return (
		<ErrorWrap>
			<div className="d-md-none" >
				<br />
				<p className="mb-1">{t('mass_filter')}</p>
				<Form.Group controlId="filterCodex" className="mb-3">
					<Form.Control type="text" placeholder={"🔍 " + t('doc_code_partner')} value={massFilterApp} onChange={handleChangeAppFilter} />
				</Form.Group>
				<Row>
					<Col>
						<p className="mb-1">{t('biz-currency')}</p>
						<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light" className="sto beGrayButton">
							{Object.keys(currencyTitles)
								.map((title, idx) =>
									<Dropdown.Item key={idx} onClick={() => handleChangeCurrency(title)} >{t(currencyTitles[title])}</Dropdown.Item>
								)}
						</DropdownButton>
					</Col>
					<Col>
						<p className="mb-1">{t('menu-ins')}</p>
						<DropdownButton id="filterInsurance" title={t(insuranceTitles[filterInsurance])} variant="light" className="sto beGrayButton">
							<Dropdown.Item onClick={() => handleChangeInsurance("")}>{t('all')}</Dropdown.Item>
							{insurance_badges.map((itm) => <Dropdown.Item key={itm.status} onClick={() => handleChangeInsurance(itm.status)}><Badge bg={itm.bg} text={itm.color} className='beGray' style={{ width: "90px" }}>{t(itm.label)}</Badge> {t(itm.explanation)}</Dropdown.Item>)}
						</DropdownButton>
					</Col>
				</Row>
				<br />
				<Table striped >
					<tbody>
						{
							show_invoices.map(
								function (inv) {
									return (
										<LinkContainer to={{ pathname: "/partners/invoices-issued/" + inv[0] }} key={inv[0]}>
											<tr>
												<td>
													<Row>
														<Col className="beBigger me-1">{inv[0]}</Col>
														<Col className="text-end">{format_amount(inv[2])}&nbsp;{inv[3]}</Col>
													</Row>
													<Row>
														<Col>{date_formatCZ(inv[1])}</Col>
														<Col className="text-end">{inv[4]}</Col>
													</Row>
													<Col className="text-end p-0">
														<Insured3 status={get_insurance_status(inv[6], inv[5], inv[9], inv[10])} />
													</Col>
												</td>
											</tr>
										</LinkContainer>
									);
								})
						}
					</tbody>
				</Table>
			</div>
		</ErrorWrap>
	);
}