import { authorizedFetch } from "./common";

export function getDeliveryNotes(
  type: "PD" | "ND",
  from: string,
  to: string,
  take: number = 10,
  skip: number = 0,
  filter: unknown = {},
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetDeliveryNotesRangePagedItemRangePagedResponse>(
    `/api/${type === "ND" ? "purchase-" : ""}delivery-notes/range/${from}/${to}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    { ...options, method: "GET" }
  );
}

export function getDeliveryNotesFrom(
  type: "PD" | "ND",
  from: string,
  take: number = 10,
  skip: number = 0,
  filter: unknown = {},
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetDeliveryNotesRangePagedItemRangePagedResponse>(
    `/api/${type === "ND" ? "purchase-" : ""}delivery-notes/range/${from}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    { ...options, method: "GET" }
  );
}

export function setDeliveryNoteStatus(id: number, wantedState: number, options?: RequestInit) {
  return authorizedFetch<Dto.DeliveryNoteSetStatusResponse>(`/api/delivery-notes/${id}/set-status/${wantedState}`, {
    ...options,
    method: "GET",
  });
}

export function getDeliveryNotesTracking(options?: RequestInit) {
  return authorizedFetch<Dto.StockoutLog[]>("/api/delivery-notes/tracking", {
    ...options,
    method: "GET",
  });
}

export function getDeliveryNoteDetail(code: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetDeliveryNoteDetailResponse>(`/api/delivery-notes/${code}/new`, {
    ...options,
    method: "GET",
  });
}

export function getDeliveryNoteStatusLogs(code: string, options?: RequestInit) {
  return authorizedFetch<Dto.StockoutLog[]>(`/api/delivery-notes/${code}/status-logs/new`, {
    ...options,
    method: "GET",
  });
}

export function sendDeliveryNote(code: string, options?: RequestInit) {
  return authorizedFetch<Dto.PutSendDeliveryNoteResponse>(`/api/delivery-notes/${code}/send`, {
    ...options,
    method: "PUT",
  });
}

export function cancelSendDeliveryNote(code: string, options?: RequestInit) {
  return authorizedFetch<Dto.PutSendDeliveryNoteResponse>(`/api/delivery-notes/${code}/send-cancel`, {
    ...options,
    method: "PUT",
  });
}

export function getPurchaseDeliveryNoteDetail(code: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetPurchaseDeliveryNoteDetailResponse>(`/api/purchase-delivery-notes/${code}/new`, {
    ...options,
    method: "GET",
  });
}
