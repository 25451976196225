/**
 * Report configuration manipulation utilities.
 * @file
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { filter_rule } from '../lib/utils';
import { useTranslation } from 'react-i18next';
import {
	getAvailableFormulaElementConditions, getFormulaElementSign,
	getFormulaElementCondition, getAvailableFormulaElementSrcIds,
} from './report-utils';

/**
 * Edit form for one element of one formula (row).
 *
 * @param {function} onChangeSign - gets called when user clicks on [+] or [-]
 * @param {number} coef - current coefficient: 1 or -1, 2 or -2, 3 or -3
 * @param {string} activeId - what is the current id (necessary only for select list)
 * @param {array} allSrcIds - all possible srcIds from fetched config and balances 
 * @param {function} onChangeId - gets called if user picks different source id from the list
 * @param {function} onDelete - gets called when user wants to delete this element
 * @param {boolean} multi - to allow multiple selections
 * @param {dictionary} config - for SignOptions component, who has to offer option based on usage of srcIds in config
 * @param {boolean} conditionsDisabled - when adding new term, disable choosing condition
 * @return {component}
 */
//celý výběrový formulář - ident a coef a znamínko
export function MappingEditForm({
	onChangeSign, coef, activeId, allSrcIds, onChangeId, onDelete, multi,
	normalView, relatedId, config, conditionsDisabled, availableDstIds
}) {

	/*
	console.log("+++++++++++++MappingEditForm++++++++++++++")
	console.log(allSrcIds);
	console.log("activeId: " + activeId); // this is dstId in case of transposed and srcId in case of normal - its correct
	console.log("relatedId: " + relatedId); // this is dstId in case of normal and srcId in case of transposed - its correct
	console.log("current coef: " + coef);
	*/

	//Gives allowed options to set a sign condition, getAvailableFormulaElementConditions(config, dstId, srcId);
	const allowedSingOptions = normalView ?
		getAvailableFormulaElementConditions(config, relatedId, activeId)
		:
		getAvailableFormulaElementConditions(config, activeId, relatedId);

	return (
		<Row>
			<Col>
				<IdentFilterForm activeId={activeId}
					allSrcIds={allSrcIds}
					availableDstIds={availableDstIds}
					multi={multi}
					onChangeId={onChangeId}
					config={config}
					activeDst={relatedId}
					normalView={normalView} />
			</Col>
			<Col>
				<AccountingForm coef={coef}
					onClickSign={onChangeSign}
					onClickDelete={onDelete ? () => onDelete(activeId) : false}
					activeId={activeId}
					normalView={normalView}
					relatedId={relatedId}
					allowedSingOptions={allowedSingOptions}
					conditionsDisabled={conditionsDisabled}
				/>
			</Col>
		</Row>
	);
}

/**
 * Component to allow selecting from a list of ids. It uses multiple
 * prop so that nothing needs to be selected at the beginning. However
 * the code ensures only one item is selected.
 *
 * @param {string} activeId - what is the current id (necessary only for select list)
 * @param {array} allSrcIds - all possible srcIds from fetched config and balances 
 * @param {function} onChangeId - gets called if user picks different source id from the list
 * @param {boolean} multi - to allow multiple selections
 * @return {component}
 */
// area s nabídkou identů a filtrem pro ně
export function IdentFilterForm({ activeId, allSrcIds, onChangeId, multi, config, activeDst,
	normalView, availableDstIds }) {
	const [filterId, setFilterId] = useState("");

	// Get available based on config
	const availableIds = normalView ?
		getAvailableFormulaElementSrcIds(allSrcIds, config, activeDst, activeId) //potřebujeme tu předávat ty 2 poslední parametry?
		:
		availableDstIds; //sem patří funkce, co vyloučí nejvýše jedno dstId, které má opačnou hodnotu podmíněného koeficientu  //getAvailableFormulaElementDstIds
	//console.log(availableIds);

	// Add activeId if missing
	const allIds = (activeId ? [...availableIds, ...(multi ? activeId : [activeId])] : availableIds)
		.filter((v, i, a) => a.indexOf(v) === i)
		.sort();

	// Apply filter on the result
	const filteredIds = allIds.filter((id) => filter_rule(filterId, id, true));

	function onSelected(ev) {
		if (multi) {
			const selectedIds = [].slice.call(ev.target.selectedOptions).map(item => item.value);
			onChangeId(selectedIds);
		} else {
			onChangeId(ev.target.value);
		}
	}

	return (
		<>
			<Form.Group controlId="filterId" className='mt-3 mb-2'>
				<Form.Control type="text"
					placeholder="&#128269;"
					value={filterId}
					onChange={(ev) => setFilterId(ev.target.value)} />
			</Form.Group>
			<Form.Group controlId="selectId">
				<Form.Control as="select"
					htmlSize={5}
					multiple
					onChange={onSelected}
					value={multi ? activeId || [] : [activeId]}>
					{filteredIds.map(function (id, idx) {
						return (
							<option value={id}
								key={idx}>{id}</option>
						);
					})}
				</Form.Control>
			</Form.Group>
		</>
	);
}

/**
 * Form controlling a single relation between source and destination
 * with given coefficient. The same UI can be used in both forward and
 * transposed configuration editor this way.
 *
 * @param {number} coef - current coefficient for this mapping
 * @param {function} onClickSign - gets called with -1/1 based on which sign the user clicked
 * @param {function} onClickDelete - gets called when user clicks the delete button
 * @param {string} label - to customize the label shown above the SignButtons
 * @param {string} deleteLabel - customization of the delete button label
 * @param {string} activeId - id of subject of this form
 * @param {boolean} normalView - where is it used - options are normal and transposed view
 * @param {boolean} conditionsDisabled - when adding new term, conditions cannot be edited
 * @return {component}
 */
//výběr znamínka a koeficientu - podmínky
export function AccountingForm({
	coef, onClickSign, onClickDelete, label = "rep-sign", deleteLabel = 'rep-remove',
	activeId, normalView, relatedId, allowedSingOptions, conditionsDisabled
}) {
	const { t } = useTranslation();

	//console.log(activeId + " " + relatedId); 

	const title = normalView ?
		t('rep-delete_source_ident') + " " + activeId + " " + t('rep-from_destination_ident_formula') + " " + relatedId + "."
		:
		t('rep-delete_source_ident') + " " + relatedId + " " + t('rep-from_formula_for_destination_id') + " " + activeId + ".";
	return (
		<>
			<Row>
				<Col xs="auto">
					<div>
						{t(label)}
					</div>
					<div>
						<SignButtons coef={coef}
							onClick={onClickSign}
							conditions={allowedSingOptions}
						/>
					</div>
					<div className="mt-5 pt-5">
						{onClickDelete ?
							<>
								<Button variant="light" className='border' size="sm"
									onClick={() => onClickDelete()}
									title={title}>&#x1f5d1; {t(deleteLabel)}</Button>
							</> : <></>}
					</div>
				</Col>
				<Col className='ps-5'>
					<div>
						{t("rep-sign_options")}
					</div>
					<TermConditionChooser onClick={onClickSign}
						options={allowedSingOptions}
						disabled={conditionsDisabled}
						coef={coef} />
				</Col>
			</Row>

		</>
	);
}

/**
 * Plus/minus sign buttons showing current coefficient and allowing to
 * set it to 1 or -1.
 *
 * @param {number} coef - the current coefficient
 * @param {function} onClick - the function that gets called with 1/-1 when user clicks particular sign
 * @return {component}
 */
function SignButtons({ coef, onClick, conditions }) {
	// When adding, we choose the first available
	const coefToReturn = coef === null ? conditions[0] : coef;
	return (
		<>
			<Button size="sm"
				className='m-1'
				variant={(coef > 0 ? "" : "outline-") + "success"}
				onClick={() => onClick ? onClick(Math.abs(coefToReturn)) : null}>
				+
			</Button>
			<Button size="sm"
				className='m-1'
				variant={(coef < 0 ? "" : "outline-") + "danger"}
				onClick={() => onClick ? onClick(-Math.abs(coefToReturn)) : null}>
				&ndash;
			</Button>
		</>
	);
}

/**
 * Component for choosing condition for given formula term.
 * 
 * @param {function} onClick - gets called with new value
 * @param {array} options - list of absolute numbers representing valid conditions
 * @param {number} coef - currenct coeficient, can be 1/-1,2,-2,3,-3
 * @returns {component}
 */
function TermConditionChooser({ onClick, options, coef, disabled }) {
	const { t } = useTranslation();

	// Extract both attributes
	const currectConditionOption = coef === null ? options[0] : getFormulaElementCondition(coef);
	const sign = coef === null ? null : getFormulaElementSign(coef);

	return (
		<Form.Group controlId="display">
			<Form.Check name="SignOptions" type="radio" value={1} id='1' label={t('rep-without_condition')}
				onClick={() => onClick(1 * sign)}
				checked={currectConditionOption === 1}
				disabled={(!options.includes(1)) || disabled} readOnly />
			<Form.Check name="SignOptions" type="radio" value={2} id='2' label={t('rep_only_if_acc_balance_is') + " >= 0"}
				onClick={() => onClick(2 * sign)}
				checked={currectConditionOption === 2}
				disabled={(!options.includes(2)) || disabled} readOnly />
			<Form.Check name="SignOptions" type="radio" value={3} id='3' label={t('rep_only_if_acc_balance_is') + " < 0"}
				onClick={() => onClick(3 * sign)}
				checked={currectConditionOption === 3}
				disabled={(!options.includes(3)) || disabled} readOnly />
		</Form.Group>
	);
}
