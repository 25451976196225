/**
 * Icons for sorting filters.
 * @module comp/sort
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React from 'react';
import { FaSortAlphaDownAlt, FaSortAlphaDown, FaSortAmountDownAlt, FaSortAmountDown } from "react-icons/fa";

//seen all - OK

export function SortIcons({ name, sortValue, setSortValue,
    direction, setDirection, setType, numeric = false, dateType = false
}) {

    const type = numeric ? "num" : dateType ? "date" : "letters";
    const icon1 = numeric || dateType ? <FaSortAmountDownAlt /> : <FaSortAlphaDown />;
    const icon2 = numeric || dateType ? <FaSortAmountDown /> : <FaSortAlphaDownAlt />;
    const active1 = direction === "up";
    const active2 = direction === "down";
    const color1 = active1 && sortValue === name ? "text-white bg-dark" : "";
    const color2 = active2 && sortValue === name ? "text-white bg-dark" : "";

    return (
        <p className='mb-0'>
            <span style={{ cursor: "pointer" }} className={color1 + " p-1 my-3 d-inline"} onClick={() => {
                setSortValue(name); setDirection("up"); setType(type);
            }}>{icon1}</span>
            &nbsp;&nbsp;
            <span style={{ cursor: "pointer" }} className={color2 + " p-1 my-3 d-inline"} onClick={() => {
                setSortValue(name); setDirection("down"); setType(type);
            }}>{icon2}</span>
        </p>
    );
}