/**
 * Error wrapper which shows error in user-friendly way and prevents system from crash.
 * @module comp/errorwrap
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from 'react';
import { Alert } from 'react-bootstrap';

//seen all - OK

/**
 * ErrorWrapper which displays Alert, when component fails to render. Can be used around any component. Written with classes on purpose.
 * @return  - Alert component
 */
export class ErrorWrap extends React.Component { ////how-to-trans? - zatím česky, nějaké řešení další fázi
	
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null
		};
	}

	componentDidCatch(error, info) {
		this.setState({
			hasError: true,
			error: error,
			errorInfo: info
		});
	}


	render() {
		if (this.state.hasError) {
			return (
				<Alert variant='danger' className="p-4">
					<h2>Něco se nepovedlo... 😔</h2>
					<p>Pošlete nám prosím snímek obrazovky.</p>
					<div className='text-muted'>
					<h5 className='mb-3'>Informace pro vývojáře</h5>
					<span>Název chyby: {this.state.error && this.state.error.toString()}</span>
					<p style={{ whiteSpace: 'pre-wrap' }}>
						{this.state.errorInfo.componentStack}
					</p>
					</div>
				</Alert>
			);
		} else {
			return this.props.children;
		}
	}
}

