import { QueryStatus, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";

type QueryLoadDataReturn<T> = [
  (startDate: string, endDate: string, resultReceived?: (b: boolean) => void) => void,
  data: T,
  state: QueryStatus,
  isFetching: boolean,
  refetch: () => void,
];

export function useQueryLoadData<T extends { items?: object[] | null; totalCount?: number; oldest?: unknown }>(
  mainKey: string,
  getRange: (from: string, to: string, o?: RequestInit) => Promise<T>,
  defaultValue: T
): QueryLoadDataReturn<T> {
  const [from, setFrom] = useState<string>(undefined);
  const [to, setTo] = useState<string>(undefined);

  const { data, refetch, status, isFetching } = useQuery({
    queryKey: [mainKey, from, to],
    queryFn: ({ signal }) => {
      if (!from || !to) return Promise.resolve<T>(defaultValue);
      return getRange(from, to, { signal });
    },
    initialData: defaultValue,
  });

  const loadData = useCallback(
    (startDate: string, endDate: string) => {
      setFrom(startDate);
      setTo(endDate);
    },
    [setFrom, setTo]
  );

  return [loadData, data, status, isFetching, refetch];
}
