/**
 * Transposed configuration view editor.
 * @file
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { Button, Table, Form, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { filterReportConfigSrc } from './report-config-utils';
import { FormulaElement, WholeFormula } from './report-config-formula';
import { MappingEditForm } from './report-config-forms';
import { filter_rule, filter_rules } from '../lib/utils';
import { useTranslation } from 'react-i18next';
import { getAvailableFormulaElementDstIds, isSrcIdUsedInsufficiently } from './report-utils';
import { Pager } from '../comp/pager';
import { ItemsAndFiltered } from '../comp/dynamic-load';
import { existing_titles } from '../lists/reporting_titles';

/**
 * Configuration viewer and editor with source identifiers being on
 * left-hand side.
 *
 * @param {any} config - the whole configuration
 * @param {any} setup - meta configuration of the configuration editor with identifiers
 * @param {function} updateConfig - the universal config modification function
 * @param {boolean} multi - if true, multiple mappings are possible in transposed view
 * @param {boolean} readonly - if true, no editing enabled
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {any} allSrcIds - array of all srcIds used in configuration and present in setup
 * @param {any} accountNames - names of accounts to display in transposed config
 * @return {component}
 */
export function TransposedConfig({ config, setup, updateConfig, multi, readonly, srcToDst, allSrcIds, accountNames, keyName }) {
	const { t } = useTranslation();

	/*
	console.log("+++++++++++transposed+++++++++++");
	console.log(allSrcIds);
	*/

	// Which row is currently being edited (possibly)
	const [current, setCurrent] = useState(null);

	const [filterSrc, setFilterSrc] = useState("");
	const [filterDst, setFilterDst] = useState("");
	const [filterExisting, setFilterExisting] = useState("1"); // 1 is all
	const [offset, setOffset] = useState(0);

	// Filtering
	const filteredSrcIds = allSrcIds
		.filter((srcId) =>
			filter_rule(filterSrc, srcId, true) &&
			// filter_rules(filterDst, Object.keys(srcToDst[srcId])) &&
			(
				(filterDst === "") ||
				((filterDst === "-") && isSrcIdUsedInsufficiently(srcToDst, srcId)) ||
				filter_rules(filterDst, Object.keys(srcToDst[srcId]))
			) &&
			((filterExisting === "1") ||
				((filterExisting === "2") && (setup.srcIds.indexOf(srcId) < 0)) || //not existing
				((filterExisting === "3") && (setup.srcIds.indexOf(srcId) >= 0)) //existing
			));

	const slicedSrcIds = filteredSrcIds.slice(offset, offset + 10);

	//filter rule pro isExisting

	// Switches between different rows
	function onRowActivated(srcId) {
		setCurrent(srcId);
	}

	const cleanFilters = () => {
		setFilterSrc("");
		setFilterDst("");
		setFilterExisting("1");
	}

	return (
		<>
			<Row>
				<Col xs="12" md="4" ></Col>
				<Col xs="12" md="4" className='text-center'>
					<ItemsAndFiltered filtered_data={filteredSrcIds} data={allSrcIds} cleanFilters={cleanFilters} itemsName="rep-source_idents" />
				</Col>
				<Col xs="12" md="4" >
					<Pager offset={offset} pagesize={10} total={filteredSrcIds.length} callback={setOffset} />
				</Col>
			</Row>
			<Table bordered style={{ backgroundColor: "white" }}>
				<thead className='beGray'>
					<tr>
						<th>{t('name')}</th>
						<th className="w-25">
							<Row>
								<Col>
									{t('rep-source_ident')}
									<Form.Control type="text"
										placeholder="&#128269;"
										value={filterSrc}
										onChange={(ev) => setFilterSrc(ev.target.value)} />
								</Col>
								<Col>
									<p className="mb-0">{t('rep-existing_accounts')}</p>
									<DropdownButton className='sto' id="isExisting" title={t(existing_titles[filterExisting])} variant="light">
										<Dropdown.Item onClick={() => setFilterExisting("1")}>{t('all')}</Dropdown.Item>
										<Dropdown.Item onClick={() => setFilterExisting("2")}>{t('rep-non_existing')}</Dropdown.Item>
										<Dropdown.Item onClick={() => setFilterExisting("3")}>{t('rep-existing')}</Dropdown.Item>
									</DropdownButton>
								</Col>
							</Row>

						</th>
						<th>
							{t('rep-asign')}
							<Form.Control type="text"
								placeholder="&#128269;"
								value={filterDst}
								onChange={(ev) => setFilterDst(ev.target.value)} />
						</th>
					</tr>
				</thead>
				<tbody>
					{slicedSrcIds.map((srcId, idx) =>
						<TransposedConfigRow ident={srcId}
							config={filterReportConfigSrc(config, srcId)}
							updateConfig={updateConfig}
							onActivate={onRowActivated}
							active={current === srcId}
							setup={setup}
							multi={multi}
							readonly={readonly}
							srcToDst={srcToDst}
							key={idx}
							allDstIds={getAvailableFormulaElementDstIds(config, srcId)}
							allSrcIds={allSrcIds}
							accountNames={accountNames}
							keyName={keyName} />)}
				</tbody>
			</Table>
		</>
	);
}

/**
 * Single row of the transposed configuration table allowing edit.
 *
 * @param {string} ident - the source identifier
 * @param {any} config - the configuration relevant to this source ident
 * @param {function} onActivate - gets called when user interaction happens
 * @param {boolean} active - true if last user interaction was here
 * @param {function} updateConfig - the universal update config function
 * @param {boolean} multi - if true, multiple mappings are fine in transposed view row
 * @param {any} setup - the meta-configuration
 * @param {boolean} readonly - if true, no editing allowed
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {any} accountNames - names of accounts to display in transposed config
 * @return {component}
 */
function TransposedConfigRow({ ident, config, onActivate, active, updateConfig, multi, setup, readonly, 
	srcToDst, allSrcIds, allDstIds, accountNames, keyName }) {
	const { t } = useTranslation();
	//console.log("ident in Transposed config row, which shoul be source ident: " + ident); //this is correct
	// Currently selected formula in this row
	const [current, setCurrent] = useState(null);

	// Are we adding now?
	const [adding, setAdding] = useState(false);
	const [addingDstId, setAddingDstId] = useState(null);
	const [addingSign, setAddingSign] = useState(null);

	// All names of formulae with this ident in src part
	const thisDstIds = Object.keys(config).sort();
	//const freeDstIds = (setup.dstIds || []).filter((v) => thisDstIds.indexOf(v) < 0);

	// Is this srcId known?
	const unknown = (setup.srcIds || []).indexOf(ident) < 0;

	// There may be more formulae!
	function onFormulaActivated(dstId) {
		onActivate(ident);
		setCurrent((dstId === current) && active ? null : dstId);
	}

	// Just changes it in the global configuration
	function onSignClicked(coef) {
		updateConfig(current, ident, coef);
	}

	// Setting coefficient to 0 removes it from given formula
	function onDeleteClicked(dstId) {
		updateConfig(dstId, ident, 0);
	}

	// Tries to keep "focus" by making sure the swap is recorded in
	// current as well.
	function onIdChanged(newDstId) {
		updateConfig(current, ident, 0, newDstId, ident, config[current][ident]);
		setCurrent(newDstId);
	}

	// Allow creating new mapping if none present or if duplicates are allowed
	const showAdd = (isSrcIdUsedInsufficiently(srcToDst, ident) || multi) && !readonly;

	// May trigger re-render! But required as adding form is different
	// to actual formula edit.
	if ((current !== null) && adding && active) {
		setAdding(false);
	}

	// On/off trigger
	function onAddButtonClick() {
		onActivate(ident);
		setCurrent(null);
		setAddingDstId(null);
		setAddingSign(null);
		if (!active) {
			setAdding(true);
		} else {
			setAdding(!adding);
		}
	}

	function onAddingIdChanged(newDstId) {
		if (addingSign !== null) {
			// Done
			updateConfig(newDstId, ident, addingSign);
			setCurrent(newDstId);
			setAdding(false);
		} else {
			// One half
			setAddingDstId(newDstId);
		}
	}

	function onAddingSignClicked(newSign) {
		if (addingDstId !== null) {
			// Done
			updateConfig(addingDstId, ident, newSign);
			setCurrent(addingDstId);
			setAdding(false);
		} else {
			// The other half
			setAddingSign(newSign);
		}
	}

	// Only if current row is active
	const showAdding = active && adding;

	/*
	console.log(accountNames);
	console.log(ident);
	console.log(keyName);
	*/

	return (
		<tr>
			<td>{(accountNames[ident] || {})[keyName] ? accountNames[ident][keyName] : "-"}</td>
			<td>
				<FormulaElement unknown={unknown}
					ident={ident}
					variant="info"
					value={setup.balances ? setup.balances[ident] : null}
					balancesOn={setup.balances}
					multi />
			</td>
			<td>
				{thisDstIds.map((dstId, idx) => <WholeFormulaEdit formula={config[dstId]}
					current={ident}
					ident={dstId}
					onActivate={onFormulaActivated}
					active={active && (dstId === current)}
					onClickSign={onSignClicked}
					onClickDelete={() => onDeleteClicked(dstId)}
					allSrcIds={allSrcIds}
					setup={setup}
					onChangeId={onIdChanged}
					editable={!readonly}
					srcToDst={srcToDst}
					multi={multi}
					key={idx}
					config={config}
					availableDstIds={allDstIds} />)}
				{showAdd ? <Button onClick={onAddButtonClick}
					variant={(showAdding ? "" : "") + "info"}
					title={t('rep-adds_source_ident') + " " + ident + " " + t('rep-to_destination_one')}
				>{t('rep-add')}</Button> : <></>}
				{showAdding ?
					<MappingEditForm availableDstIds={allDstIds}
						activeId={addingDstId}
						coef={addingSign}
						onChangeSign={onAddingSignClicked}
						onChangeId={onAddingIdChanged}
						normalView={false}
						relatedId={current}
						config={config}
						allSrcIds={allSrcIds}
						conditionsDisabled
					/>
					: <></>}
			</td>
		</tr>
	);
}

/**
 * Binds together a WholeFormula with optional MappingEditForm
 *
 * @param {string} ident - the left-hand term (dstId)
 * @param {string} formula - dictionary representing the whole formula
 * @param {string} current - currently selected srcId inside this formula (visual-only)
 * @param {boolean} active - if editable and this is true, highlight the left-hand term
 * @param {function} onActivate - if editable and user clicks the left-hand term, this 
 * gets called and ident (dstId) is passed as argument
 * @param {function} onClickSign - gets called when user clicks on [+] or [-]
 * @param {array} allSrcIds - all possible srcIds from fetched config and balances 
 * @param {function} onClickDelete - gets called when user wants to delete this element
 * @param {function} onChangeId - gets called if user picks different source id from the list
 * @param {any} setup - meta-configuration
 * @param {boolean} editable - if true, allow editing
 * @param {boolean} multi - if true, multiple mappings are fine in transposed view row
 * @param {any} srcToDst - mapping of srcIds to all dstIds where they are used
 * @param {dictionary} config - for SignOptions component, who has to offer option based on usage of srcIds in config
 * @return {component}
 */
function WholeFormulaEdit({ ident, formula, current, active, onActivate, onClickSign,
	onClickDelete, allSrcIds, onChangeId, setup, editable, multi, srcToDst, config, availableDstIds }) {
	return (
		<>
			<WholeFormula formula={formula}
				current={current}
				ident={ident}
				editable={editable}
				onClick={onActivate}
				setup={setup}
				multi={multi}
				srcToDst={srcToDst}
				active={active} />
			{active ? <MappingEditForm onChangeSign={onClickSign}
				onDelete={onClickDelete}
				activeId={ident}
				allSrcIds={allSrcIds}
				onChangeId={onChangeId}
				coef={formula[current]}
				normalView={false}
				relatedId={current}
				config={config}
				availableDstIds={availableDstIds}
			/> : <></>}
		</>
	);
}
