/**
 * List of payments history of given partner.
 * @module partners/partner-payments-history
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { PartnerPaymentsHistoryWeb } from './partner-payments-history-web';
import { PartnerPaymentsHistoryApp } from './partner-payments-history-app';
import { his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';

//seen all - OK

//  0    1      2        3         4        5               6       7        8        9        10     11    12      13
// ps,saldo,uhrada,datdokladu,poznamka,datsplatnosti,koddokladu,zpozdeni,zpozdeni2,kodmeny,comm_type,note,note_ts,pojisteno

export function PartnerPaymentsHistory({ currency, payments, partner, loadedPaymentsStatus, reloadButton }) {

	const real_payments = payments.filter((pay) => pay[1] <= 0);

	const [offset, setOffset] = useState(20 * Math.floor(real_payments.length / 20));
	const [paymentsReady, setPaymentsReady] = useState(false);
	if ((!paymentsReady) && (real_payments.length > 0)) {
		setOffset(20 * Math.floor(real_payments.length / 20));
		setPaymentsReady(true);
	}

	if (!his_fetch_success(loadedPaymentsStatus)) {
		return <HisFetchStatus status={loadedPaymentsStatus} loadingType="big" errorType="fetcherError" waitingType="explained" reloadButton={reloadButton}/>;
	}

	return (
		<ErrorWrap>
			<PartnerPaymentsHistoryWeb payments={real_payments} currency={currency} partner={partner}
				offset={offset} setOffset={setOffset} />
			<PartnerPaymentsHistoryApp debts={real_payments} hidedebt={true} acc_currency={currency} partner={partner}
				offset={offset} setOffset={setOffset} />
		</ErrorWrap>
	);
}
