/**
 * Confirmation button with possible additional user input.
 * @module comp/confimation-button 
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { remove_spaces_and_enters } from "../lib/format";
import { useTranslation } from 'react-i18next';

//seen all - OK

/*
 * Button with overlay that requires confimation of action. 
 * @param {function} finalOnClick - function with the final action of button
 * @param {string} initialButtonText - button text
 * @param {string} initialButtonColor - button color
 * @param {string} popoverText - text in the overlay
 * @param {string} popoverButtonText - button text in the overlay
 * @param {string} popoverButtonColor - button color in the overlay
 * @param {string} errorMessage - message, if final action fails, message displays in overlay
 * @param {component} loadingComponent - component shown during performing finalOnClick
 * @param {boolean} extraInfo - true if we want something additional (input) in the overlay, default false
 * @param {string} validationRegex - regex for validating user input in overlay, default false
 * @param {string} errorMessageInput - error message if user fails the input, default empty string
 * @param {string} inputLabel - label of the input, default empty string
 * 
 * @return - Button with required confirmation
 */
export function ConfirmButton({ finalOnClick, initialButtonText, initialButtonColor, popoverText,
    popoverButtonText, popoverButtonColor, errorMessage, loadingComponent, extraInfo = false, validationRegex = false,
    errorMessageInput = "", inputLabel = "", }) { //validačníRegex je defaultně false, když tam nepříjde, nebudu nic validovat
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [extraInfoContent, setExtraInfoContent] = useState("");
    // const editedFinalOnclick = extraInfo ? finalOnClick(extraInfoContent) : finalOnClick(); //tahle věc by se vyhodnotila při každém buildu commponenty, ale já to chci na kliiknutí, tohle by musela být lambda a etn funkční záps je smerter

    function myOnClick() { //toto musí být funckce, aby se to při kliknutí volalo, kdyby to byl jen const nebude se to volat
        if (extraInfo) {
            finalOnClick(extraInfoContent);
        } else {
            finalOnClick();
        }
    }

    function validateString(str) {
        const regex = validationRegex;
        return regex.test(str);
    }

    //console.log(extraInfoContent);

    const NDValid = extraInfoContent !== "" && validationRegex ? validateString(remove_spaces_and_enters(extraInfoContent)) : false;
    const extraInfoMandatory = extraInfo && !NDValid;
    const messageColor = errorMessage === "sent" ? "text-success" : "text-danger";
    const errorMessageText = errorMessage === 'error' ? t('error') : errorMessage === 'sent' ? t('prod-sent') : "";

    return (
        <OverlayTrigger onToggle={setShow} show={show} trigger="click" rootClose placement="right-start" overlay={
            <Popover id={initialButtonText} style={{ maxWidth: "300px", width: "300px" }}>
                <div>
                    <h5 className="float-end m-2 myHover" style={{ cursor: "pointer" }}
                        onClick={() => setShow(false)}>
                        ×
                    </h5>
                    <div className="p-3">
                        {popoverText}
                        <br />
                        {extraInfo ?
                            <Form.Group controlId="formText" className="mt-3">
                                <Form.Label>{inputLabel}</Form.Label>
                                <Form.Control isValid={NDValid} isInvalid={!NDValid && extraInfoContent !== ""} size="sm" type="text" value={extraInfoContent}
                                    onChange={(ev) => setExtraInfoContent(ev.target.value)} placeholder="ND202200000001" />
                                <Form.Control.Feedback type='invalid'>{errorMessageInput}</Form.Control.Feedback>
                            </Form.Group>

                            : ""}
                        <Button disabled={loadingComponent !== "" || extraInfoMandatory} className="mt-2" size="sm"
                            variant={popoverButtonColor} onClick={myOnClick}>{popoverButtonText}
                        </Button>
                        <div className="my-2">{loadingComponent}</div>
                        <div className={messageColor + " mt-1"}>{!loadingComponent ? errorMessageText : ""}</div>
                    </div>
                </div>
            </Popover>
        }>
            <Button className="m-1" size="sm" variant={initialButtonColor}>
                {initialButtonText}
            </Button>
        </OverlayTrigger>
    );
}

//když bude extra info true, tak nad button dát input field - jeho onchange patří sem
// validační regex jako další prop, - tím chci  říct, že to má mít 14 znaků začínat na nd ... "^ND[0-9]{12}$" // regex se používá skrz match
// ^  - začátek řetězce, $ - konec řetězce, ND - prostě ND, [] - povolená tříd znaků na dané pozici rozsah jsou znaky 0-9, {} povinné opakování je právě 12
// asi trimovat value input fieldu
// 