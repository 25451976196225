import React from 'react';
import { Nav } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { ErrorWrap } from '../comp/errorwrap';
import { LinkContainer } from 'react-router-bootstrap';
import { WarehouseStocksDisplay as WarehouseStocksDisplayNew } from './ProductStocks';
import { ProductInventoryTwistWarehouse as ProductInventoryTwistWarehouseOld } from './product-inventory';
import { physicalWarehouses, warehouses_names_ids_separe } from '../lists/warehouses-defs';
import { ChooseCategory } from '../comp/choose-category';
import { WarehouseStocksDisplay } from "./product-stocks";
import { ProductInventoryTwistWarehouse } from "./ProductInventoryTwistWarehouse";

//seen all - OK

/**
 * Returns navigation for all physical warehouses, switches among components for warehouses or twist stocks views
 * 
 * @param {any} userlogin - info about logged user
 * @param {string} dataType - can be warehouses or twist - its used in route and fetcher
 * @param {object} effectiveDate - used in fetcher for stocks
 * @returns {component}
 */
export function WarehousesTabs({ userlogin, dataType, /*effectiveDate*/ inventoryView = false }) {
    //const fetchDateProp = date_formatISO(effectiveDate);
    const fetchDate = useParams().fetchDate;
    //console.log(useLocation().pathname);
    //console.log(fetchDate);
    return (
        <ErrorWrap>
            <Nav variant="tabs" className="mb-3">
                {physicalWarehouses.map(function (wh, idx) {
                    const linkTo = inventoryView ? "/products/stocks/" + fetchDate + "/inventory/" + wh + "/todaysTwist" : "/products/stocks/" + fetchDate + "/" + dataType + "/" + wh;
                    return (
                        <LinkContainer to={linkTo} key={idx}>
                            <Nav.Link eventKey={linkTo}>{warehouses_names_ids_separe[wh]}</Nav.Link>
                        </LinkContainer>
                    );
                })}
            </Nav>
            <Routes>
                {physicalWarehouses.map(function (wh, idx) {
                    const linkTo = inventoryView ? wh + "/:twistDate" : wh;
                    return (
                        <Route path={linkTo} key={"a" + idx} element={
                            <React.Fragment key={"b" + idx}>
                                {inventoryView ?
                                    <ProductInventoryTwistWarehouse key={"c" + idx} warehouseId={wh} fetchDate={fetchDate} />
                                    :
                                    <WarehouseStocksDisplayNew key={"d" + idx} userlogin={userlogin} dataType={dataType} warehouseId={wh} fetchDate={fetchDate} />
                                }
                            </React.Fragment>
                        } />
                    );
                })}
              {physicalWarehouses.map(function (wh, idx) {
                const linkTo = (inventoryView ? wh + "/:twistDate" : wh) + "/old";
                return (
                  <Route path={linkTo} key={"a" + idx} element={
                    <React.Fragment key={"b" + idx}>
                      {inventoryView ?
                        <ProductInventoryTwistWarehouseOld key={"c" + idx} userlogin={userlogin} warehouse={wh} fetchDate={fetchDate} />
                        :
                        <WarehouseStocksDisplay key={"d" + idx} userlogin={userlogin} dataType={dataType} warehouseID={wh} fetchDate={fetchDate} />
                      }
                    </React.Fragment>
                  } />
                );
              })}
                <Route path="" element={<ChooseCategory />} />
            </Routes>
        </ErrorWrap >
    )
}