/**
 * Partner insurance app view.
 * @module partners/partner-insurance-app
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import Table from 'react-bootstrap/Table';
import { format_amount } from '../lib/format';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import { PartnerInsuranceStatus, PartnerRatingHistory, PartnerDetailLimitDetail, filterDetail } from '../insurance/insurance-utils';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerInsuranceApp({ partner }) {
    const { t } = useTranslation();
    return (
        <ErrorWrap>
            <div className="d-md-none">
                <Row>
                    <Col lg={6}>
                        <PartnerInsuranceStatus status={partner.Insurance} partner={partner} />
                        <PartnerDetailLimitsApp partner={partner} />
                    </Col>
                    <Col lg={6}>
                        <h5 className='mt-3'>{t('biz-rating_hist')}</h5>
                        <PartnerRatingHistory history={partner.RatingHistory} />
                    </Col>
                </Row>
            </div>
        </ErrorWrap>
    );
}

function PartnerDetailLimitApp({ lidx, limit }) {
    const { t } = useTranslation();

    const actcls = limit[0] ? "limit-active" : "limit-inactive";
    const details = limit[11];
    var idx = 0;
    return (
        <Accordion.Item eventKey={lidx}>
            <Accordion.Header as="div" className={actcls + " p-0"}>
                <div className='container-fluid p-0 pe-2'>
                    <ListGroup.Item className={actcls}>
                        <Row className="g-0">
                            <Col>{t('ins-number')}</Col>
                            <Col xs="5" className="text-center">{t('state')}</Col>
                            <Col className="text-end">{t('biz-requested')}</Col>
                        </Row>
                        <Row className="g-0">
                            <Col><b>{limit[1]}</b></Col>
                            <Col xs="5" className="text-center"><b>{limit[2]}</b></Col>
                            <Col className="text-end"><b>{limit[8]}</b></Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className={actcls}>
                        <Row className="g-0">
                            <Col xs="5" className="text-end">{t('biz-valid_from_to')}:&nbsp; </Col>
                            <Col className="text-start"><b>{limit[3]}&nbsp;–&nbsp;{limit[4]}</b></Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs="5" className="text-end">{t('ins-terminated')}:&nbsp; </Col>
                            <Col className="text-start"><b>{limit[10]}</b></Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs="5" className="text-end">{t('ins-required_amount')}:&nbsp;</Col>
                            <Col className="text-start"><b>{format_amount(limit[5])}&nbsp;{limit[7]}</b></Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs="5" className="text-end">{t('ins-approved_amount')}:&nbsp; </Col>
                            <Col className="text-start"><b>{format_amount(limit[6])}&nbsp;{limit[7]}</b></Col>
                        </Row>
                    </ListGroup.Item>
                </div>
            </Accordion.Header>
            <Accordion.Body className='p-1'>
                <Table size="sm" className="propTable">
                    <tbody>
                        {
                            details.filter(filterDetail).map(function (detail) {
                                idx += 1;
                                return <PartnerDetailLimitDetail key={idx} idx={idx} detail={detail} />;
                            })

                        }
                    </tbody>
                </Table>
            </Accordion.Body>
        </Accordion.Item>
    );
}

function PartnerDetailLimitsApp({ partner }) {
    const { t } = useTranslation();

    var limits = partner.limits;
    if (typeof (limits) === 'undefined') {
        return "";
    }
    var lidx = 0;
    return (
        <>
            <h5 className="d-md-none">{t('ins-limits')}</h5>
            <Accordion className="d-md-none">
                <ListGroup>
                    {
                        limits.map(function (limit) {
                            lidx += 1;
                            return <PartnerDetailLimitApp key={lidx} lidx={lidx} limit={limit} />;
                        })}
                </ListGroup>
            </Accordion>
        </>
    );
}