import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BooleanDropdown } from "./boolean";

interface BooleanDropdownRhfProps {
  name: string;
  className?: string;
  variant?: "negative" | "product-checks" | "order-tracking" | "";
}

export default function BooleanDropdownRhf({ name, className, variant = "" }: BooleanDropdownRhfProps) {
  const form = useFormContext();

  return (
    <Controller
      control={form.control}
      render={({ field: { onChange, value } }) => {
        return <BooleanDropdown onChange={onChange} variant={variant} value={value} className={className} />;
      }}
      name={name}
    />
  );
}
