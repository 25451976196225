/**
* Routing for reporting.
* @module reporting/reporting
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams } from "react-router-dom";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { useTranslation } from 'react-i18next';
import { ReportProfitLoss } from './report-profit-loss';
import { ReportFGProfitLoss } from './report-fg-profit-loss';
import { ReportManagementRoute } from './report-management';
import { ReportingStart } from './reporting-navigation';
import { ReportBalanceSheet } from './report-balance-sheet';
import { ReportStockPosition } from './report-stock-position';

export function ReportingRoutes({ userlogin }) {

    const [minMaxDate, setMinMaxDate] = useState(null);
    const [costCenters, setCostCenters] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);

    const reloadIt = () => {
        setLoadedStatus(0);
        setMinMaxDate(null);
    }

    useEffect(() => {
        if (minMaxDate === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/reporting/common",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setMinMaxDate(result.dateRange);
                            setCostCenters(result.costCenters);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setMinMaxDate("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, minMaxDate]);


    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    return (
        <Routes>
            {/**  <Route path="/:reportId/:year/:month/:centre/*" element={<ReportingSwitch userlogin={userlogin} costCenters={costCenters} />} /> */}
            <Route path="/:reportId/:year/:month/:booked/*" element={<ReportingSwitch userlogin={userlogin} costCenters={costCenters} />} />
            <Route path="/:reportId/*" element={<ReportingSwitch userlogin={userlogin} costCenters={costCenters} />} /> {/**this route says 'unknown report fot some wtf url */}
            <Route path='' element={
                <ReportingStart userlogin={userlogin} minMaxDate={minMaxDate} costCenters={costCenters} />
            } />
        </Routes>
    );
}

export function ReportingSwitch({ userlogin, costCenters = [] }) {

    const { reportId } = useParams();

    return (
        <Routes>
            <Route path='/*' element={
                <SwitchComponents reportId={reportId} userlogin={userlogin} costCenters={costCenters} />
            } />
        </Routes>
    );
}

function SwitchComponents({ userlogin, reportId, costCenters }) {
    const { t } = useTranslation();


    if (reportId === "profit-loss") {
        return <ReportProfitLoss reportName="profit-loss" userlogin={userlogin} />;
    }
    if (reportId === "profit-loss-old") {
        return <ReportProfitLoss reportName="profit-loss-old" userlogin={userlogin} />;
    }
    if (reportId === "fg-profit-loss") {
        return <ReportFGProfitLoss reportName="profit-loss" userlogin={userlogin} />;
    }
    if (reportId === "fg-profit-loss-old") {
        return <ReportFGProfitLoss reportName="profit-loss-old" userlogin={userlogin} />;
    }
    if (reportId === "balance-sheet") {
        return <ReportBalanceSheet reportName="balance-sheet" userlogin={userlogin} />;
    }
    if (reportId === "management-report") {
        return <ReportManagementRoute reportName="profit-loss" userlogin={userlogin} costCenters={costCenters} />;
    }
    if (reportId === "stock-positions") {
        return <ReportStockPosition reportName="stock-positions" userlogin={userlogin} />;
    }
    else {
        return <p>{t('rep-unknown_report')}</p>;

    }
}