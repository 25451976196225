import React, { useEffect } from "react";
import { Table, Form, Row, Col, Button, Badge, Alert } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { PurchaseOrderProcessedItem, Texts } from "./PurchaseOrder";
import { ErrorWrap } from "../../comp/errorwrap";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { StockNotificationFormValues } from "./StockNotifications";

/*
 * First step of stockin component, where user choose products from order, that should arrive to warehouse.
 *
 * @param {string} kodDokladu - code of the order
 * @param {any} items - all items in orders
 * @param {dictionary} pickedItems - state to store items ids, that user picked
 * @param {function} setPickedItems - function to update pickedItems
 * @param {array} texts - text information to display during the stockin process ("neutralizace" and such)
 * @returns {component}
 */

interface StockNotificationStep1Props {
  kodDokladu: string;
  items: PurchaseOrderProcessedItem[];
  texts: any;
  hidden?: boolean;
}

export function StockNotificationStep1({ kodDokladu, items, texts, hidden = false }: StockNotificationStep1Props) {
  const { t } = useTranslation();

  const { formState, watch } = useFormContext<StockNotificationFormValues>();

  const someSelected = Object.values(watch("pickedItems")).some((v) => v);

  const goodChoice = !formState.errors?.pickedItems;
  const error = !goodChoice ? (
    <Alert style={{ maxWidth: "500px" }} className="mt-2" variant="danger">
      {t("ord-choosen_items_doenst_belong_to_same_wh")}!
    </Alert>
  ) : (
    ""
  );

  return (
    <div style={{ display: hidden ? "none" : undefined }}>
      <ErrorWrap>
        <Row>
          <Col>
            <h5 className="mb-0">{t("ord-choose_items")}</h5>
            <Table size="sm" className="mt-2 border">
              <thead className="beGray">
                <tr>
                  <th className="text-center">{t("ord-check_item")}</th>
                  <th className="text-center">{t("warehouse")}</th>
                  <th className="text-center">{t("ord-remainin")}</th>
                  <th>{t("prod-id")}</th>
                  <th>{t("code")}</th>
                  <th>{t("name")}</th>
                </tr>
              </thead>
              <tbody>
                {items.map(function (item) {
                  const disabledItem = item.item.remainingQuantity <= 0;
                  return (
                    <Controller<StockNotificationFormValues>
                      key={item.itemId}
                      name={`pickedItems.${item.itemId}`}
                      rules={{
                        validate: (value, formValues) => {
                          const itemWarehouses = items
                            .filter((item) => !!formValues.pickedItems[item.itemId])
                            .map((item) => item.item.warehouseBookCode);
                          return itemWarehouses.every((v) => v === itemWarehouses[0]) ? true : "warehouses_not_same";
                        },
                      }}
                      render={({ field }) => (
                        <tr
                          key={item.itemId}
                          onClick={
                            disabledItem
                              ? undefined
                              : (e) => field.onChange({ ...e, target: { ...e.target, value: !field.value } })
                          }
                          style={{ cursor: disabledItem ? "" : "pointer" }}
                        >
                          <td className="text-center">
                            <Form.Check
                              type="checkbox"
                              checked={!!field.value}
                              onChange={(e) => field.onChange(e)}
                              id={item.itemId.toString()}
                              disabled={disabledItem}
                            />
                          </td>
                          <td className="text-center">{item.item.warehouseBookCode}</td>
                          <td className="text-center">
                            {item.item.remainingQuantity}&nbsp;{item.item.unitOfMeasurementCode}
                          </td>
                          <td>{item.productId}</td>
                          <td>{item.item.productCode}</td>
                          <td>{item.item.productName}</td>
                        </tr>
                      )}
                    />
                  );
                })}
              </tbody>
            </Table>
            <span className="text-muted mb-0">
              <Badge pill bg="secondary">
                i
              </Badge>{" "}
              {t("ord-items_within_one_wh_only")} {error}
            </span>
          </Col>
          <Col xs="4">
            <Texts texts={texts} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu }}>
              <Button>{t("ord-cancel_sn")}</Button>
            </LinkContainer>
          </Col>
          <Col className="text-end">
            <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu + "/notification/2" }}>
              <Button disabled={!goodChoice || !someSelected} className="float-end">
                {t("ord-second_step")}
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </ErrorWrap>
    </div>
  );
}
