/**
 * List of insured partners - comparation of Twist and Credendo data
 * @module insurance/insurance-list
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Rating } from '../comp/rating';
import { format_amount } from '../lib/format';
import Form from 'react-bootstrap/Form';
import { get_map_keys, filter_rule, filter_rules } from '../lib/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function InsuranceList({ userlogin }) {
	const { t } = useTranslation();

	const [partners, setPartners] = useState(null);
	const [filter_cr_name, set_filter_cr_name] = useState("");
	const [filter_cr_ic, set_filter_cr_ic] = useState("");
	const [filter_cr_dic, set_filter_cr_dic] = useState("");
	const [filter_cr_fic, set_filter_cr_fic] = useState("");
	const [filter_tw_kod, set_filter_tw_kod] = useState("");
	const [filter_tw_name, set_filter_tw_name] = useState("");
	const [filter_tw_ic, set_filter_tw_ic] = useState("");
	const [filter_tw_dic, set_filter_tw_dic] = useState("");
	const [filter_limit_status, set_filter_limit_status] = useState("");
	const [filter_rating, set_filter_rating] = useState("");
	const [filter_currency, set_filter_currency] = useState("");
	const [currency, setCurrency] = useState("XXX");
	const [loadedStatus, setLoadedStatus] = useState(0);

	const reloadIt = () => {
		setPartners(null);
		set_filter_cr_name("");
		set_filter_cr_ic("");
		set_filter_cr_dic("");
		set_filter_cr_fic("");
		set_filter_tw_kod("");
		set_filter_tw_name("");
		set_filter_tw_ic("");
		set_filter_tw_dic("");
		set_filter_limit_status("");
		set_filter_rating("");
		set_filter_currency("");
		setCurrency("XXX");
		setLoadedStatus(0);
	}


	useEffect(() => {
		if (partners === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/credendo/limits",
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							setPartners(result.partners);
							//console.log(result.partners); // appears once
							setCurrency(result.currency);
							//console.log(result.currency); // appears once
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setPartners("error");
						}
					}
				]
			)
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, partners]);


	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt}
		/>;
	}

	const handle_change_cr_name = (e) => {
		set_filter_cr_name(e.target.value);
	}
	const handle_change_cr_ic = (e) => {
		set_filter_cr_ic(e.target.value);
	}
	const handle_change_cr_dic = (e) => {
		set_filter_cr_dic(e.target.value);
	}
	const handle_change_cr_fic = (e) => {
		set_filter_cr_fic(e.target.value);
	}
	const handle_change_tw_kod = (e) => {
		set_filter_tw_kod(e.target.value);
	}
	const handle_change_tw_name = (e) => {
		set_filter_tw_name(e.target.value);
	}
	const handle_change_tw_ic = (e) => {
		set_filter_tw_ic(e.target.value);
	}
	const handle_change_tw_dic = (e) => {
		set_filter_tw_dic(e.target.value);
	}
	const handle_change_limit_status = (e) => {
		set_filter_limit_status(e.target.value);
	}
	const handle_change_rating = (e) => {
		set_filter_rating(e.target.value);
	}
	const handle_change_currency = (e) => {
		set_filter_currency(e.target.value);
	}

	const listpartners = partners.filter(
		function (partner) {
			const partner_limits = partner.limits.filter(function (l) {
				return (filter_rule(filter_limit_status, l.status_desc) &&
					filter_rule(filter_currency, l.currency));

			});
			return (filter_rule(filter_cr_name, partner.name)) &&
				(filter_rule(filter_cr_ic, partner.ic)) &&
				(filter_rule(filter_cr_dic, partner.dic)) &&
				(filter_rule(filter_cr_fic, partner.fic)) &&
				(filter_rules(filter_tw_name, partner.tw_partners,
					function (p) {
						return p[1];
					})) &&
				(filter_rules(filter_tw_ic, partner.tw_partners,
					function (p) {
						return p[2];
					})) &&
				(filter_rules(filter_tw_dic, partner.tw_partners,
					function (p) {
						return p[3];
					})) &&
				(filter_rules(filter_limit_status, partner_limits,
					function (l) {
						return l.status_desc;
					})) &&
				(filter_rules(filter_tw_kod, partner.tw_partners,
					function (p) {
						return p[0];
					})) &&
				(filter_rule(filter_rating, partner.rating)) &&
				(filter_rules(filter_currency, partner_limits,
					function (l) {
						return l.currency;
					}))
				;
		});
	var statuses = {};
	var ratingsmap = {};
	var currsmap = {};
	//tady u partners si nejsem jistá, jestli tam nepatří náhodou listpartners ?? 
	for (var pi in partners) {
		const partner = partners[pi];
		for (var li in partner.limits) {
			const limit = partner.limits[li];
			statuses[limit.status_desc] = true;
			currsmap[limit.currency] = true;
		}
		ratingsmap[partner.rating] = true;
	}
	const status_descs = get_map_keys(statuses).sort();
	const ratings = get_map_keys(ratingsmap).sort();
	const currs = get_map_keys(currsmap).sort();

	var idx = 0;
	return (
		<ErrorWrap>
			<Table size="sm" className='border-top'>
				<thead>
					<tr>
						<th scope="col" colSpan="9">
							<Row>
								<Col xs={6}><h5>{t('ins-Crerendo_search')}</h5></Col>
								<Col className="leftBorder" xs={6}><h5>{t('ins-Twist_search')}</h5></Col>
							</Row>
							<Row>
								<Col xs={1}>
									<Form.Group controlId="rating">
										<Form.Label>Rating</Form.Label>
										<Form.Control as="select" onChange={handle_change_rating}>
											<option value="">{t('all')}</option>
											{
												ratings.map(
													function (sd) {
														return <option value={sd} key={sd}>{sd}</option>;
													}
												)
											}
										</Form.Control>
									</Form.Group>
								</Col>
								<Col xs={5} >
									<Form.Group controlId="crname" className="mb-3">
										<Form.Control type="text" placeholder={"🔍 " + t('name')} onChange={handle_change_cr_name} />
									</Form.Group>
									<Row className="mb-2">
										<Col xs={4}>
											<Form.Group controlId="cric">
												<Form.Control type="text" placeholder={"🔍 " + t('biz-ic')} onChange={handle_change_cr_ic} />
											</Form.Group>
										</Col>
										<Col xs={4}>
											<Form.Group controlId="crdic">
												<Form.Control type="text" placeholder={"🔍 " + t('biz-dic')} onChange={handle_change_cr_dic} />
											</Form.Group>
										</Col>
										<Col xs={4}>
											<Form.Group controlId="crfic">
												<Form.Control type="text" placeholder={"🔍 " + t('ins-FIC')} onChange={handle_change_cr_fic} />
											</Form.Group>
										</Col>
									</Row>
								</Col>
								<Col xs={6}>
									<Row className="mb-3">
										<Col className="leftBorder" xs={6}>
											<Form.Group controlId="twkod">
												<Form.Control type="text" placeholder={"🔍 " + t('code')} onChange={handle_change_tw_kod} />
											</Form.Group>
										</Col>
										<Col xs={6}>
											<Form.Group controlId="twname">
												<Form.Control type="text" placeholder={"🔍 " + t('name')} onChange={handle_change_tw_name} />
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col className="leftBorder" xs={6}>
											<Form.Group controlId="twic">
												<Form.Control type="text" placeholder={"🔍 " + t('biz-ic')} onChange={handle_change_tw_ic} />
											</Form.Group>
										</Col>
										<Col xs={6}>
											<Form.Group controlId="twdic">
												<Form.Control type="text" placeholder={"🔍 " + t('biz-dic')} onChange={handle_change_tw_dic} />
											</Form.Group>
										</Col>
									</Row>
								</Col>
							</Row>
						</th>
					</tr>
					<tr className="beGray">
						<th scope="col" className="text-center align-middle">{t('ins-number')}</th>
						<th scope="col" className="text-center">
							<Form.Group controlId="stav">
								<Form.Label>{t('state')}</Form.Label>
								<Form.Control size="sm" as="select" onChange={handle_change_limit_status}>
									<option value="">{t('all')}</option>
									{
										status_descs.map(
											function (sd) {
												return <option value={sd} key={sd}>{sd}</option>;
											}
										)
									}
								</Form.Control>
							</Form.Group>
						</th>
						<th scope="col" className="text-center align-middle">{t('ins-request_date')}</th>
						<th scope="col" className="text-center align-middle">{t('from-prep')}</th>
						<th scope="col" className="text-center align-middle">{t('to_prep')}</th>
						<th scope="col" className="text-center align-middle">{t('ins-terminated')}</th>
						<th scope="col" className="text-center align-middle">{t('ins-required_amount')}</th>
						<th scope="col" className="text-center align-middle">{t('ins-approved_amount')}</th>
						<th scope="col" className="text-center">
							<Form.Group controlId="curr">
								<Form.Label>{t('biz-currency')}</Form.Label>
								<Form.Control size="sm" as="select" onChange={handle_change_currency}>
									<option value="">{t('all')}</option>
									{
										currs.map(
											function (sd) {
												return <option value={sd} key={sd}>{sd}</option>;
											}
										)
									}
								</Form.Control>
							</Form.Group>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						listpartners.map(function (partner) {
							idx += 1;
							return (
								<React.Fragment key={idx}>
									<InsuredPartnerRows partner={partner} filter_limit_status={filter_limit_status} key={partner.KodPartnera} currency={currency} />
								</React.Fragment>
							);
						})
					}
				</tbody>
			</Table>
		</ErrorWrap>
	);
}


function InsuredPartnerHeader({ partner, currency }) {
	const { t } = useTranslation();

	const twistpartners = partner.tw_partners;
	const insuredstr = partner.insured ? "limit-active" : "limit-inactive";
	var idx = 0;
	return (
		<tr className={insuredstr}>
			<td colSpan="9">
				<Row>
					<Col xs="1" className="text-center"><Rating rating={partner.rating} history={partner.rating_history} /></Col>
					<Col xs="5">
						<Row>
							<Col as="h5" className="m-0">{partner.name}</Col>
						</Row>
						<Row>
							<Col>{partner.country}</Col>
						</Row>
						<Row>
							<Col xs="7">{t('biz-ic')}: {partner.ic}, {t('biz-dic')}: {partner.dic}, {t('ins-FIC')}: {partner.fic}</Col>
							<Col className="text-end">{t('ins-gained_limit')}: {format_amount(partner.balance)}&nbsp;{currency}</Col>
						</Row>
					</Col>

					<Col xs="6" className="leftBorder">
						{
							twistpartners.map(function (partner) {
								idx += 1;
								return (
									<React.Fragment key={idx}>
										<InsuredTwistPartner partner={partner} />
									</React.Fragment>
								);
							})
						}
					</Col>
				</Row>
			</td>
		</tr>
	);
}

function InsuredPartnerRows({ partner, filter_limit_status, currency }) {
	const limits = partner.limits.filter(
		function (l) {
			return filter_rule(filter_limit_status, l.status_desc);
		});
	var idx = 0;
	return (
		<>
			<InsuredPartnerHeader partner={partner} currency={currency} />
			{
				limits.map(function (limit) {
					idx += 1;
					return <InsuredPartnerLimit limit={limit} key={idx} />;
				})
			}
		</>
	);
}

function LimitChecker({ limit }) {
	if (limit.active) {
		return <span>&nbsp; <span role="img" aria-label="OK">&#x2705;</span></span>;
	}
	return "";
}

function InsuredPartnerLimit({ limit }) {
	return (
		<tr>
			<td className="text-center">{limit.limit_number}</td>
			<td>{limit.status_desc}<LimitChecker limit={limit} /></td>
			<td className="text-center">{limit.requested_on}</td>
			<td className="text-center">{limit.valid_since}</td>
			<td className="text-center">{limit.valid_until}</td>
			<td className="text-center">{limit.ended_on}</td>
			<td className="text-center">{format_amount(limit.requested_amount)}</td>
			<td className="text-end"><b>{format_amount(limit.approved_amount)}</b></td>
			<td><b>{limit.currency}</b></td>
		</tr>
	);
}

function InsuredTwistPartner({ partner }) {
	const { t } = useTranslation();

	return (
		<LinkContainer to={{ pathname: "/partners/list/" + partner[0] + "/detail" }}>
			<a href="!#" className="noStyle">
				<h5 className="m-0">{partner[1]}</h5>
				<p className="m-0">{t('code')}: {partner[0]}</p>
				<p className="m-0">{t('biz-ic')}: {partner[2]}, {t('biz-dic')}: {partner[3]}</p>
			</a>
		</LinkContainer>
	);
}