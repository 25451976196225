/**
 * Partner menu bar.
 * @module partners/partner-tabs
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { useParams } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerTabs({
	loadedPaymentsStatus, loadedHistoryStatus, loadedLiabilitiesStatus, loadedDebtsStatus, loadedSaleOrdersStatus, loadedPurchaseOrdersStatus
}) {
	const { t } = useTranslation();

	const { partnerCode } = useParams();

	return (
		<ErrorWrap>
			<Nav variant="tabs" defaultActiveKey="#first" className="mb-3">
				<LinkContainer to={"/partners/list/" + partnerCode + "/detail"}>
					<Nav.Link>
						<span className="d-none d-lg-inline">{t('menu2-ins-info')}</span>
						<span className="d-lg-none">{t('info')}</span>
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to={"/partners/list/" + partnerCode + "/debt"}>
					<Nav.Link>
						<span className="d-none d-lg-inline">{t('biz-debts')}</span>
						<span className="d-lg-none">{t('debts')}</span>
						<HisFetchStatus status={loadedDebtsStatus} />
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to={"/partners/list/" + partnerCode + "/liabilities"}>
					<Nav.Link>
						<span className="d-none d-lg-inline">{t('biz-liab')}</span>
						<span className="d-lg-none">{t('biz-liab')}</span>
						<HisFetchStatus status={loadedLiabilitiesStatus} />
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to={"/partners/list/" + partnerCode + "/payments"}>
					<Nav.Link>
						<span className="d-none d-lg-inline">{t('biz-hist')}</span>
						<span className="d-lg-none">{t('history')}</span>
						<HisFetchStatus status={loadedPaymentsStatus} />
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to={"/partners/list/" + partnerCode + "/history"}>
					<Nav.Link>
						<span className="d-none d-lg-inline">{t('biz-hist_balance')}</span>
						<span className="d-lg-none">{t('biz-balance')}</span>
						<HisFetchStatus status={loadedHistoryStatus} />
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to={"/partners/list/" + partnerCode + "/insurance"}>
					<Nav.Link>
						<span className="d-none d-lg-inline">{t('biz-debt_ins')}</span>
						<span className="d-lg-none">{t('menu-ins')}</span>
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to={"/partners/list/" + partnerCode + "/orders/sale"}>
					<Nav.Link>
						<span>{t('biz-ord_pur')}</span>
						<HisFetchStatus status={loadedSaleOrdersStatus} />
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to={"/partners/list/" + partnerCode + "/orders/purchase"}>
					<Nav.Link>
						<span>{t('biz-ord_sal')}</span>
						<HisFetchStatus status={loadedPurchaseOrdersStatus} />
					</Nav.Link>
				</LinkContainer>
			</Nav>
		</ErrorWrap>
	);
}
