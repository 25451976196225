//used as sort of ADR class validator at delivery-notes-sales-detail-web.js
export const ADR_icons = {
    "1": "ADR_1.svg",
    "2.1": "ADR_2.1.svg",
    "2.2": "ADR_2.2.svg",
    "2.3": "ADR_2.3.svg",
    "3": "ADR_3.svg",
    "4.1": "ADR_4.1.svg",
    "4.2": "ADR_4.2.svg",
    "4.3": "ADR_4.3.svg",
    "5.1": "ADR_5.1.svg",
    "5.2": "ADR_5.2.svg",
    "6.1": "Dangclass6_1.svg",
    "6.2": "ADR_6.2.svg",
    "7": "ADR_7B.svg",
    "8": "UN_transport_pictogram_-_8.svg",
    "9": "ADR_9.svg",
    "9A": "ADR_9A.svg"
}