/**
 * Creating new user.
 * @module user-new
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */

import React, { useState } from 'react';
import { UserProps } from './user-detail';
import { Button, Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { his_fetch } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Form for creating new user
 * 
 * @param {any} userlogin - current user info
 * @param {array} existingUsers - to avoid user duplication
 * @returns {component}
 */
export function NewUser({ userlogin, existingUsers }) {
    const { t } = useTranslation();

    const [username, setUsername] = useState(undefined);
    const [oid, setOid] = useState(null)
    const [mail, setMail] = useState(undefined);
    const [workerCode, setWorkerCode] = useState("");
    const [saving, setSaving] = useState(false);
    const [editEnabled, setEditEnabled] = useState(true);
    const [done, setDone] = useState(false);
    const [newUserID, setNewUserID] = useState(null);
    const [lang, setLang] = useState('cs');

    function saveUser() {
        if (!saving) {
            setSaving(true);
            setEditEnabled(false);

            var user = {};
            user['username'] = username;
            user['azureUserOid'] = oid === "" ? null : oid;
            user['email'] = mail;
            user['language'] = lang;
            if (workerCode) {
                user['twKodPracovnika'] = workerCode;
            }

            const jsonData = JSON.stringify(user);
            //console.log(user);
            //console.log(jsonData);

            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users",
                        json: true,
                        ok: function (resource, result) {
                            //console.log(result);
                            if (result.user_id) {
                                setNewUserID(result.user_id);
                                setSaving(false);
                                setDone(true);
                            } else {
                                return t('error');
                            }
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                        },
                        args: {
                            method: 'POST',
                            body: jsonData,
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    }
                ]
            );
        }
    }

    if (done) {
        return (
            <ErrorWrap>
                <div style={{ maxWidth: "700px" }} className="text-center">
                    <h1 className="text-success mb-4 mt-4">{t('user_created')}</h1>
                    <LinkContainer to={{ pathname: "/system/users/new" }}>
                        <Button className="me-3">{t('create_other_user')}</Button>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: "/system/users/" + newUserID }}>
                        <Button>{t('created_user_detail')}</Button>
                    </LinkContainer>
                </div>
            </ErrorWrap>
        );
    }

    const invalidUsername = existingUsers.includes(username);
    const cannotSave = !username || !mail || !workerCode || invalidUsername;

    return (
        <ErrorWrap>
            <Row>
                <Col>
                    <Row>
                        <Col><h4 className="d-inline">{t('sys-create_new_user')}</h4></Col>
                        <Col className='text-end' xs="auto">
                            {saving ?
                                <Button className=" mb-2" disabled>{t('creating_user')}...</Button>
                                :
                                <Button className=" mb-2" disabled={cannotSave} onClick={saveUser}>{t('save')}</Button>
                            }
                            <LinkContainer to={{ pathname: "/system/users" }}>
                                <Button disabled={saving} variant='danger' className='ms-4 mb-2 '>{t('discard_changes')}</Button>
                            </LinkContainer>

                        </Col>
                    </Row>
                    <UserProps userlogin={userlogin} editEnabled={editEnabled} username={username} setUsername={setUsername} workerCode={workerCode} setWorkerCode={setWorkerCode}
                        hisMail={mail} setHisMail={setMail} oid={oid} setOid={setOid}
                        invalidUsername={invalidUsername} lang={lang} setLang={setLang} />
                </Col>
                <Col></Col>
            </Row>
        </ErrorWrap>
    )
}
