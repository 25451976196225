import { authorizedFetch } from "./common";
import { OrderBy } from "../lib/utilTypes";
import { StockNotificationsTrackingFilterValues } from "../orders/stockNotificationTracking/StockNotifyTracking";

export function getStockNotificationsTracking(
  orderBy: StockNotificationsTrackingFilterValues["orderBy"],
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetStockNotificationTrackingResponseItem[]>(
    `/api/stock-notifications/tracking/new?&orderBy=${orderBy}`,
    {
      ...options,
      method: "GET",
    }
  );
}
