/**
 * Overview of currently running threads in HIS.
 * @module system/threads
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { his_fetch, HisFetchStatus } from './comp/FetchLoader';
import { Table, Card, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { date_time_format } from './lib/date-utils';
import { filter_rule, icompare } from './lib/utils';
import { Loading } from './comp/loading';
import { useTranslation } from 'react-i18next';

//seen all - OK

//children je pole uzlů, když je to prázdné pole, tak pass1 a pass2 vrátí prázdné pole, - funkce se nezavolá ani jednou, pokud tomu dám prázdné pole 
// když tam jsou children, tak v pass1 dostanu pole o stejné velikosti - stejný childs, a v pass2 udělám filtraci
// pokud má uzel poduzly, tak na něj dále aplikuju tu filter_thread_children
//zpracuj potomky a vrať mi subset potomků, ten subset pak filtruju
// pokud dělám pass2, tak má jistotu, že všechno pod tím má hotové oba passy
// princip stromového argoritmu - nejdřív jdeš až na dno, pak  se postupně vracíš nahoru 
/**
 * Function for tree structure filtering
 * 
 * @param {any} children - any children to filter, it can be also root thread
 * @param {function} filter_func - filtering function
 * @returns {array} - array of filtered items
 */
function filter_thread_children(children, filter_func) {
    const pass1 = children.map((child) => ({ ...child, filterPassed: filter_func(child), children: filter_thread_children(child.children || [], filter_func) })); //map na prázdné pole nic neudělá
    const pass2 = pass1.filter((child) => child.filterPassed || child.children.length > 0); //ten skutečný filtr //dítě tohoto záznamu vyhovuje filtru
    return pass2; //pokud dané uzel nemá filter_passed, tak je šedej
}

/**
 * Visualization of currently running threads in HIS
 * 
 * @param {any} userlogin - current user info 
 * @returns {component}
 */
export function Threads({ userlogin }) {
    const { t } = useTranslation();

    const [newData, setNewData] = useState(null);
    const [oldData, setOldData] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [filterRunning, setFilterRunning] = useState(false);
    const [filterName, setFilterName] = useState("");
    const [filterRuntime, setFilterRuntime] = useState("");
    const [reloadingPeriod, setReloadingPeriod] = useState(0); //0 = no backgroud reloading, or miliseconds
    const [collapsed, setCollapsed] = useState({});

    //console.log(newData);
    //console.log(loadedStatus);

    function toggleCollapsed(id) {
        setCollapsed({ ...collapsed, [id]: !collapsed[id] });
    }

    //console.log(reloadingPeriod);
    //console.log(loadedStatus);

    function reloadIt() {
        setNewData(null);
    }

    /** spustí fetch, pokud newData === null, nastaví oldData a newData na result, return funkce ruší fetch, 
     * pokud nejsou newData null (nezačínáme), tak setTimeout, kde dám newData na null, a za reloadingPeriod znova...?, tato větev useEffectu v returnu clearuje timeout
     * 
     */
    useEffect(() => {
        //console.log("useEffect - start");
        //console.log(reloadingPeriod);
        if (newData === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/threads-monitoring",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            // console.log(result);
                            setOldData(result);
                            setNewData(result);
                            //console.log("fetch ok");
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setNewData("error"); //pokud to neklaplo, tak by to chtělo změnit newData
                        }
                    }
                ]);
            //console.log("useEffect - fetch running");
            return () => { //pokud podmínka newData === null je pravdivá, tak spustí fetch, kterej se v případě potřeby zruší
                //console.log("return useEffect - stopping fetch");
                running_fetch();
                //console.log("return useEffect - finished");
            }
        } else {
            if (reloadingPeriod > 0) {
                //console.log("starting timeout");
                const timeout_id = setTimeout(() => {
                    //console.log("tick tock");
                    setNewData(null);
                    // setData((data) => result);
                }, reloadingPeriod);
                //console.log("timeout set");
                return () => {
                    clearTimeout(timeout_id);
                };
            }
        }
    }, [userlogin, reloadingPeriod, newData]);

    //pokud newData nejsou null, tak je nech, jinak použij oldData, případně je přenastav na prázdné, tímto je vždy co zobrazit nějak, pokud tam nejsou z nějakých minulých iterací oldData, tak prázdné
    const data = (newData !== null && newData !== "error" ) ? newData : oldData !== null ? oldData : { threads: [] };

    const handlePeriodChange = setReloadingPeriod; //tohle je identické setReloadingPeriod(val)?? //tohle je alias, kdybych chtěla reloadovat hned po  změně periody, tak tu bych nastavila newData na null


    const threads = [data.threads];
    const filtered_threads = filter_thread_children(threads,
        function (child) {
            return (
                filter_rule(filterName, child.name)
                &&
                icompare(child["run-time"], filterRuntime)
                &&
                (
                    (filterRunning === false) ||
                    (filterRunning && child["running?"] === true)
                )
            );
        });


    return (
        <>
            <Row>
                <Col>
                    <h5 className='mb-3'>
                        <Button disabled={loadedStatus !== 2} size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
                        {t('sys-threads')}
                    </h5>
                </Col>
                <Col className='text-center'>
                    {loadedStatus !== 2 ? <Loading size="small" message={t('loading_data')} margin="0" /> : ""}
                </Col>
                <Col className='text-end'>
                    <h6 className='d-inline'>Reloading:&nbsp;</h6>
                    <Form.Group controlId="display" className='mb-0 ms-3 d-inline'>
                        <Form.Check inline name="reloadingTime" type="radio" value={0} id='1' label="Never" onClick={() => handlePeriodChange(0)} defaultChecked={reloadingPeriod === 0} />
                        <Form.Check inline name="reloadingTime" type="radio" value={15 * 1000} id='2' label="15s" onClick={() => handlePeriodChange(15 * 1000)} defaultChecked={reloadingPeriod === 15} />
                        <Form.Check inline name="reloadingTime" type="radio" value={1 * 60 * 1000} id='3' label="1min" onClick={() => handlePeriodChange(60 * 1000)} defaultChecked={reloadingPeriod === 60} />
                        <Form.Check inline name="reloadingTime" type="radio" value={5 * 60 * 1000} id='4' label="5min" onClick={() => handlePeriodChange(5 * 60 * 1000)} defaultChecked={reloadingPeriod === 5 * 60} />
                    </Form.Group>
                </Col>
            </Row>

            <Card body className='mb-2'>
                <Row>
                    <Col><span className='text-muted'>Timestamp&nbsp;</span> {data.timestamp ? date_time_format(data.timestamp) : "-"}</Col>
                    <Col><span className='text-muted'>Current memory use&nbsp;</span> {data["current-memory-use"] ? data["current-memory-use"] : "-"}</Col>
                    <Col><span className='text-muted'>Current gc milliseconds&nbsp;</span> {data["current-gc-milliseconds"] ? data["current-gc-milliseconds"] : "-"}</Col>
                </Row>
            </Card>


            <h5 className='mt-4'>Threads</h5>
            <Table size="sm" striped>
                <thead className='beGray'>
                    <tr>
                        <th className='pb-2'>
                            <Form.Group controlId="filterName">
                            <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="&#128269;"
                                    value={filterName} onChange={(event) => setFilterName(event.target.value)} />
                            </Form.Group>
                        </th>
                        <th className='pb-2'>
                            <Form.Group controlId="filterRuntime" className="mb-0">
                                <Form.Label>Run time</Form.Label>
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterRuntime} onChange={(ev) => setFilterRuntime(ev.target.value)}
                                />
                            </Form.Group>

                        </th>
                        <th className='pb-2'>Running
                            <Form.Group className="mb-3 d-inline" controlId="running">
                                <Form.Check type="checkbox"
                                    label="true"
                                    id="running"
                                    checked={filterRunning}
                                    onChange={() => setFilterRunning(filterRunning ? false : true)} />
                            </Form.Group>
                        </th>
                        <th className='pb-2'>Dead children</th>
                        <th className='pb-2'>Dead children run time</th>
                        <th className='pb-2'>Alive children</th>
                    </tr>
                </thead>
                <ThreadsTable filtered_threads={filtered_threads}
                    collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            </Table>

            <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />


        </>
    );
}


/**
 * Overview of processes running in HIS.
 * @param {dictionary} data - all data available
 * @return {component}
 */
function ThreadsTable({ filtered_threads, collapsed, toggleCollapsed }) {

    return (
        <tbody>
            {filtered_threads.map((thread, idx) => <ThreadWithChildren key={idx} rowData={thread} collapsed={collapsed} toggleCollapsed={toggleCollapsed} />)}
        </tbody>
    );
}

/**
 * Row with its children.
 * @param {dictionary} rowData - dictionary representing all rows
 * @return {component}
 */
function ThreadWithChildren({ rowData, indent = 0, collapsed, toggleCollapsed }) {
    const this_collapsed = !!collapsed[rowData.name];
    return (
        <>
            <ThreadRow rowData={rowData} indent={indent} collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            {(this_collapsed ? [] : rowData.children).map(function (kid, idx) {
                return <ThreadWithChildren rowData={kid} indent={indent + 1} key={idx} collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            })}
        </>
    );
}


/**
 * Single row of one thread.
 * @param {dictionary} rowData - dictionary representing all rows
 * @param {dictionary} indent - left indentation for children
 * @return {component}
 */
function ThreadRow({ rowData, indent = 0, collapsed, toggleCollapsed }) {
    //  console.log(rowData);
    const padding = String(indent * 25) + "px";
    const class_grey = rowData.filterPassed ? "" : " text-muted ";
    const this_collapsed = !!collapsed[rowData.name];
    return (
        <tr className={class_grey}>
            <td style={{ paddingLeft: padding }}>
                {rowData.children.length > 0 ?
                    <Button size="sm" variant="light" className='mx-1 w-5' onClick={() => toggleCollapsed(rowData.name)} > {this_collapsed ? "+" : <>&ndash;</>}</Button>
                    : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {rowData.name}</td>
            <td>{rowData["run-time"]}</td>
            <td>{String(rowData["running?"])}</td>
            <td>{rowData["dead-children"]}</td>
            <td>{rowData["dead-children-run-time"]}</td>
            <td>{rowData["alive-children"]}</td>
        </tr>
    );
}
