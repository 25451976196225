import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputGroup, { InputGroupProps } from "react-bootstrap/InputGroup";
import { FaCalendarAlt } from "react-icons/fa";
import { isFunction } from "../lib/utils";

type MyInputProps = InputGroupProps & {
  value?: string;
  size: FormControlProps["size"];
};

const MyInput = React.forwardRef<HTMLDivElement, MyInputProps>(({ value, onClick, onChange, size }, ref) => (
  <InputGroup onClick={onClick} ref={ref} style={{ minWidth: "130px", maxWidth: "200px" }}>
    <Form.Control size={size} className="bg-white" value={value} onChange={onChange} />
    <InputGroup.Text>
      <FaCalendarAlt />
    </InputGroup.Text>
  </InputGroup>
));

interface DatePickerCustomProps {
  onChange: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
  value: string | Date;
  size?: FormControlProps["size"];
}

/**
 * Form for date.
 */
export function DatePickerCustom({ onChange, minDate, maxDate, value, size = "sm" }: DatePickerCustomProps) {
  function changeDate(date) {
    if (isFunction(onChange)) {
      onChange(date);
    }
  }

  return (
    <DatePicker
      id="date-validity"
      locale="cs"
      dateFormat={["d. M. yyyy", "d.M.yyyy", "d. M.yyyy", "d.M. yyyy"]}
      selected={value ? new Date(value) : null}
      minDate={minDate}
      maxDate={maxDate}
      onChange={changeDate}
      customInput={<MyInput size={size} />}
      showYearDropdown
      yearDropdownItemNumber={30}
      scrollableYearDropdown
    />
  );
}
