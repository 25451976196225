/**
 * Components for rendering web version of delivery notes list
 * @module delivery-notes/delivery-note-sales-web
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { date_formatCZ } from '../lib/date-utils';
import { Boolean } from '../comp/boolean';
import Table from 'react-bootstrap/Table';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import {DeliveryNotesWebFilters} from "./DeliveryNotesWebFilters";
//import { mappings } from './delivery-notes-sales';

//seen all - OK

/*
 * Single desktop line of delivery note
 * 
 * @param {any} dn - one line of delivery note
 * @param {string} type - ist "PD" for sales delivery notes and "ND" for purchase delivery notes
 * @returns {component}
 */
function DeliveryNoteSale({ dn, type }) {
	const url = type === "PD" ? "/delivery-notes/sales/" : "/delivery-notes/purchase/";
	const exportImport = type === "PD" ? "exported" : "imported";
	return (
		<LinkContainer to={{ pathname: url + dn.deliveryNoteCode }} key={dn[0]}>
			<tr key={dn.deliveryNoteCode}>
				<td>{dn.deliveryNoteCode}</td>
				<td>{dn.orderCode}</td>
				<td className="text-center"><Boolean variant="onlyTrue" value={dn[exportImport]} /></td>
				<td>{dn.partnerCode}</td>
				<td>{dn.partnerName}</td>
				<td>{dn.note}</td>
				<td>{dn.orderDate ? date_formatCZ(dn.orderDate) : "-"}</td>
				<td className="text-end">{dn.invoicedPercent} %</td>
				<td className="text-center"><Boolean variant="onlyTrue" value={dn.closed} /></td>
				<td className="text-center"><Boolean variant="onlyTrue" value={dn.approved} /></td>
				<td className="text-center"><Boolean variant="negative" value={dn.cancelled} /></td>
				<td>{dn.issuedBy}</td>
			</tr>
		</LinkContainer>
	);
}

/*
 * Desktop list of delivery notes with filters
 * 
 * @param {boolean} isLoaded - says, whether show new content or loading, prevents old data to stay, when switching from sale to purchase list of delivery notes
 * @returns {component}
 */
export function DeliveryNotesSalesWeb({ dnotes_shown, type, isLoaded }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<div className="d-none d-lg-block">
				<Table hover striped >
					<thead className="beGray">
						<DeliveryNotesWebFilters type={type} />
					</thead>
					<tbody>
						{isLoaded ?
							dnotes_shown.map((dn, idx) => <DeliveryNoteSale key={idx} dn={dn} type={type} />)
							:
							<></>
						}
					</tbody>
				</Table>
			</div>
		</ErrorWrap>
	)
}
