
/**
 * Definition of colors in the system
 * @module lib/colors
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

const delay_colors_def =  [
    ['rgba(23, 162, 184, 0.5)',-10000],
    ['rgba(255, 223, 100, 0.5)',-7],
    ['rgba(255, 216, 52, 0.5)',0],
    ['rgba(255, 178, 52, 0.5)',30],
    ['rgba(248, 118, 42, 0.5)',60],
    ['rgba(255, 98, 98, 0.5)',90]    
];

export function delay_color(days) {
    var i;
    var res = '';
    for (i = 0 ; i < delay_colors_def.length ; i++) {
	const cdef = delay_colors_def[i];
	if (days > cdef[1]) {
	    res = cdef[0];
	} else {
	    return res;
	}
    }
    return res;
}

export function worst_color() {
    return delay_colors_def[delay_colors_def.length - 1][0];
}

export const rating_colors = {
    'A': '#81b02e',
    'B': '#ccd92e',
    'C': '#ffbf00',
    'D': '#f49629',
    'E': '#f0691a',
    'F': '#ff4200',
    'X': '#6c757d',
    '?': '#7892ae'
};

export const statistics_colors = [
    "#a6cee3",
    "#1f78b4",
    "#b2df8a",
    "#33a02c",
    "#fb9a99",
    "#e31a1c",
    "#fdbf6f",
    "#ff7f00",
    "#cab2d6",
    "#6a3d9a",
    "#ffff99",
    "#b15928"
];

//colors for progress-path
export const greenish = "#198754";
export const redish = "#dc3545";
export const yellowish = "rgb(217, 164, 4)";
export const greyish = "#a1aeb3";