import React, { Dispatch, SetStateAction, useContext, useEffect, useReducer, useState } from "react";
import { ProductsListFilterValues } from "../products/ProductsList";
import { matchPath, useLocation } from "react-router-dom";
import { UseFormReset, UseFormWatch } from "react-hook-form";
import { useDebounceFilterValuesCallback } from "../hooks/debounceFilterValues";
import { OrdersPurchasePagedFilterValues } from "../orders/OrdersPurchasePaged";
import { OrdersSalePagedFilterValues } from "../orders/OrdersSalePaged";
import { DeliveryNotesWebFiltersValues } from "../delivery-notes/DeliveryNotesWebFilters";
import { TrackingTableFilterValues } from "../delivery-notes/DeliveryNotesTracking";
import { StockNotificationsTrackingFilterValues } from "../orders/stockNotificationTracking/StockNotifyTracking";

interface FilterCacheData {
  products: FilterCacheItem<ProductsListFilterValues>;
  purchaseOrders: FilterCacheItem<OrdersPurchasePagedFilterValues>;
  saleOrders: FilterCacheItem<OrdersSalePagedFilterValues>;
  deliveryNotesPD: FilterCacheItem<DeliveryNotesWebFiltersValues>;
  deliveryNotesND: FilterCacheItem<DeliveryNotesWebFiltersValues>;
  deliveryNotesTracking: FilterCacheItem<TrackingTableFilterValues>;
  stockNotificationTracking: FilterCacheItem<StockNotificationsTrackingFilterValues>;
}

type FilterCacheAreas = {
  [key in keyof FilterCacheData]: string[];
};

const areas: FilterCacheAreas = {
  products: ["/products/list", "/products/:code/view"],
  purchaseOrders: ["/orders/purchase", "/orders/purchase/:code"],
  saleOrders: ["/orders/sale", "/orders/sale/:code"],
  deliveryNotesPD: ["/delivery-notes/sales", "/delivery-notes/sales/:code"],
  deliveryNotesND: ["/delivery-notes/purchase", "/delivery-notes/purchase/:code"],
  deliveryNotesTracking: ["/delivery-notes/tracking", "/delivery-notes/sales/:code"],
  stockNotificationTracking: ["/orders/tracking", "/orders/purchase/:code"],
};

interface FilterCacheItem<T> {
  filter?: T;
  offset?: number;
}

const defaultValue: FilterCacheData = {
  products: { offset: 0 },
  purchaseOrders: { offset: 0 },
  saleOrders: { offset: 0 },
  deliveryNotesPD: { offset: 0 },
  deliveryNotesND: { offset: 0 },
  deliveryNotesTracking: { offset: 0 },
  stockNotificationTracking: { offset: 0 },
};

const FilterCacheContext = React.createContext<FilterCacheData>(defaultValue);

const FilterCacheDispatchContext = React.createContext<Dispatch<FilterCacheAction>>(undefined);

interface FilterCacheProviderProps {
  children: React.ReactNode;
}

function useFilterCache() {
  const context = React.useContext(FilterCacheContext);
  if (!context) {
    throw new Error("useFilterCache must be used within a FilterCacheProvider");
  }
  return context;
}

export function FilterCacheProvider({ children }: FilterCacheProviderProps) {
  const location = useLocation();
  const [, setArea] = useState<keyof FilterCacheData | null>(null);
  const [state, dispatch] = useReducer(reducer, defaultValue);

  useEffect(() => {
    const newAreas = Object.keys(areas).filter((key) =>
      areas[key].some((pattern: string) => {
        return matchPath(pattern, location.pathname);
      })
    ) as (keyof FilterCacheData)[];
    setArea((area) => {
      if (newAreas.includes(area)) return area;
      const newArea = newAreas[0];
      if (area === newArea) return area;
      if (!!area) dispatch({ type: area, filter: "reset" });
      return newArea;
    });
  }, [location.pathname]);

  return (
    <FilterCacheContext.Provider value={state}>
      <FilterCacheDispatchContext.Provider value={dispatch}>{children}</FilterCacheDispatchContext.Provider>
    </FilterCacheContext.Provider>
  );
}

interface FilterCacheAction {
  type: keyof FilterCacheData;
  filter: FilterCacheItem<unknown> | "reset";
}

function reducer(state: FilterCacheData, action: FilterCacheAction): FilterCacheData {
  const newState = { ...state };
  if (action.filter === "reset") {
    newState[action.type] = { offset: 0 };
    return newState;
  }
  newState[action.type] = { ...newState[action.type], ...action.filter };
  return newState;
}

export function useCachedOffset(type: keyof FilterCacheData): [number, Dispatch<SetStateAction<number>>] {
  const cache = useFilterCache();
  const dispatch = useContext(FilterCacheDispatchContext);
  const [offset, setOffset] = useState(cache[type].offset ?? 0);

  return [
    offset,
    (newOffset: number) => {
      setOffset(newOffset);
      dispatch({ type, filter: { offset: newOffset } });
    },
  ];
}

export function useCachedDebouncedFilter<T>(
  type: keyof FilterCacheData,
  watch: UseFormWatch<T>,
  reset: UseFormReset<T>,
  defaultValue: T,
  delay: number = 500
): [T] {
  const cache = useFilterCache();
  const dispatch = useContext(FilterCacheDispatchContext);
  const [filter, setFilter] = useState<T>((cache[type].filter as T) ?? defaultValue);

  useEffect(() => {
    reset((cache[type].filter as T) ?? defaultValue);
  }, [reset, defaultValue]);

  useDebounceFilterValuesCallback(
    watch,
    (v) => {
      setFilter((p) => ({ ...p, ...v }));
      dispatch({ type, filter: { filter: v } });
    },
    delay
  );

  return [filter];
}

export function useCachedOffsetAndFilter<T>(
  type: keyof FilterCacheData,
  watch: UseFormWatch<T>,
  reset: UseFormReset<T>,
  defaultValue: T,
  delay: number = 500
): [number, Dispatch<SetStateAction<number>>, T] {
  const [offset, setOffset] = useCachedOffset(type);
  const [filter] = useCachedDebouncedFilter(type, watch, reset, defaultValue, delay);

  return [offset, setOffset, filter];
}
