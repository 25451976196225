import { authorizedFetch } from "./common";

export function getStocksRecords(warehouseId: number, date: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetStocksRecordsResponse>(`/api/stocks/records/${warehouseId}/${date}/new`, {
    ...options,
    method: "GET",
  });
}

export function getTwistStocksRecords(warehouseId: number, date: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetTwistStocksResponse>(`/api/stocks/twist/${warehouseId}/${date}/new`, {
    ...options,
    method: "GET",
  });
}

export function getStocksExpirations(date: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetStocksExpirationsResponse>(`/api/stocks/expirations/all/${date}/new`, {
    ...options,
    method: "GET",
  });
}

export function getStocksPacksAll(date: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetStocksPacksAllResponseItem[]>(`/api/stocks/packs/all/${date}/new`, {
    ...options,
    method: "GET",
  });
}

export function getStocksPacksNotes(date: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetStocksPacksNotesResponseItem[]>(`/api/stocks/packs/notes/${date}/new`, {
    ...options,
    method: "GET",
  });
}
