import { authorizedFetch } from "./common";

export function getProduct(code: string, options?: RequestInit) {
  return authorizedFetch<Dto.GetProductDetailResponse>(`/api/products/${code.replaceAll("/", "%2F")}`, {
    ...options,
    method: "GET",
  });
}

export function getProductWarehouseStatuses(code: string, options?: RequestInit) {
  return authorizedFetch<Dto.ProductDetailWarehouseStatus[]>(
    `/api/products/${code.replaceAll("/", "%2F")}/warehouse-statuses`,
    { ...options, method: "GET" }
  );
}

export function getProductConfiguration(id: number, options?: RequestInit) {
  return authorizedFetch<Dto.GetProductConfigResponse[]>(`/api/products/${id}/config`, {
    ...options,
    method: "GET",
  });
}

export function setProductConfiguration(id: number, config: Dto.PutProductConfigRequest, options?: RequestInit) {
  return authorizedFetch<Dto.ResultMessage>(`/api/products/${id}/config`, {
    ...options,
    body: JSON.stringify(config),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
  });
}

export function sendProductWarehouseCard(productId: number, warehouseId: number, options?: RequestInit) {
  return authorizedFetch<Dto.ResultMessage>(`/api/products/${productId}/send-card/${warehouseId}`, {
    ...options,
    method: "PUT",
  });
}

export function cancelSendingProductWarehouseCard(productId: number, warehouseId: number, options?: RequestInit) {
  return authorizedFetch(`/api/products/${productId}/cancel-card-send/${warehouseId}`, {
    ...options,
    method: "GET",
  });
}

export function getProducts(take: number = 10, skip: number = 0, filter: unknown = {}, options?: RequestInit) {
  return authorizedFetch<Dto.GetProductsItemResponsePagedResponse>(
    `/api/products?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    { ...options, method: "GET" }
  );
}

export function getProductsCheck(options?: RequestInit) {
  return authorizedFetch<Dto.GetProductsCheckNewResponse>(`/api/products/check/new`, { ...options, method: "GET" });
}
