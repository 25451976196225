import React, { useState } from 'react';
import { his_fetch } from '../comp/FetchLoader';
import { stock_progress_specification } from './progress-stockins-def';
import { ProgressPath, make_progress_steps } from '../comp/progress';
import { Loading } from '../comp/loading';
import { ConfirmButton } from '../comp/confirm-button';
import { useTranslation } from 'react-i18next';
import { remove_spaces_and_enters } from "../lib/format";

//seen all - OK


/**
 * Progress path for stockin.
 * 
 * @param {any} one_history - history records for one stockin
 * @param {boolean} compact - true if displayed with labels
 * @param {any} userlogin - information about logged user
 * @param {boolean} authorizedUser - whether user is authorized to use magic buttons
 * @param {function} reloadIt - reloads after usage of magic buttons
 * @returns {component}
 */
export function StockinProgressPath({ one_history, compact = false, userlogin, authorizedUser, reloadIt }) {
    const { t } = useTranslation();

    const [updating, setUpdating] = useState(false);
    const [errorMess, setErrorMess] = useState("");

    //console.log(remove_spaces_and_enters("sd sd \n fd ... er re"));

    function updateStatus(sn_id, wanted_status, extraInfo = false) {
        const uriAppend = extraInfo ? "/" + remove_spaces_and_enters(extraInfo) : "";
        const uri = "/api/stock-notifications/" + sn_id + "/set-status/" + wanted_status + uriAppend;
        if (!updating) {
            setUpdating(true);
            his_fetch(
                userlogin,
                [
                    {
                        uri: uri,
                        json: true,
                        ok: function (resource, result) {
                            //console.log(result);
                            if (result.update === "ok") {
                                //console.log("ok");
                                reloadIt();
                            } else {
                                console.log(t('error'));
                                setErrorMess('error');
                                setUpdating(false);
                            }
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setErrorMess('error');
                            setUpdating(false);
                        },
                        args: {
                            method: 'PUT',
                        }
                    }
                ]
            );
        }
    }

    //console.log(notify.history);

    const my_steps = make_progress_steps(stock_progress_specification, one_history,
        function (step) {
            //console.log(step)
            const timeDiffMinutes = (new Date() - new Date(step.timestamp)) / 1000 / 60;
            const over15min = timeDiffMinutes > 15;
            const showMagicButtons = authorizedUser && over15min && step.active;
            const loading = updating && step.active;
            const loadingComponent = loading ? <Loading margin="0" size="small" /> : "";
            const cancelButton = <ConfirmButton finalOnClick={() => updateStatus(step.sns_sn_id, 10)}
                initialButtonText={t('cancel')}
                initialButtonColor="danger"
                popoverText={t('really_wanna_cancel_sn')}
                popoverButtonText={t('cancel_manually')}
                popoverButtonColor="danger"
                errorMessage={errorMess}
                loadingComponent={loadingComponent}
            />;
            const confirmButton = <ConfirmButton finalOnClick={() => updateStatus(step.sns_sn_id, 14)}
                initialButtonText={t('confirm')}
                initialButtonColor="warning"
                popoverText={t('really_wanna_conform_sn')}
                popoverButtonText={t('confirm_manually')}
                popoverButtonColor="warning"
                errorMessage={errorMess}
                loadingComponent={loadingComponent}
            />;

            const stockinButton = <ConfirmButton finalOnClick={(extraInfo) => updateStatus(step.sns_sn_id, 13, extraInfo)} //ta lamdba dostane hodnotu z té stavé proměnné
                initialButtonText={t('stock_in')}
                initialButtonColor="warning"
                popoverText={t('really_wanna_stock_in')}
                popoverButtonText={t('manually_stock_in')}
                popoverButtonColor="warning"
                errorMessage={errorMess}
                loadingComponent={loadingComponent}
                extraInfo
                inputLabel={t('enter_delivery_note_purchase_code')}
                validationRegex={/^ND[0-9]{12}$/}
                errorMessageInput={t('deno_code_must_have_ND_prep_and_12_digits')}
            />;
            const NDcode = <div>{step.sns_twist_nd}</div>;
            const Twist_response = <div className={step.status === 6 ? 'text-danger' : ""}>{step.sns_twist_response}</div>; //naprasená classa, ale zatím chceme červenou pouze ve stavu 6 zamítnuto, jinak default barvy

            function switch_content_for_state(status) {
                switch (status) {
                    case 4: // 4: Vyzvednuto
                        return (
                            <>
                                {showMagicButtons ?
                                    <>{cancelButton}{confirmButton}</> : <></>}
                            </>
                        );

                    case 5: // 5: Potvrzeno
                    case 14: // 14: Ručně potvrzeno
                    case 7: // 7. Příjem obdržen, ale trvá dlouho
                        return (
                            <>
                                {showMagicButtons ?
                                    <>{cancelButton}{stockinButton}</> : <></>}
                            </>

                        );
                    case 11: // 11: Chyba v oznámení
                    case 12: // 12: Chyba naskladnění
                    case 8: // 8. Příjem uložen
                        return <>
                            {showMagicButtons ? <>{stockinButton}</> : <></>}
                        </>;
                    case 13: // 13: Ručně naskladněno
                    case 9: // 9: Naskladněno (automaticky)
                        return <>
                            {NDcode}
                        </>;
                    default:
                        return <></>;
                }
            }
            return (
                <>
                    {step.sns_twist_response ? Twist_response : ""}
                    {switch_content_for_state(step.status)}
                </>
            );

        },
    );
    return (
        <ProgressPath steps={my_steps} compact={compact} authorizedUser={authorizedUser} />
    );
}