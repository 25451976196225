import React, { useState, useEffect } from "react";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Table, Form, Row, Col } from "react-bootstrap";
import { warehouses_names_ids_separe } from '../lists/warehouses-defs';
import { Pager } from '../comp/pager';
import { filter_rule, icompare, compare_values } from '../lib/utils';
import { ItemsAndFiltered } from "../comp/dynamic-load";
import { date_time_format, date_formatCZ } from "../lib/date-utils";
import { SortIcons } from '../comp/sort';
import { convert_warehouse_container } from './product-checks';
import { format_amount } from "../lib/format";
import { useTranslation } from 'react-i18next';
import { hilite } from "../lib/format";
import { MultipleSelect } from '../comp/multiple-select';
import { BooleanDropdown } from '../comp/boolean';
import { MyInfo } from '../comp/info-badge';
import {
    unique_sorted_product_ids, get_batch_weight_kg_twist, get_package_count_twist,
    kg_package_weight, get_batch_weight_kg_warehouse, create_wh_dict
} from "./product-utils";



/**
 * Keys remaping HSH TWIST: adds some keys to every dictionary (batch) in an array - goal is unify(rename) keys
 * 
 * @param {array} batches - array of dictionaries of batches
 * @returns {array}
 */
function remap_twist_keys(batches) {
    return batches.reduce((acc, batchLine) => //this is how we add keys to dicts in array of dicts!!!!!!
        [...acc,
        {
            ...batchLine,
            // keys added on frontend have prefix "alf": a - first letter of alphabetically shown keys, l - lucka, f - frontend
            /*šarže*/
            alf_batch: (batchLine.sth_KodSerie || "").trim(),
            /*Množství MJ */
            alf_batch_sum: batchLine.sth_MnozstviSkl, //tohle pak zobrazuju, to je ok
            /*Hmotnost KG*/
            //alf_batch_weight_kg_old: kg_package_weight(batchLine) * (batchLine.sth_MnozstviSkl / batchLine.pjo_MnozstviSklMjVObalu),
            alf_batch_weight_kg: get_batch_weight_kg_twist(batchLine),
            alf_package: convert_warehouse_container(batchLine.wid, batchLine.co_k_HSHObal5),
            alf_package_count: get_package_count_twist(batchLine),
            //alf_package_count_old: batchLine.sth_MnozstviSkl / batchLine.pjo_MnozstviSklMjVObalu, //(počet balení - toto se používá jen na stavech zboží v twistu (na inventurách ne))
            alf_package_amount: batchLine.pjo_MnozstviSklMjVObalu, // kolik MJ je v jednom balení, to je ok 
            // alf_amount_of_batch - kolik je toho na šarži v MJ
            alf_package_unit: batchLine.p_KodMjSkl, // MJ
            // kolik má celá šarže v kg
            alf_locations: [],
            alf_last_update: batchLine.sth_stamp,
        }],
        []);
}

/* Fraction behavioral study
console.log(Fraction(null)); // ok, 0
//console.log(Fraction("fff")); //spadne
//console.log(Fraction("")); //spadne
console.log(Fraction("3")); //ok, 3
*/

/**
 * Keys remaping warehouses: adds some keys to every dictionary (batch) in an array - goal is unify(rename) keys
 * 
 * @param {array} batches - array of dictionaries of batches
 * @returns {array}
 */
function remap_warehouses_keys(batches) {
    return batches.reduce((acc, batchLine) => //this is how we add keys to dicts in array of dicts!!!!!!
        [...acc,
        {
            ...batchLine,
            // keys added on frontend have prefix "alf": a - first letter of alphabetically shown keys, l - lucka, f - frontend
            alf_batch: (batchLine.sr_KodSerie || "").trim(),
            alf_batch_sum: batchLine.sr_amount,
            //alf_batch_weight_kg_old: kg_package_weight(batchLine) * batchLine.sr_items_count,
            alf_batch_weight_kg: get_batch_weight_kg_warehouse(batchLine),
            alf_package: batchLine.co_k_HSHObal5,
            alf_package_count: batchLine.sr_items_count,
            alf_package_amount: batchLine.sr_amount_per_item,
            alf_package_unit: batchLine.sr_amount_unit,
            alf_locations: batchLine.sr_locations,
            alf_last_update: null
        }],
        []);
}


/**
 * Preprocessing of stocks data for both - Twist and warehouse data
 * 
 * @param {any} stocksData - stocks from warehouse or Twist
 * @param {string} type - type can be warehouse or anythning else, which is interpreted as Twist
 * @returns {array}
 */
export function preprocess_stocks_data(stocksData, type, sort_key = "alf_last_update", direction = 1) {
    const productID_db_name = type === "warehouses" ? "sr_k_IDProduktu" : "sth_k_IDProduktu";
    const unique_product_ids_sorted = unique_sorted_product_ids(stocksData, productID_db_name);

    //creates dictionary, that matches productID as key with product data as value
    const product_batches_tree = unique_product_ids_sorted.reduce(function (acc, productID) {
        const oneProductStocksData = stocksData.filter((rec) => rec[productID_db_name] === productID);
        const productData = type === "warehouses" ? remap_warehouses_keys(oneProductStocksData) : remap_twist_keys(oneProductStocksData);
        //console.log(productData);
        return ({
            ...acc,
            [productID]: productData,
        })
    }, {});

    // creates array of dictionaries with data for one product, including its batches
    //console.log(product_batches_tree[5400]);
    const stocksTreeArray = unique_product_ids_sorted.map(function (IDProduktu) {
        const this_product = product_batches_tree[IDProduktu];
        const one_batch = this_product[0];
        //adding sum keys to array of batches dictionaries and sorting them later
        const unique_batches = this_product
            .map((b) => b.alf_batch)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((b) => this_product.find((br) => br.alf_batch === b))
            .map((br) => ({
                ...br,
                alf_batch_sum: this_product
                    .filter((br2) => br.alf_batch === br2.alf_batch)
                    .reduce((acc, br) => acc + (br.alf_batch_sum || 0), 0),
                alf_batch_weight_kg: this_product
                    .filter((br2) => br.alf_batch === br2.alf_batch)
                    .reduce((acc, br) => acc + (br.alf_batch_weight_kg || 0), 0)
            }));
        const sorted_batches = unique_batches.sort((a, b) => sort_last_update(a, b, sort_key, direction === "up" ? 1 : -1));
	// if (IDProduktu==469) {
	//     console.log('==== ' + IDProduktu);
	//     console.log('XXX package_kg = ' + kg_package_weight(one_batch));
	//     console.log(sorted_batches);
	//     console.log(this_product);
	// } 
        return ({
            IDProduktu: IDProduktu,
            product_name: one_batch.p_NazevProduktu,
            product_code: one_batch.p_KodProduktu,
            package: one_batch.alf_package,
            package_amount: one_batch.alf_package_amount,
            package_unit: one_batch.alf_package_unit,
            package_weight_kg: kg_package_weight(one_batch),
            batches: sorted_batches,
            sum_of_batches_amounts: unique_batches.map((batch) => batch.alf_batch_sum).reduce((partial_sum, a) => partial_sum + a, 0), //FRACTION needed?
            product_sum_kg: kg_package_weight(one_batch) * unique_batches.map((batch) => batch.alf_package_count).reduce((partial_sum, a) => partial_sum + a, 0), //FRACTION needed? //váha jednoho * suma všech kusů
        })
    }).sort((a, b) => sort_last_update(a.batches[0], b.batches[0], sort_key, direction === "up" ? 1 : -1));

    //console.log(stocksTreeArray);
    return stocksTreeArray;
}

/**
 * Compares two values according to sorting key, two dictionaries are expected  
 * 
 * @param {dictionary} a - first entry
 * @param {dictionary} b - second entry
 * @param {string} sort_key - name of sorting key
 * @param {string} direction - sorting direction
 * @returns {number} - represents which entry is below the other
 */
export function sort_last_update(a, b, sort_key = "alf_last_update", direction = 1) {
    const a_value = a[sort_key];
    const b_value = b[sort_key];
    //console.log('sorting: a_date='+a_date+' b_date='+b_date);
    if (a_value < b_value) {
        return 1 * direction;
    }
    if (b_value < a_value) {
        return -1 * direction;
    }
    return 0;
}

/**
 * special preprocessing for expirations, where 3 level structure is needed
 * 
 * @param {any} expirations - data received from backend
 * @param {string} direction - direction of sorting 
 * @param {string} sort_key - key name for sorting
 * @returns {any} - array of dictionaries grouped by ID_produktu, with batches per products, with batch_in_warehouse instances per batch/expiry (same bach may be in multiple warehouses)
 */
function preprocess_expirations_data(expirations, direction, sort_key) {
    const unique_product_ids_sorted = unique_sorted_product_ids(expirations, "sth_k_IDProduktu");
    // console.log(unique_product_ids_sorted);

    //creates dictionary, that matches productID as key with product data as value
    const product_batches_tree = unique_product_ids_sorted.reduce(function (acc, productID) {
        const oneProductStocksData = expirations.filter((rec) => rec.sth_k_IDProduktu === productID);
        const productData = remap_twist_keys(oneProductStocksData);
        //console.log(productData);
        return ({
            ...acc,
            [productID]: productData,
        })
    }, {});
    // console.log(product_batches_tree);

    // creates array of dictionaries with data for one product, including its batches
    const expirationsTreeArray =
        sort_key === "expiry" ? //ternary operator was used, because sorting of expiry is on second level of structure and sorting of last_update is on third level, so we need 2 or 3 times sorting for mentioned cases

            unique_product_ids_sorted.map(function (IDProduktu) {
                const this_product = product_batches_tree[IDProduktu]; //všechny batches per jedno IDProduktu
                const one_batch = this_product[0]; //první batch z produktové  série

                return ({
                    IDProduktu: IDProduktu,
                    product_name: one_batch.p_NazevProduktu,
                    product_code: one_batch.p_KodProduktu,
                    package: one_batch.alf_package,
                    centre: one_batch.p_KodSkupiny6,
                    batches: process_expirations_batches(this_product).sort((a, b) => compare_values(a, b, "expiry", direction === "up" ? 1 : -1)), //local sorting of expirations within batches on product
                    product_td_rowspan: this_product.length,
                    problematic: this_product.filter((rec) => rec.ps_DatExpirace === null).length > 0 //zatím je problematic === bez expirace
                })
            })
                .sort((a, b) => compare_values(a.batches[0], b.batches[0], sort_key, direction === "up" ? 1 : -1)) //sorting according to expiry globaly

            :

            unique_product_ids_sorted.map(function (IDProduktu) {
                const this_product = product_batches_tree[IDProduktu]; //všechny batches per jedno IDProduktu
                const one_batch = this_product[0]; //první batch z produktové  série
                //console.log(one_batch);
                return ({
                    IDProduktu: IDProduktu,
                    product_name: one_batch.p_NazevProduktu,
                    product_code: one_batch.p_KodProduktu,
                    package: one_batch.alf_package,
                    centre: one_batch.p_KodSkupiny6,
                    batches: process_expirations_batches(this_product, direction, sort_key).sort((a, b) => compare_values(a.batches_in_whs[0], b.batches_in_whs[0], "alf_last_update", direction === "up" ? 1 : -1)), //second level sorting based on last update within one product
                    product_td_rowspan: this_product.length,
                    problematic: this_product.filter((rec) => rec.ps_DatExpirace === null).length > 0 //zatím je problematic === bez expirace
                })
            })
                .sort((a, b) => compare_values(a.batches[0].batches_in_whs[0], b.batches[0].batches_in_whs[0], sort_key, direction === "up" ? 1 : -1)); //global sorting based on last_update

    return expirationsTreeArray;
}

/**
 * Returns batches in product grouped by batchString, batches_in_whs represent the batch in warehouse
 * 
 * @param {*} batches - all batches per one product
 * @param {*} direction - direction of sorting 
 * @param {*} sort_key - key name for sorting
 * @returns {any} - array of dictionaries
 */
function process_expirations_batches(batches, direction, sort_key) {
    const unique_batches = unique_sorted_product_ids(batches, "sth_KodSerie");
    const batches_tree = unique_batches.reduce(function (acc, batchString) {
        const oneBatchData = batches.filter((rec) => rec.sth_KodSerie === batchString);
        return ({
            ...acc,
            [batchString]: oneBatchData,
        })
    }, {});
    const batchesTreeArray = unique_batches.map(function (batchString) {
        const this_batch = batches_tree[batchString]; //všechny záznamy per jedden batchstring
        const one_batch_item = this_batch[0]; //první záznam z série jedné šarže
        const sort_it = direction && sort_key;
        const batches_in_whs = sort_it ? this_batch.sort((a, b) => compare_values(a, b, sort_key, direction === "up" ? 1 : -1)) : this_batch; //third level of sorting based on last update, only if we call the function with params direction and sort_key
        return ({
            alf_batch: batchString,
            expiry: one_batch_item.ps_DatExpirace,
            batches_in_whs: batches_in_whs,
            batch_td_rowspan: this_batch.length
        })
    })

    return batchesTreeArray;
}


/**
 * Fetches and displays stocks in particular warehouse (warehouse and twist view is switched by type prop)
 * 
 * @param {any} userlogin - info about logged user
 * @param {any} dataType - "warehouses" or "twitst" or "expirations" - used for fetch
 * @param {any} warehouseID - warehouse to fetch
 * @param {any} fetchDate - effective date to fetch
 * @returns {component}
 */
export function WarehouseStocksDisplay({ userlogin, dataType, warehouseID, fetchDate }) {

    const [stocksData, setStocksData] = useState(null);
    const [updateTime, setUpdateTime] = useState("-");
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [offset, setOffset] = useState(0);

    const reloadIt = () => {
        setStocksData(null);
        setLoadedStatus(0);
    }

    useEffect(() => {
        if (stocksData === null) {
            const fetchType = dataType === "warehouses" ? "records" : dataType; // warehouses are special case, where url and api path arent same, twist and expirations are same=ok
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stocks/" + fetchType + "/" + warehouseID + "/" + fetchDate,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setStocksData(result.records);
                            setUpdateTime(result.snapshot_created || null);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setStocksData("error");
                        }

                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, stocksData, warehouseID, dataType, fetchDate]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    return (
        <WarehousesStocksTable stocksData={stocksData} offset={offset} setOffset={setOffset}
            updateTime={updateTime} dataType={dataType} warehouseID={warehouseID} />
    )
}



function WarehousesStocksTable({ stocksData, offset, setOffset, updateTime, dataType, warehouseID }) {
    const { t } = useTranslation();

    const dictionaryOfWHValues = create_wh_dict(stocksData, true);
    const dictionaryOfWHValuesFalse = create_wh_dict(stocksData, false);
    const itemsNames = Object.keys(dictionaryOfWHValues).reduce((acc, v) => ({ ...acc, [v]: v }), {});

    /*
    console.log(dictionaryOfWHValues);
    console.log(dictionaryOfWHValuesFalse);
    console.log(itemsNames);
    */

    const [filterID, setFilterID] = useState("");
    const [filterBatch, setFilterBatch] = useState("");
    //const [filterLocation, setFilterLocation] = useState("");
    const [filterCount, setFilterCount] = useState("");
    const [filterAmount, setFilterAmount] = useState("");
    const [filterAmountKG, setFilterAmountKG] = useState("");
    const [filterTotal, setFilterTotal] = useState("");
    const [filterTotalKG, setFilterTotalKG] = useState("");
    const [filterSuperTotal, setFilterSuperTotal] = useState("");
    const [filterSuperTotalKG, setFilterSuperTotalKG] = useState("");
    const [sortValue, setSortValue] = useState("expiry");
    const [direction, setDirection] = useState("down");
    const [type, setType] = useState("");
    const [checkedWH, setCheckedWH] = useState(dictionaryOfWHValues);
    const [filterProblematic, setFilterProblematic] = useState(null);
    const [filterCentre, setFilterCentre] = useState("");

    const cleanFilters = () => {
        setFilterAmount("");
        setFilterAmountKG("");
        setFilterBatch("");
        setFilterCount("");
        setFilterID("");
        setFilterTotal("");
        setFilterTotalKG("");
        setFilterSuperTotal("");
        setFilterSuperTotalKG("");
        setFilterProblematic(null);
        setFilterCentre("")
        setCheckedWH(dictionaryOfWHValues);
    }

    useEffect(() => {
        const dictionaryOfWHValues = create_wh_dict(stocksData, true);
        setCheckedWH(checkedWHCat => ({ ...dictionaryOfWHValues, ...checkedWHCat })); //functional updates 
    }, [stocksData]);

    const handleChangeID = function (event) {
        setFilterID(event.target.value);
        setOffset(0);
    }

    const handleChangeBatch = function (event) {
        setFilterBatch(event.target.value);
        setOffset(0);
    }

    const handleChangeCount = function (event) {
        setFilterCount(event.target.value);
        setOffset(0);
    }

    const handleChangeAmount = function (event) {
        setFilterAmount(event.target.value);
        setOffset(0);
    }
    const handleChangeAmountKG = function (event) {
        setFilterAmountKG(event.target.value);
        setOffset(0);
    }
    const handleChangeTotal = function (event) {
        setFilterTotal(event.target.value);
        setOffset(0);
    }
    const handleChangeTotalKG = function (event) {
        setFilterTotalKG(event.target.value);
        setOffset(0);
    }
    const handleChangeSuperTotal = function (event) {
        setFilterSuperTotal(event.target.value);
        setOffset(0);
    }
    const handleChangeSuperTotalKG = function (event) {
        setFilterSuperTotalKG(event.target.value);
        setOffset(0);
    }
    const handleChangeCentre = function (event) {
        setFilterCentre(event.target.value);
        setOffset(0);
    }

    // console.log(stocksData);
    const preprocessedData = dataType === "expirations" ? preprocess_expirations_data(stocksData, direction, sortValue) : preprocess_stocks_data(stocksData, dataType, sortValue, direction);
    //const preprocessedData = preprocess_stocks_data(stocksData, dataType, sortValue, direction);

    // console.log(preprocessedData);

    const stocks_filtered = preprocessedData.filter(
        function (stock) {
            return (
                (filter_rule(filterID, stock.IDProduktu, true) || filter_rule(filterID, stock.product_code, true) || filter_rule(filterID, stock.product_name, true)) &&
                (icompare(stock.package_amount, filterAmount)) &&
                (icompare(stock.package_weight_kg, filterAmountKG)) &&
                (icompare(stock.sum_of_batches_amounts, filterSuperTotal)) &&
                (icompare(stock.product_sum_kg, filterSuperTotalKG)) &&
                (stock.batches.reduce((res, batch) => res || filter_rule(filterBatch, batch.alf_batch, true), false)) &&
                (stock.batches.reduce((res, batch) => res || icompare(batch.alf_package_count, filterCount), false)) &&
                (stock.batches.reduce((res, batch) => res || icompare(batch.alf_batch_sum, filterTotal), false)) &&
                (stock.batches.reduce((res, batch) => res || icompare(batch.alf_batch_weight_kg, filterTotalKG), false))
                // (stock.batches.locations.reduce((res, batch) => res || filter_rule(filterLocation, batch.locations, true), false))
            );
        }
    )

    const expirations_filtered = preprocessedData.filter(
        function (stock) {
            return (
                ((filterProblematic === null) || (filterProblematic === stock.problematic)) &&
                (filter_rule(filterID, stock.IDProduktu, true) || filter_rule(filterID, stock.product_code, true) || filter_rule(filterID, stock.product_name, true)) &&
                (filter_rule(filterCentre, stock.centre, true)) &&
                (stock.batches.reduce((res, batch) => res || filter_rule(filterBatch, batch.alf_batch, true), false)) &&

                (stock.batches.reduce((res, batch) => res || (batch.batches_in_whs || []).reduce((res, batchInWh) => res || icompare(batchInWh.alf_package_count, filterCount), false), false)) &&
                (stock.batches.reduce((res, batch) => res || (batch.batches_in_whs || []).reduce((res, batchInWh) => res || icompare(batchInWh.alf_batch_sum, filterTotal), false), false)) &&
                (stock.batches.reduce((res, batch) => res || (batch.batches_in_whs || []).reduce((res, batchInWh) => res || icompare(batchInWh.alf_batch_weight_kg, filterTotalKG), false), false)) &&
                (stock.batches.reduce((res, batch) => res || (batch.batches_in_whs || []).reduce((res, batchInWh) => res || checkedWH[batchInWh.wid], false), false))
            );
        }
    )

    const filtered = dataType === "expirations" ? expirations_filtered : stocks_filtered;

    const stocks_sorted = filtered.sort(function (a, b) {
        if (type === "num") {
            if (direction === "up") {
                return a[sortValue] - b[sortValue];
            }
            if (direction === "down")
                return b[sortValue] - a[sortValue];
        }
        return 0;
    });

    const show_stocks = stocks_sorted.slice(offset, offset + 20);

    //dataType can be following: warehouses, twist, expirations
    const showOnePackContent = dataType === "warehouses" || dataType === "twist" ? true : false;
    const showSum = dataType === "warehouses" || dataType === "twist" ? true : false;
    const showWarehouseAndExpiry = dataType === "expirations" ? true : false;

    return (
        <>
            <Row>
                <Col>
                    <h4 className="mb-3">{showWarehouseAndExpiry ? "" : t('prod-stock_wh') + warehouses_names_ids_separe[warehouseID]}</h4>
                    {updateTime ? <p className="text-secondary">{t('prod-last_update')}: {date_time_format(updateTime)}</p> : ""}
                </Col>
                <Col className="text-center">
                    <ItemsAndFiltered filtered_data={filtered} data={preprocessedData} cleanFilters={cleanFilters} />
                </Col>
                <Col>
                    <Pager offset={offset} pagesize={20} total={filtered.length} callback={setOffset} />
                </Col>
            </Row>
            <Table size="sm" bordered style={{ backgroundColor: "white" }}>
                <thead className="beGray">
                    <tr>
                        {showWarehouseAndExpiry ?
                            <th rowSpan={3} className="text-center pb-2">
                                <p className="mb-2">{t('problematic')}</p>
                                <BooleanDropdown variant="product-checks" onChange={setFilterProblematic} value={filterProblematic} />
                            </th>
                            : <></>
                        }
                        <th rowSpan={3} className="align-middle text-center pb-2">
                            <div className="mb-1">{t('not-prod_id')}&nbsp;
                                <SortIcons name={"IDProduktu"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric />
                                {t('prod-code')}
                                <br />
                                {t('prod-name')}
                            </div>
                            <Form.Group controlId="filterCode" className='mb-0'>
                                <Form.Control type="text" placeholder="&#128269;" value={filterID} onChange={handleChangeID} />
                            </Form.Group>
                        </th>
                        {showWarehouseAndExpiry ?
                            <th rowSpan={3} className="text-center pb-1">{t('biz-centre')}
                                <Form.Group controlId="filterCentre">
                                    <Form.Control type="text" placeholder="&#128269;" value={filterCentre} onChange={handleChangeCentre} />
                                </Form.Group>
                            </th>
                            : <></>}
                        <th rowSpan={3} className="text-center pb-2">{t('ord-pack')}</th>
                        {showOnePackContent ?
                            <th colSpan={2} rowSpan={2} className="text-center ">{t('prod-1_pack_content')} 🧴</th>
                            : <></>}
                        <th colSpan={showWarehouseAndExpiry ? 7 : 5} className="text-center">{t('prod-found_batches')}</th>
                        {showSum ?
                            <th colSpan={2} rowSpan={2} className="text-center ">{t('product_total')}</th>
                            : <></>}
                    </tr>
                    <tr>
                        <th rowSpan={2} className="">  <p className="mb-1">{t('ord-batch')}</p>
                            <Form.Group controlId="filterCode" className='mb-1'>
                                <Form.Control type="text" placeholder="&#128269;" value={filterBatch} onChange={handleChangeBatch} />
                            </Form.Group>
                        </th>
                        {showWarehouseAndExpiry ?
                            <>
                                <th rowSpan={2} className="text-end pb-2">{t('ord-expiry')}<br />
                                    <SortIcons name={"expiry"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType} numeric />
                                </th>
                                <th rowSpan={2} className="text-center">{t('warehouse')}
                                    <MultipleSelect checked={checkedWH} setChecked={setCheckedWH}
                                        dictionaryTrue={dictionaryOfWHValues} dictionaryFalse={dictionaryOfWHValuesFalse}
                                        itemsNames={itemsNames} setOffset={setOffset} id="filterWH" withoutNumber />

                                </th>
                            </>
                            : <></>
                        }
                        <th colSpan={2} className="text-center">{t('prod-amount_per_batch')}</th>
                        <th rowSpan={2} className="text-end ">
                            <Form.Group controlId="filtercOUNT" className="mb-0">
                                <Form.Label className="mb-1"> {t('prod-pack_count')}</Form.Label>
                                <Form.Control className="mb-1" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterCount}
                                    onChange={handleChangeCount} />
                            </Form.Group>
                        </th>
                        {dataType === "twist" || dataType === "expirations" ?
                            <th rowSpan={2} className="text-end pb-2">{t('prod-date_of_last')} <br /> {t('prod-status_update')} <br />
                                <SortIcons name={"alf_last_update"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric /></th>
                            :
                            <th rowSpan={2} className="pb-2 text-center">{t('prod-wh_location')}</th>
                        }
                    </tr>
                    <tr>
                        {showOnePackContent ?
                            <>
                                <th className="text-center">
                                    <Form.Group controlId="filterAmountMJ" className="mb-0">
                                        <Form.Label className="mb-1">{t('measure_unit')} <MyInfo text='mj_means_warehouse_unit' /></Form.Label>
                                        <Form.Control className="mb-1" type="text"
                                            placeholder="&#128269; > < ="
                                            value={filterAmount}
                                            onChange={handleChangeAmount} />
                                    </Form.Group>
                                </th>
                                <th className="text-center">
                                    <Form.Group controlId="filterAmountKG" className="mb-0">
                                        <Form.Label className="mb-1">{t('prod-kg')}</Form.Label>
                                        <Form.Control className="mb-1" type="text"
                                            placeholder="&#128269; > < ="
                                            value={filterAmountKG}
                                            onChange={handleChangeAmountKG} />
                                    </Form.Group>
                                </th>
                            </>
                            : <></>}
                        <th className="text-center">
                            <Form.Group controlId="filterTotal" className="mb-0">
                                <Form.Label className="mb-1"> {t('measure_unit')} <MyInfo text='mj_means_warehouse_unit' /></Form.Label>
                                <Form.Control className="mb-1" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterTotal}
                                    onChange={handleChangeTotal} />
                            </Form.Group>
                        </th>
                        <th className="text-center">
                            <Form.Group controlId="filterTotalKG" className="mb-0">
                                <Form.Label className="mb-1"> {t('prod-kg')}</Form.Label>
                                <Form.Control className="mb-1" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterTotalKG}
                                    onChange={handleChangeTotalKG} />
                            </Form.Group>
                        </th>

                        {showSum ?
                            <>
                                <th className="text-center">
                                    <Form.Group controlId="filterSuperTotal" className="mb-0">
                                        <Form.Label className="mb-1"> {t('measure_unit')} <MyInfo text='mj_means_warehouse_unit' /></Form.Label>
                                        <Form.Control className="mb-1" type="text"
                                            placeholder="&#128269; > < ="
                                            value={filterSuperTotal}
                                            onChange={handleChangeSuperTotal} />
                                    </Form.Group>
                                </th>

                                <th className="text-center">
                                    <Form.Group controlId="filterSuperTotalKG" className="mb-0">
                                        <Form.Label className="mb-1"> {t('prod-kg')}</Form.Label>
                                        <Form.Control className="mb-1" type="text"
                                            placeholder="&#128269; > < ="
                                            value={filterSuperTotalKG}
                                            onChange={handleChangeSuperTotalKG} />
                                    </Form.Group>
                                </th>
                            </>
                            : <></>}
                    </tr>
                </thead>
                {showWarehouseAndExpiry ?
                    <tbody>
                        {show_stocks.map(function (prod, idx) {
                            // console.log(show_stocks);
                            const bg = (idx % 2) === 0 ? "" : "beGray3";
                            return prod.batches.map(function (batch, idxBatch) {
                                // console.log(batch);
                                return batch.batches_in_whs.map(function (batchInWh, idxBatchInWh) {
                                    const class_hilite_MJ = icompare(batchInWh.alf_batch_sum, filterTotal) && filterTotal !== "" ? "bg-info-light" : "";
                                    const class_hi_kg = icompare(batchInWh.alf_batch_weight_kg, filterTotalKG) && filterTotalKG !== "" ? "bg-info-light" : "";
                                    const class_hi_count = icompare(batchInWh.alf_package_count, filterCount) && filterCount !== "" ? "bg-info-light" : "";
                                    const class_hi_wh = checkedWH[batchInWh.wid] && !(Object.values(checkedWH).filter((val) => val === false).length === 0) ? "bg-info-light" : ""; //pokud jsou všechny true, tak nebarvit, pole těch false je 0
                                    const problematic_bg = prod.problematic ? "bg-danger" : "bg-success";
                                    //  console.log(batchInWh);
                                    return (
                                        <tr key={idxBatchInWh} className={bg}>
                                            {
                                                idxBatch === 0 && idxBatchInWh === 0 ?
                                                    <>
                                                        <td rowSpan={prod.product_td_rowspan} className={problematic_bg + " text-center align-middle"}>{prod.problematic ? <span className='bg-light p-1'>❌</span> : "✅"}</td>
                                                        <td rowSpan={prod.product_td_rowspan} className={" align-middle text-center"}>
                                                            <h5>{hilite(String(prod.IDProduktu) || "", filterID)}</h5>
                                                            {hilite(prod.product_code || "", filterID)} <br />
                                                            {hilite(prod.product_name || "", filterID)}
                                                        </td>
                                                        <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">{hilite(prod.centre || "", filterCentre)}</td>
                                                        <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">{prod.package}</td>
                                                    </>
                                                    : <></>
                                            }

                                            {idxBatchInWh === 0 ?
                                                <>
                                                    <td rowSpan={batch.batch_td_rowspan} className="align-middle"> {filterBatch.length > 0 ? hilite(batch.alf_batch || "", filterBatch) : batch.alf_batch}</td>
                                                    <td rowSpan={batch.batch_td_rowspan} className="text-end align-middle">{batch.expiry ? date_formatCZ(batch.expiry) : <span className="text-danger">{t('expiry-missing')}!</span>}</td>
                                                </> : <></>
                                            }


                                            <td className="text-center align-middle"><span className={class_hi_wh}>{batchInWh.wid}</span></td>
                                            <td className="text-center align-middle"><span className={class_hilite_MJ}>{format_amount(batchInWh.alf_batch_sum)} {batchInWh.alf_package_unit}</span></td>
                                            <td className="text-center align-middle"><span className={class_hi_kg}>{format_amount(batchInWh.alf_batch_weight_kg)}&nbsp;{t('prod-kg')}</span></td>
                                            <td className="text-end align-middle"><span className={class_hi_count}>{batchInWh.alf_package_count} {t('piece_short')}</span></td>
                                            <td className="text-end align-middle">{batchInWh.alf_last_update ? date_formatCZ(batchInWh.alf_last_update) : "-"}</td>
                                        </tr>
                                    );
                                })
                            })
                        })}
                    </tbody>
                    :
                    <tbody>
                        {show_stocks.map(function (prod, idx) {
                            // console.log(show_stocks);
                            const bg = (idx % 2) === 0 ? "" : "beGray3";
                            return prod.batches.map(function (batch, idxBatch) {
                                const batchesLength = prod.batches.length;
                                return (
                                    <tr key={idxBatch} className={bg}>
                                        {idxBatch === 0 ?
                                            <>
                                                <td rowSpan={batchesLength} className="align-middle text-center">
                                                    <h5>{prod.IDProduktu}</h5>
                                                    {prod.product_code} <br />
                                                    {prod.product_name}
                                                </td>
                                                <td rowSpan={batchesLength} className="align-middle text-center">{prod.package}</td>
                                                {showOnePackContent ?
                                                    <>
                                                        <td rowSpan={batchesLength} className="align-middle text-center">{format_amount(prod.package_amount)} {prod.package_unit}</td>
                                                        <td rowSpan={batchesLength} className="align-middle text-center">{format_amount(prod.package_weight_kg)}&nbsp;{t('prod-kg')}</td>
                                                    </>
                                                    : <></>}
                                            </>
                                            : <></>}
                                        <td className="align-middle">{filterBatch.length > 0 ? hilite(batch.alf_batch || "", filterBatch) : batch.alf_batch}</td>
                                        <td className="text-center align-middle">{format_amount(batch.alf_batch_sum)} {batch.alf_package_unit}</td>
                                        <td className="text-center align-middle">{format_amount(batch.alf_batch_weight_kg)}&nbsp;{t('prod-kg')}</td>
                                        <td className="text-end align-middle">{batch.alf_package_count} {t('piece_short')}</td>
                                        {dataType === "twist" || dataType === "expirations" ?
                                            <td className="text-end align-middle">{batch.alf_last_update ? date_formatCZ(batch.alf_last_update) : "-"}</td>
                                            :
                                            <td className="align-middle text-center">{String((batch.alf_locations || []).map((m) => " " + m))}</td>
                                        }
                                        {idxBatch === 0 && showSum ?
                                            <>
                                                <td rowSpan={batchesLength} className="align-middle text-center">{format_amount(prod.sum_of_batches_amounts)} {prod.package_unit}</td>
                                                <td rowSpan={batchesLength} className="align-middle text-center">{format_amount(prod.product_sum_kg)}&nbsp;{t('prod-kg')}</td>
                                            </>
                                            : <></>}
                                    </tr>
                                );
                            })
                        })}
                    </tbody>
                }

            </Table >
        </>
    );
}
