import { useState, useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ReportConfig } from './report-config';
import { useParams } from "react-router-dom";
import { ReportTabs, ListSources, AmountsTables, ReportResultTable } from './reporting-components';
import { useTranslation } from 'react-i18next';
import { get_booked_string_for_filename } from './report-utils';


export function ReportBalanceSheet({ userlogin, reportName }) {
    const { t } = useTranslation();

    const { year } = useParams();
    const { month } = useParams();
    const { booked } = useParams();

    // Store the data
    const [balances, setBalances] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [config, setConfig] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [updating, setUpdating] = useState(false); // for func saveConfig
    const [mess, setMess] = useState(''); // error message for func saveConfig
    const [saved, setSaved] = useState(true);
    const [history, setHistory] = useState([]);
    const [rehistory, setRehistory] = useState([]);
    const [accountNames, setAccountNames] = useState(null);

    useEffect(() => {
        const storno_fetch = his_fetch(
            userlogin,
            [
                {
                    uri: "/api/reporting/mappings/" + reportName,
                    json: true,
                    status: setLoadedStatus,
                    ok: (resource, result) => {
                        //console.log("got config");
                        setConfig(result.config);
                    },
                    error: function (resource, reason) {
                        console.log('err: ' + reason);
                        setConfig("error");
                    }
                },
                {
                    uri: "/api/reporting/balance-sheet/" + year + "/" + month + "/" + booked, //(month === "full" ? "" : ("/" + month)),
                    json: true,
                    status: setLoadedStatus,
                    ok: (resource, result) => {
                        //console.log("got balances");
                        // console.log(result);
                        setBalances(result.months);
                        setCurrentMonth(shortenMonth(Object.keys(result.months).sort()[0]));
                        setAccountNames(result.chartOfAccounts);
                    },
                    error: function (resource, reason) {
                        console.log('err: ' + reason);
                        setBalances("error");
                    }
                }
            ]
        );
        return () => {
            // console.log("finito");
            storno_fetch();
        }
    }, [userlogin, month, year, reportName]);


    //console.log(config);
    // Continue only if we have the source balances
    if ((!his_fetch_success(loadedStatus)) || (config === null) || balances === null) {
        return <HisFetchStatus status={loadedStatus}
            loadingType="big"
            errorType="fetcherError"
        />;
    }


    function saveConfig() {
        if (!updating) {
            setUpdating(true);
            setMess("");
            const jsonData = JSON.stringify(config);
            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/reporting/mappings/" + reportName,
                        json: true,
                        ok: function (resource, result) {
                            //console.log('confirmation: ' + result);
                            if (result.error) {
                                console.log(result);
                                setMess([t('saving_failed'), "text-danger"]);
                                setUpdating(false);
                            } else {
                                //console.log(result);
                                setMess([t('successfully_saved'), "text-success"]);
                                setUpdating(false);
                            }
                        },
                        error: function (resource, reason) {
                            console.log('error: ' + reason);
                            setUpdating(false);
                            setMess([t('saving_failed'), "text-danger"]);
                        },
                        args: {
                            method: 'PUT',
                            body: jsonData,
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    }
                ]
            )
        }
    }

    //console.log(updating);
    //console.log(mess);

    // Get all available sources
    const months = Object.keys(balances).sort();
    const srcIds = months
        .reduce((acc, month) => [...acc, ...Object.keys(balances[month])], [])
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort();
    const dstIds = Object.keys(config || {});

    //console.log(balances);
    //console.log(srcIds);

    const shortMonths = months.map(shortenMonth);
    const currentLongMonth = months.filter((m) => currentMonth && m.substring(0, currentMonth.length) === currentMonth)[0];
    const language = userlogin.userinfo.language;
    const accountNameTranslation = language === "en" ? "NazevUctuPreklad" : "NazevUctu";

    //console.log(months);

    return (
        <>
            <ReportTabs />
            <Routes>
                <Route path='balance' element={
                    <AmountsTables balances={balances} grouping={{ "": /.*/ }}
                        accountNames={accountNames} keyName={accountNameTranslation}
                        excelFileName={reportName + "_" + year + "-" + month + "-" + t(get_booked_string_for_filename(booked)).toLocaleLowerCase() + "-balances.xlsx"} />
                } />
                <Route path='sources' element={
                    <ListSources sources={srcIds} grouping={{ "": /.*/ }}
                        accountNames={accountNames} keyName={accountNameTranslation} />
                } />
                <Route path='config' element={
                    <ReportConfig name="Balance sheet"
                        setup={{
                            srcIds: srcIds,
                            dstIds: dstIds,
                            balances: balances[currentLongMonth]
                        }}
                        setConfig={setConfig}
                        subMenu={shortMonths}
                        subMenuLabel={t('months')}
                        currentSubMenu={currentMonth}
                        onSubMenu={(month) => setCurrentMonth(month)}
                        config={config}
                        onSave={saveConfig}
                        savingMess={mess} setSavingMess={setMess}
                        saved={saved} setSaved={setSaved}
                        history={history} setHistory={setHistory}
                        rehistory={rehistory} setRehistory={setRehistory}
                        accountNames={accountNames} keyName={accountNameTranslation}
                        reportName={reportName}
                    />
                } />

                <Route path='result' element={
                    <ReportResultTable identifiers={dstIds}
                        config={config}
                        balances={balances}
                        columns={months}
                        excelFileName={reportName + "_" + year + "-" + month + "-" + t(get_booked_string_for_filename(booked)).toLocaleLowerCase() + "-result.xlsx"} />
                } />
            </Routes>
        </>
    );
}

function shortenMonth(s) {
    return s.split("-").reverse().slice(1).reverse().join("-");
}

