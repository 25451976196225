/**
 * Components for rendering purchase delivery note
 * @module delivery-notes/delivery-note-purchase-detail
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Col, Card, Table, Button, Image, Accordion, ListGroup } from 'react-bootstrap';
import { Boolean } from '../comp/boolean';
import { date_formatCZ } from '../lib/date-utils';
import { MandatoryAddressPart } from '../partners/partner-detail';
import { romanize_packaging_group } from '../products/product-checks';

//seen all - OK

/**
 * Detail of purchase delivery note
 * 
 * @param {any} userlogin - info about logged user
 * @returns {component} 
 */
export function DeliveryNotePurchaseDetail({ userlogin }) {

    const { kodDokladu } = useParams();

    const [loadedStatus, setLoadedStatus] = useState(0);
    const [document, setDocument] = useState(null);

    const reloadIt = () => {
        setDocument(null);
    }

    useEffect(() => {
        if (document === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/purchase-delivery-notes/" + kodDokladu,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setDocument(result);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setDocument("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, document, kodDokladu]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    const unique_itemIds = document.items.map((itm) => itm.spp_IDPolozky).filter((v, i, a) => a.indexOf(v) === i);
    const items_by_itemIds = unique_itemIds.reduce((acc, item_id) => ({
        ...acc,
        [item_id]: document.items.filter((rec) => rec.spp_IDPolozky === item_id)
    }), {});

    const items_transformed = unique_itemIds.map(function (item_id) {
        return ({
            item_id: item_id,
            items: items_by_itemIds[item_id]
        });
    })

    //console.log(items_transformed);

    const header = document.header || {};
    const partner = document.partner[0] || {};
    const items = items_transformed;

    return (
        <ErrorWrap>
            <Button size="sm" className="me-2 mb-2" onClick={() => { setDocument(null); setLoadedStatus(0) }}><Image src="/img/reload.svg" height="19" /></Button>
            <h4 className='d-inline'>Nákupní dodací list {document.KodDokladu}</h4>
            <Row>
                <Col xs="12" lg="6">
                    <BasicInfo header={header} />
                </Col>
                <Col xs="12" lg="6">
                    <PartnerInfo partner={partner} />
                </Col>
            </Row>
            <Items items={items} />
            <NDItemsMobile items={items} />
        </ErrorWrap>
    );
}


/**
 * Table of items on purchase delivery note
 * 
 * @param {any} items - grouped items by IDPolozky
 * @returns {component}
 */
function Items({ items }) {
    const { t } = useTranslation();
    return (
        <Card body className='d-none d-lg-block'>
            <h5>{t('biz-items')}</h5>
            <Table bordered size="sm">
                <thead className='beGray'>
                    <tr>
                        <th>{t('biz-centre')}</th>
                        <th>{t('deno-contract')}</th>
                        <th>{t('deno-category')}</th>
                        <th>{t('prod-id')}</th>
                        <th>{t('prod-code')}</th>
                        <th>{t('warehouse')}</th>
                        <th>{t('prod-name')}</th>
                        <th>{t('ord-adr_class')}</th>
                        <th>{t('ord-pack_group')}.</th>
                        <th>{t('ord-un_num')}</th>
                        <th>{t('ord-batch')}</th>
                        <th className='text-end'>{t('prod-amount_MJ')}</th>
                        <th className='text-center'>{t('ord-expiry')}</th>
                        <th>{t('biz-invoiced')}</th>
                        <th>{t('biz-closed')}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((itms) =>
                        itms.items.map((itm, idxx) =>
                            <tr key={idxx}>
                                {idxx === 0 ?
                                    <>
                                        <td rowSpan={itms.items.length} className='align-middle text-muted'>{itm.spp_KodStrediska ? itm.spp_KodStrediska : "-"}</td>
                                        <td rowSpan={itms.items.length} className='align-middle text-muted'>{itm.spp_KodZakazky ? itm.spp_KodZakazky : "-"}</td>
                                        <td rowSpan={itms.items.length} className='align-middle text-muted'>{itm.spp_KodKategorie ? itm.spp_KodKategorie : "-"}</td>

                                        <td rowSpan={itms.items.length} className='align-middle'>{itm.p_k_IDProduktu}</td>
                                        <td rowSpan={itms.items.length} className='align-middle'>
                                            <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(itm.spp_KodProduktu) + "/view" }}>
                                                <a href="!#">
                                                    {itm.spp_KodProduktu}
                                                </a>
                                            </LinkContainer></td>
                                        <td rowSpan={itms.items.length} className='align-middle'>{itm.spp_KodKnihy}</td>
                                        <td rowSpan={itms.items.length} className='align-middle'>{itm.spp_NazevProduktu}</td>
                                        <td rowSpan={itms.items.length} className='align-middle text-center'>{itm.kcunc_TridaADR ? itm.kcunc_TridaADR : "-"}</td>
                                        <td rowSpan={itms.items.length} className='align-middle text-center'>{itm.p_k_ADRObalovaSkupina ? romanize_packaging_group(itm.p_k_ADRObalovaSkupina) : "-"}</td>
                                        <td rowSpan={itms.items.length} className='align-middle text-center'>{itm.p_k_ADRUNCislo ? itm.p_k_ADRUNCislo : "-"}</td>

                                    </>
                                    : <></>
                                }
                                <td>{itm.sps_KodSerie}</td>
                                <td className='text-end'>{itm.sps_MnozstviSkl}&nbsp;{itm.p_KodMjSkl}</td>
                                <td className='text-end'>{itm.sps_DatExpirace ? date_formatCZ(itm.sps_DatExpirace) : "-"}</td>

                                {idxx === 0 ?
                                    <>
                                        <td rowSpan={itms.items.length} className='align-middle text-center'><Boolean variant="onlyTrue" value={itm.spp_Fakturovano} /></td>
                                        <td rowSpan={itms.items.length} className='align-middle text-center'><Boolean variant="onlyTrue" value={itm.spp_Uzavreno} /></td>
                                    </>
                                    : <></>
                                }
                            </tr>))}
                </tbody>
            </Table>
        </Card>
    )
}

function NDItemsMobile({ items }) {
    return (
        <Accordion className="d-lg-none">
            {
                items.map(function (item, idx) {
                    return <SingleNDItemMobile
                        key={idx}
                        item={item}
                    />;
                })}
        </Accordion>
    );
}

function SingleNDItemMobile({ item }) {
    // console.log(item);
    const { t } = useTranslation();
    const item_comms = item.items[0];
    //console.log(item_comms);
    return (
        <ErrorWrap>
            <Accordion.Item eventKey={item_comms.p_k_IDProduktu}>
                <Accordion.Header as="div" >
                    <div>
                        <b>{t('prod-name')}:</b> {item_comms.spp_NazevProduktu}
                        <br />
                        <b>{t('prod-code')}: </b>
                        <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(item_comms.spp_KodProduktu) + "/view" }}>
                            <a href="!#">{item_comms.spp_KodProduktu}</a>
                        </LinkContainer>
                        <br />
                        <b>{t('not-prod_id')}: </b>{item_comms.p_k_IDProduktu}
                    </div>

                </Accordion.Header>
                <Accordion.Body className='p-2'>
                    <Card.Body className="beSmaller">
                        <ListGroup variant="flush" className="smaller">
                            <ListGroup.Item>
                                <Row className="g-0">
                                    <Col>{t('deno-contract')}: <span className='text-muted'>{item_comms.spp_KodZakazky}</span></Col>
                                    <Col className="text-end">{t('warehouse')}: {item_comms.spp_KodKnihy}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col xs="auto">
                                        {t('biz-centre')}: <span className='text-muted'>{item_comms.spp_KodStrediska}</span>

                                    </Col>
                                    <Col className="text-end">{t('ord-adr_class')}: {item_comms.kcunc_TridaADR ? item_comms.kcunc_TridaADR : "-"}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col xs="auto">{t('deno-category')}: <span className='text-muted'>{item_comms.spp_KodKategorie}</span>
                                    </Col>
                                    <Col className="text-end">{t('ord-pack_group')}:{item_comms.p_k_ADRObalovaSkupina ? romanize_packaging_group(item_comms.p_k_ADRObalovaSkupina) : "-"} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{t('biz-invoiced')}: <Boolean variant="onlyTrue" value={item_comms.spp_Fakturovano} />
                                    </Col>
                                    <Col className="text-end">{t('ord-un_num')}: {item_comms.p_k_ADRUNCislo ? item_comms.p_k_ADRUNCislo : "-"} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{t('biz-closed')}: <Boolean variant="onlyTrue" value={item_comms.spp_Uzavreno} /></Col>
                                    <Col className="text-end"></Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        <Table size="sm" borderless className='mt-2'>
                            <thead className='bg-w-2'>
                                <tr>
                                    <th>{t('ord-batch')}</th>
                                    <th className='text-end'>{t('prod-amount_MJ')}</th>
                                    <th className='text-end'>{t('ord-expiry')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.items.map((itm, idx) =>
                                    <tr key={idx}>
                                        <td>{itm.sps_KodSerie}</td>
                                        <td className='text-end'>{itm.sps_MnozstviSkl}&nbsp;{itm.p_KodMjSkl}</td>
                                        <td className='text-end'>{itm.sps_DatExpirace ? date_formatCZ(itm.sps_DatExpirace) : "-"}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Accordion.Body>
            </Accordion.Item>
        </ErrorWrap>
    )
}
/**
 * Card of basic information to ND - purchase delivery note
 * 
 * @param {dictionary} header - data to display
 * @returns {component}
 */
function BasicInfo({ header }) {
    const { t } = useTranslation();
    return (
        <Card body className="p-0 mb-4">
            <h5>{t('deno-basic_info')}</h5>
            <Table borderless size="sm" className="p-0 m-0">
                <tbody>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('deno-stockin_date')}&nbsp;</td>
                        <td className="p-0">{header.ndd_DatDokladu ? date_formatCZ(header.ndd_DatDokladu) : "-"}</td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('deno-issuer')} &nbsp;</td>
                        <td className="p-0">{header.ndd_KodPracovnikaVystavil}</td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('biz-ord_code')} &nbsp;</td>
                        <td className="p-0"> <LinkContainer to={{ pathname: "/orders/purchase/" + header.ndd_KodDokladuObjednavky }}>
                            <a href="!#">
                                {header.ndd_KodDokladuObjednavky}</a>
                        </LinkContainer></td>
                    </tr>

                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('biz-centre')} &nbsp;</td>
                        <td className="p-0">{header.ndd_KodStrediska}</td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('deno-contract')} &nbsp;</td>
                        <td className="p-0">{header.spd_KodZakazky}</td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('deno-category')} &nbsp;</td>
                        <td className="p-0">{header.ndd_KodKategorie}</td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('biz-invoiced')} &nbsp;</td>
                        <td className="p-0">{header.ndd_FakturovanoProc} %</td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('ord-imported')} &nbsp;</td>
                        <td className="p-0">
                            <Boolean variant="onlyTrue" value={header.ndd_k_Importovano} />
                        </td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('biz-closed')} &nbsp;</td>
                        <td className="p-0">
                            <Boolean variant="onlyTrue" value={header.ndd_Uzavreno} /></td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('deno-approved')} &nbsp;</td>
                        <td className="p-0">
                            <Boolean variant="onlyTrue" value={header.ndd_Schvaleno} /></td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('canceled')} &nbsp;</td>
                        <td className="p-0">
                            <Boolean variant="negative" value={header.ndd_Zruseno} />
                        </td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('note')} &nbsp;</td>
                        <td className="p-0">{header.ndd_Poznamka}</td>
                    </tr>

                </tbody>
            </Table>
        </Card>
    )
}
/**
 * Card with information about partner
 * 
 * @param {dictionary} partner - data to display
 * @returns {component}
 */
function PartnerInfo({ partner }) {
    const { t } = useTranslation();
    return (
        <Card body className="p-0 mb-4">
            <h5>{t('partner')}</h5>
            <Table borderless size="sm" className="p-0 m-0">
                <tbody>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('biz-partner_name')} &nbsp;</td>
                        <td className="p-0">{partner.NazevPartnera}</td>
                    </tr>
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('biz-partner_code')} &nbsp;</td>
                        <td className="p-0">
                            <LinkContainer to={{ pathname: "/partners/list/" + partner.KodPartnera + "/detail" }}>
                                <a href="!#">{partner.KodPartnera}
                                </a>
                            </LinkContainer>
                        </td>
                    </tr>
                    <tr className="p-0 bg-light">
                        <td colSpan="2" className="text-center p-0">{t('biz-adrs')} 🗺️ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <MandatoryAddressPart
                        nazev={partner.NazevAdresy} adresa1={partner.Adresa} adresa2={partner.Adresa2}
                        misto={partner.Misto} psc={partner.PSC} kodStatu={partner.KodStatu}
                        bgLight kodAdresy={null}
                        ErrorBadge={() => true} />
                    <tr className="p-0">
                        <td className="text-end text-muted p-0 ">{t('biz-adrs_type')} &nbsp;</td>
                        <td className="p-0">{partner.typadresy}</td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    );
}