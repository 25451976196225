/**
 * Partner debt analysis
 * @module partners/partner-debts
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { PartnerDebtsApp } from './partner-debts-app';
import { PartnerDebtsWeb } from './partner-debts-web';
import { his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';

//seen all - OK

export function PartnerDebts({ partner, currency, debts, loadedDebtsStatus, reloadButton }) {

	const [saldoType, setSaldoType] = useState(1);
	const debts_filtered = debts.filter((debt) => saldoType === 0 || debt[11] > 0);

	if (!his_fetch_success(loadedDebtsStatus)) {
		return <HisFetchStatus status={loadedDebtsStatus} loadingType="big" errorType="fetcherError" waitingType="explained" reloadButton={reloadButton}/>;
	}

	return (
		<ErrorWrap>
			<PartnerDebtsWeb partner={partner} debts={debts_filtered} currency={currency} saldoType={saldoType} setSaldoType={setSaldoType} />
			<PartnerDebtsApp debts={debts_filtered} acc_currency={currency} partner={partner}  />
		</ErrorWrap>
	);
}