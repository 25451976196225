import { useTranslation } from "react-i18next";
import { Card, Col, Form, Row } from "react-bootstrap";
import { warehouses_for_select } from "../../lists/warehouses-defs";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { StockinsTrackingWebFiltersProps, StockNotificationsTrackingFilterValues } from "./StockNotifyTracking";
import { useUserLogin } from "../../UserLoginProvider";
import { MultipleSelectRhf } from "../../comp/MultipleSelectRhf";
import BooleanDropdownRhf from "../../comp/BooleanDropdownRhf";
import { SortIconsRhf } from "../../comp/SortIconsRhf";

export function StockinTrackingAppFilters({ statusesForSelect }: StockinsTrackingWebFiltersProps) {
  const { t } = useTranslation();
  const [myNotesChecked, setMyNotesChecked] = useState(false);
  const { register, setValue } = useFormContext<StockNotificationsTrackingFilterValues>();
  const { userName } = useUserLogin();

  return (
    <div className="mb-2">
      <Card className="m-0 p-2">
        <Card.Body className="m-0 p-0">
          <Row className="mb-2">
            <Col>
              <p className="mb-1">{t("ord-pur")}</p>
              <Form.Group controlId="filterCode" className="mb-1">
                <Form.Control type="text" placeholder="&#128269;" {...register("orderCode")} />
              </Form.Group>
            </Col>
            <Col>
              <p className="mb-1">{t("ord-created_by")}</p>
              <Form.Group controlId="filterCreator" className="mb-1">
                <Form.Control type="text" placeholder="&#128269;" {...register("creator")} />
              </Form.Group>
              <Form.Check
                type="checkbox"
                id="filter_my_stockins"
                onChange={(e) => {
                  if (e.target.checked) {
                    setValue("creator", userName);
                    setMyNotesChecked(true);
                  } else {
                    setValue("creator", "");
                    setMyNotesChecked(false);
                  }
                }}
                checked={myNotesChecked}
                label={t("my_stockins")}
              />
            </Col>
          </Row>

          <Row className="mb-3 mt-3">
            <Col>
              <p className="mb-1">{t("warehouse")}</p>
              <MultipleSelectRhf values={warehouses_for_select} id="warehouse" />
            </Col>
            <Col>
              <p className="mb-1">{t("state")}</p>
              <MultipleSelectRhf values={statusesForSelect} id="status" />
            </Col>
            <Col>
              <p className="mb-1">{t("problematic")}</p>
              <BooleanDropdownRhf variant="order-tracking" name="problematic" />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="filterDaysToEta" className="mb-0 mt-2">
                <Form.Label className="mb-1 bolder">{t("ord-days_to_or_after_eta")}</Form.Label>
                <Form.Control className="mb-1" type="text" placeholder="&#128269; > < =" {...register("etaDays")} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6" className="pb-2">
              <span className="bolder">{t("ord-last_change_time")} &nbsp;</span>
              <SortIconsRhf columnName="recorded" filterFieldName="orderBy" numeric />
            </Col>
            <Col>
              <span className="bolder">{t("ord-eta")} &nbsp;</span>
              <SortIconsRhf columnName="eta" filterFieldName="orderBy" numeric />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
