/**
 * Synchronization information for HIS homepage.
 * @module his-info
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus } from './comp/FetchLoader';
import { Table, Row, Col, Alert } from 'react-bootstrap';
import { make_checker } from './comp/checker';
import { his_frontend_version } from './lib/version';
import { date_getLatestWorkday, date_time_format } from './lib/date-utils';
import { ErrorWrap } from './comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

/** 
 * Component fetching syctimmes and checking their time validity. 
 *  
 * @param {dictonary} userlogin - info about logged user
 * @param {component} TableComponent - default component is HISInfoTable
 * @return {component} - COMPONENT Card with synctimes and possible error badges and alert
 */
export function HISInfo({ userlogin, TableComponent = HISInfoTable }) {
	const { t } = useTranslation();

	const [synctimes, setSynctimes] = useState(null);
	const [loadedStatus, setLoadedStatus] = useState(0);

	const { performCheck, ErrorBadge, TodoList, checksOK } = make_checker();

	useEffect(() => {
		const running_fetch = his_fetch(
			userlogin,
			[
				{
					uri: "/api/synctimes",
					json: true,
					status: setLoadedStatus,
					ok: function (resource, result) {
						//console.log(result);
						setSynctimes(result.synctimes);
						//console.log(result.synctimes);
					},
					error: function (resource, reason) {
						console.log('err: ' + reason);
					}
				}
			]
		)
		return () => {
			running_fetch();
		}
	}, [userlogin]);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} />
	}

	const lastWorkday = date_getLatestWorkday();

	function getSynctime(service) {
		return synctimes.filter(s => s[0] === service);
	}

	//console.log(timeExists(synctimes, "twist"));

	const twist_sync = getSynctime("twist").length === 1 ? new Date(getSynctime("twist")[0][1]) : "";
	const credendo_sync = getSynctime("credendo").length === 1 ? new Date(getSynctime("credendo")[0][1]) : "";
	const insurance_sync = getSynctime("insurance").length === 1 ? new Date(getSynctime("insurance")[0][1]) : "";

	//console.log(getSynctime("twist")[0][1]);

	if (credendo_sync !== "") {
		performCheck("credendoSync", "danger",
			function () {
				const credendo_diff_over_1day = ((lastWorkday - credendo_sync) / 1000 / 60 / 60 / 24) > 1;
				const credendo_old = credendo_diff_over_1day;
				if (credendo_old) {
					return t('hp-warn_cr');
				}
				return false;
			});
	}
	if (insurance_sync !== "") {
		performCheck("pojisteniSync", "danger",
			function () {
				const insurance_diff_over_1day = ((lastWorkday - insurance_sync) / 1000 / 60 / 60 / 24) > 1;
				const insurance_old = insurance_diff_over_1day;
				if (insurance_old) {
					return t('hp-warn_ins');
				}
				return false;
			});
	}
	if (twist_sync !== "") {
		performCheck("twistSync", "danger",
			function () {
				const twist_diff_over_2h = (((new Date() - twist_sync) / 1000 / 60 / 60) > 2);
				if (twist_diff_over_2h) {
					return t('hp-warn_tw');
				}
				return false;
			});
	}

	return (
		<ErrorWrap>
			<TableComponent version={his_frontend_version()} ErrorBadge={ErrorBadge}
				checksOK={checksOK} TodoList={TodoList} twist_sync={twist_sync} credendo_sync={credendo_sync}
				insurance_sync={insurance_sync} />
		</ErrorWrap>
	);
}

/**
 * Information about version and databates syctimes used on HIS homepage
 * 
 * @param {string} version - version of HIS
 * @param {object} ErrorBadge - component to show error number
 * @param {function} checksOK - returns whether performed checks are ok 
 * @param {object} TodoList - obejct with component showing errors
 * @param {object} twist_sync - time of last synchronization of twist
 * @param {object} credendo_sync - time of last synchronization of Credendo
 * @param {object} insurance_sync - time of last synchronization of insurance
 * @returns {component}
 */
function HISInfoTable({ version, ErrorBadge, checksOK, TodoList, twist_sync, credendo_sync, insurance_sync }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Table borderless>
				<tbody>
					<tr>
						<td className="text-end text-muted p-1">{t('hp-version')} &nbsp;</td>
						<td className="p-1">{version}</td>
					</tr>
					{credendo_sync ?
						<tr>
							<td className="text-end text-muted p-1">{t('hp-sync_cr')} &nbsp;</td>
							<td className="p-1">{date_time_format(credendo_sync)}<ErrorBadge tag={"credendoSync"} /></td>
						</tr>
						: <></>}
					{insurance_sync ?
						<tr>
							<td className="text-end text-muted p-1">{t('hp-sync_ins')} &nbsp;</td>
							<td className="p-1">{date_time_format(insurance_sync)}<ErrorBadge tag={"pojisteniSync"} /></td>
						</tr>
						: <></>}
					{twist_sync ?
						<tr>
							<td className="text-end text-muted p-1">{t('hp-sync_tw')} &nbsp;</td>
							<td className="p-1">{date_time_format(twist_sync)}<ErrorBadge tag={"twistSync"} /></td>
						</tr>
						: <></>}
				</tbody>
			</Table>
			<HISInfoAlert checksOK={checksOK} TodoList={TodoList} />
		</ErrorWrap>
	)
}

/**
 * Alert component, which shows if checks arent allright
 * 
 * @param {function} checksOK - function checking times 
 * @param {function} TodoList - component that builds todolist
 * @returns {component}
 */
function HISInfoAlert({ checksOK, TodoList }) {
	const { t } = useTranslation();

	if (checksOK()) {
		return <></>;
	}
	return <ErrorWrap><Alert variant="danger"><h5>{t('hp-warn-data')}</h5><TodoList /></Alert></ErrorWrap>;
}


/**
 * Shows last synctimes of databases ad row, used in insurance section
 * 
 * @param {function} checksOK - returns whether performed checks are ok 
 * @param {object} TodoList - obejct with component showing errors
 * @param {object} twist_sync - time of last synchronization of twist
 * @param {object} credendo_sync - time of last synchronization of Credendo
 * @param {object} insurance_sync - time of last synchronization of insurance
 * @returns {component}
 */
export function LastSynctimes({ checksOK, TodoList, credendo_sync, twist_sync, insurance_sync }) {
	const { t } = useTranslation();
	
	return (
		<ErrorWrap>
			<Row className="text-muted">
				{credendo_sync ?
					<Col>{t('hp-sync_cr')}: {date_time_format(credendo_sync)}</Col>
					: <></>}
				{insurance_sync ?
					<Col>
						<div className="text-center mb-2"> {t('hp-sync_ins')}: {date_time_format(insurance_sync)} </div>
						<HISInfoAlert checksOK={checksOK} TodoList={TodoList} />
					</Col>
					: <></>}
				{twist_sync ?
					<Col className="text-end">{t('hp-sync_tw')}: {date_time_format(twist_sync)}</Col>
					: <></>}
			</Row>
		</ErrorWrap>
	);
}
