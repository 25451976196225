import React from "react";
import { Table, Badge, Button } from "react-bootstrap";
import { date_time_format, date_parseCzech } from "../../lib/date-utils";
import { warehouses_names_ids_separe } from "../../lists/warehouses-defs";
import { LinkContainer } from "react-router-bootstrap";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { NotificationLineApp } from "../stockin-display-mobile";
import { StockinProgressPath } from "../stockin-progress-path";
import { stock_progress_specification } from "../progress-stockins-def";
import { useTranslation } from "react-i18next";
import { icompare } from "../../lib/utils";
import { useUserLogin } from "../../UserLoginProvider";

//seen all - OK

/**
 * Returns dictionary with selected keys
 *
 * @param {dictionary} dictionary - dictionary
 * @param {string} keys - string with regular expresion of selected keys
 * @return {dictionary} -  dictionary with selected keys
 */
function extract_keys_from_dictionary_to_dictionary(dictionary, keys, extra_keys = null) {
  return Object.keys(dictionary) // vytáhnu keys
    .filter((k) => k.search(keys) >= 0) // vyberu zajímavé klíče, vznikne pole
    .reduce((acc, k) => ({ ...acc, [k]: dictionary[k], extra_keys }), {}); //plním zpátky slovník klíči z toho výběrového pole, začnu prázdným, klíč: původníSlovník[hodnota]
}

/**
 * Returns dictionary, where key is stock notification id and value is array of logs for particular stock notification
 *
 * @param {array} all_stock_notifications_logs - array of dictionaries, where one dictionary is one log for stock notification
 * @return {dictionary} - key is sn_id, value is array of logs for particular stock notification
 */
export function split_stock_notifications_logs(all_stock_notifications_logs) {
  //přejmenovat a přidat parametr klíče
  //console.log(all_stock_notifications_logs);
  const sn_ids = all_stock_notifications_logs
    .map((rec) => rec.sns_sn_id) // z logů vytáhnu všechna sns_sn_id
    .filter((v, i, a) => a.indexOf(v) === i); // udělám pole unikátních
  //console.log("sn_ids: ");
  // console.log(sn_ids);
  const logs_dict = sn_ids.reduce(
    (acc, sn_id) => ({ ...acc, [sn_id]: all_stock_notifications_logs.filter((rec) => rec.sns_sn_id === sn_id) }),
    {}
  ); // mám pole sn_ids, ke každému dávám logy z all_stock_notifications_logs - filtruji ty, co se rovnají sn_id z sns_ids
  //console.log("dict:");
  //console.log(logs_dict);
  return logs_dict;
}

/**
 * Returns comparison numbers
 *
 * @param {dictionary} a - dictionary representing one stock notification
 * @param {dictionary} b - dictionary representing one stock notification
 * @return {number} - -1,0,1 to convey comparison of a or b based on date "created_at"
 */
export function stockin_activity_compare(a, b, sort_key = "sn_created_at", useHistory = true, direction = 1) {
  const a_created = a[sort_key];
  const b_created = b[sort_key];
  const a_date = a.history.length === 0 || !useHistory ? a_created : [...a.history].reverse()[0].recorded; //když js řadil, tak náhodně prohodil pořadí historie tam a zpět
  const b_date = b.history.length === 0 || !useHistory ? b_created : [...b.history].reverse()[0].recorded;
  //console.log('sorting: a_date='+a_date+' b_date='+b_date);
  if (a_date < b_date) {
    return 1 * direction;
  }
  if (b_date < a_date) {
    return -1 * direction;
  }
  return 0;
}

/**
 * Returns comparison function with first record of param a and b
 *
 * @param {dictionaries} a - bunch of log dictionaries for one stock notification
 * @param {dictionaries} b - bunch of log dictionaries for one stock notification
 * @return {function} - compare function for sort
 */
function kocicka(a, b, sort_key = "sn_created_at", useHistory = true, direction = 1) {
  //console.log(a);
  const a_not = a.notifications[0];
  const b_not = b.notifications[0];
  return stockin_activity_compare(a_not, b_not, sort_key, useHistory, direction);
}

/**
 * Returns date of last change in case, there is some history
 *
 * @param {any} a
 * @returns {string}
 */
export function date_of_last_change(a) {
  const a_created = a["sn_created_at"];
  const a_date = a.history.length === 0 ? a_created : [...a.history].reverse()[0].recorded;
  return a_date;
}

/**
 * Returns dictionaries of separate batches with batch related values - amount, batch, bbd and snb_id, snb = stock notification batch
 *
 * @param {number} sni_id - id of a stock notification
 * @param {array} stockins - batch of logs for all stock notifications
 * @return {dictionary} - dictionaries of separate batches
 */
function extract_batch_info(sni_id, stockins) {
  return stockins
    .filter((v) => v.sni_id === sni_id) //filtruji záznamy, které mají stejné sni_id, jako to z parametru
    .map((sni) => extract_keys_from_dictionary_to_dictionary(sni, "snb_")); // mapem překonvetrovávám slovník

  /*
  Object.keys(sni) // mapem překonvetrovávám slovník, běru všechny klíče ze slovníku, vyfiltruji zajímavé a pomocí reduce vrátím jenom zajímavé klíče stylem klíč: hodnota
      .filter((k) => k.search("snb_") >= 0) //dávám tam klíče, co začínají na "snb_"
      .reduce((acc, k) => ({ ...acc, [k]: sni[k] }), {})); //map zpracovává pole a každá položka je slovník, kterou překonvertovávám 
      */
}

/**
 * Returns dictionary, where stock notification is in batch
 *
 *@param {number} sni_id - id of a stock notification
 * @param {dictionaries} stockins - batch logs for all stock notifications
 * @return {dictionary} -  stock notification with all its batches
 */
function extract_items(sn_id, stockins) {
  return stockins
    .filter((v) => v.sn_id === sn_id) // filtruji záznamy, které mají stejné sn_id
    .map((v) => v.sni_id) // map zpracovává pole
    .filter((v, i, a) => a.indexOf(v) === i) // filtruji unikátní sni_id pro položku, sni_id se vyskytuje nkrát, pokud má položka více batches
    .map((sni_id) => stockins.find((sni) => sni.sni_id === sni_id)) // sériově za sebou jsou mapy, mohla bych každý řádek strčit na const, tady vytahuji položky, které odpovídají unitkátním sni_id, náhodný výběr
    .map((sni) =>
      //extract_keys_from_dictionary_to_dictionary(sni, "nop_|^p_|pj_|pjo_|snb_|sni_", batches: extract_batch_info(sni.sni_id, stockins)));

      Object.keys(sni) // jednotlivé záznamy položek přemapovávám na slovník, kde jsou jen klíče, týkající se položky
        .filter((k) => k.search("nop_|^p_|pj_|pjo_|snb_|sni_") >= 0) //filtruji zajímavé slovníkové klíče
        .reduce((acc, k) => ({ ...acc, [k]: sni[k], batches: extract_batch_info(sni.sni_id, stockins) }), {})
    ); //reducem stavímm slovník, který má klíč, hodnota
}

interface StockNotificationProcessedItemBatch {
  snb_id: number;
  extra_keys: any;
  snb_amount: number;
  snb_batch: string;
  snb_bbd: string;
}

interface StockNotificationProcessedItem {
  batches: StockNotificationProcessedItemBatch[];
  nop_KodDokladu: string;
  nop_KodMj: string;
  nop_KodMjSkl: string;
  p_KodMjSkl: string;
  p_KodProduktu: string;
  p_NazevProduktu: string;
  pj_HmotnostMj: number;
  pj_MjHmotnostiMj: string;
  pjo_KodObalu: string;
  pjo_MnozstviSklMjVObalu: number;
  sni_id: number;
  sni_k_IDProduktu: number;
  sni_nop_id: number;
}

interface StockNotificationProcessedLogItem {
  frf_data_size: any;
  frf_file_name: any;
  frf_id: any;
  fwr_data_size: number;
  fwr_file_name: string;
  fwr_id: number;
  mp_Jmeno: string;
  mp_KodPracovnika: string;
  nod_KodDokladu: string;
  recorded: string;
  sns_changed_by: number;
  sns_ftp_request_id: number;
  sns_invalidated: any;
  sns_received_file_id: any;
  sns_sn_id: number;
  sns_twist_nd: any;
  sns_twist_response: any;
  status: number;
  u_username: string;
}

interface StockNotificationProcessedNotification {
  sn_id: number;
  extra_keys: any;
  mp_Jmeno: string;
  nop_KodDokladu: string;
  sn_created_at: string;
  sn_created_by: number;
  sn_eta: string;
  sn_number: number;
  sn_warehouse_id: number;
  sns_recorded: string;
  sns_status: number;
  u_username: string;
  items: StockNotificationProcessedItem[];
  history: StockNotificationProcessedLogItem[];
  sn_eta_parsed: Date;
  days_to_eta: number;
  problematic_solve_soon: boolean;
}

export interface StockNotificationProcessed {
  KodDokladu: string;
  notifications: StockNotificationProcessedNotification[];
}

/**
 * Returns array of dictionaries with orders purchase, that contain stocks notifications with items with batches and with historyLogs in a tree
 */
export function stockin_data_transformation(
  stockins: Dto.GetPurchaseOrderStockNotificationOld[],
  historyLogs: Dto.StockNotificationsLog[],
  sort_key = "sn_created_at",
  useHistory = true,
  direction = 1
): StockNotificationProcessed[] {
  // funkce, kam nasypu denormalizovaný data (dělám strom), chci jeden strom (historie + NO) //histocký logy jako druhý argument
  //console.log(stockins);
  //console.log(historyLogs);
  const historyLogs_by_sn_id = split_stock_notifications_logs(historyLogs);
  //console.log(historyLogs_by_sn_id);
  //console.log(stockins);
  const tracked_reformed = stockins
    .map((n) => n.sn_id) // vytáhnu si všechny sn_id do pole
    .filter((v, i, a) => a.indexOf(v) === i) // pole unikátních
    .map((sn_id) => stockins.find((sn) => sn.sn_id === sn_id)) // z stockins si vytáhnu vždy libovolnou položku s sn_id
    .map((sn) => ({
      ...extract_keys_from_dictionary_to_dictionary(sn, "sn_|mp_|u_|nop_KodDokladu|sns_"), // vyspreaduju si klíče, viz parametr
      items: extract_items(sn.sn_id, stockins),
      history: historyLogs_by_sn_id[sn.sn_id] || [],
      //tady v rámci preprocessingu už potřebuji vědět, jesli jsou dostupné kouzelné hůlky, nebo je tam něco nestandardního, aby to šlo filtrovat
      //nicméně šlo by je filtrovat jako takové, které jsou v definovaném stavu a mají více než xx minut od poslední změny
      sn_eta_parsed: date_parseCzech(sn.sn_eta),
      days_to_eta: etaDaysNumber(sn.sn_eta),
      problematic_solve_soon: is_stockin_problematic(sn.sns_status, sn.sns_recorded),
    })); // mapuju si sn, funkce extract_sn dostala ...spread oterator, abych mohla přidat items
  //console.log(tracked_reformed);

  const tracked_NOs = tracked_reformed
    // @ts-ignore
    .map((sn) => sn.nop_KodDokladu) //kody dokladu
    .filter((v, i, a) => a.indexOf(v) === i) // unikátní
    .map((nop_KodDokladu) => ({
      //mapem stavím pole slovníků
      KodDokladu: nop_KodDokladu,
      notifications: tracked_reformed
        // @ts-ignore
        .filter((sn) => sn.nop_KodDokladu === nop_KodDokladu)
        .sort((a, b) => stockin_activity_compare(a, b, sort_key, useHistory, direction)),
    }))
    .sort((a, b) => kocicka(a, b, sort_key, useHistory, direction)); //lambda je nepojmenovaná funkce, aby kočička měla přístup k hodnotám a mohla být venku, lexikální kontext je místo, kde platí příslušné názvy, anonymní funkce, co si volá kočičku - jejím cílem je dostat externí údaje donitř sortu
  //console.log(tracked_NOs);
  // @ts-ignore
  return tracked_NOs;
}

/**
 * Return boolean, wheter stockin need some attention
 *
 * @param {number} status - current stockin status
 * @param {date} last_update - date and time of last update
 * @returns {boolean}
 */
export function is_stockin_problematic(status, last_update) {
  const valid_params = status && last_update;
  const specification_case = valid_params ? stock_progress_specification.filter((rec) => rec.number === status) : [];
  const solution_needed_seconds = specification_case.length > 0 ? specification_case[0].needs_solution : null;
  if (solution_needed_seconds === null) {
    return false;
  } else {
    const its_time = new Date(last_update).getTime() + solution_needed_seconds * 1000 < new Date().getTime();
    return its_time;
  }
}

/**
 * Returns dictionary of unique sn_ids with value false or true according to paramater
 *
 *@param {dictionaries} notifications - all stock notification entries, one entry is one batch
 * @param {boolean}  value - all historyLogs entries, one entry is one historical event
 * @return {dictionary} - dictionaries of order purchase
 */
export function get_id_boolean(notifications, value) {
  return notifications
    .map((sn) => sn.id) //pole všech sn_id [1, 1, 2, 3, 4, 4]
    .filter((v, i, a) => a.indexOf(v) === i) // pole unikátních sn_id [1, 2, 3, 4]
    .reduce((acc, sn_id) => ({ ...acc, [sn_id]: value }), {}); // tvorba slovníku {1: false, 2: false, 3: false}
}

/**
 * Returns string saying, how much days remains to ETA, is over ETA
 *
 *@param {string} eta - date and time in date or iso format
 * @return {string} - "number of days"
 */
export function etaDaysNumber(eta) {
  const etaParsed = date_parseCzech(eta);
  const days_diff_eta = Math.floor((new Date().getTime() - new Date(etaParsed).getTime()) / 1000 / 60 / 60 / 24);
  //console.log("diff:" + days_diff_eta) ;
  return days_diff_eta;
}

/**
 * Returns string expressing whether given days are before or after eta
 *
 *@param {string} etaDaysNumber - number of days remaining to eta
 * @return {string} - "human time information"
 */
export function etaDaysWithString(etaDaysNumber, t) {
  const etaToday = etaDaysNumber === 0;
  const days = Math.abs(etaDaysNumber);
  //console.log("days:" + days);
  if (etaToday) {
    return t("ord-eta") + " " + t("is_today");
  } else if (etaDaysNumber > 0) {
    return (
      <>
        {days} {t("ord-days_after_eta")}
      </>
    );
  } else {
    return (
      <>
        {days} {t("ord-days_to_eta")}
      </>
    );
  }
}

/*
 * Component rendering head of Stock Notifications table.
 *
 * @param {boolean} showDaysToEtaCol - display column of remaining days to eta
 * @return {commponent} - the rendered component of head for table
 */
export function NotificationsTableHead({ showDaysToEtaCol = false }) {
  const { t } = useTranslation();
  return (
    <thead className="beGray ">
      <tr className="d-none d-lg-table-row">
        <th className="text-center">{t("ord-sn_num")}</th>
        <th className="text-center">{t("ord-created_by")}</th>
        <th className="text-center">{t("ord-last_change_time")}</th>
        <th className="text-center">{t("ord-eta")}</th>
        {showDaysToEtaCol ? <th className="text-center">{t("ord-days_to_eta")}</th> : <></>}
        <th className="text-center">{t("warehouse")}</th>
        <th className="text-center">{t("state")}</th>
        <th className="text-center">{t("biz-items")}</th>
        <th></th>
      </tr>
    </thead>
  );
}

/**
 *
 * @param {string} kodDokladu - code of NO
 * @param {function} expandAllInNO - function for expading content of all stock notification
 * @param {boolean} is_NO_hidden - represents whether the is at least one opened content
 * @return {component} - the rendered component of line with kodDokladu and expand button
 */
export function OrderPurchaseLinkButton({ kodDokladu, expandAllInNO, is_NO_hidden }) {
  //used only for global view
  return (
    <>
      <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/orders/purchase/" + kodDokladu }}>
        <h5 className="mb-0 d-inline blueish">{kodDokladu}</h5>
      </LinkContainer>
      <Button size="sm" variant="light" className="mb-2 ms-2" onClick={() => expandAllInNO(kodDokladu)}>
        {is_NO_hidden(kodDokladu) ? <MdExpandMore /> : <MdExpandLess />}
      </Button>
    </>
  );
}

interface NotificationsOfOrderPurchaseProps {
  notifications: StockNotificationProcessed["notifications"];
  toggleDetailShown: (id: number) => void;
  detailShown: { [key: number]: boolean };
  global?: boolean;
  authorizedUser: boolean;
  reloadIt: () => void;
  expandAllInNO: any;
  is_NO_hidden: (code: string) => boolean;
  filterDaysToEta: any;
  showProblematicCol?: boolean;
  showDaysToEtaCol?: boolean;
}

export function NotificationsOfOrderPurchase({
  notifications,
  toggleDetailShown,
  detailShown,
  global = false,
  authorizedUser,
  reloadIt,
  expandAllInNO,
  is_NO_hidden,
  filterDaysToEta,
  showProblematicCol = false,
  showDaysToEtaCol = false,
}: NotificationsOfOrderPurchaseProps) {
  const userLogin = useUserLogin();

  return notifications.map(function (notify, idx) {
    return (
      <NotificationLine
        notify={notify}
        length={notifications.length}
        key={idx}
        toggleDetailShown={toggleDetailShown}
        detailShown={detailShown}
        authorizedUser={authorizedUser}
        userlogin={userLogin}
        reloadIt={reloadIt}
        global={global}
        isFirstStockin={idx === 0}
        expandAllInNO={expandAllInNO}
        is_NO_hidden={is_NO_hidden}
        filterDaysToEta={filterDaysToEta}
        showProblematicCol={showProblematicCol}
        showDaysToEtaCol={showDaysToEtaCol}
      />
    );
  });
}

/**
 * Displays mobile and web version of stockin line
 *
 * @param {any} notify - one stockin
 * @param {function} toggleDetailShown - expands one stockin
 * @param {dictionary} detailShown - state - ecpanded stockins
 * @param {boolean} global - global tracking view or false in case of order view
 * @param {any} userlogin - information about logged user
 * @param {boolean} authorizedUser - whether user is authorized to use magic buttons in progressPath
 * @param {function} reloadIt - reloads after usage of magic buttons in ProgressPath
 * @param {number} length - count of stockins in order
 * @param {boolean} isFirstStockin - id the stocking is first within order
 * @param {function} expandAllInNO - expands all stocking within order
 * @param {boolean} is_NO_hidden - if all details at NO are collapsed
 * @param {boolean} showProblematicCol - display column with sign, if stockin is problematic
 * @param {boolean} showDaysToEtaCol - display column of remaining days to eta
 * @returns {component}
 */
export function NotificationLine({
  notify,
  toggleDetailShown,
  detailShown,
  authorizedUser,
  userlogin,
  reloadIt,
  global,
  length,
  isFirstStockin,
  expandAllInNO,
  is_NO_hidden,
  filterDaysToEta,
  showProblematicCol,
  showDaysToEtaCol,
}) {
  const confirmed_and_after_eta = notify.days_to_eta > 0 && (notify.sns_status === 5 || notify.sns_status === 14);

  return (
    <>
      <NotificationLineWeb
        notify={notify}
        toggleDetailShown={toggleDetailShown}
        detailShown={detailShown}
        global={global}
        className="d-none d-lg-table-row"
        length={length}
        isFirstStockin={isFirstStockin}
        expandAllInNO={expandAllInNO}
        is_NO_hidden={is_NO_hidden}
        userlogin={userlogin}
        authorizedUser={authorizedUser}
        reloadIt={reloadIt}
        filterDaysToEta={filterDaysToEta}
        showProblematicCol={showProblematicCol}
        showDaysToEtaCol={showDaysToEtaCol}
        confirmedAndAfterETA={confirmed_and_after_eta}
      />
      <NotificationLineApp
        notify={notify}
        toggleDetailShown={toggleDetailShown}
        detailShown={detailShown}
        global={global}
        className="d-lg-none"
        userlogin={userlogin}
        authorizedUser={authorizedUser}
        reloadIt={reloadIt}
        filterDaysToEta={filterDaysToEta} //k tomuto se vrátit
        showProblematicCol={showProblematicCol}
        showDaysToEtaCol={showDaysToEtaCol}
        confirmedAndAfterETA={confirmed_and_after_eta}
      />
    </>
  );
}

/**
 *
 * @param {any} notify - one stockin
 * @param {function} toggleDetailShown - expands one stockin
 * @param {dictionary} detailShown - state - ecpanded stockins
 * @param {boolean} global - global tracking view or false in case of order view
 * @param {string} className - display on large screen only
 * @param {number} length - count of stockins in order
 * @param {boolean} isFirstStockin - id the stocking is first within order
 * @param {function} expandAllInNO - expands all stocking within order
 * @param {boolean} is_NO_hidden - if all details at NO are collapsed
 * @param {any} userlogin - information about logged user
 * @param {boolean} authorizedUser - whether user is authorized to use magic buttons in progressPath
 * @param {function} reloadIt - reloads after usage of magic buttons in ProgressPath
 * @param {boolean} showDaysToEtaCol - display column of remaining days to eta
 * @param {boolean} showDaysToEtaCol - display column of remaining days to eta
 * @param {boolean} confirmedAndAfterETA - true, if stockin was confirmed and is after ETA
 * @returns {component}
 */
function NotificationLineWeb({
  notify,
  toggleDetailShown,
  detailShown,
  global,
  className,
  length,
  isFirstStockin,
  expandAllInNO,
  is_NO_hidden,
  userlogin,
  authorizedUser,
  reloadIt,
  filterDaysToEta = "",
  showProblematicCol,
  showDaysToEtaCol,
  confirmedAndAfterETA,
}) {
  const { t } = useTranslation();

  const my_class = "text-center beGray4 align-middle";
  const my_onClick = () => toggleDetailShown(notify.sn_id);
  const my_style = { cursor: "pointer" };
  const eta_search_highlite =
    icompare(notify.days_to_eta, filterDaysToEta) && filterDaysToEta !== "" ? "bg-white text-primary" : "";
  //console.log(notify);

  return (
    <React.Fragment>
      <tr className={className}>
        {isFirstStockin && global ? (
          <td rowSpan={length * 2} className={my_class}>
            <OrderPurchaseLinkButton
              kodDokladu={notify.nop_KodDokladu}
              expandAllInNO={expandAllInNO}
              is_NO_hidden={is_NO_hidden}
            />
          </td>
        ) : (
          <></>
        )}
        {showProblematicCol ? (
          <td style={my_style} onClick={my_onClick} className={my_class}>
            {notify.problematic_solve_soon ? t("problematic") + " 🔥" : "OK"}{" "}
          </td>
        ) : (
          <></>
        )}
        <td style={my_style} onClick={my_onClick} className={my_class}>
          {notify.sn_number}
        </td>
        <td style={my_style} onClick={my_onClick} className={my_class}>
          {notify.mp_Jmeno} ({notify.u_username})
        </td>
        <td style={my_style} onClick={my_onClick} className={my_class}>
          {date_time_format(date_of_last_change(notify))}
        </td>
        <td style={my_style} onClick={my_onClick} className={my_class}>
          {notify.sn_eta}
        </td>
        {showDaysToEtaCol ? (
          <td style={my_style} onClick={my_onClick} className={my_class}>
            {confirmedAndAfterETA ? (
              <Badge className="pt-2 px-3" bg="danger" pill>
                <h6>
                  <span className={eta_search_highlite}>{etaDaysWithString(notify.days_to_eta, t)}</span>
                </h6>
              </Badge>
            ) : (
              <span className={eta_search_highlite}>{etaDaysWithString(notify.days_to_eta, t)}</span>
            )}
          </td>
        ) : (
          <></>
        )}
        <td style={my_style} onClick={my_onClick} className={my_class}>
          {warehouses_names_ids_separe[notify.sn_warehouse_id]}
        </td>
        <td style={my_style} onClick={my_onClick} className={my_class}>
          <StockinProgressPath
            one_history={notify.history}
            compact
            userlogin={userlogin}
            authorizedUser={authorizedUser}
            reloadIt={reloadIt}
          />
        </td>
        <td style={my_style} onClick={my_onClick} className={my_class}>
          {notify.items.length}
        </td>
        <td style={my_style} onClick={my_onClick} className={my_class}>
          <Button size="sm" variant="light">
            {detailShown[notify.sn_id] ? <MdExpandLess /> : <MdExpandMore />}
          </Button>
        </td>
      </tr>
      <tr className={" p-0 m-0 " + className}>
        <td colSpan={9} className="p-0 m-0">
          <div className={"mb-0 px-4 py-3 bg-light" + (detailShown[notify.sn_id] ? "" : " d-none")}>
            <StockinProgressPath
              one_history={notify.history}
              userlogin={userlogin}
              authorizedUser={authorizedUser}
              reloadIt={reloadIt}
            />
            <NotificationItemsTable notify={notify} />
          </div>
        </td>
        <td className="p-0 m-0" colSpan={4}></td>
      </tr>
    </React.Fragment>
  );
}

/**
 * Table of items within one stockin
 *
 * @param {any} notify - stockin details
 * @returns {component}
 */
export function NotificationItemsTable({ notify }) {
  const { t } = useTranslation();
  return (
    <>
      <h5 className="mt-3">{t("ord-notified_products")}</h5>
      <Table size="sm" className="mb-4 border">
        <thead className="beGray4">
          <tr>
            <th>{t("prod-id")}</th>
            <th>{t("ord-item_id")}</th>
            <th>{t("code")}</th>
            <th>{t("name")}</th>
            <th className="text-end">{t("ord-amount_measure_unit")}</th>
            <th>{t("ord-batch")}</th>
            <th>{t("ord-expiry")}</th>
          </tr>
        </thead>
        <tbody>
          {notify.items.map(function (item, idx) {
            return <NotificationItemLine item={item} key={idx} />;
          })}
        </tbody>
      </Table>
    </>
  );
}

/**
 * Single item of stockin
 *
 * @param {any} item - single item details
 * @returns {component}
 */
function NotificationItemLine({ item }) {
  return (
    <tr>
      <td className="align-middle">{item.sni_k_IDProduktu}</td>
      <td className="align-middle">{item.sni_nop_id}</td>
      <td className="align-middle">{item.p_KodProduktu}</td>
      <td className="align-middle">{item.p_NazevProduktu}</td>
      <td className="text-end align-middle">
        {item.batches.map(function (b, idx) {
          return (
            <p className="mb-0" key={idx}>
              {b.snb_amount} {item.p_KodMjSkl}
            </p>
          );
        })}
      </td>
      <td className="align-middle">
        {item.batches.map(function (b, idx) {
          return (
            <p className="mb-0" key={idx}>
              {b.snb_batch}
            </p>
          );
        })}
      </td>
      <td className="align-middle">
        {item.batches.map(function (b, idx) {
          return (
            <p className="mb-0" key={idx}>
              {b.snb_bbd ? b.snb_bbd : "---"}
            </p>
          );
        })}
      </td>
    </tr>
  );
}
