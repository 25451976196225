import React from "react";
import { format_amount } from "../../lib/format";
import { make_product_check_tag } from "../../products/product-checks";
import { date_parseCzech, date_parseISO, date_isTomorrow, date_isToday } from "../../lib/date-utils";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Fraction from "fraction.js";

//seen all - OK

//Validation function used across performChecks
export function dnote_get_items_warehouses(deliveryNote: Dto.GetDeliveryNoteDetailResponse) {
  return deliveryNote === null
    ? []
    : deliveryNote.items.map((item) => item.stockOut.bookCode).filter((v, i, a) => a.indexOf(v) === i);
}

export function dnote_field_ok(fld) {
  if (fld === null) {
    return false;
  }
  const sfld = String(fld);
  if (sfld.trim().length === 0) {
    return false;
  }
  return true;
}

export function number_is_integer(num) {
  return Math.floor(num) === num;
}

export function get_integer_fraction(nom, den) {
  var nom_f = new Fraction(nom);
  var den_f = new Fraction(den);
  var res = nom_f.div(den_f);
  if (res.d === 1) {
    return res.n;
  }
  return nom / den;
}

export function fraction_is_integer(nom, den) {
  var nom_f = new Fraction("" + nom);
  var den_f = new Fraction("" + den);
  var res = nom_f.div(den_f);
  return res.d === 1;
}

export function null_or_qmark(x: any | null) {
  return x === null || x === "?";
}

export function twist_TypAdresy_str(typ) {
  switch (typ) {
    case 0:
      return "basic";
    case 1:
      return "invoicing";
    case 2:
      return "delivery";
    case 3:
      return "seat";
    case 4:
      return "unspecified";
    default:
      return "unknown3";
  }
}

export function format_possible_real(num) {
  if (number_is_integer(num)) {
    return String(num);
  }
  return format_amount(num);
}

interface HeaderOrItemFieldProps {
  header: string | null;
  item: string | null;
}

export function HeaderOrItemField({ header, item }: HeaderOrItemFieldProps) {
  if (item !== null) {
    return <span>{item}</span>;
  } else if (header !== null) {
    return <span className="text-muted">{header}</span>;
  }
  return <>&mdash;</>;
}

export function compare_prp_sort(a: Dto.OrderText, b: Dto.OrderText) {
  const anum = parseInt(a.textTypeCode.replace(/[^0-9]/gi, ""));
  const bnum = parseInt(b.textTypeCode.replace(/[^0-9]/gi, ""));
  return anum - bnum;
}

export function dnote_validate_items(dnote: Dto.GetDeliveryNoteDetailResponse) {
  const noItems = dnote.items.length === 0;
  if (noItems) {
    return "no_items_deno";
  }
  return false;
}

export function dnote_validate_HeaderOrItemField(
  dnote: Dto.GetDeliveryNoteDetailResponse,
  getHeaderField: (h: Dto.GetDeliveryNoteDetailHeader) => any,
  getItemField: (i: Dto.GetDeliveryNoteDetailItem) => any,
  err
) {
  const header = dnote.header;
  const HValue = getHeaderField(header);
  if (!null_or_qmark(HValue)) {
    return false;
  }
  const some_missing = dnote.items.filter((item) => null_or_qmark(getItemField(item))).length > 0;
  if (some_missing) {
    return err;
  }
  return false;
}

export function dnote_validate_KodAdresyDodani(dnote) {
  /* KodAdresy se nesmí shodovat s žádným KodDopravce (připravit seznam)
   */
  const header = dnote.header;
  const KodAdresy = header.pdd_KodAdresyDodani;
  if (!dnote_field_ok(KodAdresy)) {
    return "no_delivery_address";
  }
  const KodDopravce = header.pdd_KodDopravce;
  // console.log('KodDopravce = ' + KodDopravce + ' :: KodAdresy = ' + KodAdresy);
  if (KodDopravce === "SAMOODBĚR") {
    if (String(KodAdresy).match(/^[0-9][0-9]$/)) {
      return false;
    }
    return "wrong_delivery_address";
  }
  if (String(KodAdresy).match(/^[0-9][0-9]$/)) {
    return "wrong_delivery_address2";
  }
  return false;
}

export function dnote_validate_KodDopravce(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Kdo to veze - pokud SAMOODBĚR/HSHLOGISTIKA, jinak 16 či 22 -
   * musí sedět se skladem položek !! GW - 2021-03-14: DONE
   */
  const header = dnote.header;
  const KodDopravce = header.carrierCode;
  if (KodDopravce === "SAMOODBĚR" || KodDopravce === "HSHLOGISTIKA") {
    return false;
  }
  const warehouses = dnote_get_items_warehouses(dnote);
  if (warehouses.length !== 1) {
    return "cannot_check_multiple_wh_items";
  }
  // console.log('warehouses = ' + JSON.stringify(warehouses));
  const warehouse = warehouses[0];
  // console.log('warehouse = "' + warehouse + '" :: KodDopravce ="' + KodDopravce + '"');
  if (KodDopravce === warehouse) {
    return false;
  }
  return "carrier_doesnt_match_items_warehouse";
}

export function dnote_validate_DodaciPodminka(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Dodací podmínka !! HSH - 2021-03-14: DONE
   * Pokud bude HSHLOGISTIKA nebo 16 či 22, tak DAP
   * Pokud SAMOODBĚR, tak FCA
   */
  const header = dnote.header;
  const KodDopravce = header.carrierCode;
  const DodaciPodminka = header.deliveryConditionCode;
  if (KodDopravce === "SAMOODBĚR") {
    return DodaciPodminka !== "FCA" ? "missing_FCA_condition_for_self_pickup" : false;
  }
  return DodaciPodminka !== "DAP" ? "missing_DAP_condition_for_carrier" : false;
}

export function dnote_validate_DodaciAdresa(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Dodací adresa !! GW/HSH
   * pokud SAMOODBĚR, tak se musí shodovat se skladem
   * pokud ostatní, tak se nesmí shodovat se skladem
   */
  const header = dnote.header;
  const KodDopravce = header.carrierCode;
  const KodAdresy = header.deliveryAddressCode;
  const warehouses = dnote_get_items_warehouses(dnote);
  if (warehouses.length !== 1) {
    return "cannot_check_multiple_wh_items";
  }
  const warehouse = warehouses[0];
  //console.log('KodDopravce = ' + KodDopravce + ' :: warehouse = ' + warehouse + ' :: KodAdresy = ' + KodAdresy);
  if (KodDopravce === "SAMOODBĚR") {
    if (KodAdresy === warehouse) {
      return false;
    }
    return "wh_and_delivery_address_must_match_for_self_pickup";
  }
  if (KodAdresy === warehouse) {
    return "delivery_address_is_same_as_sent_from_one";
  }
  return false;
}

export function dnote_validate_DatumDodani_soft(dnote: Dto.GetDeliveryNoteDetailResponse) {
  const header = dnote.header;
  if (!header) {
    return "unknown";
  }
  const DatumDodani = header.deliveryDate;
  const KodDopravce = header.carrierCode;
  const today = new Date();
  const prp9 = dnote.textPrp9?.text ? dnote.textPrp9.text : null;

  if (KodDopravce === "SAMOODBĚR" || KodDopravce === "HSHLOGISTIKA") {
    const prp9_is_today = date_isToday(date_parseCzech(prp9));
    if (prp9_is_today) {
      return "warning_removal_from_storage_date_is_today";
    }

    const prp9_is_tomorrow = date_isTomorrow(prp9);
    if (prp9_is_tomorrow) {
      if (today.getHours() >= 14) {
        return "warning_removal_from_storage_is_tomorrow_and_its_after_14h_today";
      }
    }
    return false;
  } else {
    const datum_dodani_is_tomorrow = date_isTomorrow(DatumDodani);

    if (datum_dodani_is_tomorrow) {
      if (today.getHours() >= 14) {
        return "warning_delivery_is_tomorrow_and_its_after_14h_today";
      }
    }
  }
  return false;
}

export function dnote_validate_DatumDodani(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Datum dodání musí být nejdříve dnes !! HSH/GW Ujasnit s GW jak
   * budou nakládat s aktuálním dnem: V ten samý den by se to posílat
   * nemělo, ani u samoodběrů. Vše by se mělo hlásit alespoň 24
   * předem, pro rozvozy na následující den, myslím do 13:00 pokud
   * je datum bráno z PRP9, musí být v definovaném formátu
   */
  // TODO: weekends!
  const header = dnote.header;
  if (!header) {
    return "unknown";
  }
  const DatumDodani = header.deliveryDate;
  //console.log('DatumDodani: ' + DatumDodani);
  const KodDopravce = header.carrierCode;
  const today = new Date();

  if (KodDopravce === "SAMOODBĚR" || KodDopravce === "HSHLOGISTIKA") {
    if (!dnote.textPrp9 || !dnote.textPrp9.text) {
      return "missing_PRP9_date_when_goods_must_be_ready_on_platform";
    }
    const prp9 = dnote.textPrp9.text; //separární funkce, co bude kontrolovat, jestli je to dd.mm.rr - takže jestli po splitu to má délku 3, zavolat na to taky date_parseCzech
    var ymda = prp9.split(".");
    if (ymda.length !== 3) {
      return "PRP9_date_isnt_in_valid_format_DD.MM.YYYY";
    }
    const d = ymda[0];
    const m = ymda[1];
    const y = ymda[2];
    if (d.length !== 2 || m.length !== 2 || y.length !== 4) {
      return "PRP9_date_isnt_in_valid_format_DD.MM.YYYY";
    }
    const di = parseInt(d);
    const mi = parseInt(m);
    const yi = parseInt(y);
    if (mi < 1 || mi > 12 || di < 1 || di > 31) {
      return "PRP9_date_isnt_in_valid_format_DD.MM.YYYY";
    }

    if (
      yi < today.getFullYear() ||
      (yi === today.getFullYear() && mi < today.getMonth() + 1) ||
      (yi === today.getFullYear() && mi === today.getMonth() + 1 && di < today.getDate())
    ) {
      return "PRP9_date_cant_be_in_past";
    }
    return false;
  } else {
    // Must be a future day
    // console.log('DatumDodani: ' + DatumDodani);
    const parsedDate = date_parseISO(DatumDodani);
    // console.log(parsedDate);
    // console.log(today);

    if (
      parsedDate.getFullYear() < today.getFullYear() ||
      (parsedDate.getFullYear() === today.getFullYear() && parsedDate.getMonth() < today.getMonth()) ||
      (parsedDate.getFullYear() === today.getFullYear() &&
        parsedDate.getMonth() === today.getMonth() &&
        parsedDate.getDate() <= today.getDate())
    ) {
      // console.log("Problem");
      return "delivery_date_must_be_tomorrow_soonest";
    }
  }
  return false;
}

export function dnote_validate_SingleWarehouse(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Všechny položky PD musí být na jeden sklad !! GW 2021-03-14:
   * odloženo, nemáme testovací PD, který by byl špatně
   */
  const warehouses = dnote_get_items_warehouses(dnote);
  if (warehouses.length !== 1) {
    return "deno_contains_multiple_wh_items";
  }
  return false;
}

export function dnote_validate_DodaciAdresaData(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Povinné: DP-NAME, STREET, COUNTRY, ZIPCODE, CITY
   */
  const header = dnote.header;
  const NazevAdresy = header.deliveryAddress.addressName;
  const Adresa = header.deliveryAddress.address;
  const Misto = header.deliveryAddress.city;
  const KodStatu = header.deliveryAddress.countryCode;
  const PSC = header.deliveryAddress.zipCode;
  // DP-NAME, STREET, COUNTRY, ZIPCODE, CITY
  if (
    dnote_field_ok(NazevAdresy) &&
    dnote_field_ok(Adresa) &&
    dnote_field_ok(Misto) &&
    dnote_field_ok(KodStatu) &&
    dnote_field_ok(PSC)
  ) {
    return false;
  }
  return "delivery_address_miss_mandatory_fields_fix_partners_address";
}

export function dnote_validate_HSH_DIN(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* pokud doručuje HSH, nesmí být DIN kódy, které nedávají smysl !! HSH+GW
   */
  const din_texts = dnote.textsDin;
  const header = dnote.header;
  const KodDopravce = header.carrierCode;
  if (KodDopravce === "SAMOODBĚR" || KodDopravce === "HSHLOGISTIKA") {
    if (din_texts.length > 0) {
      return "no_DIN_codes_allowed_for_self_pickup_and_HSHlogistika";
    }
    return false;
  }
  return false;
}

export function dnote_validate_DIN_Phone(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* pokud je DIN, který vyžaduje telefon, musí tam být telefon u adresy !! GW
   * prioritu má mobilní, musí být vyplněn alespoň jeden
   */
  const dins = dnote.textsDin;
  const needs_phone =
    dins.map((din) => din.textTypeCode).filter((ktt) => ktt === "PRP36" || ktt === "PRP37").length > 0;
  if (needs_phone) {
    const header = dnote.header;
    const Telefon = header.deliveryAddress.phoneNumber;
    const Mobil = header.deliveryAddress.mobilePhoneNumber;
    const has_phone = dnote_field_ok(Telefon) || dnote_field_ok(Mobil);
    if (has_phone) {
      return false;
    }
    return "PRP37_PRP37_must_have_phone_contant_fix_partnes_address";
  }
  return false;
}

export function dnote_validate_Uzavreno(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* PD musí být uzavřen !! HSH/GW
   */
  const header = dnote.header;
  const Uzavreno = header.closed;
  if (Uzavreno) {
    return false;
  }
  return "deno_has_to_closed";
}

export function dnote_validate_DopravniProstredek(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Dopravní prostředky - musí být vyplněn (obvykle KAMION) !! HSH
   */
  const header = dnote.header;
  const transportationCode = header.transportationCode;
  if (dnote_field_ok(transportationCode)) {
    return false;
  }
  return "missing_vehicle";
}

export function dnote_validate_TypAdresyFakturacni(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Fakturační adresa musí být základní nebo fakturační nebo sídlo !! HSH
   */
  const header = dnote.header;
  const TypAdresy = header.partnerAddress.addressType;
  if (TypAdresy === 0 || TypAdresy === 1 || TypAdresy === 3) {
    return false;
  }
  return "wrong_invoicing_adress_type"; // TODO
}

export function dnote_validate_DatDokladuPRP9(dnote: Dto.GetDeliveryNoteDetailResponse) {
  // Jen když je PRP9
  const header = dnote.header;
  if (!header) {
    return "unknown";
  }
  const KodDopravce = header.carrierCode;
  if (KodDopravce === "SAMOODBĚR" || KodDopravce === "HSHLOGISTIKA") {
    const DatDokladu = header.deliveryDate;
    // console.log(dnote.text_prp9);
    const prp9 = dnote.textPrp9.text;
    // console.log('prp9: ' + prp9);
    if (!dnote_field_ok(prp9) || prp9 === undefined) {
      return "missing_PRP9_for_doc_date_check";
    }
    const prp9a = prp9.split(".");
    const ddd = new Date(DatDokladu);
    const prp9d = new Date(Number.parseInt(prp9a[2]), Number.parseInt(prp9a[1]) - 1, Number.parseInt(prp9a[0]));
    const ddt = ddd.getTime();
    const prp9t = prp9d.getTime();
    if (ddt < prp9t) {
      return "doc_date_is_before_removal_from_wh_date";
    }
    //console.log(ddd);
    //console.log(prp9d);
  }
  return false;
}

export function dnote_validate_DatExpirace(dnote: Dto.GetDeliveryNoteDetailResponse) {
  const expirations_ok = dnote.items.filter((item) => !dnote_field_ok(item.expirationDate)).length === 0;
  if (expirations_ok) {
    return false;
  }
  return "product_expiry_missing";
}

export function dnote_validate_partner(dnote: Dto.GetDeliveryNoteDetailResponse) {
  if (dnote.warehousePartner) {
    return false;
  }
  return "automated_dispesing_isnt_implemented_for_this_wh";
}

export function dnote_validate_all_texts(dnote: Dto.GetDeliveryNoteDetailResponse) {
  for (var sidx in dnote.textsSkl) {
    const txt = dnote.textsSkl[sidx];
    if (txt.originalTextName === null) {
      return "unknown_skl_text";
    }
  }
  return false;
}

export function dnote_validate_FakturacniAdresaData(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* Povinné: DP-NAME, STREET, COUNTRY, ZIPCODE, CITY
   */
  const header = dnote.header;
  const NazevAdresy = header.partnerAddress.addressName;
  const Adresa = header.partnerAddress.address;
  const Misto = header.partnerAddress.city;
  const KodStatu = header.partnerAddress.countryCode;
  const PSC = header.partnerAddress.zipCode;
  // DP-NAME, STREET, COUNTRY, ZIPCODE, CITY
  if (
    dnote_field_ok(NazevAdresy) &&
    dnote_field_ok(Adresa) &&
    dnote_field_ok(Misto) &&
    dnote_field_ok(KodStatu) &&
    dnote_field_ok(PSC)
  ) {
    return false;
  }
  return "invoice_address_miss_mandatory_fields_fix_partners_address";
}

export function dnote_validate_KodStrediska(dnote: Dto.GetDeliveryNoteDetailResponse) {
  /* [2] středisko, priorita položka, musí být vyplněno
   */
  return dnote_validate_HeaderOrItemField(
    dnote,
    (h) => h?.stockOut?.departmentCode,
    (i) => i?.stockOut?.departmentCode,
    "missing_centre_on_deno"
  );
}

export function dnote_validate_KodZakazky(dnote: Dto.GetDeliveryNoteDetailResponse) {
  return dnote_validate_HeaderOrItemField(
    dnote,
    (h) => h?.stockOut?.orderCode,
    (i) => i.stockOut.orderCode,
    "missing_contract_on_deno"
  );
}

export function dnote_validate_KodKategorie(dnote: Dto.GetDeliveryNoteDetailResponse) {
  return dnote_validate_HeaderOrItemField(
    dnote,
    (h) => h?.stockOut?.categoryCode,
    (i) => i?.stockOut?.categoryCode,
    "missing_category_on_deno"
  );
}

export function can_be_canceled(dnote: Dto.GetDeliveryNoteDetailResponse) {
  // console.log(dnote.status);
  // console.log(dnote.pd_status);
  if (dnote.warehousePartner === "LF") {
    // Only for LF right now
    if (dnote.header.cancelled) {
      // Must be canceled in Twist
      if (dnote.items.length === 0) {
        // No items must be present (double check)
        return true;

        /* old conditions 
        if ((dnote.status === 5) || // accepted
          (dnote.status === 8) || // manually accepted
          (dnote.status === 11) // accepted with PDF
        ) { // Only if the warehouse accepted it in the past!
          if ((dnote.pd_status !== 13) && (dnote.pd_status !== 11)) {
            //console.log("can be canceled");
            return true;
          }
        }
        */
      }
    }
  }
  return false;
}

export function check_amount_fits_packages(
  performCheck: (a: string, b: string, c: () => boolean | string) => undefined,
  id: string,
  package_quantity_count: Fraction,
  svs_MnozstviSkl,
  t
) {
  performCheck(make_product_check_tag(id, "amount-fits-packages"), "danger", function () {
    if (!svs_MnozstviSkl) {
      return "cant_check_package_count_missing_amount";
    }
    if (package_quantity_count.s === -1) {
      return "package_count_cant_be_negative_num";
    }
    if (package_quantity_count.d !== 1) {
      return (
        t("cannot_remove_from_wh_amount_doesnt_match_whole_packeges_it_matches") +
        " " +
        String(package_quantity_count) +
        " " +
        t("package2")
      );
    }
    return false;
  });
}

export function evaluate_expiration(expiration: string) {
  // states
  // 0 - expired
  // 1 - less than one month
  // 3 - less than three months
  // false - its ok
  if (!expiration) {
    return false;
  }
  const exp = new Date(expiration);
  const today = new Date();
  const remaingDays = (exp.getTime() - today.getTime()) / 1000 / 60 / 60 / 24;
  if (remaingDays <= 0) {
    return 0;
  } else if (remaingDays > 0 && remaingDays < 30) {
    return 1;
  } else if (remaingDays > 30 && remaingDays < 90) {
    return 3;
  } else {
    return false;
  }
}

export function ExpirationBadge({ expiration }) {
  const { t } = useTranslation();
  const expirySOS = evaluate_expiration(expiration);
  switch (expirySOS) {
    case 0:
      return (
        <>
          <br />
          <Badge bg="danger p-1">{t("expired")}</Badge>
        </>
      );
    case 1:
      return (
        <>
          <br />
          <Badge bg="warning p-1">&#60; {t("1_month")}</Badge>
        </>
      );
    case 3:
      return (
        <>
          <br />
          <Badge bg="warning p-1">&#60; {t("3_months")}</Badge>
        </>
      );
    default:
      return <></>;
  }
}

export function check_expiration(performCheck, id, expiration, soft = false) {
  const danger = soft ? "warning" : "danger";
  const expirySOS = evaluate_expiration(expiration);
  performCheck(make_product_check_tag(id, "expiration"), danger, function () {
    if (expirySOS === 0) {
      return "expired_goods";
    }
    if (expirySOS === 1) {
      return "expires_in_less_than_month";
    }
    if (expirySOS === 3) {
      return "expires_in_less_than_3_months";
    }
    return false;
  });
}
