import { useTranslation } from "react-i18next";
import { delivery_note_progress_specification, final_states } from "./progress-delivery-note-def";
import { warehouses_for_select, warehouses_names_ids_separe } from "../lists/warehouses-defs";
import React, { useEffect, useState } from "react";
import { his_fetch_success, TableFetchStatusOverlay } from "../comp/FetchLoader";
import { date_formatCZ, date_isCzech, date_parseCzech, date_time_format } from "../lib/date-utils";
import { filter_rule } from "../lib/utils";
import { ErrorWrap } from "../comp/errorwrap";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { ItemsAndFiltered } from "../comp/dynamic-load";
import { Loading } from "../comp/loading";
import Form from "react-bootstrap/Form";
import { Pager } from "../comp/pager";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { get_id_with_boolean_in_dict } from "./delivery-notes-sales-tracking-new";
import { useQuery } from "@tanstack/react-query";
import { getDeliveryNotesTracking } from "../api/deliveryNotes";
import { useUserLogin } from "../UserLoginProvider";
import { queryStatusToLoadedStatus } from "../api/common";
import Table from "react-bootstrap/Table";
import { LinkContainer } from "react-router-bootstrap";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { SortIconsRhf } from "../comp/SortIconsRhf";
import { MultipleSelectRhf } from "../comp/MultipleSelectRhf";
import { useIsMdUpQuery } from "../hooks/breakpoints";
import { OrderBy } from "../lib/utilTypes";
import { ProgressPathDeliveryNote } from "./ProgressPathDeliveryNote";
import { useCachedOffsetAndFilter } from "../comp/FilterCacheProvider";

function identifyWarehouse(history: Dto.StockoutLog[]) {
  const uniqueWh = history
    .map((wh) => wh.warehouseId)
    .filter((wh) => wh !== null)
    .filter((v, i, a) => a.indexOf(v) === i); //odeberu null a udělám unique
  if (uniqueWh.length === 1) {
    return uniqueWh[0];
  } else {
    return null;
  }
}

//zero number in specification is for states, which doesnt have backend bumber assigned yet
const statesWithoutZeroAndFinalNumbers = delivery_note_progress_specification
  .filter((item) => item.number !== 0)
  .filter((item) => !final_states.includes(item.number));
const dictionary_of_statuses = Object.assign(
  {},
  ...statesWithoutZeroAndFinalNumbers.map((x) => ({ [x.number]: x.name }))
);

function useWarehousesWithNull() {
  const { t } = useTranslation();

  return { ...warehouses_for_select, null: t("unknown2") };
}

export const DeliveryNotesSalesTrackingQueryKey = ["deliveryNotes", "tracking"];

interface TrackingRowData {
  orderCode: string;
  history: Dto.StockoutLog[];
  warehouseId?: number | null;
  creator?: string;
  username?: string;
  lastModified?: string;
  status?: number;
  deliveryDate?: string;
}

const defaultFilterValues: TrackingTableFilterValues = {
  warehouseIds: [],
  statuses: [],
  orderBy: "-lastModified",
  deliveryNoteCode: "",
  creator: "",
};

export function DeliveryNotesSalesTracking() {
  const { t } = useTranslation();
  const isMdUp = useIsMdUpQuery();

  const form = useForm<TrackingTableFilterValues>({
    mode: "onChange",
    defaultValues: defaultFilterValues,
  });
  const { watch, reset } = form;
  const [offset, setOffset, filterFormValues] = useCachedOffsetAndFilter(
    "deliveryNotesTracking",
    watch,
    reset,
    defaultFilterValues
  );

  useEffect(() => {
    form.register("orderBy");
    form.register("warehouseIds");
    form.register("statuses");

    return () => {
      form.unregister("orderBy");
      form.unregister("warehouseIds");
      form.unregister("statuses");
    };
  }, [form]);

  useEffect(() => {
    const { unsubscribe } = watch((data, { name }) => {
      if (name === "warehouseIds" || name === "statuses") {
        setOffset(0);
      }
    });

    return () => unsubscribe();
  }, [watch]);

  const [reloadingPeriod, setReloadingPeriod] = useState(0);
  const [detailShown, setDetailShown] = useState({});

  const { data, refetch, status, isFetching } = useQuery({
    queryKey: DeliveryNotesSalesTrackingQueryKey,
    queryFn: ({ signal }) => {
      return getDeliveryNotesTracking({ signal });
    },
    refetchInterval: reloadingPeriod,
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const reloadIt = () => {
    return refetch();
  };

  const cleanFilters = () => {
    form.reset(defaultFilterValues, {});
  };

  //functions for handling diy accordion
  const allHidden = Object.keys(detailShown).length === Object.values(detailShown).filter((v) => v === false).length;

  function expandAll() {
    setDetailShown(get_id_with_boolean_in_dict(data, "deliveryNoteCode", allHidden));
  }

  function toggleDetailShown(id: string) {
    setDetailShown({ ...detailShown, [id]: !detailShown[id] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
  }

  function format_delivery_date(date: string) {
    if (date_isCzech(date)) {
      return date_parseCzech(date);
    } else {
      return new Date(date);
    }
  }

  //historylog preprocessing, unique codes of PD order code and asign logs
  const uniquePdCodes = data?.map((log) => log.deliveryNoteCode).filter((v, i, a) => a.indexOf(v) === i) ?? [];
  const historyLogsByOrderCode: { [key: string]: Dto.StockoutLog[] } = uniquePdCodes.reduce(
    (acc, deliveryNoteCode) => ({
      ...acc,
      [deliveryNoteCode]: data.filter((rec) => rec.deliveryNoteCode === deliveryNoteCode),
    }),
    {}
  );

  // preprocessing 2: builds 2 level dictionary with PD information and its historylogs
  const trackingTransformed = uniquePdCodes.map(function (PD): TrackingRowData {
    const oneHistorySorted = historyLogsByOrderCode[PD].sort(
      (a, b) => new Date(a.recorded).getTime() - new Date(b.recorded).getTime()
    );
    const oneHistoryReversed = [...oneHistorySorted].reverse();
    return {
      orderCode: PD,
      history: oneHistorySorted,
      warehouseId: identifyWarehouse(oneHistorySorted),
      creator: oneHistorySorted[0].userName,
      username: oneHistorySorted[0].login,
      lastModified: oneHistoryReversed[0].recorded, //musí být z posledního
      status: oneHistoryReversed[0].status, //musí být z posledního
      deliveryDate: format_delivery_date(oneHistoryReversed[0].deliveryDate),
    };
  });

  //removing PDs in final states
  const nonFinalPDs = trackingTransformed.filter((tr) => !final_states.includes(tr.status));

  //standard filtering based on user input
  const trackingFiltered = nonFinalPDs.filter(function (tra) {
    return (
      filter_rule(filterFormValues.deliveryNoteCode, tra.orderCode, true) &&
      (filter_rule(filterFormValues.creator, tra.creator, true) ||
        filter_rule(filterFormValues.creator, tra.username, true)) &&
      (filterFormValues.statuses.length === 0 ||
        filterFormValues.statuses.includes(tra.status?.toString() ?? "null")) &&
      (filterFormValues.warehouseIds.length === 0 ||
        filterFormValues.warehouseIds.includes(tra.warehouseId?.toString() ?? "null"))
    );
  });

  const trackingSortedRows = trackingFiltered.sort(function (a, b) {
    if (!filterFormValues.orderBy) {
      return 0;
    }
    const asc = !filterFormValues.orderBy.startsWith("-");
    const sortValue = asc ? filterFormValues.orderBy : filterFormValues.orderBy.slice(1);
    if (asc) {
      return new Date(a[sortValue]).getTime() - new Date(b[sortValue]).getTime();
    } else {
      return new Date(b[sortValue]).getTime() - new Date(a[sortValue]).getTime();
    }
  });

  const trackingShownRows = trackingSortedRows.slice(offset, offset + 20);
  return (
    <ErrorWrap>
      <Row>
        <Col>
          <Button
            disabled={loadedStatus === 0 || loadedStatus === 1 || loadedStatus === 4}
            size="sm"
            className="me-2 d-inline"
            onClick={reloadIt}
          >
            <Image src="/img/reload.svg" height="19" />
          </Button>
        </Col>
        <Col className="text-center">
          <ItemsAndFiltered filtered_data={trackingFiltered} data={nonFinalPDs} cleanFilters={cleanFilters} />
          {!his_fetch_success(loadedStatus) ? (
            <span>
              {" "}
              <Loading size="small" message="Načítání dat" margin="0" />
            </span>
          ) : (
            ""
          )}
        </Col>
        <Col className="text-end">
          <h6 className="d-inline">{t("background_loading")}:&nbsp;</h6>
          <Form.Group controlId="display" className="mb-0 ms-3 d-inline">
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={0}
              id="1"
              label={t("never")}
              onClick={() => setReloadingPeriod(0)}
              defaultChecked={reloadingPeriod === 0}
            />
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={15 * 1000}
              id="2"
              label="15s"
              onClick={() => setReloadingPeriod(15 * 1000)}
              defaultChecked={reloadingPeriod === 15}
            />
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={60 * 1000}
              id="3"
              label="1min"
              onClick={() => setReloadingPeriod(60 * 1000)}
              defaultChecked={reloadingPeriod === 60}
            />
            <Form.Check
              inline
              name="reloadingTime"
              type="radio"
              value={5 * 60 * 1000}
              id="4"
              label="5min"
              onClick={() => setReloadingPeriod(5 * 60 * 1000)}
              defaultChecked={reloadingPeriod === 5 * 60}
            />
          </Form.Group>
          <br />
          <br />
          <Pager offset={offset} pagesize={20} total={trackingFiltered.length} callback={setOffset} />
          <Button size="sm" className="float-end me-3 mb-2" onClick={() => expandAll()}>
            {allHidden ? (
              <>
                {t("expand")} <MdExpandMore />
              </>
            ) : (
              <>
                {t("hide")} <MdExpandLess />
              </>
            )}
          </Button>
        </Col>
      </Row>

      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          {isMdUp ? (
            <TrackingTable
              tracking={trackingShownRows}
              toggleDetailShown={toggleDetailShown}
              detailShown={(id) => !!detailShown[id]}
              setReloadingPeriod={setReloadingPeriod}
            />
          ) : (
            <TrackingTableApp
              tracking={trackingShownRows}
              toggleDetailShown={toggleDetailShown}
              detailShown={(id) => !!detailShown[id]}
              setReloadingPeriod={setReloadingPeriod}
            />
          )}
        </FormProvider>
      </TableFetchStatusOverlay>
    </ErrorWrap>
  );
}

interface TrackingTableProps {
  tracking: TrackingRowData[];
  toggleDetailShown: (id: string) => void;
  detailShown: (id: string) => boolean;
  setReloadingPeriod: (time: number) => void;
}

function TrackingTable({ tracking, toggleDetailShown, detailShown, setReloadingPeriod }: TrackingTableProps) {
  return (
    <Table size="sm" striped className="d-none d-md-table">
      <TrackingTableFilters />
      <tbody>
        {tracking.map((row: TrackingRowData) => (
          <TrackingRow
            row={row}
            key={row.orderCode}
            toggleDetailShown={toggleDetailShown}
            detailShown={detailShown}
            setReloadingPeriod={setReloadingPeriod}
          />
        ))}
      </tbody>
    </Table>
  );
}

export interface TrackingTableFilterValues {
  deliveryNoteCode?: string;
  creator?: string;
  orderBy?: OrderBy<TrackingRowData>;
  warehouseIds?: string[];
  statuses?: string[];
}

function TrackingTableFilters() {
  const { t } = useTranslation();

  const userLogin = useUserLogin();
  const warehousesWithNull = useWarehousesWithNull();

  const form = useFormContext<TrackingTableFilterValues>();
  const { register, setValue } = form;

  const [myNotesChecked, setMyNotesChecked] = useState(false);

  return (
    <thead className="beGray">
      <tr>
        <th style={{ width: "15%" }}>
          <Form.Group className="m-1" controlId="filterCode">
            <Form.Label column={true}>{t("doc_code")}</Form.Label>
            <Form.Control type="text" placeholder="&#128269; " {...register("deliveryNoteCode")} />
          </Form.Group>
        </th>
        <th style={{ width: "15%" }}>
          <Row>
            <Col xs="auto">
              <p className="mb-1">{t("ord-created_by")}</p>
            </Col>
            <Col className="text-end">
              <div className="float-end">
                <Form.Check
                  type="checkbox"
                  id="filter_my_PD"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue("creator", userLogin.userName);
                      setMyNotesChecked(true);
                    } else {
                      setValue("creator", "");
                      setMyNotesChecked(false);
                    }
                  }}
                  checked={myNotesChecked}
                  label={t("my_delivery_notes")}
                />
              </div>
            </Col>
          </Row>
          <Form.Group className="m-1" controlId="filterCreator">
            <Form.Control type="text" placeholder="&#128269; " {...register("creator")} />
          </Form.Group>
        </th>
        <th style={{ width: "15%" }} className="text-end pb-2">
          {t("ord-last_change_time")} &nbsp; <br />
          <SortIconsRhf filterFieldName="orderBy" columnName="lastModified" numeric />
        </th>
        <th style={{ width: "15%" }} className="text-center">
          <p className="mb-1">{t("warehouse")}</p>
          <MultipleSelectRhf values={warehousesWithNull} id="warehouseIds" />
        </th>
        <th style={{ width: "10%" }} className="text-end">
          {" "}
          {t("deno-delivery_date")}
          <SortIconsRhf filterFieldName="orderBy" columnName="deliveryDate" numeric />
        </th>
        <th style={{ width: "30%" }} className="text-center">
          <p className="mb-2">{t("state")}</p>
          <MultipleSelectRhf values={dictionary_of_statuses} id="statuses" />
        </th>
        <th></th>
      </tr>
    </thead>
  );
}

interface TrackingRowProps {
  row: TrackingRowData;
  toggleDetailShown: (id: string) => void;
  detailShown: (id: string) => boolean;
  setReloadingPeriod: (time: number) => void;
}

function TrackingRow({ row, toggleDetailShown, detailShown, setReloadingPeriod }: TrackingRowProps) {
  const { t } = useTranslation();

  return (
    <>
      <tr>
        <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.orderCode)} className="align-middle">
          <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/delivery-notes/sales/" + row.orderCode }}>
            <h6 className="mb-0 d-inline blueish">{row.orderCode}</h6>
          </LinkContainer>
        </td>
        <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.orderCode)} className="align-middle">
          {row.creator} ({row.username})
        </td>
        <td
          style={{ cursor: "pointer" }}
          onClick={() => toggleDetailShown(row.orderCode)}
          className="text-end align-middle"
        >
          {date_time_format(row.lastModified)}
        </td>
        <td
          style={{ cursor: "pointer" }}
          onClick={() => toggleDetailShown(row.orderCode)}
          className="text-center align-middle"
        >
          {row.warehouseId ? warehouses_names_ids_separe[row.warehouseId] : t("unknown")}
        </td>
        <td
          style={{ cursor: "pointer" }}
          onClick={() => toggleDetailShown(row.orderCode)}
          className="text-end align-middle"
        >
          {date_formatCZ(row.deliveryDate)}
        </td>
        <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.orderCode)} className="text-end">
          <ProgressPathDeliveryNote oneHistory={row.history} compact setReloadingPeriod={setReloadingPeriod} />
        </td>
        <td
          style={{ cursor: "pointer" }}
          onClick={() => toggleDetailShown(row.orderCode)}
          className="text-center align-middle"
        >
          <Button size="sm" variant="light">
            {detailShown(row.orderCode) ? <MdExpandLess /> : <MdExpandMore />}
          </Button>
        </td>
      </tr>
      <tr className=" p-0 m-0 ">
        <td colSpan={6} className="p-0 m-0">
          <div className={"mb-0 px-4 py-3 bg-light" + (detailShown(row.orderCode) ? "" : " d-none")}>
            <ProgressPathDeliveryNote oneHistory={row.history} setReloadingPeriod={setReloadingPeriod} />
          </div>
        </td>
      </tr>
    </>
  );
}

function TrackingTableAppFilters() {
  const { t } = useTranslation();
  const warehousesWithNull = useWarehousesWithNull();
  const userLogin = useUserLogin();
  const [myNotesChecked, setMyNotesChecked] = useState(false);
  const { register, setValue } = useFormContext<TrackingTableFilterValues>();

  return (
    <Card className="m-0 p-2">
      <Card.Body className="m-0 p-0">
        <Row>
          <Col>
            <Form.Group className="mb-3 d-md-none" controlId="filterCode">
              <Form.Label column={true}>{t("doc_code")}</Form.Label>
              <Form.Control type="text" placeholder="&#128269; " {...register("deliveryNoteCode")} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3 d-md-none" controlId="filterCreator">
              <Form.Label column={true}>{t("ord-created_by")}</Form.Label>
              <Form.Control type="text" placeholder="&#128269; " {...register("creator")} />
            </Form.Group>
            <Form.Check
              type="checkbox"
              id="filter_my_PDs"
              onChange={(e) => {
                if (e.target.checked) {
                  setValue("creator", userLogin.userName);
                  setMyNotesChecked(true);
                } else {
                  setValue("creator", "");
                  setMyNotesChecked(false);
                }
              }}
              checked={myNotesChecked}
              label={t("my_delivery_notes")}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <span className="bolder">{t("ord-last_change_time")}</span>
            <SortIconsRhf filterFieldName="orderBy" columnName="lastModified" numeric />
          </Col>
          <Col>
            <span className="bolder">{t("deno-delivery_date")} &nbsp;</span>
            <SortIconsRhf filterFieldName="orderBy" columnName="deliveryDate" numeric />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <p className="mb-0 bolder">{t("warehouse")}</p>
            <MultipleSelectRhf values={warehousesWithNull} id="warehouseIds" />
          </Col>
          <Col className="text-end">
            <p className="mb-0 bolder">{t("state")}</p>
            <MultipleSelectRhf values={dictionary_of_statuses} id="statuses" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function TrackingTableApp({ tracking, detailShown, toggleDetailShown, setReloadingPeriod }: TrackingTableProps) {
  return (
    <div>
      <TrackingTableAppFilters />
      <Table striped className="mt-4">
        <tbody>
          {tracking.map((row, idx) => (
            <TrackingRowApp
              key={idx}
              row={row}
              toggleDetailShown={toggleDetailShown}
              detailShown={detailShown}
              setReloadingPeriod={setReloadingPeriod}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function TrackingRowApp({ row, detailShown, toggleDetailShown, setReloadingPeriod }: TrackingRowProps) {
  const { t } = useTranslation();
  return (
    <>
      <tr onClick={() => toggleDetailShown(row.orderCode)}>
        <td>
          <div className="text-center">
            <ProgressPathDeliveryNote oneHistory={row.history} compact setReloadingPeriod={setReloadingPeriod} />
          </div>
          <Row>
            <Col>
              {" "}
              <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/delivery-notes/sales/" + row.orderCode }}>
                <h6 className="mb-0 d-inline blueish">{row.orderCode}</h6>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">{row.creator ? row.creator : t("unknown")}</Col>
            <Col className="text-end">
              {t("warehouse")}: {row.warehouseId ? warehouses_names_ids_separe[row.warehouseId] : t("unknown")}
            </Col>
          </Row>
          <Row>
            <Col>
              {t("deno-delivery_date")}: {date_formatCZ(row.deliveryDate)}
            </Col>
            <Col className="text-end">
              {t("last_modified")}: {date_time_format(row.deliveryDate)}
            </Col>
          </Row>
        </td>
      </tr>
      <tr className={" p-0 m-0 " + (detailShown(row.orderCode) ? "" : " d-none")}>
        <td>
          <div className={"mb-0 px-2 pt-3"}>
            <ProgressPathDeliveryNote oneHistory={row.history} setReloadingPeriod={setReloadingPeriod} />
          </div>
        </td>
      </tr>
    </>
  );
}
