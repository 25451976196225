/**
 * Homapage with release notes array.
 * @module home
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { HISInfo } from './his-info';
import { ErrorWrap } from './comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

const release_notes = [
    [20231225,
     ["doba skladování: šarže s netisknutelnými znaky před kódem", // #716
      "poznámky na šarži v přehledu doby skladování a v globálním seznamu poznámek" // #700
     ]],
    [20231210,
     ["reporty z účetního deníku: zaúčtované i nezaúčtované transakce", // #666
      "inventury dle dat z doby skladování - řešení duplicitních šarží" // #714
     ]],
    [20231128,
     ["stock positions report: úpravy FG dle požadavků HSH HH" // #715
     ]],
    [20231126,
     ["oprava MIME typu statických PDF odkazů", // #711
      "změna kódování předmětu mailů pro posílání přes Exchange" // #703
     ]],
    [20231124,
     ["aktualizace komunikačních knihoven SSL/TLS", // #707
      "přepočet stavu skladů v měně", // #708
      "automatická synchronizace Credendo pro HIS SK" // #709
     ]],
    [20231116, // #690
     ["doba skladování: zpracování vratek, úpravy výběru šarží",
      "inventury, stavy zboží ve skladu: množství dle součtu na šarži z rozpadu data naskladnění"
     ]],
    [20231029,
     ["GW: autodetekce formátu množství v importovaných stavech skladů" // #702
     ]],
    [20231022,
     ["emailové notifikace obchodníkům: změny odkazů do systému (partner, faktura)", // #698
      "finanční reporting: export zdrojových dat do XLSX", // #701
      "stock positions report, doba skladování: výdeje záporného množství, párování převodek mezi produkty" // #692
     ]],
    [20231015,
     ["stock positions report, doba skladování: prvotní zpracování převodek", // #690
      "LF: změna kódování zasílaných karet produktů (překryv CP-1250 a LATIN-1 u některých obalů)" // #556
     ]],
    [20230924,
     ["management report: kompatibilita s novými subsegmenty", // #694
      "skladové příjemky: zobrazení více příchozích souborů jednoho příjmu", // #693
      "reporty: názvy účtů z účtové osnovy v konfiguraci mapování", // #696
      "reporty/budget: export mapování českých účtů na německé (xlsx, JSON)" // #695
     ]],
    [20230917,
     ["názvy účtů z Twistu v reportech", // #688
      "reporting: stock positions report" // #692
     ]],
    [20230827,
     ["skladové zásoby: doba skladování", // #690
      "rozšíření filtrů na NO" // #689
     ]],
    [20230813,
     ["reporting: balance sheet report, prvotní mapování s podmínkami" // #666
     ]],
    [20230806,
     ["reporting: detekce nedefinovaných zdrojů (účtů)", // #686
      "manuální zrušení avizace NO při nulovém příjmu", // #680
      "avizace NO: odkazy na produkty ke schválení (lomítko v kódu produktu)" // #687
     ]],
    [20230730,
     ["Joppa: kódování znaků v importu skladových zásob", // #685
      "správa uživatelů: zákaz předvyplňování formulářů z úložiště prohlížeče", // #684
      "sledování výdejů: propojení historie stavu s datem dodání", // #682
      "sledování výdejů: koncový stav podle dopravce (SAMOODBĚR / ostatní)" // #683
     ]],
    [20230716,
     ["expirace: načítání šarží produktu z příjemek i výdejek najednou", // #679
      "zobrazení chyb před avizací: deduplikace pro dlouhé objednávky", // #518
      "management report: data z účetního deníku" // #678
     ]],
    [20230709,
     ["strukturované zobrazení emailů a příloh", // #620
      "možnost manuálního uzavření avizace na pozici \"4\"", // #674
      "aktualizace komponent zobrazení grafů", // #672
      "sjednocení formátů pro zobrazení na všech typech zařízení", // #675
      "globální konfigurace jazyka systému (před přihlášením uživatele)", // #635
      "oprava zobrazení PD v některých chybových stavech", // #677
      "synchronizace šarží produktů při naskladnění i vyskladnění", // #670
      "sjednocení uživatelského rozhraní změny hesla s ostatními formuláři", // #676
      "nové možnosti ručního řízení PD" // #627
     ]],
    [20230625,
     ["Oprava reakce na změny stavu PD po aktualizaci", // #671
      "Revize všech akcí prováděných při změnách stavů NO a PD", // #610
      "Zamezení zasílání duplicitních PDF PD", // #648
      "Překlopení statistik PD podle skladů na novou databázi", // #629
      "Zpracování chyb při selhání mazání na vzdáleném FTP serveru (Joppa)" // #650
    ]],
    [20230618,
     ["aktualizace komponent uživatelského rozhraní na nejnovější verze", // #658
      "sledování avizací: propojení na NO v mobilní aplikaci", // #667
      "zamezení spouštění akcí při opakované změně stavu avizací a výdejů", // #649
      "aktualizace zabezpečení (novější HTTPS)", // #668
      "oprava statistik výdejů podle skladů", // #629
      "doplnění překladů v mobilní aplikaci", // #664
      "možnost používání manuálního potvrzení ve sledování avizací a výdejů", // #669
      "zobrazení celých (i dlouhých) názvů souboru ve sledování odpovědí skladů" // #663
     ]],
    [20230606,
     ["zobrazení střediska produktu v přehledu expirací" // #665
     ]],
    [20230530,
     ["obnova komunikátoru pro všechny uživatele po aktualizaci základních komponent", // #661
      "oprava zobrazení produktů se znakem \"/\" v kódu" // #662
     ]],
    [20230529,
     ["oprava řazení chybných akcí při chybějící odpovědi ze skladu", // #659
      "oprava výběru reportu s focus group" // #660
     ]],
    [20230527,
     ["konfigurace mapování reportingu: detekce změn", // #631
      "aktualizace základních komponent webové a mobilní aplikace (React, Router)", // #621
      "oddělení databázových spojení uživatelského rozhraní a zpracování dat", // #655
      "zobrazení ID produktu v automatických příjemkách", // #657
      "ruční naskladnění: mezery a speciální znaky v kódu dokladu" // #656
     ]],
    [20230521,
     ["zvýraznění avizací NO po ETA", // #645
      "oprava spouštění událostí po změnách nahrávání souborů na FTP" // #654
     ]],
    [20230517,
     ["analýza expirací zboží ve skladech po šaržích", // #653
      "statistiky avizací v mobilní aplikaci", // #484
      "FTP komunikace se sklady: zajištění adresáře po přihlášení (problém v LF)" // #651
     ]],
    [20230510,
     ["LogFlex Ostrava - kontrola karet na PD a NO" // #652
     ]],
    [20230504,
     ["mobilní zobrazení ND", // #647
      "LogFlex Ostrava - inventury" // #637
     ]],
    [20230428,
     ["oprava kompatibility Credendo exportů s novým modulem XLSX", // #646
      "zobrazení ND", // #528
      "avizace NO: filtry dle ETA a dalších atributů" // #492
     ]],
    [20230418,
     ["prvotní nastavení parametrů produktu po synchronizaci s Twistem", // #643
      "avizace: přehled avizací uživatele", // #547
      "výdeje přihlášeného uživatele" // #644
     ]],
    [20230417,
     ["oprava nastavení produktu po sjednocení schvalování s ostatními parametry", // #643
      "avizace ADR produktů - volitelná obalová skupina pro třídy 1, 2, 5.2, 6.2 a 7" // #642
     ]],
    [20230416,
     ["evidence pH pro ADR produkty třídy 8", // #598
      "lokalizace rozhraní do více jazyků: čeština, angličtina, slovenština", // #11
      "zobrazení grafu salda partnera s novou verzí", // #639
      "změna načítání \"včerejších\" dat z Twistu", // #640
      "GW: oprava desetinných množství v obalu s novým exportem XLSX", // #638
      "LogFlex: nový sklad Ostrava" // #637
     ]],
	[20230331,
		["aktualizace exportních modulů XLSX", // #619
			"zobrazení slovenských faktur", // #636
			"reporting: profit/loss - focus groups" // #634
		]],
	[20230321,
		["zajištění kompatibility s novějšími verzemi některých prohlížečů" // #635
		]],
	[20230309,
		["uživatelé: nastavení jazyka uživatelského rozhraní", // #628
			"Joppa: načítání expirací z položek příjemek" // #633
		]],
	[20230306,
		["dokončení dynamického načítání online aktualizovaných dat", // #616
			"reporting: ukládání revizí konfigurace" // #630
		]],
	[20230301,
		["reporting: profit/loss - starý intranet" // #626
		]],
	[20230227,
		["Joppa: párování skladových karet se stavy skladů", // #622
			"NO: kontrola položek bez specifikace produktu", // #625
			"zobrazení historie stavů avizací a výdejů pro kroky s více záznamy" // #623
		]],
	[20230226,
		["Joppa: načítání stavu skladu a inventura", // #622
			"reporting: oddělení CZ/SK konfigurací, uložení do databáze", // #86
			"historie karet ve skladech: zarovnání sbalených záznamů", // #624
			"zrychlení zobrazení znovunačítaných dat (objednávky, avizace, dodací listy, ...)" // #616
		]],
	[20230219,
		["LL: načítání stavu skladů s produkt bez šarže (paleta)", // #618
			"GW: inventury - sčítání množství na šarži s netisknutelnými znaky", // #615
			"restrukturalizace přístupu k jednotlivým modulům, zjednodušení přístupu v mobilní verzi", // #593
			"uzavření starých avizací příjmů" // #546
		]],
	[20230207,
		["Joppa: přepočty obsahu balení" // #617
		]],
	[20230205,
		["sledování avizací: automatická obnova a načítání na pozadí", // #524
			"reporting: mapování slovenských účtů" // #86
		]],
	[20230130,
		["Joppa: automatický import příjemek", // #265
			"reporting: filtry nedefinovaných a nepoužitých účtů" // #86
		]],
	[20230129,
		["export reportů pro HSH DE ve formátu XLSX", // #603
			"stromový výpis procesů systému s filtry a automatickým obnovováním (sledování běhu)", // #608
			"LF: mapování jednotek - kusů" // #614
		]],
	[20230122,
		["přesný výpočet načitatelných záznamů v časových výpisech nezávisle na filtrech", // #609
			"reporting: mapování výnosových účtů, úpravy nákladových účtů" // #86
		]],
	[20230118,
		["výpisy všech akcí na příjmech i odeslání FTP souborů + nové filtry", // #611
			"možnost kopírovat název souboru i stahovat jejich obsah ve FTP výpisech", // #613
			"srovnání stránkování po znovunačtení sledování výdejů" // #604
		]],
	[20230112,
		["GW: mapování obalu OKTABIN/KRT", // #612
			"GW: změna kódování přijímaných dat na UTF-8", // #601
			"využití nového sledování výdejů pro statistiky", // #610
			"asynchronní načítání všech historických dat " // #611
		]],
	[20230108,
		["odstraněno staré sledování komunikace PD", // #605
			"úprava sledování aizací podle sledování výdejů", // #606
			"sledování příjmů - napojení ND na příjemky", // #607
			"reporting: nové mapování nákladových účtů" // #86
		]],
	[20221229,
		["sjednocené sledování avizací a výdejů, FTP události, statistiky", // #575
			"inventury: dokončení výpočtů - konverze desetinných čísel" // #600
		]],
	[20221220,
		["avizace: vypnutí kontroly šarže a expirace pro produkty kategorie 3", // #602
			"inventury: sjednocení zobrazení stavu skladů, Twistu a porovnání" // #600
		]],
	[20221218,
		["změny komunikace se sklady (především GW) na základě pilotního provozu inventur" // #601
		]],
	[20221215,
		["inventury GW: změna kódování stavů skladů na UTF-8", // #601
			"karty GW: změna kódování na CP-1250",
			"FTP komunikace GW: změna struktury názvů příchozích souborů",
			"inventury: přepočty neúplných balení na šarži",
			"inventury: filtrování podle chyb na šarži v případě, že celkové součty sedí"
		]],
	[20221213,
		["inventury: datum poslední aktualizace skladu",
			"inventury: zarovnání jednotek a množství",
			"inventury: sjednocení nulových stavů" // #600
		]],
	[20221212,
		["inventury: filtry produktů, šarží a množství v kg na produktu a šarži" // #600
		]],
	[20221211,
		["zobrazení časového odpočtu ve všech relevantních stavech příjmů a výdejů", // #585
			"dokončení nových kontrol avizací: množství na šarži a jedna neznámá šarže na položku", // #591
			"přehled importovaných příjemek", // #584
			"výpis odchozích a příchozích souborů v zobrazení stavu výdeje (PD)", // #599
			"srovnání stavu skladů dle skladů a Twistu - počáteční inventury" // #529
		]],
	[20221204,
		["možnost zadání avizované množství jako desetinné číslo s tečkou i čárkou", // #597
			"synchroniace příjemek: SkladPrijemDoklady, SkladPrijemPolozky, SkladPrijemSerie", // #529
			"nové kontroly avizací NO: zobrazení šarží, které překračují množství na položce" // #591
		]],
	[20221127,
		["sjednocení zpracování chyb při načítání dat ze serveru", // #552, #554
			"možnost ručního naskladnění avizace NO při zaseknutí na kroku \"příjem obdržen\"", // #590
			"zobrazení skladu v 2. kroku a shrnutí avizace", // #594
			"vypnutí připojení k databázi TRANSCO pro slovenský Twist", // #595
			"zobrazení stavu zásob ve skladech GW" // #592
		]],
	[20221120,
		["zobrazení posledního času aktualizace stavu skladů", // #586
			"restrukturalizace zobrazení seznamů (sklady, stavy příjů a výdejů)", // #555
			"oprava možnosti avizace množství s desetinnou čárkou", // #588
			"nové kontroly avizovaných množství a šarží na položce avizace", // #589
			"zobrazení stavu zásob ve skladu LF" // #587
		]],
	[20221113,
		["nová dokumentace zpracování a sledování výdejů", // #572
			"řešení problémů přenosu stavů skladů LL", // #529
			"prototyp profit/loss reportů pro budget dle nových šablon", // #86
			"aktualizace přihlašovacích mechanismů a správy uživatelů" // #553
		]],
	[20221106,
		["odlišení varování o jiných pracovnících na avizaci mezi NO a avizací", // #580
			"kontrola nulových a záporných množství k dodání na NO", // #579
			"příjmy: zobrazení přijatého souboru ve sledování avizace a příjmu", // #578
			"oddělení konfigurace skladů od globální konfigurace systému (pro inventury)", // #581
			"sledování systémových procesů na pozadí (plánovač, FTP procesy skladů)", // #551
			"stránkování a filtry ve výpisu skladových zásob dle skladů", // #582
			"dokončení integrace opětovného připojování k databázím TRANSCO a TWIST_HSH_HSH" // #577
		]],
	[20221030,
		["zobrazení stavu zásob ve skladu LL" // #557
		]],
	[20221023,
		["sjednocení záznamu událostí o výdejích s avizacemi příjmů", // #525
			"zobrazení všech modulů v mobilní aplikaci", // #570
			"přepracované zobrazení průběhu výdejů", // #540
			"nová implementace ručních zásahů do výdejů", // #569
			"znovupřipojení k databázi TRANSCO při chybě importu do Twistu", // #566
			"odmítnutí automatického příjmu v případě ručního naskladnění" // #573
		]],
	[20221006,
		["úprava filtrů sledování výdejů", // #567
			"zobrazení chybových odpovědí externích systémů, zobrazení identifikátoru příjemky", // #564
			"import karet Lorenc Logistic s chybějícím ID produktu" // #568
		]],
	[20221002,
		["generování dat pro PD s ADR produkty bez obalové skupiny", // #562
			"nové sledování výdejů - převod záznamu na zobrazené kroky" // #540
		]],
	[20220925,
		["synchronizace Twist: účetní deník - změny více jak 32767 záznamů", // #559
			"odesílání skladových karet Lorenc Logistic", // #480
			"kontrola karet ve skladu 30", // #560
			"záznam identifikace příjemky při naskladnění z LF a GW", // #558
			"zobrazení více uživatelů pracujících na avizace, možnost ukončení přímo z výpisu na NO", // #543
			"výjimky z kontroly obalové skupiny pro ADR produkty tříd 1, 2, 5.2, 6.2 a 7" // #561
		]],
	[20220918,
		["sledování příjmů: zobrazení a odkaz na název vytvořeného souboru avizace", // #544
			"sledování příjmů: ukládání a zobrazení důvodu zamítnutí avizace skladem", // #545
			"archiv komunikace: řešení problému posledního dne v měsíci", // #550
			"sledování zatížení systému při komunikaci se sklady", // #551
			"načítání a kontrola karet LL", // #480
			"sledování výdejů: záznam kroků komunikace" // #540
		]],
	[20220904,
		["explicitní zpracování posledního dne v měsíci v archivu komunikace se sklady", // #550
			"rozšíření akceptovaných znaků při generování PDF (PD, proof of export)" // #537
		]],
	[20220831,
		["upozornění na chybu příjmu z GW ve sledování avizací", // #548
			"oprava zobrazení PINu položek avizací autorizovaným uživatelům" // #549
		]],
	[20220821,
		["dokončení specifikace sledování avizace příjmu", // #516
			"uzavření nekompletních historických avizací", // #536
			"rozbalené zobrazení aktuálně probíhajících avizací na NO", // #535
			"zobrazení časového odpočtu po 15 minutách u všech kroků avizací", // #534
			"negenerovat imaginární šarži pro chybné příjmy z GW", // #539
			"persistence filtrů kontroly karet při znovunačtení" // #538
		]],
	[20220814,
		["sjednocený systém řazení výsledků (avizace, PD, platební morálka)", // #527
			"řešení chybějících změn stavu avizací pokud je vynechána FTP operace", // #530
			"delší uchovávání systémového záznamu v produkčním prostředí (problémy FTP jsou reportovány pozdě)", // #531
			"naskladňování na imaginární šarži, pokud není zaslána skladem" // #512
		]],
	[20220807,
		["stránkování a filtry ve sledování avizací", // #523
			"zadání ND při ručním naskladnění" // #520
		]],
	[20220731,
		["zobrazení časového odpočtu jen u aktuálního kroku sledování avizace",
			"mobilní verze sledování stavu avizací"
		]],
	[20220728,
		["zpracování nestandardních stavů při naskladňování do Twistu",
			"redesign sledování stavu avizace v zobrazení NO pro menší monitory",
			"zobrazení sledování avizací na mobilu"
		]],
	[20220727,
		["zobrazení odpovědí Twistu z nasklanění na NO",
			"GW neutralizace: rozlišení příznaků ve skladech Jeneč a Syrovice",
			"sledování procesu avizací a příjmů"
		]],
	[20220724,
		["záznam odpovědí Twistu při naskladňování příjmů (LF, GW)",
			"předávání příznaku NEUTRALIZACE na výdeji PD v GW"
		]],
	[20220720,
		["import dostupných atributů karet Lorenc Logistic"
		]],
	[20220710,
		["generování karet produktů v XLSX pro hromadný import na straně skladů",
			"export seznamu všech pohledávek do XLSX"
		]],
	[20220707,
		["zasílání odkazu na NO z rozpracované avizace",
			"možnost generování PDF salda s polskými (a dalšími) znaky"
		]],
	[20220628,
		["kontrola evidence karet v LL - zobrazení na produktu",
			"sloučení definic skladů - integrace LL (účetní sklad, napojení API, zobrazení)",
			"rozšířené vyhledávání v odchozích emailech (do skladů)",
			"podpora nestandardních znaků v avizacích do LF"
		]],
	[20220619,
		["seznam PD: zobrazení i PD s datem v budoucnosti",
			"historie karet produktů: sloučení překrývajících se záznamů",
			"dynamické načítání (PD, NO a další): zrychlení prvotního načtení seznamu",
			"filtrování karet produktů podle bezpečnostních listů i ve skladu GW Syrovice"
		]],
	[20220612,
		["historie karet produktů - řešení chyb nemonotónních časových řad z prvních exportů GW (nový záznam nenavazuje na zneplatnění předchozího)"
		]],
	[20220607,
		["zobrazení historie karet produktů ve skladech - sloučení navazujících záznamů",
			"mazání opakujících se souborů na FTP skladů (GW)"
		]],
	[20220605,
		["nový přehled skladových karet: doplňkové kontroly",
			"kontroly chyb na NO, PD a Produktu: odkaz na konkrétní místo v dokumentu (automaticky najede na dané místo i v dlouhých výpisech)",
			"GW příjmy: zpracování nepárovatelných položek s nulovým množstvím",
			"zobrazování časových řad: načítání vždy od následujícího dne (poslední den v měsíci)",
			"NO: zobrazení IDPoložky z Twistu pro vybrané uživatele",
			"zobrazení produktů v mobilní aplikaci",
			"pohledávky: přidání splatnosti a tolerance do globálních výpisů"
		]],
	[20220529,
		["kontrola, zda je vyplněn sklad na položce NO",
			"kontrola avizace: avizované množství musí být kladné (pro odebrání položky je možný návrat na předchozí krok)",
			"nová kontrola skladových karet"
		]],
	[20220523,
		["LF: naskladňování"
		]],
	[20220522,
		["rozšíření sledování avizací NO o trvalé chybové stavy",
			"sjednocení a optimalizace načítání dat dle data a času: FP, FV, NO, PO, PD, emaily, FTP přijaté a odeslané soubory a sledování stavu PD",
			"import informace o chybějícím BL produktu ve skladu GW (Jeneč)",
			"filtrování v mobilní verzi sledování výdejů",
			"platební morálka: přidáno souhrnné účetní saldo po splatnosti"
		]],
	[20220515,
		["oprava počítání expirací produktů typu 2 při vícepoložkové avizaci NO"
		]],
	[20220512,
		["zrychlení načítání dlouhých seznamů: přijaté a odeslané soubory, emaily"
		]],
	[20220508,
		["avizace a příjmy: řešení rozdílných nákupních a skladových jednotek"
		]],
	[20220505,
		["naskladňování GW příjmů - expirace"
		]],
	[20220428,
		["NO: detekce a hlášení nekonzistencí databáze Twistu oproti avizovaným příjmům",
			"NO: oddělení objednávek dopravy"
		]],
	[20220427,
		["PD: oprava kontroly data dodání a PRP9"
		]],
	[20220426,
		["NO avizace: řazení let při výběru expirace",
			"komunikátor: ochrana před vícenásobným odesláním",
			"NO: změna ukončení avizace nadřízeným pracovníkem při vícenásobné avizaci",
			"avizace Joppa: možnost předání expirací u produktů s povinným sledováním šarže i expirace"
		]],
	[20220419,
		["GW: zpracování příjmů s více řádky se stejnou šarží",
			"avizace NO: sjednocení odkazů na NO v avizacích, sledování i výpisu rozpracovaných",
			"statistiky výdejů a avizací: abecední / číselné řazení uživatelů / skladů",
			"avizace NO: oddělení probíhajících a uzavřených avizací v zobrazení NO",
			"filtrování přijatých souborů podle spuštěné akce",
			"upozornění na rozpracované avizace v zobrazení NO",
			"avizace NO: sledování a zobrazení doby ve stavech \"avizace vyzvednuta\" a \"potvrzena\"",
			"porovnávání množství produktu v jednotkách nákupní objednávky oproti skladovým jednotkám"
		]],
	[20220413,
		["automatické příjmy: nulové položky na příjemkách GW"
		]],
	[20220410,
		["automatické uzavírání rozpracovaných avizací po 48h",
			"sledování stavů avizace GW: příjem obdržen, naskladněno (Twist)"
		]],
	[20220407,
		["příjem více šarží po avizaci jedné",
			"GW: export PD partnerů s mezerou na konci kódu partnera"
		]],
	[20220329,
		["rychlý výběr roku při zadávání expirace s přesností na den",
			"sledování avizací: změny odkazů a ovládání seskupení avizací objednávek",
			"automatické příjmy GW"
		]],
	[20220320,
		["avizace: kontrola správnosti Mj na položce",
			"komunikace se sklady - PD: změna reakce na ruční potvrzení (nově znovunačtení s aktualizovanými údaji)",
			"zobrazení ID produktu ve shrnutí avizace"
		]],
	[20220317,
		["Joppa a LogFlex: nové mapování obalů"
		]],
	[20220313,
		["FTP Joppa: náhrada procesu znovupřipojování na server protistrany",
			"Twist: sekundární napojení na databázi pro import příjemek ze skladů",
			"zobrazení ID produktu v jednotlivých krocích avizace",
			"možnost rozbalení detailu jednotlivých probíhajících i minulých avizací NO"
		]],
	[20220301,
		["úprava uživatelského nastavení a zabezpečení účtů včetně změny hesla",
			"Credendo: úprava výkazů",
			"přehled nákupních objednávek partnera",
			"zrychlení načítání seznamu a kontroly produktů",
			"položkový přehled marží na fakturách vydaných"
		]],
	[20220224,
		["avizace NO pro partnery s mezerami na konci kódu",
			"zrychlení načítání seznamu partnerů při návratu z detailu partnera",
			"změna kontroly základních adres partnera na NO (žádné i vícečetné)"
		]],
	[20220223,
		["oprava synchronizace databáze Twist (SK)",
			"hlášení chyb uživatelům (pro předání na technickou podporu)"
		]],
	[20220216,
		["možnost kopírování názvu souboru ze záznamů FTP přenosů",
			"kontrola expirace zboží při výdeji",
			"kontrola adresy partnera na NO a v detailu partnera",
			"sledování otevřených avizací",
			"Joppa: reakce na zaseklou komunikaci FTP"
		]],
	[20220211,
		["možnost avizací bez známé šarže pro příjezdy na následující pracovní den nebo pro dodávku více jak 5t",
			"nové filtry sledování výdejů a poznámek",
			"sjednocení výběru roku s a bez měsíce pro statistiky, avizace (expirace) a hlášení obratu"
		]],
	[20220202,
		["platební morálka v XLSX",
			"volitelná avizace expirací produktů NO pro produkty konfigurace 1 do LF a GW",
			"nové zobrazení a filtr všech poznámek"
		]],
	[20220201, [
		"načítání produktů se znakem \"%\" v kódu produktu",
	]],
	[20220128, [
		"avizace příjezdu zboží do LF: kód partnera a adresa dodavatele",
	]],
	[20220124, [
		"sjednocení navigace druhé úrovně (partneři)",
		"systemizace chybových zpráv u kontrol NO, PD a produktů",
		"oddělení skladů 27 a 29 pro účely avizací",
		"předběžné zpracování marží včetně dopravy",
		"NO: avizace příjezdu zboží do LF",
		"vyhledávání ve sledování výdejů",
		"zobrazování stavů PD po zpracování skladem",
	]],
	[20220120, [
		"filtrování přijatých souborů podle spuštěných akcí",
		"filtrování skladů položek NO (řešení problémů s konsignačními sklady)",
		"parametrizace analýzy platební morálky (měsíce, řazení, filtry)",
	]],
	[20220118, [
		"rozpracované avizace: kód dokladu, proklik na NO",
		"statistiky: sjednocení avizací a výdejů",
		"sjednocení výběru platných měsíců ve statistikách a hlášení obratu",
		"vyhodnocení platební morálky zákazníků",
	]],
	[20220114, [
		"statistika avizací příjezdu zboží na sklad",
		"FTP komunikace - reakce na nepotvrzený upload souboru",
	]],
	[20220112, [
		"oprava formátování data v avizacích NO",
		"sjednocení kontrol dat vystavení, dodání a příjezdu",
		"řešení neúspěšně přenesených FTP souborů",
		"zrychlení zpracování přijatých souborů ze skladů",
	]],
	[20220111, [
		"synchronizace partnerů s chybným FIČ / kreditním limitem",
		"NO: kontrola shody jednotek a obalu jen informativní, musí se shodovat celkové množství v kg",
		"sjednocení zobrazení data v českém formátu",
		"PD: kontrola počtu základních adres partnera",
		"partneři: podpora více modulů",
	]],
	[20220107, [
		"dokončení sjednocení kontrol NO, PD a produktu",
		"LF: mapování Pytel - big bag",
		"Twist: synchronizace chybějících dokladů",
		"kontrola obalů: koncové mezery",
		"avizace Joppa: předávání kódu partnera",
		"GW: převody hmotnostních jednotek",
	]],
	[20220105, [
		"sledování stavu výdeje v GW",
		"LogFlex: mapování obalu - krabice",
	]],
	[20220103, [
		"sjednocení kontrol NO, PD a produktu: násobky balení ve skladech a v Twistu",
		"porovnání obalů mezi sklady a Twistem",
		"Joppa: finalizace komunikačního protokolu (reakce na chybové stavy, které Joppa neumí reportovat)",
	]],
	[20211221, [
		"Joppa: potvrzení avizací příjmů",
		"Joppa: kontrola obalu (BOX/KRABICE)",
	]],
	[20211220, [
		"kontroly PD: schválení produktu k nákupu a v HISu, nastaveni sledování šarží a expirací a nastavení expirace v měsících",
		"kontrola duplicitních položek na NO - chyby v exportu karet ze skladu nebo vícenásobné obaly",
		"PD: proof of export",
		"zobrazení obalů na produktu a kontrola, zda je právě jeden základní",
	]],
	[20211216, [
		"sjednocení kontrol balení produktu pro výdej i příjem",
		"oddělení zpracování dat z jednotlivých skladů tak, aby se komunikace vzájemně neovlivňovala (oddělení komunikace Joppa)",
		"kontrola správnosti adres partnera na NO před avizací",
	]],
	[20211213, [
		"sledování výdejů: indexace dat (rychlé načítání)",
		"kontrola názvu balení na PD: pouze informativně",
		"seznamy emailů: zobrazení odesilatele",
	]],
	[20211210, [
		"avizace: nové kontroly a porovnání karet se skladem",
		"interní komunikátor: nový vzhled, k dispozici na NO, PD i produktu",
		"sjednocení kontrol přepočtů, karty ve skladu a obalů na produktu",
		"dodací listy: sjednocení kontrol a zobrazení položek s avizacemi",
		"zobrazení emailové komunikace u PD, NO a produktu",
	]],
	[20211125, [
		"reference na zakázku, středisko a kategorii u položek avizace do skladů GW",
		"práce na sjednocování kontrol mezi produkty, nákupními objednávkami a dodacími listy",
	]],
	[20211117, [
		"persistentní filtrování v kontrole karet",
		"zobrazení obalu v detailu produktu - Twist i sklad",
		"korektní zobrazení ADR informací ze skladu v detailu produktu",
		"formulář obnovení zapomenutého hesla",
		"kontrola duplicit uživatelských jmen",
		"kontrola aktuálnosti dat v modulu pojištění",
		"rozšíření kontrol položek nákupní objednávky před avizací",
		"rozlišení nové a aktualizované skladové karty při zaslání do skladu GW",
	]],
	[20211114, [
		"odesílání odkazu na produkt a nákupní objednávku emailem jinému uživateli",
	]],
	[20211110, [
		"LogFlex: logistické informace o probíhajícím výdeji",
	]],
	[20211103, [
		"rozšíření komunikace Joppa (karty, příprava avizací)",
		"přehled konfigurací a expirací produktů",
		"více komunikačních adresářů pro jeden sklad (Joppa)",
		"Credendo: generování hlášení obratu při změně FIČ",
		"rozšíření kontrol NO pro avizace příjmu zboží",
	]],
	[20211020, [
		"tisk PD s chybně zadanými prodejními jednotkami",
		"odesílání a zpětná kontrola karet ve skladu Joppa",
	]],
	[20211014, [
		"správa uživatelů",
		"nový sklad LogFlex Syrovice",
		"nastavení dopočítávaných expirací produktů kategorie sledování šarží a expirací 2 a 3",
	]],
	[20211006, [
		"detail produktu: dashboard stavu v Twistu i ve skladech (skladové karty)",
		"úprava zasílání dat do skladů pro případ, že ve skladu dojde k úplnému výpadku IT služeb",
		"odesílání skladových karet LogFlex",
		"sledování stavu přenosu skladových karet",
	]],
	[20210924, [
		"převody HSH obalů a obalů dle specifikací GebrüderWeiss",
		"rozlišení chybějící a neúplné karty ve skladu",
	]],
	[20210922, [
		"základní kontrola skladových karet položek objednávky",
	]],
	[20210920, [
		"tisk atypických polských znaků v PDF",
		"kontrola povinného nastavení sledování šarží a expirací",
	]],
	[20210919, [
		"kontrola nastavení sledování šarží a expirací skladových karet",
		"vyhledávání v archivu emailů a datových přenosů FTP",
		"kontrola správnosti fakturační adresy při odeslání PD do skladu",
		"ošetření skladových karet produktů pro dodavatele s mezerami na konci kódu partnera",
		"možnost znovunačtení při chybách sítě, znovunačtení na více místech",
		"odkazy na partnery, produkty a faktury ze všech pohledů",
		"kontrola schválení nákupu produktu při odeslání skladové karty",
	]],
	[20210914, [
		"kontrola skladových karet v detailu produktu",
		"kontrola existence SKL textů na PD před odesláním",
		"řešení chybné komunikace serveru Joppa",
		"odesílání skladových karet GW",
		"nastavení sledování šarží a expirací v GW podle nastavení karty produktu",
	]],
	[20210829, [
		"zasílání storna do LF i pro PD, ke kterým bylo úspěšně odesláno PDF",
		"sledování vyskladnění na rampu v JP",
		"sledování aktivity výdejů: datové i fyzické přesuny",
	]],
	[20210822, [
		"základní kontrola dodavatele produktu pro generování skladových karet",
		"nastavení sledování šarží a expirací produktu skladem",
		"signalizace odeslaného PDF PD emailem",
		"odesílání PDF PD na FTP GW",
		"alternativní názvy a kódy produktu v PD pro jednotlivé partnery",
	]],
	[20210811, [
		"sestupné řazení všech archivů (od nejnovějších položek)",
		"filtrování názvů produktů - sklad Joppa",
		"tisk ADR třídy a obalové skupiny do PD PDF",
	]],
	[20210808, [
		"základní pohled na prodejní objednávky včetně online synchronizace",
		"možnost znovunačtení nejen u PD, nově: nákupní a prodejní objednávky, produkty (skladové karty)",
		"kontrola produktů a seznam karet ve skladech: jednotné hlavičky a zrušeno stránkování",
		"synchronizace Twist: definice produktů pro partnery",
		"import skladových karet Joppa",
		"základní zobrazení archivu emailů",
		"procházení archivu odeslaných a přijatých FTP souborů + download",
	]],
	[20210730, [
		"online synchronizace nákupních objednávek (pro generování hromadných skladových karet)",
		"reakce na síťové chyby při stahování skladových karet z produktů i nákupních objednávek",
		"integrace procesních návodů do nápovědy",
		"stahování souborů datové komunikace se sklady z uživatelského rozhraní",
		"přehled založených skladových karet v jednotlivých skladech (pro kontrolu před odesláním karty)",
	]],
	[20210726, [
		"dokončeno zrychlené načítání všech dat do prohlížeče/aplikace",
		"přiblížení (zoom) a posun grafu historie salda",
		"výpis pohledávek a závazků partnera v PDF",
		"úprava oprávnění k potvrzování PD",
	]],
	[20210718, [
		"Twist: online synchronizace produktových karet",
		"dynamické načítání informací o partnerovi",
		"import skladových karet LogFlex",
	]],
	[20210706, [
		"mobilní verze seznamů dodacích listů - prodejní a sledování",
		"mobilní detail dodacího listu",
		"mobilní verze seznamů faktur - vydaných i přijatých",
		"mobilní závazky u partnera",
		"výběr sekce u faktur, dodacích listů a produktů - zamezuje dlouhému načítání",
		"úprava hlavičky u partnera pohledávek",
		"nové stavy u detailu dodacího listu - automatické odesílání PDF u skladu GW Jeneč",
	]],
	[20210628, [
		"zasílání PDF PD na více emailových adres (Joppa)",
	]],
	[20210627, [
		"výpis závazků k partnerovi",
		"archivace všech odeslaných emailů",
	]],
	[20210622, [
		"úprava přenosu PDF emailem do skladu Joppa",
		"změna logování komunikace s FTP serverem Joppa",
	]],
	[20210621, [
		"filtr pouze plně účetně uhrazených faktur v platební historii partnera",
		"zobrazení historického zpoždění plateb, zrušeno zobrazení prodlení (nově pouze v neuhrazených pohledávkách)",
	]],
	[20210620, [
		"seznam došlých faktur",
		"kontrola karet ve skladech: společný prefix názvu produktu, celková hmotnost balení",
		"výpočet salda při párování hromadných úhrad faktur vydaných",
		"automatické výdeje ze skladu Joppa",
		"statistiky výdejů podle skladu",
	]],
	[20210616, [
		"implicitní zobrazení podle dokladového salda v neuhrazených pohledávkách partnera",
		"výpočet maximálního prodlení jen z faktur z nevyrovnaným dokladovým saldem",
		"výpočet průměrného prodlení jen z faktur s kladným prodlením a nevyrovnaným dokladovým saldem",
		"komunikace LF: nový formát odpovědí, rozšířující informace ze skladu",
		"synchronizace došlých faktur (FakturyDosleDoklady a FakturyDoslePolozky)",
	]],
	[20210614, [
		"filtrace skladových karet s problémy",
		"tisk doplňujících textů do PDF dodacích listů",
	]],
	[20210613, [
		"automatické odesílání PDF dodacích listů do skladů LF a GW",
		"úprava notifikací faktur po splatnosti (reorganizace tabulky, dokladové saldo)",
		"možnost zobrazení pohledávek partnera podle účetního i dokladového salda",
		"načítání a základní kontrola skladových karet GW",
	]],
	[20210608, [
		"výpočet průměrného zpoždění ze všech uhrazených faktur",
		"změna kódování načítaných skladových karet GW na ISO8859-1",
	]],
	[20210607, [
		"oprava přepočtu množství v obalu pro různé počty desetinných míst",
	]],
	[20210606, [
		"denní import skladových karet GebrüderWeiss",
	]],
	[20210530, [
		"statistiky výdejů po dnech, týdnech a měsících",
		"zapnutí online synchronizace všech dat po celý den (online seznamy dodacích listů, produktů, faktur ...)",
		"karty produktů pro sklad Joppa ve formátu XLSX",
		"úprava tiskové verze PDF dodacího listu - speciální znaky",
	]],
	[20210523, [
		"úpravy PDF PD: speciální znaky v názvech produktů",
	]],
	[20210520, [
		"statistiky výdejů pro vybraný měsíc",
		"náhrada chybějícího čísla objednávky (a data) zákazníka na položce z hlavičky PD",
		"online synchronizace prodejních objednávek",
	]],
	[20210518, [
		"česká a anglická verze PDF dodacího listu",
	]],
	[20210516, [
		"předání kódu zakázky jako REFERENCE-2 pro GW",
		"zobrazení času, jak dlouho sklad zpracovává požadavek",
		"základní statistiky automatických výdejů",
		"tisková PDF verze PD pro odeslání",
	]],
	[20210512, [
		"pohledávky: zobrazení účetního salda všech pohledávek partnera v mobilní aplikaci",
		"Credendo: rozlišení mezi nesplněním E1 podmínky a překročením limitu nepojištěných faktur",
		"požadavek na auto s hydraulickým čelem pro LF",
	]],
	[20210509, [
		"název partnera na PD pro SAMOODBĚR a HSHLOGISTIKA (zrušen název adresy)",
		"tolerance mezer v PRP9",
		"zjednodušen výstup kontrol pro PD s chybějícími položkami nebo neznámým skladem",
		"online synchronizace všech adres partnera při načítání PD",
		"řešení problému identifikace sérií s variabilním počtem mezer na konci kódu série",
		"online sledování probíhající komunikace se všemi sklady",
	]],
	[20210506, [
		"odesílání PD do LF a příjem odpovědí z LF",
		"kontrola obalové jednotky při vyskladnění",
	]],
	[20210427, [
		"oprava PRP9 po změnách zasílání dat do GW",
		"rozšíření přesnosti zasílaného množství na maximum dle dat v Twistu",
		"zobrazení stavu \"Exportováno\" a \"Schváleno\" v seznamu PD online",
	]],
	[20210426, [
		"podpora kódování UTF-8 i CP-1250 pro komunikaci se sklady",
		"zaslání informace SKL12 pro PD s ADR zbožím do skladu",
		"odeslání storna PD do LF, sledování stavu storna PD",
		"sledování stavu PD nezávisle na komunikaci (ochrana před dvojitým zasláním storna PD)",
	]],
	[20210419, [
		"formátování DELIVERY-DATE-FROM dle požadavku GW",
		"celočíselné dělení množství v položkách PD (dle skladových karet)",
	]],
	[20210418, [
		"rozlišení skladů pro PD",
		"detailní zobrazení komunikace se sklady",
		"automatická obnova po problémech s připojením k serveru skladu",
		"upozornění na ADR zboží",
		"úprava adres skladu pro HSHLOGISTIKA - GW+LF",
		"změna v datech pro GW - datum \"FROM\" nově vždy datum odeslání PD",
	]],
	[20210407, [
		"online synchronizace produktů, jejich jednotek a jednotek obalů",
		"použití skladového množství z položky PD i ze série výdejky (SV)",
	]],
	[20210329, [
		"oprava součtů pojistných limitů, pokud je chybně vyplněno FIČ",
		"varování při odeslání PD s dnešním datem (SAMOODBĚR, HSHLOGISTIKA)",
		"filtrování unikátních PRP kódů",
		"kontrola, zda je PD uzavřen, má přednost před kontrolou střediska, kategorie a zakázky",
		"pro HSHLOGISTIKA a SAMOODBĚR zobrazeno PRP9 jako \"Datum vyskladnění\"",
	]],
	[20210321, [
		"odesílání prodejních dodacích listů do GW",
		"základní sledování komunikace s GW",
		"kontrola prodejních dodacích listů před odesláním požadavku na automatický výdej",
		"online synchronizace prodejních dodacích listů včetně položek a textů a adres partnera",
		"nová role: vedoucí CSR, možnost vrácení či potvrzení neúspěšně exportovaného PD",
	]],
	[20210226, [
		"Credendo: řešení kolizí párovacích symbolů při přesunech faktur mezi partnery (autolimity)",
	]],
	[20210212, [
		"skladové karty pro LogFlex",
	]],
	[20210210, [
		"skladová karta jednotlivého produktu pro GW",
		"seznam nákupních objednávek",
		"zobrazení nákupní objednávky",
		"skladové karty produktu pro GW z nákupní objednávky",
	]],
	[20210128, [
		"oprava hlášení Credendo autolimitů (chybné řazení položek / nečitelný XLSX soubor)",
		"zrychlení výpočtu stavu pojištění u jmenovitých limitů Credendo",
		"dynamické načítání platební historie a historie salda partnera",
	]],
	[20210124, [
		"stav pojištění faktur v emailových notifikacích",
		"úprava formátu hlášení obratu - autolimity",
		"konfigurace skladů a komunikačních kanálu GW",
		"kompletní historie salda, historie pojištění a limitů",
		"sjednocení zobrazení prodlev a ratingů",
		"varování při spuštění v MS Internet Explorer",
		"poznámky k fakturám a partnerům: sjednocení vzhledu a zrychlení načítání",
	]],
	[20201224, [
		"unifikované hlavičky partnerů",
		"dynamické načítání historických faktur a dodacích listů (až do roku 2008)",
		"detail prodejních dodacích listů",
		"pojištění faktur: vyhodnocení autolimitů",
		"filtrování podle pojištěného stavu ve všech seznamech faktur",
		"hlášení obratu na autolimitech",
	]],
	[20201124, [
		"pojištění faktur: odložená účinnost a závazné dodávky",
		"hlášení obratu pro jmenovité limity, seznam hlášených faktur k archivaci",
		"vyhledávání pojistných limitů podle měny a stavu najednou",
		"časové omezení pro synchronizaci Credendo 2h",
		"sjednoceno stránkování ve všech seznamech",
		"základní seznam produktů",
		"přehled prodejních dodacích listů",
	]],
	[20201026, [
		"výhled (trend) ratingu partnera",
		"zobrazení měn limitů v hlavičce partnera",
		"notifikace faktur s hrozbou pojistné události jen s nenulovým dokladovým saldem",
		"zobrazení aktuálně pojištěného salda u všech partnerů",
		"výpočet celkového pojištěného salda v tuzemské měně",
		"FIČ ve shrnutí pojištění partnera",
		"první verze hlášení pojištěného obratu pro jmenovité limity v účetní měně",
	]],
	[20201019, [
		"sjednocení vyhledávání v partnerech, pohledávkách, fakturách, poznámkách a pojištění",
		"sledování dat a časů posledních synchronizací",
		"souhrny pojistných limitů podle měn",
		"výpis volných FIČ",
		"zobrazení zda je faktura pojištěná u platební historie a neuhrazených pohledávek partnera, ve výpisu faktur a v celkovém seznamu neuhrazených pohledávek",
		"rozlišení českého a slovenského prostředí v aplikaci i v notifikacích",
		"určení současných možností pojištění partnera u jmenovitých pojistných limitů",
	]],
	[20201001, [
		"úprava pohledu na seznam pojistných limitů",
		"detaily pojistných limitů partnera",
		"načítání speciálních podmínek (E1) pojistných smluv",
		"zobrazení historie ratingu partnera",
		"zrychlení importu databáze Twist, import celého účetního deníku (nově i 2017 a starší)",
	]],
	[20200913, [
		"synchronizace pojistných limitů Credendo",
		"zobrazení pojistných limitů partnera v základních informacích o partnerovi",
		"zobrazení kreditního ratingu partnera podle dat Credendo v seznamu partnerů a v základních informacích o partnerovi",
		"přehled pojistných limitů Credendo s vyhledáváním podle dat Twistu i Credendo",
		"nové pohledy na pohledávky v mobilní aplikaci",
		"jednotné zobrazení základních údajů partnera v hlavičce",
	]],
	[20200816, [
		"nový pohled na fakturu vydanou + mobilní verze",
		"upgrade serveru",
		"mazat a kopírovat poznámky lze nově jen pokud není aktivní formulář nové poznámky",
	]],
	[20200810, [
		"návod na instalaci na OS Android",
	]],
	[20200729, [
		"běh aplikace na iOS",
		"návod na instalaci na iOS (sekce \"nápověda\")",
	]],
	[20200723, [
		"oprava stahování PDF dokumentů po spuštění mobilní aplikace",
	]],
	[20200708, [
		"opraveno zarovnání součtů v tabulkách v emailových notifikacích",
		"zmírněna pravidla pro vynucené odhlašování",
		"možnost instalace mobilní aplikace",
	]],
	[20200602, [
		"zasílané notifikace nově obsahují informaci o zadané toleranci splatnosti",
		"výpis všech poznámek zobrazuje ke všem poznámkám jméno a kód partnera, kód dokladu je zobrazen jen u poznámek k fakturám",
		"možnost editovat poznámku jako novou",
	]],
	[20200528, [
		"opraveno generování PDF pro partnery s nekorektně vyplněnou adresou",
		"přidáno číslo smlouvy do detailu partnera a notifikací faktur v prodlení 70 a více dní",
		"při posílání týdenních notifikací se nevkládá denní či týdenní tabulka, která by byla prázdná",
	]],
	[20200526, [
		"odesílání notifikací překročení tolerance splatnosti nyní zohledňuje technickou toleranci plateb +3 dny",
		"přidány aktivní odkazy do emailových notifikací",
		"správa uživatelů - přehled pro administrátory",
		"týdenní notifikace faktur, které jsou stále po splatnosti déle než je tolerance",
	]],
	[20200519, [
		"oddělené šablony hlavičkových papírů v CZ/SK verzi",
		"odesílání denních notifikací o dlužných fakturách po splatnosti",
	]],
	[20200512, [
		"zobrazení středisek z položek ve výpisu dlužných faktur (pro faktury bez střediska na hlavičce)",
		"zrychlení načítání výpisu všech dlužných faktur",
		"samostatný hlavičkový papír pro slovenské PDF dokumenty",
		"opraveno přepínání hlavní nabídky při zobrazení partnera a/nebo faktury",
		"vyšší zabezpečení cookies sezení (přihlašování)",
		"vynucení šifrovaného (HTTPs) spojení pro všechna přihlášení",
		"pro PDF výstupy použito logo HSH v křivkách",
		"prohledávatelný výpis všech poznámek v systému",
		"možnost zneplatnění zadané poznámky",
	]],
	[20200505, [
		"možnost změny hesla uživatelem (dostupné z profilu uživatele vedle tlačítka odhlášení)",
		"PDF export výpisu neuhrazených faktur partnera",
		"součty salda v měně v přehledu neuhrazených faktur",
		"filtrování pohledávek bez vyplněného střediska hodnotou filtru " - "",
		"konfigurovatelná místní měna: CZK pro ČR, EUR pro SR",
		"výpis neuhrazených pohledávek podle účetního nebo dokladového salda",
	]],
	[20200421, [
		"poznámky k partnerům a fakturám",
		"úprava pohledu na partnera",
		"zjednodušení a pročištění platební historie a neuhrazených pohledávek - za partnera i celkových",
		"zobrazení poznámek k partnerům a fakturám v seznamu všech neuhrazených pohledávek",
		"zobrazení poznámek k fakturám v seznamech platební historie a neuhrazených pohledávek partnera",
		"jednoduchý pohled na fakturu vydanou",
		"možnost procházení vydaných faktur podle kódu dokladu",
	]],
	[20200407, [
		"přihlášení do systému",
		"seznam partnerů s hledáním podle kódu, názvu, IČ, DIČ, telefonu a emailové adresy",
		"základní pohled na partnera s výpisem kontaktů a adres",
		"platební historie partnera zahrnující zpoždění minulých plateb",
		"aktuální dlužné faktury partnera včetně prodlení s úhradou",
		"vývoj celkového salda a salda po splatnosti pro jednotlivé partnery",
		"výpis všech pohledávek s filtrací podle střediska, názvu partnera a minimálního dlužného salda na faktuře",
	]],
];

/** 
 * single release note which has header and bullet points. 
 *  
 * @param {array} note - array with number of release note and array with sentences
 * @return {component} - COMPONENT single release note
 */
function ReleaseNote({ note }) {
	return (
		<ErrorWrap>
			<h4>v{note[0]}</h4>
			<ul>
				{note[1].map((li, idx) => <li key={idx}>{String(li)}</li>)}
			</ul>
		</ErrorWrap>
	);
}

/** 
 * List of release notes. 
 *  
 * @param {array} notes - array of array with numerous release notes
 * @return {component} - COMPONENT list of release notes
 */
function ReleaseNotesList({ notes }) {
	return notes.map((note, idx) => <ReleaseNote key={idx} note={note} />);
}

/** 
 * Release notes component with new and old release notes, takes all release notes and splits them in two for old and new component. 
 *  
 * @param {array} notes - array of array with all release notes
 * @param {number} shown - default release number of notes shown
 * @return {component} - COMPONENT new and old expandable release notes
 */
function ReleaseNotes({ notes, shown = 5 }) {
	const { t } = useTranslation();

	const shown_notes = notes.slice(0, shown);
	const old_notes = notes.slice(shown);
	return (
		<Card body className='p-2'>
			<h3 className='mb-4'>{t('hp-rel_notes')}</h3>
			<ReleaseNotesList notes={shown_notes} />
			<OldNews notes={old_notes} />
		</Card>
	);
}

/** 
 * Homepage view with synchronization times and and release notes. 
 *  
 * @param {dictonary} userlogin - info about logged user
 * @return {component} - COMPONENT homepage cards
 */
export function Home({ userlogin }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Row>
				<Col md={6} className="mb-3">
					<Card body className='p-2'>
						<h2 className='mb-3'>{t('hp-welcome')}</h2>
						<HISInfo userlogin={userlogin} />
					</Card>
				</Col>
				<Col md={6} className="mb-3">
					<ReleaseNotes notes={release_notes} />
				</Col>
			</Row>
		</ErrorWrap>
	);
}

/** 
 * Old expandable release notes. 
 *  
 * @param {array} notes - array of old notes
 * @return {component} - COMPONENT old release notes with expand button
 */
function OldNews({ notes }) {
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	const handleDoExpand = () => {
		setExpanded(true);
	}

	const handleDoCollapse = () => {
		setExpanded(false);
	}

	if (expanded) {
		return (
			<ErrorWrap>
				<ReleaseNotesList notes={notes} />
				<Button onClick={handleDoCollapse}>{t('hp-btn_hide')}</Button>
			</ErrorWrap>
		);
	}
	return <Button onClick={handleDoExpand}>{t('hp-btn_older')}...</Button>;
}
