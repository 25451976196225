
import { RequestErrorWithCode } from './lib/fetcher';

export const queryOptions = {
    cacheTime: 0,
    retry(failureCount, error) {
        if (error instanceof RequestErrorWithCode) {
            return false;
        }

        return failureCount < 3; // Retry up to 3 times
    }
}