import React, { useState, useEffect } from "react";
import { Table, Form, Row, Col, Alert } from "react-bootstrap";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { preprocess_stocks_data } from './product-stocks';
import { merge_warehouse_with_twist_stocks } from './product-inventory-magic';
import { warehouses_names_ids_separe } from '../lists/warehouses-defs';
import { Pager } from '../comp/pager';
// import { BooleanDropdown } from '../comp/boolean';
import { filter_rule, icompare } from "../lib/utils";
import { ItemsAndFiltered } from "../comp/dynamic-load";
import { date_time_format } from "../lib/date-utils";
import { format_amount } from "../lib/format";
import { MultipleSelect } from '../comp/multiple-select';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from "react-router-dom";
import { date_getYesterday, date_formatISO } from '../lib/date-utils';
import { SortIcons } from '../comp/sort';
import { useTranslation } from 'react-i18next';
import { hilite } from "../lib/format";

//seen all - OK

const statuses = ["danger", "warning", ""];

/**
 * Table of inventory to compare Twist and warehouse data
 * 
 * @param {*} userlogin - info about logged user
 * @param {*} warehouse - choosen warehouse to fetch
 * @param {*} fetchDate - effective date
 * @returns {component}
 */
export function ProductInventoryTwistWarehouse({ userlogin, warehouse, fetchDate }) {
    const { t } = useTranslation();

    //here for translation usage
    const statusesTitles = { "danger": " ❌ " + t('prod-incorrect_amount'), "warning": "❌ " + t('prod-incorrect_batch'), "": "✅ OK" };

    const TwistDate = useParams().twistDate;
    //console.log(TwistDate);
    //console.log(fetchDate);

    const dictionaryOStatusHValues = statuses.reduce((acc, v) => ({ ...acc, [v]: true }), {});
    const dictionaryOfStatusValuesFalse = statuses.reduce((acc, v) => ({ ...acc, [v]: false }), {});

    const [warehouseData, setWarehouseData] = useState(null);
    const [updateTime, setUpdateTime] = useState("");
    const [twistData, setTwistData] = useState(null);
    const [loadedStatus1, setLoadedStatus1] = useState(0);
    const [loadedStatus2, setLoadedStatus2] = useState(0);
    const [offset, setOffset] = useState(0);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterIdCodeName, setFilterIdCodeName] = useState("");
    const [filterBatch, setFilterBatch] = useState("");
    const [filterBatchKgTwist, setFilterBatchKgTwist] = useState("");
    const [filterBatchKgWH, setFilterBatchKgWH] = useState("");
    const [filterTwistSum, setFilterTwistSum] = useState("");
    const [filterWHSum, setFilterWHSum] = useState("");
    const [checkedStatus, setCheckedStatus] = useState(dictionaryOStatusHValues);
    const [sortValue, setSortValue] = useState("");
    const [direction, setDirection] = useState("up");
    const [type, setType] = useState("");

    const fetchDateTwist = TwistDate === "todaysTwist" ? fetchDate : date_formatISO(date_getYesterday(fetchDate));

    //console.log(loadedStatus1);
    //console.log(loadedStatus2);

    useEffect(() => {
        if (warehouseData === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stocks/records/" + warehouse + "/" + fetchDate,
                        json: true,
                        status: setLoadedStatus1,
                        ok: function (resource, result) {
                            //console.log(result);
                            setWarehouseData(result.records);
                            setUpdateTime(result.snapshot_created || "");
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setWarehouseData("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        } else {
                const second_fetch = his_fetch(
                    userlogin,
                    [
                        {
                            uri: "/api/stocks/twist/" + warehouse + "/" + fetchDateTwist,
                            json: true,
                            status: setLoadedStatus2,
                            ok: function (resource, result) {
                                //console.log(result);
                                setTwistData(result.records);
                            },
                            error: function (resource, reason) {
                                console.log('err: ' + reason);
                                setTwistData("error");
                            }
                        }
                    ]
                )
                return () => {
                    second_fetch();
                }
            
        }
    }, [userlogin, warehouse, fetchDate, fetchDateTwist, warehouseData]);

    //na warehouseData záleží, jestli se má přerenderovat ta komponenta 
    // nemůžu používat zřetězený fetcher na data, na kterých něco závisí 


    if (!his_fetch_success(loadedStatus1)) {
        return <HisFetchStatus status={loadedStatus1} loadingType="big" errorType="fetcherError" reloadButton={() => setWarehouseData(null)} />;
    }
    if (!his_fetch_success(loadedStatus2)) {
        return <HisFetchStatus status={loadedStatus2} loadingType="big" errorType="fetcherError" reloadButton={() => setTwistData(null)} />;
    }

    const cleanFilters = () => {
        setFilterStatus(null);
        setCheckedStatus(dictionaryOStatusHValues);
        setFilterIdCodeName("");
        setFilterBatch("");
        setFilterBatchKgTwist("");
        setFilterBatchKgWH("");
        setFilterTwistSum("");
        setFilterWHSum("");
        setSortValue("");
        setDirection("up");
        setType("");
    }

    const handleIdCodeName = (e) => {
        setFilterIdCodeName(e.target.value);
        setOffset(0);
    }
    const handleBatch = (e) => {
        setFilterBatch(e.target.value);
        setOffset(0);
    }
    const handleSumTwist = (e) => {
        setFilterTwistSum(e.target.value);
        setOffset(0);
    }
    const handleSumWH = (e) => {
        setFilterWHSum(e.target.value);
        setOffset(0);
    }
    const handleBatchKgTwist = (e) => {
        setFilterBatchKgTwist(e.target.value);
        setOffset(0);
    }
    const handleBatchKgWH = (e) => {
        setFilterBatchKgWH(e.target.value);
        setOffset(0);
    }


    if (warehouseData.length === 0) {
        return (
            <>
                <p className="text-secondary mt-3">{t('prod-last_update_of_wh_data')}: {updateTime ? date_time_format(updateTime) : ""}</p>
                <Alert variant="danger">{t('prod-cannot_make_inventory_no_wh_data')}.</Alert>
            </>
        );
    }
    if (twistData.length === 0) {
        return <Alert variant="danger">{t('prod-cannot_make_inventory_no_twist_data')}.</Alert>;
    }

    const wd2 = preprocess_stocks_data(warehouseData, "warehouses");
    const td2 = preprocess_stocks_data(twistData, "kočička");
    const mashup = merge_warehouse_with_twist_stocks(td2, wd2);

    /*
    console.log(warehouseData);
    console.log(twistData);
    console.log(wd2);
    console.log(td2);
    console.log("================================");
    console.log(mashup);
    */


    const data_filtered = mashup.filter(
        function (rec) {
            return (
                ((filterStatus === null) ||
                    (filterStatus === rec.problem)) &&
                ((filter_rule(filterIdCodeName, rec.IDProduktu, true)) ||
                    (filter_rule(filterIdCodeName, rec.product_name, true)) ||
                    (filter_rule(filterIdCodeName, rec.product_code, true))) &&
                ((rec.batches.reduce((acc, batchline) => acc || (filter_rule(filterBatch, batchline.twist.alf_batch || null, true)), false)) ||
                    (rec.batches.reduce((acc, batchline) => acc || (filter_rule(filterBatch, batchline.warehouse.alf_batch || null, true)), false))
                ) &&
                (rec.batches.reduce((acc, batchline) => acc || icompare(batchline.twist.alf_batch_weight_kg, filterBatchKgTwist), false)) &&
                (rec.batches.reduce((acc, batchline) => acc || icompare(batchline.warehouse.alf_batch_weight_kg, filterBatchKgWH), false)) &&
                // zreducovaný or a začínám na false - batchline.twist.něco či batch-while.něco porovnávám uvnitř toho reducu, reduce přes batches
                icompare(rec.super_sum_kg_twist, filterTwistSum) &&
                icompare(rec.super_sum_kg_warehouse, filterWHSum) &&
                (checkedStatus[rec.stocks_check_status])
            );
        }
    );
    //if (data_filtered.length === 1) {
    //console.log(data_filtered);
    //}

    const data_sorted = data_filtered.sort(function (a, b) {
        if (type === "num") {
            if (direction === "up") {
                return a[sortValue] - b[sortValue];
            }
            if (direction === "down")
                return b[sortValue] - a[sortValue];
        }
        return 0;
    });

    const show_mashup = data_sorted.slice(offset, offset + 10);
    //console.log(show_mashup);

    return (
        <>
            <Row>
                <Col>
                    <h4 className="mb-3">{t('prod-wh_inventory')} {warehouses_names_ids_separe[warehouse]}</h4>
                    <p className="text-secondary">{t('prod-last_update_of_wh_data')}: {date_time_format(updateTime)}</p>
                    <Form.Group controlId="formBasicCheckbox">
                        <LinkContainer to={"/products/stocks/" + fetchDate + "/inventory/" + warehouse + (TwistDate === "todaysTwist" ? "/yesterdaysTwist" : "/todaysTwist")}>
                            <Form.Check readOnly type="checkbox" checked={TwistDate === "yesterdaysTwist"} label={t('prod-yesterdays_twist')} />
                        </LinkContainer>
                    </Form.Group>

                </Col>
                <Col className="text-center"><ItemsAndFiltered filtered_data={data_filtered} data={mashup} cleanFilters={cleanFilters} /></Col>
                <Col><Pager offset={offset} pagesize={10} total={data_filtered.length} callback={setOffset} /></Col>
            </Row>

            <Table size="sm">
                <thead className="beGray">
                    <tr>
                        <th rowSpan={3}>
                            <p className="mb-2">{t('state')}</p>
                            <MultipleSelect checked={checkedStatus} setChecked={setCheckedStatus}
                                dictionaryTrue={dictionaryOStatusHValues} dictionaryFalse={dictionaryOfStatusValuesFalse}
                                itemsNames={statusesTitles} setOffset={setOffset} id="filterWH" withoutNumber width="300px" />
                            {/** <BooleanDropdown variant="product-checks" onChange={setFilterStatus} value={filterStatus} /> */}
                        </th>
                        <th rowSpan={3}>
                            <Form.Group controlId="filterIDCodeName" className="mb-0">
                                <Form.Label>{t('product')}&nbsp;
                                    <SortIcons name={"IDProduktu"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType} numeric />
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269;" onChange={handleIdCodeName} value={filterIdCodeName} />
                            </Form.Group>
                        </th>
                        <th colSpan={7} className="text-center">{t('prod-found_batches')}</th>
                        <th rowSpan={2} colSpan={4} className="text-center">{t('prod-product_mj')}</th>
                        <th rowSpan={2} colSpan={2} className="text-center">{t('prod-product_KG')}</th>
                    </tr>
                    <tr>
                        <th rowSpan={2}>
                            <Form.Group controlId="filterBatch" className="mb-0">
                                <Form.Label>{t('ord-batch')}</Form.Label>
                                <Form.Control type="text" placeholder="&#128269;" onChange={handleBatch} value={filterBatch} />
                            </Form.Group>
                        </th>
                        <th colSpan={4} className="text-center">{t('prod-amount_MJ')}</th>
                        <th colSpan={2} className="text-center">{t('prod-weight_kg')}</th>

                    </tr>
                    <tr>
                        <th colSpan={2} className="text-center">{t('warehouse')}</th>
                        <th colSpan={2} className="text-center">{t('prod-twist')}</th>
                        <th className="text-center">{t('warehouse')}
                            <Form.Group controlId="filterBatchKgTwist" className="mb-0" >
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterBatchKgWH} onChange={handleBatchKgWH} />
                            </Form.Group>
                        </th>
                        <th className="text-center">{t('prod-twist')}
                            <Form.Group controlId="filterBatchKgWH" className="mb-0" >
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterBatchKgTwist} onChange={handleBatchKgTwist} />
                            </Form.Group>
                        </th>
                        <th colSpan={2} className="text-center">{t('warehouse')}</th>
                        <th colSpan={2} className="text-center">{t('prod-twist')}</th>
                        <th className="text-center">{t('warehouse')}
                            <Form.Group controlId="filterSumWH" className="mb-0" >
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterWHSum} onChange={handleSumWH} />
                            </Form.Group>
                        </th>
                        <th className="text-center">{t('prod-twist')}
                            <Form.Group controlId="filterSumTwist" className="mb-0" >
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterTwistSum} onChange={handleSumTwist} />
                            </Form.Group>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {show_mashup.map(function (rec, idx) {
                        const classSumKG = rec.super_sum_kg_ok !== true ? "alert-danger" : "";
                        const wholeProductClass = "alert-" + rec.stocks_check_status;
                        const bg = (idx % 2) === 0 ? "" : "beGray3";
                        const problem_classa = !rec.stocks_check_status ? "bg-success" : "bg-" + rec.stocks_check_status;
                        return (
                            <React.Fragment key={idx}>
                                {
                                    rec.batches.map(function (batchLine, idm) {
                                        const classBatchKG = batchLine.sum_kg_ok !== true ? "alert-danger" : "";
                                        const backlightTwistKg = filterBatchKgTwist.length > 0 ? icompare((batchLine.twist || {}).alf_batch_weight_kg, filterBatchKgTwist) ? "bg-info-light" : "" : "";
                                        const backlightWHKg = filterBatchKgWH.length > 0 ? icompare((batchLine.warehouse || {}).alf_batch_weight_kg, filterBatchKgWH) ? "bg-info-light" : "" : "";
                                        const myRowSpan = rec.batches.length;
                                        return (
                                            <React.Fragment key={idm}>
                                                <tr className={bg} key={idm}>
                                                    {idm === 0 ?
                                                        <>
                                                            <td rowSpan={myRowSpan} className={problem_classa + " align-middle text-center"}>{rec.problem ? <span className='bg-light p-1'>❌</span> : "✅"}</td>
                                                            <td rowSpan={myRowSpan} className={wholeProductClass + " align-middle text-center "}>
                                                                {rec.IDProduktu}
                                                                <br />
                                                                {rec.product_name}
                                                            </td>
                                                        </>
                                                        : <></>
                                                    }
                                                    <td className="align-middle ">
                                                        {!batchLine.twist.alf_batch ? // if there is no twist batch, show warehouse batch
                                                            filterBatch.length > 0 ? hilite(batchLine.warehouse.alf_batch || "", filterBatch) : batchLine.warehouse.alf_batch
                                                            :
                                                            filterBatch.length > 0 ? hilite(batchLine.twist.alf_batch || "", filterBatch) : batchLine.twist.alf_batch
                                                        }
                                                    </td>
                                                    <td className="align-middle text-end">{format_amount(batchLine.warehouse.alf_batch_sum)}</td>
                                                    <td className="align-middle">{batchLine.warehouse.alf_package_unit}</td>
                                                    <td className={"align-middle text-end"}>{format_amount(batchLine.twist.alf_batch_sum)}</td>
                                                    <td className="align-middle">{batchLine.twist.alf_package_unit}</td>
                                                    <td className={classBatchKG + " align-middle text-end "}>
                                                        <span className={backlightWHKg}>{batchLine.warehouse.alf_batch_weight_kg === "Chyba" ? "Chyba" : format_amount(batchLine.warehouse.alf_batch_weight_kg)} {t('prod-kg')}</span>
                                                    </td>
                                                    <td className={classBatchKG + " align-middle text-end "}>
                                                        <span className={backlightTwistKg}>{batchLine.twist.alf_batch_weight_kg === "Chyba" ? "Chyba" : format_amount(batchLine.twist.alf_batch_weight_kg)} {t('prod-kg')}</span>
                                                    </td>
                                                    {idm === 0 ?
                                                        <>
                                                            <td rowSpan={myRowSpan} className={" align-middle text-end "}>
                                                                {isNaN(rec.super_sum_kg_warehouse) ? "Chyba" : format_amount(parseFloat(rec.super_sum_mj_warehouse))}
                                                            </td>
                                                            <td rowSpan={myRowSpan} className={" align-middle "}>
                                                                {rec.package_unit}
                                                            </td>
                                                            <td rowSpan={myRowSpan} className={" align-middle text-end "}>
                                                                {isNaN(rec.super_sum_kg_warehouse) ? "Chyba" : format_amount(parseFloat(rec.super_sum_mj_twist))}
                                                            </td>
                                                            <td rowSpan={myRowSpan} className={" align-middle "}>
                                                                {rec.package_unit}
                                                            </td>
                                                            <td rowSpan={myRowSpan} className={classSumKG + " align-middle text-end "}>
                                                                {isNaN(rec.super_sum_kg_warehouse) ? "Chyba" : format_amount(parseFloat(rec.super_sum_kg_warehouse))} kg
                                                            </td>
                                                            <td rowSpan={myRowSpan} className={classSumKG + " align-middle text-end "}>
                                                                {isNaN(rec.super_sum_kg_twist) ? "Chyba" : format_amount(parseFloat(rec.super_sum_kg_twist))} kg
                                                            </td>
                                                        </>
                                                        : <></>
                                                    }
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </>
    )
}