/**
 * Module handling making checks for delivery-notes, products, orders purchase, etc..
 * @module comp/checker
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';

const boldErrorTexts = ["expired_goods"];
/**
 * Set of functions, that operate checking of values
 * 
 * @returns {dictionary} - dictionary of functions
 */
export function make_checker() {
	var last_badge_idx = 0;
	var todo = [];
	var check_results = {};

	function getBadgeIdx() {
		last_badge_idx = last_badge_idx + 1;
		return last_badge_idx;
	}

	function performCheck(tag, variant, check) {
		// Check must return false if everything is OK
		if (!check_results[tag]) {
			const result = check();
			if (result) {
				const idx = getBadgeIdx();
				// Number for badge, result as additional info, variant for badge
				check_results[tag] = [idx, variant, result]
				todo.push([idx, variant, result]); // Number and additional information for checkup list
			}
		}
	}

	function ErrorBadge({ tag }) {
		if (check_results[tag]) {
			const idx = check_results[tag][0];
			const variant = check_results[tag][1];
			return (
				<Badge id={idx} style={{ marginRight: "1ex", marginLeft: "1ex", scrollMarginTop: "100px" }} pill bg={variant}
					title={check_results[tag][2]}>{idx}</Badge>
			);
		}
		return <></>;
	}

	function TodoList() {
		const { t } = useTranslation();

		function get_color(text) {
			return todo.filter((line) => line[2] === text)[0][1];
		}
		function get_indexes(text) {
			return todo.filter((line) => line[2] === text).map((line) => line[0]);
		}

		//console.log(todo);
		const unique_texts = todo.map((line) => line[2]).filter((v, i, a) => a.indexOf(v) === i).map((text) => [text]);
		//console.log(unique_texts);


		const my_todo = unique_texts.map((newLine) => newLine.concat(get_color(newLine[0])).concat([get_indexes(newLine[0])]));
		//console.log(my_todo);

		//console.log(boldErrorTexts.includes("expired_goods"));

		/**
		 * to see the result, comment lines 193-200 v problem-card.js, testovat lze na NO202311000001 
		 * pořešit pak ještě tučné nadpisy, viz check na expiraci
		 */

		return (
			<>
				{my_todo.map(function (el, idx) {
					//console.log(el[2])
					return (
						<div key={idx}>
							{el[2].length > 1 ?
								<>
									{el[2].map((index) => {
										return (<a className='toDoLink' href={"#" + index} key={index}><Badge className='me-1' pill bg={el[1]}>{index}</Badge></a>)
									})}
									{boldErrorTexts.includes(el[0]) ? <span className='fw-bold text-danger'>{t(el[0])}</span> : t(el[0])}
								</>
								:
								<a className='toDoLink' href={"#" + el[2][0]}>
									{el[2].map((index) => <Badge className='me-1' pill bg={el[1]} key={index}>{index}</Badge>)}
									&nbsp;{t(el[0])} </a>
							}

						</div>
					);
				})}

			</>
		);
	}

	//
	// Returns true if there are no "danger" check results, or no worse than warning, if second param is choosen
	function checksOK(pattern = false, seriousness = "danger") {
		if (pattern) {
			pattern = new RegExp(pattern);
		}
		return Object.keys(check_results)
			.filter((tag) => (pattern === false) || (tag.match(pattern)))
			.map((k) => check_results[k])
			.filter((r) => r[1] === seriousness)
			.length === 0;
	}

	//
	// Returns the worst variant of checks that did not pass
	function worstCheckResult(pattern = false) {
		if (pattern) {
			pattern = new RegExp(pattern);
		}
		var worst_result = "success";
		Object.keys(check_results)
			.filter((tag) => (pattern === false) || (tag.match(pattern)))
			.map(function (k) {
				const check_result = check_results[k];
				if (worst_result === "success") {
					if (check_result[1] === "warning") {
						worst_result = "warning";
					}
				}
				if (check_result[1] === "danger") {
					worst_result = "danger";
				}
				return worst_result; // added to solve warning
			});
		return worst_result;
	}

	return {
		performCheck: performCheck,
		ErrorBadge: ErrorBadge,
		TodoList: TodoList,
		checksOK: checksOK,
		worstCheckResult: worstCheckResult,
	};
}
