/**
 * Partners payment discipline view.
 * @module partners/payments-discipline
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useEffect, useCallback } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus, his_fetch_error, his_fetch_loading } from '../comp/FetchLoader';
import Table from 'react-bootstrap/Table';
import { Pager } from '../comp/pager';
import Form from 'react-bootstrap/Form';
import { LinkContainer } from 'react-router-bootstrap';
import { format_amount } from '../lib/format';
import { filter_rule, icompare } from '../lib/utils';
import { delay_color } from '../lists/colors';
import { Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Downloader } from '../comp/downloader';
import { SortIcons } from '../comp/sort';
import { ErrorWrap } from '../comp/errorwrap';
import { ItemsAndFiltered } from '../comp/dynamic-load';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PaymentsDiscipline({ userlogin, morale, setMorale, loadedStatus, setLoadedStatus, offset,
    setOffset, filterCode, setFilterCode, filterName, setFilterName, filterCount, setFilterCount, filterDays,
    setFilterDays, filterTolerance, setFilterTolerance, filterBalance, setFilterBalance, filterDelay, setFilterDelay,
    sortValue, setSortValue, direction, setDirection, type, setType, months, setMonths, monthsFetch, setMonthsFetch,
    filterBalanceDelayed, setFilterBalanceDelayed
}) {
    const { t } = useTranslation();

    const loadMore = useCallback(function () {
        if (months !== monthsFetch) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/payment-morale/" + months,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setMonthsFetch(months);
                            setMorale(result.morale);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, months, monthsFetch, setMonthsFetch, setMorale, setLoadedStatus])

    const cleanFilters = () => {
        setFilterCode("");
        setFilterName("");
        setFilterCount("");
        setFilterDays("");
        setFilterTolerance("");
        setFilterBalance("");
        setFilterBalanceDelayed("");
        setFilterDelay("");
        setSortValue("");
        setDirection("");
        setType("");
    }

    useEffect(() => {
        loadMore();
    }, [loadMore]);

    if (his_fetch_error(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={loadMore} />;
    }

    const handleChangeCode = function (event) {
        setFilterCode(event.target.value);
        setOffset(0);
    }
    const handleChangeName = function (event) {
        setFilterName(event.target.value);
        setOffset(0);
    }

    const handleChangeCount = function (event) {
        setFilterCount(event.target.value);
        setOffset(0);
    }

    const handleChangeDays = function (event) {
        setFilterDays(event.target.value);
        setOffset(0);
    }

    const handleChangeTolerance = function (event) {
        setFilterTolerance(event.target.value);
        setOffset(0);
    }

    const handleChangeBalance = function (event) {
        setFilterBalance(event.target.value);
        setOffset(0);
    }

    const handleChangeBalanceDelayed = function (event) {
        setFilterBalanceDelayed(event.target.value);
        setOffset(0);
    }

    const handleChangeDelay = function (event) {
        setFilterDelay(event.target.value);
        setOffset(0);
    }

    const handleCountChange = function (ev) {
        setMonths(parseInt(ev.target.value));
    }


    const morale_filtered = morale.filter(
        function (mo) {
            return (
                filter_rule(filterCode, mo.KodPartnera, true) &&
                filter_rule(filterName, mo.NazevPartnera, true) &&
                icompare(mo.pocet_faktur, filterCount) &&
                icompare(mo.splatnost_dny, filterDays) &&
                icompare(mo.tolerance, filterTolerance) &&
                icompare(mo.ucetni_saldo, filterBalance) &&
                icompare(mo.saldo_late, filterBalanceDelayed) &&
                icompare(mo.zpozdeni_dny, filterDelay)
            );
        }
    )

    const morale_sorted = morale_filtered.sort(function (a, b) {
        if (type === "letters") {
            if (direction === "up") {
                var nameA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
                var nameB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0; //tady je to legitimní, když A není větší než B a naopak, tak tak rovnost je 0
            }
            if (direction === "down") {
                var nameAA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
                var nameBB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
                if (nameAA > nameBB) {
                    return -1;
                }
                if (nameAA < nameBB) {
                    return 1;
                }
                return 0;
            }
        }
        if (type === "num") {
            if (direction === "up") {
                return a[sortValue] - b[sortValue];
            }
            if (direction === "down")
                return b[sortValue] - a[sortValue];
        }
        return 0;
    });

    const morale_list = morale_sorted.slice(offset, offset + 20);

    //const pocet_faktur = morale_sorted.reduce((acc, v) => acc + v.pocet_faktur, 0);
    //console.log(pocet_faktur);

    return (
        <ErrorWrap>
            <Row className="g-0">
                <Col xs="auto"> <h4 className='d-inline'>{t('biz-morale_for_last')} {monthsFetch} {t('months_(4p)')}</h4></Col>
                <Col className='mx-3'>
                    {monthsFetch === 0 ? "" :
                        <Downloader img="/application-excel.svg" alt="pdf" label={t('biz-morale_for') + " " + monthsFetch + " " + t('months_(4p)')}
                            path={"/api/payment-morale/" + monthsFetch + "/xlsx"} height="25"
                            filename={"payments-morale-last-" + monthsFetch + "-months.xlsx"} />
                    }
                </Col>
                <Col>
                    <ItemsAndFiltered filtered_data={morale_filtered} data={morale} cleanFilters={cleanFilters} />
                </Col>
                <Col xs="auto" className='text-end'>
                    <Form.Group controlId="months-count" style={{ maxWidth: "220px" }}>
                        <InputGroup size='sm'>
                            <Button variant="light" className="border" disabled={months < 2} onClick={() => setMonths(months - 1)} >–</Button>
                            <Form.Control type="text" disabled value={t('last') + " " + months + " " + t('months_(4p)')} onChange={handleCountChange} />
                            <Button variant="light" className="border" onClick={() => setMonths(months + 1)}>+</Button>
                        </InputGroup>
                    </Form.Group>

                </Col>
                <Col xs="auto" className='text-start'>
                    {!his_fetch_success(loadedStatus) ?
                        <Button style={{ width: "110px" }} className="ms-2 mb-1" variant="primary" size="sm" disabled>
                            <HisFetchStatus status={loadedStatus} loadingType="Načítání" />
                        </Button>
                        :
                        <Button style={{ width: "110px" }} className="ms-2 mb-1" variant="primary" size="sm" onClick={loadMore}>{t('show')}</Button>}
                </Col>
            </Row>

            <Pager offset={offset} pagesize={20} total={morale_filtered.length} callback={setOffset} />
            <Table striped size="sm">
                <thead className='beGray'>
                    <tr>
                        <th width="15%" className="text-center">
                            <Form.Group controlId="filterCode" className='mb-2'>
                                <Form.Label className="pb-1">
                                    <div className='mb-2 d-inline'>{t('biz-partner_code')}  &nbsp;</div>
                                    <SortIcons name={"KodPartnera"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType}
                                    />
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; " value={filterCode} onChange={handleChangeCode} />
                            </Form.Group>
                        </th>
                        <th width="15%" className="text-center">
                            <Form.Group controlId="filterName" className='mb-2'>
                                <Form.Label className="pb-1">{t('biz-partner_name')}  &nbsp;
                                    <SortIcons name={"NazevPartnera"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType}
                                    />
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; " value={filterName} onChange={handleChangeName} />
                            </Form.Group>
                        </th>
                        <th width="13%" className='text-center'>
                            <Form.Group controlId="filterCount" className="mb-0">
                                <Form.Label className="pb-1">{t('biz-inv_count')} &nbsp;
                                    <SortIcons name={"pocet_faktur"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType} numeric
                                    />
                                </Form.Label>
                                <Form.Control className="mb-2" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterCount} onChange={handleChangeCount} />
                            </Form.Group>
                        </th>

                        <th width="10%" className='text-center'><Form.Group controlId="filterDays" className="mb-0">
                            <Form.Label className="pb-1">{t('biz-due_in_days')} &nbsp;
                                <SortIcons name={"splatnost_dny"} sortValue={sortValue} setSortValue={setSortValue}
                                    direction={direction} setDirection={setDirection} setType={setType} numeric
                                />
                            </Form.Label>
                            <Form.Control className="mb-2" type="text"
                                placeholder="&#128269; > < ="
                                value={filterDays} onChange={handleChangeDays} />
                        </Form.Group>
                        </th>
                        <th width="10%" className='text-center'>
                            <Form.Group controlId="filterTolerance" className="mb-0">
                                <Form.Label className="pb-1">{t('biz-tolerance')} &nbsp;
                                    <SortIcons name={"tolerance"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType} numeric
                                    />
                                </Form.Label>
                                <Form.Control className="mb-2" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterTolerance} onChange={handleChangeTolerance} />
                            </Form.Group>
                        </th>
                        <th width="10%" className='text-end pe-4'>
                            <Form.Group controlId="filterBalance" className="mb-0">
                                <Form.Label className="pb-1">{t('biz-acc_balance')} &nbsp;
                                    <SortIcons name={"ucetni_saldo"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType} numeric
                                    />
                                </Form.Label>
                                <Form.Control className="mb-2" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterBalance} onChange={handleChangeBalance} />
                            </Form.Group>
                        </th>
                        <th width="10%" className='text-end pe-4'>
                            <Form.Group controlId="filterDebt" className="mb-0">
                                <Form.Label className="pb-1">{t('biz-after_due_balance')}&nbsp;
                                    <SortIcons name={"saldo_late"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType} numeric
                                    />
                                </Form.Label>
                                <Form.Control className="mb-2" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterBalanceDelayed} onChange={handleChangeBalanceDelayed} />
                            </Form.Group>
                        </th>
                        <th width="10%" className='text-center'>
                            <Form.Group controlId="filterDelay" className="mb-0">
                                <Form.Label className="pb-1">{t('biz-delay_in_days')} &nbsp;
                                    <SortIcons name={"zpozdeni_dny"} sortValue={sortValue} setSortValue={setSortValue}
                                        direction={direction} setDirection={setDirection} setType={setType} numeric
                                    />
                                </Form.Label>
                                <Form.Control className="mb-2" type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterDelay} onChange={handleChangeDelay} />
                            </Form.Group>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        morale_list.map(function (mo) {
                            const style = { backgroundColor: delay_color(mo.zpozdeni_dny) };
                            return (
                                <tr key={mo.KodPartnera}>
                                    <td>
                                        <LinkContainer to={{ pathname: "/partners/list/" + mo.KodPartnera + "/detail" }}>
                                            <a href="!#">{mo.KodPartnera}</a>
                                        </LinkContainer>
                                    </td>
                                    <td>{mo.NazevPartnera}</td>
                                    <td className='text-center'>{mo.pocet_faktur}</td>
                                    <td className='text-center'>{mo.splatnost_dny}</td>
                                    <td className='text-center'>{mo.tolerance}</td>
                                    <td className='text-end pe-4'>{format_amount(mo.ucetni_saldo)}</td>
                                    <td className='text-end'>{format_amount(mo.saldo_late)}</td>
                                    <td className='text-center' style={style}>{mo.zpozdeni_dny}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table >
            <p className='text-center'>
                {his_fetch_loading(loadedStatus) ?
                    <HisFetchStatus status={loadedStatus} loadingType="big" />
                    : ""}
            </p>
        </ErrorWrap>
    );
}

