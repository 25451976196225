import { PublicClientApplication } from "@azure/msal-browser";
import { acquireTokenOrRedirect } from "../msal";

let msalInstance: PublicClientApplication = undefined;

export function setMsalInstance(instance: PublicClientApplication) {
  msalInstance = instance;
}

export async function authorizedFetch<T>(url: string, options: RequestInit): Promise<T> {
  const response = await acquireTokenOrRedirect(msalInstance).then((token) => {
    const o: RequestInit = {
      cache: "no-cache",
      ...options,
      headers: {
        ...(options.headers ? options.headers : {}),
      },
    };
    o.headers["Authorization"] = "Bearer " + token;
    return fetch(url, o);
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return (await response.json()) as T;
}

export type LoadedStatus = 0 | 1 | 2 | 3 | 4;

export function queryStatusToLoadedStatus(
  queryStatus: "pending" | "error" | "success",
  isFetching: boolean
): LoadedStatus {
  switch (queryStatus) {
    case "pending":
      return 1;
    case "error":
      return 3;
    case "success":
      return isFetching ? 1 : 2;
  }
}
