/**
* Definition of possible states from progress path in case of delivery notes (PD)
* @module orders/progress-delivery-note-def
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import { greenish, redish, yellowish } from '../lists/colors';

/** CO JSOU FINÁLNÍ STAVY
 * chyba v PDF sem nepatří, protože to chceme vidět v trackingu
 * 4 CSV zamítnuto (musej vyrobit nový dodák)
 * 8 CSV ručně zrušeno
 * chyba v CSV (musej pravit PD ve spolupráci s D, skladem...) - není BE stav!!!!!
 * 11 storno potvrzeno
 * 13 storno ručně potvrzeno
 * 20 předáno zákazníkovi/dopravci
 */


export const final_states = [4, 8, 11, 13, 20, 31];

export const storno_allowed_states = [5, 12, 28, 17];    //CSV potvrzeno, CSV ručně potvrzeno, Chyba v PDF, PDF vyzvednuto

//specification started //numbers will by specified by backend I guess? 
export const delivery_note_progress_specification = [

    // 1nd position
    {
        name: "generating_deno",
        number: 25,
        position: 1,
        color: greenish,
        standard: true,
        timer: null, //nezasekne se, případně řeší technik
    },
    {
        name: "sending_deno",
        number: 1,
        position: 1,
        color: greenish,
        standard: false,
        timer: 5 * 60, // 5min // třeba techniky, žádné ruční akce
    },
    {
        name: "deno_uploaded",
        number: 2,
        position: 1,
        color: greenish,
        standard: false,
        timer: 5 * 60, // 5min // třeba techniky, žádné ruční akce
    },
    {
        name: "deno_picked",
        number: 3,
        position: 1,
        color: greenish,
        standard: false,
        timer: 15 * 60, // 15min
        // tam už mám magicy - ručně potvrdit a zrušit, co odsud 31 - ne, ať si to odklikaj přes ručně potvrdit 
    },
    {
        name: "deno_data_error", //v tento moment by to mělo jít poslat znova - budeme chtít aby se proces nastartoval znova - retry, stejně jako retry u PDF
        number: 26,
        position: 1,
        color: redish,
        standard: false,
        timer: null, //koncový stav
    },
    // 2rd position
    {
        name: "deno_confirmed", //tady se začně posílat PDF, to se nezasekne
        number: 5,
        position: 2,
        color: greenish,
        standard: true,
        timer: null,
    },
    {
        name: "deno_denied",
        number: 4,
        position: 2,
        color: redish,
        standard: false,
        timer: null, //koncový
    },
    // 3th position
    {
        name: "pdf_generating",
        number: 27,
        position: 3,
        color: greenish,
        standard: true,
        timer: 1 * 60, //1 min  // třeba techniky, žádné ruční akce
    },
    {
        name: "pdf_sending",
        number: 15,
        position: 3,
        color: greenish,
        standard: false,
        timer: 5 * 60, // 5 min  // třeba techniky, žádné ruční akce
    },
    {
        name: "pdf_uploaded",
        number: 16,
        position: 3,
        color: greenish,
        standard: false,
        timer: 5 * 60, // 5 min  // třeba techniky, žádné ruční akce
    },
    {
        name: "pdf_picked",
        number: 17,
        position: 3,
        color: greenish,
        standard: false,
        timer: 15 * 60, //v tento moment čekáme, na to co sklad, jako v případě potvvrzení dodacího listu - vstupuje do hry datum dodání //dodělali jsme magic button pro tento stav
        //zrušit nebo 31 
        //do tohoto vstupuje prp9 a datum dodání a liší se jestli to přebírá klient nebo HSH logistika - toho budem řeššit pak společně s etou
    },
    {
        name: "pdf_error",
        number: 28,
        position: 3,
        color: redish,
        standard: false,
        timer: null, //koncový stav v tuto chvíli, časem tlačítko zkusit znovu
    },
    {
        name: "deno_confirmed_manually", //tady se začně posílat PDF, to se nezasekne
        number: 12,
        position: 3,
        color: yellowish,
        standard: false,
        timer: null,
    },
    {
        name: "deno_canceled_manually",
        number: 8,
        position: 3,
        color: yellowish,
        standard: false,
        timer: null, //koncové
    },
    // 4th position
    {
        name: "deno-dispensing_preparation",
        number: 18,
        position: 4,
        color: greenish,
        standard: true,
        timer: 24 * 60 * 60, // 24 h 
        // 31 || zrušit 
    },
    {
        name: "storno_generating",
        number: 29,
        position: 4,
        color: yellowish,
        standard: false,
        timer: 1 * 60, // 1 min  // třeba techniky, žádné ruční akce
    },
    {
        name: "storno_sending",
        number: 9,
        position: 4,
        color: yellowish,
        standard: false,
        timer: 5 * 60, //5 min  // třeba techniky, žádné ruční akce
    },
    {
        name: "storno_uploaded",
        number: 21,
        position: 4,
        color: yellowish,
        standard: false,
        timer: 5 * 60, //5 min  // třeba techniky, žádné ruční akce
    },
    {
        name: "storno_picked",
        number: 22,
        position: 4,
        color: yellowish,
        standard: false,
        timer: 15 * 60, //15 min // zase možnost hůlek, co se může stát??
        // už tam mám magicy, ty jsou správně (storno potvrzeno ručně a storno zrušeno) + přidat 31
    },
    {
        name: "storno_error",
        number: 30,
        position: 4,
        color: redish,
        standard: false,
        timer: 1, //vyskladnění pokračuje, zvláštní situace - timer ihned
        // 31 || zrušit 

    },
    // 5th position
    {
        name: "deno_on_platform",
        number: 19,
        position: 5,
        color: greenish,
        standard: true,
        timer: 24 * 60 * 60, // 24 h 
        // 31 || zrušit 

    },
    {
        name: "storno_confirmed",
        number: 11,
        position: 5,
        color: yellowish,
        standard: false,
        timer: null, //koncový
    },
    {
        name: "storno_confirmmed_manually",
        number: 13,
        position: 5,
        color: yellowish,
        standard: false,
        timer: null, //koncový
    },
    {
        name: "storno_denied",
        number: 10,
        position: 5,
        color: redish,
        standard: false,
        timer: 1, //pokračujeme ve výdeji - zboží je venku a je třeba to řešit
        // 31 || zrušit 
    },
    {
        name: "storno_canceled_manually",
        number: 14,
        position: 5,
        color: yellowish,
        standard: false,
        timer: 1, // pokračujeme ve výdeji - čekám na výdej a nevím, jak dlouho se něco předtím dělo
        // 31 || zrušit 
    },
    // 6th position
    {
        name: "handed_over",
        name2: "handed_over_shipping", //Předáno dopravci
        name3: "handed_over_customer", //Předáno zákazníkovi
        number: 20,
        position: 6,
        color: greenish,
        standard: true,
        timer: null, //koncový
    },
    {
        name: "handed_over", //this won't be used anywhere, but its for consistency
        name2: "stockout_finished_manually_shipping", //Předáno zákazníkovi - ručně potvrzeno
        name3: "stockout_finished_manually_customer", //Předáno dopravci - ručně potvrzeno
        number: 31,
        position: 6,
        color: yellowish,
        standard: false,
        timer: null, //koncový
    },
];


/*
// 1st position
{
    name: "Kontrola PDF",
    number: 23,
    position: 1,
    color: greenish,
    standard: true,
    timer: null,
},
{
    name: "Chyba v PDF",
    number: 24,
    position: 1,
    color: redish,
    standard: false,
    timer: null,
},
*/