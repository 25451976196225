/**
 * Partner insurance web view.
 * @module partners/partner-insurance-web
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { Table, Row, Col, Button } from 'react-bootstrap';
import { format_amount } from '../lib/format';
import { PartnerInsuranceStatus, PartnerRatingHistory, PartnerDetailLimitDetail, filterDetail } from '../insurance/insurance-utils';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

//seen all - OK

export function PartnerInsuranceWeb({ partner }) {
    const { t } = useTranslation();

    return (
        <ErrorWrap>
            <div className="d-none d-md-block">
                <Row>
                    <Col lg={6}>
                        <PartnerInsuranceStatus status={partner.Insurance} partner={partner} />
                        <PartnerDetailLimits partner={partner} />
                    </Col>
                    <Col lg={6}>
                        <h5>{t('biz-rating_hist')}</h5>
                        <PartnerRatingHistory history={partner.RatingHistory} />
                    </Col>
                </Row>
            </div>
        </ErrorWrap>
    );
}

function PartnerDetailLimits({ partner }) {
    const { t } = useTranslation();

    var limits = partner.limits;
    if (typeof (limits) === 'undefined') {
        return "";
    }
    var lidx = 0;
    return (
        <>
            <h5 className="d-none d-md-table">{t('ins-limits')}</h5>
            <Table size="sm" className='d-none d-md-table'>
                <thead>
                    <tr className="beGray">
                        <th>{t('ins-number')}</th>
                        <th>{t('state')}</th>
                        <th>{t('biz-requested')}</th>
                        <th>{t('from-prep')}</th>
                        <th>{t('to_prep')}</th>
                        <th>{t('ins-terminated')}</th>
                        <th className="text-end">{t('ins-required_amount')}</th>
                        <th className="text-end">{t('ins-approved_amount')}</th>
                        <th>{t('biz-currency')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        limits.map(function (limit) {
                            lidx += 1;
                            return <PartnerDetailLimit key={lidx} lidx={lidx} limit={limit} />;
                        })}
                </tbody>
            </Table>
        </>
    );
}



function PartnerDetailLimit({ lidx, limit }) {

    const [toggled, setToggled] = useState({});

    function toggleDetailShown(id) {
        setToggled({ ...toggled, [id]: !toggled[id] });
    }

    const actcls = limit[0] ? "limit-active trhref" : "limit-inactive trhref";
    const details = limit[11];
    var idx = 0;
    return (
        <>
            <tr className={actcls} onClick={() => toggleDetailShown(limit[1])}>
                <td>{limit[1]}</td>
                <td>{limit[2]}</td>
                <td>{limit[8]}</td>
                <td>{limit[3]}</td>
                <td>{limit[4]}</td>
                <td>{limit[10]}</td>
                <td className="text-end">{format_amount(limit[5])}</td>
                <td className="text-end"><b>{format_amount(limit[6])}</b></td>
                <td><b>{limit[7]}</b></td>
                <td><Button size="sm" variant="light" >{toggled[limit[1]] ? <MdExpandLess /> : <MdExpandMore />}</Button></td>
            </tr>
            <tr className={toggled[limit[1]] ? "" : " d-none"}>
                <td colSpan={10}>
                    <Table size="sm" className="propTable">
                        <tbody>
                            {
                                details.filter(filterDetail).map(function (detail) {
                                    idx += 1;
                                    return <PartnerDetailLimitDetail key={idx} idx={idx} detail={detail} />;
                                })

                            }
                        </tbody>
                    </Table>
                </td>
            </tr>
        </>
    );
}