/**
 * Component displaying orange universal ADR icon.
 * @module comp/adr
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React from 'react';
import { Image } from 'react-bootstrap';

//seen all - OK

/** 
 * Displaying ADR icon. 
 *
 * @param {object} props
 * @param {boolean} props.isADR - boolean whether product is ADR
 * @param {number} [props.height=45] - default is 45

 * @return - image of ADR
 */
export function ShowADR({ isADR, height = 45 }) {
    if (isADR) {
        return <Image src={"/img/adr/isADR.svg"} height={height} />;
    } else {
        return "";
    }
}