/**
 * Progress path implementation for delivery-notes
 * @module delivery-notes/delivery-note-progress-path
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React, { useState } from "react";
import { his_fetch } from "../comp/FetchLoader";
import { delivery_note_progress_specification } from "./progress-delivery-note-def";
import { ProgressPath, make_progress_steps } from "../comp/progress";
import { Loading } from "../comp/loading";
import { ConfirmButton } from "../comp/confirm-button";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { setDeliveryNoteStatus } from "../api/deliveryNotes";
import { useUserLogin } from "../UserLoginProvider";

export function ProgressPathDeliveryNote({ one_history, compact = false, setReloadingPeriod }) {
  const { t } = useTranslation();

  const [errorMess, setErrorMess] = useState("");
  const userLogin = useUserLogin();

  const { mutate: updateStatus, isPending } = useMutation({
    mutationFn: ({ orderCode, wantedStatus }) => {
      return setDeliveryNoteStatus(orderCode, wantedStatus);
    },
    onError: (error) => {
      setErrorMess(t("error"));
    },
    onSuccess: (data) => {
      if (data.status === "done") {
        setErrorMess("sent");
        setReloadingPeriod(5 * 1000);
      } else {
        setErrorMess(t("error"));
      }
    }
  });
  
  const updating = isPending;

  const authorizedUser = !!userLogin.userInfo.roleCsrHead;

  const my_steps = make_progress_steps(delivery_note_progress_specification, one_history,
    function(step) {
      // console.log(step);
      const timeDiffMinutes = (new Date() - new Date(step.timestamp)) / 1000 / 60;
      const over15min = timeDiffMinutes > 15;
      const showMagicButtons = authorizedUser && over15min && step.active; //why this isnt working?
      const loading = updating && step.active;
      const loadingComponent = loading ? <Loading margin="0" size="small" /> : "";
      //new buttons with new api
      const cancelPDButton = <ConfirmButton finalOnClick={() => updateStatus({ orderCode :step.pdd_KodDokladu, wantedStatus: 8 })} //Ručně zrušit (8)
                                            initialButtonText={t("cancel")} popoverButtonText={t("cancel_manually")}
                                            popoverText={t("really_wanna_cancel") + " " + t("deno") + "?"}
                                            initialButtonColor="danger" popoverButtonColor="danger"
                                            errorMessage={errorMess} loadingComponent={loadingComponent}
      />;
      const confirmPDButton = <ConfirmButton
        finalOnClick={() => updateStatus({ orderCode: step.pdd_KodDokladu, wantedStatus: 12 })} // Ručně potvrdit (12)
        initialButtonText={t("confirm")} popoverButtonText={t("confirm_manually")}
        popoverText={t("really_wanna_confirm") + " " + t("deno") + "?"}
        initialButtonColor="warning" popoverButtonColor="warning"
        errorMessage={errorMess} loadingComponent={loadingComponent}
      />;
      const cancelStornoButton = <ConfirmButton
        finalOnClick={() => updateStatus({ orderCode: step.pdd_KodDokladu, wantedStatus: 8 })} //Storno ručně zrušeno (14)
        initialButtonText={t("cancel")} popoverButtonText={t("cancel_manually")}
        popoverText={t("really_wanna_cancel") + " " + t("storno") + "?"}
        initialButtonColor="danger" popoverButtonColor="danger"
        errorMessage={errorMess} loadingComponent={loadingComponent}
      />;
      const confirmStornoButton = <ConfirmButton
        finalOnClick={() => updateStatus({ orderCode: step.pdd_KodDokladu, wantedStatus: 8 })} //Storno ručně potvrzeno (13)
        initialButtonText={t("confirm")} popoverButtonText={t("confirm_manually")}
        popoverText={t("really_wanna_confirm") + " " + t("storno") + "?"}
        initialButtonColor="warning" popoverButtonColor="warning"
        errorMessage={errorMess} loadingComponent={loadingComponent}
      />;
      const finalizePDManually = <ConfirmButton
        finalOnClick={() => updateStatus({ orderCode: step.pdd_KodDokladu, wantedStatus: 31 })} //Výdej ručně dokončen (31)
        initialButtonText={t("confirm") + " " + t("stockout_completion")} popoverButtonText={t("confirm_manually")}
        popoverText={t("really_wanna_confirm") + " " + t("stockout_completion") + "?"}
        initialButtonColor="warning" popoverButtonColor="warning"
        errorMessage={errorMess} loadingComponent={loadingComponent}
      />;


      /*          const actionSubject = step.status === 3 ? t('deno') + "?" : t('storno') + "?"; //prozatímní řešení, zatím jsou jen 2 sady magic buttons
                  const cancelButton = <ConfirmButton finalOnClick={() => updateStatus(step.pdd_KodDokladu, "revert")}
                      initialButtonText={t('cancel')}
                      initialButtonColor="danger"
                      popoverText={t('really_wanna_cancel') + " " + actionSubject}
                      popoverButtonText={t('cancel_manually')}
                      popoverButtonColor="danger"
                      errorMessage={errorMess}
                      loadingComponent={loadingComponent}
                  />;
                  const confirmButton = <ConfirmButton finalOnClick={() => updateStatus(step.pdd_KodDokladu, "confirm")}
                      initialButtonText={t('confirm')}
                      initialButtonColor="warning"
                      popoverText={t('really_wanna_confirm') + " " + actionSubject}
                      popoverButtonText={t('confirm_manually')}
                      popoverButtonColor="warning"
                      errorMessage={errorMess}
                      loadingComponent={loadingComponent}
                  />;
                  */

      switch (step.status) {
        case 3: // Vyzvednuto -> třeba moci ručně zrušit (8) a ručně potvrdit (12)
          return (
            <>
              {showMagicButtons ?
                <>{cancelPDButton}{confirmPDButton}</> : <></>}
            </>
          );
        case 22: // Storno vyzvednuto -> třeba moci na stornu ručně zrušeno (14) a storno ručně potvrzeno (13) a ručně dokončeno (31)
          return (
            <>
              {showMagicButtons ?
                <>{cancelStornoButton}{confirmStornoButton}{finalizePDManually}</> : <></>}
            </>
          );
        //-> třeba moci na ručně zrušeno (8) a ručně dokončeno (31)
        case 17: // PDF vyzvednuto 
        case 18: // Příprava výdeje zboží
        case 19: // Na rampě
        case 14: // Storno ručně zrušeno
        case 10: // Storno zamítnuto
        case 30: // Chyba ve stornu
          return (
            <>
              {showMagicButtons ?
                <>{cancelPDButton}{finalizePDManually}</> : <></>}
            </>
          );
        default:
          return <></>;
      }
    });
  return (
    <ProgressPath steps={my_steps} compact={compact} authorizedUser={authorizedUser} />
  );
}