/**
 * Orders sale list app and web
 * @module orders/orders-sale-paged
 * @author Miroslav Lhoťan <miroslav.lhotan@netglade.cz>
 */
import React, { useState, useCallback } from "react";
import { Table, Row, Col, Form } from "react-bootstrap";
import { Boolean } from "../comp/boolean";
import { LinkContainer } from "react-router-bootstrap";
import { format_amount } from "../lib/format";
import { date_formatCZ } from "../lib/date-utils";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { useTranslation } from "react-i18next";
import { DynamicLoadPaged, DynamicLoadPagedProps } from "../comp/DynamicLoadPaged";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getSaleOrders } from "../api/orders";
import { queryStatusToLoadedStatus } from "../api/common";
import { useCachedOffsetAndFilter } from "../comp/FilterCacheProvider";

export interface OrdersSalePagedFilterValues {
  orderCode?: string;
  partnerCode?: string;
  note?: string;
  massFilter?: string;
}

const initialFilter: OrdersSalePagedFilterValues = {
  orderCode: "",
  partnerCode: "",
  note: "",
  massFilter: "",
};

export function OrdersSalePaged() {
  const [from, setFrom] = useState<string>(undefined);
  const [to, setTo] = useState<string>(undefined);

  const form = useForm<OrdersSalePagedFilterValues>({ mode: "onChange" });
  const { watch, reset } = form;
  const [offset, setOffset, debouncedFilter] = useCachedOffsetAndFilter("saleOrders", watch, reset, initialFilter);

  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["saleOrders", from, to, offset, debouncedFilter],
    queryFn: ({ signal }) => {
      if (!from || !to)
        return Promise.resolve<Dto.GetSaleOrdersPagedItemRangePagedResponse>({
          items: [],
          totalCount: 0,
          filteredCount: 0,
          rangeCount: 0,
          count: 0,
        });
      return getSaleOrders(from, to, 20, offset, debouncedFilter, { signal });
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const loadData = useCallback<DynamicLoadPagedProps["loadData"]>((rangeFrom, rangeTo) => {
    setFrom(rangeFrom);
    setTo(rangeTo);
  }, []);

  return (
    <ErrorWrap>
      <DynamicLoadPaged
        period="1y"
        loadData={loadData}
        offset={offset}
        setOffset={setOffset}
        cleanFilters={() => form.reset(initialFilter)}
        reloadIt={refetch}
        filteredCount={data?.filteredCount}
        loadingState={loadedStatus}
        totalCount={data?.totalCount}
        rangeCount={data?.rangeCount}
      />
      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          <OrdersSaleWeb orders={data?.items ?? []} />
          <OrdersSaleApp orders={data?.items ?? []} />
        </FormProvider>
      </TableFetchStatusOverlay>
    </ErrorWrap>
  );
}

interface OrdersSaleProps {
  orders: Dto.GetSaleOrdersPagedItem[];
}

function OrdersSaleWeb({ orders }: OrdersSaleProps) {
  const { t } = useTranslation();
  const { register } = useFormContext<OrdersSalePagedFilterValues>();

  return (
    <div className="d-none d-lg-block">
      <Table hover size="sm" striped className="d-none d-lg-table">
        <thead className="beGray">
          <tr>
            <th>
              <Form.Group className="m-1" controlId="filterCode">
                <Form.Control type="text" placeholder={"🔍 " + t("doc_code")} {...register("orderCode")} />
              </Form.Group>
            </th>
            <th>
              <Form.Group className="m-1" controlId="filterPartner">
                <Form.Control type="text" placeholder={"🔍 " + t("biz-partner_code")} {...register("partnerCode")} />
              </Form.Group>
            </th>
            <th className="pb-2"> {t("date")}</th>
            <th>
              <Form.Group className="m-1" controlId="filterNote">
                <Form.Control type="text" placeholder={"🔍 " + t("note")} {...register("note")} />
              </Form.Group>
            </th>
            <th className="pb-2 text-end">{t("biz-amount_money")}</th>
            <th className="pb-2">{t("biz-currency")}</th>
            <th className="pb-2 text-center">{t("biz-closed")}</th>
            <th className="pb-2 text-center">{t("biz-invoiced")}&nbsp;%</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(function (ord) {
            return (
              <LinkContainer to={{ pathname: "/orders/sale/" + ord.orderCode }} key={ord.orderCode}>
                <tr key={ord.orderCode}>
                  <td>{ord.orderCode}</td>
                  <td>{ord.partnerCode}</td>
                  <td>{date_formatCZ(ord.orderDate)}</td>
                  <td>{ord.note}</td>
                  <td className="text-end">{format_amount(ord.totalAmount)}</td>
                  <td>{ord.currencyCode}</td>
                  <td className="text-center">
                    <Boolean value={ord.closed} variant="onlyTrue" />
                  </td>
                  <td className="text-center">{ord.invoicedPercent}&nbsp;%</td>
                </tr>
              </LinkContainer>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

function OrdersSaleApp({ orders }: OrdersSaleProps) {
  const { t } = useTranslation();
  const { register } = useFormContext<OrdersSalePagedFilterValues>();

  return (
    <div className="d-lg-none">
      <br />
      <p className="mb-1">{t("mass_filter")}</p>
      <Form.Group controlId="filterCodex" className="mb-3">
        <Form.Control type="text" placeholder={"🔍 " + t("filter_doc_code_note_partner")} {...register("massFilter")} />
      </Form.Group>
      <Table striped>
        <tbody>
          {orders.map(function (ord) {
            return (
              <LinkContainer to={{ pathname: "/orders/sale/" + ord.orderCode }} key={ord.orderCode}>
                <tr>
                  <td>
                    <Row className="g-0">
                      <Col className="beBigger me-1">{ord.orderCode}</Col>
                      <Col className="text-end align-bottom">
                        {format_amount(ord.orderCode)} {ord.currencyCode}
                      </Col>
                    </Row>
                    <Row>
                      <Col>{ord.partnerCode}</Col>
                      <Col className="text-end">
                        {t("biz-closed")}: <Boolean value={ord.closed} variant="onlyTrue" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>{date_formatCZ(ord.orderDate)}</Col>
                      <Col xs="auto" className="text-end">
                        {t("biz-invoiced")}: {ord.invoicedPercent}&nbsp;%
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {t("note_short")}: {ord.note}
                      </Col>
                    </Row>
                  </td>
                </tr>
              </LinkContainer>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
