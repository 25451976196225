import { authorizedFetch } from "./common";

export function getReceivedFtpFiles(
  from: string,
  take: number = 10,
  skip: number = 0,
  filter: Dto.GetReceivedFtpRangePagedFilterRequest = {},
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetReceivedFtpRangeResponseItemRangePagedResponse>(
    `/api/ftp/get/received/range/${from}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    { ...options, method: "GET" }
  );
}

export function getSentFtpFiles(
  from: string,
  take: number = 10,
  skip: number = 0,
  filter: Dto.GetSentFtpRangePagedFilterRequest = {},
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetSentFtpRangePagedResponseItemRangePagedResponse>(
    `/api/ftp/get/sent/range/${from}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    { ...options, method: "GET" }
  );
}
