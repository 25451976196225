/**
 * Common partner components.
 * @module partners/partner-panels
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { format_amount } from '../lib/format';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerKreditniLimity({ KreditniLimit, InterniLimit, klstyle, ilstyle, saldo }) {
	const { t } = useTranslation();
	return (
		<Card className="mb-3">
			<Card.Header as="h5">{t('biz-credit_limits')}</Card.Header>
			<Card.Body className="p-1">
				<Table size="sm">
					<thead>
						<tr>
							<th rowspan="2" scope="col" className="text-center">{t('biz-balance')}</th>
							<th colspan="2" scope="col" className="text-center">{t('biz-limit')}</th>
						</tr>
						<tr>
							<th scope="col" className="text-center">{t('biz-credit')}</th>
							<th scope="col" className="text-center">{t('biz-internal')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="text-end">{format_amount(saldo)}</td>
							<td className="text-end" style={klstyle}>{format_amount(KreditniLimit)}</td>
							<td className="text-end" style={ilstyle}>{format_amount(InterniLimit)}</td>
						</tr>
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
}

const numstyle = {
	fontSize: "125%",
	fontWeight: "680"
};

/* apparently unused component
export function PartnerSaldo({ lstyle, lsaldo, currency, title }) {
	if ((title || "").length === 0) {
		title = "Saldo po splatnosti";
	}
	return (
		<Card style={lstyle} className="mb-3">
			<Card.Header as="h5">{title}</Card.Header>
			<Card.Body>
				<div className="text-center" style={numstyle}>
					{format_amount(lsaldo)} {currency}
				</div>
			</Card.Body>
		</Card>
	);
}
*/

export function PartnerSaldo2({ lstyle, lsaldos, currs, title, tsaldo, currency }) {
	const { t } = useTranslation();
	
	var ttitle = "";
	if (typeof tsaldo !== "undefined") {
		ttitle = format_amount(tsaldo);
		// console.log("měna: " + currency);
	}
	if ((title || "").length === 0) {
		title = t('biz-after_due_balance') + ":";
	}
	return (
		<Card style={lstyle} className="mb-3">
			<Card.Header as="h6">
				<div className="d-flex justify-content-between">
					<span>{title}</span>
					<span>{ttitle} {currency}</span>
				</div>
			</Card.Header>
			<Card.Body>
				<div className="text-center" style={numstyle}>
					{
						currs.map(function (curr) {
							return (
								<Row className="g-0" key={curr}>
									<Col xs="7" className="text-end">{format_amount(lsaldos[curr])}</Col>
									<Col className="text-start">&nbsp;{curr}</Col>
								</Row>
							);
						})
					}
				</div>
			</Card.Body>
		</Card>
	);
}
