import React, { useState, useCallback } from "react";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import Table from "react-bootstrap/Table";
import { date_formatCZ, date_time_format } from "../lib/date-utils";
import { Pager } from "../comp/pager";
import { LinkContainer } from "react-router-bootstrap";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { ErrorWrap } from "../comp/errorwrap";
import { LoadingDataInfo } from "../comp/dynamic-load";
import { useTranslation } from "react-i18next";
import { DynamicLoadPaged, DynamicLoadPagedProps } from "../comp/DynamicLoadPaged";
import { useQuery } from "@tanstack/react-query";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";
import { getEmails } from "../api/emails";
import { queryStatusToLoadedStatus } from "../api/common";

const defaultFilters: Dto.GetEmailListPagedHandlerFilterRequest = {
  to: "",
  subject: "",
  cc: "",
  bcc: "",
  from: "",
  massFilter: "",
};

interface EmailsProps {
  pageSize?: number;
  existingEmails?: Dto.EmailArchiveItem[];
  noFetch?: boolean;
  optionalHeader?: string;
}

export function Emails({
  pageSize = 20,
  existingEmails = undefined,
  noFetch = false,
  optionalHeader = "",
}: EmailsProps) {
  const [offset, setOffset] = useState<number>(0);
  const [from, setFrom] = useState<string>(undefined);
  const form = useForm<Dto.GetEmailListPagedHandlerFilterRequest>({ mode: "onChange", defaultValues: defaultFilters });
  const { watch, reset } = form;
  const [filter] = useDebounceFilterValues(watch, defaultFilters);

  const loadData = useCallback<DynamicLoadPagedProps["loadData"]>((rangeFrom, rangeTo) => {
    setFrom(rangeFrom);
  }, []);

  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["emails", from, offset, filter],
    queryFn: ({ signal }) => {
      if (!from)
        return Promise.resolve<Dto.EmailArchiveItemRangePagedResponse>({
          items: [],
          totalCount: 0,
          filteredCount: 0,
          rangeCount: 0,
          count: 0,
        });

      return getEmails(from, 20, offset, filter, { signal });
    },
    enabled: !noFetch,
    initialData: {
      items: existingEmails ?? [],
      totalCount: existingEmails?.length ?? 0,
      filteredCount: existingEmails?.length ?? 0,
      rangeCount: existingEmails?.length ?? 0,
      count: 0,
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const cleanFilters = () => {
    reset(defaultFilters);
  };

  const temporaryMarginClass = noFetch ? " me-3 " : "";

  return (
    <ErrorWrap>
      {noFetch ? (
        <>
          <h5 className="d-inline ms-3 mb-2">{optionalHeader}</h5>
          <Pager
            offset={offset}
            pagesize={pageSize}
            total={data.filteredCount}
            callback={setOffset}
            className={temporaryMarginClass}
          />
        </>
      ) : (
        <DynamicLoadPaged
          period="1m"
          loadData={loadData}
          offset={offset}
          setOffset={setOffset}
          cleanFilters={cleanFilters}
          loadingState={loadedStatus}
          totalCount={data?.totalCount}
          filteredCount={data?.filteredCount}
          rangeCount={data?.rangeCount}
          reloadIt={refetch}
        />
      )}
      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          <EmailsWeb emails={data?.items ?? []} noFetch={noFetch} />
          <EmailsApp emails={data?.items ?? []} />
        </FormProvider>
      </TableFetchStatusOverlay>
      <LoadingDataInfo loadedStatus={loadedStatus} data={data?.items ?? []} />
    </ErrorWrap>
  );
}

interface EmailsAppProps {
  emails: Dto.EmailArchiveItem[];
}

function EmailsApp({ emails }: EmailsAppProps) {
  const { t } = useTranslation();
  const { register } = useFormContext<Dto.GetEmailListPagedHandlerFilterRequest>();

  return (
    <ErrorWrap>
      <Form.Group controlId="filterSubject" className="d-md-none mb-3 mx-1">
        <Form.Control
          type="text"
          placeholder={"🔍 " + t("sys-filter_from_to_subject_cc")}
          {...register("massFilter")}
        />
      </Form.Group>
      <Table striped className="d-md-none">
        <tbody>
          {emails.map(function (em, idx) {
            const date_time = em.sent.split(" ");
            return (
              <LinkContainer key={idx} to={{ pathname: "/system/emails/" + em.id }}>
                <tr>
                  <td>
                    <Row>
                      <Col>
                        <span className="text-muted">{t("from-prep")}: </span>
                        {em.user?.name ?? <>&mdash;</>}&nbsp; ({em.user?.login ?? <>&mdash;</>})
                      </Col>
                      <Col xs="auto">{date_formatCZ(date_time[0])}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="text-muted">{t("to_whom")}: </span>
                        {em.to.map((a, idx) => (
                          <React.Fragment key={idx}>
                            <span key={idx} className="mb-0">
                              {a}
                            </span>
                            <br />
                          </React.Fragment>
                        ))}
                      </Col>
                      <Col xs="auto">{date_time[1]}</Col>
                    </Row>
                    <span className="text-muted">{t("sys-subject")}:</span> {em.subject}
                    <br />
                    {em.cc.length !== 0 ? (
                      <>
                        <span className="text-muted">{t("sys-cc")}: </span>
                        {em.cc.map((b, idx) => (
                          <span key={idx} className="mb-0">
                            {b}
                            <br />
                          </span>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                    {em.bcc.length !== 0 ? (
                      <>
                        <span className="text-muted">{t("sys-bcc")}: </span>
                        {em.bcc.map((b, idx) => (
                          <span key={idx} className="mb-0">
                            {b}
                            <br />
                          </span>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </LinkContainer>
            );
          })}
        </tbody>
      </Table>
    </ErrorWrap>
  );
}

interface EmailsWebProps {
  emails: Dto.EmailArchiveItem[];
  noFetch: boolean;
}

function EmailsWeb({ emails, noFetch }: EmailsWebProps) {
  const { t } = useTranslation();
  const { register } = useFormContext<Dto.GetEmailListPagedHandlerFilterRequest>();

  const myPadding = noFetch ? " ps-3" : "";
  const myPadding2 = noFetch ? " pe-3" : "";
  return (
    <ErrorWrap>
      <Table hover size="sm" striped borderless className="d-none d-md-table">
        <thead className="beGray">
          <tr>
            <th className={myPadding + " py-3"}>
              <Form.Group controlId="filterSubject">
                <Form.Control type="text" placeholder={"🔍 " + t("sys-from")} {...register("from")} />
              </Form.Group>
            </th>
            <th className="py-3">
              <Form.Group controlId="filterTo">
                <Form.Control type="text" placeholder={"🔍 " + t("sys-to")} {...register("to")} />
              </Form.Group>
            </th>
            <th className="py-3">
              <Form.Group controlId="filterSubject">
                <Form.Control type="text" placeholder={"🔍 " + t("sys-subject")} {...register("subject")} />
              </Form.Group>
            </th>
            <th className="align-middle text-end pe-5">{t("date")}</th>
            <th className="py-3">
              <Form.Group controlId="filterCC">
                <Form.Control type="text" placeholder={"🔍 " + t("sys-cc")} {...register("cc")} />
              </Form.Group>
            </th>
            <th className={myPadding2 + " py-3"}>
              <Form.Group controlId="filterBCC">
                <Form.Control type="text" placeholder={"🔍 " + t("sys-bcc")} {...register("bcc")} />
              </Form.Group>
            </th>
          </tr>
        </thead>
        <tbody>
          {emails.map(function (em, idx) {
            return (
              <LinkContainer to={{ pathname: "/system/emails/" + em.id }} key={idx}>
                <tr>
                  <td className={myPadding}>
                    {em.user?.name ?? <>&mdash;</>}&nbsp; ({em.user?.login ?? <>&mdash;</>})
                  </td>
                  <td>
                    {em.to.map((a, idx) => (
                      <p key={idx} className="mb-0">
                        {a}
                      </p>
                    ))}{" "}
                  </td>
                  <td>{em.subject}</td>
                  <td className="text-end">{date_time_format(em.sent)}</td>
                  <td>
                    {em.cc.map((b, idx) => (
                      <p key={idx} className="mb-0">
                        {b}
                      </p>
                    ))}
                  </td>
                  <td>
                    {em.bcc.map((c, idx) => (
                      <p key={idx} className="mb-0">
                        {c}
                      </p>
                    ))}
                  </td>
                </tr>
              </LinkContainer>
            );
          })}
        </tbody>
      </Table>
    </ErrorWrap>
  );
}
