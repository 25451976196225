import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
/* už nepotřebujem
const resources = {
  en: {
    "Welcome to React": "Welcome to React and react-i18next"
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next"
    }
  }
};
*/

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'cs',
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    resources: {
      en: {
        translations: require('./locales/en/translations.json')
      },
      cs: {
        translations: require('./locales/cs/translations.json')
      },
      sk: {
        translations: require('./locales/sk/translations.json')
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

i18n.languages = ['en', 'cs', 'sk'];

export default i18n;