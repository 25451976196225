
/**
 * Component displaying Alert which says "choose category".
 * @module comp/choose-category
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

//seen all - OK
/** 
 * Component displaying Alert which says "choose category".
 *  
 * @return {component}
 */
export function ChooseCategory() {
    const { t } = useTranslation();

    return (
        <Alert variant="info" style={{ maxWidth: "300px" }} >
            {t('menu2-sub_cat')}
        </Alert>
    );
}