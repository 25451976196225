/**
 * Partner detail view.
 * @module partners/partner-detail
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { make_product_check_tag } from '../products/product-checks';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import { Notes } from "../notes/Notes";

//seen all - OK

export function PartnerDetail({ user, userinfo, partner, addresses, persons, userlogin, ErrorBadge }) {
	return (
		<ErrorWrap>
			<Row>
				<Col lg={6}>
					<div className="pt-3">
						<PartnerDetailInfo partner={partner} />
					</div>
					<Row>
						<Col md={6}>
							{addresses.map(function (adrs, idx) {
								return <Address key={idx} adrs={adrs} ErrorBadge={ErrorBadge} />;
							})}
						</Col>
						<Col md={6}>
							<div className="mb-2">
								<PartnerPersons persons={persons} />
							</div>
						</Col>
					</Row>
				</Col>
				<Col lg={6}>
					<Notes objectType="Partner" objectCode={partner.KodPartnera} />
				</Col>
			</Row >
		</ErrorWrap >
	);
}

export function MandatoryAddressPart({ nazev, adresa1, adresa2, misto, psc, kodStatu, withAsterisks = false, bgLight = false, ErrorBadge, kodAdresy }) {
	const { t } = useTranslation();

	const asterix = withAsterisks ? "*" : "";
	const trClass = bgLight ? "p-0 bg-light" : "p-0";
	//console.log(nazev);
	// console.log(adresa1);
	// console.log(adresa2);
	// console.log(misto);
	// console.log(psc);
	// console.log(kodStatu);
	//  console.log(withAsterisks);
	// console.log(bgLight);
	//  console.log(kodAdresy);
	return (
		<>
			<tr className={trClass}>
				<td className="text-end text-muted p-0 ">{t('biz-adrs_name')}{asterix}&nbsp;</td>
				<td className="p-0">{nazev} <ErrorBadge tag={make_product_check_tag("hsh" + kodAdresy, "address-name")} /></td>
			</tr>
			<tr className={trClass}>
				<td className="text-end text-muted p-0 ">{t('biz-adrs')}{asterix}&nbsp;</td>
				<td className="p-0">{adresa1} <ErrorBadge tag={make_product_check_tag("hsh" + kodAdresy, "address-street")} /></td>
			</tr>
			{adresa2 ?
				<tr className={trClass}>
					<td className="text-end text-muted p-0 ">{t('biz-adrs')} 2&nbsp;</td>
					<td className="p-0">{adresa2}</td>
				</tr>
				: <></>}
			<tr className={trClass}>
				<td className="text-end text-muted p-0 ">{t('biz-location')}{asterix}&nbsp;</td>
				<td className="p-0">{misto} <ErrorBadge tag={make_product_check_tag("hsh" + kodAdresy, "address-city")} /></td>
			</tr>
			<tr className={trClass}>
				<td className="text-end text-muted p-0 ">{t('biz-zip')}{asterix}&nbsp;</td>
				<td className="p-0">{psc} <ErrorBadge tag={make_product_check_tag("hsh" + kodAdresy, "address-zip")} /></td>
			</tr>
			<tr className={trClass}>
				<td className="text-end text-muted p-0 ">{t('biz-cntr_code')}{asterix}&nbsp;</td>
				<td className="p-0">{kodStatu}  <ErrorBadge tag={make_product_check_tag("hsh" + kodAdresy, "address-country-code")} /> </td>
			</tr>
		</>
	);
}

export function Address({ adrs, ErrorBadge }) {
	const { t } = useTranslation();
	return (
		<Card className='mb-2'>
			<Card.Body>
				<Row>
					<Col>
						<h5 className='d-inline'>🗺️ {t('biz-adrs')} {adrs.KodAdresy}</h5>
					</Col>
					<Col className='text-muted text-end'>
						<small>*{t('biz-mandatory')}</small>
					</Col>
				</Row>
				<Table borderless className='m-0'>
					<tbody>
						<MandatoryAddressPart nazev={adrs.NazevAdresy} adresa1={adrs.Adresa} adresa2={adrs.Adresa2}
							misto={adrs.Misto} psc={adrs.PSC} kodStatu={adrs.KodStatu} withAsterisks ErrorBadge={ErrorBadge}
							kodAdresy={adrs.KodAdresy} />
						<tr className=" ">
							<td className=" pt-2 border-top text-end text-muted p-0 ">{t('email')} &nbsp;</td>
							<td className=" pt-2 border-top p-0">{adrs.Email}</td>
						</tr>
						<tr className="p-0">
							<td className="text-end text-muted p-0 ">{t('biz-phon')} &nbsp;</td>
							<td className="p-0">{adrs.Telefon}</td>
						</tr>
						<tr className="p-0">
							<td className="text-end text-muted p-0 ">{t('biz-mobile')} &nbsp;</td>
							<td className="p-0">{adrs.Mobil}</td>
						</tr>
						<tr className="p-0">
							<td className="text-end text-muted p-0 ">{t('biz-agent')} &nbsp;</td>
							<td className="p-0">{adrs.Zastupce}</td>
						</tr>
						<tr className="p-0">
							<td className="text-end text-muted p-0 ">{t('biz-adrs_type')} &nbsp;</td>
							<td className="p-0">{adrs.TypAdresy}
								<ErrorBadge tag={make_product_check_tag("hsh" + adrs.TypAdresy, "basic-address")} />
							</td>
						</tr>
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	)
}

/*
function PartnerAddresses({ addresses }) {
	const fields = [
		["Adresát", ["NazevAdresy"]],
		["Zástupce", ["Zastupce"]],
		["Adresa", ["Adresa", "Adresa2"]],
		["Místo", ["Misto"]],
		["PSČ", ["PSC"]],
		["Stát", ["KodStatu"]],
		["Telefon", ["Telefon"]],
		["Mobil", ["Mobil"]],
		["Fax", ["Fax"]],
		["E-mail", ["Email"]],
		["Typ adresy", ["TypAdresy"]],
		["Základní sklad:", ["KodKnihySkladu"]]
	];
	var idx = 0;
	return (
		<Card className="mt-3" >
			<Card.Header>
				<Card.Title>Adresy</Card.Title>
			</Card.Header>
			<Table size="sm">
				<tbody>
					{
						addresses.map(function (add) {
							idx += 1;
							var jdx = 0;
							return (
								<React.Fragment key={idx}>
									<tr>
										<th className="text-center bg-light" colSpan="2">{add["KodAdresy"]}</th>
									</tr>
									{
										fields.filter(function (fdef) {
											return (!(add[fdef[1][0]] === null));
										}).map(function (fdef) {
											jdx += 1;
											return (
												<tr key={jdx} className="propTable2">
													<th>{fdef[0]}</th>
													<td>{fdef[1].map((k) => add[k]).join("\n")}</td>
												</tr>
											);
										})
									}
								</React.Fragment>
							)
						})
					}
				</tbody>
			</Table>
		</Card>
	);
}
*/

function PartnerPersons({ persons }) {
	const { t } = useTranslation();

	const fields = [
		["name", ["Jmeno"]],
		["biz-title", ["Titul"]],
		["biz-position", ["Pozice"]],
		["biz-phon", ["Telefon"]],
		["biz-mobile", ["Mobil"]],
		["Fax", ["Fax"]],
		["email", ["Email"]],
		["biz-greetings", ["Osloveni"]],
		["note", ["Poznámka"]],
		["sys-language", ["KodJazyka"]],
		["biz-adrs", ["KodAdresy"]],
		["biz-responsible", ["KodPracovnikaZodpovida"]]
	];
	var idx = 0;
	return (
		<Card>
			<Card.Header >
				<Card.Title>{t('biz-contacts')}</Card.Title>
			</Card.Header>
			<Table size="sm">
				<tbody>
					{
						persons.map(function (per) {
							var perrow = (
								<tr>
									<th colSpan="2" className="text-center bg-light">{per["Jmeno"]}</th>
								</tr>
							);
							var jdx = 0;
							var frows = fields.filter(function (fdef) {
								return (!(per[fdef[1][0]] === null));
							}).map(function (fdef) {
								jdx += 1;
								return (
									<tr key={jdx} className="propTable2">
										<th>{t(fdef[0])}</th>
										<td>{fdef[1].map((k) => per[k]).join("\n")}</td>
									</tr>
								);
							});
							idx += 1;
							return (
								<React.Fragment key={idx}>
									{perrow}
									{frows}
								</React.Fragment>
							);
						})
					}
				</tbody>
			</Table>
		</Card>
	);
}

function PartnerDetailInfo({ partner }) {
	const { t } = useTranslation();

	const fields = [
		//	["IČ", ["ICO"]],
		//	["DIČ", ["DIC"]],
		["biz-agent", ["Zastupce"]],
		["biz-phon", ["Telefon"]],
		["biz-mobile", ["Mobil"]],
		["Fax", ["Fax"]],
		["note", ["Poznamka"]],
		//	["Stát", ["KodStatu"]],
		["biz-invoicing_email", ["Email"]],
		["biz-email_invoice", ["EmailovaFakturace"]],
		["biz-web", ["WWWAdresa"]],
		["biz-registrar_of_companies", ["ObchodniRejstrik"]],
		["biz-check_payments", ["k_ZpozdeniPlatebKontrola"]],
		["biz-agreement_num", ["CisloSmlouvy"]]
	];
	return (
		<Table bordered size="sm">
			<tbody>
				{
					fields.filter(function (fdef) {
						return (!(partner[fdef[1][0]] === null));
					}).map(function (fdef) {
						return (
							<tr key={fdef[0]} className="propTable2">
								<th>{t(fdef[0])}</th>
								<td>{fdef[1].map((k) => partner[k]).join("\n")}</td>
							</tr>
						);
					})
				}
			</tbody>
		</Table>

	);
}
