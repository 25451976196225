/**
 * Partner debt web view.
 * @module partners/partner-debts-web
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { delay_color, worst_color } from '../lists/colors';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { PartnerSaldo2 } from './partner-panels';
import { format_amount } from '../lib/format';
import { date_formatCZ, date_formatISO } from '../lib/date-utils';
import { LinkContainer } from 'react-router-bootstrap';
import { Downloader } from '../comp/downloader';
import notes_comms from '../notes/notes-comms.json';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

// 0    1     2         3         4          5           6        7          8       9         10           11       12      13    14      15
// ps,saldo,uhrada,datdokladu,poznamka,datsplatnosti,koddokladu,zpozdeni,zpozdeni2,kodmeny,CastkaCelkemMen,saldo2,comm_type,note,note_ts,pojisteno

export function PartnerDebtsWeb({ partner, debts, currency, acc_currency, saldoType, setSaldoType }) {
	const { t } = useTranslation();
	/* **************************************************************** */

	const classSaldo0 = saldoType === 0 ? "primary" : "light";
	const classSaldo1 = saldoType === 1 ? "primary" : "light";
	const classGray1 = saldoType === 0 ? "" : "beGray";
	const classGray2 = saldoType === 1 ? "" : "beGray";
	
	function handleSaldoTypeChange0() {
		setSaldoType(0);
	}
	function handleSaldoTypeChange1() {
		setSaldoType(1);
	}

	/* **************************************************************** */

	const KodPartnera = partner.KodPartnera;
	const NazevPartnera = partner.NazevPartnera;
	const todayStr = date_formatISO(new Date());
	var saldo = 0;
	var lsaldo = 0;
	var i;
	var currmap = {};
	var totalcurr = {};
	var latecurr = {};
	for (i = 0; i < debts.length; i++) {
		saldo += debts[i][1];
		if (debts[i][8] > 0) {
			lsaldo += debts[i][1];
		}
		currmap[debts[i][9]] = true;
	}
	var currary = [];
	var ckey;
	for (ckey in currmap) {
		currary.push(ckey);
		totalcurr[ckey] = 0;
		latecurr[ckey] = 0;
	}
	for (i = 0; i < debts.length; i++) {
		totalcurr[debts[i][9]] += debts[i][11];
		if (debts[i][8] > 0) {
			latecurr[debts[i][9]] += debts[i][11];
		}
	}
	var lstyle = {};
	var sstyle = {};
	if (lsaldo > 0) {
		lstyle = {
			backgroundColor: worst_color(),
			fontWeight: "bold"
		};
	}

	//console.log(debts);

	return (
		<ErrorWrap>
			<Row className="p-3">
				<Col lg={4}>
					<PartnerSaldo2 lstyle={lstyle} lsaldos={latecurr} currs={currary} tsaldo={lsaldo} currency={currency} />
				</Col>
				<Col lg={5}>
					<h6 className="mb-0 mt-2 text-center">{t('biz-pdf_dwnld')}</h6>
					<Row className="pt-3">
						<Col xs={4} className="text-center">
							<Downloader img="/application-pdf.svg" alt="pdf" label="CZ"
								path={"/api/partners/" + KodPartnera + "/debt/pdf/cz"}
								filename={NazevPartnera + "-saldo-" + todayStr + "-cz.pdf"} />
						</Col>
						<Col xs={4} className="text-center">
							<Downloader img="/application-pdf.svg" alt="pdf" label="EN"
								path={"/api/partners/" + KodPartnera + "/debt/pdf/en"}
								filename={NazevPartnera + "-saldo-" + todayStr + "-en.pdf"} />
						</Col>
						<Col xs={4} className="text-center">
							<Downloader img="/application-pdf.svg" alt="pdf" label="SK"
								path={"/api/partners/" + KodPartnera + "/debt/pdf/sk"}
								filename={NazevPartnera + "-saldo-" + todayStr + "-sk.pdf"} />
						</Col>
					</Row>
				</Col>
				<Col />
				<Col xs={12} lg={2} className="text-end mt-3 pe-0 ps-0">
					<div className=" text-center" >
						<Button className={"m-1 " + classGray1} variant={classSaldo0} onClick={handleSaldoTypeChange0} style={{ width: "150px" }}>{t('biz-acc_balance')}</Button>
						<br className="d-none d-lg-block" />
						<Button className={"m-1 " + classGray2} variant={classSaldo1} onClick={handleSaldoTypeChange1} style={{ width: "150px" }}>{t('biz-inv_balance')}</Button>
					</div>
				</Col>

				<Col xs={12} className="d-lg-none mt-3">
					<PartnerSaldo2 lsaldos={totalcurr} lstyle={sstyle} currs={currary} title={t('biz-total_balance') + ":"} tsaldo={saldo} currency={currency} />
				</Col>
			</Row>
			<Table striped size="sm" hover className="d-none d-lg-table">
				<thead>
					<tr className="beGray">
						<th scope="col" rowSpan="2" className="text-center align-middle">{t('doc_code')} / {t('biz-code_debt')}</th>
						<th scope="col" colSpan="3" className="text-center">{t('date')}</th>
						<th scope="col" rowSpan="2" className="text-center align-middle">{t('biz-acc_balance')} {currency}</th>
						<th scope="col" colSpan="2" className="text-center">{t('biz-payment')}</th>
						<th scope="col" rowSpan="2" className="text-center align-middle">{t('note')}</th>
						<th scope="col" colSpan="4" className="text-center">{t('biz-inv_total')}</th>
						<th scope="col" rowSpan="2" className="text-center align-middle">{t('menu-ins')}</th>
					</tr>
					<tr className="beGray">
						<th scope="col" className="text-center">{t('biz-issued')}</th>
						<th scope="col" className="text-center">{t('biz-due(2p)')}</th>
						<th scope="col" className="text-center">{t('biz-payments')}</th>
						<th scope="col" className="text-center">{t('biz-delay')}</th>
						<th scope="col" className="text-center">{t('biz-delay2')}</th>
						<th scope="col" className="text-center">{t('biz-balance')}</th>
						<th scope="col">{t('biz-currency')}</th>
						<th scope="col" className="text-center">{t('biz-amount_money')}</th>
						<th scope="col">{t('biz-currency')}</th>
					</tr>
				</thead>
				<tbody>
					{
						debts.map(function (pm) {
							var lstyle = {};
							var ostyle = {};
							if (pm[7] !== null) {
								lstyle = {
									fontWeight: "bold",
									backgroundColor: delay_color(pm[7])
								}
							}
							if (pm[8] !== null) {
								ostyle = {
									fontWeight: "bold",
									backgroundColor: delay_color(pm[8])
								}
							}
							var formatted_note = "";
							if (pm[13]) {
								const commdef = notes_comms[pm[12]];
								const emoji = commdef.emoji;
								const variant = commdef.variant;
								const text = commdef.text;
								const cls = "rounded p-1 me-1 bg-" + variant + " " + text;
								formatted_note = <>
									<br />
									<span className={cls}><img src={emoji} alt="" width="20" /></span>
									{date_formatCZ(pm[14])}&nbsp;•&nbsp;
									{pm[13]}
								</>;
							}
							return (
								<LinkContainer to={{ pathname: "/partners/invoices-issued/" + pm[6] }} key={pm[0]}>
									<tr key={pm[0]}>
										<td>{pm[6]}{formatted_note}</td>
										<td className="text-end">{date_formatCZ(pm[3])}</td>
										<td className="text-end">{date_formatCZ(pm[5])}</td>
										<td className="text-end">{date_formatCZ(pm[2])}</td>
										<td className="text-end">{format_amount(pm[1])}</td>
										<td className="text-center" style={lstyle}>{pm[7]}</td>
										<td className="text-center" style={ostyle}>{pm[8]}</td>
										<td>{pm[4]}</td>
										<td className="text-end">{format_amount(pm[11])}</td>
										<td>{pm[9]}</td>
										<td className="text-end">{format_amount(pm[10])}</td>
										<td>{pm[9]}</td>
										<td className="text-center"><Insured3 status={get_insurance_status(pm[16], pm[15], pm[17], partner.credendo_country_ok)} /></td>
									</tr>
								</LinkContainer>
							);
						})
					}
					<tr>
						<td colSpan="4">
							<strong>{t('biz-total_balance')}</strong>
						</td>
						<td className="text-end">{format_amount(saldo)}</td>
						<td colSpan="6" className="text-end">
							{
								currary.map(function (el) {
									return (
										<React.Fragment key={el}>
											{format_amount(totalcurr[el])}<br />
										</React.Fragment>
									);
								})
							}
						</td>
						<td>
							{
								currary.map(function (el) {
									return (
										<React.Fragment key={el}>
											{el}<br />
										</React.Fragment>
									);
								})
							}
						</td>
						<td></td>
					</tr>
					<tr style={lstyle}>
						<td colSpan="4">
							<strong>{t('biz-after_due_balance')}</strong>
						</td>
						<td className="text-end">{format_amount(lsaldo)}</td>
						<td colSpan="6" className="text-end">
							{
								currary.map(function (el) {
									return (
										<React.Fragment key={el}>
											{format_amount(latecurr[el])}<br />
										</React.Fragment>
									);
								})
							}
						</td>
						<td>
							{
								currary.map(function (el) {
									return (
										<React.Fragment key={el}>
											{el}<br />
										</React.Fragment>
									);
								})
							}
						</td>
						<td></td>
					</tr>
				</tbody>
			</Table>
		</ErrorWrap>
	);
}
