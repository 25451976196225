/**
 * Version of HIS, one function
 * @module lib/version
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import pkg from "../../package.json";
export function his_frontend_version() {
  return pkg.version;
}

export function matchFeVersion(beVersion) {
  const beVersionParts = beVersion.split(".");
  const feVersionParts = pkg.version.split(".");

  return (
    beVersionParts[0] === feVersionParts[0] &&
    beVersionParts[1] === feVersionParts[1] &&
    beVersionParts[2] === feVersionParts[2]
  );
}
