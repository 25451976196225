import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BooleanDropdownRhf from "../comp/BooleanDropdownRhf";
import { useFormContext } from "react-hook-form";
import { DeliveryNotesWebFiltersValues } from "./DeliveryNotesWebFilters";

export default function DeliveryNotesAppFilters({ type }: { type: "PD" | "ND" }) {
  const { t } = useTranslation();

  const { register } = useFormContext<DeliveryNotesWebFiltersValues>();

  return (
    <>
      <Form.Group className="m-1" controlId="filterWriter1">
        <Form.Label>{t("mass_filter")}</Form.Label>
        <Form.Control type="text" placeholder="&#128269;" {...register("massFilter")} />
        <Form.Text className="text-muted">{t("deno-search_code_order_partner_note_creator")}</Form.Text>
      </Form.Group>
      <Row>
        <Col xs={6}>
          <p className="mb-0 mt-1 text-center">{type === "PD" ? t("deno-exported") : t("ord-imported")}</p>
          <BooleanDropdownRhf name={type === "PD" ? "exported" : "imported"} className="beGrayButton" />
        </Col>
        <Col xs={6}>
          <p className="mb-0 mt-1 text-center">{t("biz-closed")}</p>
          <BooleanDropdownRhf name="closed" className="beGrayButton" />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <p className="mb-0 mt-1 text-center">{t("deno-approved")}</p>
          <BooleanDropdownRhf name="approved" className="beGrayButton" />
        </Col>
        <Col xs={6}>
          <p className="mb-0 mt-1 text-center">{t("canceled")}</p>
          <BooleanDropdownRhf name="cancelled" className="beGrayButton" />
        </Col>
      </Row>
    </>
  );
}
