import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";
import { NO_PIN_validate } from "../../comp/pin";
import { useFormContext } from "react-hook-form";
import { StockNotificationFormValues } from "./StockNotifications";

// 0 - not set
// 1 - entering pin
// 2 - unlocked
// 3 - unknown batch with exception
type Unlocking = 0 | 1 | 2 | 3;

interface PinProps {
  nop_IDPolozky: number;
  disabledForms: boolean;
  exceptionCase: boolean;
}

export function Pin({ nop_IDPolozky, disabledForms, exceptionCase }: PinProps) {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext<StockNotificationFormValues>();
  const [unlocking, setUnlocking] = useState<Unlocking>(0);

  function unlock(unlockStatus: 2 | 3) {
    const unlockedItems = getValues("unlockedItems");
    const item = {};
    item[nop_IDPolozky] = unlockStatus;
    setValue("unlockedItems", { ...unlockedItems, ...item }, { shouldValidate: true });
    setValue(`items.${nop_IDPolozky}.batches.0.batch`, "neznamá", { shouldValidate: true, shouldDirty: true });
  }

  function changePIN(val) {
    const PIN_ok = NO_PIN_validate(nop_IDPolozky, 1, val);
    if (PIN_ok === true) {
      unlock(2);
      setUnlocking(2);
    }
  }

  function setKnown() {
    setUnlocking(0);
    setValue(`items.${nop_IDPolozky}.batches.0.batch`, "", { shouldValidate: true, shouldDirty: true });
    const unlockedItems = getValues("unlockedItems");
    unlockedItems[nop_IDPolozky] = undefined;
    setValue("unlockedItems", { ...unlockedItems }, { shouldValidate: true });
  }

  function handleUnknownBatch(exceptionCase) {
    if (exceptionCase) {
      setUnlocking(3); //nastavuju stav 3 v případě exception case
      unlock(3);
    } else {
      setUnlocking(1);
    }
  }

  switch (unlocking) {
    case 1:
      return (
        <div className="d-inline-block mb-0 ms-1">
          <InputGroup style={{ maxWidth: "100px" }} className="">
            <Form.Control type="pin" placeholder="PIN" onChange={(ev) => changePIN(ev.target.value)} />
            <InputGroup.Text style={{ cursor: "pointer" }} onClick={() => setUnlocking(0)}>
              X
            </InputGroup.Text>
          </InputGroup>
        </div>
      );
    case 2:
    case 3:
      return (
        <Button
          style={{ width: "20px" }}
          variant="dark"
          size="sm"
          className="w-100 my-2 mb-1"
          onClick={() => setKnown()}
        >
          {t("ord-known_batch")}
        </Button>
      );
    case 0:
      return (
        <Button
          variant="dark"
          size="sm"
          className="w-100 my-2 mb-1"
          disabled={disabledForms}
          onClick={() => handleUnknownBatch(exceptionCase)}
        >
          {t("ord-unknown_batch")}
        </Button>
      );
    default:
      return (
        <p>
          {t("error")}&nbsp; {unlocking}
        </p>
      );
  }
}
