/**
 * Reponsive component for product parameters view - density, amount, unit, package weight, package type, ADR.
 * @module products/product-view
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import {
    make_product_check_tag, product_warehouse_property_defined,
    convert_warehouse_container, romanize_packaging_group
} from "./product-checks";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

//seen all - OK

export function DensityAmountUnitWeightView({ ErrorBadge, id, density, densityUnit, amount, unit, packageWeight,
    bg = false, border = false, mobile = false }) {
    const { t } = useTranslation();
    const background = bg ? " bg-red" : "";
    const borderRight = border ? " border-end" : "";
    return (
        !mobile ?
            <>
                <td className={"text-center align-middle" + background}>
                    {amount ? amount : <>&mdash;</>}
                    &nbsp;
                    {unit ? unit : <>&mdash;</>}
                    <ErrorBadge tag={make_product_check_tag(id, "amount")} />
                    <ErrorBadge tag={make_product_check_tag(id, "unit")} />
                </td>
                <td className={"text-center align-middle" + background}>
                    {density ? density : <>&mdash;</>}
                    &nbsp;
                    {densityUnit ? densityUnit : <> &mdash;</>}
                    <ErrorBadge tag={make_product_check_tag(id, "twist-unit-kg")} />
                    <ErrorBadge tag={make_product_check_tag(id, "warehouse-unit-kg")} />
                </td>
                <td className={"text-center align-middle" + background + borderRight}>
                    <PackageWeightView value={packageWeight} />&nbsp;{t('prod-kg')}
                    <ErrorBadge tag={make_product_check_tag(id, "missing-amount-or-density")} />
                    <ErrorBadge tag={make_product_check_tag(id, "package-weight-compare")} />
                </td>
            </>
            :
            <>
                <tr className={background}>
                    <td className="text-end p-0">{t('ord-content_w_unit')}:  &nbsp; </td>
                    <td className="p-0">
                        {amount ? amount : <>&mdash;</>}
                        &nbsp;
                        {unit ? unit : <>&mdash;</>}
                        <ErrorBadge tag={make_product_check_tag(id, "amount")} />
                        <ErrorBadge tag={make_product_check_tag(id, "unit")} />
                    </td>
                </tr>
                <tr className={background}>
                    <td className="text-end p-0">{t('ord-density')}:   &nbsp;</td>
                    <td className="p-0">
                        {density ? density : <>&mdash;</>}
                        &nbsp;
                        {densityUnit ? densityUnit : <> &mdash;</>}
                        <ErrorBadge tag={make_product_check_tag(id, "twist-unit-kg")} />
                        <ErrorBadge tag={make_product_check_tag(id, "warehouse-unit-kg")} />
                    </td>

                </tr>
                <tr className={background}>
                    <td className="text-end p-0">{t('ord-1_pack_weight')}:  &nbsp; </td>
                    <td className="p-0">
                        <PackageWeightView value={packageWeight} />&nbsp;{t('prod-kg')}
                        <ErrorBadge tag={make_product_check_tag(id, "missing-amount-or-density")} />
                        <ErrorBadge tag={make_product_check_tag(id, "package-weight-compare")} />
                    </td>
                </tr>
            </>
    );
}

export function ProductContainerView({ id, wid = undefined, container, ErrorBadge, remap = false, border = false, bg = false, mobile = false }) {
    const background = bg ? " bg-red" : "";

    const container_fmt = product_warehouse_property_defined(container) ?
        remap ? convert_warehouse_container(wid, container) : container
        : <>&mdash;</>;
    const borderLeft = border ? " border-start" : "";
    return (
        !mobile ?
            <td className={"text-center align-middle" + borderLeft + background}>
                {container_fmt}
                <ErrorBadge tag={make_product_check_tag(id, "k_HSHObal5")} />
                <ErrorBadge tag={make_product_check_tag(id, "duppack")} />
            </td>
            :
            <tr className={background}>
                <td className="text-end p-0"> Obal:  &nbsp;</td>
                <td className="p-0">
                    {container_fmt}
                    <ErrorBadge tag={make_product_check_tag(id, "k_HSHObal5")} />
                    <ErrorBadge tag={make_product_check_tag(id, "duppack")} />
                </td>
            </tr>
    );
}

export function ProductADRView({ id, TridaADR, k_ADRObalovaSkupina, k_ADRUNCislo,
    ErrorBadge, romanized = false, bg = false, mobile = false }) {
    const background = bg ? " bg-red" : "";

    const TridaADR_fmt = TridaADR ? TridaADR : <>&mdash;</>;
    const k_ADRObalovaSkupina_fmt = k_ADRObalovaSkupina !== null ?
        romanized ? k_ADRObalovaSkupina : romanize_packaging_group(k_ADRObalovaSkupina)
        :
        <>&mdash;</>;
    const k_ADRUNCislo_fmt = k_ADRUNCislo ? k_ADRUNCislo : <>&mdash;</>;
    return (
        !mobile ?
            <>
                <td className={"text-center align-middle" + background}>
                    {TridaADR_fmt}
                    <ErrorBadge tag={make_product_check_tag(id, "TridaADR")} />
                    <ErrorBadge tag={make_product_check_tag(id, "TridaADR2")} />
                    <ErrorBadge tag={make_product_check_tag(id, "wrong-wh-adr")} />
                </td>
                <td className={"text-center align-middle" + background}>
                    {k_ADRObalovaSkupina_fmt}
                    <ErrorBadge tag={make_product_check_tag(id, "k_ADRObalovaSkupina")} />
                    <ErrorBadge tag={make_product_check_tag(id, "k_ADRObalovaSkupina2")} />
                    <ErrorBadge tag={make_product_check_tag(id, "wrong-wh-package-group")} />
                </td>
                <td className={"text-center align-middle" + background}>
                    {k_ADRUNCislo_fmt}
                    <ErrorBadge tag={make_product_check_tag(id, "k_ADRUNCislo")} />
                    <ErrorBadge tag={make_product_check_tag(id, "k_ADRUNCislo2")} />
                    <ErrorBadge tag={make_product_check_tag(id, "wrong-wh-unnum")} />
                </td>
            </>
            :
            <>
                <Row className="g-0">
                    <Col className={background}>
                        Třída ADR: {TridaADR_fmt}
                        <ErrorBadge tag={make_product_check_tag(id, "TridaADR")} />
                        <ErrorBadge tag={make_product_check_tag(id, "TridaADR2")} />
                        <ErrorBadge tag={make_product_check_tag(id, "wrong-wh-adr")} />
                    </Col>
                    <Col className={background + "text-center"}>
                        Obalová sk.: {k_ADRObalovaSkupina_fmt}
                        <ErrorBadge tag={make_product_check_tag(id, "k_ADRObalovaSkupina")} />
                        <ErrorBadge tag={make_product_check_tag(id, "k_ADRObalovaSkupina2")} />
                        <ErrorBadge tag={make_product_check_tag(id, "wrong-wh-package-group")} />
                    </Col>
                    <Col className={background + "text-end"}>
                        Číslo UN: {k_ADRUNCislo_fmt}
                        <ErrorBadge tag={make_product_check_tag(id, "k_ADRUNCislo")} />
                        <ErrorBadge tag={make_product_check_tag(id, "k_ADRUNCislo2")} />
                        <ErrorBadge tag={make_product_check_tag(id, "wrong-wh-unnum")} />
                    </Col>
                </Row>
            </>
    );
}

/**
 * Display's "-" if there isnt a value, in other cases displays value as string.
 * 
 * @param {any} value - most likely is null or fraction
 * @returns {component}
 */
export function PackageWeightView(value) {
    //console.log(value);
    if (!value) {
        return <>&ndash;</>
    } else {
        return String(value.value);
    }
}

