import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { his_fetch_success, HisFetchStatus } from "../../comp/FetchLoader";
import Button from "react-bootstrap/Button";
import { findLongestPrefixLen } from "../products-check-overview-horizontal";
import { date_time_format, number_formatTime } from "../../lib/date-utils";
import { make_checker } from "../../comp/checker";
import { OkOrProblemsCard } from "../../comp/problems-card";
import { ShowADR } from "../../comp/adr";
import { warehouses } from "../../lists/warehouses-defs";
import {
  check_basic_package,
  check_product_adr,
  check_product_batches_expirations_monitoring,
  check_product_expiration,
  check_product_his_approval,
  check_product_hsh_adr,
  check_product_hsh_amount,
  check_product_hsh_container,
  check_product_hsh_density,
  check_product_hsh_MJ,
  check_product_pH,
  check_product_twist_approval,
  check_product_warehouse_adr,
  check_product_warehouse_container,
  check_product_warehouse_density,
  check_product_wh_amount,
  check_product_wh_MJ,
  check_suppliers_address_type,
  check_suppliers_addresses,
  package_weight_compare,
} from "../product-checks";
import { check_package_weight_kg_input, get_package_weight_kg } from "../../lib/generic-checks";
import { Emails } from "../../emails";
import { ErrorWrap } from "../../comp/errorwrap";
import { HistoryApp, ProductCompareApp } from "./ProductDetailApp";
import {
  AltNames,
  BasicInfo,
  HistoryTableWeb,
  IsActive,
  Packagings,
  ProductCompareWeb,
  Supplier,
} from "./ProductDetailWeb";
import Accordion from "react-bootstrap/Accordion";
import { statuses_sending_card_strs } from "../../lists/statuses-sending-card";
import { useTranslation } from "react-i18next";
import { ProductConfiguration } from "./ProductConfig";
import { Notes } from "../../notes/Notes";
import { useQuery } from "@tanstack/react-query";
import { getProduct, getProductWarehouseStatuses } from "../../api/products";
import { queryStatusToLoadedStatus } from "../../api/common";
import { useUserLogin } from "../../UserLoginProvider";
import Fraction from "fraction.js";

//new code for přijato (čas odmítnutí nebo přijetí serverem - pokud je 4 tak tam dát 4, pokud 5 tak 5, pokud ani jeden, tak vzít 5, potom 4, potom empty)
function status_4or5(whStatuses: Dto.ProductDetailWarehouseStatus, warehouse_status?: number) {
  const status_4_date = whStatuses.lastConfirmed ? whStatuses.lastConfirmed : false;
  //	console.log(status_4_date);
  //	console.log(warehouse_status);
  const status_5_date = whStatuses.lastRejected ? whStatuses.lastRejected : false;
  if (warehouse_status === 4) {
    return status_4_date;
  } else if (warehouse_status === 5) {
    return status_5_date;
  } else if (status_5_date) {
    return status_5_date;
  } else if (status_4_date) {
    return status_4_date;
  } else {
    return "";
  }
}

// Returns actual product from the warehouse - should be changed after
// merging to warehouse_statuses on the backend
function getProductInWarehouse(currentWarehouseCards: Dto.ProductWarehouseCard[], wid: number) {
  const productsInWarehouse = currentWarehouseCards.filter((p) => p.warehouseId === wid);

  const isProductInWarehouse = productsInWarehouse.length > 0;
  return isProductInWarehouse ? productsInWarehouse[0] : {};
}

function isWarehouseStatusChanging(warehouseStatus: Dto.ProductDetailWarehouseStatus, piw: Dto.ProductWarehouseCard) {
  if (warehouseStatus.currentStatus === 6) {
    return false;
  }
  var changing = false;
  if (warehouseStatus.lastSending) {
    changing = true;
  }
  if (warehouseStatus.lastSent) {
    changing = true;
  }
  if (warehouseStatus.currentStatus === 4) {
    if (warehouseStatus.lastSent < warehouseStatus.lastConfirmed) {
      changing = false;
    }
  } else if (warehouseStatus.currentStatus === 5) {
    if (warehouseStatus.lastSent < warehouseStatus.lastRejected) {
      changing = false;
    }
  } else {
    // 1, 2, 3
    if (warehouseStatus.lastRejected) {
      if (warehouseStatus.lastSent < warehouseStatus.lastRejected) {
        changing = false;
      }
    } else if (warehouseStatus.lastConfirmed) {
      if (warehouseStatus.lastSent < warehouseStatus.lastConfirmed) {
        changing = false;
      }
    }
  }
  if (piw.created) {
    if (warehouseStatus.lastSent) {
      if (warehouseStatus.lastSent < piw.created) {
        changing = false;
      }
    }
  }
  return changing;
}

function areWarehouseStatusesChanging(
  currentWarehouseCards: Dto.ProductWarehouseCard[],
  warehouseStatuses: Dto.ProductDetailWarehouseStatus[]
) {
  return (
    warehouseStatuses
      .map((w) => w.warehouseId)
      .filter((wid) =>
        isWarehouseStatusChanging(
          warehouseStatuses.find((w) => w.warehouseId === wid),
          getProductInWarehouse(currentWarehouseCards, wid)
        )
      ).length > 0
  );
}

export function ProductDetail() {
  const { t } = useTranslation();

  const { id } = useParams();

  const userLogin = useUserLogin();

  const [reloadingPeriod, setReloadingPeriod] = useState(0); //0 = no backgroud reloading, or milisecond

  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["product", id],
    queryFn: async ({ queryKey, signal }) => {
      const response = await getProduct(queryKey[1], { signal });
      if (areWarehouseStatusesChanging(response.currentWarehouseCards, response.warehouseStatuses)) {
        setReloadingPeriod(5 * 1000);
      }
      return response;
    },
  });

  const { data: newWarehouseStatuses } = useQuery({
    queryKey: ["product", id, "warehouseStatuses"],
    queryFn: ({ queryKey, signal }) => getProductWarehouseStatuses(queryKey[1], { signal }),
    enabled: reloadingPeriod > 0,
    refetchInterval: reloadingPeriod,
  });

  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const reloadIt = () => {
    return refetch();
  };

  if (!his_fetch_success(loadedStatus)) {
    return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
  }

  const {
    product,
    alternativeNames: altNames,
    currentWarehouseCards: productCheck,
    warehouseStatuses,
    emails,
    supplier,
    packagings,
    cardsHistory: cardHistory,
  } = data;
  const suppliers = !!supplier ? [supplier] : [];

  const warehousesStatuses = newWarehouseStatuses ?? warehouseStatuses;

  const { performCheck, ErrorBadge, TodoList, checksOK, worstCheckResult } = make_checker();

  check_product_hsh_container(performCheck, "hsh", product.hshPackage); //danger
  check_product_hsh_amount(performCheck, "hsh", product.packageUnitOfMeasurementQuantity); //danger
  check_product_hsh_MJ(performCheck, "hsh", product.storageUnitOfMeasurementCode); //danger
  check_product_hsh_density(performCheck, "hsh", product.unitOfMeasurementWeight, product.unitOfMeasurementUnit); //danger
  check_product_hsh_adr(
    performCheck,
    "hsh",
    product.adr,
    product.adrClass,
    product.adrPackingGroup?.toString(),
    product.adrUnNumber
  ); //danger

  // 666: "Počítání hmotnosti obalu v kg" - pro zobrazení a vstup pro check
  //console.log(product);
  const [packageWeightInputsOK_HSH] = check_package_weight_kg_input(
    product.unitOfMeasurementWeight,
    product.unitOfMeasurementUnit,
    product.packageUnitOfMeasurementQuantity
  );
  const package_weight_kg_hsh = packageWeightInputsOK_HSH
    ? get_package_weight_kg(
        product.unitOfMeasurementWeight,
        product.unitOfMeasurementUnit,
        product.packageUnitOfMeasurementQuantity
      )
    : null;

  const soFarSoGood = checksOK();

  const preprocessedWarehouses = warehouses.map(function (wh): PreprocessedWarehouse {
    const piw = getProductInWarehouse(productCheck, wh[0]);
    const isProductInWarehouse = !!piw["productName"];
    // 666: "Počítání hmotnosti obalu v kg" - pro zobrazení a vstup pro check
    //console.log(piw);

    const [packageWeightInputsOK_wh] = check_package_weight_kg_input(
      piw.unitOfMeasurementWeightInKg,
      "kg",
      piw.packageUnitOfMeasurementQuantity
    ); //ze skladu to nechodí
    const package_weight_kg_wh = packageWeightInputsOK_wh
      ? get_package_weight_kg(piw.unitOfMeasurementWeightInKg, "kg", piw.packageUnitOfMeasurementQuantity)
      : null;

    //	const worth_display2 = piw.pcr_MnozstviSklMjVObalu && piw.pcr_HmotnostMjVKg;
    //const package_weight_kg_wh_count = Fraction(piw.pcr_MnozstviSklMjVObalu).mul(Fraction(piw.pcr_HmotnostMjVKg));
    //	const package_weight_kg_wh = worth_display2 ? String(package_weight_kg_wh_count) : <>&mdash;</>;

    if (isProductInWarehouse && soFarSoGood) {
      check_product_warehouse_container(performCheck, "wh-" + wh[0], product.hshPackage, wh[0], piw.hshPackage, true); //warning
      check_product_wh_amount(
        performCheck,
        "wh-" + wh[0],
        piw.packageUnitOfMeasurementQuantity,
        product.packageUnitOfMeasurementQuantity,
        true
      ); //warning
      check_product_wh_MJ(
        performCheck,
        "wh-" + wh[0],
        piw.storageUnitOfMeasurementCode,
        product.storageUnitOfMeasurementCode,
        true
      ); //warning
      check_product_warehouse_density(
        performCheck,
        "wh-" + wh[0],
        piw.unitOfMeasurementWeightInKg,
        product.unitOfMeasurementWeight,
        true
      ); //warning
      package_weight_compare(performCheck, "wh-" + wh[0], package_weight_kg_hsh, package_weight_kg_wh, true); //warning
      check_product_warehouse_adr(
        performCheck,
        "wh-" + wh[0],
        product.adr,
        product.adrClass,
        product.adrPackingGroup?.toString(),
        product.adrUnNumber,
        piw.adrClass,
        piw.adrPackingGroup,
        piw.adrUnNumber,
        true
      ); //warning
    }
    const stavKontroly =
      checksOK("package-weight-compare-wh-" + wh[0], "warning") && isProductInWarehouse && soFarSoGood
        ? t("yes")
        : t("no"); //stav kontroly má časem říkat, jeslti se daný produkt se smí avizovat, produkt je v pořádku pro účely skladů

    //shoduje se do jisté míry název produktu?
    const wNazevProduktu = piw.productName || "";
    const prefixLen = isProductInWarehouse ? findLongestPrefixLen([product.productName, wNazevProduktu]) : 0;
    const nazevPrefix = wNazevProduktu.substring(0, prefixLen);
    const nazevSuffix = wNazevProduktu.substring(prefixLen);
    const superClass5 = "alert-success";
    //zpětně načtena
    const readback_datetime = piw.created;
    const readback_datetime_str = readback_datetime ? date_time_format(readback_datetime) : "-";
    const readback_datetime_class = readback_datetime ? "text-end align-middle" : "text-center align-middle";
    // stav komunikace
    //const whStatuses = warehousesStatuses[wh[0]];
    const whStatuses = warehousesStatuses.find((w) => w.warehouseId === wh[0]);
    const isThereWarehouse = (whStatuses && true) || false;
    const warehouse_status = !isThereWarehouse ? undefined : whStatuses["currentStatus"];
    const warehouse_status_str = t(statuses_sending_card_strs[warehouse_status]) || "-";
    //odeslána
    const sent_status_2 = !isThereWarehouse ? "" : whStatuses.lastSent || "";
    const sent_status_str = sent_status_2 ? date_time_format(sent_status_2) : "-";
    //přijata
    const received_status = !isThereWarehouse ? "" : status_4or5(whStatuses, warehouse_status);
    const received_status_str = received_status ? date_time_format(received_status) : "-";
    const canCompareSentReceived = sent_status_2 && received_status;
    const receivedIsSmaller = received_status < sent_status_2;
    const received_class = canCompareSentReceived ? (receivedIsSmaller ? " alert-danger" : "") : "";

    const readback_maybe_confirmed = readback_datetime && sent_status_2;
    const readback_confirmed = readback_maybe_confirmed && sent_status_2 < readback_datetime;
    const readback_class =
      readback_datetime_class +
      (readback_maybe_confirmed ? (readback_confirmed ? " alert-success" : " alert-danger") : " alert-secondary");

    const ADRBelongsToADRWarehouse = wh[0] !== 22 && wh[0] !== 30 && product.adr;
    const states_1_2_3 = warehouse_status === 1 || warehouse_status === 2 || warehouse_status === 3;
    const states_2_3 = warehouse_status === 2 || warehouse_status === 3;
    const compliance_can_send_ADR_anywhere = userLogin.userInfo.roleCompliance || userLogin.userInfo.hisSuperuser;

    const title = wh[0] !== 22 && wh[0] !== 30 && product.adr ? t("the_wh_isnt_ADR_but_product_is") : "";

    const timeDiff = sent_status_2 ? Math.abs(new Date().getTime() - new Date(sent_status_2).getTime()) / 1000 : 0;
    const timeOver = timeDiff > 15 * 60;
    const showCancel = timeOver && states_2_3 && !readback_confirmed;
    const show_timeDiff =
      states_2_3 && !readback_confirmed ? (
        <>
          {number_formatTime(timeDiff)}
          <br />
        </>
      ) : (
        ""
      );
    return {
      warehouseId: wh[0],
      lineRowSpan: wh[1],
      piw: piw,
      isProductInWarehouse: isProductInWarehouse,
      package_weight_kg_wh: package_weight_kg_wh,
      stavKontroly: stavKontroly,
      nazevPrefix: nazevPrefix,
      nazevSuffix: nazevSuffix,
      superClass5: superClass5,
      readback_datetime_str: readback_datetime_str,
      warehouse_status_str: warehouse_status_str,
      sent_status_str: sent_status_str,
      received_status_str: received_status_str,
      received_class: received_class,
      readback_class: readback_class,
      states_1_2_3: states_1_2_3,
      readback_confirmed: readback_confirmed,
      ADRBelongsToADRWarehouse: ADRBelongsToADRWarehouse,
      compliance_can_send_ADR_anywhere: compliance_can_send_ADR_anywhere,
      showCancel: showCancel,
      show_timeDiff: show_timeDiff,
      title: title,
    };
  });

  check_suppliers_addresses(performCheck, "hsh", suppliers); //danger
  check_suppliers_address_type(performCheck, "hsh", suppliers); //danger
  check_product_twist_approval(performCheck, "hsh", product.approvedInTwist); //danger
  check_product_his_approval(performCheck, "hsh", product.needsApproval, product.warehouseConfiguration?.approvedInHis); //danger
  check_product_pH(performCheck, "hsh", product.adrClass, product.warehouseConfiguration?.ph); //danger

  const configured = (product.warehouseConfiguration?.type ?? 0) > 0; //používá se i na dalších místech tu
  check_product_batches_expirations_monitoring(performCheck, "hsh", configured); //danger
  check_product_expiration(
    performCheck,
    "hsh",
    product.warehouseConfiguration?.type,
    product.warehouseConfiguration?.months
  ); //danger
  check_product_adr(performCheck, "hsh", product.adr, true); //warning
  check_basic_package(performCheck, "hsh", packagings); //danger

  const approved = !!product.warehouseConfiguration?.productApproved;
  const no_fatal_errors = checksOK();

  return (
    <ErrorWrap>
      <h4>
        <Button size="sm" className="me-2 d-inline" onClick={reloadIt}>
          <Image src="/img/reload.svg" height="19" />
        </Button>
        {t("product")} {product.productId}: {product.productName}
        <IsActive active={product.active} />
        <ShowADR isADR={product.adr} />
      </h4>
      <Row>
        <Col lg={4} className="mb-3">
          <BasicInfo product={product} />
        </Col>
        <Col lg={4}>
          <OkOrProblemsCard
            //ok={no_fatal_errors}
            headerOk={t("product_card_is_ok")}
            textOk=""
            todo={<TodoList />}
            variant={worstCheckResult()}
          />
        </Col>
        <Col lg={4}>
          <ProductConfiguration product={product} ErrorBadge={ErrorBadge} />
        </Col>
      </Row>
      <ProductWarehouses
        preprocessedWarehouses={preprocessedWarehouses}
        package_weight_kg_hsh={package_weight_kg_hsh}
        product={product}
        approved={approved}
        configured={configured}
        no_errors={no_fatal_errors}
        reloadIt={reloadIt}
        ErrorBadge={ErrorBadge}
        //right_package_group={right_package_group}
      />
      <CardsHistory cardHistory={cardHistory} ErrorBadge={ErrorBadge} />
      <Row>
        <Col lg={5}>
          <Supplier suppliers={suppliers} product={product} ErrorBadge={ErrorBadge} />
          <br />
          <Packagings packagings={packagings} ErrorBadge={ErrorBadge} />
          <br />
        </Col>
        <Col lg={7}>
          <AltNames names={altNames} />

          <br />
          <Notes objectType="Product" objectCode={product?.productId?.toString() ?? ""} enabledNotes={[1]} />
          <br />
        </Col>
      </Row>
      {emails.length > 0 ? (
        <>
          <Card className="myShadow mb-4">
            <Card.Body className="px-0 pb-0">
              <Emails
                pageSize={5}
                existingEmails={emails}
                noFetch
                optionalHeader={t("prod-emails") + " " + product.productId}
              />
            </Card.Body>
          </Card>
        </>
      ) : (
        ""
      )}
    </ErrorWrap>
  );
}

function getHistoryInWarehouse(history: Dto.ProductWarehouseCard[], wid: number) {
  return history.filter((p) => p.warehouseId === wid);
}

function cardHistoryRecordEqual(h1: Dto.ProductWarehouseCard, h2: Dto.ProductWarehouseCard) {
  const keys = [
    "unitOfMeasurementWeightInKg",
    "storageUnitOfMeasurementCode",
    "packageUnitOfMeasurementQuantity",
    "productName",
    "adrClass",
    "gwCheckBatch",
    "gwCheckBbd",
    "adrPackingGroup",
    "adrUnNumber",
    "hshPackage",
    "safetySheet",
  ];
  return keys.reduce(function (acc, key) {
    return acc && h1[key] === h2[key]; //předchozí hodnoty a nová hodnota, 1 a 1 a 0... pokud tam bude aspoń jedna nula, tak smůla
  }, true);
}

function cardHistoryRecordAdjoined(h1: Dto.ProductWarehouseCard, h2: Dto.ProductWarehouseCard) {
  const t1s = h1.invalidated;
  const t2s = h2.created;
  if (t1s === null) {
    throw new Error("Chyba v historii karet: " + String(h1));
  }
  const d1 = new Date(t1s).getTime();
  const d2 = new Date(t2s).getTime();
  const tdiff_ms = d2 - d1;
  const tdiff = tdiff_ms / 1000;
  const min_diff = -3600;
  if (tdiff < min_diff) {
    console.log("Chybné pořadí záznamů v historii karet: " + JSON.stringify(h1) + " > " + JSON.stringify(h2));
  }
  const max_diff = 12 * 3600;
  return tdiff < max_diff;
}

function compactifyCardsHistory(history: Dto.ProductWarehouseCard[]) {
  if (history.length < 2) {
    return history;
  }
  const first_record = history[0];
  const rest_records = history.slice(1);
  const result = rest_records
    .reduce(
      //procházím páry za sebou a něco generuju
      function (acc, rec) {
        //rec = aktuální záznam, který zpracovávámm z původní obsáhlé historie, v akumulátoru vzniká kompaktifikovaná historie - bud se přidávají záznamy, nebo je mění platnost
        const current_head_record = acc[0]; //vytáhnu z acc, co je tam jako poslední, pak když jsou datově stejný a navazujou na sebe tak prodloužím platnost toho, co tam byl a vrazím ho zpět
        if (cardHistoryRecordEqual(current_head_record, rec) && cardHistoryRecordAdjoined(current_head_record, rec)) {
          // Merge
          const acc_rest = acc.slice(1);
          const new_head: Dto.ProductWarehouseCard = {
            ...current_head_record,
            invalidated: rec.invalidated, //tímto přepisuju tento klíč, který byl původně v tom ...current_head_record
          };
          return [new_head].concat(acc_rest);
        } else {
          //nebo ten záznam přidám jako nový do kompaktifikované historie
          // Add
          return [rec].concat(acc);
        }
      },
      [first_record]
    )
    .reverse(); //do akumulátoru přijde pole, které obsahuje first record
  return result;
}

interface CardsHistoryProps {
  cardHistory: Dto.ProductWarehouseCard[];
  ErrorBadge: React.FunctionComponent<{ tag: string }>;
}

export interface PreprocessedHistory {
  warehouseId: number;
  history: Dto.ProductWarehouseCard[];
  historyLength: number;
  reducedHistory: Dto.ProductWarehouseCard[];
}

function CardsHistory({ cardHistory, ErrorBadge }: CardsHistoryProps) {
  const { t } = useTranslation();

  const preprocessedHistory = warehouses.map(function (wh): PreprocessedHistory {
    const history = getHistoryInWarehouse(cardHistory, wh[0]);
    const historyLength = history.length;
    const reducedHistory = compactifyCardsHistory(history);

    return {
      warehouseId: wh[0],
      history: reducedHistory,
      historyLength: historyLength,
      reducedHistory: history,
    };
  });

  return (
    <ErrorWrap>
      <Card className="mb-4 mt-4 myShadow">
        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h4 className=" ms-2 mt-1">{t("prod-card_wh_history")}</h4>
            </Accordion.Header>
            <Accordion.Body>
              <Card.Body className="p-0 m-0">
                <HistoryTableWeb preprocessedHistory={preprocessedHistory} />
                <HistoryApp preprocessedHistory={preprocessedHistory} ErrorBadge={ErrorBadge} />
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </ErrorWrap>
  );
}

interface ProductWarehousesProps {
  preprocessedWarehouses: PreprocessedWarehouse[];
  package_weight_kg_hsh: number;
  product: Dto.ProductDetailProduct;
  approved: boolean;
  configured: boolean;
  no_errors: boolean;
  reloadIt: () => void;
  ErrorBadge: any;
}

function ProductWarehouses({
  preprocessedWarehouses,
  package_weight_kg_hsh,
  product,
  approved,
  configured,
  no_errors,
  ErrorBadge,
}: ProductWarehousesProps) {
  const { t } = useTranslation();

  return (
    <ErrorWrap>
      <Card className="mb-4 mt-4 myShadow">
        <Card.Body className="p-0 m-0">
          <h4 className=" ms-4 mt-3 mb-3">{t("prod-wh_cards")}</h4>
          <ProductCompareWeb
            product={product}
            ErrorBadge={ErrorBadge}
            package_weight_kg_hsh={package_weight_kg_hsh}
            preprocessedWarehouses={preprocessedWarehouses}
            approved={approved}
            configured={configured}
            no_errors={no_errors}
          />
          <ProductCompareApp
            product={product}
            ErrorBadge={ErrorBadge}
            package_weight_kg_hsh={package_weight_kg_hsh}
            preprocessedWarehouses={preprocessedWarehouses}
            approved={approved}
            configured={configured}
            no_errors={no_errors}
          />
        </Card.Body>
      </Card>
    </ErrorWrap>
  );
}

export interface PreprocessedWarehouse {
  warehouseId: number;
  lineRowSpan: number;
  piw: Dto.ProductWarehouseCard;
  isProductInWarehouse: boolean;
  package_weight_kg_wh: Fraction;
  stavKontroly: string;
  nazevPrefix: string;
  nazevSuffix: string;
  superClass5: string;
  readback_datetime_str: string;
  warehouse_status_str: string;
  sent_status_str: string;
  received_status_str: string;
  received_class: string;
  readback_class: string;
  states_1_2_3: boolean;
  readback_confirmed: boolean;
  ADRBelongsToADRWarehouse: boolean;
  compliance_can_send_ADR_anywhere: boolean;
  showCancel: boolean;
  show_timeDiff: "" | React.ReactElement;
  title: string;
}
