/**
 * Margins with filters
 * @module invoices/margins
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Button, Table } from 'react-bootstrap';
import { format_amount } from '../lib/format';
import { date_formatCZ, date_1stOfTheMonth, date_getLastMonthLastDate } from '../lib/date-utils';
import { Boolean } from '../comp/boolean';
import { Pager } from '../comp/pager';
import {
    icompare,
    filter_rule
} from '../lib/utils';
import Form from 'react-bootstrap/Form';
import { DatePickerCustom } from '../comp/DatePicker';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { LinkContainer } from 'react-router-bootstrap';
import { date_formatISO } from '../lib/date-utils';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function Margins({ userlogin }) {
    const { t } = useTranslation();

    const { startDate } = useParams();
    const { endDate } = useParams();

    const [margins, setMargins] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [offset, setOffset] = useState(0);
    const [minDate, setMinDate] = useState(startDate ? new Date(startDate) : date_1stOfTheMonth(new Date(), true)); // prvního minulý měsíc
    const [maxDate, setMaxDate] = useState(endDate ? new Date(endDate) : date_getLastMonthLastDate()); // posledního minulý měsíc
    const [filterMargin, setFilterMargin] = useState("");
    const [filterMarginPercentage, setFilterMarginPercentage] = useState("");
    const [filterBranch, setFilterBranch] = useState("");
    const [filterContract, setFilterContract] = useState("");
    const [filterPartner, setFilterPartner] = useState("");
    const [filterProduct, setFilterProduct] = useState("");
    const [filterCosts, setFilterCosts] = useState("");
    const [filterInvoiceID, setFilterInvoiceID] = useState("");
    const [filterPrice, setFilterPrice] = useState("");
    const [filterRabat, setFilterRabat] = useState("");

    //console.log(minDate);
    //console.log(maxDate);
    var y1 = minDate.getFullYear();
    var m1 = minDate.getMonth() + 1;
    var d1 = minDate.getDate();
    const y2 = maxDate.getFullYear();
    const m2 = maxDate.getMonth() + 1;
    const d2 = maxDate.getDate();
    //console.log(y1);
    // console.log(m1);
    //console.log(d1);

    const reloadIt = () => {
        setMargins(null);
        setLoadedStatus(0);
    }

    useEffect(() => {
        if (margins === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/margins?year1=" + y1 + "&month1=" + m1 + "&day1=" + d1 + "&year2=" + y2 + "&month2=" + m2 + "&day2=" + d2,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setMargins(result.margins);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setMargins("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, margins, y1, m1, d1, y2, m2, d2]);


    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt}
        />;
    }

    const handleChangeMargin = function (event) {
        setFilterMargin(event.target.value);
        setOffset(0);
    }

    const handleChangeMarginPercentage = function (event) {
        setFilterMarginPercentage(event.target.value);
        setOffset(0);
    }

    const handleChangeContract = function (event) {
        setFilterContract(event.target.value);
        setOffset(0);
    }

    const handleChangeBranch = function (event) {
        setFilterBranch(event.target.value);
        setOffset(0);
    }

    const handleChangePartner = function (event) {
        setFilterPartner(event.target.value);
        setOffset(0);
    }

    const handleChangeProduct = function (event) {
        setFilterProduct(event.target.value);
        setOffset(0);
    }
    const handleChangeCosts = function (event) {
        setFilterCosts(event.target.value);
        setOffset(0);
    }
    const handleChangeInvoiceID = function (event) {
        setFilterInvoiceID(event.target.value);
        setOffset(0);
    }
    const handleChangePrice = function (event) {
        setFilterPrice(event.target.value);
        setOffset(0);
    }
    const handleChangeRabat = function (event) {
        setFilterRabat(event.target.value);
        setOffset(0);
    }

    const margins_filtered = (margins || []).filter(
        function (ma) {
            return (
                ((filterCosts.length === 0) ||
                    (icompare(ma.fn_NakVceDop, filterCosts) ||
                        icompare(ma.fn_NakladCZK, filterCosts) ||
                        icompare(ma.fn_Doprava, filterCosts)
                    )) &&
                ((filterMargin.length === 0) ||
                    (icompare(ma.fn_MarzeVcDop, filterMargin) ||
                        (icompare(ma.fn_Marže, filterMargin)))) &&
                ((filterPrice.length === 0) ||
                    (icompare(ma.fvp_CenaPoSlevachMen, filterPrice) ||
                        (icompare(ma.fn_TržbaCZK, filterPrice)))) &&
                icompare(ma.fn_ProcMarzeVceDopr, filterMarginPercentage) &&
                icompare(ma.fn_ProcRabVceDop, filterRabat) &&
                filter_rule(filterBranch, ma.fvp_KodStrediskaP) &&
                filter_rule(filterContract, ma.fvp_KodZakazkyP) &&
                filter_rule(filterInvoiceID, ma.fvp_KodDokladu) &&
                ((filterPartner.length === 0) ||
                    (filter_rule(filterPartner, ma.fvd_KodPartnera, true) ||
                        (filter_rule(filterPartner, ma.par_NazevPartnera, true)))) &&
                ((filterProduct.length === 0) ||
                    (filter_rule(filterProduct, ma.fvp_KodProduktu, true) ||
                        (filter_rule(filterProduct, ma.fvp_NazevProduktu, true))))
            );
        }
    )
    const show_margins = margins_filtered.slice(offset, offset + 20);

    const changeDateFrom = function (value) {
        setMinDate(value);
    }
    const changeDateTo = function (value) {
        setMaxDate(value);
    }

    var idx = 0;
    return (
        <ErrorWrap>
            <Row className="mb-3 g-0">
                <Col><h4 className='d-inline'>{t('menu2-biz-marg')}</h4></Col>
                <Col xs="auto" className='text-end pt-2 pe-3'>{t('from-prep')}: </Col>
                <Col xs="auto"> <DatePickerCustom onChange={changeDateFrom} minDate={new Date("2008-01-01")} maxDate={new Date()}
                    value={minDate}
                    holidays={[]} /></Col>
                <Col xs="auto" className='text-end pt-2 pe-3 ps-4'>{t('to_prep')}: </Col>
                <Col xs="auto">  <DatePickerCustom onChange={changeDateTo} minDate={new Date("2008-01-01")} maxDate={new Date()}
                    value={maxDate}
                    holidays={[]} /></Col>
                <Col xs="auto">
                    <LinkContainer to={{ pathname: "/partners/margins/" + date_formatISO(minDate) + "/" + date_formatISO(maxDate) }} >
                        <Button className="ms-2" onClick={() => setMargins(null)}>OK</Button>
                    </LinkContainer>
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className='bg-red border'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {t('menu2-biz-marg')} &nbsp; &nbsp;
                    <span className='bg-blue border'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {t('biz-margins_under_2%')} &nbsp; &nbsp;
                    <span className='bg-yellow border'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {t('biz-margins_under_25%')} &nbsp; &nbsp;
                </Col>
                <Col xs="auto"> <Pager offset={offset} pagesize={20} total={margins_filtered.length} callback={setOffset} /></Col>
            </Row>
            <Table size="sm">
                <thead className='beGray'>
                    <tr>
                        <th className='pb-3'>
                            <Form.Group className="mt-2" controlId="filterBranch" style={{ maxWidth: "70px" }}>
                                <Form.Label className="pb-1">{t('biz-centre_code')}
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; " value={filterBranch} onChange={handleChangeBranch} />
                            </Form.Group>
                        </th>
                        <th className='pb-3'>
                            <Form.Group className="mt-2" controlId="filterContract" >
                                <Form.Label className="pb-1">{t('biz-contract_code')}
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; " value={filterContract} onChange={handleChangeContract} />
                            </Form.Group>
                        </th>
                        <th className='pb-3'>{t('biz-closed')}</th>
                        <th className='pb-3'>
                            <Form.Group className="mt-2" controlId="filterProduct" >
                                <Form.Label className="pb-1">
                                    {t('product')}
                                    <br />
                                    <span className='text-muted'>{t('prod-code')}</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; " value={filterProduct} onChange={handleChangeProduct} />
                            </Form.Group>
                        </th>
                        <th className='pb-3'>
                            <Form.Group className="mt-2" controlId="filterInvoice" >
                                <Form.Label className="pb-1">{t('doc_code')}
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; " value={filterInvoiceID} onChange={handleChangeInvoiceID} />
                            </Form.Group>
                        </th>
                        <th className='pb-3'>{t('date')}
                            <br /> 1.&nbsp;{t('biz-doc')}
                            <br /> 2.&nbsp;{t('biz-UZP')}
                        </th>
                        <th className='pb-3'>
                            <Form.Group className="mt-2" controlId="filterPartner" >
                                <Form.Label className="pb-1">
                                    {t('partner')}
                                    <br />
                                    <span className='text-muted'>{t('biz-partner_code')}</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; " value={filterPartner} onChange={handleChangePartner} />
                            </Form.Group>
                        </th>
                        <th className='pb-3'>{t('biz-transfer_code')}</th>
                        <th className='pb-3'>{t('biz-cat_code')}</th>
                        <th colSpan={2} className="text-center pb-3">
                            <Form.Group className="mt-3" controlId="filterPrice" >
                                <Form.Label className="pb-1">{t('biz-price_amount')} &nbsp;
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; > < =" value={filterPrice} onChange={handleChangePrice} />
                            </Form.Group>
                        </th>
                        <th colSpan={2} className="text-center p-3">
                            <Form.Group className="mt-3" controlId="filterCosts" >
                                <Form.Label className="pb-1">{t('biz-costs')}  &nbsp;
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; > < =" value={filterCosts} onChange={handleChangeCosts} />
                            </Form.Group>
                        </th>
                        <th colSpan={2} className="text-center pb-3">
                            <Form.Group className="mt-3" controlId="filterMarginPerc" >
                                <Form.Label className="pb-1">{t('biz-margin')}  &nbsp;
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; > < = %" value={filterMarginPercentage} onChange={handleChangeMarginPercentage} />
                            </Form.Group>

                            <Form.Group className="mt-3" controlId="filterMargin" >
                                <Form.Control type="text" placeholder="&#128269; > < = CZK" value={filterMargin} onChange={handleChangeMargin} />
                            </Form.Group>
                        </th>
                        <th className='pb-3'>
                            <Form.Group className="mt-3" controlId="filterDiscount" >
                                <Form.Label className="pb-1">{t('biz-rebate')}  &nbsp;
                                </Form.Label>
                                <Form.Control type="text" placeholder="&#128269; > < =" value={filterRabat} onChange={handleChangeRabat} />
                            </Form.Group>
                        </th>
                        <th className='pb-3'>{t('biz-explanation')}</th>
                    </tr>
                </thead>
                <tbody>
                    {show_margins.map(function (m) {
                        idx += 1;
                        const style1 = parseInt(m.fn_MarzeVcDop) < 0 ? "bg-red " : "";
                        const style2 = parseInt(m.fn_ProcMarzeVceDopr) < 2 && parseInt(m.fn_ProcMarzeVceDopr) >= 0 ? "bg-blue" : "";
                        const style3 = parseInt(m.fn_ProcMarzeVceDopr) > 25 ? "bg-yellow " : "";
                        const explanationNeeded = style1 !== "" || style2 !== "" || style3 !== "";
                        return (
                            <tr key={idx} className={style1 + style2 + style3}>
                                <td className='align-middle'>{m.fvp_KodStrediskaP}</td>
                                <td className='align-middle'>{m.fvp_KodZakazkyP}</td>
                                <td className='align-middle'>{<Boolean variant="onlyTrue" value={m.fvd_Uzavreno} />}</td>
                                <td className='align-middle'>
                                    {m.fvp_NazevProduktu}
                                    <br />
                                    <span className='text-muted'>{m.fvp_KodProduktu}</span>
                                </td>
                                <td className='align-middle'>{m.fvp_KodDokladu}</td>
                                <td className='align-middle'>
                                    {date_formatCZ(m.fvd_DatDokladu)}
                                    <br />
                                    {date_formatCZ(m.fvd_DatUZP)}
                                </td>
                                <td className='align-middle'>
                                    {m.par_NazevPartnera}
                                    <br />
                                    <span className='text-muted'>{m.fvd_KodPartnera}</span></td>
                                <td className='align-middle'>{m.fvp_KodTypuPohybu}</td>
                                <td className='align-middle'>{m.ck_KodKategorie}</td>
                                <td className='text-end align-middle'>
                                    <span className='bolder'>
                                        {format_amount(m.fn_TržbaCZK)}
                                    </span>
                                    <br />
                                    <span className='text-muted'>
                                        {format_amount(m.fvp_CenaPoSlevachMen)}
                                        <br />
                                        {m.fvp_Mnozstvi}
                                        <br />
                                        {m.fvp_CenaMjMen}
                                    </span>
                                </td>
                                <td className='ps-0 align-middle'>
                                    <span className='bolder'>
                                        CZK
                                    </span>
                                    <br />
                                    <span className='text-muted'>
                                        {m.fvd_KodMeny}
                                        <br />
                                        {m.fvp_KodMj.trim()}
                                        <br />
                                        {m.fvd_KodMeny}/{m.fvp_KodMj}
                                    </span>
                                </td>
                                <td className='text-end align-middle'>
                                    <span className='bolder'> {format_amount(m.fn_NakVceDop)}</span>
                                    <br />
                                    <span className='text-muted'>
                                        {format_amount(m.fn_NakladCZK)}
                                        <br />
                                        {m.fn_Doprava === 0 ? "0" : format_amount(m.fn_Doprava)}
                                    </span>
                                </td>
                                <td className='ps-0 align-middle'>
                                    <span className='bolder'>CZK</span>
                                    <br />
                                    <span className='text-muted'>
                                        CZK&nbsp;{t('goods')}
                                        <br />
                                        CZK&nbsp;{t('transport')}
                                    </span>
                                </td>
                                <td className='text-end align-middle'>
                                    <span className='bolder'>{format_amount(m.fn_ProcMarzeVceDopr)}</span>
                                    <br />
                                    <span className='text-muted'>
                                        {format_amount(m.fn_Marže)}
                                        <br />
                                        {format_amount(m.fn_MarzeVcDop)}
                                    </span>
                                </td>
                                <td className='ps-0 align-middle'>
                                    <span className='bolder'>%</span>
                                    <br />
                                    <span className='text-muted'>CZK
                                        <br />
                                        CZK&nbsp;{t('biz-transport_incl')}
                                    </span>
                                </td>
                                <td className='align-middle'>{format_amount(m.fn_ProcRabVceDop)}&nbsp;%</td>
                                <td >
                                    {explanationNeeded ?
                                        <Form.Group controlId="explanation" className='mb-0' style={{ minWidth: "200px" }}>
                                            <Form.Control as="textarea" rows={3} />
                                        </Form.Group>
                                        : ""}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </ErrorWrap>
    );
}