/**
 * statistics Component rendering graph and table
 * @module comp/statistics
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { whID2whNumberStats } from '../lists/warehouses-defs';
import { getUserWeekStat } from '../lib/statistics-utils';
import { statistics_colors } from '../lists/colors';
import { useTranslation } from 'react-i18next';
import { weekdays } from '../lists/months';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { mesice } from '../lists/months';
import { groupArray } from '../lib/utils';
import { getDaysNumberFromPreviousMonth } from '../lib/date-utils';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

// viewType represents the timeline of graph, possible values: 1 - month_by_days, 2 - year_by_weeks, 3 - year_by_months
/**
 * 
 * @param {number} viewType - represents the timeline of graph, possible values: 1 - month_by_days, 2 - year_by_weeks, 3 - year_by_months
 * @param {object} date - date of displayed data - we need it to shape table of month for mobile version
 * @returns {component}
 */
export function StatsTableWeekly({ stats, weeks, get_keys, stat_key, objectname, viewType, date }) {

	const users = get_keys(stats);
	const usersdata = users.map(function (username) {
		const userWeeks = weeks.map(function (weekdef) {
			return [getUserWeekStat(stats, username, weekdef, stat_key), weekdef[0], weekdef[3]]; //tady to poslední - tu hodnotu mám ve weeks, ale jak ji dostanu sem, resp. pak dolů?
		});
		const userTotal = userWeeks.reduce((acc, uw) => acc + uw[0], 0);
		return [username, userWeeks, userTotal];
	});
	const totals = weeks.map(function (weekdef) {
		return [getUserWeekStat(stats, 'ALL', weekdef, stat_key), weekdef[0], weekdef[3]];
	});
	const total_total = totals.reduce((a, v) => a + v[0], 0);

	function sortFunction(a, b) { //somwhow works for letters and numbers too
		if (a[0] === b[0]) {
			return 0;
		}
		else {
			return (a[0] < b[0]) ? -1 : 1;
		}
	}

	//console.log(usersdata.sort(sortFunction));

	return (
		<>
			<StatsGraph users={users} usersdata={usersdata} day_columns={weeks} totals={totals} stat_key={stat_key} />
			<br />
			<WebTable objectname={objectname} weeks={weeks} usersdata={usersdata} sortFunction={sortFunction}
				stat_key={stat_key} totals={totals} total_total={total_total} />
			<MobileTable objectname={objectname} usersdata={usersdata} sortFunction={sortFunction}
				stat_key={stat_key} total_total={total_total} viewType={viewType} date={date} />
		</>
	);
}

/**
 * Return app version of table with object's performance
 * 
 * @param {string} objectname 
 * @returns {component}
 */
function MobileTable({ objectname, usersdata, sortFunction, stat_key, total_total, viewType, date }) {
	const { t } = useTranslation();

	const [detailShown, setDetailShown] = useState({});

	const allHidden = Object.keys(detailShown).length === Object.values(detailShown).filter((v) => v === false).length;

	function expandAll() {
		const value = allHidden ? true : false;
		const usernames = usersdata.map((line) => line[0]);
		setDetailShown(usernames.reduce((acc, v) => ({ ...acc, [v]: value }), {}));
	}

	function toggleDetailShown(id) {
		setDetailShown({ ...detailShown, [id]: !detailShown[id] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
	}


	const days_number_in_last_month = getDaysNumberFromPreviousMonth(date);

	const dateCopy = new Date(date); //pokud bych tam neměla new Date, tak to není legitimní kopie
	const nextMonth1st = new Date(dateCopy.setMonth(dateCopy.getMonth() + 1));
	//calculates, how many days of last week are still in this month
	const days_number_in_next_month_calc = getDaysNumberFromPreviousMonth(new Date(nextMonth1st));
	//works to caltulate, how many days in last week should be empty, cuz they are in next month
	const days_number_in_next_month = 7 - days_number_in_next_month_calc === 7 ? 0 : 7 - days_number_in_next_month_calc;


	return (
		<div className='d-lg-none'>
			<Button size="sm" className='float-end me-3 mb-2' onClick={() => expandAll()}>
				{allHidden ? <>{t('expand')} <MdExpandMore /></> : <>{t('hide')} <MdExpandLess /></>}
			</Button>

			<Table size="sm" >
				<thead className='beGray text-center'>
					<tr>
						<th>{objectname}</th>
						<th>{t('total')}</th>
						<th></th>
					</tr>

				</thead>
				<tbody>
					{usersdata.sort(sortFunction).map(function (userdata, idx) {
						const [username, , userTotal] = userdata; //jak toto čistě vyřešit?
						return (
							<React.Fragment key={idx}>
								<tr key={username} className="text-center">
									<td>{stat_key === "warehouse_id" ? whID2whNumberStats[username] : username}</td>
									<td>{userTotal}</td>
									<td><Button
										onClick={() => toggleDetailShown(username)}
										size="sm" variant="light" >
										{detailShown[username] ? <MdExpandLess /> : <MdExpandMore />}
									</Button></td>
								</tr>
								<tr className=" p-0 m-0 ">
									<td colSpan={3} className="p-0 m-0">
										<div className={'mb-0 px-4 py-3 ' + (detailShown[username] ? "" : " d-none")}>
											<LineDetailTable data={userdata} viewType={viewType}
												monthPrefix={days_number_in_last_month} monthSuffix={days_number_in_next_month} />
										</div>
									</td>
								</tr>
							</React.Fragment>
						);
					})}
					<tr className="beGray text-center">
						<td>{t('total')}</td>
						<td>{total_total}</td>
						<td></td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
}

/**
 * Return 2D array, which can be mapped as table, 2D array inner size is specified by viewType
 * 
 * @param {array} source - flat array of cell values
 * @param {number} viewType - represents the timeline of graph, possible values: 1 - month_by_days, 2 - year_by_weeks, 3 - year_by_months
 * @param {number} monthPrefix - number of week days in previous month
 * @param {number} monthSuffix - number of week days in next month
 * @param {function} t - translation
 * @returns {array}
 */
function get_table_data(source, viewType, monthPrefix, monthSuffix, t) {
	switch (viewType) {
		case 2: // year_by_weeks
			const week_with_num_array = source[1].map((line) => line[1] + ": " + line[0]);
			return groupArray(week_with_num_array, 6);
		case 3: // year_by_months
			const month_with_qty_array = source[1].map((line) => t(mesice[(line[1] - 1)][1]) + ": " + line[0]);
			return groupArray(month_with_qty_array, 3);
		default:
			const days_with_number = source[1].map((line) => line[1] + ".: " + line[0]);
			//console.log(days_with_number);
			const prefix_arr = Array.from({ length: monthPrefix }, () => '-');
			const suffix_arr = Array.from({ length: monthSuffix }, () => '-');
			const final_arr = prefix_arr.concat(days_with_number).concat(suffix_arr);
			return groupArray(final_arr, 7);
	}
}

/**
 * Return statistical detail numbers for one line (user or warehouse typically), line detail is represented aas table
 * 
 * @param {*} data 
 * @param {number} viewType - represents the timeline of graph, possible values: 1 - month_by_days, 2 - year_by_weeks, 3 - year_by_months
 * @param {number} monthPrefix - number of week days in previous month
 * @param {number} monthSuffix - number of week days in next month
 * @returns {component}
 */
function LineDetailTable({ data, viewType, monthPrefix, monthSuffix }) {
	const { t } = useTranslation();

	const weekDaysHeader = viewType === 1;
	const tranformed_table_data = get_table_data(data, viewType, monthPrefix, monthSuffix, t);
	return (
		<Table size="sm" bordered>
			{weekDaysHeader ?
				<thead>
					<tr>
						{weekdays.map((day, idy) =>
							<th className={'text-end ' + ((idy === 5 || idy === 6) && weekDaysHeader ? ' bg-w-2 ' : 'bg-light')} key={day}>{t(day)}</th>
						)}
					</tr>
				</thead>
				: <></>
			}

			<tbody>
				{tranformed_table_data.map((line, idx) =>
					<tr key={idx}>
						{line.map((cell, idy) =>
							<td className={' text-end ' + ((idy === 5 || idy === 6) && weekDaysHeader ? ' bg-w-2 ' : '')} key={idy}>
								{cell}
							</td>
						)}
					</tr>)}
			</tbody>
		</Table>
	);
}
/**
 * Table of statistics for web view
 * 
 * @param {*} param0 
 * @returns {component}
 */
function WebTable({ objectname, weeks, usersdata, sortFunction, stat_key, totals, total_total }) {
	const { t } = useTranslation();

	return (
		<Table size="sm" className="d-none d-lg-table">
			<thead className='beGray text-center'>
				<tr>
					<th>{objectname}</th>
					{weeks.map(function (week) {
						const className = week[3] === true ? "beGray3" : "";
						return <th key={week[0]} className={className}>{week[0]}</th>;
					})}
					<th>{t('total')}</th>
				</tr>
			</thead>
			<tbody>
				{usersdata.sort(sortFunction).map(function (userdata) {
					// console.log(userdata);
					const [username, userWeeks, userTotal] = userdata;
					//console.log(userdata);
					//console.log(whID2whNumberStats[username]);
					return (
						<tr key={username} className="text-center">
							<td>{stat_key === "warehouse_id" ? whID2whNumberStats[username] : username}</td>
							{userWeeks.map(function (uw) {
								const className = uw[2] === true ? "beGray4" : "";
								return <td key={uw[1]} className={className}>{uw[0]}</td>
							})}
							<td>{userTotal}</td>
						</tr>
					);
				})}
				<tr className="beGray text-center">
					<td>{t('total')}</td>
					{totals.map(function (total) {
						const className = total[2] === true ? "beGray3" : "";
						return <td key={total[1]} className={className}>{total[0]}</td>;
					})}
					<td>{total_total}</td>
				</tr>
			</tbody>
		</Table>
	);
}

/**
 * Graph with statisticcs visualization
 * 
 * @param {*} usersdata 
 * @param {*} day_columns
 * @param {*} totals
 * @param {*} stat_key
 * @returns {component}
 */

function StatsGraph({ usersdata, day_columns, totals, stat_key }) {
	const { t } = useTranslation();

	var idx = 0;

	var datasets = usersdata.map(function (userdata) {
		const [username, userstats] = userdata;
		const color = statistics_colors[idx % statistics_colors.length];
		const rightLabel = stat_key === "warehouse_id" ? whID2whNumberStats[username] : username;
		idx += 1;
		return {
			label: rightLabel,
			fill: false,
			borderColor: color,
			data: userstats.map((stat) => stat[0]),
			tension: 0.4
		}
	});
	datasets.push({
		label: t('total'),
		fill: false,
		borderColor: 'black',
		data: totals.map((stat) => stat[0]),
		tension: 0.4
	});

	const data = {
		labels: day_columns.map((dc) => dc[0]),
		datasets: datasets,
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			position: 'left'
		},
		scales: {
			y: {
				ticks: {
					stepSize: 1
				}
			}
		},
	};

	return (
		<div style={{ height: "240px" }}>
			<Line data={data} options={options} />
		</div>
	);
}