import React from 'react';
import { Row, Col, Button, Card, Nav } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ErrorWrap } from '../comp/errorwrap';
import { LinkContainer } from 'react-router-bootstrap';
import { DatePickerCustom } from '../comp/DatePicker';
import { WarehouseStocksDisplay } from './product-stocks';
import { WarehouseStocksDisplay as WarehouseStocksDisplayNew } from './ProductStocks';
import { date_formatISO } from '../lib/date-utils';
import { useTranslation } from 'react-i18next';
import { WarehousesTabs } from './stocks-warehouses-tabs';
import { TimeInStock as TimeInStockOld } from './products-time-in-stock';
import { TimeInStock } from "./TimeInStock";
//seen all - OK

/**
 * Return navigation and switch for stocks and inventory
 * 
 * @param {any} userlogin - info about logged user
 * @returns {component}
 */
export function StocksSwitch({ userlogin }) {
    const { t } = useTranslation();

    const effectiveDate = useParams().fetchDate;
    const fetchDate = effectiveDate;

    //console.log('StocksSwitch: ' + fetchDate);

    return (
        <ErrorWrap>
            <Nav variant="tabs" className="mb-3">
                <LinkContainer to={"/products/stocks/" + fetchDate}>
                    <ShowEffectiveDate effectiveDate={effectiveDate} />
                </LinkContainer>
                <LinkContainer disabled={!effectiveDate} to={"/products/stocks/" + fetchDate + "/warehouses"}>
                    <Nav.Link eventKey={"/products/stocks/" + fetchDate + "/warehouses"}>{t('menu3-prod-stock-wh')}</Nav.Link>
                </LinkContainer>
                <LinkContainer disabled={!effectiveDate} to={"/products/stocks/" + fetchDate + "/twist"}>
                    <Nav.Link eventKey={"/products/stocks/" + fetchDate + "/twist"}>{t('menu3-prod-stock-twist')}</Nav.Link>
                </LinkContainer>
                <LinkContainer disabled={!effectiveDate} to={"/products/stocks/" + fetchDate + "/inventory"}>
                    <Nav.Link eventKey={"/products/stocks/" + fetchDate + "/inventory"}>{t('menu2-prod-stock')}</Nav.Link>
                </LinkContainer>
                <LinkContainer disabled={!effectiveDate} to={"/products/stocks/" + fetchDate + "/expirations"}>
                    <Nav.Link eventKey={"/products/stocks/" + fetchDate + "/expirations"}>{t('ord-expiry')}</Nav.Link>
                </LinkContainer>
                <LinkContainer disabled={!effectiveDate} to={"/products/stocks/" + fetchDate + "/time-in-stock"}>
                    <Nav.Link eventKey={"/products/stocks/" + fetchDate + "/time-in-stock"}>{t('menu3-time-in-stock')}</Nav.Link>
                </LinkContainer>
            </Nav>
            <Routes>
                <Route path="warehouses/*" element={
                    <WarehousesTabs userlogin={userlogin} dataType="warehouses" effectiveDate={effectiveDate} />
                } />
                <Route path="twist/*" element={
                    <WarehousesTabs userlogin={userlogin} dataType="twist" effectiveDate={effectiveDate} />
                } />
                <Route path="inventory/*" element={
                    <WarehousesTabs userlogin={userlogin} effectiveDate={effectiveDate} inventoryView />
                } />
                <Route path="expirations" element={
                    <WarehouseStocksDisplayNew userlogin={userlogin} warehouseId={"all"} fetchDate={fetchDate} dataType="expirations" />
                } />
                <Route path="expirations/old" element={
                    <WarehouseStocksDisplay userlogin={userlogin} warehouseID={"all"} fetchDate={fetchDate} dataType="expirations" />
                } />
                <Route path="time-in-stock" element={
                    <TimeInStock userlogin={userlogin} fetchDate={fetchDate} />
                } />
                <Route path="time-in-stock/old" element={
                    <TimeInStockOld userlogin={userlogin} fetchDate={fetchDate} />
                } />
                <Route path="" element={<PickEffectiveDate effectiveDate={effectiveDate} />} />
            </Routes >
        </ErrorWrap >
    );
}




/**
 * Displays effective date for looking at stocks
 * 
 * @param {object} effectiveDate  - initil value is null, user is forced to pick it
 * @returns {component}
 */
export function ShowEffectiveDate({ effectiveDate }) {
    const { t } = useTranslation();
    const show_date = effectiveDate; // ? date_formatCZ(effectiveDate) : "xx-xx-xxxx";
    const fetchDate = effectiveDate;// date_formatISO(effectiveDate);
    return (
        <span className='text-muted'>
            {t('prod-picked_date')}: {show_date}
            <LinkContainer to={"/products/stocks/" + fetchDate}>
                <Button className='mx-1' size="sm" variant="light">✏️</Button>
            </LinkContainer>
        </span>
    );
}

/**
 * Form for changing effective date for looking at stocks
 * 
 * @param {object} effectiveDate - initil value is null, user is forced to pick it
 * @param {function} setEffectiveDate - update function for effectiveDate
 * @returns {component}
 */
export function PickEffectiveDate({ effectiveDate }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const changeDate = function (value) {
        //setEffectiveDate(value);
        navigate("/products/stocks/" + date_formatISO(value));
    }
    return (
        <Card body style={{ maxWidth: "500px" }} >
            <h5 className='mb-4'>{t('prod-choosen_date')}</h5>

            <Row className='mb-2 g-0'>
                <Col xs="auto" className='me-2'>
                    <DatePickerCustom
                        onChange={changeDate}
                        minDate={new Date("2022-01-01")}
                        value={new Date(effectiveDate)}
                    />
                </Col>
            </Row>
        </Card>
    );
}
