
/**
 * Warehouse's packages types overview.
 * @module products/warehouses-packagings
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import packaging from './warehouses-packaging.json';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { partnerLogo, wid2partner, warehouses_names_ids } from '../lists/warehouses-defs';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

function Warehouse({ title, logo, mappings }) {
  const { t } = useTranslation();
  // console.log(title);
  // console.log(mappings);
  // console.log(mappings["BAG"]);
  // console.log(mappings["SUD"]);
  // data["BAG"].concat(data["SUD"]);
  const items = Object.keys(mappings).reduce((acc, v) => acc.concat(mappings[v]), []);
  // console.log(items);
  const uniqueItems = items.sort().filter((v, i, a) => a.indexOf(v) === i);
  // uniqueItems.sort();

  const warehouse_packaging = Object.keys(mappings);
  warehouse_packaging.sort();

  return (
    <ErrorWrap>
      <Card style={{ margin: '1em' }}>
        <Card.Body className='pb-2'>
          <Card.Title>
            <Row>
              <Col><h3>{title}</h3></Col>
              <Col xs="auto" className='text-end'>{logo}</Col>
            </Row>
          </Card.Title>
          {/*<p>{JSON.stringify(data)}</p>*/}
          {/*<p>{JSON.stringify(mappings)}</p>*/}
        </Card.Body>
        <Table size='sm' striped className='mb-0'>
          <thead className='beGray'>
            <tr>
              <th rowSpan={2} className='align-bottom text-center border-end'>{t('warehouse')}</th>
              <th colSpan={uniqueItems.length} className='text-center'>HSH</th>
            </tr>
            <tr>

              {uniqueItems.map((item) =>
                <th className='text-center' key={item}>{item}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {warehouse_packaging.map((pack) =>
              <tr key={pack}>
                <th className='text-center border-end'>{pack}</th>
                {uniqueItems.map((item) =>
                  <td className='text-center' key={item}>
                    {/* zobrazeno pouze pro info 
               {item} 
              {pack} 
              */}
                    {mappings[pack].indexOf(item) < 0 ? <>&mdash;</> : '✅'}
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </ErrorWrap>
  )
};


export function WarehousesPackagings() {
  // console.log(packaging);
  // console.log(Object.keys(packaging));
  const warehouses = Object.keys(packaging);
  //console.log(wid2partner["16"]);

  // v prvnim kroku nevytvarim JSX komponentu Warehouse, ale jen neco jako <p></p>
  // warehouses -> {packaging[warehouse]}
  const listWarehouses = warehouses.map((warehouse) =>

    <Col key={warehouse} xs={12} lg={6}>
      <Warehouse
        mappings={packaging[warehouse]}
        title={warehouses_names_ids[warehouse]}
        logo={<>{partnerLogo[wid2partner[warehouse]]}</>}
      />
    </Col>
  );

  // console.log(listWarehouses);

  return (
    <Row>
      {listWarehouses}
      {/* <Button variant='danger'>knoflik</Button> */}
    </Row>
  );
}
