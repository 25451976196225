import { useTranslation } from 'react-i18next';
import { Badge } from "react-bootstrap";

//seen all - OK

/**
 * Simple pill badge, with title info
 * 
 * @returns {component}
 */
export function MyInfo({ text }) {
    const { t } = useTranslation();
    return <Badge bg="secondary" className="text-white" pill title={t(text)}>i</Badge>;
}
