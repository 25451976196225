import React, { useState } from "react";
import { Col, Row, Button, Form, Card } from "react-bootstrap";
import { Loading } from "../comp/loading";
import { date_time_format } from "../lib/date-utils";
import notes_comms from "./notes-comms.json";
import { his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { useTranslation } from "react-i18next";
import { useUserLogin } from "../UserLoginProvider";
import { DefaultError, useQuery } from "@tanstack/react-query";
import { getObjectNotes2 } from "../api/notes";
import { useCreateNote, useCreateNoteRunning, useHideShowNote } from "../hooks/notesHooks";
import { queryStatusToLoadedStatus } from "../api/common";

interface EmbeddedNotesProps {
  objectType: Exclude<Dto.NoteObjectType, "Unknown">;
  objectCode: string;
  objectCode2: string;
  enabledNotes?: number[];
  existingNotes?: Dto.GetStocksPacksNotesResponseItem[];
}

export function EmbeddedNotes({
  objectType,
  objectCode,
  objectCode2,
  enabledNotes = [5, 4, 3, 2, 1],
  existingNotes,
}: EmbeddedNotesProps) {
  const { t } = useTranslation();
  const { userInfo } = useUserLogin();

  const [formOpened, setFormOpened] = useState(false);
  const [selectedComm, setSelectedComm] = useState(0);
  const [preparedNote, setPreparedNote] = useState("");

  const {
    data: notes,
    refetch,
    status,
    isFetching,
  } = useQuery<
    Dto.GetObjectNotesResponseItem[],
    DefaultError,
    Dto.GetObjectNotesResponseItem[],
    ["notes", Exclude<Dto.NoteObjectType, "Unknown">, string, string]
  >({
    queryKey: ["notes", objectType, objectCode, objectCode2],
    queryFn: ({ queryKey, signal }) => getObjectNotes2(queryKey[1], queryKey[2], queryKey[3], { signal }),
    initialData: existingNotes,
    enabled: false,
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const creating = useCreateNoteRunning();

  const reloadIt = () => {
    setFormOpened(false);
    setSelectedComm(0);
    setPreparedNote("");
    return refetch();
  };

  const userinfo = userInfo;
  const user = userInfo.username;

  const setupForm = function (comm) {
    setFormOpened(true);
    setPreparedNote("");
    setSelectedComm(comm);
  };

  const copynew = function (note) {
    setFormOpened(true);
    setSelectedComm(note.commId);
    setPreparedNote(note.note);
  };

  const cancelForm = function () {
    setFormOpened(false);
    setSelectedComm(0);
  };
  const { createNoteMutation } = useCreateNote(objectType, selectedComm, objectCode, objectCode2, cancelForm);

  const { mutateHideNote, mutateShowNote } = useHideShowNote(objectType, objectCode, objectCode2);

  const allow_copy = formOpened === false;
  const comm = selectedComm;

  if (!his_fetch_success(loadedStatus) && existingNotes === undefined) {
    return (
      <Card body>
        <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
      </Card>
    );
  }

  return (
    <ErrorWrap>
      <Card>
        <Card.Body>
          <div>
            {enabledNotes.map(function (idx) {
              const commdef = notes_comms[idx];
              var variant = commdef.variant;
              if (comm === idx) {
              } else {
                variant = "outline-" + variant;
              }
              const name = t(commdef.name);
              const onclick = function () {
                setupForm(idx);
              };
              const emoji2 = commdef.emoji2;
              return (
                <Button key={idx} size="sm" className="mb-2 me-2" variant={variant} onClick={onclick}>
                  <img src={emoji2} alt="" width="20" /> {name}
                </Button>
              );
            })}
          </div>
          {formOpened && !creating && (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                return createNoteMutation({ note: e.currentTarget.note.value });
              }}
            >
              <Form.Group controlId="note" className="mb-2">
                <Form.Label>{t("not-text")}:</Form.Label>
                {preparedNote === "" ? (
                  <Form.Control as="textarea" name="note" rows={2} />
                ) : (
                  <Form.Control as="textarea" name="note" rows={2} defaultValue={preparedNote} />
                )}
              </Form.Group>
              <Row>
                <Col xs={6}>
                  <Button size="sm" variant="secondary" onClick={cancelForm}>
                    {t("cancel")}
                  </Button>
                </Col>
                <Col xs={6} className="text-end">
                  <Button size="sm" variant="primary" type="submit">
                    {t("rep-add")}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          {creating && <Loading message={t("processing") + "... "} />}

          {notes.map(function (note, idx) {
            const commId = note.communicationId;
            const commdef = notes_comms[commId];
            const variant = commdef.variant;
            const emoji = commdef.emoji;
            let allow_hide = (userinfo.hisSuperuser || note.createdBy.login === user) && note.hiddenAt === null;
            let allow_show = !allow_hide && (userinfo.hisSuperuser || note.createdBy.login === user);
            if (formOpened) {
              allow_hide = false;
              allow_show = false;
            }
            var textclass = "";
            if (note.hiddenAt != null) {
              textclass = "cross-out";
            }
            const dohandlehide = function () {
              mutateHideNote({ noteId: note.id });
            };
            const dohandleshow = function () {
              mutateShowNote({ noteId: note.id });
            };
            const docopynew = function () {
              copynew(note);
            };
            return (
              <div key={idx}>
                <hr />
                <div className="d-flex justify-content-between">
                  <div className={textclass}>
                    <em className="text-muted">
                      <small>
                        {date_time_format(note.created)}
                        &nbsp;|&nbsp;
                        {note.createdBy.departmentCode}
                        &nbsp;|&nbsp;
                        {note.createdBy.name} ({note.createdBy.login})
                      </small>
                    </em>
                  </div>
                  <div>
                    {allow_hide ? (
                      <Button size="sm" variant="light" onClick={dohandlehide}>
                        {t("not-archive")}
                      </Button>
                    ) : allow_show ? (
                      <Button size="sm" variant="light" onClick={dohandleshow}>
                        {t("not-renew")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <p>
                  {allow_copy ? (
                    <Button size="sm" variant={variant} onClick={docopynew} className="me-1">
                      <img src={emoji} alt="" width="20" />
                    </Button>
                  ) : (
                    <Button size="sm" variant={variant} onClick={docopynew} className="me-1" disabled>
                      <img src={emoji} alt="" width="20" />
                    </Button>
                  )}
                  <span className={textclass}>{note.note}</span>
                </p>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    </ErrorWrap>
  );
}
