import parse from "html-react-parser";
import PostalMime, { Email } from "postal-mime";
import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HisFetchStatus, his_fetch_success } from "../comp/FetchLoader";
import { AttachmentDownloader } from "../comp/downloader";
import { ErrorWrap } from "../comp/errorwrap";
import { date_time_format } from "../lib/date-utils";
import { useQuery } from "@tanstack/react-query";
import { getEmail } from "../api/emails";
import { queryStatusToLoadedStatus } from "../api/common";

export function EmailDetail() {
  const { t } = useTranslation();

  const { code } = useParams();
  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["email", code],
    queryFn: async ({ signal }) => {
      const email = await getEmail(parseInt(code), { signal });
      return { ...email, content: await PostalMime.parse(email.mime) };
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  if (!his_fetch_success(loadedStatus) || !data) {
    return (
      <HisFetchStatus
        status={!data ? 1 : loadedStatus}
        loadingType="big"
        errorType="fetcherError"
        reloadButton={refetch}
      />
    );
  }

  return (
    <ErrorWrap>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Row>
            <Col>
              <span className="text-muted">{t("sys-from")}: </span> {data.from}
            </Col>
            <Col>
              <span className="text-muted">{t("date")}: </span>
              {date_time_format(data.sent)}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col>
              <Row className="g-0">
                <Col xs="auto" className="me-1">
                  <span className="text-muted">{t("sys-to_pl")}: </span>
                </Col>
                <Col>
                  {data.to.map((a) => (
                    <React.Fragment key={a}>
                      {a}
                      <br />
                    </React.Fragment>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col>
              <span className="text-muted">ID: </span>
              {data.id}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col xs="6">
              <span className="text-muted">{t("sys-subject")}: </span>
              {data.subject}
            </Col>
            <Col xs="3">
              <span className="text-muted">{t("sys-cc")}: </span>
              {data.cc.map((a) => (
                <React.Fragment key={a}>
                  {a}
                  <br />
                </React.Fragment>
              ))}
            </Col>
            <Col xs="3">
              <span className="text-muted">{t("sys-bcc")}: </span>
              {data.bcc.map((a) => (
                <React.Fragment key={a}>
                  {a}
                  <br />
                </React.Fragment>
              ))}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col>
              <MailContentVisualizer content={data.content} />
            </Col>
            <Col>
              <pre>{data.mime}</pre>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </ErrorWrap>
  );
}

interface MailContentVisualizerProps {
  content: Email;
}

function MailContentVisualizer({ content }: MailContentVisualizerProps) {
  const htmlArray = [];
  if (content.text) {
    htmlArray.push(<EmailPartCard key="text" description="text/plain" content={<pre>{content.text}</pre>} />);
  }
  if (content.html) {
    htmlArray.push(<EmailPartCard key="html" description="text/html" content={parse(content.html)} />);
  }
  for (const [i, attachment] of content.attachments.entries()) {
    htmlArray.push(
      <EmailPartCard
        key={`attachment_${i}`}
        description={attachment.mimeType}
        content={
          <AttachmentDownloader
            data={attachment.content}
            subtype={attachment.mimeType}
            height={30}
            filename={attachment.filename}
          />
        }
      />
    );
  }

  return htmlArray;
}

/**
 * Single entity Card
 *
 * @param {any} entity
 * @param {any} content
 * @returns {component}
 */
function EmailPartCard({ description, content }) {
  return (
    <Card body className="my-2">
      <p className="mb-4">
        <Badge className="float-end fw-normal" bg="light" text="dark">
          {description}
        </Badge>
      </p>
      {content}
    </Card>
  );
}
