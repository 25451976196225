import { MutationState, useMutation, useMutationState } from "@tanstack/react-query";
import { cancelSendingProductWarehouseCard, sendProductWarehouseCard } from "../../api/products";
import { useTranslation } from "react-i18next";

const KEY = "sendCard";

export function useWarehouseSendingCardStatus(productId: number) {
  const { t } = useTranslation();

  const sendingMutations = useMutationState({
    filters: { mutationKey: [KEY, productId], status: "pending" },
  });

  const errorMutations = useMutationState({
    filters: { mutationKey: [KEY, productId], status: "error" },
  });

  function isWarehouseSendingCard(warehouseId: number) {
    return sendingMutations.some(
      (m: MutationState<unknown, Error, { warehouseId: number }>) => m.variables.warehouseId === warehouseId
    );
  }

  function warehouseSendingCardError(warehouseId: number) {
    return errorMutations.some(
      (m: MutationState<unknown, Error, { warehouseId: number }>) => m.variables.warehouseId === warehouseId
    )
      ? t("sending_fail")
      : undefined;
  }

  return { isWarehouseSendingCard, warehouseSendingCardError };
}

export function useSendAndCancelCard(productId: number) {
  const { mutateAsync: sendCardMutation } = useMutation({
    mutationKey: [KEY, productId],
    mutationFn: ({ warehouseId }: { warehouseId: number }) => sendProductWarehouseCard(productId, warehouseId),
    gcTime: 0,
  });

  const { mutateAsync: cancelSendingMutation } = useMutation({
    mutationFn: ({ warehouseId }: { warehouseId: number }) => cancelSendingProductWarehouseCard(productId, warehouseId),
    gcTime: 0,
  });

  function sendCard(warehouseId: number) {
    return sendCardMutation({ warehouseId });
  }

  function cancelSending(warehouseId: number) {
    return cancelSendingMutation({ warehouseId });
  }

  return { sendCard, cancelSending };
}
