/**
 * Simple component for displaying rating and supportive functions
 * @module comp/rating
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { rating_colors } from '../lists/colors';

//seen all - OK

// From: https://dracoblue.net/dev/linear-least-squares-in-javascript/
function findLineByLeastSquares(values_x, values_y) {
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var count = 0;

    /*
     * We'll use those variables for faster read/write access.
     */
    var x = 0;
    var y = 0;
    var values_length = values_x.length;

    if (values_length !== values_y.length) {
        // throw new Error('The parameters values_x and values_y need to have same size!');
        return [0, 0];
    }

    /*
     * Nothing to do.
     */
    if (values_length === 0) {
        return [0, 0];
    }

    /*
     * Calculate the sum for each of the parts necessary.
     */
    var v;
    for (v = 0; v < values_length; v++) {
        x = values_x[v];
        y = values_y[v];
        sum_x += x;
        sum_y += y;
        sum_xx += x * x;
        sum_xy += x * y;
        count++;
    }

    /*
     * Calculate m and b for the formular:
     * y = x * m + b
     */
    var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
    var b = (sum_y / count) - (m * sum_x) / count;

    /*
     * We will make the x and y result line now
     */
    var result_values_x = [];
    var result_values_y = [];

    for (v = 0; v < values_length; v++) {
        x = values_x[v];
        y = x * m + b;
        result_values_x.push(x);
        result_values_y.push(y);
    }

    return [m, b];
}

/**
 * Function, that returns history as an array
 * 
 * @param {any} history - history of rating in various formats
 * @returns {array}
 */
function ensure_history_array(history) {
    if (history === null) {
        return [];
    }
    if (Array.isArray(history)) {
        return history;
    }
    return history.replace("(", "").replace(")", "").split(" ").map(function (s) {
        return parseInt(s);
    });
}

/**
 * Returns htlm element image with arrows, that describe the trend of rating
 * 
 * @param {any} phistory - history of rating in whatever structure
 * @returns {component}
 */
function get_rating_trend(phistory) {
    const history = ensure_history_array(phistory);
    if (history.length === 0) {
        return "";
    }
    var idx = 0;
    const xs = history.map(function (x) {
        idx += 1;
        return idx;
    });
    const lr = findLineByLeastSquares(xs, history);
    const m = lr[0];
    const r = m * history.length;
    if (r < -1.0) {
        return <img src="/img/trend_down.svg" alt="" width="21" className="rating-img ms-1" />; //  "🡶"
    } else if (r > 1.0) {
        return <img src="/img/trend_up.svg" alt="" width="21" className="rating-img ms-1" />; //  "🡵"
    }
    return <img src="/img/trend_stable.svg" alt="" width="26" className="rating-img ms-1" />; //  "🡲"
}

/**
 * Colored rating badge
 * 
 * @param {string} rating - rating letter
 * @param {boolean} show_label - whether show label "Rating"
 * @param {array} history - array of rating history to compute its trend
 * @param {string} variant - visual style
 * @returns {component}
 */
export function Rating({ rating, show_label = false, history = [], variant }) {
    // const rating = props.rating;
    // const show_label = props["show_label"];
    const rrating = rating || '?';
    const color = rating_colors[rrating];
    const rstyle = {
        backgroundColor: color
    };
    const label = show_label ? <span>Rating</span> : <></>;
    const trend = get_rating_trend(history);

    switch (variant) {
        case "horizontal":
            return (
                <span style={rstyle} className="rating_new2 stin2">
                    <span className="beBiggest"> <span> {label}  </span>{rrating}{trend}&nbsp;</span><span></span>
                </span>
            );
        case "stin":
            return (
                <span style={rstyle} className="rating_new stin2">
                    {label}
                    <span className="beBiggest">{rrating}{trend}</span><span></span>
                </span>
            );

        default:
            return (
                <span style={rstyle} className="rating_new">
                    {label}
                    <span className="beBiggest">{rrating}{trend}</span><span></span>
                </span>
            );
    }
}
