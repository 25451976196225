/**
 * Partner orders sale view.
 * @module partners/partner-orders-sale
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import Table from 'react-bootstrap/Table';
import { Boolean } from '../comp/boolean';
import { LinkContainer } from 'react-router-bootstrap';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { Pager } from '../comp/pager';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

export function PartnerOrdersSale({ saleOrders, loadedSaleOrdersStatus, reloadButton }) {
    const { t } = useTranslation();

    const [offset, setOffset] = useState(0);

    if (!his_fetch_success(loadedSaleOrdersStatus)) {
        return <HisFetchStatus status={loadedSaleOrdersStatus} loadingType="big" errorType="fetcherError" waitingType="explained" reloadButton={reloadButton} />;
    }

    const saleOrdersTransformed = saleOrders.map(
        function (ord) {
            const remainingInCurrency = ord.pod_CastkaCelkemMen * (100 - ord.pod_FakturovanoProc) / 100;
            ord["remainingInCurrency"] = remainingInCurrency;
            return ord;
        });

    const saleOrdersRemaining = saleOrdersTransformed.filter(
        function (ord) {
            return ord.remainingInCurrency > 0;
        });

    const remainingCurrencies = saleOrdersRemaining.reduce(
        function (acc, ord) {
            if (!acc[ord.pod_KodMeny]) {
                acc[ord.pod_KodMeny] = 0;
            }
            acc[ord.pod_KodMeny] += ord.remainingInCurrency;
            return acc;
        }, {});

    const saleOrdersTable = saleOrdersTransformed.slice(offset, offset + 20);

    return (
        <ErrorWrap>
            <Row>
                <Col md={4}>
                    <Card className='mb-3'>
                        <Card.Header as="h5">{t('biz-uninvoiced_ord')}</Card.Header>
                        <Card.Body>
                            <table>
                                <tbody>
                                    {Object.keys(remainingCurrencies).map(function (curr) {
                                        return (
                                            <tr key={curr}>
                                                <td className="text-end">{format_amount(remainingCurrencies[curr])}</td>
                                                <td>{curr}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Pager offset={offset} pagesize={20} total={saleOrders.length} callback={setOffset} />
            <SOWeb saleOrdersTable={saleOrdersTable} />
            <SOApp saleOrdersTable={saleOrdersTable} />
        </ErrorWrap>
    )
}

function SOWeb({ saleOrdersTable }) {
    const { t } = useTranslation();
    return (
        <Table hover size="sm" striped className="d-none d-lg-table">
            <thead className="beGray">
                <tr>
                    <th className="pb-2">{t('doc_code')}</th>
                    <th className="pb-2 text-end">{t('date')}</th>
                    <th className="pb-2 text-center">{t('note')}</th>
                    <th className="pb-2 text-end">{t('biz-amount_money')}</th>
                    <th className="pb-2">{t('biz-currency')}</th>
                    <th className="pb-2 text-center">{t('biz-closed')}</th>
                    <th className="pb-2 text-center">{t('biz-invoiced')}&nbsp;%</th>
                    <th className="pb-2 text-end">{t('biz-amount_remain')}</th>
                    <th className="pb-2">{t('biz-currency')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    saleOrdersTable.map(
                        function (ord) {
                            return (
                                <LinkContainer to={{ pathname: "/orders/sale/" + ord.pod_KodDokladu }}
                                    key={ord.pod_KodDokladu}>
                                    <tr key={ord.pod_KodDokladu}>
                                        <td>{ord.pod_KodDokladu}</td>
                                        <td className="text-end">{date_formatCZ(ord.pod_DatDokladu)}</td>
                                        <td className="text-center">{ord.pod_Poznamka}</td>
                                        <td className="text-end">{format_amount(ord.pod_CastkaCelkemMen)}</td>
                                        <td>{ord.pod_KodMeny}</td>
                                        <td className="text-center"><Boolean value={ord.pod_Uzavreno} variant="onlyTrue" /></td>
                                        <td className="text-center">{ord.pod_FakturovanoProc}&nbsp;%</td>
                                        <td className="text-end">{format_amount(ord.remainingInCurrency)}</td>
                                        <td>{ord.pod_KodMeny}</td>
                                    </tr>
                                </LinkContainer>
                            );
                        }
                    )
                }
            </tbody>
        </Table>
    );
}

function SOApp({ saleOrdersTable }) {
    const { t } = useTranslation();
    return (
        <Table striped className="d-lg-none">
            <tbody>
                {saleOrdersTable.map(function (ord) {
                    return (
                        <LinkContainer to={{ pathname: "/orders/sale/" + ord.pod_KodDokladu }}
                            key={ord.pod_KodDokladu}>
                            <tr>
                                <td>
                                    <Row className="g-0">
                                        <Col className="beBigger me-1">{ord.pod_KodDokladu}</Col>
                                        <Col className="text-end align-bottom">{format_amount(ord.pod_CastkaCelkemMen)} {ord.pod_KodMeny}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{date_formatCZ(ord.pod_DatDokladu)}</Col>
                                        <Col className="text-end">{t('biz-closed')}: <Boolean value={ord.pod_Uzavreno} variant="onlyTrue" /></Col>
                                    </Row>
                                    <Row>
                                        <Col>{t('note_short')}: {ord.pod_Poznamka}</Col>
                                        <Col xs="auto" className="text-end">{t('biz-invoiced')}: {ord.pod_FakturovanoProc}&nbsp;%</Col>
                                    </Row>
                                    <Row>
                                        <Col></Col>
                                    </Row>
                                </td>
                            </tr>
                        </LinkContainer>
                    );
                })
                }
            </tbody>
        </Table>
    );
}
