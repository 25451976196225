/**
 * Downloader of XLSX file for insurance company or HSH internal insurance report for any month
 * @module insurance/insurance-report
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { Downloader } from '../comp/downloader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { platneRoky, monthsOfYear } from '../lib/month-year-utils';
import { MonthYearDropdown } from '../comp/month-year-dropdown';
import { date_getLastMonthDate, date_formatYYYY_MM, date_formatMM_YYYY } from '../lib/date-utils';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function InsuranceReport() {
	const { t } = useTranslation();

	const lastMonth = date_getLastMonthDate(new Date());
	const [today, setToday] = useState(lastMonth);

	const minDate = new Date("2015-01-01");
	const roky = platneRoky(minDate, lastMonth);
	const mesice = monthsOfYear(minDate, lastMonth, today.getFullYear());
	//console.log(mesice.slice(0));

	return (
		<ErrorWrap>
			<Card className="mt-3 mx-auto" style={{ maxWidth: "700px" }}>
				<Card.Header>
					<h4 className="text-center">{t('ins-report')}&nbsp;&nbsp;
						<MonthYearDropdown today={today} setToday={setToday} years={roky} months={mesice} minDate={minDate} maxDate={lastMonth} />
					</h4>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col className="text-center" ><Card.Title>{t('ins-rep_ins_firm')}</Card.Title></Col>
						<Col className="border-start text-center"><Card.Title>{t('ins-intr_firm_rep')}</Card.Title></Col>
					</Row>
					<Row>
						<Col className="text-center">
							<Downloader img="/application-excel.svg" alt="pdf" label={t('ins-rep_ins_firm') + " " + date_formatMM_YYYY(today, "_") + ".xlsx"}
								path={"/api/credendo/report/" + date_formatYYYY_MM(today)}
								filename={t('ins-rep_ins_firm') + " " + date_formatMM_YYYY(today, "_") + ".xlsx"} />
						</Col>
						<Col className="border-start text-center">
							<Downloader img="/application-excel.svg" alt="pdf" label={t('ins-intr_firm_rep') + " " + date_formatMM_YYYY(today, "_") + ".xlsx"}
								path={"/api/credendo/int-report/" + date_formatYYYY_MM(today)}
								filename={t('ins-intr_firm_rep') + " " + date_formatMM_YYYY(today, "_") + ".xlsx"} />
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</ErrorWrap>
	);

}