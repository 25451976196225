/**
 * Report configuration manipulation utilities.
 * @file
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */

//seen all - OK

/**
 * Returns a subset of config with only those dstIds where given srcId
 * is used in their formulae.
 *
 * @param {any} config - the whole configuration dictionary of dstId to formulae dictionaries mapping
 * @param {string} src - filter only those dstIds which contain this srcId in their formulae
 * @return {any} - subset of the config dictionary
 */
export function filterReportConfigSrc(config, src) {
    return Object.keys(config)
	.filter((dstId) => config[dstId][src])
	.reduce((acc, k) => ({ ...acc, [k]: config[k]}), {});
}

/**
 * Updates one or more formulae of given configuration. The number of
 * arguments minus one must be divisible by three and each of the
 * triplets passed represents one change to be made. See
 * updateReportConfigSingle for description.
 *
 * @param {string} dst - dstId of a formula to update / create
 * @param {array} triplets - array which length is divisible by three
 * @return {any} - updated configuration dictionary
 */
export function updateReportConfig(config, ...triplets) {
    // We're done
    if (triplets.length === 0) {
	return config;
    }

    // Error - now enforced, no silent dropping of unaligned tail
    // elements (non-triplets)
    if (triplets.length < 3) {
	throw new Error("updateReportConfig has " + triplets.length + " remaining elements: " + JSON.stringify(triplets));
    }

    // Recursively update it
    return updateReportConfig(updateReportConfigSingle(config, triplets[0], triplets[1], triplets[2]),
			      ...triplets.slice(3));
}

/**
 * Filter predicate for selectin unique array elements.
 * @param {any} v - current element value
 * @param {number} i - current element index
 * @param {array} a - the whole array
 * @return {boolean} - true if this is the first occurence of given
 * value in the array.
 */
function unique_filter(v, i, a) {
    return a.indexOf(v) === i;
}

/**
 * Updates one formula of given configuration.
 *
 * @param {string} dst - identifier of a formula to update / create / remove
 * @param {string} src - the source identifier to be added / updated, if false, dst gets removed
 * @param {number} coef - the new coefficient, if 0, given src gets removed from given dst
 * @return {any} - updated configuration dictionary
 */
function updateReportConfigSingle(config, dst, src, coef) {
    return [...Object.keys(config), dst]                        // Make sure all current and given dst are there
	.filter(unique_filter)                                  // Include each only once (deduplicates dst argument if already there)
	.filter((dstId) => (src !== false) || (dst !== dstId))  // Remove dst if requested by src being false
	.reduce((accConfig, dstId) => ({
	    ...accConfig,                                       // Gradually build new configuration
	    [dstId]: dstId === dst ?
		updateFormula(config[dstId] || {}, src, coef)   // If adding dst, update the formula as requested
		:
		config[dstId]                                   // Otherwise copy from original
	}),
		{});                                            // Start always from scratch
}

/**
 * Updates source coefficient coef in given formula. If the coef
 * argument is 0, given source id is removed from the formula.
 *
 * @param {any} formula - dictionary of id: coef
 * @param {string} src - source id to update or remove
 * @param {number} coef - the new coefficient, if 0 the source id is removed
 * @return {any} - the updated formula
 */
function updateFormula(formula, src, coef) {
    if (src) {
	if (coef === 0) {
	    // Remove given key
	    return Object.keys(formula)
		.filter((k) => k !== src)
		.reduce((acc, k) => ({
		    ...acc,
		    [k]: formula[k]
		}),
			{});
	} else {
	    // Update given key
	    return({
		...formula,
		[src]: coef
	    });
	}
    }

    // If no src argument given, return the original untouched
    return formula;
}
