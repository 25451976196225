/**
 * Partner insurance mother component.
 * @module partners/partner-insurance
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { PartnerInsuranceWeb } from './partner-insurance-web';
import { PartnerInsuranceApp } from './partner-insurance-app';
import { ErrorWrap } from '../comp/errorwrap';

//seen all - OK

export function PartnerInsurance({ partner }) {

	return (
		<ErrorWrap>
			<PartnerInsuranceWeb partner={partner} />
			<PartnerInsuranceApp partner={partner} />
		</ErrorWrap>
	);
}