import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Loading } from "../comp/loading";
import { date_time_format } from "../lib/date-utils";
import Badge from "react-bootstrap/Badge";
import notes_comms from "./notes-comms.json";
import Card from "react-bootstrap/Card";
import { his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { useTranslation } from "react-i18next";
import { useUserLogin } from "../UserLoginProvider";
import { DefaultError, useMutation, useMutationState, useQuery, useQueryClient } from "@tanstack/react-query";
import { createNote, getObjectNotes, hideNote, showNote } from "../api/notes";
import { queryStatusToLoadedStatus } from "../api/common";
import { FormProvider, useForm } from "react-hook-form";
import { useCreateNote, useCreateNoteRunning, useHideShowNote } from "../hooks/notesHooks";

interface NotesNewProps {
  objectType: Exclude<Dto.NoteObjectType, "Unknown">;
  objectCode: string;
  enabledNotes?: number[];
}

export function Notes({ objectType, objectCode, enabledNotes = [5, 4, 3, 2, 1] }: NotesNewProps) {
  const { t } = useTranslation();
  const { userInfo } = useUserLogin();

  const [formOpened, setFormOpened] = useState(false);
  const [selectedComm, setSelectedComm] = useState(0);
  const [preparedNote, setPreparedNote] = useState("");

  const { data, status, isFetching, refetch } = useQuery<
    Dto.GetObjectNotesResponseItem[],
    DefaultError,
    Dto.GetObjectNotesResponseItem[],
    ["notes", Exclude<Dto.NoteObjectType, "Unknown">, string]
  >({
    queryKey: ["notes", objectType, objectCode],
    queryFn: ({ queryKey, signal }) => getObjectNotes(queryKey[1], queryKey[2], { signal }),
    initialData: [],
    enabled: objectCode?.length > 0,
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);
  const creating = useCreateNoteRunning();

  const { mutateHideNote, mutateShowNote } = useHideShowNote(objectType, objectCode);

  const reloadIt = () => {
    setFormOpened(false);
    return refetch();
  };

  function setupForm(comm: number, note: string) {
    setFormOpened(true);
    setPreparedNote(note);
    setSelectedComm(comm);
  }

  function cancelForm() {
    setFormOpened(false);
    setSelectedComm(0);
  }

  const allow_copy = formOpened === false;
  const comm = selectedComm;

  if (!his_fetch_success(loadedStatus)) {
    return (
      <Card>
        <Card.Header>
          <h5 className="mb-3">{t("notes")}</h5>
        </Card.Header>
        <Card.Body>
          <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
        </Card.Body>
      </Card>
    );
  }

  return (
    <ErrorWrap>
      <Card>
        <Card.Body>
          <h5 className="mb-3">{formOpened === false ? t("notes") : t("not-new_note")}</h5>
          <div>
            {enabledNotes.map(function (idx) {
              const commDef = notes_comms[idx];
              const variant = comm === idx ? commDef.variant : `outline-${commDef.variant}`;
              const name = t(commDef.name);
              const emoji2 = commDef.emoji2;
              return (
                <Button key={idx} size="sm" className="mb-2 me-2" variant={variant} onClick={() => setupForm(idx, "")}>
                  <img src={emoji2} alt="" width="20" /> {name}
                </Button>
              );
            })}
          </div>
          {formOpened && !creating && (
            <NoteEditForm
              preparedNote={preparedNote}
              cancelForm={cancelForm}
              objectType={objectType}
              communicationId={selectedComm}
              objectCode={objectCode}
            />
          )}
          {creating && <Loading message={t("processing") + "... "} />}
          {data.map(function (note, idx) {
            const commId = note.communicationId;
            const commdef = notes_comms[commId];
            const variant = commdef.variant;
            const emoji = commdef.emoji;
            let allow_hide = (userInfo.hisSuperuser || note.createdBy.login === userInfo.username) && !note.hiddenBy;
            let allow_show = !allow_hide && (userInfo.hisSuperuser || note.hiddenBy?.login === userInfo.username);
            if (formOpened) {
              allow_hide = false;
              allow_show = false;
            }
            var textclass = "";
            if (!!note.hiddenBy) {
              textclass = "cross-out";
            }
            return (
              <div key={idx}>
                <hr />
                <div className="d-flex justify-content-between">
                  <div className={textclass}>
                    <em className="text-muted">
                      <small>
                        {date_time_format(note.created)}
                        &nbsp;|&nbsp;
                        {note.createdBy.departmentCode}
                        &nbsp;|&nbsp;
                        {note.createdBy.name} ({note.createdBy.login})
                      </small>
                    </em>
                  </div>
                  <div>
                    {allow_hide ? (
                      <Button size="sm" variant="light" onClick={() => mutateHideNote({ noteId: note.id })}>
                        {t("not-archive")}
                      </Button>
                    ) : allow_show ? (
                      <Button size="sm" variant="light" onClick={() => mutateShowNote({ noteId: note.id })}>
                        {t("not-renew")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <p>
                  <Button
                    size="sm"
                    variant={variant}
                    className="me-1"
                    disabled={!allow_copy}
                    onClick={() => setupForm(note.communicationId, note.note)}
                  >
                    <img src={emoji} alt="" width="20" />
                  </Button>
                  <span className={textclass}>{note.note}</span>
                </p>
              </div>
            );
          })}
        </Card.Body>
        <Card.Footer className="bg-white text-center p-2 text-muted">
          <Badge pill bg="secondary">
            i
          </Badge>
          &nbsp;&nbsp;<small>{t("not-archived_notes_hidden")}</small>
        </Card.Footer>
      </Card>
    </ErrorWrap>
  );
}

interface NoteEditFormProps {
  preparedNote?: string;
  cancelForm: () => void;
  objectType: Exclude<Dto.NoteObjectType, "Unknown">;
  objectCode: string;
  objectCode2?: string;
  communicationId: number;
}

interface NoteEditFormValues {
  note: string;
}

function NoteEditForm({
  preparedNote,
  cancelForm,
  objectType,
  objectCode,
  objectCode2,
  communicationId,
}: NoteEditFormProps) {
  const { t } = useTranslation();
  const form = useForm<NoteEditFormValues>({ mode: "onChange", defaultValues: { note: preparedNote } });
  const { isValid } = form.formState;

  const { createNoteMutation } = useCreateNote(objectType, communicationId, objectCode, objectCode2, cancelForm);

  return (
    <FormProvider {...form}>
      <Form
        onSubmit={form.handleSubmit((values: NoteEditFormValues) => {
          return createNoteMutation(values);
        })}
      >
        <Form.Group controlId="note" className="mb-2">
          <Form.Label>{t("not-text")}:</Form.Label>
          <Form.Control
            as="textarea"
            name="note"
            rows={2}
            {...form.register("note", { minLength: 1, required: true })}
          />
        </Form.Group>
        <Row>
          <Col xs={6}>
            <Button size="sm" variant="secondary" onClick={cancelForm}>
              {t("cancel")}
            </Button>
          </Col>
          <Col xs={6} className="text-end">
            <Button<"input"> size="sm" variant="primary" type="submit" disabled={!isValid}>
              {t("rep-add")}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
}
