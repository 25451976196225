
//časem sjednotit
export const monitoring_names = {
    null: "-",
    1: "check_bbd_batch",
    2: "check_batch",
    3: "no_check",
};

export const monitoring_titles = {
    "": "all",
    null: "unknown",
    1: "check_bbd_batch",
    2: "check_batch",
    3: "no_check",
};

export const monitoring_options = {
    0: "-",
    1: "required_bbd_batch_check",
    2: "required_batch_check",
    3: "bbd_batch_optional"
};
