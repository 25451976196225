/**
 * Help section functions and utils.
 * @module help
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Routes, Route } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { his_fetch } from './comp/FetchLoader';
import { LinkContainer } from 'react-router-bootstrap';
import { ErrorWrap } from './comp/errorwrap';
import { Button } from 'react-bootstrap';

//seen all - OK

/*
 * The file must begin with ---, first --- after newline is the end of metadata.
 * Parses metadata as key:value, returns the rest after \n--- as the markdown itself

 */
function parse_markdown_metadata(str) {
	const delregexp = /\r?\n----*\r?\n/g;
	const result = delregexp.exec(str);
	//console.log(result);
	if (result === null) {
		return [{}, str];
	}
	const resultstr = result[0];
	const mdpos = resultstr.length + result.index;
	const mdstr = str.substring(mdpos);
	const ystr = str.substring(0, result.index);
	const ylines = ystr.split(/\r?\n/);
	const cregexp = /:/;
	var metadata = {};
	for (var ydx in ylines) {
		const yline = ylines[ydx];
		const cmatch = cregexp.exec(yline);
		if (cmatch) {
			const ykey = yline.substring(0, cmatch.index);
			const yval = yline.substring(cmatch.index + 1)
				.trim().replace(/^"/, "").replace(/"$/, "");
			metadata[ykey] = yval;
		}
	}
	return [metadata, mdstr];
}

/**
 * Navigation with help documents
 * 
 * @param {array} helpfiles - 2D array with name, url, filename of help documents
 * @returns {component}
 */
function HelpNav({ helpfiles }) {
	return (
		<ErrorWrap>
			<Nav className="beActive flex-column">
				{helpfiles.map(function (hf) {
					return (
						<LinkContainer to={"/help/" + hf[0]} key={hf[0]}>
							<Nav.Link >{hf[1]}</Nav.Link>
						</LinkContainer>
					);
				})}
			</Nav>
		</ErrorWrap>
	);
}

/**
 * Displays single help document with PDF download button
 * 
 * @param {any} userlogin - info about logged user
 * @param {array} helpfiles - 2D array with name, url, filename of help documents
 * @returns {component}
 */
function HelpView({ userlogin, helpfiles }) {
	//console.log(useParams());
	const { helpFile } = useParams();
	const helpDef = helpfiles.filter((hf) => hf[0] === helpFile)[0];
	//console.log(helpfiles);
	//console.log(helpFile);
	const helpURI = helpDef[2];
	const helpPic = helpDef[3];

	const [markdown, setMarkdown] = useState("");
	const [first, setFirst] = useState(true);
	const [metadata, setMetadata] = useState({});

	useEffect(function () {
		setFirst(false);
		const running_fetch = his_fetch(userlogin,
			[
				{
					uri: "/help/" + helpURI,
					devlocal: true,
					ok: function (resource, data) {
						//console.log("done");
						const [metadata, mddata] = parse_markdown_metadata(data);
						setMetadata(metadata);
						setMarkdown(mddata);
					},
					error: function (resource, reason) {
						console.log('err: ' + reason);
					}
				}
			]);
		return () => {
			running_fetch();
		}
	}, [first, userlogin, helpURI]);

	const filtered_markdown = markdown
		.replaceAll(/\\.*/g, "")
		.replaceAll(/\$.*\$/g, "")
		.replaceAll(/[{].*[}]/g, "")
		.replaceAll("Obsah\n-----", "");

	// console.log(helpURI.substring(0, helpURI.length - 2));

	return (
		<ErrorWrap>
			<div className="mdStyle bg-white px-5 py-3">
				<h3 className='d-inline'>{metadata["title"]}</h3>
				<Button className="float-end">
					<img src="/application-pdf.svg" alt="PDF" height="30" />
					&nbsp;&nbsp;&nbsp;
					<a className="text-white noStyle toDoLink" href={helpURI.substring(0, helpURI.length - 2) + "pdf"} >Stáhnout PDF</a>
				</Button>
				<ReactMarkdown remarkPlugins={[gfm]}
					transformImageUri={(s, a, t) => "/help/" + helpPic + "/" + s}
					children={filtered_markdown} />
			</div>
		</ErrorWrap>
	);
}

/**
 * Navigation with routes for Help section
 * 
 * @param {any} userlogin - info about logged user
 * @returns {componet}
 */

export function Help({ userlogin }) {
	// remarkPlugins={[gfm, images]}, měla jsem to v mark-downu, ale nic to nedělalo

	const helpfiles = [
		["pojmy", "Slovníček", "pojmy.md", "pojmy"],
		["csr-pd-export", "Odesílání PD do skladu", "process-csr-pd-export.md", "pd-export"],
		["product-cards-warehouses", "Odeslání produktové karty do skladu", "process-product-card-warehouse-sending.md", "product-cards"],
		["ios-install", "Instalace na iPhone", "ios-install.md", "iphone"],
		["android-install", "Instalace na Android", "android-install.md", "android"],
		["no-sn", "Avizace", "process-NO-stock-notify.md", "no-sn"],
	];

	return (
		<ErrorWrap>
			<Row>
				<Col xs="12" xl="auto">
					<HelpNav helpfiles={helpfiles} />
					<hr className="d-xl-none" />
				</Col>
				<Col>
					<Routes>
						<Route path=":helpFile" element={<HelpView helpfiles={helpfiles} userlogin={userlogin} />} />
					</Routes>
				</Col>
			</Row>
		</ErrorWrap>
	);
}

