import React, { useState } from "react";
import { his_fetch_success, HisFetchStatus } from "../comp/FetchLoader";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Pager } from "../comp/pager";
import { date_compare, filter_rule, get_dict_for_multiselect, icompare, is_date_comparable } from "../lib/utils";
import { ItemsAndFiltered } from "../comp/dynamic-load";
import { date_formatCZ } from "../lib/date-utils";
import { format_amount, hilite } from "../lib/format";
import { useTranslation } from "react-i18next";
import { MyInfo } from "../comp/info-badge";
import { EmbeddedNotes } from "../notes/EmbeddedNotes";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import { getStocksPacksAll, getStocksPacksNotes } from "../api/stocks";
import { queryStatusToLoadedStatus } from "../api/common";
import { getBatchWeightKgTwist, getPackageCountTwist, uniqueSortedProductIds } from "./ProductStocks";
import { SortIconsRhf } from "../comp/SortIconsRhf";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { MultipleSelectRhf } from "../comp/MultipleSelectRhf";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";

/**
 * Return array of notes for specific batch within specific product
 */
function getNotesPerBatch(batchString: string, IDProduktu: number, notes: Dto.GetStocksPacksNotesResponseItem[]) {
  const usableNotes = typeof notes !== "object" ? [] : notes;

  const notesPerProduct = usableNotes.filter((note) => String(note.objectCode) === String(IDProduktu));
  const notesPerBatch = notesPerProduct.filter((note) => note.objectCode2 === batchString);
  return notesPerBatch.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}

/**
 * Main structure of data for "Time in-stock" table, grouped by Product ID.
 */
function preprocessTimeInStockData(
  records: Dto.GetStocksPacksAllResponseItem[],
  notes: Dto.GetStocksPacksNotesResponseItem[]
) {
  const uniqueProductIdsSorted = uniqueSortedProductIds(
    records,
    (r: Dto.GetStocksPacksAllResponseItem) => r.product.id
  );

  const productBatchesTree: { [id: number]: Dto.GetStocksPacksAllResponseItem[] } = uniqueProductIdsSorted.reduce(
    function (acc, productID: number) {
      const oneProductStocksData = records.filter((rec) => rec.product.id === productID);
      return {
        ...acc,
        [productID]: oneProductStocksData,
      };
    },
    {}
  );

  return uniqueProductIdsSorted.map(function (IDProduktu) {
    const this_product = productBatchesTree[IDProduktu]; //všechny batches per jedno IDProduktu
    const one_batch = this_product[0]; //první batch z produktové série

    return {
      IDProduktu: IDProduktu,
      product_name: one_batch.product.name,
      product_code: one_batch.product.code,
      package: one_batch.product.hshPackage,
      centre: one_batch.product.groupCode,
      batches: processStocksBatches(this_product, notes), // ??.sort((a, b) => compare_values(a, b, "expiry", direction === "up" ? 1 : -1)), //local sorting of expirations within batches on product
      product_td_rowspan: this_product.length,
    };
  });
}

/**
 * 2nd level structure of data for "Time in-stock" - all batches per product grouped by batch string.
 */
function processStocksBatches(
  batches: Dto.GetStocksPacksAllResponseItem[],
  notes: Dto.GetStocksPacksNotesResponseItem[] /*, direction, sort_key */
) {
  const uniqueBatches = uniqueSortedProductIds(batches, (r: Dto.GetStocksPacksAllResponseItem) => r.seriesCode);
  const batchesTree: { [key: string]: Dto.GetStocksPacksAllResponseItem[] } = uniqueBatches.reduce(function (
    acc,
    batchString
  ) {
    const oneBatchData = batches.filter((rec) => rec.seriesCode === batchString);
    return {
      ...acc,
      [batchString]: oneBatchData,
    };
  }, {});
  return uniqueBatches.map(function (batchString) {
    const this_batch = batchesTree[batchString]; //všechny záznamy per jedden batchstring
    const one_batch_item = this_batch[0]; //první záznam z série jedné šarže

    return {
      alf_batch: batchString,
      expiry: one_batch_item.expiration,
      batches_in_whs: processStocksBatchesStockinDates(this_batch), // batches_in_whs, //one batch can be in multiple warehouses
      batch_td_rowspan: this_batch.length,
      notes: getNotesPerBatch(batchString, one_batch_item.product.id, notes),
    };
  });
}

/**
 * 3rd level structure of data for "Time in-stock" - all stockin_date items groupped by warehouse ID.
 */
function processStocksBatchesStockinDates(one_batch: Dto.GetStocksPacksAllResponseItem[]) {
  const uniqueWarehousesPerBatch = uniqueSortedProductIds(one_batch, (r) => r.warehouseId);
  const widsTree: { [wid: string]: Dto.GetStocksPacksAllResponseItem[] } = uniqueWarehousesPerBatch.reduce(function (
    acc,
    wid
  ) {
    const oneWarehouseBatchData = one_batch.filter((rec) => rec.warehouseId === wid);
    return {
      ...acc,
      [wid]: oneWarehouseBatchData,
    };
  }, {});

  return uniqueWarehousesPerBatch.map(function (wid) {
    const thisWarehouse = widsTree[wid]; //všechny záznamy per jedden batchstring
    //const one_warehouse_item = this_warehouse[0]; //první záznam
    return {
      wid: wid,
      stocks_in_one_wh: addCalculationsToDateInWh(thisWarehouse), // one wh can have multiple stocking dates of one batch
      warehouse_td_rowspan: thisWarehouse.length,
    };
  });
}

/**
 * Adds extra keys to given dictionary. In this case, quantity_in_kg and pieces are calculated and added per record
 */
function addCalculationsToDateInWh(recordsInOneWarehouse: Dto.GetStocksPacksAllResponseItem[]) {
  return recordsInOneWarehouse.map((record) => ({
    ...record,
    quantity_in_kg: getBatchWeightKgTwist(record),
    pieces: getPackageCountTwist(record),
  }));
}

interface TimeInStockProps {
  fetchDate: string;
}

/**
 * Main component with fetch for the section Time in-stock.
 */
export function TimeInStock({ fetchDate }: TimeInStockProps) {
  const {
    data: stocksData,
    refetch: refetchStocksData,
    status: stocksDataStatus,
    isFetching: stocksDataFetching,
  } = useQuery({
    queryKey: ["stocks", "packs", "all", fetchDate],
    queryFn: ({ signal, queryKey }) => getStocksPacksAll(queryKey[3], { signal }),
    initialData: [],
  });
  const stocksDataLoading = queryStatusToLoadedStatus(stocksDataStatus, stocksDataFetching);

  function reloadIt() {
    return refetchStocksData();
  }

  const {
    data: fetchedNotes,
    status: notesStatus,
    isFetching: notesFetching,
  } = useQuery({
    queryKey: ["stocks", "packs", "notes", fetchDate],
    queryFn: ({ signal, queryKey }) => getStocksPacksNotes(queryKey[3], { signal }),
    initialData: [],
    enabled: stocksData.length > 0,
  });
  const notesLoading = queryStatusToLoadedStatus(notesStatus, notesFetching);

  if (!his_fetch_success(stocksDataLoading)) {
    return (
      <HisFetchStatus status={stocksDataLoading} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
    );
  }

  return <TimeInStockTable records={stocksData} notes={fetchedNotes} loadedStatusNotes={notesLoading} />;
}

const defaultFilterValues: TimeInStockTableFilterValues = {
  orderBy: "IDProduktu",
  centre: "",
  product: "",
  packages: [],
  batch: "",
  warehouses: [],
  lastUpdateDate: "",
  stockInDate: "",
  expiryDate: "",
  quantity: "",
  quantityKg: "",
  count: "",
  note: "",
};

interface TimeInStockTableProps {
  records: Dto.GetStocksPacksAllResponseItem[];
  notes: Dto.GetStocksPacksNotesResponseItem[];
  loadedStatusNotes: number;
}

/**
 * Table of Time in-stock with filters
 */
function TimeInStockTable({ records, notes, loadedStatusNotes }: TimeInStockTableProps) {
  const { t } = useTranslation();
  const form = useForm<TimeInStockTableFilterValues>({ mode: "onChange", defaultValues: defaultFilterValues });
  const { watch } = form;
  const [filterValues] = useDebounceFilterValues(watch, defaultFilterValues);

  const dictionaryOfWHValues = get_dict_for_multiselect(records, "warehouseId", true);
  const itemsNames = Object.keys(dictionaryOfWHValues).reduce((acc, v) => ({ ...acc, [v]: v }), {});
  const dictionaryPackageValues = get_dict_for_multiselect(
    records.map((r) => r.product),
    "hshPackage",
    true
  );
  const itemsNamesPackage = Object.keys(dictionaryPackageValues).reduce((acc, v) => ({ ...acc, [v]: v }), {});

  const [offset, setOffset] = useState(0);
  const [openedNotes, setOpenedNotes] = useState({});

  const cleanFilters = () => {
    form.reset(defaultFilterValues);
  };

  const preprocessedData = preprocessTimeInStockData(records, notes);

  //function for expading notes
  function toggleOpenNotes(identification) {
    setOpenedNotes({ ...openedNotes, [identification]: !openedNotes[identification] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
  }

  const allHidden = Object.keys(openedNotes).length === Object.values(openedNotes).filter((v) => v === false).length;

  function expandAll() {
    const value = allHidden ? true : false;
    const newDictionary = openAllNotes(value);
    setOpenedNotes(newDictionary);
  }

  function openAllNotes(value) {
    const all_productID_batch_combinations = records.map((rec) => String(rec.product.id) + String(rec.seriesCode));
    const unique_identifications = all_productID_batch_combinations.filter((v, i, a) => a.indexOf(v) === i);
    const dictionary = unique_identifications.reduce((acc, id) => ({ ...acc, [id]: value }), {});
    return dictionary;
  }

  const stocks_filtered = preprocessedData.filter(function (stock) {
    return (
      (filter_rule(filterValues.product, stock.IDProduktu, true) ||
        filter_rule(filterValues.product, stock.product_code, true) ||
        filter_rule(filterValues.product, stock.product_name, true)) &&
      filter_rule(filterValues.centre, stock.centre, true) &&
      (filterValues.packages.length === 0 || filterValues.packages.includes(stock.package)) &&
      stock.batches.reduce((res, batch) => res || filter_rule(filterValues.batch, batch.alf_batch, true), false) &&
      stock.batches.reduce((res, batch) => res || date_compare(batch.expiry, filterValues.expiryDate), false) &&
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) =>
              res || filterValues.warehouses.length === 0 || filterValues.warehouses.includes(batchInWh.wid),
            false
          ),
        false
      ) &&
      //tripple reduce to filter number of packages/piecess
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) =>
              res ||
              (batchInWh.stocks_in_one_wh || []).reduce(
                (res, dateInWh) => res || icompare(dateInWh.pieces, filterValues.count),
                false
              ),
            false
          ),
        false
      ) &&
      //tripple reduce to filter quantity
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) =>
              res ||
              (batchInWh.stocks_in_one_wh || []).reduce(
                (res, dateInWh) => res || icompare(dateInWh.storageAmount, filterValues.quantity),
                false
              ),
            false
          ),
        false
      ) &&
      //tripple reduce to filter quantity in kg
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) =>
              res ||
              (batchInWh.stocks_in_one_wh || []).reduce(
                (res, dateInWh) => res || icompare(dateInWh.quantity_in_kg, filterValues.quantityKg),
                false
              ),
            false
          ),
        false
      ) &&
      //tripple reduce to filter stockinDate
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) =>
              res ||
              (batchInWh.stocks_in_one_wh || []).reduce(
                (res, dateInWh) => res || date_compare(dateInWh.stockInDate, filterValues.stockInDate),
                false
              ),
            false
          ),
        false
      ) &&
      //tripple reduce to filter lastUpdate
      stock.batches.reduce(
        (res, batch) =>
          res ||
          (batch.batches_in_whs || []).reduce(
            (res, batchInWh) =>
              res ||
              (batchInWh.stocks_in_one_wh || []).reduce(
                (res, dateInWh) => res || date_compare(dateInWh.stamp, filterValues.lastUpdateDate),
                false
              ),
            false
          ),
        false
      ) &&
      //reduce to filter notes text
      (stock.batches.reduce(
        (res, batch) =>
          res || (batch.notes || []).reduce((res, note) => res || filter_rule(filterValues.note, note.note), false),
        false
      ) ||
        (filterValues.note === "" && stock.batches.reduce((res, batch) => res || batch.notes.length === 0, false))) //or notes is empty array and filter in unfullfilled
    );
  });

  const stocks_sorted = stocks_filtered.sort(function (a, b) {
    if (filterValues.orderBy.includes("IDProduktu")) {
      if (filterValues.orderBy.startsWith("-") === false) {
        return a["IDProduktu"] - b["IDProduktu"];
      }
      return b["IDProduktu"] - a["IDProduktu"];
    }
    if (filterValues.orderBy.includes("centre")) {
      if (filterValues.orderBy.startsWith("-") === false) {
        const nameA = a["centre"] ? a["centre"].toUpperCase() : "žžžžž";
        const nameB = b["centre"] ? b["centre"].toUpperCase() : "žžžžž";
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        const nameA = a["centre"] ? a["centre"].toUpperCase() : "žžžžž";
        const nameB = b["centre"] ? b["centre"].toUpperCase() : "žžžžž";
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
    }
    return 0;
  });

  const show_stocks = stocks_sorted.slice(offset, offset + 20);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">{t("menu3-time-in-stock")}</h4>
        </Col>
        <Col className="text-center">
          <ItemsAndFiltered filtered_data={stocks_filtered} data={preprocessedData} cleanFilters={cleanFilters} />
        </Col>
        <Col>
          <Pager offset={offset} pagesize={20} total={stocks_filtered.length} callback={setOffset} />
        </Col>
      </Row>
      <Table size="sm" bordered style={{ backgroundColor: "white" }}>
        <thead className="beGray">
          <FormProvider {...form}>
            <TimeInStockTableFilters
              packageValues={itemsNamesPackage}
              loadedStatusNotes={loadedStatusNotes}
              expandAll={expandAll}
              allHidden={allHidden}
              warehouseValues={itemsNames}
            />
          </FormProvider>
        </thead>
        <tbody>
          {show_stocks.map(function (prod, idx) {
            // 1st level of the product
            const bg = idx % 2 === 0 ? "" : "beGray3";
            return prod.batches.map(function (batch, idxBatch) {
              //2nd level of the batch
              const class_hi_expiry =
                date_compare(batch.expiry, filterValues.expiryDate) && is_date_comparable(filterValues.expiryDate)
                  ? "bg-info-light"
                  : "";
              return batch.batches_in_whs.map(function (batchInWh, idxBatchInWh) {
                //3rd level of warehouse
                const class_hi_wh =
                  filterValues.warehouses.includes(batchInWh.wid) &&
                  filterValues.warehouses.length !== Object.keys(itemsNames).length
                    ? "bg-info-light"
                    : ""; //pokud jsou všechny true, tak nebarvit, pole těch false je 0
                //console.log(batch.notes);
                const notes_on_batch = batch.notes;
                return batchInWh.stocks_in_one_wh.map(function (stockinDateInWh, idxDateInWh) {
                  //4th level of one date in one warehouse of one batch of an product
                  const class_hi_stockinDate =
                    date_compare(stockinDateInWh.stockInDate, filterValues.stockInDate) &&
                    is_date_comparable(filterValues.stockInDate)
                      ? "bg-info-light"
                      : "";
                  const class_hilite_MJ =
                    icompare(stockinDateInWh.storageAmount, filterValues.quantity) && filterValues.quantity !== ""
                      ? "bg-info-light"
                      : "";
                  const class_hi_kg =
                    icompare(stockinDateInWh.quantity_in_kg, filterValues.quantityKg) && filterValues.quantityKg !== ""
                      ? "bg-info-light"
                      : "";
                  const class_hi_count =
                    icompare(stockinDateInWh.pieces, filterValues.count) && filterValues.count !== ""
                      ? "bg-info-light"
                      : "";
                  const class_hi_update =
                    date_compare(stockinDateInWh.stamp, filterValues.lastUpdateDate) &&
                    is_date_comparable(filterValues.lastUpdateDate)
                      ? "bg-info-light"
                      : "";
                  return (
                    <tr key={idxDateInWh} className={bg}>
                      {idxBatch === 0 && idxBatchInWh === 0 && idxDateInWh === 0 ? (
                        <>
                          <td rowSpan={prod.product_td_rowspan} className={" align-middle text-center"}>
                            <h5>{hilite(String(prod.IDProduktu) || "", filterValues.product)}</h5>
                            {hilite(prod.product_code || "", filterValues.product)} <br />
                            {hilite(prod.product_name || "", filterValues.product)}
                          </td>
                          <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">
                            {hilite(prod.centre || "", filterValues.centre)}
                          </td>
                          <td rowSpan={prod.product_td_rowspan} className="align-middle text-center">
                            {prod.package}
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      {idxBatchInWh === 0 && idxDateInWh === 0 ? (
                        <>
                          <td rowSpan={batch.batch_td_rowspan} className="align-middle">
                            {" "}
                            {filterValues.batch.length > 0
                              ? hilite(batch.alf_batch || "", filterValues.batch)
                              : batch.alf_batch}
                          </td>
                          <td rowSpan={batch.batch_td_rowspan}>
                            <Row className="g-0">
                              <Col className="me-2">
                                {batch.notes.length > 0 && !openedNotes[prod.IDProduktu + batch.alf_batch] ? (
                                  <span className={!!batch.notes[0].hiddenAt ? "cross-out" : ""}>
                                    <span className="text-muted">{batch.notes[0].createdBy.name}:</span>&nbsp;
                                    {batch.notes[0].note}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {batch.notes.length > 1 && !openedNotes[prod.IDProduktu + batch.alf_batch] ? (
                                  <>
                                    <br />
                                    (+ {batch.notes.length - 1} další)
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                              <Col xs="auto">
                                <Button
                                  disabled={loadedStatusNotes !== 2}
                                  onClick={() => toggleOpenNotes(prod.IDProduktu + batch.alf_batch)}
                                  className="float-end px-1 py-1"
                                  size="sm"
                                  variant="outline-danger"
                                >
                                  <img alt="note" height={20} src="/img/note2.svg"></img>
                                </Button>
                              </Col>
                            </Row>
                            {openedNotes[prod.IDProduktu + batch.alf_batch] ? ( //zkontrolovat, jestli se nacházím na správném batchi
                              <>
                                <EmbeddedNotes
                                  objectType="ProductSeries"
                                  objectCode={prod.IDProduktu.toString()}
                                  objectCode2={batch.alf_batch}
                                  enabledNotes={[1]}
                                  existingNotes={notes_on_batch}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td rowSpan={batch.batch_td_rowspan} className={"text-end align-middle "}>
                            <span className={class_hi_expiry}>
                              {batch.expiry ? (
                                date_formatCZ(batch.expiry)
                              ) : (
                                <span className="text-danger">{t("expiry-missing")}!</span>
                              )}
                            </span>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      {idxDateInWh === 0 ? (
                        <>
                          <td rowSpan={batchInWh.warehouse_td_rowspan} className="text-center align-middle">
                            <span className={class_hi_wh}>{batchInWh.wid}</span>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                      <td className="text-end align-middle">
                        <span className={class_hi_stockinDate}>{date_formatCZ(stockinDateInWh.stockInDate)}</span>
                      </td>
                      <td className="text-end align-middle">
                        <span className={class_hilite_MJ}>
                          {format_amount(stockinDateInWh.storageAmount)}{" "}
                          {stockinDateInWh.product.storageUnitOfMeasurementCode}
                        </span>
                      </td>
                      <td className="text-end align-middle">
                        <span className={class_hi_kg}>
                          {format_amount(stockinDateInWh.quantity_in_kg)}&nbsp;{t("prod-kg")}
                        </span>
                      </td>
                      <td className="text-end align-middle">
                        <span className={class_hi_count}>
                          {stockinDateInWh.pieces} {t("piece_short")}
                        </span>
                      </td>
                      <td className="text-end align-middle">
                        <span className={class_hi_update}>
                          {stockinDateInWh.stamp ? date_formatCZ(stockinDateInWh.stamp) : "-"}
                        </span>
                      </td>
                    </tr>
                  );
                });
              });
            });
          })}
        </tbody>
      </Table>
    </>
  );
}

interface TimeInStockTableFilterValues {
  orderBy?: string;
  product?: string;
  centre?: string;
  packages: string[];
  batch?: string;
  note?: string;
  expiryDate?: string;
  stockInDate?: string;
  lastUpdateDate?: string;
  warehouses: string[];
  count?: string;
  quantity?: string;
  quantityKg?: string;
}

interface TimeInStockTableFiltersProps {
  packageValues: { [key: string]: string };
  warehouseValues: { [key: string]: string };
  loadedStatusNotes: number;
  expandAll: () => void;
  allHidden: boolean;
}

function TimeInStockTableFilters({
  packageValues,
  loadedStatusNotes,
  expandAll,
  allHidden,
  warehouseValues,
}: TimeInStockTableFiltersProps) {
  const { t } = useTranslation();
  const { register } = useFormContext<TimeInStockTableFilterValues>();

  return (
    <>
      <tr>
        <th rowSpan={3} className="align-middle text-center pb-2">
          <div className="mb-1">
            {t("not-prod_id")}&nbsp;
            <SortIconsRhf columnName="IDProduktu" filterFieldName="orderBy" numeric />
            {t("prod-code")}
            <br />
            {t("prod-name")}
          </div>
          <Form.Group controlId="filterCode" className="mb-0">
            <Form.Control type="text" placeholder="&#128269;" {...register("product")} />
          </Form.Group>
        </th>
        <th rowSpan={3} className="text-center pb-2">
          {t("biz-centre")}
          <SortIconsRhf columnName="centre" filterFieldName="orderBy" />
          <br />
          <Form.Group controlId="filterCentre">
            <Form.Control type="text" placeholder="&#128269;" {...register("centre")} />
          </Form.Group>
        </th>
        <th rowSpan={3} className="text-center pb-2">
          {t("ord-pack")}
          <MultipleSelectRhf values={packageValues} id="packages" withoutNumber />
        </th>
        <th colSpan={9} className="text-center">
          {t("prod-found_batches")}
        </th>
      </tr>
      <tr>
        <th rowSpan={2} className="">
          {" "}
          <p className="mb-1">{t("ord-batch")}</p>
          <Form.Group controlId="filterCode" className="mb-1">
            <Form.Control type="text" placeholder="&#128269;" {...register("batch")} />
          </Form.Group>
        </th>
        <th style={{ width: "20%" }} rowSpan={2}>
          <p className="mb-1">{t("prod-batch_notes")}</p>
          <HisFetchStatus status={loadedStatusNotes} errorType="" loadingType="" reloadButton={() => undefined} />
          <Row className="g-0">
            <Col>
              <Form.Group controlId="filterNote" className="mb-1">
                <Form.Control type="text" placeholder="&#128269;" {...register("note")} />
              </Form.Group>
            </Col>
            <Col xs="auto" className="ms-2">
              <Button variant="outline-danger" onClick={expandAll}>
                {allHidden ? (
                  <>
                    {t("expand")} <MdExpandMore />
                  </>
                ) : (
                  <>
                    {t("hide")} <MdExpandLess />
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </th>
        <th rowSpan={2} className="text-end pb-2">
          <br />
          <Form.Group controlId="filterExpiry" className="mb-0">
            <Form.Label className="mb-1"> {t("ord-expiry")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("expiryDate", { pattern: /[=<>][0-9.]+/ })}
            />
          </Form.Group>
        </th>
        <th rowSpan={2} className="text-center">
          {t("warehouse")}
          <MultipleSelectRhf values={warehouseValues} id="warehouses" withoutNumber />
        </th>
        <th rowSpan={2} className="text-end">
          <Form.Group controlId="filterStockinD" className="mb-1">
            <Form.Label className="mb-1"> {t("deno-stockin_date")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("stockInDate", { pattern: /[=<>][0-9.]+/ })}
            />
          </Form.Group>
        </th>
        <th colSpan={2} className="text-center">
          {t("prod-amount_per_batch")}
        </th>
        <th rowSpan={2} className="text-center ">
          <Form.Group controlId="filtercOUNT" className="mb-0">
            <Form.Label className="mb-1"> {t("prod-pack_count")}</Form.Label>
            <Form.Control
              className="mb-1"
              type="text"
              placeholder="&#128269; > < ="
              {...register("count", { pattern: /[=<>][0-9]+/ })}
            />
          </Form.Group>
        </th>
        <th rowSpan={2} className="text-end">
          <Form.Group controlId="filterUpdate" className="mb-1">
            <Form.Label className="mb-1">
              {" "}
              {t("prod-date_of_last")} <br /> {t("prod-status_update")}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="&#128269; > < ="
              {...register("lastUpdateDate", { pattern: /[=<>][0-9.]+/ })}
            />
          </Form.Group>
        </th>
      </tr>
      <tr>
        <th className="text-center">
          <Form.Group controlId="filterTotal" className="mb-0">
            <Form.Label className="mb-1">
              {" "}
              {t("measure_unit")} <MyInfo text="mj_means_warehouse_unit" />
            </Form.Label>
            <Form.Control
              className="mb-1"
              type="text"
              placeholder="&#128269; > < ="
              {...register("quantity", { pattern: /[=<>][0-9]+/ })}
            />
          </Form.Group>
        </th>
        <th className="text-center">
          <Form.Group controlId="filterTotalKG" className="mb-0">
            <Form.Label className="mb-1"> {t("prod-kg")}</Form.Label>
            <Form.Control
              className="mb-1"
              type="text"
              placeholder="&#128269; > < ="
              {...register("quantityKg", { pattern: /[=<>][0-9]+/ })}
            />
          </Form.Group>
        </th>
      </tr>
    </>
  );
}
