/**
* Tracking list of stock-notifications, which are being processed
* @module orders/stock-notify-tracking
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React, { useState, useEffect } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Row, Col, Form, Table, Image, Button } from 'react-bootstrap';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { ErrorWrap } from '../comp/errorwrap';
import {
    NotificationsOfOrderPurchase, stockin_data_transformation,
    get_id_boolean,
} from './stockin-display-comms';
import { StockinsAppFilters } from './stockin-display-mobile';
import { Pager } from '../comp/pager';
import { filter_rule, icompare } from '../lib/utils';
import { warehouses_for_select } from '../lists/warehouses-defs';
import { MultipleSelect } from '../comp/multiple-select';
import { stock_progress_specification } from './progress-stockins-def';
import { SortIcons } from '../comp/sort';
import { ItemsAndFiltered } from '../comp/dynamic-load';
import { Loading } from '../comp/loading';
import { BooleanDropdown } from '../comp/boolean';
import { useTranslation } from 'react-i18next';
import { MyInfo } from '../comp/info-badge';

//seen all - OK

/**
 * Tracking of stockins table.
 * 
 * @param {any} userlogin - information about logged user
 * @param {string} defaultUser - whether filter for created_by should be filled by default
 * @returns {component}
 */
export function StockNotifyTracking({ userlogin, defaultUser = null }) {
    const { t } = useTranslation();

    const dictionaryOfWHValues = Object.keys(warehouses_for_select).reduce((acc, v) => ({ ...acc, [v]: true }), {});
    const dictionaryOfWHValuesFalse = Object.keys(warehouses_for_select).reduce((acc, v) => ({ ...acc, [v]: false }), {});
    const statuses_for_select = stock_progress_specification.reduce((acc, v) => ({ ...acc, [v.number]: v.name }), {});
    const dictionaryOfStatusValues = Object.keys(statuses_for_select).reduce((acc, v) => ({ ...acc, [v]: true }), {});
    const dictionaryOfStatusValuesFalse = Object.keys(statuses_for_select).reduce((acc, v) => ({ ...acc, [v]: false }), {});

    const [newData, setNewData] = useState(null);
    const [oldData, setOldData] = useState(null);
    const [reloadingPeriod, setReloadingPeriod] = useState(0);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [detailShown, setDetailShown] = useState({});
    const [historyLogs, setHistoryLogs] = useState([]);
    const [offset, setOffset] = useState(0);
    const [filterNOCode, setFilterNOCode] = useState("");
    const [filterCreator, setFilterCreator] = useState(defaultUser !== null ? defaultUser : "");
    const [checkedWH, setCheckedWH] = useState(dictionaryOfWHValues);
    const [checkedStatus, setCheckedStatus] = useState(dictionaryOfStatusValues);
    const [sortValue, setSortValue] = useState("sn_created_at");
    const [direction, setDirection] = useState("up");
    const [type, setType] = useState("");
    const [filterDaysToEta, setFilterDaysToEta] = useState("");
    const [filterProblematic, setFilterProblematic] = useState(null);

    const cleanFilters = () => {
        setFilterNOCode("");
        setFilterCreator("");
        setCheckedWH(dictionaryOfWHValues);
        setCheckedStatus(dictionaryOfStatusValues);
        setSortValue("sn_created_at");
        setDirection("up");
        setType("");
        setFilterDaysToEta("");
        setFilterProblematic(null);
    }

    const authorizedUser = userlogin.userinfo.hisSuperuser === true || userlogin.userinfo.roleCompliance === true || userlogin.userinfo.roleCsrHead === true;

    function reloadIt() {
        setNewData(null);
    }

    useEffect(() => {
        if (newData === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stock-notifications/tracking",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            setOldData(result.notifications);
                            setNewData(result.notifications);
                            setHistoryLogs(result.logs);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setNewData("error");
                        }
                    }
                ]);
            return () => {
                running_fetch();
            }
        } else {
            if (reloadingPeriod > 0) {
                const timeout_id = setTimeout(() => {
                    setNewData(null);
                }, reloadingPeriod);
                return () => {
                    clearTimeout(timeout_id);
                };
            }
        }
    }, [userlogin, reloadingPeriod, newData]);

    //console.log(loadedStatus);
    // tady zůstává loaded status 1 i přes chybu - děje se to při reloadech - weird + oldData dependency

    const data = (newData !== null && newData !== "error") ? newData : oldData !== null ? oldData : [];

    const tracked_NOs = stockin_data_transformation(data, historyLogs, sortValue, sortValue === "sn_created_at", direction === "up" ? 1 : -1);

    //function for expading stockin content
    function toggleDetailShown(sn_id) {
        setDetailShown({ ...detailShown, [sn_id]: !detailShown[sn_id] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
    }

    const allHidden = Object.keys(detailShown).length === Object.values(detailShown).filter((v) => v === false).length;

    function expandAll() {
        const value = allHidden ? true : false;
        setDetailShown(get_id_boolean(data, value));
    }

    function get_unique_SN_ids_from_kodDokladu(kodDokladu) {
        return data.filter((sn) => sn.nop_KodDokladu === kodDokladu).map((sn) => sn.sn_id).filter((v, i, a) => a.indexOf(v) === i);
    }

    function is_NO_hidden(kodDokladu) {
        const ids = get_unique_SN_ids_from_kodDokladu(kodDokladu);
        const isHidden = ids.reduce((acc, sn_id) => acc || detailShown[sn_id], false);
        return !isHidden;
    }

    function expandAllInNO(NO) {
        const sn_ids = get_unique_SN_ids_from_kodDokladu(NO);
        const value = is_NO_hidden(NO) ? true : false;
        setDetailShown(sn_ids.reduce((acc, sn_id) => ({ ...acc, [sn_id]: value }), detailShown)); //z pole dělám slovník a začínám na slovníku detailShown
    }

    const handleChangeNOCode = function (event) {
        setFilterNOCode(event.target.value);
        setOffset(0);
    }
    const handleChangeCreator = function (event) {
        setFilterCreator(event.target.value);
        setOffset(0);
    }
    const handleChangeDaysToEta = function (event) {
        setFilterDaysToEta(event.target.value);
        setOffset(0);
    }


    const stockins_filtered = tracked_NOs.filter(
        function (no) {
            return (
                (filter_rule(filterNOCode, no.KodDokladu)) &&
                ((filterCreator === "") ||
                    (no.notifications.reduce((res, notify) => res || filter_rule(filterCreator, notify.mp_Jmeno, true), false)) ||
                    (no.notifications.reduce((res, notify) => res || filter_rule(filterCreator, notify.u_username, true), false))

                ) &&
                (no.notifications.reduce((res, notify) => res || checkedWH[notify.sn_warehouse_id], false)) &&
                (no.notifications.reduce((res, notify) => res || checkedStatus[notify.sns_status], false)) &&
                (no.notifications.reduce((res, notify) => res || icompare(notify.days_to_eta, filterDaysToEta), false)) &&
                (no.notifications.reduce((res, notify) => res || ((filterProblematic === null) || (filterProblematic === notify.problematic_solve_soon)), false))

            );
        });

    const stockins_sorted = stockins_filtered.sort(function (a, b) {
        if (type === "num") {
            if (direction === "up") {
                return new Date(a[sortValue]) - new Date(b[sortValue]);
            }
            if (direction === "down")
                return new Date(b[sortValue]) - new Date(a[sortValue]);
        }
        return 0;
    });

    const show_stockins = stockins_sorted.slice(offset, offset + 5);
    const username = userlogin.userinfo.username;
    //console.log(tracked_NOs);
    return (
        <ErrorWrap>
            <Row>
                <Col>
                    <Button disabled={!his_fetch_success(loadedStatus)} size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
                </Col>
                <Col className='text-center'>
                    <ItemsAndFiltered filtered_data={stockins_filtered} data={tracked_NOs} cleanFilters={cleanFilters} itemsName={t('ord-of_ords_purch')} />
                    {!his_fetch_success(loadedStatus) ?
                        <span> <Loading size="small" message={t('loading')} margin="0" /></span> : ""}
                </Col>
                <Col className='text-end'>
                    <h6 className='d-inline'>

                        {t('background_loading')}:&nbsp;</h6>
                    <Form.Group controlId="display" className='mb-0 ms-3 d-inline'>
                        <Form.Check inline name="reloadingTime" type="radio" value={0} id='1' label={t('never')} onClick={() => setReloadingPeriod(0)} defaultChecked={reloadingPeriod === 0} />
                        <Form.Check inline name="reloadingTime" type="radio" value={15 * 1000} id='2' label="15s" onClick={() => setReloadingPeriod(15 * 1000)} defaultChecked={reloadingPeriod === 15} />
                        <Form.Check inline name="reloadingTime" type="radio" value={1 * 60 * 1000} id='3' label="1min" onClick={() => setReloadingPeriod(60 * 1000)} defaultChecked={reloadingPeriod === 60} />
                        <Form.Check inline name="reloadingTime" type="radio" value={5 * 60 * 1000} id='4' label="5min" onClick={() => setReloadingPeriod(5 * 60 * 1000)} defaultChecked={reloadingPeriod === 5 * 60} />
                    </Form.Group>
                    <br />
                    <br />
                    <Pager offset={offset} pagesize={5} total={stockins_filtered.length} callback={setOffset} />
                    <Button size="sm" className='float-end me-3 mb-2' onClick={() => expandAll()}>
                        {allHidden ? <>{t('expand')} <MdExpandMore /></> : <>{t('hide')} <MdExpandLess /></>}
                    </Button>
                </Col>
            </Row>

            <StockinsAppFilters filterNOCode={filterNOCode} handleChangeNOCode={handleChangeNOCode} filterCreator={filterCreator}
                handleChangeCreator={handleChangeCreator} sortValue={sortValue} setSortValue={setSortValue} direction={direction}
                setDirection={setDirection} setType={setType} checkedWH={checkedWH} setCheckedWH={setCheckedWH} dictionaryOfWHValues={dictionaryOfWHValues}
                dictionaryOfWHValuesFalse={dictionaryOfWHValuesFalse} setOffset={setOffset} checkedStatus={checkedStatus}
                setCheckedStatus={setCheckedStatus} dictionaryOfStatusValues={dictionaryOfStatusValues} dictionaryOfStatusValuesFalse={dictionaryOfStatusValuesFalse}
                statuses_for_select={statuses_for_select} username={username} setFilterCreator={setFilterCreator}
                filterDaysToEta={filterDaysToEta} handleChangeDaysToEta={handleChangeDaysToEta}
                filterProblematic={filterProblematic} setFilterProblematic={setFilterProblematic}
            />

            <Table className='border' size="sm">
                <StockinsTrackingWebFilters filterNOCode={filterNOCode} handleChangeNOCode={handleChangeNOCode} filterCreator={filterCreator}
                    handleChangeCreator={handleChangeCreator} sortValue={sortValue} setSortValue={setSortValue} direction={direction}
                    setDirection={setDirection} setType={setType} checkedWH={checkedWH} setCheckedWH={setCheckedWH} dictionaryOfWHValues={dictionaryOfWHValues}
                    dictionaryOfWHValuesFalse={dictionaryOfWHValuesFalse} setOffset={setOffset} checkedStatus={checkedStatus}
                    setCheckedStatus={setCheckedStatus} dictionaryOfStatusValues={dictionaryOfStatusValues} dictionaryOfStatusValuesFalse={dictionaryOfStatusValuesFalse}
                    statuses_for_select={statuses_for_select} username={username} setFilterCreator={setFilterCreator}
                    filterDaysToEta={filterDaysToEta} handleChangeDaysToEta={handleChangeDaysToEta}
                    filterProblematic={filterProblematic} setFilterProblematic={setFilterProblematic}
                />

                <tbody>
                    {show_stockins.map(function (no, idx) {
                        return (
                            <React.Fragment key={idx}>
                                <NotificationsOfOrderPurchase key={idx} global notifications={no.notifications} detailShown={detailShown} toggleDetailShown={toggleDetailShown}
                                    authorizedUser={authorizedUser} userlogin={userlogin} reloadIt={reloadIt} expandAllInNO={expandAllInNO} is_NO_hidden={is_NO_hidden}
                                    filterDaysToEta={filterDaysToEta} showProblematicCol showDaysToEtaCol />
                                <tr>
                                    <td colSpan={11} ></td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
            <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
        </ErrorWrap>
    )
}

/**
 * Filters for stockin overview in desktop version
 * 
 * @param {string} filterNOCode - filter value
 * @param {function} handleChangeNOCode - filter handle function
 * @param {string} filterCreator - filter value
 * @param {function} handleChangeCreator - filter handle function
 * @param {string} sortValue - state - value for user sorting 
 * @param {function} setSortValue - updates value for user sorting
 * @param {string} direction - state - sirection of sorting
 * @param {function} setDirection - updates the direction of sorting
 * @param {function} setType - type of sorting
 * @param {dictionary} checkedWH - multiselect state
 * @param {function} setCheckedWH - multiselect update function
 * @param {dictionary} dictionaryOfWHValues - dictionary for multiselect with true values
 * @param {dictionary} dictionaryOfWHValuesFalse - dictionary for multiselect with false values
 * @param {function} setOffset - function that updates start of pager
 * @param {dictionary} checkedStatus - multiselect state
 * @param {function} setCheckedStatus - multiselect update function
 * @param {dictionary} dictionaryOfStatusValues - dictionary for multiselect with true values
 * @param {dictionary} dictionaryOfStatusValuesFalse - dictionary for multiselect with false values
 * @param {dictionary} statuses_for_select
 * @returns {component}
 */
function StockinsTrackingWebFilters({ filterNOCode, handleChangeNOCode, filterCreator, handleChangeCreator, sortValue, setSortValue, direction,
    setDirection, setType, checkedWH, setCheckedWH, dictionaryOfWHValues, dictionaryOfWHValuesFalse, setOffset, checkedStatus,
    setCheckedStatus, dictionaryOfStatusValues, dictionaryOfStatusValuesFalse, statuses_for_select, username, setFilterCreator,
    filterDaysToEta, handleChangeDaysToEta, filterProblematic, setFilterProblematic }) {
    const { t } = useTranslation();

    return (
        <thead className='beGray '>
            <tr className='d-none d-lg-table-row'>
                <th width="10%" className='py-2'>
                    <p className="mb-1">{t('ord-pur')}</p>
                    <Form.Group controlId="filterCode" className='mb-1'>
                        <Form.Control type="text" placeholder="&#128269;" value={filterNOCode} onChange={handleChangeNOCode} />
                    </Form.Group>
                </th>
                <th className='text-center'>
                    <p className="mb-1">{t('problematic')}</p>
                    <div className='mb-2'>
                        <BooleanDropdown variant="order-tracking" onChange={setFilterProblematic} value={filterProblematic} />
                    </div>
                </th>
                <th className='text-center pb-2'>{t('ord-sn_num')}</th>
                <th className='text-center'>
                    <Row className="g-0">
                        <Col className='text-start'><p className="mb-1">{t('ord-created_by')}</p>
                        </Col>
                        <Col className='text-end'>
                            <div className='float-end mb-0'>
                                <Form.Check type="checkbox" id="filter_my_stockins"
                                    onChange={filterCreator !== username ? () => setFilterCreator(username) : () => setFilterCreator("")}
                                    checked={filterCreator === username}
                                    label={t('my_stockins')} />
                            </div>
                        </Col>
                    </Row>
                    <Form.Group controlId="filterCreator" className='mb-1'>
                        <Form.Control type="text" placeholder="&#128269;" value={filterCreator} onChange={handleChangeCreator} />
                    </Form.Group>
                </th>
                <th className='text-center pb-2'>{t('ord-last_change_time')}&nbsp;
                    <SortIcons name={"sn_created_at"} sortValue={sortValue} setSortValue={setSortValue}
                        direction={direction} setDirection={setDirection} setType={setType} numeric />
                </th>
                <th className='text-center pb-2'>{t('ord-eta')}&nbsp;
                    <SortIcons name={"sn_eta"} sortValue={sortValue} setSortValue={setSortValue}
                        direction={direction} setDirection={setDirection} setType={setType} numeric />
                </th>
                <th className='text-center'>&nbsp;
                    <Form.Group controlId="filterDaysToEta" className="mb-0">
                        <Form.Label className="mb-1">{t('ord-days_to_or_after_eta')} <MyInfo text='filter_days_to_ETA_with_minus_sign' /></Form.Label>
                        <Form.Control className="mb-1" type="text"
                            placeholder="&#128269; > < ="
                            value={filterDaysToEta} onChange={handleChangeDaysToEta}
                        />
                    </Form.Group>
                </th>
                <th className='text-center'>
                    <p className="mb-1">{t('warehouse')}</p>
                    <MultipleSelect checked={checkedWH} setChecked={setCheckedWH}
                        dictionaryTrue={dictionaryOfWHValues} dictionaryFalse={dictionaryOfWHValuesFalse}
                        itemsNames={warehouses_for_select} setOffset={setOffset} id="filterWH" />
                </th>
                <th className='text-center'>
                    <p className="mb-1">{t('state')}</p>
                    <MultipleSelect checked={checkedStatus} setChecked={setCheckedStatus}
                        dictionaryTrue={dictionaryOfStatusValues} dictionaryFalse={dictionaryOfStatusValuesFalse}
                        itemsNames={statuses_for_select} setOffset={setOffset} id="filterStatus" />
                </th>
                <th className='text-center pb-2'>{t('biz-items')}</th>
                <th></th>
            </tr>
        </thead>
    )
}


