/**
 * Partner debt analysis utility functions.
 * @module partners/partner-stats-utils
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

//seen all - OK


export function partner_get_acc_saldos(payments) {
    // payments must be from payments history! 
    var saldo = 0;
    var lsaldo = 0;
    var maxover = -10000;
    var maxover2 = -10000; // only from document saldo
    var nump1 = 0;
    var sumd1 = 0;
    var nump2 = 0;
    var sumd2 = 0;
    var nump3 = 0;
    var sumd3 = 0;
    for (var i = 0 ; i < payments.length ; i++) {
	saldo += payments[i][1];
	// console.log('sumd1 += ' + payments[i][7]);
	if (payments[i][7] !== null) { // all late payments settled at least partially
	    nump1 += 1;
	    sumd1 += payments[i][7];
	}
	if (payments[i][8] !== null) { // all payments overdue not settled completely now
	    nump2 += 1;
	    sumd2 += payments[i][8];
	    if (payments[i][8] > maxover) {
		maxover = payments[i][8];
	    }
	    if (payments[i][8] > 0) {
		lsaldo += payments[i][1];
	    }
	}
	if (payments[i][11] > 0) {
	    if (payments[i][8] > maxover2) {
		maxover2 = payments[i][8];
	    }
	    if ((payments[i][8] !== null)&&
		(payments[i][8] > 0)) {
		nump3 += 1;
		sumd3 += payments[i][8];
	    }
	}
    }
    var avg1 = sumd1;
    var avg2 = sumd2;
    var avg3 = sumd3;
    // console.log('avg1='+avg1);
    // console.log('avg2='+avg2);
    if (nump1 > 0) {
	avg1 /= nump1;
	avg1 = Math.floor(avg1);
    } else {
	avg1 = -10000;
    }
    if (nump2 > 0) {
	avg2 /= nump2;
	avg2 = Math.floor(avg2);
    } else {
	avg2 = -10000;
    }
    if (nump3 > 0) {
	avg3 /= nump3;
	avg3 = Math.floor(avg3);
    } else {
	avg3 = -10000;
    }
    return {
	saldo: saldo,
	lsaldo: lsaldo,
	maxover: maxover,
	avg1: avg1,
	avg2: avg2,
	maxover2: maxover2,
	avg3: avg3
    };
}

export function partner_get_insured_limit(partner) {
    if (partner.limits) {
	if (partner.limits[0]) {
	    if (partner.limits[0][0]) {
		return [partner.limits[0][6],partner.limits[0][7]];
	    }
	}
    }
    return ['—',''];
}
