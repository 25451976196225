import React from "react";
import { useParams } from "react-router-dom";
import { his_fetch_success, HisFetchStatus } from "../../comp/FetchLoader";
import { Routes, Route } from "react-router-dom";
import { StockNotification } from "./StockNotifications";
import { PurchaseOrderDetail } from "./PurchaseOrderDetail";
import { is_warehouse_defined } from "../../lists/warehouses-defs";
import { make_checker } from "../../comp/checker";
import { Card, Alert, Table, Button } from "react-bootstrap";
import {
  check_product_hsh_adr,
  check_product_warehouse_adr,
  check_product_hsh_container,
  check_product_warehouse_container,
  package_weight_compare,
  check_card_in_warehouse,
  check_product_warehouse_values,
  check_product_hsh_density,
  check_product_hsh_amount,
  check_product_hsh_MJ,
  check_product_warehouse_density,
  check_product_wh_MJ,
  check_product_wh_amount,
  check_product_expiration,
  check_suppliers_addresses,
  check_suppliers_address_type,
  check_product_twist_approval,
  check_product_his_approval,
  check_product_batches_expirations_monitoring,
  check_duplicities,
  make_product_check_tag,
  check_package_weight_wh_values,
  check_NO_unit_uniformity,
  check_warehouse_existence,
  check_item_has_IDProduktu,
} from "../../products/product-checks";
import { get_package_weight_kg, check_package_weight_kg_input } from "../../lib/generic-checks";
import { ErrorWrap } from "../../comp/errorwrap";
import { endNotification } from "../stock-notifications-open";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getPurchaseOrder, getPurchaseOrderOpenNotifications } from "../../api/orders";
import { queryStatusToLoadedStatus } from "../../api/common";
import { useUserLogin } from "../../UserLoginProvider";

//seen all - OK

/**
 * Finds duplicities in an array, returns duplicate items
 *
 * @param {array} arr - array with items
 * @returns {array}
 */
export function findDuplicates(arr) {
  let sorted_arr = arr.slice().sort();
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
}

export interface PurchaseOrderProcessedItem extends Dto.GetPurchaseOrderDetailItem {
  package_weight_kg_hsh: number;
  package_weight_kg_wh: number;
  bg_item_green_red: string;
  is_card: boolean;
  redish: string;
  item_checks_ok: boolean;
}

export function PurchaseOrder() {
  const { orderCode } = useParams();

  const {
    data: purchaseOrder,
    refetch: refetchOrder,
    status,
    isFetching,
  } = useQuery({
    queryKey: ["api/orders/purchase/", orderCode],
    queryFn: ({ queryKey, signal }) => getPurchaseOrder(queryKey[1], { signal }),
  });

  const { data: openNotifications, refetch: refetchOpenNotifications } = useQuery({
    enabled: !!purchaseOrder,
    queryKey: ["api/stock-notifications/", purchaseOrder?.order?.orderId, "/open"],
    refetchInterval: 5 * 1000,
    queryFn: ({ queryKey, signal }) => {
      const orderId = queryKey[1] as number;
      if (!orderId) return Promise.resolve<Dto.GetOrderOpenStockNotificationsResponseItem[]>([]);
      return getPurchaseOrderOpenNotifications(orderId, { signal });
    },
    initialData: [],
  });

  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const reloadIt = async () => {
    await refetchOrder();
    await refetchOpenNotifications();
  };

  if (!his_fetch_success(loadedStatus)) {
    return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
  }

  const notifyOtherUsers_usable = openNotifications;

  const { performCheck, ErrorBadge, TodoList, checksOK, worstCheckResult } = make_checker();

  check_suppliers_addresses(performCheck, "hsh", [purchaseOrder.supplierData]);
  check_suppliers_address_type(performCheck, "hsh", [purchaseOrder.supplierData]);

  const preprocessedItems = purchaseOrder.items.map(function (item): PurchaseOrderProcessedItem {
    const polozky_ids = purchaseOrder.items.map((item) => item.itemId);
    const duplicities = findDuplicates(polozky_ids);
    check_duplicities(performCheck, item.itemId, duplicities);

    check_NO_unit_uniformity(
      performCheck,
      item.itemId?.toString(),
      item.item.storageUnitOfMeasurementWeight,
      item.item.storageUnitOfMeasurementUnit
    );

    check_product_twist_approval(performCheck, item.productId, item.approvedInTwist);
    check_product_his_approval(
      performCheck,
      item.productId,
      item.needsApproval,
      item.warehouseConfiguration.approvedInHis
    );
    const configured = parseInt(item.warehouseConfiguration.type?.toString() || "0") > 0;
    check_product_batches_expirations_monitoring(performCheck, +item.productId, configured);
    check_product_expiration(
      performCheck,
      +item.productId,
      item.warehouseConfiguration.type,
      item.warehouseConfiguration.months
    );
    check_item_has_IDProduktu(performCheck, item.productId, item.productId);

    check_product_hsh_container(performCheck, "hsh-" + item.productId, item.hshPackage);
    check_product_hsh_amount(performCheck, "hsh-" + item.productId, item.packageUnitOfMeasurementQuantity);
    check_product_hsh_MJ(performCheck, "hsh-" + item.productId, item.storageUnitOfMeasurementCode);
    check_product_hsh_density(
      performCheck,
      "hsh-" + item.productId,
      item.unitOfMeasurementWeight,
      item.unitOfMeasurementUnit
    );
    check_product_hsh_adr(
      performCheck,
      "hsh-" + item.productId,
      item.adr,
      item.classAdr,
      item.adrPackingGroup?.toString(),
      item.adrUnNumber
    );

    // 666: "Počítání hmotnosti obalu v kg"
    //console.log(item);
    const [packageWeightInputsOK_HSH] = check_package_weight_kg_input(
      item.unitOfMeasurementWeight,
      item.unitOfMeasurementUnit,
      item.packageUnitOfMeasurementQuantity
    );
    const package_weight_kg_hsh = packageWeightInputsOK_HSH
      ? get_package_weight_kg(
          item.unitOfMeasurementWeight,
          item.unitOfMeasurementUnit,
          item.packageUnitOfMeasurementQuantity
        )
      : null;
    const [packageWeightInputsOK_wh] = check_package_weight_kg_input(
      item.warehouseCard.unitOfMeasurementWeightInKg,
      "kg",
      item.warehouseCard.packageUnitOfMeasurementQuantity
    ); // "kg" protože ze skladu to nechodí
    const package_weight_kg_wh = packageWeightInputsOK_wh
      ? get_package_weight_kg(
          item.warehouseCard.unitOfMeasurementWeightInKg,
          "kg",
          item.warehouseCard.packageUnitOfMeasurementQuantity
        )
      : null;
    //const worth_display1 = item.pjo_MnozstviSklMjVObalu && item.pj_HmotnostMj;
    //const package_weight_kg_hsh_count = Fraction(item.pjo_MnozstviSklMjVObalu).mul(Fraction(item.pj_HmotnostMj));
    //const package_weight_kg_hsh = worth_display1 ? String(package_weight_kg_hsh_count) : <>&mdash;</>;
    //const worth_display2 = item.warehouseCard.packageUnitOfMeasurementQuantity && item.warehouseCard.unitOfMeasurementWeightInKg;
    //const package_weight_kg_wh_count = Fraction(item.warehouseCard.packageUnitOfMeasurementQuantity).mul(Fraction(item.warehouseCard.unitOfMeasurementWeightInKg));
    //const package_weight_kg_wh = worth_display2 ? String(package_weight_kg_wh_count) : <>&mdash;</>;
    const adr_whs = [16, "16", 22, "22"];
    const warehouse = item.item.warehouseBookCode ? item.item.warehouseBookCode.trim() : "nonsense";
    const adr_warehouse = adr_whs.includes(warehouse);
    const soFarSoGood = checksOK("hsh-" + item.productId + "$"); //kotroluju, jestli karetní hsh checky jsou ok

    check_warehouse_existence(
      performCheck,
      item.item.warehouseBookCode + "-" + item.productId,
      item.item.warehouseBookCode
    );
    if (item.warehouseCard.warehouseId && soFarSoGood) {
      //item.pci_warehouse_id - product-card-imports -  indentiffikuje soubor, co poslali rano ze skladu, pokud je to null, tak produkt ve skladu není
      //console.log(item.pci_warehouse_id); //pokud nenavážem imporní soubor na kod knihy skladu je to null a neumime to kontrolovat, necháváme zelené
      //console.log(soFarSoGood);
      //	console.log(item.pci_warehouse_id && soFarSoGood);
      //	console.log(item.pci_warehouse_id);
      //	console.log(item.pcr_repeats);
      //	console.log("pre-missing-card-" + item.item.warehouseBookCode + "-" + item.productId);
      check_card_in_warehouse(
        performCheck,
        item.item.warehouseBookCode + "-" + item.productId,
        item.warehouseCard.repeats
      );
      //	console.log("post-missing-card-" + item.item.warehouseBookCode + "-" + item.productId);
      if (checksOK("missing-card-" + item.item.warehouseBookCode + "-" + item.productId)) {
        //console.log(checksOK("missing-card-" + item.item.warehouseBookCode + item.productId));
        check_product_warehouse_values(
          performCheck,
          "wh-" + item.item.warehouseBookCode + "-" + item.productId,
          item.warehouseCard.productName,
          item.warehouseCard.unitOfMeasurementWeightInKg,
          item.warehouseCard.packageUnitOfMeasurementQuantity,
          item.warehouseCard.storageUnitOfMeasurementCode,
          true
        );
        // console.log("pre-missing-values-" + item.item.warehouseBookCode + item.productId);
        //	if (checksOK("missing-values-" + item.item.warehouseBookCode + "-" + item.productId)) {
        //console.log(checksOK("missing-values-" + item.item.warehouseBookCode + item.productId));
        check_product_warehouse_density(
          performCheck,
          "wh-" + item.item.warehouseBookCode + "-" + item.productId,
          item.warehouseCard.unitOfMeasurementWeightInKg,
          item.unitOfMeasurementWeight,
          true
        );
        check_product_wh_amount(
          performCheck,
          "wh-" + item.item.warehouseBookCode + "-" + item.productId,
          item.warehouseCard.packageUnitOfMeasurementQuantity,
          item.packageUnitOfMeasurementQuantity,
          true
        );
        check_product_wh_MJ(
          performCheck,
          "wh-" + item.item.warehouseBookCode + "-" + item.productId,
          item.warehouseCard.storageUnitOfMeasurementCode,
          item.storageUnitOfMeasurementCode,
          true
        );
        check_package_weight_wh_values(
          performCheck,
          "wh-" + item.item.warehouseBookCode + "-" + item.productId,
          item.warehouseCard.packageUnitOfMeasurementQuantity,
          item.warehouseCard.unitOfMeasurementWeightInKg
        );
        if (checksOK("missing-amount-or-density-" + item.item.warehouseBookCode + "-" + item.productId)) {
          package_weight_compare(
            performCheck,
            "wh-" + item.item.warehouseBookCode + "-" + item.productId,
            package_weight_kg_hsh,
            package_weight_kg_wh
          );
        }
        check_product_warehouse_container(
          performCheck,
          "wh-" + item.item.warehouseBookCode + "-" + item.productId,
          item.hshPackage,
          Number.parseInt(item.item.warehouseBookCode),
          item.warehouseCard.hshPackage
        );
        check_product_warehouse_adr(
          performCheck,
          "wh-" + item.item.warehouseBookCode + "-" + item.productId,
          item.adr,
          item.classAdr,
          item.adrPackingGroup?.toString(),
          item.adrUnNumber,
          item.warehouseCard.adrClass,
          item.warehouseCard.adrPackingGroup,
          item.warehouseCard.adrUnNumber,
          !adr_warehouse
        );
      }
    }

    // kontroluje checky, který končej, -productId
    const item_checks_ok =
      checksOK("hsh-" + item.productId + "$") &&
      checksOK("wh-" + item.item.warehouseBookCode + "-" + item.productId) &&
      checksOK("missing-card-" + item.item.warehouseBookCode + "-" + item.productId) &&
      checksOK("" + item.productId + "$") &&
      checksOK("" + item.itemId + "$");
    const bg_item_green_red = item_checks_ok ? "bg-success" : "bg-danger";
    const is_card = checksOK("missing-card-" + item.item.warehouseBookCode + "-" + item.productId);
    const redish =
      item.warehouseCard.productName || item_checks_ok || !soFarSoGood || item.warehouseCard.warehouseId === null
        ? ""
        : " bg-red";

    return {
      ...item,
      package_weight_kg_hsh: package_weight_kg_hsh,
      package_weight_kg_wh: package_weight_kg_wh,
      bg_item_green_red: bg_item_green_red,
      is_card: is_card,
      redish: redish,
      item_checks_ok: item_checks_ok,
    };
  });
  //console.log(preprocessedItems);

  const everythingOK = checksOK();
  const items_config_warehouses = preprocessedItems.filter((item) => is_warehouse_defined(item.item.warehouseBookCode));
  const noWarehouse = items_config_warehouses.length === 0;
  const notifi_able_items = items_config_warehouses.filter((item) => item.item_checks_ok);
  const supplier_ok = checksOK("addresses-hsh") && checksOK("address-hsh");
  const showButton = notifi_able_items.length > 0 && supplier_ok; //jsou dobře checky na supplier
  const deliveryNO = purchaseOrder.order.orderCode.slice(6, 8) === "10";
  //console.log(deliveryNO);

  const products_packagings_list = purchaseOrder.items.reduce(function (acc, it) {
    const id = it.productId;
    const obal = it.packagingCode;
    const current_list = acc[id] || [];
    return {
      ...acc,
      [id]: current_list
        .concat([obal])
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((v) => v !== null),
    };
  }, {});
  //console.log(products_packagings_list);
  /* eslint-disable */
  const products_packagings_counts = Object.keys(products_packagings_list).reduce(function (acc, id) {
    //const bude v budoucnu potřeba při řešení problému
    const len = products_packagings_list[id].length;
    acc[id] = len;
    performCheck(make_product_check_tag(id, "duppack"), "danger", function () {
      if (len === 0) {
        return false;
        // return "není obal";
      }
      if (len > 1) {
        return "multiple_basic_package_for_product";
      }
      return false;
    });
    return acc;
  }, {});
  //console.log(products_packagings_counts);

  /*if (order.twist_sync_problem) {
    return (
      <>
        <SyncProblemHugeAlert sentence={t("ord_purch_is_blocked")} />
        <br />
        <br />
        <PurchaseOrderDetail
          order={order}
          items={preprocessedItems}
          reloadIt={reloadIt}
          ErrorBadge={ErrorBadge}
          TodoList={TodoList}
          everythingOK={everythingOK}
          worstCheckResult={worstCheckResult}
          showButton={false} //hidden so as not to show
          noWarehouse={noWarehouse}
          deliveryNO={deliveryNO}
          userlogin={userlogin}
          stockNotifi={stockNotifi}
          emails={emails}
          texts={texts}
          notifyOtherUsers={notifyOtherUsers_usable}
          stockNotifiLogs={stockNotifiLogs}
        />
      </>
    );
  }*/

  return (
    <ErrorWrap>
      <Routes>
        <Route
          path="notification/:step"
          element={
            <StockNotification
              order={purchaseOrder.order}
              items={notifi_able_items}
              stockinsNumber={purchaseOrder.stockNotifications.length}
              reloadIt={reloadIt}
              texts={purchaseOrder.texts}
              notifyOtherUsers={notifyOtherUsers_usable}
            />
          }
        />
        <Route
          path=""
          element={
            <PurchaseOrderDetail
              order={purchaseOrder.order}
              items={preprocessedItems}
              reloadIt={reloadIt}
              ErrorBadge={ErrorBadge}
              TodoList={TodoList}
              everythingOK={everythingOK}
              worstCheckResult={worstCheckResult}
              showButton={showButton}
              noWarehouse={noWarehouse}
              stockNotifi={purchaseOrder.stockNotifications}
              emails={purchaseOrder.emails}
              texts={purchaseOrder.texts}
              notifyOtherUsers={notifyOtherUsers_usable}
              deliveryNO={deliveryNO}
              //stockNotifiLogs={purchaseOrder.stock_notifications_logs}
            />
          }
        />
      </Routes>
    </ErrorWrap>
  );
}

interface TextsProps {
  texts: Dto.OrderText[];
}

/**
 * Text to display during stockin process - "neutralizace" and such
 */
export function Texts({ texts }: TextsProps) {
  const { t } = useTranslation();

  var idx = 0;
  if (texts.length === 0) {
    return "";
  } else {
    return (
      <ErrorWrap>
        <Card className="mt-4 mb-4" style={{ maxWidth: "700px" }}>
          <Card.Body className="p-3">
            <h5>{t("ord-texts")}</h5>
            <Table size="sm" borderless className="m-0">
              <tbody>
                {texts.map(function (t) {
                  idx += 1;
                  return (
                    <tr key={idx}>
                      <td width="30%">{t.textTypeCode}</td>
                      <td>{t.textName}</td>
                      {t.textTypeCode === "PRP14" ? <td>{t.text}</td> : false}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </ErrorWrap>
    );
  }
}

/*
 * Alert
 *
 * @param {array} notifyOtherUsers
 * @param {any} userlogin
 * @param {function} reloadIt
 * @param {boolean} NOview
 * @returns {component}
 */

interface OtherUsersAlertProps {
  notifyOtherUsers: Dto.GetOrderOpenStockNotificationsResponseItem[];
  reloadIt: () => void;
  NOview?: boolean;
}

export function OtherUsersAlert({ notifyOtherUsers, reloadIt, NOview = false }: OtherUsersAlertProps) {
  const { t } = useTranslation();
  const userLogin = useUserLogin();

  //console.log(notifyOtherUsers);
  const text = NOview
    ? t("ord-following_users_are_working_on_sn_within_this_order") + " "
    : t("ord-following_users_are_doing_this_sn_too") + " ";
  const authorizedUser =
    userLogin.userInfo.hisSuperuser === true ||
    userLogin.userInfo.roleCompliance === true ||
    userLogin.userInfo.roleCsrHead === true;
  const activeUsers = notifyOtherUsers.map((us) => us.username);
  const filteredUsernames = activeUsers.filter((e) => e !== userLogin.userName);
  const filteredUsersSpaced = filteredUsernames.map((u) => " " + u);
  const intruder = filteredUsernames.length > 0;
  if (intruder) {
    return (
      <Alert variant="danger">
        {text} <b>{String(filteredUsersSpaced)}</b>. <br />
        {authorizedUser
          ? notifyOtherUsers
              .filter((user) => user.username !== userLogin.userName)
              .map(function (user, idx) {
                return (
                  <Button
                    key={idx}
                    onClick={() => endNotification(user.orderId, user.openedBy, reloadIt)}
                    variant="dark"
                    size="sm"
                    className="me-2 mt-3"
                  >
                    {t("ord-terminate_users_notification")} {user.username}
                  </Button>
                );
              })
          : ""}
      </Alert>
    );
  }
  return "";
}

//
