import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { his_fetch_success, HisFetchStatus } from "../../comp/FetchLoader";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Loading } from "../../comp/loading";
import { Boolean } from "../../comp/boolean";
import { monitoring_options } from "../../lists/monitoring_batch_bbd";
import { make_product_check_tag } from "../product-checks";
import { ErrorWrap } from "../../comp/errorwrap";
import { useTranslation } from "react-i18next";
import { useUserLogin } from "../../UserLoginProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getProductConfiguration, setProductConfiguration } from "../../api/products";
import { queryStatusToLoadedStatus } from "../../api/common";
import { Controller, FormProvider, useForm } from "react-hook-form";

export const defined_pH_classes = ["8", 8];

interface ProductConfigurationValues {
  approved?: "true" | "false";
  type?: number;
  months?: number;
  ph?: string;
}

interface ProductConfigurationProps {
  product: Dto.ProductDetailProduct;
  ErrorBadge: React.FunctionComponent<{ tag: string }>;
}

/*
 * Card with product configuration setting. Namely, approval in HIS, monitoring config, expiry in months and pH.
 */
export function ProductConfiguration({ product, ErrorBadge }: ProductConfigurationProps) {
  const { t } = useTranslation();
  const { userInfo } = useUserLogin();

  const queryClient = useQueryClient();
  const { data, status, isFetching, refetch, error } = useQuery({
    queryKey: ["productConfig", product.productId],
    queryFn: ({ queryKey, signal }) => getProductConfiguration(queryKey[1] as number, { signal }),
    initialData: [],
  });
  const errorMess = !!error ? "error" : undefined;
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const form = useForm<ProductConfigurationValues>({ mode: "onChange" });

  useEffect(() => {
    const currentApproval = data.find((line) => line.invalidated === null)?.approved;
    const currentConfig = data.find((line) => line.invalidated === null)?.type;
    const currentMonthExp = data.find((line) => line.invalidated === null)?.months;
    const currentPh = data.find((line) => line.invalidated === null)?.ph;
    form.reset({
      approved: currentApproval ? "true" : "false",
      type: currentConfig ?? 0,
      ph: currentPh,
      months: currentMonthExp,
    });
  }, [form, data]);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ productId, config }: { productId: number; config: Dto.PutProductConfigRequest }) =>
      setProductConfiguration(productId, config),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["product", product.productCode] }),
  });

  if (!his_fetch_success(loadedStatus)) {
    return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={refetch} />;
  }

  //user cannot configure product
  const noRight = !(userInfo.roleCompliance || userInfo.hisSuperuser);
  //if ph input should display
  const right_ph_class = defined_pH_classes.includes(product.adrClass);

  //when expiry in months is mandatory
  const currentConfig = form.watch("type");
  const monthExpNeeded = currentConfig === 2 || currentConfig === 3;
  //empty mandatory expiry

  return (
    <ErrorWrap>
      <Card body className="myShadow">
        <Row>
          <Col>
            <h5>{t("prod-product_card_setting")}</h5>
          </Col>
          <Col xs="auto" className="text-end">
            {isPending ? (
              <Loading message={t("saving") + " ..."} margin="0" size="small" />
            ) : (
              <Button
                size="sm"
                disabled={form.formState.isDirty === false || form.formState.isValid === false}
                onClick={form.handleSubmit((v) =>
                  mutateAsync({
                    productId: product.productId,
                    config: {
                      approved: v.approved === "true",
                      type: v.type,
                      months: v.months,
                      ph: v.ph,
                    },
                  })
                )}
              >
                {t("save")}
              </Button>
            )}
            <br />
            {errorMess ? <small className="text-danger">{errorMess}</small> : ""}
          </Col>
        </Row>
        <Row>
          <Col className="text-end">{t("prod-approved_card")}:</Col>
          <Col>
            <Boolean value={product.warehouseConfiguration?.productApproved ?? false} />
          </Col>
        </Row>
        <Row>
          <Col className="text-end">
            {t("prod-approved_buy")}:
            <ErrorBadge tag={make_product_check_tag("hsh", "twistApproval")} />
          </Col>
          <Col>
            <Boolean value={product.approvedInTwist} />
          </Col>
        </Row>
        <FormProvider {...form}>
          <Row className="my-2">
            <Col className="text-end">
              {t("prod-approved_his")}:
              <ErrorBadge tag={make_product_check_tag("hsh", "hisApproval")} />
            </Col>
            <Col>
              {product.needsApproval ? (
                <Controller
                  control={form.control}
                  render={({ field }) => (
                    <Form.Control
                      size="sm"
                      as="select"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={noRight}
                    >
                      <option value={"false"}>{t("no")}</option>
                      <option value={"true"}>{t("yes")}</option>
                    </Form.Control>
                  )}
                  name="approved"
                />
              ) : (
                t("not_necessary")
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              {t("prod-bbd_batch_monitoring")}:
              <ErrorBadge tag={make_product_check_tag("hsh", "configured")} />
            </Col>
            <Col>
              <Controller
                name="type"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <Form.Control
                    size="sm"
                    as="select"
                    type="number"
                    value={value}
                    onChange={(e) => onChange(+e.target.value)}
                    disabled={noRight}
                  >
                    {Object.keys(monitoring_options).map(function (m) {
                      return (
                        <option disabled={m === "0"} value={parseInt(m)} key={m}>
                          {t(monitoring_options[m])}
                        </option>
                      );
                    })}
                  </Form.Control>
                )}
              />
            </Col>
          </Row>
          {monthExpNeeded ? (
            <Row>
              <Col className="text-end mt-1">
                {t("prod-expiry_months")}:
                <ErrorBadge tag={make_product_check_tag("hsh", "monthExpiration")} />
              </Col>
              <Col>
                <Form.Group className="mb-2 mt-1" controlId="expirace_mesice">
                  <Form.Control
                    size="sm"
                    type="number"
                    min={1}
                    {...form.register("months", { valueAsNumber: true, required: true, shouldUnregister: true })}
                    disabled={noRight}
                  />
                  {form.formState.errors.months?.type === "required" && (
                    <small className="text-danger">{t("expiry_required")}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {right_ph_class ? (
            <Row>
              <Col className="text-end mt-1">
                pH:
                <ErrorBadge tag={make_product_check_tag("hsh", "pH-missing")} />
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-2" controlId="ph">
                  <Form.Control
                    size="sm"
                    type="number"
                    {...form.register("ph", { required: !noRight, shouldUnregister: true })}
                    disabled={noRight}
                  />
                  {form.formState.errors.ph?.type === "required" && (
                    <small className="text-danger">{t("ph_mandatory")}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </FormProvider>
      </Card>
    </ErrorWrap>
  );
}
