/**
 * Tracking of product cards sending.
 * @module products/product-tracking
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { date_time_format } from '../lib/date-utils';
import { warehouses_names_ids } from '../lists/warehouses-defs';
import { statuses_sending_card_strs } from '../lists/statuses-sending-card';
import { Button } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function ProductsTracking({ userlogin }) {
    const { t } = useTranslation();

    const [products, setProducts] = useState(undefined);
    const [loadedStatus, setLoadedStatus] = useState(0);

    const reloadIt = () => {
        setLoadedStatus(0);
        setProducts(null);
    }

    useEffect(() => {
        if (!products) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: '/api/products/tracking',
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            setProducts(result);
                        },
                        error: function (resource, result) {
                            console.log('error');
                            setProducts("error");
                        },
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, products]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    return (
        <ErrorWrap>
            <Button size="sm" className="me-2 mb-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
            <Table striped hover size="sm">
                <thead className="beGray">
                    <tr>
                        <th>{t('prod-id')}</th>
                        <th>{t('prod-code')}</th>
                        <th>{t('prod-name')}</th>
                        <th className="text-center">{t('warehouse')}</th>
                        <th className="text-center">{t('prod-communication_state')}</th>
                        <th className="text-end">{t('prod-sent')}</th>
                    </tr>
                </thead>
                <tbody>
                    {(products || []).map(function (p, idx) {
                        return (
                            <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(p.productCode) + "/view" }} key={idx}>
                                <tr >
                                    <td>{p.productId}</td>
                                    <td>{p.productCode}</td>
                                    <td>{p.productName}</td>
                                    <td className="text-center">{warehouses_names_ids[p.warehouseId]}</td>
                                    <td className="text-center">
                                        {p.status}
                                        &nbsp;&ndash;&nbsp;
                                        {t(statuses_sending_card_strs[p.status])}
                                    </td>
                                    <td className="text-end">{date_time_format(p.recorded)}</td>
                                </tr>
                            </LinkContainer>
                        );
                    })}
                </tbody>
            </Table>
        </ErrorWrap>
    );
}