/**
* statistics of stock notifications of users and warehouses
* @module orders/stock-notifications-statistics
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React, { useState, useEffect } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import Form from 'react-bootstrap/Form';
import { MonthYearDropdown } from '../comp/month-year-dropdown';
import { platneRoky, monthsOfYear } from '../lib/month-year-utils';
import {
    getDaysOfMonth, getWeeksOfYear, getMonthsOfYear, get_users,
    get_warehouses
} from '../lib/statistics-utils';
import { StatsTableWeekly } from '../comp/statistics';
import { date_formatMM_YYYY } from '../lib/date-utils';
import { Alert, Button } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all -  OK

/**
 * Staticstics concerning stockins- workers and warehouses performance.
 * 
 * @param {any} userlogin - information about logged user
 * @returns {component} 
 */
export function StockNotifyStatistics({ userlogin }) {
    const { t } = useTranslation();

    const [statistics, setStatistics] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [today, setToday] = useState(new Date());
    const [viewSpan, setViewSpan] = useState(1);

    const reloadIt = () => {
        setStatistics(null);
        setLoadedStatus(0);
    }

    useEffect(() => {
        if(statistics === null){
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stock-notifications/stats/new",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            setStatistics(result);
                            //console.log(result);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setStatistics("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, statistics]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }


    const noData = statistics.notificationsByUsers.length === 0 || statistics.notificationsByWarehouses.length === 0;
    const month_title = date_formatMM_YYYY(today);
    const year_title = today.getFullYear();
    const minDate = statistics.notificationsByUsers.length > 0 ? statistics.notificationsByUsers[0]["date"] : new Date();
    const maxDate = new Date();
    const years = platneRoky(minDate, maxDate);
    const months = monthsOfYear(minDate, maxDate, today.getFullYear());

    const handleChangeView = function (val) {
        setViewSpan(val);
    }

    const periods = viewSpan === 1 ? getDaysOfMonth(today) : viewSpan === 2 ? getWeeksOfYear(today) : getMonthsOfYear(today);

    return (
        <ErrorWrap>
            <div className='bg-white p-3'>
                <Button size="sm" className="me-2 mb-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
                <h4 className="d-inline pb-5">{t('ord-stats')} {viewSpan === 1 ? month_title : year_title}</h4>
                {noData ? <Alert className="d-inline mx-5" variant='danger'>{t('empty_database')}.</Alert> : ""}
                <div className="float-end">
                    <Form.Group controlId="display">
                        <Form.Check inline name="viewSpan" type="radio" value={1} id='1' label={t('month_by_days')} onClick={() => handleChangeView(1)} defaultChecked={viewSpan === 1} />
                        <Form.Check inline name="viewSpan" type="radio" value={2} id='2' label={t('year_by_weaks')} onClick={() => handleChangeView(2)} defaultChecked={viewSpan === 2} />
                        <Form.Check inline name="viewSpan" type="radio" value={3} id='3' label={t('year_by_month')} onClick={() => handleChangeView(3)} defaultChecked={viewSpan === 3} />
                    </Form.Group>
                    <div className="float-end mt-3">
                        {viewSpan === 1 ?
                            <MonthYearDropdown minDate={minDate} maxDate={maxDate} setToday={setToday} today={today} years={years} months={months} marginCorrection />
                            :
                            <MonthYearDropdown minDate={minDate} maxDate={maxDate} setToday={setToday} today={today} years={years} months={months} yearOnly marginCorrection />
                        }
                    </div>
                </div>

                <div>&nbsp;</div>

                <h5>{t('ord-sn_by_users')}</h5>
                <StatsTableWeekly stats={statistics.notificationsByUsers} weeks={periods} get_keys={get_users} stat_key={"username"} objectname={t('not-user')} viewType={viewSpan} date={today} />
                <div>&nbsp;</div>
                <h5 className='mb-0'>{t('ord-an_by_wh')}</h5>
                <StatsTableWeekly stats={statistics.notificationsByWarehouses} weeks={periods} get_keys={get_warehouses} stat_key={"warehouseId"} objectname={t('warehouse')} viewType={viewSpan} date={today} />
            </div>
        </ErrorWrap>
    );
}