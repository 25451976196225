/**
 * Partner header for all tabs.
 * @module partners/partner-header
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { format_amount } from '../lib/format';
import { worst_color, delay_color } from '../lists/colors';
import Card from "react-bootstrap/esm/Card";
import Table from 'react-bootstrap/Table';
import { ErrorWrap } from "../comp/errorwrap";
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PartnerHeader({ ic, dic, country,
  name, code, rating,
  credit_limit, internal_limit, insured_limit,
  acc_saldo, acc_saldo_late, acc_currency,
  tolerance,
  delay_avg, hold_avg, hold_max,
  rating_history,
  insured_currency, insured_saldo, reloadIt,
  todo, variant, ok
}) {
  const { t } = useTranslation();
  // Sanitization
  if (delay_avg === -10000) {
    delay_avg = '—';
  }
  if (hold_avg === -10000) {
    hold_avg = '—';
  }
  if (hold_max === -10000) {
    hold_max = '—';
  }

  // Late saldo coloring
  var lstyle = {};
  if (acc_saldo_late > 0) {
    lstyle = {
      backgroundColor: worst_color()
    };
  }

  // Over limits coloring
  var ilvar = "";
  if (acc_saldo > internal_limit) {
    ilvar = worst_color();
  }
  const ilstyle = {
    backgroundColor: ilvar,

  };

  // Delay/hold coloring
  var cstyle = {
    backgroundColor: delay_color(delay_avg),
  };
  var cstyle2 = {
    backgroundColor: delay_color(hold_avg),
  };
  var cstyle3 = {
    backgroundColor: delay_color(hold_max),
  };

  // ...
  return (
    <ErrorWrap>
      <Row className="mb-3 g-0" >
        <Col xs={12} sm={7} md={6} lg="3">
          <Card className="stin">
            <Card.Body className="p-0 text-center">
              <div className="d-inline-block">
                <Table className="propTable m-0" responsive size="sm" borderless>
                  <tbody>
                    <tr>
                      <th className="text-end">{t('code')}:</th>
                      <td className="text-start">{code}</td>
                    </tr>
                    <tr>
                      <th className="text-end">{t('biz-ic')}:</th>
                      <td className="text-start">{ic}</td>
                    </tr>
                    <tr>
                      <th className="text-end">{t('biz-dic')}:</th>
                      <td className="text-start">{dic}</td>
                    </tr>
                    <tr>
                      <th className="text-end">{t('biz-country')}:</th>
                      <td className="text-start">{country}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={7} md={6} lg="3">
          <Card className="stin">
            <Card.Body className="p-0 text-center">
              <div className="d-inline-block">
                <Table className="propTable m-0" responsive size="sm" borderless>
                  <tbody>
                    <tr>
                      <th className="text-end">{t('biz-balance')}:</th>
                      <td className="text-end">{format_amount(acc_saldo)}</td>
                      <td className="text-start">{acc_currency}</td>
                    </tr>
                    <tr style={lstyle}>
                      <th className="text-end">{t('biz-after_due')}:</th>
                      <td className="text-end">{format_amount(acc_saldo_late)}</td>
                      <td className="text-start">{acc_currency}</td>
                    </tr>
                    <tr style={{ color: "#0a820a" }}>
                      <th className="text-end">{t('biz-ins_balance')}:</th>
                      <td className="text-end">
                        {insured_saldo ? format_amount(insured_saldo) : "–"}</td>
                      <td className="text-start">{acc_currency}</td>
                    </tr>
                    <tr style={{ color: "#d7081b" }}>
                      <th className="text-end">{t('biz-not_ins_balance')}:</th>
                      <td className="text-end">
                        {insured_saldo ? format_amount(acc_saldo - insured_saldo) : "–"}</td>
                      <td className="text-start">{acc_currency}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={7} md={6} lg="3" >
          <Card className="stin">
            <Card.Body className="p-0 text-center">
              <div className="d-inline-block">
                <Table className="propTable m-0" responsive size="sm" borderless>
                  <tbody>
                    <tr>
                      <th className="text-end">{t('biz-pay_tolerance')}:</th>
                      <td className="text-end">{tolerance}</td>
                    </tr>
                    <tr>
                      <th className="text-end">{t('biz-avg_delay')}:</th>
                      <td className="text-end" style={cstyle}><b>{delay_avg}</b></td>
                    </tr>
                    <tr>
                      <th className="text-end">{t('biz-max_delay')}:</th>
                      <td className="text-end" style={cstyle3}><b>{hold_max}</b></td>
                    </tr>
                    <tr>
                      <th className="text-end">{t('biz-avg_late')}:</th>
                      <td className="text-end" style={cstyle2}><b>{hold_avg}</b></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={7} md={6} lg="3">
          <Card className="stin">
            <Card.Body className="p-0 text-center">
              <div className="d-inline-block">
                <Table className="propTable m-0" responsive size="sm" borderless>
                  <tbody>
                    <tr>
                      <th className="text-center">{t('biz-ins_lim')}:</th>
                    </tr>
                    <tr>
                      <td className="text-center">{format_amount(insured_limit)}&nbsp;{insured_currency}</td>
                    </tr>
                    <tr>
                      <th className="text-center">{t('biz-intern_lim')}:</th>
                    </tr>
                    <tr>
                      <td className="text-center"><span style={ilstyle}>{format_amount(internal_limit)}&nbsp;{acc_currency}</span></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {/** 
              <Row className="text-center d-lg-none">
                <Col className="mb-2 mt-2">Pojistný&nbsp;limit: <b>{format_amount(insured_limit)}&nbsp;{insured_currency}</b></Col>
                <Col className="mb-2 mt-2">Interní&nbsp;limit: <b style={ilstyle}>{format_amount(internal_limit)}&nbsp;{acc_currency}</b></Col>
              </Row>
              */}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg="3">

        </Col>
      </Row>
    </ErrorWrap>
  );
}
