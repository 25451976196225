/**
 * Displays Credendo summaries
 * @module insurance/insurance-info
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { format_amount } from '../lib/format';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function InsuranceInfo({ limitscurr, freefics, totalinsured, currency }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>

			<Row>
				<Col xs="12" lg="6" xl="5">
					<Card bg="secondary" text="white" className="p-m">
						<Card.Header className="text-center">
							<h3>{t('ins-ins_limits_sum')}</h3>
							<p>{t('ins-insurance_limit')}:</p>
						</Card.Header>
						<Card.Body>
							{
								limitscurr.map(function (lc) {
									return (
										<Row className="g-0" key={lc[1]}>
											<Col xs="7" className="text-end"><h3>{format_amount(lc[0])}</h3></Col>
											<Col className="text-start"><h3>&nbsp;{lc[1]}</h3></Col>
										</Row>
									);
								})
							}
						</Card.Body>

					</Card>
				</Col>
				<Col xs="12" lg="6" xl="5">
					<Card bg="secondary" text="white" className="p-m">
						<Card.Header className="text-center">
							<h3>{t('ins-insured_balances_total')}</h3>
							<p>{t('ins-currently_used_insurance')}:</p>
						</Card.Header>
						<Card.Body>
							<Row className="g-0">
								<Col xs="7" className="text-end"><h3>{format_amount(totalinsured)}</h3></Col>
								<Col className="text-start"><h3>&nbsp;{currency}</h3></Col>
							</Row>
							<Row className="g-0">
								<Col className="d-none d-lg-block"><h3>&nbsp;</h3></Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col xs="12" xl="2">
					<Card style={{ maxWidth: "200px" }}>
						<Card.Body>
							<Card.Title>{t('ins-free_FIC')}</Card.Title>
							{
								freefics.filter(function (ff) {
									return ff[0] > 100;
								}).map(function (ff) {
									if (ff[0] === ff[1]) {
										return (
											<p key={ff[0]}>
												{ff[0]}
											</p>
										);
									}
									else if (ff[1] === false) {
										return (
											<p key={ff[0]}>
												{ff[0] + "+"}
											</p>
										)
									}
									else {
										return (
											<p key={ff[0]}>
												{ff[0]} – {ff[1]}
											</p>
										)
									}
								})
							}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</ErrorWrap>
	);
}
