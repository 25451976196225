import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { date_time_format } from "../lib/date-utils";
import { LinkContainer } from "react-router-bootstrap";
import Form from "react-bootstrap/Form";
import { Pager } from "../comp/pager";
import notes_comms from "./notes-comms.json";
import { filter_rule } from "../lib/utils";
import { ErrorWrap } from "../comp/errorwrap";
import { LoadingDataInfo } from "../comp/dynamic-load";
import { useTranslation } from "react-i18next";
import { queryStatusToLoadedStatus } from "../api/common";
import { DynamicLoadPaged } from "../comp/DynamicLoadPaged";
import { useQueryLoadData } from "../hooks/queryHooks";
import { getNotesRange } from "../api/notes";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { MultipleSelectRhf } from "../comp/MultipleSelectRhf";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";

const object_types = {
  Partner: "partner",
  Invoice: "not-invo",
  Product: "product",
  ProductSeries: "ord-batch",
};

interface NotesFilterValues {
  communicationIds: string[];
  department: string;
  user: string;
  types: Dto.NoteObjectType[];
  code: string;
  name: string;
  invoice: string;
  productId: string;
  note: string;
  archived: "all" | "current" | "archived";
}

const defaultValues: NotesFilterValues = {
  communicationIds: ["1", "2", "3", "4", "5"],
  department: "",
  user: "",
  types: ["Product", "Partner", "Invoice", "ProductSeries"],
  code: "",
  name: "",
  invoice: "",
  productId: "",
  note: "",
  archived: "all",
};

interface NotesListNewProps {
  defaultTypeFilter?: Dto.NoteObjectType[];
}

export function NotesListNew({
  defaultTypeFilter = ["Product", "Partner", "Invoice", "ProductSeries"],
}: NotesListNewProps) {
  const { t } = useTranslation();

  const [loadData, data, status, isFetching, refetch] = useQueryLoadData("notes", getNotesRange, {
    items: [],
    totalCount: 0,
    oldest: null,
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const form = useForm<NotesFilterValues>({ mode: "onChange", defaultValues });

  useEffect(() => {
    form.register("communicationIds");
    form.register("types");
    form.setValue("types", defaultTypeFilter);

    return () => {
      form.unregister("communicationIds");
      form.unregister("types");
    };
  }, [form]);

  const [filter] = useDebounceFilterValues(form.watch, { ...defaultValues, types: defaultTypeFilter });

  const [offset, setOffset] = useState(0);

  const cleanFilters = () => {
    form.reset(defaultValues);
  };

  const filteredNotes =
    data?.items?.filter(function (note) {
      const archivedNotes = !!note.hiddenBy ? "archived" : "current";
      return (
        filter_rule(filter.department, note.createdBy.departmentCode ?? note.hiddenBy?.departmentCode, true) &&
        filter_rule(filter.user, note.createdBy.login, true) &&
        filter_rule(filter.productId, !!note.product ? note.objectCode : "⛔", true) &&
        (filter_rule(filter.code, note.partner?.code, true) ||
          filter_rule(filter.code, note.objectCode2, true) ||
          filter_rule(filter.code, note.product?.code, true)) &&
        (filter_rule(filter.user, note.partner?.name, true) || filter_rule(filter.name, note.product?.name, true)) &&
        filter_rule(filter.note, note.note, true) &&
        filter_rule(filter.invoice, note.objectCode, true) &&
        filter.communicationIds.includes(note.communicationId.toString()) &&
        filter.types.includes(note.objectType) &&
        (filter.archived === "all" || filter.archived === archivedNotes)
      );
    }) ?? [];
  const notesList = filteredNotes.slice(offset, offset + 20);

  return (
    <ErrorWrap>
      <DynamicLoadPaged
        period="1y"
        loadData={loadData}
        loadingState={loadedStatus}
        rangeCount={data?.items.length ?? 0}
        filteredCount={filteredNotes.length}
        offset={offset}
        reloadIt={refetch}
        setOffset={setOffset}
        totalCount={data?.totalCount ?? 0}
        cleanFilters={cleanFilters}
      />
      <FormProvider {...form}>
        <Form.Group controlId="display" className="mb-0 mt-1">
          <Form.Check
            inline
            name="viewSpan"
            type="radio"
            value="all"
            id="1"
            label={t("all")}
            {...form.register("archived")}
          />
          <Form.Check
            inline
            name="viewSpan"
            type="radio"
            value="current"
            id="2"
            label={t("not-recent")}
            {...form.register("archived")}
          />
          <Form.Check
            inline
            name="viewSpan"
            type="radio"
            value="archived"
            id="3"
            label={t("not-archived")}
            {...form.register("archived")}
          />
        </Form.Group>
        <TableFetchStatusOverlay status={loadedStatus}>
          <Table size="sm" striped hover>
            <thead className="beGray">
              <NotesFilters />
            </thead>
            <tbody>
              {notesList.map(function (note) {
                const commId = note.communicationId;
                const commdef = notes_comms[commId];
                const variant = commdef.variant;
                const cname = commdef.text;
                const emoji = commdef.emoji;
                var textclass = "align-middle";
                if (!!note.hiddenBy?.login) {
                  textclass = "cross-out align-middle";
                }
                var linkto = {};
                if (note.objectType === "Partner") {
                  // Partner
                  linkto = { pathname: "/partners/list/" + note.objectCode + "/detail" };
                } else if (note.objectType === "Invoice") {
                  // Faktura
                  linkto = { pathname: "/partners/invoices-issued/" + note.objectCode };
                } else if (note.objectType === "Product") {
                  //Product
                  linkto = { pathname: "/products/" + encodeURIComponent(note.product?.code) + "/view" };
                }
                return (
                  <LinkContainer to={linkto} key={note.id}>
                    <tr key={note.id}>
                      <td className={"text-center ps-2 pe-2 align-middle bg-" + variant + " " + cname}>
                        <img src={emoji} alt="" width="20" />
                      </td>
                      <td className={textclass}>{date_time_format(note.created)}</td>
                      <td className={textclass}>{note.createdBy?.departmentCode}</td>
                      <td className={textclass}>
                        {note.createdBy?.name} ({note.createdBy?.login})
                      </td>
                      <td className={textclass}>{t(object_types[note.objectType])}</td>
                      <td className="align-middle">
                        {note.objectType === "Product" || note.objectType === "ProductSeries"
                          ? note.product.code
                          : note.partner.code}
                        {note.objectType === "ProductSeries" ? (
                          <>
                            <br />
                            <span className="text-muted">{t("ord-batch")}: </span>
                            {note.objectCode2}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="align-middle">
                        {note.objectType === "Product" || note.objectType === "ProductSeries"
                          ? note.product.name
                          : note.partner.code}
                      </td>
                      <td className={textclass}>{note.objectType === "Invoice" ? note.objectCode : ""}</td>
                      <td className={textclass}>
                        {note.objectType === "Product" || note.objectType === "ProductSeries" ? note.objectCode : ""}
                      </td>
                      <td className={textclass}>{note.note}</td>
                    </tr>
                  </LinkContainer>
                );
              })}
            </tbody>
          </Table>
        </TableFetchStatusOverlay>
      </FormProvider>

      <LoadingDataInfo loadedStatus={loadedStatus} data={data?.items ?? []} />
      <Pager offset={offset} pagesize={20} total={filteredNotes.length} callback={setOffset} />
    </ErrorWrap>
  );
}

function NotesFilters() {
  const { t } = useTranslation();
  const { register } = useFormContext<NotesFilterValues>();
  const notesLabels = Object.keys(notes_comms).reduce((acc, v) => ({ ...acc, [v]: t(notes_comms[v]["name"]) }), {});

  return (
    <tr>
      <th style={{ maxWidth: "90px", minWidth: "90px" }} scope="col" className="pb-3">
        <p className="mb-1">{t("not-topic")}</p>
        <MultipleSelectRhf values={notesLabels} id="communicationIds" withoutNumber />
      </th>
      <th style={{ width: "9%" }} scope="col" className="text-center align-middle">
        {t("date_time")}
      </th>
      <th style={{ width: "5%" }} scope="col" className="text-center pb-3">
        <Form.Label>{t("biz-centre")}</Form.Label>
        <Form.Group controlId="filterCode">
          <Form.Control type="text" placeholder="&#128269; " {...register("department")} />
        </Form.Group>
      </th>
      <th style={{ width: "10%" }} scope="col" className="text-center pb-3">
        <Form.Label>{t("not-user")}</Form.Label>
        <Form.Group controlId="filterUser">
          <Form.Control type="text" placeholder="&#128269; " {...register("user")} />
        </Form.Group>
      </th>
      <th style={{ width: "5%" }} scope="col" className=" align-middle pb-2">
        <p className="mb-1 pb-1">{t("not-type")}</p>
        <MultipleSelectRhf values={object_types} id="types" withoutNumber />
      </th>
      <th className="pb-3" scope="col">
        <Form.Label>{t("code")}</Form.Label>
        <Form.Group controlId="filterCode3">
          <Form.Control type="text" placeholder="&#128269;" {...register("code")} />
        </Form.Group>
      </th>
      <th scope="col" className="text-center pb-3">
        <Form.Label>{t("name")}</Form.Label>
        <Form.Group controlId="filterName">
          <Form.Control type="text" placeholder="&#128269; " {...register("name")} />
        </Form.Group>
      </th>
      <th scope="col" className="text-center pb-3">
        <Form.Label>{t("not-invo")}</Form.Label>
        <Form.Group controlId="filterCode2">
          <Form.Control type="text" placeholder="&#128269; " {...register("invoice")} />
        </Form.Group>
      </th>
      <th className="pb-3">
        <Form.Label>{t("not-prod_id")}</Form.Label>
        <Form.Group controlId="filterID">
          <Form.Control type="text" placeholder="&#128269; " {...register("productId")} />
        </Form.Group>
      </th>
      <th className="text-center pb-3">
        <Form.Label>{t("note")}</Form.Label>
        <Form.Group controlId="filterText">
          <Form.Control type="text" placeholder="&#128269; " {...register("note")} />
        </Form.Group>
      </th>
    </tr>
  );
}
