/**
 * Various helping functions
 * @module lib/utils
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import { date_parse_CZ_or_ISO } from './date-utils';


export function arrayRange(start, stop, step) {
    return Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );
}

export function get_map_keys(hm) {
    var keys = [];
    for (var k in hm) {
        keys.push(k);
    }
    return keys;
}

function safe_pattern_match(str, pattern) {
    try {
        if (str.search(pattern) >= 0) {
            return true;
        }
    } catch (e) {
        return true;
    }
    return false;
}

export function filter_rule(pattern, data, filterEmpty) {
    const datas = ("" + (data || "")).toLowerCase();
    const patterns = (pattern || "").toLowerCase();
    return (((datas === '') && (!filterEmpty)) ||
        (safe_pattern_match(datas, patterns)) ||
        ((patterns === '-') &&
            (datas === '')));
}

export function filter_rules(pattern, dataary, proc = (v) => v) {
    const patterns = (pattern || "").toLowerCase();
    if (patterns === '')
        return true;
    if ((patterns === "-") && (dataary.length === 0)) {
        return true;
    }
    var result = false;
    for (var idx in dataary) {
        const datavar = dataary[idx];
        const datas = (proc(datavar) || "").toLowerCase();
        if (patterns === "-") {
            if (datas === "") {
                return true;
            }
        } else {
            if (safe_pattern_match(datas, patterns)) {
                result = true;
            }
        }
    }
    return result;
}


//
// Compares value against comparable string
// Arguments:
//   * value - integer
//   * comp - comparable string
// Examples:
//   * icompare(1, '1') => true
//   * icompare(1, '>0') => true
//   * icompare(1, '<2') => true
//   * icompare(2, '<0') => false
//   * icompare(-1 '>10') => false
export function icompare(value, comp) {
    // console.log(comp);
    if (comp === "") return true;
    const first_char = comp.charAt(0);
    const is_comparator = first_char === '<' || first_char === '>' || first_char === '=';
    const comp_value = parseInt(is_comparator ? comp.substr(1) : comp);
    if (is_comparator) {
        switch (first_char) {
            case '<':
                return value < comp_value;
            case '>':
                return value > comp_value;
            case '=':
                return value === comp_value;
            default:
                return false;
        }
    }
    return value === comp_value;
}

//this function is necessary for highliting in the table!
export function is_date_comparable(date_str) {
    if (date_str === "") return false;

    const first_char = date_str.charAt(0);
    const is_comparator = first_char === '<' || first_char === '>' || first_char === '=';
    const input_date_to_parse = is_comparator ? date_str.substr(1) : date_str;

    if (date_parse_CZ_or_ISO(input_date_to_parse) !== false) {
        return true;
    } else {
        return false;
    }
}

/**
 * Coparator of dates, accepts dates in czech and ISO format
 * 
 * @param {string} value - value to compare with
 * @param {string} input - user input
 * @returns {boolean}
 */
export function date_compare(value, input) {
    // console.log(comp);
    if (input === "") return true;
    if (!is_date_comparable(input)) return true; //not a comparable date value yet

    const first_char = input.charAt(0);
    const is_comparator = first_char === '<' || first_char === '>' || first_char === '=';
    const input_date_to_parse = is_comparator ? input.substr(1) : input;

    const parsed_input = date_parse_CZ_or_ISO(input_date_to_parse);
    const parsed_value = date_parse_CZ_or_ISO(value); 
    // pokud to jsoo data, tak jim vynulovat hodiny třeba 
    const clean_input = parsed_input ? new Date(parsed_input.setHours(0, 0, 0)) : false;
    const clean_value = parsed_value ? new Date((parsed_value).setHours(0, 0, 0)) : false;  

    /*
    console.log(clean_input);
    console.log(clean_value);
    */    

    if (is_comparator) {
        switch (first_char) {
            case '<':
                return clean_value < clean_input;
            case '>':
                return clean_value > clean_input;
            case '=':
                return clean_value !== false && clean_input !== false && clean_value.getTime() === clean_input.getTime();
            default:
                return false;
        }
    }
    return clean_value !== false && clean_input !== false && clean_value.getTime() === clean_input.getTime();
}


export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}


/**
 * Creates 2D array from flat array with specified group sizes
 * 
 * @param {array} arr - flat initial array of values
 * @param {number} size - size of group
 * @returns {array}
 */
export function groupArray(arr, size) {
    let result = [];
    for (let i = 0; i < arr.length; i += size) {
        let chunk = arr.slice(i, i + size);
        result.push(chunk);
    }
    return result;
}

// obecná funkce, co mi bude sortovat pole slovníků na základě nějakého vnitřního klíče
// a to použiju tam, kde chci data sortovat - to si rozmyslet, kdy chci začít sortovat 
//zatím nepoužité
export function compare_values(a, b, sort_key = "alf_last_update", direction = 1) {
    const a_value = a[sort_key];
    const b_value = b[sort_key];
    //console.log('sorting: a_date='+a_date+' b_date='+b_date);
    if (a_value === null && b_value === null) {
        return 0;
    }
    if (a_value === null) {
        return 1 * direction;
    }
    if (b_value === null) {
        return -1 * direction;
    }
    if (a_value < b_value) {
        return 1 * direction;
    }
    if (b_value < a_value) {
        return -1 * direction;
    }
    return 0;
}

/**
 * Creates the dictionary with true/false value from all unique key values found in data
 * 
 * @param {any} data - all data to choose from
 * @param {string} key - what key in data should we filter, unify and sort
 * @param {boolean} boolean - which boolean is required
 * @returns {dictionary}
 */

export function get_dict_for_multiselect(data, key, boolean) {
    const uniques = data.map((rec) => rec[key]).filter((v, i, a) => a.indexOf(v) === i).sort();
    return uniques.reduce((acc, v) => ({ ...acc, [v]: boolean }), {});
}
