/**
 * Component for app rendering of list of delivery notes sales
 * @module delivery-notes/delivery-note-sales-app
 * @author Miroslav Lhoťan <miroslav.lhotan@netglade.cz>
 */

import React from 'react';
import { Accordion, ListGroup, Card, Table } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { date_formatCZ } from '../lib/date-utils';
import { Boolean } from '../comp/boolean';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import DeliveryNotesAppFilters from "./DeliveryNotesAppFilters";

/**
 * Mobile list of delivery notes with filters
 * 
 * @param {any} dnotes_shown - delivery notes to display on one page 
 * @returns {component}
 */
export function PDListMobile({ dnotes_shown, type, isLoaded }) {
	const exportImport = type === "PD" ? "exported" : "imported";

	return (
		<ErrorWrap>
			<div className="d-lg-none mb-2">
				<br />
				<DeliveryNotesAppFilters type={type} />
				<br />
				<Accordion>
					{isLoaded ?
						dnotes_shown.map(function (dn) {
							return (<SinglePDMobile
								key={dn.deliveryNoteCode}
								keyx={dn.deliveryNoteCode}
								code={dn.deliveryNoteCode}
								order={dn.orderCode}
								exported={dn[exportImport]}
								partner={dn.partnerCode}
								partnerName={dn.partnerName}
								note={dn.note}
								date={dn.date}
								invoiced={dn.invoicedPercent}
								closed={dn.closed}
								approved={dn.approved}
								cancelled={dn.cancelled}
								writer={dn.issuedBy}
								type={type}
							/>);
						})
						:
						<></>
					}
				</Accordion>
			</div>
		</ErrorWrap>
	);
}

/**
 * Return single mobile line of delivery note
 * 
 * @param {*} param0 
 * @returns {component}
 */
function SinglePDMobile({ keyx, code, order, exported, partner, partnerName, note, date, invoiced, closed, approved, cancelled, writer, type }) {
	const { t } = useTranslation();

	const url = type === "PD" ? "/delivery-notes/sales/" : "/delivery-notes/purchase/";

	return (
		<Accordion.Item eventKey={keyx}>
			<Accordion.Header>
				<Table size="sm" borderless className='m-0'>  
					<tbody>
						<tr>
							<td>{code}</td>
							<td className='text-end'>{type === "PD" ? t('deno-exported') : t('ord-imported')}:</td>
							<td><Boolean variant="onlyTrue" value={exported} /></td>
						</tr>
						<tr>
							<td>{order}</td>
							<td className='text-end'>{t('biz-closed')}:</td>
							<td><Boolean variant="onlyTrue" value={closed} /></td>
						</tr>
						<tr>
							<td>{t('biz-invoiced')}: {invoiced} %</td>
							<td className='text-end'>{t('deno-approved')}:</td>
							<td><Boolean variant="onlyTrue" value={approved} /></td>
						</tr>
						<tr>
							<td>{type === "PD" ? t('deno-delivery_date') : t('deno-stockin_date')}: {date ? date_formatCZ(date) : "-"}</td>
							<td className='text-end'>{t('canceled')}:</td>
							<td><Boolean variant="negative" value={cancelled} /></td>
						</tr>
					</tbody>
				</Table>
			</Accordion.Header>
			<Accordion.Body className='p-2'>
				<LinkContainer to={{ pathname: url + code }}>
					<Card.Body className="beSmaller">
						<ListGroup variant="flush" className="smaller">
							<ListGroup.Item>{t('partner')}: {partner}</ListGroup.Item>
							<ListGroup.Item>{t('name')}: {partnerName}</ListGroup.Item>
							<ListGroup.Item>{t('note_short')}: {note}</ListGroup.Item>
							<ListGroup.Item>{t('deno-issuer')}: {writer}</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</LinkContainer>
			</Accordion.Body>
		</Accordion.Item>
	);
}